import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ShiftWithDriverAndVehicleModel} from '../../../shared/models/shift.model';
import { HighlightedItemSource, HighlightedItemUpdate } from '../../../shared/models/highlighted-item-update';

@Injectable({
  providedIn: 'root'
})
export class ShiftMapDataService {

  constructor() { }

  private shiftSource = new BehaviorSubject<ShiftWithDriverAndVehicleModel>(null);
  shift$ = this.shiftSource.asObservable();

  private highlightedImageSource = new BehaviorSubject<HighlightedItemUpdate>(new HighlightedItemUpdate(null, null));
  readonly highlightedImage$ = this.highlightedImageSource.asObservable();

  private highlightedObservationSource = new BehaviorSubject<HighlightedItemUpdate>(new HighlightedItemUpdate(null, null));
  readonly highlightedObservation$ = this.highlightedObservationSource.asObservable();

  sendShift(data: ShiftWithDriverAndVehicleModel) {
    this.shiftSource.next(data);
  }

  sendHighlightedImageUpdate(imageId: number, source: HighlightedItemSource) {
    this.highlightedImageSource.next(new HighlightedItemUpdate(imageId, source));
  }

  sendHighlightedObservationUpdate(observationId: number, source: HighlightedItemSource) {
    this.highlightedObservationSource.next(new HighlightedItemUpdate(observationId, source));
  }
}
