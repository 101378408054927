import {EventEmitter} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {ActivatedRoute, Router} from '@angular/router';
import {ObjectWithId} from '../models/NamedId';

export class MultiSelectFilter<T> {
    constructor(
        public elements: T[]) {}
}

export abstract class MultiSelectComponent<T extends ObjectWithId> {

    abstract readonly SETTINGS_KEY;
    abstract readonly URL_PARAM_NAME;
    abstract readonly USE_FULLY_CHECKED_AS_DEFAULT: boolean;

    // input
    origin;
    // output
    filterChanged = new EventEmitter<MultiSelectFilter<T>>();

    items: T[];
    selected: T[];

    protected constructor(
        protected router: Router,
        protected route: ActivatedRoute,
    ) {}

    public abstract getLabel(item: T): string;

    public abstract openMenu();

    public abstract onApply();

    protected initComponent(data: T[]) {
        this.items = data;
        const itemIdsString = this.route.snapshot.queryParams[this.URL_PARAM_NAME];
        let itemIds = [];
        if (!itemIdsString) {
            itemIds = this.getCachedSelectedItemIds();
        } else {
            itemIds = itemIdsString.split(':::');
        }
        const items = this.items.filter(item => !!itemIds.find(itemId => String(item.id) === itemId));
        this.setFilter(items);
    }

    public isChecked(item: T): boolean {
        return (!this.selected && this.USE_FULLY_CHECKED_AS_DEFAULT)
            || (!!this.selected && !!this.selected.find(selected => item.id === selected.id));
    }

    public check(e: MatCheckboxChange, item: T) {
        if (e.checked) {
            if (!this.selected) {
                this.selected = [];
            }
            this.selected.push(item);
        } else {
            if (!this.selected && this.USE_FULLY_CHECKED_AS_DEFAULT) {
                this.selected = Object.assign([], this.items);
            }
            this.selected.splice(this.selected.indexOf(item), 1);
            if (this.selected.length === 0 && !this.USE_FULLY_CHECKED_AS_DEFAULT) {
                this.selected = undefined;
            }
        }
    }

    public apply() {
        this.setFilter(this.selected);
        this.onApply();
    }

    public setFilter(items: T[]) {
        this.selected = !items || items.length === 0 || (items.length === this.items.length && this.USE_FULLY_CHECKED_AS_DEFAULT)
            ? undefined
            : Object.assign([], items);
        const itemIds = !items || items.length === 0 || (items.length === this.items.length && this.USE_FULLY_CHECKED_AS_DEFAULT)
            ? undefined
            : items.map(item => item.id).join(':::');
        this.appendFilterToURL(itemIds);
        if (!!itemIds) {
            localStorage.setItem(this.getSelectedItemKey(), itemIds);
        } else {
            localStorage.removeItem(this.getSelectedItemKey());
        }
        this.filterChanged.emit(
            new MultiSelectFilter<T>(
                this.selected,
            )
        );
    }

    private appendFilterToURL(itemIdsString: string) {
        this.router.navigate([], {
            queryParams: {
                [this.URL_PARAM_NAME]: itemIdsString,
            },
            queryParamsHandling: 'merge',
        });
    }

    private getCachedSelectedItemIds(): string[] {
        const selectedItemIdsString = localStorage.getItem(
            this.getSelectedItemKey()
        );

        if (selectedItemIdsString) {
            return selectedItemIdsString.split(':::');
        }

        return [];
    }

    protected getSelectedItemKey() {
        return `${this.SETTINGS_KEY}:${this.origin}`;
    }
}
