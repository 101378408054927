<div class="switcher-wrapper">
    <div class="select-wrapper">
        <app-select-box
                [allItems]="statusLayers"
                [selectedItemKey]="statusLayerType"
                [width]="210"
                (selectedItemChanged)="onStatusLayerChange($event)"
        ></app-select-box>

        <app-select-box
                *ngIf="shouldShowActivityFilter()"
                [allItems]="activityFilters"
                [selectedItemKey]="activityFilter"
                [width]="165"
                [useCircleIcon]="true"
                (selectedItemChanged)="onActivityFilterChange($event)"
        ></app-select-box>

        <ng-container *ngIf="statusLayerType === StatusLayerType.ROUTE_STATUS && !!routeItems && routeItems.length > 0 && !!routeFilters">
            <ng-container *ngFor="let filterItems of routeFilters; let i = index">
                <ng-container *ngIf="getSelectedRouteFilter(i) as variable">
                    <app-select-box
                            [allItems]="filterItems"
                            [selectedItemKey]="variable"
                            [width]="200"
                            [useCircleIcon]="true"
                            (selectedItemChanged)="onRouteFilterChange(i, $event)"
                    ></app-select-box>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>

    <!-- legends -->
    <div class="legends">
        <!-- show route status legend all the time on Live Map Routes -->
        <div class="legend-wrapper" *ngIf="statusLayerType === StatusLayerType.ROUTE_STATUS">
            <app-route-status-mode-legend
            >
            </app-route-status-mode-legend>
        </div>

        <div class="legend-wrapper" *ngIf="statusLayerType === StatusLayerType.GPS_TRACKS">
            <app-gps-tracks-legend
            >
            </app-gps-tracks-legend>
        </div>

        <div class="legend-wrapper" *ngIf="statusLayerType === StatusLayerType.CURRENCY">
            <app-currency-legend
            >
            </app-currency-legend>
        </div>

        <div class="legend-wrapper" *ngIf="statusLayerType === StatusLayerType.COVERAGE">
            <app-coverage-legend
            >
            </app-coverage-legend>
        </div>

        <div class="legend-wrapper" *ngIf="showTraffic">
            <app-traffic-legend
            >
            </app-traffic-legend>
        </div>

        <div class="legend-wrapper" *ngIf="showRadar || showWarnings">
            <app-weather-legend
            >
            </app-weather-legend>
        </div>
    </div>
</div>






