import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {catchError, retry} from 'rxjs/operators';
import {HttpErrorHandler} from '../../http.error.handler';
import { HttpClient, HttpParams } from '@angular/common/http';
import {ImageModel, ImageWithDriverAndVehicle} from '../../shared/models/image';
import {Page} from '../../shared/models/Page';
import {JsonApiResponse} from '../../shared/models/JsonApiResponse';
import {Moment} from 'moment';
import {MultiSelectFilter} from '../../shared/components/multi-select-component';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor(private http: HttpClient) { }

  getImagesPage(from: Moment,
                to: Moment,
                vehicleGroupFilter: MultiSelectFilter<number>,
                page: number = 1, size: number = 25, sort?: string) {
    const params = {
      page: String(page),
      size: String(size),
      sort
    };

    if (!!from) {
      params['validFrom'] = from.toISOString();
    }

    if (!!to) {
      params['validTo'] = to.toISOString();
    }

    if (!!vehicleGroupFilter && !!vehicleGroupFilter.elements) {
      params['vehicleGroupIds'] = vehicleGroupFilter.elements.join(':::');
    }

    return this.http.get<JsonApiResponse<Page<ImageWithDriverAndVehicle>>>(
      `${environment.services.service}v1/image/pagedList`, { params })
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      );
  }

  getCadenceImagesPerShift(shiftId: number) {
    return this.http.get<JsonApiResponse<ImageModel[]>>(
      `${environment.services.service}v1/images/shift/${shiftId}`)
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      );
  }

  getImages(maxAge: Date = null)  {
    const url = `${environment.services.service}v1/images/list`;
    let params = new HttpParams();
    if (maxAge) {
      params = params.set('maxAge', maxAge.toISOString());
    }
    // observation images excluded
    return this.http
      .get<JsonApiResponse<ImageModel[]>>(url, {params})
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(HttpErrorHandler.handleError) // then handle the error
      );
  }

  getVideoStreamURL(vehicleId: number, timeFrom: Date, timeTo: Date) {
    let params = new HttpParams();
    if (!!timeFrom) {
      params = params.set('from', timeFrom.toISOString());
    }
    if (!!timeTo) {
      params = params.set('to', timeTo.toISOString());
    }
    return this.http.get<JsonApiResponse<string>>(
        `${environment.services.service}v1/vehicle/${vehicleId}/hls`, {params})
        .pipe(
            catchError(HttpErrorHandler.handleError) // then handle the error
        );
  }
}
