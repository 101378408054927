import { HttpResponse } from '@angular/common/http';
import Pbf from 'pbf';
import geobuf from 'geobuf';
import {Buffer} from 'buffer';

export class GeobufResponseHandler {
  static handleResponse(response: HttpResponse<ArrayBuffer>) {
    let geojson;
    if (response.status === 200) {
      geojson = geobuf.decode(new Pbf(
          Uint8Array.from(Buffer.from(response.body))
      ));
    } else {
      geojson = JSON.parse('{"type": "FeatureCollection", "features": []}');
      if (response.status !== 204) {
        console.warn(`Error getting geojson/geobuf! Status Code: ${response.status}`);
      }
    }
    return geojson;
  }
}
