import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {JsonApiResponse} from '../../shared/models/JsonApiResponse';
import {environment} from '../../../environments/environment';
import {MessageModel} from '../../pages/live-map/models/message.model';
import {HttpErrorHandler} from '../../http.error.handler';

@Injectable({
  providedIn: 'root'
})
export class VideoStreamingService {

  constructor(private http: HttpClient) {
  }

  sendVideoStreamEvent(driverId: number): Promise<JsonApiResponse<MessageModel>> {
    const endpointUrl = `${environment.services.service}v1/video-streaming`;
    return this.http.post<JsonApiResponse<MessageModel>>(endpointUrl,
      {driverId}
    ).pipe(
      catchError(HttpErrorHandler.handleError)
    ).toPromise();
  }
}
