import {LatLngModel} from './lat.lng.model';
import {MapLayerType} from './map-layer.model';

export class ConfigurationModel {
  readonly productName: string;
  readonly id: number;
  readonly customerName: string;
  readonly logoUrl: string;
  readonly driverSuffix: string;
  readonly additionalLayers: MapLayer[];
  /**
   * Number of hours for how long to keep the data.
   * If not provided then let's use 12h as default.
   */
  readonly lookBackPeriod: number;
  readonly region: LatLngModel[];
  readonly featureFlags: FeatureFlag[];
  readonly cartegraphFeatureFlags: CartegraphFeatureFlag[];
  readonly useMetricSystem: boolean;
  readonly weatherLocation: Point;
}

export class MapLayer {
  type: MapLayerType;
  name: string;
  url: string;
  configuration: string;
  routeConfigurationId: number;
  tokenRequired: boolean;

  // driven by settings
  checked = false;
}

export class FeatureFlag {
  readonly name: string;
  readonly label: string;
  readonly isEnabled: boolean;
}

export enum FeatureFlagEnum {
  AddressLookup = 'address_lookup',
  StationaryAlert = 'stationary_alert',
  RoadStatus = 'heat_layer',
  ReportDvir = 'report_dvir',
  ReportImage = 'report_image',
  ReportShift = 'report_shift',
  FleetMessaging = 'fleet_messaging',
  Poi = 'poi',
  Weather = 'weather',
  Traffic = 'traffic',
  CartegraphIntegration = 'cartegraph_integration',
  GuestDrivers = 'guest_drivers',
  DashCam = 'dashcam',
  VehicleInstallationReport = 'vehicle_installation_report'
}

export class CartegraphFeatureFlag {
  readonly feature: string;
  readonly enabled: boolean;
}

export class PublicPortalSettings {
  title: string;
  titleColor?: string;
  titleBackgroundColor?: string;
  headline: Headline[];
  contentBlocks: ContentBlock[];
  logoLink: string;
  scrollingSpeed: number;
  showVehicleMarkers: boolean;
  showVehicleLabels: boolean;
  showMapContent: boolean;
  useMapThemes: boolean;
  allowGpsTracks: boolean;
  allowTimeMap: boolean;
  allowPassCountMap: boolean;
  allowRouteStatusMap: boolean;
  excludeVehicleGroupIds: number[];
  routeStatusLayerConfig: RouteStatusLayerConfig;
  hideLeftPanel: boolean;
  hideMessageBar: boolean;
}

export class RouteStatusLayerConfig {
  static THRESHOLD_VALUES: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 21, 24, 30, 36, 48, 72];

  routeConfigurationId: number;
  servicedThreshold: number;
}

export class Headline {
  text: string;
  selected?: boolean;
  color?: string;
  backgroundColor?: string;
}

export class ContentBlock {
  title: string;
  description?: string;
  selected: boolean;
  markdown: string;
}

export class Point {
  x: number;
  y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
}
