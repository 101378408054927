import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetsManagerService } from '../../../../../data/assets/assets-manager.service';
import { ConfigurationService } from '../../../../../configuration/configuration.service';
import { RightPanel } from '../right-panel.class';
import { ShiftState } from 'src/app/shared/models/shift.model';
import { FeatureFlagEnum } from 'src/app/shared/models/configuration.model';
import { ImageModel } from '../../../../../shared/models/image';
import { ImagesManagerService } from '../../../../../data/images/images-manager.service';
import { VideoStreamingService } from '../../../../../data/video-streaming/video-streaming.service';
import { DrawerContent } from '../../../../../layouts/right-drawer/right-drawer.component';
import { Asset } from '../../../models/asset.class';
import { ActionMenuItem } from '../../../../../shared/models/action-menu-item.class';
import { VideoPlayerComponent } from '../../../../../shared/components/video-player/video-player.component';

@Component({
  selector: 'app-live-video',
  templateUrl: './live-video.component.html',
  styleUrls: ['./live-video.component.scss']
})
export class LiveVideoComponent extends RightPanel implements OnInit, OnDestroy {

  selectedVehicle: Asset;
  vehiclesWithCamera: Asset[];
  items: ActionMenuItem[];
  images: ImageModel[];

  ShiftState = ShiftState;
  FeatureFlagEnum = FeatureFlagEnum;
  streaming = false;
  firstTimeStreaming = true;

  timerIds: Map<number, NodeJS.Timeout> = new Map<number, NodeJS.Timeout>();

  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected configurationService: ConfigurationService,
    protected assetsManager: AssetsManagerService,
    private videoStreamingService: VideoStreamingService,
    private imageManager: ImagesManagerService,
  ) {
    super(
      DrawerContent.LIVE_VIDEO,
      router,
      activatedRoute,
      configurationService,
      assetsManager,
    );
  }

  ngOnInit() {
    this.initialize();
    this.loadConfiguration();

    const imageSubscription = this.imageManager.filteredImages$.subscribe(images => {
      this.images = images;
    });
    this.openSubscriptions.push(imageSubscription);
  }

  ngOnDestroy() {
    this.unsubscribe();
    this.selectedVehicle = undefined;
    this.vehiclesWithCamera = undefined;
    this.items = undefined;
    this.images = undefined;
    this.timerIds.forEach(timerId => {
      clearTimeout(timerId);
    });
    this.timerIds.clear();
  }

  hasFeatureFlag(featureFlag: string): boolean {
    return this.configuration?.featureFlags?.find(value => value.isEnabled && value.name === featureFlag) !== undefined;
  }

  onAssetsUpdate() {
    this.vehiclesWithCamera = this.assets
        .filter(asset => !!asset.cameraConfiguration && asset.shiftStatus !== ShiftState.ENDED)
        .sort((a, b) => {
          return a.name < b.name ? -1 : 1;
        });
    // the following is necessary not to refresh the component too often
    if (!this.items || this.items.length !== this.vehiclesWithCamera.length) {
      this.items = this.vehiclesWithCamera.map(vehicle => this.vehicleToActionMenuItem(vehicle));
    }
  }

  onAssetChange() {
    // do nothing
  }

  onAssetAction() {
    this.selectedVehicle = this.asset;
    this.loadImages();
  }

  onRouteAction() {
    // do nothing
  }

  onConfigurationLoad() {
    this.loadImages();
  }

  private loadImages() {
    if (this.hasFeatureFlag(FeatureFlagEnum.DashCam)) {
      this.imageManager.filterByShift(this.selectedVehicle?.shiftId);
    }
  }

  startStreaming(videoPlayerComponent: VideoPlayerComponent) {
    if (this.hasFeatureFlag(FeatureFlagEnum.DashCam) && this.selectedVehicle?.cameraConfiguration?.streaming?.captureStreamDuration > 0) {
      const captureStreamDuration = this.selectedVehicle?.cameraConfiguration?.streaming?.captureStreamDuration || 10;
      const vehicleId = this.selectedVehicle.id;
      this.streaming = true;
      this.firstTimeStreaming = false;
      this.videoStreamingService.sendVideoStreamEvent(this.selectedVehicle.driverId).then(() => {
        const timeOut = setTimeout(
          () => this.refreshVideoPlayer(videoPlayerComponent, vehicleId, captureStreamDuration),
          2000,
        );
        this.timerIds.set(vehicleId, timeOut);
      }).catch(() => {
        this.streaming = false;
        this.firstTimeStreaming = true;
      });
    }
  }

  refreshVideoPlayer(videoPlayerComponent: VideoPlayerComponent, vehicleId: number, captureStreamDuration: number) {
    videoPlayerComponent.refresh().then(isError => {
      if (isError && this.streaming) {
        const timeOut = setTimeout(
          () => this.refreshVideoPlayer(videoPlayerComponent, vehicleId, captureStreamDuration),
          1000,
        );
        this.timerIds.set(vehicleId, timeOut);
      } else {
        this.timerIds.delete(vehicleId);
        // set streaming flag
        const duration = captureStreamDuration * 1000;
        setTimeout(() => {
          this.streaming = false;
        }, (duration));
      }
    });
  }

  vehicleToActionMenuItem(vehicle: Asset) {
    return new ActionMenuItem(
        vehicle.id,
        'directions_car',
        vehicle.name,
        '',
        '',
        null,
        () => false,
        () => false,
        null,
        () => this.onVehicleClick(vehicle),
        () => null,
        () => null,
        [],
    );
  }

  private onVehicleClick(vehicle: Asset) {
    if (vehicle.id === this.selectedVehicle?.id) {
      this.selectedVehicle = undefined;
      this.loadImages();
      return;
    }
    this.selectedVehicle = vehicle;
    this.loadImages();
  }
}
