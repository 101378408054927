import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseMapType, ISettingKeyValuePair, SettingsService } from '../../../../configuration/settings.service';
import { environment } from '../../../../../environments/environment';
import { NgClass, NgForOf, NgIf, NgStyle } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-basemap-toggle',
  standalone: true,
  imports: [
    NgStyle,
    MatIcon,
    NgClass,
    NgIf,
    NgForOf
  ],
  templateUrl: './basemap-toggle.component.html',
  styleUrl: './basemap-toggle.component.scss'
})
export class BasemapToggleComponent implements OnInit, OnDestroy {

  switcherOpen = false;
  baseMapLayerType: string;

  baseMaps: BaseMapType[] = [
    BaseMapType.WINTER,
    BaseMapType.DARK,
    BaseMapType.IMAGERY,
    BaseMapType.OUTDOORS,
  ];
  BaseMapType = BaseMapType;

  private readonly openSubscriptions = Array<Subscription>();

  constructor(
    private settingsService: SettingsService,
  ) {
  }

  ngOnInit() {
    const settingsSubscription =
      this.settingsService.settingsChangedObservable.subscribe(
        (keyValuePair: ISettingKeyValuePair) => {
          this.onSettingsChanged(keyValuePair.key, keyValuePair.value);
        }
      );
    this.openSubscriptions.push(settingsSubscription);
  }

  ngOnDestroy() {
    this.openSubscriptions.forEach(subscription => subscription?.unsubscribe());
  }

  private onSettingsChanged(key: string, value: any) {
    switch (key) {
      case SettingsService.BASE_MAP_LAYER_KEY:
        this.baseMapLayerType = value;
        break;
      default: // other layers in configuration
        break;
    }
  }

  toggleVisibility() {
    this.switcherOpen = !this.switcherOpen;
  }

  closeSwitcher() {
    this.switcherOpen = false;
  }

  switchBaseMap(baseMapType: BaseMapType) {
    this.settingsService.setStringValue(
      SettingsService.BASE_MAP_LAYER_KEY,
      baseMapType,
    );
  }

  baseMapTypeBackground(baseMapType: BaseMapType) {
    // if class defined in SCSS then there is a problem with base_href on AWS env
    // this is a workaround where JS knows about base_href
    const basePath = environment.base_href;
    switch (baseMapType) {
      case BaseMapType.OUTDOORS:
        return {
          'background-image': `url(\'${basePath}assets/map_thumbnails/outdoor_thumb.png\')`,
        };
      case BaseMapType.DARK:
        return {
          'background-image': `url(\'${basePath}assets/map_thumbnails/darkgrey_thumb.png\')`,
        };
      case BaseMapType.IMAGERY:
        return {
          'background-image': `url(\'${basePath}assets/map_thumbnails/satellite_thumb.png\')`,
        };
      case BaseMapType.WINTER:
        return {
          'background-image': `url(\'${basePath}assets/map_thumbnails/winter_thumb.png\')`,
        };
      default: {
        return {};
      }
    }
  }
}
