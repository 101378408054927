import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject, Subscription } from 'rxjs';
import { FeatureCollection, GeoJSON, Point } from 'geojson';
import { ServicesSocketService } from '../websocket/services-socket.service';
import { LocationSocketService } from '../websocket/location-socket.service';
import { JsonApiResponse } from '../../shared/models/JsonApiResponse';
import { VehicleLocationUpdate } from '../../shared/models/vehicle-breadcrumb';
import { LatLngModel } from '../../shared/models/lat.lng.model';
import { VehiclesManagerService } from '../vehicles/vehicles-manager.service';
import {
    LocationEventData,
    MessageSource,
    ShiftEventData,
    ShiftEventType,
    WebSocketEvent
} from '../websocket/model/message.event';
import { ShiftState } from '../../shared/models/shift.model';
import { MessageRecipientCollectionModel } from '../../pages/live-map/models/message-recipient-collection.model';
import { ShiftsService } from '../shifts/shifts.service';
import { LiveMapDataService } from '../../pages/live-map/services/live-map-data.service';
import { ToastService } from '../../shared/services/toast.service';
import { MessagesService } from '../messages/messages.service';
import { LocationApiService } from '../location-api/location-api.service';

@Injectable({
    providedIn: 'root'
})
export class ServerEventService {

    private isInitialized = false;
    private readonly openSubscriptions = Array<Subscription>();

    readonly currentLocationsObservable = new BehaviorSubject<GeoJSON>(JSON.parse('{"type": "FeatureCollection", "features": []}'));
    readonly historyLocationsObservable = new BehaviorSubject<GeoJSON>(JSON.parse('{"type": "FeatureCollection", "features": []}'));

    // handle derived layers source updated
    readonly derivedLayerCacheUpdateObservable = new Subject<Date>();
    // handle tile cache updated
    readonly tilesCacheUpdateObservable = new Subject<Date>();

    constructor(private locationApi: LocationApiService,
                private serviceSocketService: ServicesSocketService,
                private locationSocketService: LocationSocketService,
                private vehiclesManager: VehiclesManagerService,
                private shiftsService: ShiftsService,
                private liveMapDataService: LiveMapDataService,
                private messagesService: MessagesService,
                private toast: ToastService,
    ) { }

    public init() {
        if (this.isInitialized) {
            throw Error('The ServerEventService has already been initialized.');
        }
        this.connectToManager();

        this.isInitialized = true;
    }

    public release() {
        if (!this.isInitialized) {
            return;
        }

        for (const subscription of this.openSubscriptions) {
            subscription.unsubscribe();
        }
        this.openSubscriptions.length = 0;
        this.isInitialized = false;
    }

    private connectToManager() {
        // initialize currentLocation, track heads
        this.findCurrentLocation();
        this.findLocationHistory();

        const servicesSubscription = this.serviceSocketService
          .onMessage(MessageSource.SHIFT)
          .subscribe((message: WebSocketEvent<ShiftEventType, ShiftEventData>) => {
              this.handleShiftEvent(message);
          });
        this.openSubscriptions.push(servicesSubscription);

        // update recent messages when a message is read
        const messageReadSub = this.serviceSocketService
          .onMessage(MessageSource.MESSAGE)
          .subscribe((event) => {
              this.liveMapDataService.handleMessageRead(event);
          });
        this.openSubscriptions.push(messageReadSub);

        // subscribe to location cache change event
        const currentLocationSubscription = this.locationSocketService
            .onMessage(MessageSource.TRACK_GEOMETRY_HEAD_CACHE)
            .subscribe((e: WebSocketEvent<string, LocationEventData>) => {
                this.findCurrentLocation();
                this.findLocationHistory();
            });
        this.openSubscriptions.push(currentLocationSubscription);

        // subscribe to geometry cache updates
        const derivedLayerCacheEventSubscription = this.locationSocketService
            .onMessage(MessageSource.DERIVED_LAYER_CACHE)
            .subscribe((e: WebSocketEvent<string, LocationEventData>) => {
                const date = new Date(e.data.updated * 1000);
                this.handleDerivedLayerCacheUpdate(date);
                console.log(`Derived layer data updated: ${date}`);
            });
        this.openSubscriptions.push(derivedLayerCacheEventSubscription);

        // subscribe to tile cache refresh
        const tileCacheEventSubscription = this.locationSocketService
            .onMessage(MessageSource.VECTOR_TILE_CACHE)
            .subscribe((e: WebSocketEvent<string, LocationEventData>) => {
                this.handleTilesCacheUpdate();
                console.log(`Tile cache updated, updated: ${new Date(e.data.updated * 1000)}`);
            });
        this.openSubscriptions.push(tileCacheEventSubscription);
    }

    private handleShiftEvent(shiftEvent: WebSocketEvent<ShiftEventType, ShiftEventData>) {
        // reload message recipients
        setTimeout(() => this.loadMessageRecipients(), 2000);

        // update shift information
        this.shiftsService
          .getShiftInfo(shiftEvent.data.shiftId)
          .toPromise()
          .then((response) => {
              const data = response.data;
              const vehicleName = !!data.vehicle.label ? data.vehicle.label : data.vehicle.name;
              let message: string;
              switch (shiftEvent.eventType) {
                  case ShiftEventType.START:
                      this.liveMapDataService.sendShiftInfo(data);
                      message = `Driver "${data.driver.name}" has just started his shift with "${vehicleName}".`;
                      break;
                  case ShiftEventType.UPDATE:
                      switch (shiftEvent.data.shiftState) {
                          case ShiftState.NORMAL:
                              message = `Vehicle "${vehicleName}" is back in normal state`;
                              break;
                          case ShiftState.STATIONARY:
                              message = `Vehicle "${vehicleName}" is stationary`;
                              break;
                          case ShiftState.PAUSED:
                              message = `Vehicle "${vehicleName}" is paused`;
                              break;
                      }
                      break;
                  case ShiftEventType.END:
                      this.liveMapDataService.sendShiftInfo(data);
                      message = `Driver "${data.driver.name}" has just ended his shift with "${vehicleName}".`;
                      break;
                  default:
              }
              this.toast.long(message);
          })
          .catch((error) => {
              console.log(error);
          });
    }

    private loadMessageRecipients() {
        this.messagesService
          .getRecipients()
          .then((response: JsonApiResponse<MessageRecipientCollectionModel>) => {
              this.liveMapDataService.sendAvailableRecipients(response.data);
          });
    }

    private findLocationHistory() {
      this.locationApi.getLocationHistoryTrackHeads().then(response => {
        this.handleLocationHistoryUpdate(response);
      }).catch(message => {
        console.log(message);
      });
    }

    private handleLocationHistoryUpdate(geojson: GeoJSON) {
        this.historyLocationsObservable.next(geojson);
    }

    private findCurrentLocation() {
        this.locationApi.getCurrentLocationPoints().then(response => {
            this.handleCurrentLocationUpdate(response);
        }).catch(message => {
            console.log(message);
        });
    }

    private handleCurrentLocationUpdate(geojson: GeoJSON) {
        // update observable - map markers
        this.currentLocationsObservable.next(geojson);

        // update location in vehicle manager - used to display in Asset detail
        const features = (geojson as FeatureCollection).features;
        const vehicleLocations = features.map(feature => {
            const prop = feature.properties;
            const coord = (feature.geometry as Point).coordinates;
            return new VehicleLocationUpdate(
                feature.properties.locationsourceid,
                {
                    id: prop.id,
                    coords: new LatLngModel(coord[1], coord[0]),
                    time: prop.unixtime,
                    speed: prop.speed,
                    heading: prop.heading,
                    flags: prop.flags,
                    gpsSource: prop.gpssource,
                }
            );
        });
        this.vehiclesManager.updateVehicleLocation(vehicleLocations);
    }

    private handleDerivedLayerCacheUpdate(date: Date) {
        this.derivedLayerCacheUpdateObservable.next(date);
    }

    private handleTilesCacheUpdate() {
        this.tilesCacheUpdateObservable.next(new Date());
    }
}
