<div class="live-map-container" *ngIf="!isLoading">

  <div #mapContent class="map__content">
    <app-map-panel
    ></app-map-panel>
  </div>

  <div
    class="live__left__content"
    [ngClass]="{ collapse: (sidenavOpen$ | async) === false }"
  >
    <div class="left__panel">
      <app-live-map-left-panel
              *ngIf="configuration && mapComponent && mapComponent.mapLayersManager"
              [configuration]="configuration"
              (activeItemChanged)="onActiveItemChanged($event)"
      >
      </app-live-map-left-panel>
    </div>

    <div class="left__panel__round-corners">
      <div class="left__panel__round-corners__box">
      </div>
    </div>

    <div class="left__map__overlay"
    >
      <div class="overlay-top-content">
        <app-status-layer-switcher
                *ngIf="mapComponent && mapComponent.mapLayersManager"
                [mapLayersManager]="mapComponent.mapLayersManager"
        >
        </app-status-layer-switcher>
      </div>

      <div class="overlay-bottom-left-content">
        <app-map-layer-switcher
                *ngIf="mapComponent && mapComponent.mapLayersManager"
                [mapLayersManager]="mapComponent.mapLayersManager"
        >
        </app-map-layer-switcher>
      </div>

    </div>
  </div>

  <div class="live__right__content"
  >
    <div class="right__map__overlay"
         [ngClass]="{ collapse: (sidenavOpen$ | async) === false,
                      rightDrawer: !!activeDrawer,
                      fullMapOverlay: showMapOverlay === MapOverlayType.FULL,
                      narrowMapOverlay: showMapOverlay === MapOverlayType.NARROW,
                      }"
         *ngIf="showMapOverlay !== MapOverlayType.NONE"
    >
      <router-outlet></router-outlet>
    </div>

    <div class="right__panel__round-corners">
      <div class="right__panel__round-corners__box">
      </div>
    </div>

    <div class="right__panel"
    >
      <div class="right__panel__quick__actions">
        <app-right-panel-button
                [drawerType]="DrawerType.ROUTE"
        >
        </app-right-panel-button>
        <app-right-panel-button
                *ngIf="hasFeatureFlag(FeatureFlagEnum.FleetMessaging)"
                [drawerType]="DrawerType.MESSAGES"
        >
        </app-right-panel-button>
        <app-right-panel-button
                [drawerType]="DrawerType.OBSERVATION"
        >
        </app-right-panel-button>
        <app-right-panel-button
                *ngIf="hasFeatureFlag(FeatureFlagEnum.DashCam)"
                [drawerType]="DrawerType.LIVE_VIDEO"
        >
        </app-right-panel-button>
        <app-right-panel-button
                [drawerType]="DrawerType.ALERTS"
        >
        </app-right-panel-button>
        <app-right-panel-button
                [drawerType]="DrawerType.QUICK_INSIGHTS"
        >
        </app-right-panel-button>
      </div>
      <div class="right__panel__bottom__actions">
        <button mat-icon-button
                [routerLink]="['/' + RootRoute.MAIN, MainRoute.ABOUT]"
                matTooltip="About"
                matTooltipPosition="left">
          <mat-icon>info</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="live__bottom__panel">
  </div>
</div>

<div class="loader-container" *ngIf="isLoading">
  <app-live-map-loader
    [message]="loadingDataName"
    [loadErrorMessage]="loadError"
  >
  </app-live-map-loader>
</div>
