import {Component, ViewChild} from '@angular/core';
import {EditUserComponent} from '../../edit-user/edit-user.component';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import {MatProgressBar} from '@angular/material/progress-bar';
import {UserManagementModule} from '../../user-management.module';
import {MatButton} from '@angular/material/button';

@Component({
  standalone: true,
  selector: 'app-add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatProgressBar,
    UserManagementModule,
    MatButton,
    MatDialogClose,
    EditUserComponent
  ],
  styleUrl: './add-user-dialog.component.scss'
})
export class AddUserDialogComponent {
  isWorking = false;

  @ViewChild('editUserComponent')
  editUserComponent: EditUserComponent

  createNewUserConfig = EditUserComponent.getCreateNewUserParams();

  constructor(
    private dialogRef: MatDialogRef<AddUserDialogComponent>,
  ) {
  }

  isSaveButtonEnabled() {
    return this.editUserComponent?.canBeSaved();
  }

  performSave() {
    this.isWorking = true;
    const disableCloseStateToRestore = this.dialogRef.disableClose;
    this.dialogRef.disableClose = true;

    this.editUserComponent.save()
      .then((result) => {
        this.dialogRef.close(result);
      })
      .catch((saveError) => {
        console.warn('user save error', saveError);
      })
      .finally(() => {
        this.isWorking = false;
        this.dialogRef.disableClose = disableCloseStateToRestore;
      });
  }
}
