<button
  mat-icon-button
  (click)="onClick()"
  [matTooltip]="'Filter'"
  [ngClass]="{'ops-selectable-secondary': active}"
>
  <mat-icon>tune</mat-icon>

  <div class="applied-filter-count"
       *ngIf="!active && appliedFiltersCount > 0"
  >
    <span>{{ appliedFiltersCount }}</span>
  </div>
</button>
