import {Component} from '@angular/core';
import {MapStyles} from '../../../../configuration/map-styles';
import {SettingsService} from '../../../../configuration/settings.service';
import {MatDialog} from '@angular/material/dialog';
import {
  RouteStatusConfig,
  RouteStatusMapConfigComponent
} from '../route-status-map-config/route-status-map-config.component';

@Component({
  selector: 'app-route-status-mode-legend',
  templateUrl: './route-status-mode-legend.component.html',
  styleUrls: ['./route-status-mode-legend.component.scss']
})
export class RouteStatusModeLegendComponent {

  MapStyles = MapStyles;

  constructor(
      public dialog: MatDialog,
      private settingsService: SettingsService,
  ) {}

  openConfiguration() {
    const data = {
      threshold: +this.settingsService.getStringValue(SettingsService.ROUTE_STATUS_THRESHOLD),
    } as RouteStatusConfig;

    const dialogRef = this.dialog.open(RouteStatusMapConfigComponent, {
      maxWidth: '80vw',
      maxHeight: '85vh',
      width: '500px',
      height: '300px',
      data: Object.assign({}, data),
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined && result) {
        const settings = result as RouteStatusConfig;
        if (settings.threshold !== data.threshold) {
          this.settingsService.setStringValue(SettingsService.ROUTE_STATUS_THRESHOLD, String(settings.threshold));
        }
      }
    });
  }
}
