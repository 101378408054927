import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {VehicleData} from '../edit-vehicle/vehicle.types';

@Component({
  selector: 'app-dialog-confirm-delete-vehicle',
  templateUrl: 'dialog-confirm-delete-vehicle.html',
})
export class DialogConfirmDeleteVehicleComponent {
  dialogBody: string = null;

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmDeleteVehicleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: VehicleData,
  ) {
    this.populateDialogBody();
  }

  private populateDialogBody() {
    this.dialogBody = `
      <p>
        Are you sure you want to delete vehicle <strong>${this.data.name}</strong>?
      </p>`;
  }
}
