import {Component} from '@angular/core';
import {MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle} from '@angular/material/dialog';

import {CartegraphManagementService} from '../../../../../../data/cartegraph/cartegraph-management.service';
import {ImportResultRow} from '../../../../../../shared/models/cartegraph.model';
import {JsonApiResponse} from '../../../../../../shared/models/JsonApiResponse';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle
} from '@angular/material/expansion';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable
} from '@angular/material/table';
import {FormsModule} from '@angular/forms';
import {MatCheckbox} from '@angular/material/checkbox';
import {NgForOf, NgIf} from '@angular/common';
import {MatButton} from '@angular/material/button';
import {MatStepper} from '@angular/material/stepper';
import {MatProgressBar} from '@angular/material/progress-bar';


@Component({
  standalone: true,
  selector: 'app-import-users-cartegraph-dialog',
  templateUrl: './import-users-cartegraph-dialog.component.html',
  styleUrls: [],
  imports: [
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatTable,
    MatHeaderCellDef,
    MatCellDef,
    FormsModule,
    MatCheckbox,
    MatColumnDef,
    MatHeaderCell,
    MatCell,
    MatHeaderRow,
    MatRow,
    MatDialogTitle,
    MatDialogContent,
    NgForOf,
    NgIf,
    MatHeaderRowDef,
    MatRowDef,
    MatButton,
    MatDialogActions,
    MatDialogClose,
    MatStepper,
    MatProgressBar
  ]
})
export class ImportUsersCartegraphDialogComponent {

  uiError: string;
  ImportDriversStateEnum = ImportDriversStateEnum; // allow access from template via variable
  currentState = ImportDriversStateEnum.NOOP;
  importKeys: { name: string, label: string }[] = [
    {name: 'added', label: 'Added'},
    {name: 'activated', label: 'Updated or reactivated'},
    {name: 'deactivated', label: 'Deactivated'},
    {name: 'skipped', label: 'Skipped'},
    {name: 'error', label: 'Errors'}];
  importResult: { key: string; list: ImportResultRow[] };

  constructor(public dialog: MatDialog,
              private cartegraphService: CartegraphManagementService) {
  }

  doImport(): Promise<JsonApiResponse<{ key: string, list: ImportResultRow[] }>> {
    this.currentState = ImportDriversStateEnum.WORKING;
    const apiPromise = this.cartegraphService.importDrivers();
    apiPromise
      .then((response) => {
        if (response.error) {
          this.uiError = response.error;
          this.currentState = ImportDriversStateEnum.NOOP;
        } else {
          this.importResult = response.data;
          this.currentState = ImportDriversStateEnum.IMPORTED;
        }
      }).catch(error => {
      this.uiError = error;
      this.currentState = ImportDriversStateEnum.NOOP;
    });

    return apiPromise;
  }

}

enum ImportDriversStateEnum {
  NOOP,
  WORKING,
  IMPORTED
}
