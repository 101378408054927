@if (sections.length > 1 || sections[0].groups.length > 1 || sections[0].groups[0].questions.length > 0) {
	<form>
		@for (section of sections; track (section.id || 'idx_' + sidx); let sidx = $index) {
			<app-card class="dvir-section" [preCardTitle]="sections.length > 1 ? 'Section' : (sections[0].groups.length > 1 ? 'Question Groups' : 'Questions')">
				@if (sections.length > 1) {
					<div *appCardContent class="dvir-section-header settings-header-container mb-spacing">
						<div class="settings-header-title">
							<mat-form-field appearance="outline"
											class="w-100"
											subscriptSizing="dynamic">
								<mat-label>Section Title</mat-label>
								<input matInput
									   [(ngModel)]="section.title"
									   [name]="'S' + sidx"
									   required>
							</mat-form-field>
						</div>
						<div class="settings-header-actions">
							<button mat-stroked-button
									(click)="removeSection(section)">
								Delete Section
							</button>
						</div>
					</div>
				}

				@for (group of section.groups; track gidx; let gidx = $index) {
					<div *appCardContent class="dvir-group">
						@if (section.groups.length > 1) {
							<div class="settings-header-container dvir-group-header mb-spacing"
								 [class.mt-spacing]="gidx > 0 || sections.length > 1">
								<div class="settings-header-title">
									<mat-form-field appearance="outline"
													class="w-100"
													subscriptSizing="dynamic">
										<mat-label>Group Title</mat-label>
										<input matInput
											   [(ngModel)]="group.title"
											   [name]="'S' + sidx + 'G' + gidx"
											   required>
									</mat-form-field>
								</div>
								<div class="settings-header-actions">
									<button mat-stroked-button
											(click)="removeGroup(section, group)">
										Delete Group
									</button>
								</div>
							</div>
						} @else {
							<div class="mt-15"></div>
						}
						<div class="dvir-questions">
							@for (question of group.questions; track qidx; let qidx=$index) {
								<div class="dvir-question flex-row align-items-center">
									<div class="dvir-question-number">
										Q{{qidx + 1}}
									</div>
									<div class="dvir-question-content flex-grow-1">
										@switch (question.type) {
											@case (DvirInspectionQuestionDataType.TEXT) {
												<div class="flex-row">
													<mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic">
														<mat-label>Question</mat-label>
														<input matInput
															   [(ngModel)]="question.title"
															   [name]="'S' + sidx + 'G' + gidx + 'Q'+qidx"
															   autofocus
															   required>
													</mat-form-field>
													<mat-checkbox [(ngModel)]="question.notApplicable"
																  [name]="'S' + sidx + 'G' + gidx + 'Q'+qidx+'M'">
														Answer Is Optional
													</mat-checkbox>
												</div>
											}
											@default {
												<div class="flex-column my-10">
													<mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic">
														<mat-label>Title</mat-label>
														<input matInput
															   [(ngModel)]="question.title"
															   [name]="'S' + sidx + 'G' + gidx + 'Q'+qidx"
															   autofocus
															   required>
													</mat-form-field>
													<div class="flex-row">
														<mat-form-field appearance="outline" color="accent" subscriptSizing="dynamic"
																		class="provider-select">
															<mat-label>Provider</mat-label>
															<mat-select required
																		[(ngModel)]="question.valueProvider.type"
																		(ngModelChange)="questionProviderChanged(question)"
																		[name]="'S' + sidx + 'G' + gidx + 'Q'+qidx+'P'">
																@for(provider of providers; track provider.id) {
																	<mat-option [value]="provider.id">
																		{{provider.label}}
																	</mat-option>
																}
															</mat-select>
														</mat-form-field>
														<mat-form-field appearance="outline" color="accent" subscriptSizing="dynamic"
																		class="flex-grow-1">
															<mat-label>Type</mat-label>
															<mat-select required
																		[(ngModel)]="question.valueProvider.config"
																		(ngModelChange)="questionTypeChanged(question)"
																		[name]="'S' + sidx + 'G' + gidx + 'Q'+qidx+'T'">
																@for(type of configs[question.valueProvider.type]; track type.id) {
																	<mat-option [value]="type.id">
																		{{type.label}}
																	</mat-option>
																}
															</mat-select>
														</mat-form-field>
														<mat-form-field appearance="outline" color="accent" subscriptSizing="dynamic">
															<mat-label>Default Value</mat-label>
															<mat-select [(ngModel)]="question.valueProvider.defaultValue"
																		[name]="'S' + sidx + 'G' + gidx + 'Q'+qidx+'D'">
																<mat-option>- Not set -</mat-option>
																@for(option of getQuestionTypeValues(question); track option.id) {
																	<mat-option [value]="option.id">
																		{{option.label}}
																	</mat-option>
																}
															</mat-select>
														</mat-form-field>
													</div>
												</div>
											}
										}
									</div>
									<div class="dvir-question-actions flex-row">
										<button mat-icon-button [matMenuTriggerFor]="questionTypeMenu"
												[matTooltip]="'Click to change type. Currently is of type ' + (question.type === DvirInspectionQuestionDataType.DROPDOWN ? 'choice from a dropdown' : 'Text')">
											@switch (question.type) {
												@case (DvirInspectionQuestionDataType.TEXT) {
													<mat-icon>article</mat-icon>
												}
												@case (DvirInspectionQuestionDataType.DROPDOWN) {
													<mat-icon>list</mat-icon>
												}
											}
										</button>
										<mat-menu #questionTypeMenu="matMenu">
											@if (question.type !== DvirInspectionQuestionDataType.TEXT) {
												<button mat-menu-item
														(click)="changeQuestionType(question, DvirInspectionQuestionDataType.TEXT)">
													<mat-icon>article</mat-icon> Text
												</button>
											}
											@if (question.type !== DvirInspectionQuestionDataType.DROPDOWN) {
												<button mat-menu-item
														(click)="changeQuestionType(question, DvirInspectionQuestionDataType.DROPDOWN)">
													<mat-icon>list</mat-icon> Dropdown
												</button>
											}
										</mat-menu>
										<button mat-icon-button
												matTooltip="Remove this question"
												(click)="removeQuestion(section, group, question)">
											<mat-icon>delete</mat-icon>
										</button>
									</div>
								</div>
							}
							<div class="mb-spacing">
								<button mat-stroked-button
										(click)="addQuestion(group)">
									@if (section.groups.length > 1) {
										Add Question To Group
									} @else {
										Add Question
									}
								</button>
							</div>
						</div>
					</div>
				} <!-- group -->
				<div *appCardContent class="mt-spacing">
					<button mat-stroked-button
							(click)="addGroup(section)">
						Add Group To Section
					</button>
				</div>
			</app-card>
		} <!-- section -->
	</form>
	@if (sections.length > 1) {
		<div class="mb-25">
			<button mat-stroked-button
					(click)="addSection()">
				Add New Section
			</button>
		</div>
	}
} <!-- should show questions -->
