<div class="ops-action-menu-item ops-selectable-primary noselect"
     [ngClass]="{ 'active': input.activePredicate(), 'inactive': !!input.inactivePredicate && input.inactivePredicate(), 'small': small }"
     (click)="input.onClick()"
     (mouseenter)="!!input.onHoverOn && input.onHoverOn()"
     (mouseleave)="!!input.onHoverOff && input.onHoverOff()"
>
    <div class="ami-icon-and-title"
         [ngClass]="{ 'text-primary': !input.activePredicate() && (!input.inactivePredicate || (!!input.inactivePredicate && !input.inactivePredicate())) }"
    >
        <mat-icon class="ami-icon"
                  [ngClass]="{'small': small}"
        >
            {{ input.icon }}
        </mat-icon>
        <div class="ami-title"
             [ngClass]="{ 'hidden': !!input.hiddenPredicate && input.hiddenPredicate()}"
        >
            {{ input.title }}
        </div>
    </div>
    <div class="ami-subtitle-and-action">
        <div class="ami-subtitle"
             *ngIf="!!input.subtitle"
             [ngClass]="{ 'text-primary': !input.activePredicate() && (!input.inactivePredicate || (!!input.inactivePredicate && !input.inactivePredicate())) }"
        >
            {{ input.subtitle }}
        </div>
        <div class="ami-action-icon"
             *ngIf="!input.subtitle && !!input.actionIcon">
            <mat-icon>
                {{ input.actionIcon }}
            </mat-icon>
        </div>
        <button
                *ngIf="!!input.subMenus && input.subMenus.length > 0"
                class="ami-action-button"
                [matMenuTriggerFor]="menu"
                (click)="$event.stopPropagation()"
                mat-icon-button
        >
            <mat-icon class="ami-action-icon">more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <ng-container *ngFor="let subMenu of input.subMenus" >
                <button mat-menu-item (click)="subMenu.onClick()">
                    <mat-icon>{{ subMenu.icon }}</mat-icon>
                    <span>{{ subMenu.label }}</span>
                </button>
            </ng-container>
        </mat-menu>
    </div>
</div>
