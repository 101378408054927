<div class="config-header">
    <h2 mat-dialog-title>Modify Route Status Map</h2>

    <div class="config-header-action">
        <button mat-icon-button
                [matTooltip]="'Reset to defaults'"
                (click)="reset()"
                [disabled]="isDefault()"
        >
            <mat-icon>restart_alt</mat-icon>
        </button>
    </div>
</div>

<mat-dialog-content class="mat-typography">

    <div class="config-line">
        <mat-slider min="1"
                    max="24"
                    step="1"
                    showTickMarks
                    discrete
                    [displayWith]="formatLabel(thresholdValues)"
                    color="accent"
                    class="threshold-slider"
        >
            <input matSliderThumb
                   [(ngModel)]="thresholdIndex"
                   (change)="onThresholdChange($event)"
            >
        </mat-slider>
    </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button color="accent" [mat-dialog-close]="data" cdkFocusInitial>Save</button>
</mat-dialog-actions>
