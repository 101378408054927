import {Component, ElementRef, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {VehiclesService} from '../../../../data/vehicles/vehicles.service';
import {VehicleImport} from '../../../../shared/models/vehicle-import';
import {JsonApiResponse} from '../../../../shared/models/JsonApiResponse';
import {MatStepper} from '@angular/material/stepper';
import {AddOrImportVehicleDialogForm, AddOrImportVehicleResult} from './edit-vehicle/vehicle.types';


@Component({
    selector: 'app-import-vehicles',
    templateUrl: './import-vehicles.component.html',
    styleUrls: ['./import-vehicles.component.scss'],
})
export class ImportVehiclesComponent implements AddOrImportVehicleDialogForm {

    @ViewChild('stepper') stepper: MatStepper;

    uiError: string;

    selectedFile: File;
    validData: VehicleImport[];
    invalidData: VehicleImport[];
    displayedColumnsValid: string[];
    displayedColumnsInvalid: string[];
    allColumns: {
        title: string;
        valid: boolean;
        invalid: boolean;
    }[] = [
        {title: 'line', valid: true, invalid: true},
        {title: 'name', valid: true, invalid: true},
        {title: 'mapLabel', valid: true, invalid: true},
        {title: 'licensePlate', valid: true, invalid: true},
        {title: 'vGroup', valid: true, invalid: true},
        {title: 'action', valid: true, invalid: false},
        {title: 'errors', valid: false, invalid: true},
    ];
    ImportVehiclesStateEnum = ImportVehiclesStateEnum;
    currentState = ImportVehiclesStateEnum.WAITING_FOR_FILE;
    showCsvHelp = false;

    @ViewChild('fileInput') fileInput: ElementRef;

    constructor(public dialog: MatDialog,
                private vehicleService: VehiclesService) {

        this.updateColumns();
    }

    handleFileInput(event: Event) {
        this.selectedFile = (event.target['files'] as FileList)[0];
        this.upload();
    }

    private upload() {
        this.currentState = ImportVehiclesStateEnum.UPLOADING_FILE;
        const formData: FormData = new FormData();
        formData.append('file', this.selectedFile, this.selectedFile.name);
        this.vehicleService.validateVehicleImport(formData).then(response => {
            this.validData = response.data.filter(row => row.errors == null || row.errors.length === 0);
            this.invalidData = response.data.filter(row => row.errors != null && row.errors.length > 0);
            this.currentState = ImportVehiclesStateEnum.UPLOAD_SUCCEEDED;
        }).catch(error => {
            this.uiError = error;
            this.currentState = ImportVehiclesStateEnum.UPLOAD_FAILED;
        });
    }

    hasValidRowsToImport(): boolean {
        const rowsToImport = this.rowsToImport();
        return !!rowsToImport && rowsToImport.length > 0;
    }

    rowsToImport(): VehicleImport[] {
        return this.validData;
    }

    canBeSaved(): boolean {
        return this.stepper.selectedIndex === 2
            && this.hasValidRowsToImport();
    }

    save(): Promise<AddOrImportVehicleResult> {
        return new Promise((resolve, reject) => {
            this.doImport()
                .then((result) => {
                    const saveResult: AddOrImportVehicleResult = {
                        type: 'vehicleImport',
                        data: result.data,
                    };
                    resolve(saveResult);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    private doImport(): Promise<JsonApiResponse<any>> {
        this.currentState = ImportVehiclesStateEnum.IMPORTING;

        const apiCallPromise = this.vehicleService.importVehicles(this.validData);
        apiCallPromise
            .then((response) => {
                this.currentState = ImportVehiclesStateEnum.IMPORT_SUCCEEDED;
            })
            .catch(error => {
                this.uiError = error;
                this.currentState = ImportVehiclesStateEnum.IMPORT_FAILED;
            });

        return apiCallPromise;
    }

    private updateColumns() {
        this.displayedColumnsValid = this.allColumns
            .filter(col => col.valid === true)
            .map(col => col.title);

        this.displayedColumnsInvalid = this.allColumns
            .filter(col => col.invalid === true)
            .map(col => col.title);
    }

}

enum ImportVehiclesStateEnum {
    WAITING_FOR_FILE,
    UPLOADING_FILE,
    UPLOAD_FAILED,
    UPLOAD_SUCCEEDED,
    IMPORTING,
    IMPORT_FAILED,
    IMPORT_SUCCEEDED
}
