import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {SecurityService} from '../../security/security.service';
import {filter} from 'rxjs/operators';
import {WebSocketEvent, MessageSource} from './model/message.event';
import {Hub} from 'aws-amplify/utils';

@Injectable()
export abstract class WebSocketClientService {

  private initialized = false;
  private expectedConnected = false;
  private socket: WebSocket;

  connectionStatusObservable = new BehaviorSubject<boolean>(false);
  private messageSubject: Subject<WebSocketEvent<any, any>> = new Subject<WebSocketEvent<any, any>>();
  private idToken: string;

  protected constructor(
      private securityService: SecurityService,
  ) {
  }

  public init(): Promise<boolean> {
    this.expectedConnected = true;

    Hub.listen('auth', ({ payload }) => {
      switch (payload.event) {
        case 'tokenRefresh':
          console.log('Auth tokens have been refreshed.');
          this.securityService.getIdToken()
              .then(idToken => {
                this.idToken = idToken;
              })
              .catch(error => {
                console.error(error);
              });
          break;
      }
    });

    return new Promise((resolve, reject) => {
      if (this.initialized) {
        const message = 'Web Socket is already initialized! ' + this.getEndpointBaseUrl();
        console.error(message);
        reject(message);
      }

      this.securityService.getIdToken().then(idToken => {
        this.idToken = idToken;
        this.connect();
        resolve(true);
      });
    });
  }

  private convertWebSocketEventData(serializedData: string): WebSocketEvent<any, any>[] {
    try {
      return JSON.parse(serializedData) as WebSocketEvent<any, any>[];
    } catch (error) {
      console.error('Error parsing message:', error);
      return null;
    }
  }

  private connect() {
    this.socket = new WebSocket(this.getWebSocketEndPoint(this.idToken));
    this.socket.onmessage = (event: MessageEvent<string>) => {
      const messages = this.convertWebSocketEventData(event.data);
      messages.forEach((message) => this.messageSubject.next(message));
    };

    // TODO keep-alive

    this.socket.onopen = (ev: Event) => this.onConnect(ev);
    this.socket.onerror = (ev: Event) => this.onError(ev);
    this.socket.onclose = (ev: Event) => this.onClose(ev);

    this.initialized = true;
  }

  public release() {
    this.expectedConnected = false;
    if (!this.initialized) {
      console.error('Trying to release Web Socket which is not initialized! ' + this.getEndpointBaseUrl());
      return;
    }
    if (this.socket) {
      this.socket.close();
    }

    console.log('Releasing web socket ' + this.getEndpointBaseUrl());
    this.initialized = false;
  }

  abstract getEndpointBaseUrl(): string | null;

  private getWebSocketEndPoint(token: string): string {
    const endpointBaseUrl = this.getEndpointBaseUrl();
    if (!endpointBaseUrl.startsWith('ws')) {
      throw new Error('Invalid web socket url. Expecting starting with ws!');
    }
    return endpointBaseUrl + `?Authorization=${token}`;
  }

  private onConnect(event: Event) {
    this.connectionStatusObservable.next(true);
  }

  private onError(event: Event) {
    console.warn('Error on websocket occurred...');
    console.log(event);
    this.connectionStatusObservable.next(false);
  }

  private onClose(event: Event) {
    this.initialized = false;
    this.connectionStatusObservable.next(false);
    if (this.expectedConnected) {
      console.log('WebSocket connection closed. Reconnecting in 5 seconds...');
      setTimeout(() => {
        this.connect();
      }, 5000); // Reconnect after 5 seconds
    }
  }

  private onDebug(message: string) {
    console.log(message);
  }

  onMessage(source?: MessageSource): Observable<WebSocketEvent<any, any>> {
    return this.messageSubject.asObservable().pipe(
        filter(message => {
          if (!message) {
            return false;
          }
          if (!!source) {
            if (message.source === source) {
              // TODO remove once debugging of websocket messages is finished
              console.log(`onMessage() received: ${message.source}`);
            }
            return message.source === source;
          } else {
            return true;
          }
        })
    );
  }
}
