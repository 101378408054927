import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router, UrlSegment} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {InspectionFormsService} from '../../../../../data/inspection-forms/inspection-forms.service';
import {DvirFormReviewingSignaturesOption} from '../model/form-options/DvirFormReviewingSignaturesOption';
import {DvirFormQuestionFormatOption} from '../model/form-options/DvirFormQuestionFormatOption';
import {firstValueFrom, Subject, switchMap, tap} from 'rxjs';
import {SharedDataService} from '../../../../../shared/services/shared-data.service';
import {DvirFormDto, DvirFormEmailNotificationType} from '../model/dto/DvirFormDto';
import {UserManagementService} from '../../../../../data/users/user-management.service';
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';
import {FeatureFlagEnum} from '../../../../../shared/models/configuration.model';
import {ConfigurationService} from '../../../../../configuration/configuration.service';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {MatError, MatFormField} from '@angular/material/form-field';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
  ValueChangeEvent
} from '@angular/forms';
import {MatButton} from '@angular/material/button';
import {MatInput} from '@angular/material/input';
import {MatRadioButton, MatRadioGroup} from '@angular/material/radio';
import {CardComponent} from '../../../../../shared/components/card/card.component';
import {CardContentDirective} from '../../../../../shared/components/card/card-content.directive';
import {RowWithToggleComponent} from '../../row-with-toggle/row-with-toggle.component';
import {RowWithSelectComponent} from '../../row-with-select/row-with-select.component';
import {catchError, debounceTime} from 'rxjs/operators';
import {DvirForm} from '../model/dvir-form';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDeleteFormDialogComponent} from './confirm-delete-form-dialog/confirm-delete-form-dialog.component';
import {ConfirmDeleteFormDialogData} from './confirm-delete-form-dialog/ConfirmDeleteFormDialogData';
import {
  ConfirmLeaveUnsavedFormDialogComponent
} from "./confirm-leave-unsaved-form-dialog/confirm-leave-unsaved-form-dialog.component";

@Component({
  selector: 'app-inspection-form',
  standalone: true,
  templateUrl: './inspection-form.component.html',
  imports: [
    MatProgressSpinner,
    MatFormField,
    FormsModule,
    MatButton,
    MatInput,
    MatRadioGroup,
    MatRadioButton,
    MatCheckbox,
    CardComponent,
    CardContentDirective,
    MatError,
    RowWithToggleComponent,
    RowWithSelectComponent,
    ReactiveFormsModule
  ],
  styleUrls: ['./inspection-form.component.scss', '../../../../settings/settings-fields.scss', '../../../../settings/settings-common.scss']
})
export class InspectionFormComponent implements OnInit, OnDestroy {

  loading = false;
  loadError: string | undefined;

  saving = false;
  inspectionFormSaveTopic: Subject<DvirForm>

  formDetailHeader = 'Inspection form';

  inspectionForm: DvirForm = null;
  notificationUsers: { email: string, selected: boolean }[] = [];

  // to check if cartegraph is enabled for tenant
  cartegraphEnabled: boolean;

  DvirFormReviewingSignaturesOption = DvirFormReviewingSignaturesOption;
  DvirFormQuestionFormatOption = DvirFormQuestionFormatOption;
  DvirFormEmailNotificationType = DvirFormEmailNotificationType;

  formGroup: FormGroup;

  private readonly subscriptions = []
  private goToQuestionsAfterSave = false;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    private readonly inspectionFormService: InspectionFormsService,
    private readonly sharedDataService: SharedDataService,
    private readonly userService: UserManagementService,
    private readonly configurationService: ConfigurationService,
    private readonly fb: FormBuilder,
  ) {
    this.createFormGroup();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.url
        .subscribe((urlSegments) => {
          this.loading = true;
          delete this.loadError;
          const inspectionFormIdParam = this.route.snapshot.paramMap.get('inspectionFormId');

          if (inspectionFormIdParam === 'new') {
            this.initNew();
          } else if (inspectionFormIdParam === 'from-template') {
            this.initDefault();
          } else {
            this.initCopyOrEdit(+inspectionFormIdParam, urlSegments);
          }
        })
    );

    this.subscriptions.push(
      this.configurationService.sharedConfigurationModel.subscribe(cfg => {
        if (cfg) {
          this.cartegraphEnabled = this.configurationService.hasFeatureFlag(cfg.featureFlags, FeatureFlagEnum.CartegraphIntegration);
        }
      }));

    this.inspectionFormSaveTopic = new Subject()

    this.subscriptions.push(
      this.inspectionFormSaveTopic.pipe(
        debounceTime(500),
        switchMap(form => {
          this.saving = true;
          //this.formGroup.disable({emitEvent: false});
          return this.inspectionFormService.saveForm(form.toDto())
        }),
        tap((form) => {
          //this.formGroup.enable({emitEvent: false});
          if (form.id !== this.inspectionForm.id) {
            if (this.goToQuestionsAfterSave) {
              this.router.navigate(['/settings', 'inspection-forms', form.id, 'questions'])
                .then();
            } else {
              this.router.navigate(['/settings', 'inspection-forms', form.id])
                .then();
            }
          } else {
            this.saving = false;
          }
        }),
        catchError((error) => {
          this.handleActionError('Error while saving the form', error);
          throw error;
        })
      ).subscribe()
    )

    // load all supervisors and admins as potential email recipients
    firstValueFrom(this.userService.getSupervisorList())
      .then(response => {
        this.notificationUsers = response.data
          .filter(user => !user.email.startsWith('neotreks_support@'))
          .map(user => {
            return {email: user.email, selected: false}
          });
      });
  }

  async checkHasNoUnsavedData() {
    if (this.saving) {
      return false;
    }

    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched({emitEvent: false});
      const dialogRef = this.dialog.open(ConfirmLeaveUnsavedFormDialogComponent)
      return await firstValueFrom(dialogRef.afterClosed());
    }
    return true;
  }

  buttonClickedCancel() {
    this.checkHasNoUnsavedData().then(result => {
      if (result) {
        this.router.navigate(['/settings/inspection-forms'])
          .then();
      }
    });
  }

  triggerInspectionFormSave() {
    if (this.formGroup.valid) {
      const {
        title,
        hasRemarks,
        requireSignatures,
        reviewingSignatures,
        enableEmailNotifications,
        emailNotificationType,
        questionFormat,
      } = this.formGroup.value;

      const formToSave = Object.assign(
        Object.create(this.inspectionForm),
        {
          title,
          hasRemarks,
          reviewingSignatures,
          questionFormat,
          settings: {
            enableEmailNotifications,
            emailNotificationType,
            emailRecipients: enableEmailNotifications ? this.notificationUsers
              ?.filter(user => user.selected)
              ?.map(user => user.email) || [] : []
          }
        }
      );
      // handle signatures save
      if (!requireSignatures) {
        formToSave.reviewingSignatures = DvirFormReviewingSignaturesOption.NEVER_REQUIRED;
      }

      this.inspectionFormSaveTopic.next(formToSave);
    }
  }

  notificationUsersChanged(user: { email: string; selected: boolean }, $event: MatCheckboxChange) {
    user.selected = $event.checked;
    this.formGroup.updateValueAndValidity();
    this.triggerInspectionFormSave();
  }

  deleteForm() {
    this.checkHasNoUnsavedData().then(result => {
      if (!result)
        return;
      const dialogRef = this.dialog.open(ConfirmDeleteFormDialogComponent, {
        data: new ConfirmDeleteFormDialogData(this.inspectionForm)
      })

      firstValueFrom(dialogRef.afterClosed()).then(result => {
        if (result) {
          this.formGroup.disable({emitEvent: false});
          this.inspectionFormService.deleteForm(this.inspectionForm.id)
            .then(() => {
              this.snackBar.open('Inspection form has been deleted', null, {duration: 2000});
              this.router.navigate(['/settings', 'inspection-forms'])
                .then()
            });
        }
      })
    })
  }

  editQuestions() {
    if (this.inspectionForm.id === undefined) {
      this.goToQuestionsAfterSave = true
      this.triggerInspectionFormSave()
    } else {
      this.router.navigate(['/settings', 'inspection-forms', this.inspectionForm.id, 'questions'])
        .then();
    }
  }

  private initNew() {
    this.setInspectionForm(DvirForm.createNew())
    this.formDetailHeader = 'Create new inspection form';
    this.loading = false;
  }

  private initDefault() {
    const selectedSystemForm = this.sharedDataService.takeOut('dvir-form-template') as DvirFormDto;
    if (selectedSystemForm != null) {
      this.setInspectionForm(DvirForm.fromDto(selectedSystemForm));
      this.loading = false;
    } else {
      this.initNew(); // this is fallback for reload page or after the re-login
    }
  }

  private initCopyOrEdit(inspectionFormIdParam, urlSegments: UrlSegment[]) {
    const inspectionFormId = +inspectionFormIdParam;
    this.inspectionFormService.getForm(inspectionFormId)
      .then(response => {
        if (urlSegments[urlSegments.length - 1].path === 'copy') {
          delete response.id;
          response.title += ' (copy)';
        }
        this.setInspectionForm(DvirForm.fromDto(response));
        this.loading = false;
      })
      .catch(error => {
        this.handleLoadError('Error while loading inspection form', error);
        this.inspectionForm = null;
      });
  }

  private setInspectionForm(form: DvirForm) {
    this.inspectionForm = form;

    const reviewingSignatures = this.inspectionForm.reviewingSignatures
    const requireSignatures = reviewingSignatures.code !== DvirFormReviewingSignaturesOption.NEVER_REQUIRED.code;

    const { title, questionFormat, hasRemarks} = form;
    const { enableEmailNotifications, emailNotificationType, emailRecipients} = form.settings;

    this.formGroup.setValue({
      title: title || '',
      questionFormat,
      hasRemarks,
      requireSignatures,
      reviewingSignatures,
      enableEmailNotifications,
      emailNotificationType: emailNotificationType || null,
    }, {emitEvent: false});
    this.notificationUsers.forEach(user => {
      user.selected = (emailRecipients || []).indexOf(user.email) !== -1
    });
  }

  private showSnackBar(message: string) {
    this.snackBar.open(message, null, {duration: 2000});
  }

  private handleLoadError(msg: string, error: any) {
    const msgError = `${msg} :: ${error}`;

    console.error(msgError);
    this.showSnackBar(msg);

    this.loading = false;
    this.loadError = msgError;
  }

  private handleActionError(msg: string, error: any) {
    const msgError = `${msg} :: ${error}`;

    console.error(msgError);
    this.showSnackBar(msg);
  }

  private createFormGroup() {
    this.formGroup = this.fb.group({
      title: this.fb.control('', [Validators.required]),
      questionFormat: this.fb.control(undefined),
      hasRemarks: this.fb.control(undefined),
      requireSignatures: this.fb.control(undefined),
      reviewingSignatures: this.fb.control(undefined),
      enableEmailNotifications: this.fb.control(undefined),
      emailNotificationType: this.fb.control(undefined, {nonNullable: false}),
    }, {
      validators: [(control) => this.validateEmailRecipients(control)]
    })
    this.subscriptions.push(
      this.formGroup.events.subscribe((event) => {
        if (event instanceof ValueChangeEvent) {
          if (event.value.requireSignatures && event.value.reviewingSignatures.code === DvirFormReviewingSignaturesOption.NEVER_REQUIRED.code) {
            this.formGroup.setValue({
              ...event.value,
              reviewingSignatures: DvirFormReviewingSignaturesOption.ALWAYS_REQUIRED
            });
          } else if (event.value.enableEmailNotifications && !event.value.emailNotificationType) {
            this.formGroup.setValue({
              ...event.value,
              emailNotificationType: DvirFormEmailNotificationType.ON_OUT_OF_SERVICE
            })
          } else {
            this.triggerInspectionFormSave();
          }
        }
      })
    );
  }

  validateEmailRecipients(control: AbstractControl) {
    const { value : {enableEmailNotifications} } = control;
    if (!enableEmailNotifications) {
      return null
    }
    if ((this?.notificationUsers?.find(user => user.selected)))
      return null;

    return {missingRecipient: true}
  }

  duplicateForm() {
    this.checkHasNoUnsavedData().then(result => {
      if (!result)
        return;

      this.router.navigate(['/settings', 'inspection-forms', this.inspectionForm.id, 'copy']).then();
    })
  }
}
