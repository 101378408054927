import {Component} from '@angular/core';
import {MatButton} from "@angular/material/button";
import {MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";

@Component({
  selector: 'app-confirm-leave-unsaved-form-dialog',
  standalone: true,
  imports: [
    MatButton,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle
  ],
  templateUrl: './confirm-leave-unsaved-form-dialog.component.html',
  styleUrl: './confirm-leave-unsaved-form-dialog.component.scss'
})
export class ConfirmLeaveUnsavedFormDialogComponent {

}
