<div class="content-block">

    <mat-radio-button class="content-block-radio"
                      [value]="contentBlock.title"
                      [checked]="contentBlock.selected"
                      (change)="selectedChanged()"
    >
        <div>{{ contentBlock.title }}</div>
        <div class="content-block-description">{{ contentBlock.description }}</div>
    </mat-radio-button>

    <div class="action-buttons">
        <button mat-icon-button
                color="accent"
                (click)="edit()"
                [matTooltip]="'Edit Content Block'"
        >
            <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button
                color="accent"
                (click)="delete()"
                [matTooltip]="'Remove Content Block'"
        >
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</div>
