import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {JsonApiResponse} from '../../shared/models/JsonApiResponse';
import {environment} from '../../../environments/environment';
import {HttpErrorHandler} from '../../http.error.handler';
import {
  BaseObjectClass,
  CartegraphConfiguration,
  CartegraphFeatureFlag,
  CartegraphProcessing,
  CartegraphSettings,
  CartegraphSyncTask,
  CgDatasetClassField,
  CgDatasetClassFieldRequest,
  CgDatasetList,
  CgDatasetListRequest,
  CgImportTask,
  CgQuestion,
  CgQuestionType,
  EquipmentClass,
  ImportResultRow,
  LaborClass
} from '../../shared/models/cartegraph.model';
import {Page} from '../../shared/models/Page';
import {EMPTY, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartegraphManagementService {

  constructor(private http: HttpClient) {
  }

  getConfiguration() {
    return this.http.get<JsonApiResponse<CartegraphConfiguration>>(`${environment.services.service}v1/cartegraph/config`)
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      );
  }

  updateConfiguration(cartegraphUpdate: CartegraphConfiguration): Promise<JsonApiResponse<CartegraphConfiguration>> {
    return this.http.put<JsonApiResponse<CartegraphConfiguration>>(`${environment.services.service}v1/cartegraph/config`,
      cartegraphUpdate
    ).pipe(
      catchError(HttpErrorHandler.handleError) // then handle the error
    ).toPromise();
  }

  updateProcessing(processing: CartegraphProcessing): Promise<JsonApiResponse<CartegraphConfiguration>> {
    return this.http.put<JsonApiResponse<CartegraphConfiguration>>(`${environment.services.service}v1/cartegraph/config/processing`,
      processing
    ).pipe(
      catchError(HttpErrorHandler.handleError) // then handle the error
    ).toPromise();
  }

  updateFeatures(features: CartegraphFeatureFlag[]): Promise<JsonApiResponse<CartegraphConfiguration>> {
    return this.http.put<JsonApiResponse<CartegraphConfiguration>>(`${environment.services.service}v1/cartegraph/config/features`,
      features
    ).pipe(
      catchError(HttpErrorHandler.handleError) // then handle the error
    ).toPromise();
  }

  updateSettings(settings: CartegraphSettings): Promise<JsonApiResponse<CartegraphConfiguration>> {
    return this.http.put<JsonApiResponse<CartegraphConfiguration>>(`${environment.services.service}v1/cartegraph/config/settings`,
      settings
    ).pipe(
      catchError(HttpErrorHandler.handleError) // then handle the error
    ).toPromise();
  }

  testConnection(baseUrl: string, username: string, password: string): Promise<JsonApiResponse<string>> {
    return this.http.patch<JsonApiResponse<string>>(`${environment.services.service}v1/cartegraph/config`,
      {
        baseUrl, username, password
      })
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      ).toPromise();
  }

  fetchAllData(): Observable<JsonApiResponse<string>> {
    return this.http.post<JsonApiResponse<string>>(
      `${environment.services.service}v1/cartegraph/fetch`,
      {})
      .pipe(
        catchError((err) => {
          if (err?.status === 504) {
            // hide the 504 gateway timeout error
            return EMPTY;
          } else {
            // then handle the error
            return HttpErrorHandler.handleError(err);
          }
        })
      );
  }

  fetchIsRunning(): Observable<JsonApiResponse<boolean>> {
    return this.http.post<JsonApiResponse<boolean>>(
      `${environment.services.service}v1/cartegraph/fetch/status`,
      {})
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      );
  }

  importAllData(fetchAllData: boolean = false): Promise<JsonApiResponse<string>> {
    return this.http.post<JsonApiResponse<string>>(
      `${environment.services.service}v1/cartegraph/import`,
      {},
      {
        params: {
          'fetch-from-server': fetchAllData
        }
      })
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      ).toPromise();
  }

  importDrivers(): Promise<JsonApiResponse<{ key: string, list: ImportResultRow[] }>> {
    return this.http.post<JsonApiResponse<{ key: string, list: ImportResultRow[] }>>(
      `${environment.services.service}v1/cartegraph/import/drivers`,
      {})
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      ).toPromise();
  }

  importVehicles(): Promise<JsonApiResponse<{ key: string, list: ImportResultRow[] }>> {
    return this.http.post<JsonApiResponse<{ key: string, list: ImportResultRow[] }>>(
      `${environment.services.service}v1/cartegraph/import/vehicles`,
      {})
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      ).toPromise();
  }

  // tslint:disable-next-line:no-shadowed-variable
  fetchLabor(filter: string): Promise<JsonApiResponse<LaborClass[]>> {
    return this.http.post<JsonApiResponse<LaborClass[]>>(
      `${environment.services.service}v1/cartegraph/labor`,
      {filter})
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      ).toPromise();
  }

  // tslint:disable-next-line:no-shadowed-variable
  fetchEquipment(filter: string): Promise<JsonApiResponse<EquipmentClass[]>> {
    return this.http.post<JsonApiResponse<EquipmentClass[]>>(
      `${environment.services.service}v1/cartegraph/equipment`,
      {filter})
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      ).toPromise();
  }

  // tslint:disable-next-line:no-shadowed-variable
  fetchBaseObject(cgClassName: string, filter: string): Promise<JsonApiResponse<BaseObjectClass[]>> {
    return this.http.post<JsonApiResponse<BaseObjectClass[]>>(
      `${environment.services.service}v1/cartegraph/baseObject`,
      {
        cgClassName,
        filter
      })
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      ).toPromise();
  }

  getQuestions(type: CgQuestionType) {
    return this.http.get<JsonApiResponse<CgQuestion[]>>(
      `${environment.services.service}v1/cartegraph/questions`,
      {
        params: {
          type
        }
      }
    )
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      );
  }

  updateQuestions(questions: CgQuestion[]): Promise<JsonApiResponse<CgQuestion[]>> {
    return this.http.put<JsonApiResponse<CgQuestion[]>>(
      `${environment.services.service}v1/cartegraph/questions`,
      questions)
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      ).toPromise();
  }

  getDatasetList(listRequest: CgDatasetListRequest[]): Promise<JsonApiResponse<CgDatasetList[]>> {
    return this.http.post<JsonApiResponse<CgDatasetList[]>>(
      `${environment.services.service}v1/cartegraph/dataset/value-list`,
      listRequest)
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      ).toPromise();
  }

  getDatasetClassFields(listRequest: CgDatasetClassFieldRequest[]): Promise<JsonApiResponse<CgDatasetClassField[]>> {
    return this.http.post<JsonApiResponse<CgDatasetClassField[]>>(
      `${environment.services.service}v1/cartegraph/dataset/class-fields`,
      listRequest)
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      ).toPromise();
  }

  getTaskList(pageIndex: number, size: number, statuses: string, searchText?: string, sort?: string) {
    let params = {
      size,
      page: pageIndex,
      status: statuses
    };

    if (!!sort) {
      params = Object.assign(params, {sort});
    }

    if (!!searchText) {
      params = Object.assign(params, {search: searchText});
    }

    return this.http.get<JsonApiResponse<Page<CartegraphSyncTask>>>(
      `${environment.services.service}v1/cartegraph/tasks`,
      {
        params
      }
    )
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      );
  }

  updateSyncTask(syncTaskId: number, action: string, body: any = null) {
    // content type is required if body is null
    return this.http.put<JsonApiResponse<string>>(
      `${environment.services.service}v1/cartegraph/tasks/${syncTaskId}`,
      body,
      {
        params: {action},
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      );
  }

  getImportTaskList(pageIndex: number, size: number, statuses: string[], sort?: string) {
    let params = {
      size,
      page: pageIndex,
      status: statuses.join(','),
    };

    if (sort) {
      params = Object.assign(params, {sort});
    }

    return this.http.get<JsonApiResponse<Page<CgImportTask>>>(
      `${environment.services.service}v1/cartegraph/import`,
      {
        params
      }
    )
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      );
  }

}
