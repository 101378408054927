<mat-card appearance="outlined" class="mb-10 mt-10">

    <mat-card-header>
        <mat-card-title>Observations configuration</mat-card-title>
        <mat-card-subtitle>
            Exclude observations from sending into Cartegraph.
        </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content class="mt-25">
        <ng-container *ngIf="!isLoading && !uiError && observationTypeGroups && observationTypeGroups.length > 0">
            <div *ngFor="let typeGroup of observationTypeGroups">
                <ng-container *ngTemplateOutlet="groupTpl;context:{typeGroup: typeGroup}"></ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="!isLoading && !uiError && (!observationTypeGroups || observationTypeGroups.length == 0)">
            No observation groups defined yet.
        </ng-container>
    </mat-card-content>

    <mat-card-footer>
        <div *ngIf="!isLoading && uiError && uiError.length > 0" class="w-100 mt-15 content">
            <span class="ui-error">{{ uiError }}</span>
        </div>
        <div class="form-row mb-25 mt-10">
            <button
                    mat-button
                    color="accent"
                    type="button"
                    (click)="updateObservations()"
            >
                <mat-icon>save</mat-icon>
                Update configuration
            </button>
        </div>
    </mat-card-footer>

</mat-card>

<ng-template #groupTpl let-typeGroup="typeGroup">
    <app-row-with-toggle
            [primaryText]="typeGroup.group.name"
            [(checked)]="typeGroup.excluded">
    </app-row-with-toggle>
    @for (type of typeGroup.group.observationTypes; track type.title) {
        <div class="ml-25">{{ type.title }}</div>
    }
</ng-template>
