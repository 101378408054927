import { HttpErrorResponse } from '@angular/common/http';
import {EMPTY, throwError} from 'rxjs';
import * as Sentry from '@sentry/angular';
export class HttpErrorHandler {
  static handleError(response: HttpErrorResponse) {
    if (response.error instanceof ErrorEvent) {
      // A client-side or network response occurred. Handle it accordingly.
      console.error('An response occurred:', response.error.message);
      return throwError({error: response.error.message});
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,

      // ignore 401 errors, they appear after login sometimes
      if (response.status !== 401) {
        // report to Sentry 1st
        Sentry.withScope(scope => {
          // scope.setExtra('response', response);
          // scope.setExtra('exception', `${response.name} - ${response.message}`);
          // Sentry.captureException(response.error?.error || response.error || response.message);
          let errStr: string;
          if (typeof response.error?.error === 'string' && response.error?.error.length > 0) {
            errStr = response.error?.error.length;
          } else if (typeof response.error === 'string' && response.error.length > 0) {
            errStr = response.error;
          } else if (typeof response.message === 'string' && response.message.length > 0) {
            errStr = response.message;
          } else if (typeof response === 'object') {
            try {
              errStr = JSON.stringify(response);
            } catch (e) {
              errStr = response?.message || response?.error;
            }
          } else {
            errStr = response;
          }
          const error = new Error();
          error.name = `${response.name} ${response.url}`;
          error.message = errStr || response.name;

          // set some additional data
          try {
            const sdata = JSON.parse(sessionStorage.getItem('0-plowOpsUser'));
            scope.setExtra('nt-acc_tkn_xp_at', {'nt-acc_tkn_xp_at': sdata?.access_token_expires_at});
            scope.setExtra('nt-user-data', sdata?.userData);
          } catch {
          }
          try {
            scope.setContext('http-response', {response: JSON.stringify(response, null, 2)});
          } catch {
          }
          Sentry.captureException(error);
        });
      }

      // then report to console/user
      console.error(
        `Backend returned code ${response.status}, ` +
        `body was: ${JSON.stringify(response.error)}`);
      const message = response.error && response.error.error || response.error;
      return throwError(message);
    }
  }
}
