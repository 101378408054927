import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn} from '@angular/forms';
import {Directive, Input} from '@angular/core';


export function uniqueValueValidator(values: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const exists = values.includes(control.value ? String(control.value).toLowerCase() : '');
    return exists ? {uniqueValue: {value: control.value}} : null;
  };
}

/*
 * Case insensitive uniqueness validator
 */

@Directive({
  selector: '[appUniqueValue]',
  providers: [{provide: NG_VALIDATORS, useExisting: UniqueValueDirective, multi: true}]
})
export class UniqueValueDirective implements Validator {

  private lowerCaseValues: string[] = [];

  static validator(values: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const validator = new UniqueValueDirective();
      validator.appUniqueValue = values;
      return validator.validate(control);
    };
  }

  @Input()
  get appUniqueValue(): string[] {
    return this.lowerCaseValues;
  }
  set appUniqueValue(values: string[]) {
    this.lowerCaseValues = values.filter(value => value !== null).map(value => value.toLowerCase());
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.appUniqueValue ? uniqueValueValidator(this.appUniqueValue)(control) : null;
  }
}
