import {Component, Input} from '@angular/core';
import { LeftMenuItem } from '../../left-panel/live-map-panel.component';

@Component({
  selector: 'app-left-menu-item',
  templateUrl: './left-menu-item.component.html',
  styleUrls: ['./left-menu-item.component.scss']
})
export class LeftMenuItemComponent {
  @Input() input: LeftMenuItem;
  @Input() small = false;
}
