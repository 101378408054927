import {Component, OnInit} from '@angular/core';
// import {OidcSecurityService} from 'angular-auth-oidc-client';
import {Router} from '@angular/router';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {

  constructor(// private readonly oidcSecurityService: OidcSecurityService,
              private readonly router: Router) {
  }

  ngOnInit(): void {
    // #30534: Dashboard | Redirect Issue
    // this is a hotfix for ending up on callback component without possibility to get out there
    // the angular-auth-oidc-client lib fails with error 'could not find matching config for state ${stateparamfromurl}'
    // it is because of sessionStorage overwrites or something connected to it
    this.checkAuth();
  }

  private checkAuth() {
    /*this.oidcSecurityService
      .checkAuth()
      .subscribe((loginResponse) => {
          // console.log('checkAuth ok');
          if (!!loginResponse && loginResponse.isAuthenticated) {
            // forwards to home page, we lost the original url from redirect from auth service in this case
            this.router.navigate(['/']);
          } else {
            this.reauth();
          }
        },
        error => {
          // in case of error 'could not find matching config for state ${stateparamfromurl}'
          // console.log('checkAuth failed', error);
          this.router.navigate(['/']);
        });*/
  }

  private reauth() {
    /*this.oidcSecurityService.logoffAndRevokeTokens().subscribe(() => {
        this.oidcSecurityService.authorize();
      },
      error => {
        // last chance if logoff and authorize failed, authorize again
        // console.log('logoff failed', error);
        this.oidcSecurityService.authorize();
      });*/
  }

}
