import {Component, OnInit} from '@angular/core';
import {SecurityService} from '../../../../security/security.service';
import {
  UpdateUserPasswordModel,
  UserModel,
  UserModelUtil,
  UserModelView
} from '../../../../shared/models/User';
import {UserManagementService} from '../../../../data/users/user-management.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {ActivatedRoute, Router} from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { DialogEditUserComponent } from '../manage-users/dialogs/dialog-edit-user.component';
import { MatDialog } from '@angular/material/dialog';
import { UserEditMode } from '../manage-users/edit-user/edit-user.component';
import { DialogChangePasswordComponent } from '../manage-users/dialogs/dialog-change-password.component';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: [
    './my-account.component.scss',
    '../../../settings/settings-fields.scss',
    '../../settings-common.scss',
  ]
})
export class MyAccountComponent implements OnInit {

  isLoading = true;
  isEditing = false;
  isResetting = false;
  user: UserModel;
  customerId: string;

  passwordUpdateForm: UntypedFormGroup;

  constructor(private securityService: SecurityService,
              private userManagementService: UserManagementService,
              private configurationService: ConfigurationService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder,
              private snackBar: MatSnackBar,
              private dialog: MatDialog,
  ) {
    this.initForm();
  }

  initForm() {
    this.passwordUpdateForm = this.fb.group({
      newPassword: '',
      verifyPassword: ''
    }, {
      validators: [this.passwordValidator]
    });
    this.passwordUpdateForm.controls['newPassword'].setValidators([
      Validators.required,
      Validators.minLength(8),
      Validators.pattern('(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W).{4,}')
    ]);
  }

  passwordValidator(form: UntypedFormGroup) {
    const condition = form.get('newPassword').value !== form.get('verifyPassword').value;
    return condition ? { passwordsDoNotMatch: true} : null;
  }

  ngOnInit() {
    this.isEditing = this.activatedRoute.snapshot.queryParams.edit === 'true';
    this.isResetting = this.activatedRoute.snapshot.queryParams.reset === 'true';

    this.activatedRoute.queryParams.subscribe((params) => {
      this.isEditing = params.edit === 'true';
      this.isResetting = params.reset === 'true';

      if (!this.isEditing && !this.isResetting) {
        this.passwordUpdateForm.reset();
      }
    });

    this.configurationService.getCustomerId().then(response => {
      this.customerId = response.data;
    });

    this.isLoading = true;
    this.securityService.getUserEmail().then(email => {
      this.userManagementService.getUser(email).toPromise().then(response => {
        this.user = response.data;
        this.isLoading = false;
      }).catch(error => {
        console.log(error);
        this.isLoading = false;
      });
    }).catch(error => this.isLoading = false);
  }

  save() {
    this.isLoading = true;
    const updateModel = new UserModel();
    updateModel.email = this.user.email;
    updateModel.phoneNumber = this.user.phoneNumber;
    updateModel.givenName = this.user.givenName;
    updateModel.familyName = this.user.familyName;
    updateModel.roles = this.user.roles;
    firstValueFrom(
      this.userManagementService.updateUser(updateModel)
    ).then(response => {
      this.user = response.data;
      this.isLoading = false;
      this.snackBar.open('User updated.', null, { duration: 2000 });
      this.router.navigate([], {
        queryParams: {
          edit: undefined
        }
      });
    }).catch(message => {
      console.error(message);
    });
  }

  listRoles() {
    return this.user.roles.map((role) => UserModelUtil.getRoleLabel(role)).join(', ');
  }

  updateUser() {
    // reference only
    const user = this.user;
    // const user = Object.assign({}, this.findUser(userId)); // shallow copy
    // const user = {...this.findUser(userId)}; // shallow copy

    // make deep copy
    const userCopy = JSON.parse(JSON.stringify(this.user));
    const dialogRef = this.dialog.open(DialogEditUserComponent, {
      width: '450px',
      data: {
        mode: UserEditMode.SELF_UPDATE,
        user: {
          email: userCopy.email,
          familyName: userCopy.familyName,
          givenName: userCopy.givenName,
          roles: userCopy.roles,
          phoneNumber: userCopy.phoneNumber,
        } as UserModelView
      },
    });

    firstValueFrom(dialogRef.afterClosed()).then((result) => {
      if (result) {
        Object.assign(user, UserModelView.preload(result, false));
        this.snackBar.open('User has been updated.');
      }
    });
  }

  changePassword() {
    const user = this.user;
    const dialogRef = this.dialog.open(DialogChangePasswordComponent, {
      width: '450px',
      data: {
        email: user.email,
        familyName: user.familyName,
        givenName: user.givenName,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined && result) {
        this.userManagementService
          .updatePassword(new UpdateUserPasswordModel(user.email, result))
          .then((_) => {
            this.snackBar.open('Password has been updated.');
          })
          .catch((error) => {
            this.snackBar.open(error);
          });
      }
    });
  }
}
