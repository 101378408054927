import moment from 'moment';

export class RouteAssignment {
    id: number;
    created: Date;
    deleted: Date;
    completed: Date;
    onAssignmentFrom: Date;
    configId: number;
    routeId: number;
    routeName: string;
    vehicleId: number;
    shiftId: number;
    queueOrder: number;
    distanceDriven: number; // meters
    timeDriven: number; // seconds
    vehicleName?: string;
    driverName?: string;

    static sortByStatus(assignments: RouteAssignment[]): RouteAssignment[] {
        return assignments.sort((a, b) => {
            if (!!a.completed && !!b.completed) {
                return moment(a.completed).isBefore(moment(b.completed)) ? -1 : 1;
            } else {
                if (!!a.completed) {
                    return -1;
                }
                if (!!b.completed) {
                    return 1;
                }
                return a.queueOrder < b.queueOrder ? -1 : 1;
            }
        });
    }

    static sortByCreated(assignments: RouteAssignment[]): RouteAssignment[] {
        return assignments.sort((a, b) => {
            return moment(a.created).isBefore(moment(b.created)) ? -1 : 1;
        });
    }
}

export class VehicleRouteAssignmentStatus {
    vehicleId: number;
    status: RouteAssignmentStatus;
    routeAssignment?: RouteAssignment;
}

export enum RouteAssignmentStatus {
    NO_ASSIGNMENT = 'NO_ASSIGNMENT',
    REQUESTED = 'REQUESTED',
    PENDING = 'PENDING',
    ON_ASSIGNMENT = 'ON_ASSIGNMENT',
}

export enum RouteAssignmentQueue {
    IMMEDIATE = 'IMMEDIATE',
    NEXT = 'NEXT',
    END = 'END',
}
