<h2 mat-dialog-title>Add user</h2>

<mat-dialog-content>
	<app-edit-user #editUserComponent [data]="createNewUserConfig"/>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-stroked-button mat-dialog-close>
		Cancel
	</button>
	<button mat-flat-button color="primary"
			type="submit"
			[disabled]="!isSaveButtonEnabled()"
			(click)="performSave()">Add user
	</button>
</mat-dialog-actions>
