import {DvirNodeDto} from './DvirNodeDto';
import {DvirFormOptionDto} from './DvirFormOptionDto';

export class DvirFormDto {
  id: number;
  journalId: number;
  title: string;
  note: string;
  options: DvirFormOptionDto[];
  hasRemarks: boolean;
  settings: DvirFormSettingsDto;
  created: Date;
  modified: Date;
  questionsCount: number;
  nodes: DvirNodeDto[];

  constructor(id: number,
              journalId: number,
              title: string,
              note: string,
              options: DvirFormOptionDto[],
              hasRemarks: boolean,
              settings: DvirFormSettingsDto,
              created: Date,
              modified: Date,
              questionsCount,
              nodes: DvirNodeDto[]) {
    this.id = id;
    this.journalId = journalId;
    this.title = title;
    this.note = note;
    this.options = options;
    this.hasRemarks = hasRemarks;
    this.settings = settings;
    this.created = created;
    this.modified = modified;
    this.questionsCount = questionsCount;
    this.nodes = nodes;
  }
}

export class DvirFormSettingsDto {
  enableEmailNotifications: boolean;
  emailNotificationType?: DvirFormEmailNotificationType;
  emailRecipients?: string[];

  constructor() {
    this.enableEmailNotifications = false;
  }
}

export enum DvirFormEmailNotificationType {
  ALL = 'ALL',
  ON_DEFECT = 'ON_DEFECT',
  ON_OUT_OF_SERVICE = 'ON_OUT_OF_SERVICE',
}
