<div class="drawer-layout">
<ng-container *ngIf="!!configuration">
    <div class="drawer-content">
        <button mat-button
                class="drawer-action-button"
                (click)="toggleAssigningMode()"
        >
            <mat-icon>add</mat-icon> <span class="button-label">Create new assignment...</span>
        </button>
    </div>

    <div *ngIf="!!configuration" class="drawer-action-form"
         [ngClass]="{'show-form': assigningMode}"
    >
        <div class="form-line">
            <div class="form-icon">
                <mat-icon>person_add</mat-icon>
            </div>

            <ng-container *ngIf="!!availableVehiclesForAssignment">
                <div class="form-placeholder" *ngIf="availableVehiclesForAssignment.length === 0">
                    No vehicle available
                </div>

                <mat-form-field appearance="outline" color="accent" class="w-100" *ngIf="availableVehiclesForAssignment.length > 0">
                    <mat-label>Vehicle</mat-label>
                    <mat-select
                            [(ngModel)]="selectedVehicle"
                            (ngModelChange)="onAssignmentFormChange()"
                    >
                        <mat-option
                                *ngFor="let asset of availableVehiclesForAssignment"
                                [value]="asset">
                            <span class="select-vehicle-option">{{ asset.name }} ({{ asset.driverName }})</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
        </div>

        <div class="form-line">
            <div class="form-icon">
                <mat-icon>route</mat-icon>
            </div>

            <mat-form-field class="w-100" color="accent" appearance="outline">
                <mat-label>Route</mat-label>
                <mat-select [formControl]="routeSelectorControl" panelClass="routes-dropdown" (valueChange)="onAssignmentFormChange()">
                    <!--<mat-option>&#45;&#45; None &#45;&#45;</mat-option>-->

                    <ng-container *ngFor="let itemConfigLevel of routeHierarchyItems">
                        <mat-optgroup [label]="itemConfigLevel.routeName">
                            <ng-container *ngFor="let item1stLevel of itemConfigLevel.children">
                                <ng-container *ngIf="!!item1stLevel.children">
                                    <!-- category level 1 -->
                                    <mat-optgroup [label]="'&nbsp;' + item1stLevel.value">
                                        <ng-container *ngFor="let item2ndLevel of item1stLevel.children">
                                            <ng-container *ngIf="!!item2ndLevel.children">
                                                <!-- category level 2 -->
                                                <mat-optgroup [label]="'&nbsp;&nbsp;' + item2ndLevel.value">
                                                    <ng-container *ngFor="let item3rdLevel of item2ndLevel.children">
                                                        <ng-container *ngIf="!!item3rdLevel.children">
                                                            <!-- category level 3 -->
                                                            <mat-optgroup [label]="'&nbsp;&nbsp;&nbsp;' + item3rdLevel.value">
                                                                <ng-container *ngFor="let itemLeafLevel of item3rdLevel.children">
                                                                    <ng-container *ngIf="!itemLeafLevel.children">
                                                                        <mat-option [value]="itemLeafLevel">{{ itemLeafLevel.value }}</mat-option>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </mat-optgroup>
                                                        </ng-container>
                                                        <ng-container *ngIf="!item3rdLevel.children">
                                                            <mat-option [value]="item3rdLevel">{{ item3rdLevel.value }}</mat-option>
                                                        </ng-container>
                                                    </ng-container>
                                                </mat-optgroup>
                                            </ng-container>
                                            <ng-container *ngIf="!item2ndLevel.children">
                                                <mat-option [value]="item2ndLevel">{{ item2ndLevel.value }}</mat-option>
                                            </ng-container>
                                        </ng-container>
                                    </mat-optgroup>
                                </ng-container>
                                <ng-container *ngIf="!item1stLevel.children">
                                    <mat-option [value]="item1stLevel">{{ item1stLevel.value }}</mat-option>
                                </ng-container>
                            </ng-container>
                        </mat-optgroup>
                    </ng-container>

                </mat-select>
            </mat-form-field>
        </div>

        <ng-container *ngIf="!!selectedVehicle && !!selectedRoute">
            <div class="actions-wrapper">
                <div class="buttons-wrapper" *ngIf="!!assignmentActions && !showConfirmDialog">
                    <button mat-button
                            color="accent"
                            class="assign-button"
                            (click)="assignmentActions[0].onClick()"
                            *ngIf="assignmentActions.length > 0"
                    >
                        {{ assignmentActions[0].title }}
                    </button>

                    <button mat-icon-button
                            color="accent"
                            class="assign-more-button"
                            [matMenuTriggerFor]="assignActionsMenu"
                            *ngIf="assignmentActions.length > 1"
                    >
                        <mat-icon>keyboard_arrow_down</mat-icon>
                        <div class="button-content">
                        </div>
                    </button>
                    <mat-menu #assignActionsMenu="matMenu">
                        <ng-container *ngFor="let action of assignmentActions; let i = index">
                            <button
                              mat-menu-item
                              (click)="action.onClick()"
                              *ngIf="i > 0"
                            >
                                {{ action.title }}
                            </button>
                        </ng-container>
                    </mat-menu>
                </div>

                <ng-container *ngIf="showConfirmDialog">
                    <div class="confirm-headline">
                        Reassign?
                    </div>
                    <div class="confirm-body">
                        This vehicle is currently assigned to "<span class="confirm-strong">{{ getCurrentAssignmentInProgressIfAny()?.routeName }}</span>".
                    </div>
                    <div class="confirm-body">
                        If you continue, "<span class="confirm-strong">{{ getCurrentAssignmentInProgressIfAny()?.routeName }}</span>" will be canceled and this vehicle will be reassigned to "<span class="confirm-strong">{{ selectedRoute.value }}</span>".
                    </div>
                    <!--<span>The current assignment {{ getCurrentAssignmentInProgressIfAny()?.routeName }} will be closed and driver will be instructed to proceed to this route immediately. Do you wish to proceed?</span>-->
                    <div class="buttons-wrapper">
                        <div class="confirm-wrapper">
                            <button mat-button
                                    color=""
                                    class="confirm-button"
                                    (click)="cancelConfirmation()"
                            >
                                Cancel
                            </button>
                            <button mat-button
                                    color="accent"
                                    class="confirm-button"
                                    (click)="assignRoute(RouteAssignmentQueue.IMMEDIATE)"
                            >
                                Continue
                            </button>
                        </div>
                    </div>
                </ng-container>
            </div>
            <!--<div class="buttons-wrapper">
                <button mat-button
                        color=""
                        class="assign-button"
                        (click)="assignRoute(RouteAssignmentQueue.END)"
                        *ngIf="!!items && items.length >= 1"
                >
                    Add to End of Queue
                </button>

                <button mat-button
                        color=""
                        class="assign-button"
                        (click)="assignRoute(RouteAssignmentQueue.NEXT)"
                        *ngIf="!!items && items.length >= 2"
                >
                    Make next in queue
                </button>

                <button mat-button
                        *ngIf="getCurrentAssignmentInProgressIfAny()"
                        [color]="showConfirmDialog ? 'accent' : ''"
                        class="assign-button"
                        (click)="confirm()"
                >
                    Start immediately
                </button>

                <button mat-button
                        *ngIf="!getCurrentAssignmentInProgressIfAny()"
                        color=""
                        class="assign-button"
                        (click)="assignRoute(RouteAssignmentQueue.IMMEDIATE)"
                >
                    Add to Queue
                </button>
            </div>-->
        </ng-container>
    </div>
</ng-container>

    <div class="drawer-content" *ngIf="!!items && items.length > 0">
        <ng-container *ngFor="let item of items">
            <app-card-menu-item [input]="item"></app-card-menu-item>
        </ng-container>
    </div>
</div>
