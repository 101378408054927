<div class="mb-25">
	<app-card preCardTitle="New Inspection Form">
		<div *appCardContent class="settings-header-container">
			<div class="settings-header-title">
				<p>Create new form</p>
				<div class="settings-header-subtitle">Use one of out templates or create one from scratch</div>
			</div>
			<div class="settings-header-actions">
				<button mat-stroked-button
						color="accent"
						[disabled]="false"
						[matMenuTriggerFor]="newFormMenu">
					<mat-icon>
						arrow_drop_down
					</mat-icon>
					<span>Create New Form</span>
				</button>
				<mat-menu #newFormMenu="matMenu">
					<button mat-menu-item
							(click)="buttonClickedNew()">
						From Scratch
					</button>
					<button mat-menu-item
							[matMenuTriggerFor]="templateFormMenu">
						From Template
					</button>
				</mat-menu>
				<mat-menu #templateFormMenu="matMenu">
					<button mat-menu-item *ngFor="let form of systemForms"
							(click)="buttonClickAddFromTemplate(form)">
						<span [ngClass]="{'bolder' : form.default}">{{form.form.title}}</span>
					</button>
				</mat-menu>
			</div>
		</div>
	</app-card>
</div>

@if (loading) {
	<mat-spinner class="mx-a my-15" diameter="30"></mat-spinner>
}
@else {
	<app-card preCardTitle="Inspection Forms">
		<div *appCardContentRaw>
				<mat-nav-list class="inspection-forms-list">
					@for (form of inspectionForms; track form.id) {
						<a mat-list-item class="flex-row"
						   [routerLink]="['/settings','inspection-forms', form.id]">
							<div class="flex-grow-1">{{ form.title }}</div>
							<mat-icon>arrow_right</mat-icon>
						</a>
						@if (!$last) {
							<mat-divider></mat-divider>
						}
					}
				</mat-nav-list>
		</div>
	</app-card>
}
