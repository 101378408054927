/**
 * Provides functionality to dynamically show and/or hide vertical borders between sticky and non-sticky columns
 * that is available for `<mat-table>` directives.
 *
 * It requires a reference to a `<mat-table>`'s container that is used for the scrolling. `MatStickyTableBorderHelper`
 * will add a custom `table-scroll-root` CSS class to that element and will insert two new custom `<div>` elements
 * as the very first and the very last child of the container. Their position will be observed to determine the table
 * scroll state.
 *
 * Use the `attach(tableScrollRoot)` method to start observation of the scroll position. Use `detach()` to stop it.
 */
export class MatStickyTableBorderHelper {

    private static TABLE_SCROLL_ROOT_CLASS = 'table-scroll-root';
    private static START_SCROLL_BOUNDARY_CLASS = 'start-scroll-boundary';
    private static END_SCROLL_BOUNDARY_CLASS = 'end-scroll-boundary';
    private static START_SCROLL_BOUNDARY_SELECTOR = '.' + MatStickyTableBorderHelper.START_SCROLL_BOUNDARY_CLASS;
    private static END_SCROLL_BOUNDARY_SELECTOR = '.' + MatStickyTableBorderHelper.END_SCROLL_BOUNDARY_CLASS;
    private static IS_SCROLLED_TO_START_CLASS = 'is-scrolled-to-start';
    private static IS_SCROLLED_TO_END_CLASS = 'is-scrolled-to-end';

    private tableScrollRoot: HTMLElement = null;
    private tableScrollStartObserver: IntersectionObserver = null;
    private tableScrollEndObserver: IntersectionObserver = null;

    /**
     * Attach an observer of the scroll position of the table.
     * @param tableScrollRoot A container of a `<mat-table>`.
     */
    attach(tableScrollRoot: HTMLElement) {
        if (!IntersectionObserver) {
            console.warn('Intersection Observer API not supported');
            return;
        }

        tableScrollRoot.classList.add(MatStickyTableBorderHelper.TABLE_SCROLL_ROOT_CLASS);

        if (!!this.tableScrollStartObserver || !!this.tableScrollEndObserver) {
            throw new Error('Already attached.');
        }

        this.tableScrollRoot = tableScrollRoot;
        this.attachImpl();
    }

    /**
     * Detach the scroll position observers and releases any resources.
     */
    detach() {
        this.tableScrollStartObserver?.disconnect();
        this.tableScrollEndObserver?.disconnect();

        this.tableScrollStartObserver = null;
        this.tableScrollEndObserver = null;

        this.removeScrollBoundaries();

        this.tableScrollRoot.classList.remove(MatStickyTableBorderHelper.TABLE_SCROLL_ROOT_CLASS);
    }

    private addOrRemoveIsScrolledMarker(
        entries: IntersectionObserverEntry[],
        markerClass: string,
    ) {
        if (!!entries && entries.length > 0 && entries[0].intersectionRatio === 1) {
            this.tableScrollRoot.classList.add(markerClass);
        } else {
            this.tableScrollRoot.classList.remove(markerClass);
        }
    }

    private attachImpl() {
        this.insertScrollBoundaries();

        const options = {
            root: this.tableScrollRoot
        };
        const tableScrollStartCallback = (entries: IntersectionObserverEntry[], _: IntersectionObserver) => {
            this.addOrRemoveIsScrolledMarker(entries, MatStickyTableBorderHelper.IS_SCROLLED_TO_START_CLASS);
        };
        this.tableScrollStartObserver = new IntersectionObserver(tableScrollStartCallback, options);
        this.tableScrollStartObserver.observe(this.findStartScrollElement());

        const tableScrollEndCallback = (entries: IntersectionObserverEntry[], _: IntersectionObserver) => {
            this.addOrRemoveIsScrolledMarker(entries, MatStickyTableBorderHelper.IS_SCROLLED_TO_END_CLASS);
        };
        this.tableScrollEndObserver = new IntersectionObserver(tableScrollEndCallback, options);
        this.tableScrollEndObserver.observe(this.findEndScrollElement());
    }

    private insertScrollBoundary(cssClass: string, insertBeforeElement: ChildNode, yShift: number) {
        const newElement = document.createElement('div');
        newElement.classList.add(cssClass);
        newElement.style.position = 'relative';
        newElement.style.left = `${yShift}px`;
        this.tableScrollRoot.insertBefore(newElement, insertBeforeElement);
    }

    private insertScrollBoundaries() {
        if (!this.findStartScrollElement()) {
            this.insertScrollBoundary(
                MatStickyTableBorderHelper.START_SCROLL_BOUNDARY_CLASS,
                this.tableScrollRoot.firstChild,
                1
            );
        }
        if (!this.findEndScrollElement()) {
            this.insertScrollBoundary(
                MatStickyTableBorderHelper.END_SCROLL_BOUNDARY_CLASS,
                null,
                -1
            );
        }
    }

    private removeScrollBoundaries() {
        this.removeScrollBoundary(this.findStartScrollElement());
        this.removeScrollBoundary(this.findEndScrollElement());
    }

    private removeScrollBoundary(boundaryElement: HTMLElement) {
        if (boundaryElement) {
            this.tableScrollRoot.removeChild(boundaryElement);
        }
    }

    private findStartScrollElement(): HTMLElement {
        return this.tableScrollRoot.querySelector(MatStickyTableBorderHelper.START_SCROLL_BOUNDARY_SELECTOR);
    }

    private findEndScrollElement(): HTMLElement {
        return this.tableScrollRoot.querySelector(MatStickyTableBorderHelper.END_SCROLL_BOUNDARY_SELECTOR);
    }
}
