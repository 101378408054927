import {AfterViewInit, Component, DoCheck, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {inOutAnimation} from 'src/app/shared/animations/animations';
import {SecurityService} from '../../../security/security.service';
import {ConfigurationService} from '../../../configuration/configuration.service';
import {ConfigurationModel, FeatureFlagEnum} from '../../../shared/models/configuration.model';
import {Subscription} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {SettingsGlobalSearchService} from './settings-global-search.service';

interface SettingSection {
  id: string;
  icon: string;
  name: string;
  description: string;
}

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  animations: [inOutAnimation],
})
export class SettingsComponent implements OnInit, OnDestroy, DoCheck, AfterViewInit {
  // @HostBinding('class.use-material-design-3-theme') get useMaterial3() { return true; }

  protected isLoadingConfiguration = true;
  protected isLoadingAdminStatus = true;
  public settingsSections: SettingSection[] = [];

  public dashboardVersion = environment.version;
  public envName = environment.name;

  private readonly openSubscriptions = Array<Subscription>();

  /**
   * An invisible `<div>` located at the very top of the main scrollable content. Used to detect whether
   * the main content is scrolled or not.
   * @private
   */
  @ViewChild('mainContentTopMark') private mainContentTopMark: ElementRef<HTMLElement>;
  private mainContentTopMarkObserver = new IntersectionObserver((entries, _) => {
    entries.forEach(entry => {
      const isTopMarkVisible = entry.intersectionRatio > 0;
      this.isMainContentScrolled = !isTopMarkVisible;
    });
  });
  isMainContentScrolled = false;

  /**
   * Used to avoid ExpressionChangedAfterItHasBeenCheckedError from happening when `<app-manage-users>`
   * is added and calls `SettingsComponent.registerListener()` during its `ngOnInit()`.
   * This field is used to be the source of truth for `showSearchField` but the `showSearchField` is only
   * allowed to refresh its value during the _safe_ `ngDoCheck()` callback.
   * @private
   */
  private showSearchFieldSafe = false;
  showSearchField = this.showSearchFieldSafe;
  private searchHintSafe: string;
  searchHint: string;

  /**
   * Used to remember the very last valid **normalized** search term.
   * @private
   */
  private lastNormalizedSearchTerm: string | null = null;
  searchTerm: string | null = this.lastNormalizedSearchTerm;

  constructor(
      private securityService: SecurityService,
      private configurationService: ConfigurationService,
      private searchService: SettingsGlobalSearchService,
  ) { }

  ngDoCheck() {
    // Let's refresh some properties used in the views at this Angular lifecycle step.
    // It would be an ExpressionChangedAfterItHasBeenCheckedError if their value changed in some later lifecycle steps.
    this.searchHint = this.searchHintSafe;
    this.showSearchField = this.showSearchFieldSafe;
  }

  ngOnInit() {
    this.subscribeToConfigurationChanges();

    this.loadGeneralConfiguration();

    const topicsSubscription = this.searchService.topics.subscribe((topics) => {
      this.onSubscribedTopicsChanged(topics);
    });
    this.openSubscriptions.push(topicsSubscription);
  }

  ngAfterViewInit() {
    this.attachTopMarkVisibilityObserver();
  }

  ngOnDestroy() {
    this.mainContentTopMarkObserver?.disconnect();

    this.openSubscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  onSearchTermChanged() {
    let normalizedSearchTerm: string | null = this.searchTerm.trim();
    if (normalizedSearchTerm === '') {
      normalizedSearchTerm = null;
    }

    if (this.lastNormalizedSearchTerm !== normalizedSearchTerm) {
      this.lastNormalizedSearchTerm = normalizedSearchTerm;
      this.searchService.search(normalizedSearchTerm);
    }
  }

  private subscribeToConfigurationChanges() {
    const configurationSubscription = this.configurationService.sharedConfigurationModel.subscribe(cfg => {
      this.handleConfigurationUpdate(cfg);
    });
    this.openSubscriptions.push(configurationSubscription);
  }

  private handleConfigurationUpdate(cfg: ConfigurationModel) {
      // console.log('cfg.featureFlags', cfg?.featureFlags);

      const hasFeatureFlag = (flag: FeatureFlagEnum) => {
        return !!cfg
            && !!cfg.featureFlags
            && this.configurationService.hasFeatureFlag(cfg.featureFlags, flag);
      };

      const menuSecurityConfig: MenuSecurityConfig = {
        cartegraphIntegrationEnabled: hasFeatureFlag(FeatureFlagEnum.CartegraphIntegration),
        vehicleInstallReportEnabled: hasFeatureFlag(FeatureFlagEnum.VehicleInstallationReport),
        isAdmin: false
      };

      // Let's show a non-admin menu as soon as possible
      this.updateMenu(menuSecurityConfig);

      if (cfg) {
        this.isLoadingConfiguration = false;
        this.isLoadingAdminStatus = true;
        this.securityService.isAdmin()
            .then(isAdmin => {
              if (isAdmin) {
                // The user is an admin. Let's update the menu.
                menuSecurityConfig.isAdmin = true;
                this.updateMenu(menuSecurityConfig);
              }
            })
            .catch(error => console.error(error))
            .finally(() => {
              this.isLoadingAdminStatus = false;
            });
      } else {
        this.isLoadingConfiguration = true;
      }
  }

  private onSubscribedTopicsChanged(topics: string[]) {
    this.searchHintSafe = 'Search for ' + topics.join(', ');
    this.showSearchFieldSafe = topics.length > 0;
  }

  private updateMenu(config: MenuSecurityConfig) {
    this.settingsSections.length = 0;

    const adminSections: SettingSection[] = [
      {
        id: 'manage-users',
        icon: 'people',
        name: 'Users',
        description: 'Manage users and assign roles',
      },
      {
        id: 'manage-vehicles',
        icon: 'front_loader',
        name: 'Vehicles',
        description: 'Manage and configure your fleet',
      },
      {
        id: 'manage-routes',
        icon: 'route',
        name: 'Routes',
        description: 'Upload and manage your routes',
      },
      {
        id: 'manage-observations',
        icon: 'pin_drop',
        name: 'Observations',
        description: 'Create and manage driver observations',
      },
      {
        id: 'manage-public-portal',
        icon: 'preview',
        name: 'Public Portal',
        description: 'Share your progress with the public',
      }
    ];

    if (config.isAdmin) {
      this.settingsSections.push(...adminSections);
    }

    // if admin and has installation report enabled
    if (config.isAdmin && config.vehicleInstallReportEnabled) {
      const addAtIndex = this.settingsSections.findIndex(x => x.id === 'manage-vehicles') + 1;
      const vehicleInstallationSection: SettingSection = {
        id: 'vehicle-installation',
        icon: 'no_crash',
        name: 'Vehicle Installations',
        description: 'See vehicle installations report',
      };
      this.settingsSections.splice(addAtIndex, 0, vehicleInstallationSection);
    }

    if (config.isAdmin) {
      const inspectionFormsSection: SettingSection = {
        id: 'inspection-forms',
        icon: 'fact_check',
        name: 'Inspection Forms',
        description: 'Manage pre and post trip inspection forms',
      };
      this.settingsSections.push(inspectionFormsSection);
    }

    if (config.isAdmin && config.cartegraphIntegrationEnabled) {
      const cartegraphSection: SettingSection = {
        id: 'cartegraph-settings',
        icon: 'done',
        name: 'Cartegraph Settings',
        description: '',
      };
      this.settingsSections.push(cartegraphSection);
    }

    const sections: SettingSection[] = [
      {
        id: 'my-account',
        icon: 'account_circle',
        name: 'Account',
        description: 'Manage your account',
      },
    ];
    this.settingsSections.push(...sections);
  }

  private loadGeneralConfiguration() {
    // TODO: Is it a good idea to call a "refresh" method here?
    // Shouldn't there be a service that is guaranteed to always provide an up-to-date value?
    this.configurationService.refreshConfiguration();
  }

  private attachTopMarkVisibilityObserver() {
    if (!IntersectionObserver) {
      console.warn('Intersection Observer API not supported');
      return;
    }

    this.mainContentTopMarkObserver.observe(this.mainContentTopMark.nativeElement);
  }
}

interface MenuSecurityConfig {
  cartegraphIntegrationEnabled: boolean;
  vehicleInstallReportEnabled: boolean;
  isAdmin: boolean;
}
