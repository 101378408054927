<div class="filter-bar-container"
     [style.height]="!showBar ? '0' : '50px'"
>
    <div class="filter-bar-start"
         [style.display]="!showBar ? 'none' : 'flex'"
         [style.opacity]="!showBar ? '0' : '1'"
    >

        <div class="filter-bar-label">
          Filter by:
        </div>

        <ng-container *ngIf="showBar">
            <ng-content></ng-content>
        </ng-container>

        <div *ngIf="showBar">
            <button mat-button color="accent" (click)="doFilter()">
                Filter
            </button>
            <button mat-button color="accent" (click)="clearFilters()">
                Clear Filters
            </button>
        </div>

    </div>
</div>
