@if (inspectionForm) {
	<app-card preCardTitle="{{inspectionForm?.title}} Questions"
			  icon="arrow_back"
			  (iconClicked)="goBack()">
			<div *appCardContent class="settings-header-container">
				<div class="settings-header-title">
					Form Questions Operations
				</div>
				<div class="settings-header-actions">
					@if (editing) {
						@if (sectionsToEdit.length > 1 || sectionsToEdit[0].groups.length > 1) {
							<button mat-stroked-button
									[disabled]="saving"
									(click)="addSection()">
								@if (sectionsToEdit.length === 1) {
									Create New Section
								} @else {
									Add Section
								}
							</button>
						}
						@if (sectionsToEdit.length === 1 && sectionsToEdit[0].groups[0].questions.length > 0) {
							<button mat-stroked-button
									[disabled]="saving"
									(click)="addGroup()">
								@if (sectionsToEdit[0].groups.length === 1) {
									Group Questions
								} @else {
									Add Group
								}
							</button>
						}
						@if (sectionsToEdit.length === 1 && sectionsToEdit[0].groups.length === 1 && sectionsToEdit[0].groups[0].questions.length === 0) {
							<button mat-stroked-button
									[disabled]="saving"
									(click)="addQuestion()">
								Add Question
							</button>
						}
					} @else {
						<button mat-stroked-button
							(click)="startQuestionsEdit()">
							Edit Questions
						</button>
					}
				</div>
			</div>
	</app-card>
}

<form>
	@if (loading) {
		<mat-spinner mode="indeterminate" diameter="32"
					 class="mx-a">
		</mat-spinner>
	} @else if (editing) {
		<app-inspection-form-sections-edit
				[sections]="sectionsToEdit"
				(onValidChange)="valid=$event">
		</app-inspection-form-sections-edit>
	} @else {
		<app-inspection-form-sections-preview
			[sections]="inspectionForm?.sections">
		</app-inspection-form-sections-preview>
	}
</form>

@if (editing) {
<div class="button-group action-buttons">
	<button mat-stroked-button
			(click)="editing = false">
		Cancel Changes
	</button>
	<button mat-stroked-button
			[disabled]="saving || loading || !valid"
			(click)="saveInspectionForm()">
		Save Questions
	</button>
</div>
}
