import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CartegraphConfiguration, CartegraphObservationSettings} from '../../../../../shared/models/cartegraph.model';
import {CartegraphManagementService} from '../../../../../data/cartegraph/cartegraph-management.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ObservationManagementService} from '../../../../../data/observations/observation-management.service';
import {ObservationTypeGroup} from '../../../../../shared/models/observation-group';
import {firstValueFrom} from 'rxjs';


@Component({
  selector: 'app-cartegraph-settings-observations',
  templateUrl: './cartegraph-settings-observations.component.html',
  styleUrls: ['../cartegraph-config/cartegraph-settings/cartegraph-settings.scss'],
})
export class CartegraphSettingsObservations implements OnInit {

  @Output()
  configurationChange = new EventEmitter<CartegraphConfiguration>();

  @Input()
  configuration: CartegraphConfiguration;

  uiError: string;
  isLoading: boolean;

  observationTypeGroups: ObservationTypeGroupSelection[] = [];


  constructor(private cartegraphManagementService: CartegraphManagementService,
              private observationManagementService: ObservationManagementService,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.initObservations();
    this.isLoading = false;
  }

  initObservations() {
    firstValueFrom(this.observationManagementService.getObservationTypeGroups())
      .then(result => {
        if (result.data != null && result.data.length > 0) {
          this.observationTypeGroups = result.data.map(group => {
            const excluded = this.configuration.settings?.observations?.excludedObservationGroups?.find(f => f == group.id) != undefined;
            return new ObservationTypeGroupSelection(group, excluded);
          })
        }
      })
      .catch(error => {
        const msg = 'Error while loading data from server';
        this.showSnackBar(msg);
        console.error(`${msg} :: ${error}`);
      }).finally(() => {
        this.isLoading = false;
      }
    );
  }

  showSnackBar(message: string) {
    this.snackBar.open(message, null, {duration: 2000});
  }


  updateObservations() {

    this.uiError = null;
    this.isLoading = true;
    const settings = this.configuration.settings;
    const excludeGroups = this.observationTypeGroups
      .filter(f => f.excluded)
      .map(g => g.group.id);

    if (settings.observations == null) {
      settings.observations = new CartegraphObservationSettings()
    }

    settings.observations.excludedObservationGroups = excludeGroups;

    this.cartegraphManagementService.updateSettings(settings).then(response => {
      if (!!response.error) {
        this.uiError = response.error;
        this.showSnackBar('Failed to update configuration.');
      } else {
        this.configuration = response.data;
        this.initObservations();
        this.configurationChange.emit(response.data);
      }
      this.showSnackBar('Configuration successfully updated');
    }).catch(error => {
      console.log(error);
      this.uiError = error;
      this.isLoading = false;
    }).finally(() => {
      this.isLoading = false;
    });
  }

}

export class ObservationTypeGroupSelection {
  constructor(
    public group: ObservationTypeGroup,
    public excluded: boolean
  ) {
  }
}