import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VehicleCategoryModel } from '../../models/vehicle';
import { AssetsManagerService } from '../../../data/assets/assets-manager.service';

export class VehicleFilter {
  hiddenGroups?: VehicleCategoryModel[];
  visibleOfflineGroups?: VehicleCategoryModel[];
}

@Component({
  selector: 'app-vehicle-filter',
  templateUrl: './vehicle-filter.component.html',
  styleUrl: './vehicle-filter.component.scss'
})
export class VehicleFilterComponent implements OnInit {
  readonly HIDDEN_GROUPS_SETTINGS_KEY = 'hiddenVGIds';
  readonly OFFLINE_VISIBLE_GROUPS_SETTINGS_KEY = 'visibleOfflineVGIds';
  readonly HIDDEN_GROUPS_URL_PARAM_NAME = 'hiddenVgIds';
  readonly OFFLINE_VISIBLE_GROUPS_URL_PARAM_NAME = 'visibleOfflineVgIds';

  @Output() filterChanged = new EventEmitter<VehicleFilter>();

  private allGroups: VehicleCategoryModel[];
  private hiddenGroups: VehicleCategoryModel[];
  private visibleOfflineGroups: VehicleCategoryModel[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private assetManager: AssetsManagerService,
  ) {}

  ngOnInit() {
    this.initComponent(
      this.assetManager.getVehicleGroups()
    );
  }

  public toggleGroup(groupId: number, hideGroup: boolean, showOfflineGroup: boolean) {
    if (hideGroup) {
      if (!this.hiddenGroups) {
        this.hiddenGroups = [];
      }
      const index = this.hiddenGroups.findIndex(group => group.id === groupId);
      if (index === -1) {
        this.hiddenGroups.push(this.allGroups.find(group => group.id === groupId));
      }
    } else {
      if (!!this.hiddenGroups) {
        const index = this.hiddenGroups.findIndex(group => group.id === groupId);
        if (index >= 0) {
          this.hiddenGroups.splice(index, 1);
        }
      }
    }

    if (showOfflineGroup) {
      if (!this.visibleOfflineGroups) {
        this.visibleOfflineGroups = [];
      }
      const index = this.visibleOfflineGroups.findIndex(group => group.id === groupId);
      if (index === -1) {
        this.visibleOfflineGroups.push(this.allGroups.find(group => group.id === groupId));
      }
    } else {
      if (!!this.visibleOfflineGroups) {
        const index = this.visibleOfflineGroups.findIndex(group => group.id === groupId);
        if (index >= 0) {
          this.visibleOfflineGroups.splice(index, 1);
        }
      }
    }

    this.setFilter(this.hiddenGroups, this.visibleOfflineGroups);
  }

  private initComponent(data: VehicleCategoryModel[]) {
    this.allGroups = data;
    const groupIdsString = this.route.snapshot.queryParams[this.HIDDEN_GROUPS_URL_PARAM_NAME];
    const offlineGroupIdsString = this.route.snapshot.queryParams[this.OFFLINE_VISIBLE_GROUPS_URL_PARAM_NAME];

    let hiddenGroupIds = [];
    if (!groupIdsString) {
      hiddenGroupIds = this.getCachedHiddenGroupIds();
    } else {
      hiddenGroupIds = groupIdsString.split(':::');
    }

    let visibleOfflineGroupIds = [];
    if (!offlineGroupIdsString) {
      visibleOfflineGroupIds = this.getCachedVisibleOfflineGroupIds();
    } else {
      visibleOfflineGroupIds = offlineGroupIdsString.split(':::');
    }
    const hiddenGroups = this.allGroups.filter(group => !!hiddenGroupIds.find(groupId => String(group.id) === groupId));
    const visibleOfflineGroups = this.allGroups.filter(item => !!visibleOfflineGroupIds.find(groupId => String(item.id) === groupId));
    this.setFilter(hiddenGroups, visibleOfflineGroups);
  }

  private setFilter(hiddenGroups: VehicleCategoryModel[], visibleOfflineGroups: VehicleCategoryModel[]) {
    this.hiddenGroups = !hiddenGroups || hiddenGroups.length === 0
      ? undefined
      : Object.assign([], hiddenGroups);
    const hiddenGroupIds = !hiddenGroups || hiddenGroups.length === 0
      ? undefined
      : hiddenGroups.map(item => item.id).join(':::');

    this.visibleOfflineGroups = !visibleOfflineGroups || visibleOfflineGroups.length === 0
      ? undefined
      : Object.assign([], visibleOfflineGroups);
    const visibleOfflineGroupIds = !visibleOfflineGroups || visibleOfflineGroups.length === 0
      ? undefined
      : visibleOfflineGroups.map(item => item.id).join(':::');

    this.appendFilterToURL(hiddenGroupIds, visibleOfflineGroupIds);
    if (!!hiddenGroupIds) {
      localStorage.setItem(this.HIDDEN_GROUPS_SETTINGS_KEY, hiddenGroupIds);
    } else {
      localStorage.removeItem(this.HIDDEN_GROUPS_SETTINGS_KEY);
    }
    if (!!visibleOfflineGroupIds) {
      localStorage.setItem(this.OFFLINE_VISIBLE_GROUPS_SETTINGS_KEY, visibleOfflineGroupIds);
    } else {
      localStorage.removeItem(this.OFFLINE_VISIBLE_GROUPS_SETTINGS_KEY);
    }

    this.filterChanged.emit(
      {
        hiddenGroups: this.hiddenGroups,
        visibleOfflineGroups: this.visibleOfflineGroups,
      } as VehicleFilter
    );
  }

  private appendFilterToURL(hiddenGroupIdsString: string, visibleOfflineGroupIdsString: string) {
    this.router.navigate([], {
      queryParams: {
        [this.HIDDEN_GROUPS_URL_PARAM_NAME]: hiddenGroupIdsString,
        [this.OFFLINE_VISIBLE_GROUPS_URL_PARAM_NAME]: visibleOfflineGroupIdsString,
      },
      queryParamsHandling: 'merge',
    });
  }

  private getCachedHiddenGroupIds(): string[] {
    const hiddenGroupIdsString = localStorage.getItem(
      this.HIDDEN_GROUPS_SETTINGS_KEY
    );

    if (hiddenGroupIdsString) {
      return hiddenGroupIdsString.split(':::');
    }

    return [];
  }

  private getCachedVisibleOfflineGroupIds(): string[] {
    const visibleOfflineGroupIdsString = localStorage.getItem(
      this.OFFLINE_VISIBLE_GROUPS_SETTINGS_KEY
    );

    if (visibleOfflineGroupIdsString) {
      return visibleOfflineGroupIdsString.split(':::');
    }

    return [];
  }
}
