<div>
    <mat-tree #treeControl
			  [dataSource]="dataSource"
			  [childrenAccessor]="childrenAccessor"
			  class="route-tree">
        <!-- This is the tree node template for leaf nodes -->
        <!-- There is inline padding applied to this node using styles.
          This padding value depends on the mat-icon-button width. -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle
					   class="hover-node"
					   (mouseenter)="onHoverNode(node)"
					   (mouseleave)="onLeaveNode(node)">
            @if (filteringMode) {
				<mat-checkbox class="checklist-leaf-node"
							  [checked]="checklistSelection.isSelected(node)"
							  (change)="leafItemSelectionToggle(node)">
					<span>{{node.value}}</span>
				</mat-checkbox>
			} @else {
				<span>{{node.value}}</span>
				<div class="node-actions">
					<button
							(colorPickerChange)="colorChanged()"
							[(colorPicker)]="node.color"
							[cpDisabled]="!filteringMode"
							[disabled]="!filteringMode"
							[cpAlphaChannel]="'disabled'"
							mat-icon-button
							[style.color]="!!node.color ? node.color : colorNoOverride"
					>
						<mat-icon>palette</mat-icon>
					</button>
					<button
							*ngIf="!!node.color"
							(click)="clearColor(node)"
							[matTooltip]="'Remove color override'"
							mat-icon-button
							color="accent"
					>
						<mat-icon>close</mat-icon>
					</button>
					<button
							mat-icon-button
							[disabled]="!filteringMode"
							[style.color]="!!node.lineType ? node.color : colorNoOverride"
							[matMenuTriggerFor]="menu"
					>
						<mat-icon *ngIf="!node.lineType">horizontal_rule</mat-icon>
						<div class="line-type-wrapper">
							<app-line-dash-array-view
									*ngIf="!!node.lineType"
									[dashArray]="node.lineType"
							></app-line-dash-array-view>
						</div>
					</button>
					<mat-menu #menu="matMenu">
						<div class="line-type-wrapper">
							<div *ngFor="let dashArray of allDashArrays; let dashArrayIndex = index" class="dash-array-wrapper">
								<app-line-dash-array-view [dashArray]="dashArray"
														  (click)="selectDashArray(node, dashArrayIndex)">
								</app-line-dash-array-view>
							</div>
						</div>
					</mat-menu>
					<button
							mat-icon-button
							*ngIf="!!node.lineType"
							(click)="clearDashArray(node)"
							[matTooltip]="'Remove line type override'"
							color="accent"
					>
						<mat-icon>close</mat-icon>
					</button>
				</div>
			}
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
            <div class="mat-tree-node hover-node"
				 (mouseenter)="onHoverNode(node)"
				 (mouseleave)="onLeaveNode(node)">
                <button mat-icon-button matTreeNodeToggle
                        [attr.aria-label]="'Toggle ' + node.value">
                    <mat-icon class="mat-icon-rtl-mirror">
                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
						chevron_right
                    </mat-icon>
                </button>
				@if (filteringMode) {
					<mat-checkbox [checked]="descendantsAllSelected(node)"
								  [indeterminate]="descendantsPartiallySelected(node)"
								  (change)="itemSelectionToggle(node)">
						<span>{{node.value}}</span>
					</mat-checkbox>
				}
				@else {
					<span>{{node.value}}</span>
                	<div class="node-actions" *ngIf="getLevel(node) > 0">
                    <button
                            (colorPickerChange)="colorChanged()"
                            [(colorPicker)]="node.color"
                            [cpDisabled]="!filteringMode"
                            [disabled]="!filteringMode"
                            [cpAlphaChannel]="'disabled'"
                            mat-icon-button
                            [style.color]="!!node.color ? node.color : colorNoOverride"
                    >
                        <mat-icon>palette</mat-icon>
                    </button>
                    <button
                            *ngIf="!!node.color"
                            (click)="clearColor(node)"
                            [matTooltip]="'Remove color override'"
                            mat-icon-button
                            color="accent"
                    >
                        <mat-icon>close</mat-icon>
                    </button>
                    <button
                            mat-icon-button
                            [disabled]="!filteringMode"
                            [style.color]="!!node.lineType ? node.color : colorNoOverride"
                            [matMenuTriggerFor]="menu"
                    >
                        <mat-icon *ngIf="!node.lineType">horizontal_rule</mat-icon>
                        <div class="line-type-wrapper">
                            <app-line-dash-array-view
                                    *ngIf="!!node.lineType"
                                    [dashArray]="node.lineType"
                            ></app-line-dash-array-view>
                        </div>
                    </button>
                    <mat-menu #menu="matMenu">
                        <div class="line-type-wrapper">
                            <div *ngFor="let dashArray of allDashArrays; let dashArrayIndex = index" class="dash-array-wrapper">
                                <app-line-dash-array-view
                                        [dashArray]="dashArray"
                                        (click)="selectDashArray(node, dashArrayIndex)"
                                ></app-line-dash-array-view>
                            </div>
                        </div>
                    </mat-menu>
                    <button
                            mat-icon-button
                            *ngIf="!!node.lineType"
                            (click)="clearDashArray(node)"
                            [matTooltip]="'Remove line type override'"
                            color="accent"
                    >
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
				}
            </div>
            <!-- There is inline padding applied to this div using styles.
                This padding value depends on the mat-icon-button width.  -->
            <div [class.route-tree-invisible]="!treeControl.isExpanded(node)"
                 role="group">
                <ng-container matTreeNodeOutlet></ng-container>
            </div>
        </mat-nested-tree-node>
    </mat-tree>
</div>
