import {Injectable} from '@angular/core';
import {SettingsService, ActivityFilter, StatusLayerType} from '../../../../configuration/settings.service';
import {VehiclesManagerService} from '../../../../data/vehicles/vehicles-manager.service';
import {ShiftsManagerService} from '../../../../data/shifts/shifts-manager.service';
import {Subscription} from 'rxjs';
import {ShiftModel, ShiftState} from '../../../models/shift.model';
import {LayerSpecification, LineLayerSpecification, LngLatBounds, SymbolLayerSpecification} from 'maplibre-gl';
import {MapLayersManager} from '../map-layers-manager';
import {MapStyles, VehicleColor} from '../../../../configuration/map-styles';
import {LiveMapDataService} from '../../../../pages/live-map/services/live-map-data.service';
import {VehicleModelWithActiveShift} from '../../../models/vehicle.model';
import {MapFilters} from '../../../../configuration/map-filters';
import {HttpClient} from '@angular/common/http';
import {FeatureCollection, GeoJSON, LineString} from 'geojson';
import {ServerEventService} from '../../../../data/server-events/server-event.service';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {Asset} from '../../../../pages/live-map/models/asset.class';
import {AssetsManagerService} from '../../../../data/assets/assets-manager.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {MainRoute} from '../../../models/angular-routing';

@Injectable({
  providedIn: 'root'
})
export class TracksGeoJsonLayerService {

  // normal mode layers
  static readonly TRACKS_GEOJSON_SOURCE_ID = 'tracks-geojson';
  static readonly LAYER_ID_TRACK_GEOJSON = 'track-geojson';
  static readonly LAYER_ID_TRACK_HISTORY_GEOJSON = 'track-geojson-history';

  // selected active shift mode layers
  static readonly LAYER_ID_TRACK_ACTIVE_SHIFT = 'track-geojson-active-shift';
  static readonly LAYER_ID_PLOW_DOWN_ACTIVE_SHIFT = 'track-geojson-active-shift-plow-down';
  static readonly LAYER_ID_SPREADER_ON_ACTIVE_SHIFT = 'track-geojson-active-shift-spreader-on';
  static readonly LAYER_ID_ARROWS_ACTIVE_SHIFT = 'track-geojson-active-shift-arrows';
  static readonly ICON_ARROW = 'arrow';

  private mapLayersManager: MapLayersManager;
  private lineLayers: LayerSpecification[] = [];
  private sourceData: FeatureCollection;

  private readonly openSubscriptions = Array<Subscription>();

  selectedVehicleIds = [];
  activeShiftIds = [];
  vehicleDetailMode = false;
  highlightedShift: ShiftModel = null;
  // selected track layer type
  private currentTheme: StatusLayerType = null;
  // live feed?
  isEnabled: boolean;
  // custom vehicle colors
  customColors: VehicleColor[] = [];
  // flags filter
  private flagsFilter: any[] = [];
  // recent toggle on track bar
  showRecent = false;
  // vector tile cache should not be older than 1 hour
  hours = 12;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private serverEventService: ServerEventService,
              private settingsService: SettingsService,
              private vehiclesManager: VehiclesManagerService,
              private shiftsManagerService: ShiftsManagerService,
              private assetManager: AssetsManagerService,
              private liveMapDataService: LiveMapDataService,
              private configurationService: ConfigurationService,
              private http: HttpClient
              ) { }

  init(mapLayersManager: MapLayersManager, isEnabled: boolean) {
    if (!!this.mapLayersManager) {
      throw Error('The map layers manager has already been set.');
    }
    this.mapLayersManager = mapLayersManager;
    this.isEnabled = isEnabled;
    this.addSourceAndLayers();
    this.connectToManager();
  }

  release() {
    if (!this.mapLayersManager) {
      throw Error('The map has not been set!');
    }

    for (const subscription of this.openSubscriptions) {
      subscription.unsubscribe();
    }
    this.openSubscriptions.length = 0;
    this.mapLayersManager = null;
  }

  private handleWebSocketGeoJsonUpdate(geojson: GeoJSON) {
    this.sourceData = geojson as FeatureCollection;
    const source = this.mapLayersManager.getGeoJsonSource(TracksGeoJsonLayerService.TRACKS_GEOJSON_SOURCE_ID);
    if (!!source) {
      source.setData(geojson);
    }
  }

  addSourceAndLayers() {
    this.mapLayersManager.addSource(TracksGeoJsonLayerService.TRACKS_GEOJSON_SOURCE_ID, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    });

    // add layers
    this.currentTheme = this.settingsService.getStringValue(SettingsService.TRACKS_LAYER_TYPE_KEY) as StatusLayerType;
    const isVisible = this.isEnabled ? 'visible' : 'none';
    this.getLineLayers().forEach(layer => {
      layer.layout.visibility = isVisible;
      this.lineLayers.push(layer);
      this.mapLayersManager.addLayer(layer as LineLayerSpecification | SymbolLayerSpecification);
    });
  }

  private handleCustomVehicleMapColor(vehicles: VehicleModelWithActiveShift[]) {
    this.customColors = vehicles
        .filter(vehicle => !!vehicle.mapColor)
        .map(vehicle => {
          return {id: vehicle.id, color: vehicle.mapColor};
        });
    this.updateFiltersAndStyles();
  }

  private handleHighlightedShiftChanged(shift: ShiftModel) {
    this.highlightedShift = shift;
    this.updateFiltersAndStyles();
  }

  private handleVehiclesChanged(assets: Asset[]) {
    const activeShiftIds = [];
    this.selectedVehicleIds = assets.map(asset => {
      if (asset.shiftStatus !== ShiftState.ENDED && !!asset.shiftId) {
        activeShiftIds.push(asset.shiftId);
      }
      return asset.id;
    });
    this.activeShiftIds = activeShiftIds;
    this.updateFiltersAndStyles();
  }

  private handleTrackFilterChanged(trackFilter: ActivityFilter) {
    this.flagsFilter = MapFilters.getTrackFilter(trackFilter);
    this.updateFiltersAndStyles();
  }

  private handleShowRecentChanged(showRecent: boolean) {
    this.showRecent = showRecent;
    this.updateFiltersAndStyles();
  }

  private connectToManager() {
    const that = this;

    this.vehicleDetailMode =
        !!this.activatedRoute.firstChild?.firstChild?.firstChild?.firstChild?.snapshot?.routeConfig?.path?.startsWith(MainRoute.VEHICLE + '/:id');

    const settingsChangedSubscription = this.settingsService.settingsChangedObservable.subscribe({
      next(newSettings) {
        if (newSettings.key === SettingsService.TRACKS_LAYER_TYPE_KEY) {
          that.currentTheme = newSettings.value;
          that.updateFiltersAndStyles();
        }
        if (newSettings.key === SettingsService.ACTIVITY_FILTER) {
          that.handleTrackFilterChanged(newSettings.value);
        }
        if (newSettings.key === SettingsService.PLOW_TRACK_SHOW_RECENT) {
          that.handleShowRecentChanged(newSettings.value);
        }
      }
    });
    this.openSubscriptions.push(settingsChangedSubscription);

    const routerSubscription = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        const oldIsVehicleMode = this.vehicleDetailMode;
        this.vehicleDetailMode =
            !!this.activatedRoute.firstChild?.firstChild?.firstChild?.firstChild?.snapshot?.routeConfig?.path?.startsWith(MainRoute.VEHICLE + '/:id');

        // if any change
        if (oldIsVehicleMode !== this.vehicleDetailMode) {
          this.updateFiltersAndStyles();
        }
      }
    });
    this.openSubscriptions.push(routerSubscription);

    const allVehiclesSubscription = this.liveMapDataService.vehicles$.subscribe(vehicles => {
      this.handleCustomVehicleMapColor(vehicles);
    });
    this.openSubscriptions.push(allVehiclesSubscription);

    const assetsSubscription = this.assetManager.filteredAssets$.subscribe(assets => {
      this.handleVehiclesChanged(assets);
    });
    this.openSubscriptions.push(assetsSubscription);

    const highlightingUpdateSubscription = this.shiftsManagerService.highlightedShift$.subscribe({
      next(shiftFilterUpdate) {
        that.handleHighlightedShiftChanged(shiftFilterUpdate.shift);
      }
    });
    this.openSubscriptions.push(highlightingUpdateSubscription);

    const cacheChangeSubscription = this.serverEventService.historyLocationsObservable.subscribe({
      next(geojson: GeoJSON) {
        that.handleWebSocketGeoJsonUpdate(geojson);
      }
    });
    this.openSubscriptions.push(cacheChangeSubscription);
  }

  private getTrackHistoryLayer(): LineLayerSpecification {
    const layer = {
      type: 'line',
      filter: !this.vehicleDetailMode && MapFilters.getInactiveShiftsFilter(
        this.currentTheme === StatusLayerType.GPS_TRACKS,
        this.vehicleDetailMode,
        this.activeShiftIds,
        this.selectedVehicleIds,
        this.hours,
        this.flagsFilter,
        this.showRecent,
      ),
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': this.configurationService.trackStyles.liveMapPlowInactive.color,
        'line-opacity': this.configurationService.trackStyles.liveMapPlowInactive.opacity,
        'line-width': this.configurationService.trackStyles.liveMapPlowInactive.width,
      }
    } as LineLayerSpecification;
    layer.id = TracksGeoJsonLayerService.LAYER_ID_TRACK_HISTORY_GEOJSON;
    layer.source = TracksGeoJsonLayerService.TRACKS_GEOJSON_SOURCE_ID;
    return layer;
  }

  private getActiveTrackLayer(): LineLayerSpecification {
    const layer = {
      type: 'line',
      filter: !this.vehicleDetailMode && MapFilters.getActiveShiftsFilter(
          this.currentTheme === StatusLayerType.GPS_TRACKS,
          this.vehicleDetailMode,
          this.activeShiftIds,
          this.selectedVehicleIds,
          this.hours,
          this.flagsFilter,
          this.highlightedShift,
      ),
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': this.getHighlightedColor(),
        'line-opacity': this.configurationService.trackStyles.liveMapPlowLive.opacity,
        'line-width': this.getHighlightedWidth(),
      }
    } as LineLayerSpecification;
    layer.id = TracksGeoJsonLayerService.LAYER_ID_TRACK_GEOJSON;
    layer.source = TracksGeoJsonLayerService.TRACKS_GEOJSON_SOURCE_ID;
    return layer;
  }

  private getSelectedShiftLayers(): LayerSpecification[] {
    return [
      {
        id: TracksGeoJsonLayerService.LAYER_ID_TRACK_ACTIVE_SHIFT,
        type: 'line',
        source: TracksGeoJsonLayerService.TRACKS_GEOJSON_SOURCE_ID,
        filter: this.vehicleDetailMode && MapFilters.getShiftGeoJsonFilter(
            ActivityFilter.NONE, null, null, this.activeShiftIds,
        ),
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': this.configurationService.trackStyles.shiftPlowNormal.color,
          'line-opacity': this.configurationService.trackStyles.shiftPlowNormal.opacity,
          'line-width': this.configurationService.trackStyles.shiftPlowNormal.width,
        }
      } as LineLayerSpecification,
      {
        id: TracksGeoJsonLayerService.LAYER_ID_PLOW_DOWN_ACTIVE_SHIFT,
        type: 'line',
        source: TracksGeoJsonLayerService.TRACKS_GEOJSON_SOURCE_ID,
        filter: this.vehicleDetailMode && MapFilters.getShiftGeoJsonFilter(
            ActivityFilter.PLOWING_MOWING_SWEEPING, null, null, this.activeShiftIds,
        ),
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': this.configurationService.trackStyles.shiftPlowPlowDown.color,
          'line-opacity': this.configurationService.trackStyles.shiftPlowPlowDown.opacity,
          'line-width': this.configurationService.trackStyles.shiftPlowPlowDown.width,
          'line-offset': this.configurationService.trackStyles.shiftPlowPlowDown.offset,
        }
      } as LineLayerSpecification,
      {
        id: TracksGeoJsonLayerService.LAYER_ID_SPREADER_ON_ACTIVE_SHIFT,
        type: 'line',
        source: TracksGeoJsonLayerService.TRACKS_GEOJSON_SOURCE_ID,
        filter: this.vehicleDetailMode && MapFilters.getShiftGeoJsonFilter(
            ActivityFilter.SPREADING, null, null, this.activeShiftIds,
        ),
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': this.configurationService.trackStyles.shiftPlowSpreaderOn.color,
          'line-opacity': this.configurationService.trackStyles.shiftPlowSpreaderOn.opacity,
          'line-width': this.configurationService.trackStyles.shiftPlowSpreaderOn.width,
          'line-offset': this.configurationService.trackStyles.shiftPlowSpreaderOn.offset,
        }
      } as LineLayerSpecification,
      {
        id: TracksGeoJsonLayerService.LAYER_ID_ARROWS_ACTIVE_SHIFT,
        type: 'symbol',
        source: TracksGeoJsonLayerService.TRACKS_GEOJSON_SOURCE_ID,
        filter: this.vehicleDetailMode && MapFilters.getShiftGeoJsonFilter(
            ActivityFilter.NONE, null, null, this.activeShiftIds,
        ),
        layout: {
          'symbol-placement': 'line',
          'icon-image': TracksGeoJsonLayerService.ICON_ARROW,
          'icon-rotate': 90,
          'icon-rotation-alignment': 'map',
          'icon-allow-overlap': true,
          'icon-ignore-placement': true
        },
        paint: {
        }
      } as SymbolLayerSpecification,
    ];
  }

  getLineLayers(): LayerSpecification[] {
    return [
      this.getTrackHistoryLayer(),
      this.getActiveTrackLayer(),
      ...this.getSelectedShiftLayers(),
    ];
  }

  updateFiltersAndStyles() {
    this.lineLayers.forEach(lineLayer => {
      switch (lineLayer.id) {
        case TracksGeoJsonLayerService.LAYER_ID_TRACK_HISTORY_GEOJSON:
          this.mapLayersManager.setFilter(
              lineLayer.id,
              !this.vehicleDetailMode && MapFilters.getInactiveShiftsFilter(
              this.currentTheme === StatusLayerType.GPS_TRACKS,
              this.vehicleDetailMode,
              this.activeShiftIds,
              this.selectedVehicleIds,
              this.hours,
              this.flagsFilter,
              this.showRecent,
          ));
          this.mapLayersManager.setLayerPaintProperty(
              lineLayer.id,
              'line-color',
              this.configurationService.trackStyles.liveMapPlowInactive.color,
          );
          this.mapLayersManager.setLayerPaintProperty(
              lineLayer.id,
              'line-width',
              this.configurationService.trackStyles.liveMapPlowInactive.width,
          );
          break;
        case TracksGeoJsonLayerService.LAYER_ID_TRACK_GEOJSON:
          this.mapLayersManager.setFilter(
              lineLayer.id,
              !this.vehicleDetailMode && MapFilters.getActiveShiftsFilter(
              this.currentTheme === StatusLayerType.GPS_TRACKS,
              this.vehicleDetailMode,
              this.activeShiftIds,
              this.selectedVehicleIds,
              this.hours,
              this.flagsFilter,
              this.highlightedShift,
          ));
          this.mapLayersManager.setLayerPaintProperty(
              lineLayer.id,
              'line-color',
              this.getHighlightedColor(),
          );
          this.mapLayersManager.setLayerPaintProperty(
              lineLayer.id,
              'line-width',
              this.getHighlightedWidth(),
          );
          break;
        case TracksGeoJsonLayerService.LAYER_ID_TRACK_ACTIVE_SHIFT:
          this.mapLayersManager.setFilter(
              lineLayer.id,
              this.vehicleDetailMode && MapFilters.getShiftGeoJsonFilter(
                  ActivityFilter.NONE, null, null, this.activeShiftIds,
              ),
          );
          break;
        case TracksGeoJsonLayerService.LAYER_ID_PLOW_DOWN_ACTIVE_SHIFT:
          this.mapLayersManager.setFilter(
              lineLayer.id,
              this.vehicleDetailMode && MapFilters.getShiftGeoJsonFilter(
                  ActivityFilter.PLOWING_MOWING_SWEEPING, null, null, this.activeShiftIds,
              ),
          );
          break;
        case TracksGeoJsonLayerService.LAYER_ID_SPREADER_ON_ACTIVE_SHIFT:
          this.mapLayersManager.setFilter(
              lineLayer.id,
              this.vehicleDetailMode && MapFilters.getShiftGeoJsonFilter(
                  ActivityFilter.SPREADING, null, null, this.activeShiftIds,
              ),
          );
          break;
        case TracksGeoJsonLayerService.LAYER_ID_ARROWS_ACTIVE_SHIFT:
          this.mapLayersManager.setFilter(
              lineLayer.id,
              this.vehicleDetailMode && MapFilters.getShiftGeoJsonFilter(
                  ActivityFilter.NONE, null, null, this.activeShiftIds,
              ),
          );
          break;
        default:
          console.warn('This should not happen! The map layer doesn\'t exist');
      }
    });
  }

  private getHighlightedColor() {
    return !this.vehicleDetailMode && this.currentTheme === StatusLayerType.GPS_TRACKS
        ? MapStyles.getHighlightedColor(
            this.highlightedShift?.id !== undefined ? [this.highlightedShift.id] : null,
            this.configurationService.trackStyles.liveMapPlowHighlighted.color,
            this.configurationService.trackStyles.liveMapPlowLive.color,
            this.customColors,
        )
        : MapStyles.getHighlightedColor(
            this.highlightedShift?.id !== undefined ? [this.highlightedShift.id] : null,
            '#A0A0A0',
            this.configurationService.trackStyles.liveMapPlowLive.color,
            this.customColors,
        );
  }

  private getHighlightedWidth() {
    return !this.vehicleDetailMode && this.currentTheme === StatusLayerType.GPS_TRACKS
        ? MapStyles.getHighlightedProperty(
            this.highlightedShift?.id !== undefined ? [this.highlightedShift.id] : null,
            this.configurationService.trackStyles.liveMapPlowHighlighted.width,
            this.configurationService.trackStyles.liveMapPlowLive.width,
        )
        : MapStyles.getHighlightedProperty(
            this.highlightedShift?.id !== undefined ? [this.highlightedShift.id] : null,
            this.configurationService.trackStyles.liveMapPlowLive.width,
            this.configurationService.trackStyles.liveMapPlowLive.width,
        );
  }

  public getBounds(shiftId: number) {
    const features = this.sourceData?.features?.filter(feature => feature.properties['sessionkey'] === shiftId);
    if (!!features && features.length > 0) {
      const coordinatesByFeature = features.map(feature => (feature.geometry as LineString).coordinates);
      // flatten the array Position[][] to Position[]
      const coordinates = coordinatesByFeature.reduce((accumulator, value) => accumulator.concat(value), []);
      if (!!coordinates && coordinates.length > 0) {
        return coordinates.reduce((bounds, position) => {
          return bounds.extend([position[0], position[1]]);
        }, new LngLatBounds([coordinates[0][0], coordinates[0][1]], [coordinates[0][0], coordinates[0][1]]));
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}
