import {Component} from '@angular/core';
import {VehicleModel} from '../../../../../shared/models/vehicle.model';
import {EditVehicleComponentInputData} from '../edit-vehicle/vehicle.types';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {
  AddOrImportVehicleDialogComponent
} from '../add-or-import-vehicle-dialog/add-or-import-vehicle-dialog.component';
import {BehaviorSubject, combineLatest, firstValueFrom, mergeMap, Observable, tap} from 'rxjs';
import {map} from 'rxjs/operators';
import {VehiclesService} from '../../../../../data/vehicles/vehicles.service';
import {MatDialog} from '@angular/material/dialog';
import {ConfigurationService} from '../../../../../configuration/configuration.service';
import {ConfigurationModel, FeatureFlagEnum} from '../../../../../shared/models/configuration.model';
import {
  CardContentDirective,
  CardContentRawDirective
} from '../../../../../shared/components/card/card-content.directive';
import {AsyncPipe} from '@angular/common';
import {CardComponent} from '../../../../../shared/components/card/card.component';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {MatListItem, MatListItemMeta, MatListItemTitle, MatNavList} from '@angular/material/list';
import {MatIcon} from '@angular/material/icon';
import {MatButton} from '@angular/material/button';

@Component({
  selector: 'app-vehicle-list',
  standalone: true,
  imports: [
    AsyncPipe,
    CardComponent,
    MatProgressSpinner,
    MatListItem,
    MatIcon,
    CardContentRawDirective,
    MatListItemMeta,
    RouterLink,
    MatListItemTitle,
    MatNavList,
    CardContentDirective,
    MatButton
  ],
  templateUrl: './vehicle-list.component.html',
  styleUrls: ['./vehicle-list.component.scss', '../../../../settings/settings-common.scss']
})
export class VehicleListComponent {
  vehicles: Observable<VehicleModel[]>
  reloadVehiclesSubject = new BehaviorSubject<void>(undefined)

  isLoading = true;

  groupId: number;
  configuration: ConfigurationModel;

  constructor(private readonly router: Router,
              private readonly activatedRoute: ActivatedRoute,
              readonly vehicleService: VehiclesService,
              readonly configurationService: ConfigurationService,
              private readonly dialog: MatDialog,
  ) {
    this.vehicles = combineLatest(
      [
        this.activatedRoute.paramMap.pipe(
          map(parameters => parameters.get('id')),
          tap(groupId => this.groupId = Number(groupId))
        ),
        this.reloadVehiclesSubject.pipe(
          tap(() => this.isLoading = true),
          mergeMap(() =>
            vehicleService.getVehicles()
              .pipe(map(response => response.data))
          ),
        ),
        this.configurationService.sharedConfigurationModel,
      ]
    ).pipe(
      tap(([groupId, _, configuration]) => {
        this.configuration = configuration;
        this.isLoading = false;
      }),
      map(([groupId, vehicles]) => vehicles.filter(vehicle => vehicle.groupId === Number(groupId))),
    )
  }

  addVehicle() {
    // open the popup here
    const model = new VehicleModel();
    model.groupId = this.groupId;
    model.hasNoTablet = false;

    const dialogData = {
      model,
      configuration: this.configuration,
      isImported: this.configurationService
        .hasFeatureFlag(this.configuration?.featureFlags, FeatureFlagEnum.CartegraphIntegration),
    } as EditVehicleComponentInputData;

    const d = this.dialog.open(AddOrImportVehicleDialogComponent, {
      width: '550px',
      data: dialogData
    });
    firstValueFrom(d.afterClosed()).then(result => {
      if (result) {
        switch (result.type) {
          case 'singleVehicle':
            this.router.navigate(['settings', 'manage-vehicles', 'vehicle', result.data.id]);
            break;
          case 'vehicleImport':
            this.reloadVehiclesSubject.next();
            break;
          default:
            console.log('Invalid result type', result.type);
        }
      }

    });
  }

  navigateBack() {
    this.router.navigate(['/settings', 'manage-vehicles', 'group', this.groupId]).then();
  }
}
