<div class="left-panel-container noselect">

  <div class="panel-content">
    <div class="left-menu-container">


      <!-- VEHICLES -->
      <div class="group-wrapper ops-form w-100">
        <div class="group">
          <button mat-icon-button
                  (click)="toggleVehiclesExpansion()"
          >
            <mat-icon *ngIf="vehiclesCollapsed" class="tree-level-button">arrow_right</mat-icon>
            <mat-icon *ngIf="!vehiclesCollapsed" class="tree-level-button">arrow_drop_down</mat-icon> <!-- expanded -->

          </button>

          <div class="group-name strong">Vehicles</div>
        </div>

        <div *ngIf="!vehiclesCollapsed">
          <app-vehicle-filter
            class="overlay-filter"
            (filterChanged)="onVehicleFilterChange($event)"
          >
          </app-vehicle-filter> <!-- this has no visible part -->
        </div>
      </div>

      <div class="all-groups-content" *ngIf="!vehiclesCollapsed">
        <ng-container *ngFor="let groupWithItems of vehicleGroupsWithItems; let i = index">
          <div class="group-content-wrapper ops-form"
               [ngClass]="{ expanded: !groupWithItems.collapsed }"
          >
            <div class="group-wrapper">
              <div class="group">
                <button mat-icon-button
                        (click)="toggleVehicleGroupExpansion(i)"
                >
                  <mat-icon *ngIf="groupWithItems.collapsed" class="tree-level-button">arrow_right</mat-icon>
                  <mat-icon *ngIf="!groupWithItems.collapsed" class="tree-level-button">arrow_drop_down</mat-icon> <!-- expanded -->

                </button>

                <div class="group-name"
                     [ngClass]="{ hidden: !groupWithItems.visible }"
                >
                  {{ groupWithItems.name }}
                  <span *ngIf="groupWithItems.activeShiftCount > 0">({{ groupWithItems.activeShiftCount }})</span>
                </div>
              </div>

              <div class="group-actions">
                <button mat-icon-button
                        (click)="toggleVehicleGroupVisibility(i)"

                >
                  <mat-icon *ngIf="groupWithItems.visible" class="visibility-button">visibility</mat-icon>
                  <mat-icon *ngIf="!groupWithItems.visible"
                            class="visibility-button"
                            [ngStyle]="{ display: 'block' }"
                  >visibility_off</mat-icon>
                </button>
              </div>
            </div>

            <div class="items" *ngIf="!groupWithItems.collapsed">
              <div *ngFor="let item of groupWithItems.items" class="item">
                <app-left-menu-item
                        [input]="item"
                ></app-left-menu-item>
              </div>
            </div>

            <div class="offline-toggle" *ngIf="!groupWithItems.collapsed && groupWithItems.activeShiftCount > 0">
              <button mat-button
                      (click)="toggleVehicleGroupOfflineExpansion(i)"
                      class="offline-toggle-button"
              >
                <mat-icon *ngIf="groupWithItems.offlineCollapsed" class="tree-level-button">keyboard_arrow_down</mat-icon>
                <mat-icon *ngIf="!groupWithItems.offlineCollapsed" class="tree-level-button">keyboard_arrow_up</mat-icon> <!-- expanded -->
                Offline
              </button>

              <!--<button mat-icon-button
                      (click)="toggleVehicleGroupOfflineVisibility(i)"

              >
                <mat-icon *ngIf="groupWithItems.offlineVisible" class="visibility-button">visibility</mat-icon>
                <mat-icon *ngIf="!groupWithItems.offlineVisible"
                          class="visibility-button"
                          [ngStyle]="{ display: 'block' }"
                >visibility_off</mat-icon>
              </button>-->
            </div>

            <div class="items" *ngIf="!groupWithItems.collapsed && (!groupWithItems.offlineCollapsed || groupWithItems.activeShiftCount === 0)">
              <div *ngFor="let item of groupWithItems.offlineItems" class="item">
                <app-left-menu-item
                  [input]="item"
                ></app-left-menu-item>
              </div>
            </div>
          </div>
        </ng-container>
      </div>



      <!-- ROUTES -->
      <div class="group-wrapper ops-form">
        <div class="group">
          <button mat-icon-button
                  (click)="toggleRoutesExpansion()"
          >
            <mat-icon *ngIf="routesCollapsed" class="tree-level-button">arrow_right</mat-icon>
            <mat-icon *ngIf="!routesCollapsed" class="tree-level-button">arrow_drop_down</mat-icon>

          </button>

          <div class="group-name strong">Routes</div>
        </div>
      </div>

      <div class="all-groups-content" *ngIf="!routesCollapsed">

        <ng-container *ngFor="let routeHierarchyItem of routeItems">
          <app-route-group-menu
                  [path]="[]"
                  [routeHierarchyItem]="routeHierarchyItem"
                  class="route-configuration-menu-item"
          >
          </app-route-group-menu>
        </ng-container>
      </div>



      <!-- INSIGHTS -->
      <div class="group-wrapper ops-form">
        <div class="group">
          <button mat-icon-button
                  (click)="toggleInsightsExpansion()"
          >
            <mat-icon *ngIf="insightsCollapsed" class="tree-level-button">arrow_right</mat-icon>
            <mat-icon *ngIf="!insightsCollapsed" class="tree-level-button">arrow_drop_down</mat-icon>

          </button>

          <div class="group-name strong">Insights</div>
        </div>
      </div>

      <div *ngIf="!insightsCollapsed" class="insights-items">
        <div *ngFor="let item of insightsSections">
          <app-action-menu-item
                  [input]="item"
          ></app-action-menu-item>
        </div>
      </div>

    </div>

  </div>

</div>
