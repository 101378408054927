import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DigitalSpreader, DigitalSpreaderModel, HardwareConfiguration} from '../../../../../shared/models/vehicle.model';
import {ConfigurationModel} from '../../../../../shared/models/configuration.model';
import {MatCheckboxChange} from '@angular/material/checkbox';

@Component({
  selector: 'app-digital-spreader',
  templateUrl: './digital-spreader.component.html',
  styleUrls: [
    './digital-spreader.component.scss',
    '../../../../settings/settings-fields.scss'
  ]
})
export class DigitalSpreaderComponent implements OnInit {
  @Input() configuration: ConfigurationModel;
  @Input() hardwareConfiguration: HardwareConfiguration;
  @Output() digitalSpreaderChanged = new EventEmitter<DigitalSpreader>();
  digitalSpreader: DigitalSpreader;
  DeviceModel = DigitalSpreaderModel;


  ngOnInit(): void {
    if (!!this.hardwareConfiguration?.digitalSpreader) {
      this.digitalSpreader = this.hardwareConfiguration.digitalSpreader;
    } else {
      this.digitalSpreader = new DigitalSpreader();
    }
  }

  change() {
    this.digitalSpreaderChanged.emit(this.digitalSpreader);
  }

  onReadGranularCheckboxChange() {
    if (!this.digitalSpreader.settings.readGranular) {
      this.digitalSpreader.settings.granularMaxRate = null;
      this.digitalSpreader.settings.granularBlastRate = null;
    }
  }

  onReadLiquidCheckboxChange() {
    if (!this.digitalSpreader.settings.readLiquid) {
      this.digitalSpreader.settings.liquidMaxRate = null;
    }
  }
}
