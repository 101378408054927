import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ShiftReportComponent} from './components/shift-report/shift-report.component';
import {DvirReportComponent} from './components/dvir-report/dvir-report.component';
import {ShiftLookupComponent} from './components/shift-lookup/shift-lookup.component';
import {AddressLookupComponent} from './components/address-lookup/address-lookup.component';
import {ImageRetrievalComponent} from './components/image-retrieval/image-retrieval.component';
import {DeviceReportComponent} from './components/device-report/device-report/device-report.component';
import { ShiftMapComponent } from '../shift-map/components/shift-map.component';

export enum InsightsRoute {
  SHIFT_LOOKUP = 'shift-lookup',
  SHIFT_REPORT = 'shift-report',
  SHIFT_DETAIL = 'shift-detail',
  DVIR_REPORT = 'dvir-report',
  IMAGE_REPORT = 'image-report',
  ADDRESS_LOOKUP = 'address-lookup',
  DEVICE_REPORT = 'device-report',
}

const routes: Routes = [
  {
    path: InsightsRoute.SHIFT_REPORT,
    component: ShiftReportComponent,
  },
  {
    path: `${InsightsRoute.SHIFT_REPORT}/${InsightsRoute.SHIFT_DETAIL}/:id`,
    component: ShiftMapComponent,
  },
  {
    path: InsightsRoute.DVIR_REPORT,
    component: DvirReportComponent,
  },
  {
    path: `${InsightsRoute.DVIR_REPORT}/${InsightsRoute.SHIFT_DETAIL}/:id`,
    component: ShiftMapComponent,
  },
  {
    path: InsightsRoute.SHIFT_LOOKUP,
    component: ShiftLookupComponent,
  },
  {
    path: `${InsightsRoute.SHIFT_LOOKUP}/${InsightsRoute.SHIFT_DETAIL}/:id`,
    component: ShiftMapComponent,
  },
  {
    path: InsightsRoute.IMAGE_REPORT,
    component: ImageRetrievalComponent,
  },
  {
    path: `${InsightsRoute.IMAGE_REPORT}/${InsightsRoute.SHIFT_DETAIL}/:id`,
    component: ShiftMapComponent,
  },
  {
    path: InsightsRoute.ADDRESS_LOOKUP,
    component: AddressLookupComponent,
  },
  {
    path: `${InsightsRoute.ADDRESS_LOOKUP}/${InsightsRoute.SHIFT_DETAIL}/:id`,
    component: ShiftMapComponent,
  },
  {
    path: InsightsRoute.DEVICE_REPORT,
    component: DeviceReportComponent,
  },
  {
    path: `${InsightsRoute.DEVICE_REPORT}/${InsightsRoute.SHIFT_DETAIL}/:id`,
    component: ShiftMapComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
})
export class InsightsRoutingModule { }
