<h1 mat-dialog-title>
  Confirmation
</h1>

<mat-dialog-content>
  <p>
    Are you sure you want to delete route configuration named <strong>{{data.name}}</strong>?
  </p>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close cdkFocusInitial>
	  Cancel
  </button>
  <button mat-flat-button [mat-dialog-close]="data">
    Delete Route Configuration
  </button>
</mat-dialog-actions>
