import {NgModule} from '@angular/core';
import {ManageCartegraphComponent} from './manage-cartegraph.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../../../../shared/material/material.module';
import {SharedModule} from '../../../../shared/shared.module';
import {MatTreeModule} from '@angular/material/tree';
import {CartegraphSetupComponent} from './cartegraph-setup/cartegraph-setup.component';
import {CartegraphConfigComponent} from './cartegraph-config/cartegraph-config.component';
import {CartegraphImportComponent} from './cartegraph-import/cartegraph-import.component';
import {MatStepperModule} from '@angular/material/stepper';
import {CartegraphFetchComponent} from './cartegraph-fetch/cartegraph-fetch.component';
import {CartegraphTasksComponent} from './cartegraph-tasks/cartegraph-tasks.component';
import {
  CartegraphTasksImportComponent,
  CartegraphTasksImportDialogComponent
} from './cartegraph-tasks-import/cartegraph-tasks-import.component';
import {CartegraphSetupStepperComponent} from './cartegraph-setup-stepper/cartegraph-setup-stepper.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {
  CartegraphSettingsFeatures
} from './cartegraph-features/cartegraph-settings-features.component';
import {
  CartegraphSettingsImportFilters
} from './cartegraph-config/cartegraph-settings/cartegraph-settings-import-filters.component';
import {
  CartegraphSettingsDefaults
} from './cartegraph-config/cartegraph-settings/cartegraph-settings-defaults.component';
import {CartegraphSettingsProcessing} from './cartegraph-processing/cartegraph-settings-processing.component';
import {CgTaskStatusUpdateDialog} from './cartegraph-tasks/cartegraph-tasks-status.copmponent';
import {MatDialogModule} from '@angular/material/dialog';
import {CartegraphSettingsObservations} from './cartegraph-observations/cartegraph-settings-observations.component';
import {RowWithToggleComponent} from '../row-with-toggle/row-with-toggle.component';

@NgModule({
  declarations: [
    ManageCartegraphComponent,
    CartegraphSetupStepperComponent,
    CartegraphSetupComponent,
    CartegraphConfigComponent,
    CartegraphFetchComponent,
    CartegraphImportComponent,
    CartegraphTasksComponent,
    CartegraphTasksImportComponent,
    CartegraphTasksImportDialogComponent,
    CartegraphSettingsFeatures,
    CartegraphSettingsImportFilters,
    CartegraphSettingsDefaults,
    CartegraphSettingsProcessing,
    CartegraphSettingsObservations,
    CgTaskStatusUpdateDialog
  ],
  exports: [
    ManageCartegraphComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule,
    MatDialogModule,
    MatTreeModule,
    MatStepperModule,
    MatGridListModule,
    RowWithToggleComponent,
  ]
})
export class CartegraphManagementModule {
}
