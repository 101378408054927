<h2 mat-dialog-title>Drivers Import - Cartegraph</h2>
<mat-dialog-content>
	@if (currentState === ImportDriversStateEnum.NOOP) {
		<p>
			The import will load users from Cartegraph and ingest the data into the PlowOps.
		</p>

		@if (uiError?.length > 0) {
			<div class="mt-15">
				<span class="ui-error">{{ uiError }}</span>
			</div>
		}
	}

	@if (currentState === ImportDriversStateEnum.WORKING) {
		Processing Cartegraph import.
		<mat-progress-bar mode="indeterminate"></mat-progress-bar>
	}

	@if (currentState === ImportDriversStateEnum.IMPORTED) {
		<div class="pt-15 pb-15">
			<mat-accordion>
				<mat-expansion-panel *ngFor="let key of importKeys">
					<mat-expansion-panel-header>
						<mat-panel-title>
							{{key.label}}: {{importResult[key.name]?.length || 0}}
						</mat-panel-title>
					</mat-expansion-panel-header>
					<ol>
						<li *ngFor="let row of importResult[key.name]">{{row.key}}<span
								*ngIf="key.name==='error'">{{row.note}}</span></li>
					</ol>
				</mat-expansion-panel>
			</mat-accordion>
			<p class="pt-15">
				Users have been imported. See above for detailed information.
			</p>
		</div>
	}

	<ng-template #table let-data="importData" let-displayedColumns="displayedColumns">
		<table *ngIf="data && data.length>0" mat-table
			   [dataSource]="data">

			<ng-container matColumnDef="selected">
				<th mat-header-cell *matHeaderCellDef> Import</th>
				<td mat-cell *matCellDef="let element">
					<mat-checkbox [(ngModel)]="element.selected"></mat-checkbox>
				</td>
			</ng-container>

			<ng-container matColumnDef="line">
				<th mat-header-cell *matHeaderCellDef> Line</th>
				<td mat-cell *matCellDef="let element">{{element.index}}</td>
			</ng-container>

			<ng-container matColumnDef="driverName">
				<th mat-header-cell *matHeaderCellDef> Name</th>
				<td mat-cell *matCellDef="let element">{{element.row.driverName}} </td>
			</ng-container>

			<ng-container matColumnDef="login">
				<th mat-header-cell *matHeaderCellDef> Login</th>
				<td mat-cell *matCellDef="let element">{{element.dbData?.driver?.login || '-'}} </td>
			</ng-container>

			<ng-container matColumnDef="errors">
				<th mat-header-cell *matHeaderCellDef> Errors/Notes</th>
				<td mat-cell *matCellDef="let element">
					<div *ngFor="let error of element.errors">{{error}}</div>
					<div *ngIf="element.action==='NONE'">skipping import - driver already exists / is portal or admin user
					</div>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>
	</ng-template>
</mat-dialog-content>

<mat-dialog-actions align="end">
	<button mat-stroked-button [mat-dialog-close]="currentState === ImportDriversStateEnum.IMPORTED">
		@if (currentState === ImportDriversStateEnum.IMPORTED) {
			Close Dialog
		} @else {
			Cancel
		}
	</button>
	@if (currentState !== ImportDriversStateEnum.IMPORTED) {
		<button mat-flat-button color="primary"
				[disabled]="currentState === ImportDriversStateEnum.WORKING"
				(click)="doImport()">Import Users</button>
	}
</mat-dialog-actions>
