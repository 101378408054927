import {Injectable} from '@angular/core';
import {AuthenticatorService} from '@aws-amplify/ui-angular';
import {fetchAuthSession, fetchUserAttributes, FetchUserAttributesOutput} from 'aws-amplify/auth';
import {BehaviorSubject, firstValueFrom} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import { Hub } from 'aws-amplify/utils';


@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  idToken: string;
  idTokenPayload: { [key: string]: any };
  idTokenPayload$ = new BehaviorSubject<{ [key: string]: any } | undefined>(undefined);

  accessToken: string;
  accessTokenPayload: { [key: string]: any };

  constructor(
    private authenticator: AuthenticatorService,
  ) {
    this.fetchIdToken();

    Hub.listen('auth', ({ payload }) => {
      switch (payload.event) {
        case 'signedIn':
          this.fetchIdToken();
          break;
      }
    });
  }

  private fetchIdToken() {
    fetchAuthSession().then(res => {
      const accessToken = res.tokens.accessToken;
      const idToken = res.tokens.idToken;

      // You can print them to see the full objects
      // console.log(`myAccessToken: ${JSON.stringify(accessToken)}`);
      // console.log(`myIdToken: ${JSON.stringify(idToken)}`);

      this.idToken = idToken.toString();
      this.idTokenPayload = idToken.payload;
      this.idTokenPayload$.next(this.idTokenPayload);
      // console.log(this.idTokenPayload);

      this.accessToken = accessToken.toString();
      this.accessTokenPayload = accessToken.payload;
    });
  }

  /*
    DO NOT USE, it is missing tenantId
    {
      "sub": "34485408-3031-7013-813e-dfbf2b2ade2a",
      "cognito:groups": [
        "Admin",
        "System"
      ],
      "iss": "https://cognito-idp.us-east-1.amazonaws.com/us-east-1_QIpxgRV2H",
      "client_id": "1ut2nivuotrrpha9gdbfvb00tu",
      "origin_jti": "c365567d-546e-4346-896d-86423e46b52e",
      "event_id": "75311eb5-f73d-4235-81e6-9c81e2d82a93",
      "token_use": "access",
      "scope": "aws.cognito.signin.user.admin",
      "auth_time": 1727167259,
      "exp": 1727170859,
      "iat": 1727167259,
      "jti": "fed3414d-af9d-47d7-bb3f-96c0acab4814",
      "username": "34485408-3031-7013-813e-dfbf2b2ade2a"
    }
   */
  getAccessToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!this.accessToken) {
        fetchAuthSession().then(res => {
          const accessToken = res.tokens.accessToken;

          // You can print them to see the full objects
          // console.log(`myAccessToken: ${JSON.stringify(accessToken)}`);

          this.accessToken = accessToken.toString();
          this.accessTokenPayload = accessToken.payload;
          // console.log(this.accessTokenPayload);
          resolve(this.accessToken);
        });
      } else {
        resolve(this.accessToken);
      }
    });
  }

  /*
    {
      "sub": "34485408-3031-7013-813e-dfbf2b2ade2a",
      "cognito:groups": [
        "Admin",
        "System"
      ],
      "email_verified": true,
      "iss": "https://cognito-idp.us-east-1.amazonaws.com/us-east-1_QIpxgRV2H",
      "cognito:username": "34485408-3031-7013-813e-dfbf2b2ade2a",
      "preferred_username": "1:system@neotreks.com",
      "given_name": "John",
      "origin_jti": "c365567d-546e-4346-896d-86423e46b52e",
      "custom:tenantid": "1",
      "aud": "1ut2nivuotrrpha9gdbfvb00tu",
      "event_id": "75311eb5-f73d-4235-81e6-9c81e2d82a93",
      "token_use": "id",
      "auth_time": 1727167259,
      "tenantId": "1",
      "exp": 1727253659,
      "iat": 1727167259,
      "family_name": "Doe",
      "jti": "e9faf229-fc19-4458-95fb-7ed975f5c8c3",
      "email": "system@neotreks.com"
    }
   */
  getIdToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!this.idToken || this.expiresSoon()) {
        fetchAuthSession().then(res => {
          const idToken = res.tokens.idToken;

          // You can print them to see the full objects
          // console.log(`myIdToken: ${JSON.stringify(idToken)}`);

          this.idToken = idToken.toString();
          this.idTokenPayload = idToken.payload;
          this.idTokenPayload$.next(this.idTokenPayload);
          // console.log(this.idTokenPayload);
          resolve(this.idToken);
        });
      } else {
        resolve(this.idToken);
      }
    });
  }

  private expiresSoon(): boolean {
    if (!this.idTokenPayload) {
      return true;
    }
    const now = new Date().valueOf() / 1000;
    return (this.idTokenPayload.exp - now) < 600;
  }

  isAdminSync(): boolean {
    return !!this.idTokenPayload
      ? this.idTokenPayload['cognito:groups']?.filter((group: string) => group === 'Admin')?.length > 0
      : false;
  }

  isAdminFromIdToken(idTokenPayload: { [key: string]: any }): boolean {
    return idTokenPayload['cognito:groups']?.filter((group: string) => group === 'Admin')?.length > 0 || false;
  }

  isAdmin(): Promise<boolean> {
    return firstValueFrom(
      this.idTokenPayload$.pipe(
        filter(value => !!value),
        map(value => this.isAdminFromIdToken(value))
      )
    );
  }

  public getUserEmail(): Promise<string> {
    return new Promise((resolve, reject) => {
      fetchUserAttributes().then(response => {
        resolve(response.email);
      });
    });
  }

  async getUserAttributes(): Promise<FetchUserAttributesOutput> {
    try {
      return await fetchUserAttributes();
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  public logout() {
    this.authenticator.signOut();
    this.idToken = undefined;
    this.idTokenPayload = undefined;
    this.accessToken = undefined;
    this.accessTokenPayload = undefined;
    this.idTokenPayload$.next(undefined);
    // this.oidcSecurityService.logoffLocal();
    // window.location.href = `${environment.authentication.authority}/login?logout`;
  }
}
