import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AssetStatus} from '../../models/asset-status';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastService} from '../../services/toast.service';
import {MultiSelectFilter, MultiSelectComponent} from '../multi-select-component';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';

/**
 * This component allows to create a multi-select filter of Asset Status entities.
 * It either emits 'undefined' value if no filter is set or an array of entities.
 *
 * In addition, it saves the state of the filter to URL params and browser's storage.
 */

@Component({
  selector: 'app-asset-status-filter',
  templateUrl: './asset-status-filter.component.html',
  styleUrls: ['./asset-status-filter.component.scss']
})
export class AssetStatusFilterComponent extends MultiSelectComponent<AssetStatus> implements OnInit {

  SETTINGS_KEY = 'selectedAssetStatusIds';
  URL_PARAM_NAME = 'assetStatusIds';
  USE_FULLY_CHECKED_AS_DEFAULT = false;

  @Input() origin;
  @Output() filterChanged = new EventEmitter<MultiSelectFilter<AssetStatus>>();

  @ViewChild('menu') menu: MatMenu;
  @ViewChild('matTriggerIs') matTriggerIs: MatMenuTrigger;
  @ViewChild('matTriggerIsNot') matTriggerIsNot: MatMenuTrigger;

  constructor(
      protected router: Router,
      protected route: ActivatedRoute,
      private toast: ToastService,
  ) {
    super(router, route);
  }

  ngOnInit() {
    this.initComponent(AssetStatus.getAll());
  }

  getLabel(item: AssetStatus): string {
    return item.label;
  }

  onApply() {}

  openMenu() {}
}
