import {ReverseGeocodeInfo} from '../../../../../data/address-search/arcgis-api.service';

export class MovementAndLocationDetail {

  // 4 m/s is 14.4 km/h is 8.95 mph
  private static movingSpeedThreshold = 4; // meters per second
  private static directions = ['North', 'North-East', 'East', 'South-East', 'South', 'South-West', 'West', 'North-West'];

  constructor(
    private speed: number,
    private headingInDegrees: number,
    private reverseGeocodeInfo: ReverseGeocodeInfo,
  ) {
  }

  toDescription(): string {
    // true if we have speed info
    const speed = this.speed != null;
    // true if we have heading info
    const heading = this.headingInDegrees != null && this.headingInDegrees >= 0 && this.headingInDegrees < 360;
    // true if we have any location info
    const location = this.reverseGeocodeInfo != null && this.reverseGeocodeInfo.hasCoordinates();

    // true if we have location info with address, false if we have only location coordinates
    const address = location && this.reverseGeocodeInfo.hasAddress();
    // true if moving, false if stopped
    const moving = speed && (
      this.speed > MovementAndLocationDetail.movingSpeedThreshold ||
      this.speed < -MovementAndLocationDetail.movingSpeedThreshold);

    const headingString = heading ? this.headingString() : '';

    if (heading && speed && location) {
      if (moving && address) {
        return `Moving ${headingString} ${this.reverseGeocodeInfo.movingLocationForAsset()}`;
      } else if (moving && !address) {
        return `Moving ${headingString} ${this.reverseGeocodeInfo.coordinatesForAsset()}`;
      } else if (!moving && address) {
        return `Stopped ${this.reverseGeocodeInfo.stoppedLocationForAsset()}`;
      } else /* if (!moving && !address) */ {
        return `Stopped ${this.reverseGeocodeInfo.coordinatesForAsset()}`;
      }
    } else if (heading && speed && !location) {
      if (moving) {
        return `Moving ${headingString}`;
      } else /* if (!moving) */ {
        return `Stopped`;
      }
    } else if (heading && !speed && location) {
      if (address) {
        return `Heading ${headingString} ${this.reverseGeocodeInfo.movingLocationForAsset()}`;
      } else /* if (!address) */ {
        return `Heading ${headingString} ${this.reverseGeocodeInfo.coordinatesForAsset()}`;
      }
    } else if (heading && !speed && !location) {
      return `Heading ${headingString}`;
    } else if (!heading && speed && location) {
      if (moving && address) {
        return `Moving ${this.reverseGeocodeInfo.movingLocationForAsset()}`;
      } else if (moving && !address) {
        return `Moving ${this.reverseGeocodeInfo.coordinatesForAsset()}`;
      } else if (!moving && address) {
        return `Stopped ${this.reverseGeocodeInfo.stoppedLocationForAsset()}`;
      } else /* if (!moving && !address) */ {
        return `Stopped ${this.reverseGeocodeInfo.coordinatesForAsset()}`;
      }
    } else if (!heading && speed && !location) {
      if (moving) {
        return `Moving`;
      } else /* if (!moving) */ {
        return `Stopped`;
      }
    } else if (!heading && !speed && location) {
      if (address) {
        return `${this.reverseGeocodeInfo.movingLocationForAsset()}`;
      } else /* if (!address) */ {
        return `${this.reverseGeocodeInfo.coordinatesForAsset()}`;
      }
    } else /* if (!heading && !speed && !location) */ {
      return '';
    }
  }

  private headingString(): string {
    if (this.headingInDegrees == null || this.headingInDegrees < 0 || this.headingInDegrees >= 360) {
      return '?';
    } else {
      let angle = this.headingInDegrees;
      // tslint:disable-next-line:no-conditional-assignment
      const index = Math.round(((angle %= 360) < 0 ? angle + 360 : angle) / 45) % 8;
      return MovementAndLocationDetail.directions[index];
    }
  }
}
