<h1 mat-dialog-title>
	{{ title }}
</h1>

<mat-dialog-content class="dialog-content">
	<p>
		{{ text }}
	</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
	<button mat-button mat-dialog-close>Cancel</button>
	<button mat-flat-button color="warn" [mat-dialog-close]="true" cdkFocusInitial>Yes</button>
</mat-dialog-actions>
