import {ShiftState} from '../../../shared/models/shift.model';

export class WebSocketEvent<E, T> {
    eventType: E;
    source: MessageSource;
    data: T;
}

export enum MessageSource {
    SHIFT = 'shift',
    OBSERVATION = 'observation',
    MESSAGE = 'message',
    IMAGE = 'image',
    EVENT = 'event',
    ROUTE_ASSIGNMENT = 'routeAssignment',
    VEHICLE_STATUS = 'vehicleStatus',

    VECTOR_TILE_CACHE = 'location-tracking.vector-tiles-cache',
    DERIVED_LAYER_CACHE = 'location-tracking.derived-layers',
    TRACK_GEOMETRY_CACHE = 'location-tracking.track-geometry',
    TRACK_GEOMETRY_HEAD_CACHE = 'location-tracking.track-geometry-head',
}

export enum ShiftEventType {
    START = 'start',
    END = 'end',
    UPDATE = 'update',
}

export class ShiftEventData {
    shiftId: number;
    vehicleId: number;
    driverId: number;
    shiftState: ShiftState;
    start?: Date; // TODO delete?
    devices?: number; // TODO delete?
}

export enum ObservationEventType {
    NEW = 'new',
    DELETE = 'delete',
    UPDATE = 'update',
}

export enum MessageEventType {
    UPDATE = 'update',
}

export class MessageEventData {
    readonly messageId: number;
    readonly driverId: number;
    readonly seenAt: Date;
}

export enum ImageEventType {
    NEW = 'new',
}

export enum RouteAssignmentEventType {
    UPDATE = 'update',
}

export enum VehicleRouteAssignmentStatusEventType {
    UPDATE = 'update',
}

// https://neotreks.visualstudio.com/LocationServices/_git/location-services?path=/doc/PUBLIC_API.md
export interface LocationEventData {
    head: number;
    updated: number;
}
