<div class="config-header">
    <h2 mat-dialog-title>Modify Pass Count Map</h2>

    <div class="config-header-action">
        <button mat-icon-button
                [matTooltip]="'Reset to defaults'"
                (click)="reset()"
                [disabled]="isDefault()"
        >
            <mat-icon>restart_alt</mat-icon>
        </button>
    </div>
</div>

<mat-dialog-content class="mat-typography">

    <app-location-history-category-view
            [layerType]="data.legendSettings"
            (colorsChangedEvent)="onChange($event)"
    ></app-location-history-category-view>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button color="accent" [mat-dialog-close]="data" cdkFocusInitial>Save</button>
</mat-dialog-actions>
