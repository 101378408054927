<div class="legend">
    <div class="legend-header">
        <div>
            Weather
        </div>
    </div>

    <div class="legend-subtitle">
        <span>Live weather radar</span>&nbsp;<a href="https://nowcoast.noaa.gov/" target="_blank">View legend at NOAA website</a>
    </div>
    <div class="legend-settings">
        <div class="toggle-line">
            <span>Watches and Warnings</span>
            <mat-slide-toggle
                    class="mat-small"
                    [(ngModel)]="showWarnings"
                    (change)="toggleWarnings()"
                    [hideIcon]="true"
            ></mat-slide-toggle>
        </div>
    </div>
</div>
