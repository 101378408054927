import {AfterViewInit, Component, Input, OnDestroy, OnInit, output, ViewChild} from '@angular/core';
import {DvirSection} from '../../model/dvir-section';
import {CardComponent} from '../../../../../../shared/components/card/card.component';
import {MatButton, MatIconButton} from '@angular/material/button';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatInput} from '@angular/material/input';
import {MatTooltip} from '@angular/material/tooltip';
import {FormsModule, NgForm, ReactiveFormsModule} from '@angular/forms';
import {CardContentDirective} from '../../../../../../shared/components/card/card-content.directive';
import {DvirGroup} from '../../model/dvir-group';
import {DvirQuestion} from '../../model/dvir-question';
import {firstValueFrom, Subscription} from 'rxjs';
import {DvirInspectionQuestionDataType} from '../../model/QuestionDatatype';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {MatOption, MatSelect} from '@angular/material/select';
import {DvirQuestionValueProviderDto} from '../../model/dto/DvirQuestionValueProviderDto';
import {Dropdown, DropdownOption} from '../../../../../../shared/models/dvir';
import {DvirService} from '../../../../../../data/dvir/dvir.service';
import {MatCheckbox} from '@angular/material/checkbox';

@Component({
  selector: 'app-inspection-form-sections-edit',
  standalone: true,
  imports: [
    CardComponent,
    MatButton,
    MatFormField,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    MatTooltip,
    ReactiveFormsModule,
    CardContentDirective,
    FormsModule,
    MatMenu,
    MatMenuTrigger,
    MatMenuItem,
    MatSelect,
    MatOption,
    MatCheckbox
  ],
  templateUrl: './inspection-form-sections-edit.component.html',
  styleUrls: ['./inspection-form-sections-edit.component.scss', '../../../../../settings/settings-common.scss']
})
export class InspectionFormSectionsEditComponent implements OnInit, AfterViewInit, OnDestroy {
  onValidChange = output<boolean>();
  @ViewChild(NgForm)
  form: NgForm

  providers: DropdownOption[] = [];
  configs: {[key: string]:Dropdown[]} = {}

  subscriptions: Subscription[] = []
  protected readonly DvirInspectionQuestionDataType = DvirInspectionQuestionDataType;

  constructor(public dvirService: DvirService) {
  }

  private _sections: DvirSection[] = [];

  get sections(): DvirSection[] {
    return this._sections;
  }

  @Input()
  set sections(value: DvirSection[]) {
    this._sections = value;
  }

  ngOnInit() {
    firstValueFrom(this.dvirService.getDropdownProviders()).then(result => {
      result.data.forEach(d => {
        this.configs[d.id] = [];
        firstValueFrom(this.dvirService.getDropdownLists(d.id)).then(types => {
          this.configs[d.id] = types.data;
        })
      });
      this.providers = result.data;
    });
  }

  ngAfterViewInit() {
    this.subscriptions.push(
      this.form.statusChanges.subscribe((change) => {
          this.onValidChange.emit(change === 'VALID')
        }
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  addSection() {
    this._sections.push(new DvirSection([new DvirGroup([new DvirQuestion()])]));
  }

  addGroup(section: DvirSection = undefined) {
    if (!section) {
      if (this.sections.length === 0) {
        this.addSection()
        return
      } else {
        section = this.sections[0]
      }
    }
    const group = new DvirGroup();
    addNewQuestionToGroup(group);
    section.groups.push(group);
  }

  addQuestion(group: DvirGroup = undefined) {
    if (!group) {
      if (this.sections.length === 0) {
        this.addSection();
      }
      group = this.sections[0].groups[0]
    }
    addNewQuestionToGroup(group);
  }

  removeQuestion(section: DvirSection, group: DvirGroup, question: DvirQuestion) {
    const index = group.questions.indexOf(question)
    group.questions.splice(index, 1)
    if (group.questions.length === 0) {
      this.removeGroup(section, group)
    }
  }

  removeGroup(section: DvirSection, group: DvirGroup) {
    const index = section.groups.indexOf(group)
    section.groups.splice(index, 1)
    if (section.groups.length === 0) {
      section.groups.push(new DvirGroup())
    }
  }

  removeSection(section: DvirSection) {
    if (this.sections.length === 1) {
      delete section.title;
    } else {
      const index = this.sections.indexOf(section);
      this.sections.splice(index, 1);
    }
  }

  changeQuestionType(question: DvirQuestion, type: DvirInspectionQuestionDataType) {
    if (type === DvirInspectionQuestionDataType.TEXT) {
      delete question.valueProvider;
    } else {
      delete question.notApplicable;
      if (!question.valueProvider) {
        question.valueProvider = new DvirQuestionValueProviderDto()
      }
    }
    question.type = type;
  }

  getQuestionTypeValues(question: DvirQuestion) {
    return this.configs[question.valueProvider.type]
      ?.find(type => type.id === question.valueProvider.config)
      ?.values
      || []
  }

  questionProviderChanged(question: DvirQuestion) {
    delete question.valueProvider.config;
    delete question.valueProvider.defaultValue;
  }

  questionTypeChanged(question: DvirQuestion) {
    delete question.valueProvider.defaultValue;
  }
}

function addNewQuestionToGroup(group: DvirGroup) {
  const maxRank = group.questions.reduce((max, value) => Math.max(max, value.rank), 0);
  group.questions.push(new DvirQuestion(undefined, maxRank + 1));
}
