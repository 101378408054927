import {ConfigurationModel, FeatureFlagEnum} from '../../../../../shared/models/configuration.model';
import {firstValueFrom, Subject, Subscription, switchMap, tap} from 'rxjs';
import {ConfigurationService} from '../../../../../configuration/configuration.service';
import {NamedId} from '../../../../../shared/models/NamedId';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {VehiclesService} from '../../../../../data/vehicles/vehicles.service';
import {ToastService} from '../../../../../shared/services/toast.service';
import {VehicleCategoryModel} from '../../../../../shared/models/vehicle';
import {ObservationManagementService} from '../../../../../data/observations/observation-management.service';
import {RoutesService} from '../../../../../data/routes/routes.service';
import {InspectionFormsService} from '../../../../../data/inspection-forms/inspection-forms.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ObservationTypeGroup} from '../../../../../shared/models/observation-group';
import {RouteConfigurationWithSchema} from '../../../../../shared/models/route';
import {VehicleModel} from 'src/app/shared/models/vehicle.model';
import {AbstractControl, FormBuilder, FormGroup, Validators, ValueChangeEvent} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {DialogConfirmDeleteVehicleCategoryComponent} from '../dialogs-vehicle-group/vehicle-group-dialog-components';
import {MatSnackBar} from '@angular/material/snack-bar';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-vehicle-group-edit',
  templateUrl: './vehicle-group-edit.component.html',
  styleUrls: [
    '../dialogs-vehicle/dialog-components.scss',
    '../../../../settings/settings-fields.scss',
    '../../../../settings/settings-common.scss',
    'vehicle-group-edit.component.scss',
  ],
})
export class VehicleGroupEditComponent implements OnInit, OnDestroy {
  // this is a hack to access enums from templates
  FeatureFlagEnum = FeatureFlagEnum;
  configuration: ConfigurationModel;

  isLoading = false;

  stationaryAlertList: { id: any, label: string }[] = [
    {id: 10, label: '15 minutes'},
    {id: 60, label: '1 hour'},
    {id: 240, label: '4 hours'}
  ];

  state: {
    isImported?: boolean;
    maxStationaryTime?: number;
    allowReportObservations?: boolean;
  } = {
    isImported: false
  };

  vehicleGroup: VehicleCategoryModel;

  vehicles: VehicleModel[];
  allInspectionForms: NamedId[];
  allObservationTypeGroups: NamedId[];
  allAllowedRouteConfigs: NamedId[];

  formGroup: FormGroup;

  subscriptions: Subscription[] = []
  dataChangedTopic: Subject<void>;

  isSaving = false

  constructor(
    private readonly configurationService: ConfigurationService,
    private readonly toastService: ToastService,
    private readonly vehicleService: VehiclesService,
    private readonly observationManagementService: ObservationManagementService,
    private readonly inspectionFormsService: InspectionFormsService,
    private readonly routesService: RoutesService,
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
  ) {
    this.createFormGroup();
  }

  ngOnInit(): void {
    this.loadGeneralConfiguration();
    this.loadData();

    this.dataChangedTopic = new Subject();
    this.subscriptions.push(
      this.dataChangedTopic.pipe(
        debounceTime(500),
        switchMap(() => {
          this.isSaving = true;
          const model = {...this.formGroup.value}
          model.title = model.name;
          return this.vehicleService.updateVehicleCategory(this.vehicleGroup.id, model)
        }),
        tap(response => {
          this.isSaving = false;
          this.vehicleGroup = response.data;
        }),
      )
        .subscribe()
    )
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  navigateBack() {
    this.router.navigate(['settings', 'manage-vehicles'])
      .then();
  }

  deleteGroup() {
    const dialogRef = this.dialog.open(DialogConfirmDeleteVehicleCategoryComponent, {
      data: this.vehicleGroup
    });

    firstValueFrom(dialogRef.afterClosed()).then(result => {
      if (result) {
        this.isLoading = true;
        this.vehicleService.deleteVehicleCategory(this.vehicleGroup.id).then(() => {
          this.snackBar.open('Vehicle Group has been deleted');
          this.router.navigate(['/settings', 'manage-vehicles'])
            .then()
        }).catch(() => {
          this.isLoading = false;
        })
      }
    })
  }

  formUpdate(value: { [key: string]: any }) {
    Object.keys(value).forEach(key => {
      this.formGroup.controls[key].setValue(value[key]);
    })
  }

  private loadGeneralConfiguration() {
    this.subscriptions.push(
      this.configurationService.sharedConfigurationModel.subscribe(model => {
        this.configuration = model;
      })
    );
  }

  private loadData() {
    this.isLoading = true;

    const groupId = +this.activatedRoute.snapshot.paramMap.get('id') || new Error('no id param found');

    Promise.all([
      this.vehicleService.getVehicleCategories(),
      firstValueFrom(this.vehicleService.getVehicles()),
      this.inspectionFormsService.getForms(),
      firstValueFrom(this.observationManagementService.getObservationTypeGroups()),
      this.routesService.getRouteConfigurations(),
    ])
      .then(responses => {
        const [
          categoriesResponse,
          vehiclesResponse,
          inspectionFormsResponse,
          observationGroupsResponse,
          routeConfigsResponse
        ] = responses;

        this.state.isImported = this.configurationService
          .hasFeatureFlag(this.configuration?.featureFlags, FeatureFlagEnum.CartegraphIntegration);

        this.stateChanged();

        this.vehicleGroup = categoriesResponse.data.find(f => f.id === groupId);

        this.vehicles = vehiclesResponse.data.filter(f => f.groupId === this.vehicleGroup.id);

        this.state.allowReportObservations = this.vehicleGroup.observationTypeGroupIds?.length > 0;

        this.allInspectionForms = inspectionFormsResponse.map(value => new NamedId(value.journalId, value.title));

        this.allObservationTypeGroups = observationGroupsResponse.data.map((value: ObservationTypeGroup) => {
          return {id: value.id, name: value.name};
        });

        this.allAllowedRouteConfigs = routeConfigsResponse.data.map((value: RouteConfigurationWithSchema) => {
          return {id: value.id, name: value.name};
        });

        this.setFormValue(this.vehicleGroup);
      })
      .catch(error => {
        const msg = 'Error while loading data from server';
        this.toastService.long(msg);
        console.error(`${msg} :: ${error}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  private createFormGroup() {
    this.formGroup = this.fb.group<any>({
      name: this.fb.control('', [Validators.required]),
      maxStationaryTime: this.fb.control(''),
      showVehicles: this.fb.control(false),
      showVehicleHistory: this.fb.control(false),
      showVehicleObservations: this.fb.control(false),
      allowRouteSelfAssign: this.fb.control(false),
      allowedRouteConfigIds: this.fb.control([]),
      allowReportObservations: this.fb.control(false),
      observationTypeGroupIds: this.fb.control([]),
      useDvir: this.fb.control(false),
      preTripFormId: this.fb.control(undefined),
      postTripFormId: this.fb.control(undefined),
    }, {
      validators: [validateDvirForms, validateObservations, validateRoutes]
    });

    function validateDvirForms(control: AbstractControl) {
      const {value} = control;
      return value.useDvir && !value.preTripFormId && !value.postTripFormId ? {dvirMissing: true} : null;
    }

    function validateObservations(control: AbstractControl) {
      const {value} = control;
      return value.allowReportObservations && !value.observationTypeGroupIds?.length ? { observationTypeMissing: true } : null;
    }

    function validateRoutes(control: AbstractControl) {
      const {value} = control;
      return value.allowRouteSelfAssign && !value.allowedRouteConfigIds?.length ? { routesMissing: true } : null;
    }

    this.subscriptions.push(
      this.formGroup.events.subscribe((event) => {
        if (event instanceof ValueChangeEvent) {
          if (!event.value.useDvir && (event.value.preTripFormId || event.value.postTripFormId)) {
            this.setFormValue({
              ...event.value,
              preTripFormId: null,
              postTripFormId: null
            }, true)
          } else {
            this.triggerDataSave();
          }
        }
      })
    );
  }

  private triggerDataSave() {
    if (this.formGroup.valid) {
      this.dataChangedTopic.next();
    }
  }

  private stateChanged() {
    const isImported = this.state.isImported;
    if (isImported) {
      this.formGroup.controls['name'].disable();
    } else {
      this.formGroup.controls['name'].enable();
    }
  }

  private setFormValue(value: VehicleCategoryModel, emitEvent = false) {
    const {
      name,
      maxStationaryTime,
      showVehicles,
      showVehicleHistory,
      showVehicleObservations,
      allowRouteSelfAssign,
      allowedRouteConfigIds,
      observationTypeGroupIds,
      useDvir,
      preTripFormId,
      postTripFormId,
    } = value;
    this.formGroup.setValue({
      name,
      maxStationaryTime,
      showVehicles,
      showVehicleHistory,
      showVehicleObservations,
      allowRouteSelfAssign,
      allowedRouteConfigIds: allowedRouteConfigIds || [],
      allowReportObservations: (observationTypeGroupIds?.length > 0),
      observationTypeGroupIds : observationTypeGroupIds || [],
      useDvir,
      preTripFormId: (preTripFormId ?? null),
      postTripFormId: (postTripFormId ?? null),
    }, {emitEvent});
  }
}

