import {MapContentLayer} from '../MapContentLayer';
import {MapContentSource} from '../MapContentSource';
import {TrackStyles} from '../../../../../configuration/model/TrackStyles';
import {LayerSpecification, LineLayerSpecification} from 'maplibre-gl';

export class ShiftPlaybackLayer extends MapContentLayer {

  constructor(
    source: MapContentSource,
    private trackStyles: TrackStyles,
  ) {
    super('shift-playback-layer', source);
  }

  toLayerSpecification(): LayerSpecification {
    return {
      id: this.layerId,
      type: 'line',
      source: this.sourceRef.sourceId,
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': 'red',
        'line-opacity': this.trackStyles.shiftPlowNormal.opacity,
        'line-width': 10,
      }
    } as LineLayerSpecification;
  }
}
