import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {LatLngPipe} from '../../../formatting/latLng.pipe';
import {Observation} from '../../../models/observation';
import {Router} from '@angular/router';
import {ToastService} from '../../../services/toast.service';
import {MainRoute, RootRoute} from '../../../models/angular-routing';
import { ShiftsManagerService } from '../../../../data/shifts/shifts-manager.service';
import { Subscription } from 'rxjs';
import { InsightsRoute } from '../../../../pages/insights/insights-routing.module';


@Component({
  selector: 'app-observation-info-window-content',
  templateUrl: './observation-info-window-content.component.html',
  styleUrls: ['./observation-info-window-content.component.scss']
})
export class ObservationInfoWindowContentComponent implements OnInit, OnDestroy {

  @Input()
  observation: Observation;

  @Input()
  isLiveMap: boolean;

  isActiveShift = false;

  readonly RootRoute = RootRoute;
  readonly MainRoute = MainRoute;
  readonly InsightsRoute = InsightsRoute;

  private activeShiftsSubscription: Subscription;

  constructor(private toast: ToastService,
              private router: Router,
              private latLngPipe: LatLngPipe,
              private shiftManager: ShiftsManagerService,
  ) { }

  ngOnInit() {
    const that = this;
    this.activeShiftsSubscription = this.shiftManager.activeShifts$.subscribe(shiftUpdates => {
      this.isActiveShift = !!shiftUpdates.state.find(shift => shift.id === this.observation?.shiftId);
    });
  }

  ngOnDestroy() {
    this.activeShiftsSubscription?.unsubscribe();
  }

  handleLocationClick(event) {
    event.stopPropagation();

    const locationText = this.latLngPipe.transform(this.observation.location.coords);
    window.navigator.clipboard.writeText(locationText)
      .then(() => {
        this.toast.short('Location copied to clipboard.');
      })
      .catch((reason) => {
        this.toast.short(`Failed to copy the location. ${reason}`);
      });
  }

  isLinkActive(url): boolean {
    return this.router.url.startsWith(url);
  }
}
