import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {JsonApiResponse} from '../../shared/models/JsonApiResponse';
import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {HttpErrorHandler} from '../../http.error.handler';
import {RoadSegmentStatus} from '../../shared/models/road-status';

@Injectable({
  providedIn: 'root'
})
export class RoadSegmentStatusService {

  constructor(private http: HttpClient) { }

  getRouteSegmentStatus(roadSegmentId: number) {
    return this.http.get<JsonApiResponse<RoadSegmentStatus>>(`${environment.services.service}v1/road/segment/status/${roadSegmentId}`)
        .pipe(
            catchError(HttpErrorHandler.handleError)
        ).toPromise();
  }
}
