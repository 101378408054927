<p>
  <strong>{{ observation.observationType.title }}</strong>
</p>

<table>
  <tr *ngIf="isLiveMap">
    <th>Vehicle:</th>
    <td>
      <a [routerLink]="['/' + RootRoute.MAIN, MainRoute.VEHICLE, observation.vehicle.id]"
         [queryParamsHandling]="'merge'"
         [class.hidden-link]="isLinkActive('/' + RootRoute.MAIN + '/' + MainRoute.VEHICLE)"
         class="view-link"
      >
        {{ observation.vehicle.label | uppercase }}
      </a>
      <span *ngIf="isLinkActive('/' + RootRoute.MAIN + '/' + MainRoute.VEHICLE)">
        {{ observation.vehicle.label | uppercase }}
      </span>
    </td>
  </tr>
  <tr>
    <th>Location:</th>
    <td (click)="handleLocationClick($event)">{{this.observation.location.coords | latLng}}</td>
  </tr>
  <tr>
    <th>Time:</th>
    <td>{{this.observation.location.time | date:'shortDate'}} {{this.observation.location.time | date:'mediumTime'}}</td>
  </tr>
</table>

<div *ngIf="isLiveMap && !isActiveShift">
  <a [routerLink]="['/' + RootRoute.MAIN, MainRoute.INSIGHTS, InsightsRoute.SHIFT_REPORT, InsightsRoute.SHIFT_DETAIL, this.observation.shiftId]"
     class="view-link"
  >
    View Shift Detail
  </a>
</div>

<div *ngIf="this.observation.location.imageUrl"
     class="dash-cam-image-thumbnail">
  <app-retrying-image-viewer [imageUrl]="this.observation.location.imageUrl"
                             [alternativeText]="'Dashboard Camera Image'"></app-retrying-image-viewer>
</div>
