<div class="top-panel">
    <div class="toolbar">
        <div class="left-content">
            <div class="product-logo-container">
                <img [ngClass]="'logo__' + envName"
                     ngSrc="assets/PlowOpsLogo_Snowflake.svg"
                     [alt]="dashboardVersion"
                     [matTooltip]="'PlowOps: ' + dashboardVersion"
                     height="24" width="24" />
                <h1>Account Setup</h1>
            </div>
        </div>
        <div class="centered-content">
            <mat-form-field
                            class="search-field"
                            *ngIf="showSearchField">
                <mat-icon matPrefix>search</mat-icon>
                <input matInput placeholder="{{searchHint}}" type="search"
                       [(ngModel)]="searchTerm"
                       (input)="onSearchTermChanged()">
            </mat-form-field>
        </div>
        <div class="right-content">
            <button mat-icon-button [routerLink]="['/']">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
</div>
<div class="top-panel-shadow" [ngClass]="{ 'has-shadow': isMainContentScrolled}"></div>
<div class="content-container">
    <div class="left-panel">
        <div role="navigation" class="settings-menu">
            <div class="left-menu" id="menu" role="menu">
                <ng-container *ngFor="let item of settingsSections">
                    <a class="menu-item" matRipple
                       [routerLink]="item.id" routerLinkActive="selected">
                        <mat-icon>{{item.icon}}</mat-icon>
                        {{item.name}}
                    </a>
                </ng-container>
            </div>
            <mat-progress-bar
                    *ngIf="isLoadingConfiguration || isLoadingAdminStatus"
                    mode="indeterminate"></mat-progress-bar>
        </div>
    </div>
    <div class="main-panel">
        <div class="main-content">
            <div #mainContentTopMark><!-- An invisible mark used to detect the scroll position --></div>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
