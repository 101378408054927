import {NamedId} from '../../../../../shared/models/NamedId';
import {SensorType, VehicleModel} from '../../../../../shared/models/vehicle.model';
import {VehiclesService} from '../../../../../data/vehicles/vehicles.service';
import {ToastService} from '../../../../../shared/services/toast.service';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, NgModel, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {firstValueFrom, Subscription} from 'rxjs';
import {ConfigurationService} from '../../../../../configuration/configuration.service';
import {MatDialog} from '@angular/material/dialog';
import {EditVehicleComponentInputData} from '../edit-vehicle/vehicle.types';
import {DialogVehicleHardwareComponent} from '../edit-vehicle/update-vehicle-hardware.component';
import {DialogVehicleCameraComponent} from '../edit-vehicle/update-vehicle-camera.component';
import {debounceTime} from 'rxjs/operators';
import {UniqueValueDirective} from '../../../../../shared/directives/unique-value.directive';
import {ConfigurationModel, FeatureFlagEnum} from '../../../../../shared/models/configuration.model';
import {CartegraphFeature} from '../../../../../shared/models/cartegraph.model';
import {DialogConfirmDeleteVehicleComponent} from '../dialogs-vehicle/dialog-confirm-delete-vehicle.component';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-vehicle-edit',
  templateUrl: './vehicle-edit.component.html',
  styleUrls: [
    './vehicle-edit.component.scss',
    '../../../../settings/settings-fields.scss',
    '../../../../settings/settings-common.scss'
  ],
})
export class VehicleEditComponent implements OnInit, OnDestroy {
  // abstract headline: string;
  vehicle: VehicleModel;
  vehicles: VehicleModel[]; // todo this is used for appUniqueValue validation, can it be done other way?
  configuration: ConfigurationModel;
  configSubscription: Subscription;

  defaultMapColor: string;
  isImported: boolean;

  @ViewChild('ngMapColor')
  ngMapColor: NgModel;

  ui: {
    gpsColorEnabled?: boolean;
    allLmuIds?: string[];
    allNames?: string[];
    allLabels?: string[];
    allPublicAliases?: string[];
    allLicensePlates?: string[];
    allReportingTypes?: NamedId[];
    allVehicleStatuses?: NamedId[];
    allManualMaterialOptions?: NamedId[];
    additionalHardware?: boolean;
    usingTablet?: boolean;
  } = {
    allVehicleStatuses: [
      {id: true, name: 'In Service'},
      {id: false, name: 'Out Of Service'},
    ],
    allReportingTypes: [
      {id: 'DISTANCE', name: 'Odometer'},
      {id: 'TIME', name: 'Hour Meter'}
    ],
    allManualMaterialOptions:[
      {id: true, name: 'Enabled'},
      {id: false, name: 'Disabled'}
    ]
  };

  // enums
  FeatureFlagEnum = FeatureFlagEnum;
  SensorType = SensorType;

  cartegraph: {
    manualLogFeatureEnabled: boolean,
    manualMaterialLogOption?: boolean,
    manualMaterialNote?: string,
  } = {
    manualLogFeatureEnabled: false,
  };

  isWorking = true;
  isInitialized = false;

  form!: FormGroup;

  constructor(
    private readonly vehicleService: VehiclesService,
    private readonly toastService: ToastService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly configurationService: ConfigurationService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private readonly formBuilder: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.loadGeneralConfiguration(() => {
      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.configSubscription.unsubscribe();
  }

  private loadGeneralConfiguration(callback: () => void) {
    this.configSubscription = this.configurationService.sharedConfigurationModel.subscribe(model => {
      this.configuration = model;
      callback();
    });
  }


  loadData(): void {
    this.isWorking = true;

    const vehicleId = +this.activatedRoute.snapshot.paramMap.get('id') || new Error('no id param found');

    firstValueFrom(
      this.vehicleService.getVehicles(),
    ).then(vehiclesResponse => {
        this.vehicle = vehiclesResponse.data?.find(v => v.id === vehicleId);
        this.ui.gpsColorEnabled = this.vehicle?.mapColor !== null;
        const otherVehicles = vehiclesResponse.data?.filter(v => v.id !== this.vehicle?.id);
        this.vehicles = otherVehicles;
        this.ui.allLabels = otherVehicles?.map(v => v.label).filter(v => v !== null);
        this.ui.allPublicAliases = otherVehicles?.map(v => v.publicAlias).filter(v => v !== null);
        this.ui.allNames = otherVehicles?.map(v => v.name).filter(v => v !== null);
        this.ui.allLicensePlates = otherVehicles?.map(v => v.licensePlate).filter(v => v !== null);
        this.ui.allLmuIds = otherVehicles?.map(v => v.lmuId).filter(v => v !== null);
        this.ui.additionalHardware = !!this.vehicle?.hardwareConfiguration || !!this.vehicle?.cameraConfiguration;
        this.ui.usingTablet = !this.vehicle?.hasNoTablet;
        // console.log('vehicle', this.vehicle);

        this.initFromData();

    }).catch(error => {
      console.error('error occured', error);
      this.toastService.short('An error occurred to init data');
    }).finally(() => {
      this.isWorking = false;
      this.isInitialized = true;
    });
  }


  private initFromData() {
    const namesValidator = UniqueValueDirective.validator(this.ui.allNames);
    const labelsValidator = UniqueValueDirective.validator(this.ui.allLabels);
    const publicAliasesValidator = UniqueValueDirective.validator(this.ui.allPublicAliases);
    const platesValidator = UniqueValueDirective.validator(this.ui.allLicensePlates);

    const name = new FormControl(
      {value: this.vehicle.name, disabled: this.isImported}, [
        Validators.required,
        namesValidator
      ]);
    const label = new FormControl(
      {value: this.vehicle.label, disabled: this.isImported}, [
        labelsValidator
      ]);
    const publicAlias = new FormControl(
      {value: this.vehicle.publicAlias, disabled: this.isImported}, [
        publicAliasesValidator
      ]);
    const licensePlate = new FormControl(
      {value: this.vehicle.licensePlate, disabled: this.isImported}, [
        platesValidator
      ]);
    this.form = this.formBuilder.group({
      name,
      label,
      publicAlias,
      licensePlate,
    });
    name.valueChanges.pipe(debounceTime(2000)).subscribe(v => {
      this.vehicle.name = v;
      this.saveVehicle();
    });
    label.valueChanges.pipe(debounceTime(2000)).subscribe(v => {
      this.vehicle.label = v;
      this.saveVehicle();
    });
    publicAlias.valueChanges.pipe(debounceTime(2000)).subscribe(v => {
      this.vehicle.publicAlias = v;
      this.saveVehicle();
    });
    licensePlate.valueChanges.pipe(debounceTime(2000)).subscribe(v => {
      this.vehicle.licensePlate = v;
      this.saveVehicle();
    });

    // cartegraph material logging settings
    this.cartegraph.manualLogFeatureEnabled = this.hasFeatureFlag(FeatureFlagEnum.CartegraphIntegration)
      && this.hasCartegraphFlag(CartegraphFeature.SEND_MATERIAL_LOG)
      && this.hasCartegraphFlag(CartegraphFeature.MANUAL_MATERIAL_LOG);

    // manual material log entry override
    this.cartegraph.manualMaterialLogOption = this.vehicle.manualMaterialLog;
    this.cartegraph.manualMaterialNote = (this.cartegraph.manualMaterialLogOption == null) ? '(defaulted by Cartegraph settings)' : null;

  }

  saveVehicle(): void {
    if (!this.canBeSaved()) {
      this.toastService.short('Not valid, cannot be saved.');
      return null;
    }

    this.isWorking = true;
    this.vehicleService.updateVehicle(this.vehicle.id, this.vehicle)
      .then(result => {
        // this.vehicle = result.data;
      }).catch(error => {
      console.log('Vehicle save failed', error);
      this.toastService.short('Save failed.');
    }).finally(() => {
      this.isWorking = false;
      // this.toastService.short('Saved.');
    });
  }

  canBeSaved(): boolean {
    if (this.isWorking) {
      return false;
    }
    if (!!this.form.controls['name'].errors) {
      return false;
    }
    if (!!this.form.controls['label'].errors) {
      return false;
    }
    if (!!this.form.controls['publicAlias'].errors) {
      return false;
    }
    if (!!this.form.controls['licensePlate'].errors) {
      return false;
    }

    return true;
  }

  hasFeatureFlag(featureFlag: string): boolean {
    return this.configuration?.featureFlags.find(value => value.isEnabled && value.name === featureFlag) !== undefined;
  }

  hasCartegraphFlag(featureFlag: string): boolean {
    return this.configuration.cartegraphFeatureFlags?.find(value => value.enabled && value.feature === featureFlag) !== undefined;
  }
  navigateBack() {
    this.router.navigate(['settings', 'manage-vehicles', 'group', this.vehicle.groupId, 'vehicles'])
      .then();
  }

  usingTabletUpdated() {
    this.vehicle.hasNoTablet = !this.ui.usingTablet;
    this.saveVehicle();
  }

  additionalHardwareToggled() {
    if (!this.ui.additionalHardware) {
      this.vehicle.hardwareConfiguration = null;
      this.vehicle.cameraConfiguration = null;
      this.saveVehicle();
    }
  }

  modifyHardware() {
    const dialogRef = this.dialog.open(DialogVehicleHardwareComponent, {
      width: '550px',
      data: {
        model: this.vehicle,
        configuration: this.configuration,
        vehicles: this.vehicles,
        isImported: this.isImported
      } as EditVehicleComponentInputData,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.toastService.short('Hardware information updated');
        if (!!result && result.type === 'singleVehicle') {
          // just reload to be sure we have the latest data, or we could just assign returned object as it is returned from the save
          this.loadData();
        }
      }
    });
  }

  modifyDashCamera() {
    const dialogRef = this.dialog.open(DialogVehicleCameraComponent, {
      width: '550px',
      data: {
        model: this.vehicle,
        configuration: this.configuration,
        vehicles: this.vehicles,
        isImported: this.isImported
      } as EditVehicleComponentInputData,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.toastService.short('Camera information updated');
        if (!!result && result.type === 'singleVehicle') {
          // just reload to be sure we have the latest data, or we could just assign returned object as it is returned from the save
          this.loadData();
        }
      }
    });
  }

  updateMaterialAmountChange() {
    this.vehicle.manualMaterialLog = (typeof this.cartegraph.manualMaterialLogOption) === 'boolean' ? this.cartegraph.manualMaterialLogOption : null;
    this.saveVehicle();
  }

  deleteVehicle() {
    const dialogRef = this.dialog.open(DialogConfirmDeleteVehicleComponent, {
      width: '450px',
      data: this.vehicle
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult !== undefined && dialogResult) {
        this.isWorking = true;
        this.isInitialized = false;
        this.vehicleService.deleteVehicle(this.vehicle.id).then(() => {
          this.snackBar.open('The Vehicle has been deleted', null, {duration: 2000});
          this.router.navigate(['/settings', 'manage-vehicles', 'group', this.vehicle.groupId, 'vehicles'])
            .then()
        }).catch(() => {
          this.isWorking = false;
          this.isInitialized = true;
        })
      }
    });
  }
}
