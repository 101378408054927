import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {JsonApiResponse} from '../../shared/models/JsonApiResponse';
import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {HttpErrorHandler} from '../../http.error.handler';
import {EventRecord} from '../../shared/models/event.model';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(private http: HttpClient) { }

  getRecentEvents() {
    const params = {};
    return this.http.get<JsonApiResponse<EventRecord[]>>(`${environment.services.service}v1/events/recent`, { params })
        .pipe(
            catchError(HttpErrorHandler.handleError) // then handle the error
        ).toPromise();
  }
}
