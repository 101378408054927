import {Component, OnDestroy, OnInit} from '@angular/core';
import {LayerTypeFilter} from '../../../../configuration/model/LayerTypeFilter';
import {Subscription} from 'rxjs';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {SecurityService} from '../../../../security/security.service';
import {MatDialog} from '@angular/material/dialog';
import {SettingsService} from '../../../../configuration/settings.service';
import {TimeMapConfig, TimeMapConfigComponent} from '../time-map-config/time-map-config.component';
import {TrackStyles} from '../../../../configuration/model/TrackStyles';

@Component({
  selector: 'app-currency-legend',
  templateUrl: './currency-legend.component.html',
  styleUrls: ['./currency-legend.component.scss']
})
export class CurrencyLegendComponent implements OnInit, OnDestroy {

  defaultLegendSettings: LayerTypeFilter;
  defaultTrackStyles: TrackStyles;
  defaultStylesSubscription: Subscription;

  userLegendSettings: LayerTypeFilter;
  userTrackStyles: TrackStyles;
  userStylesSubscription: Subscription;
  isAdmin: boolean;

  constructor(
      public dialog: MatDialog,
      private securityService: SecurityService,
      private configurationService: ConfigurationService,
      private settingsService: SettingsService,
  ) {}

  ngOnInit() {
    this.isAdmin = this.securityService.isAdminSync();
    this.defaultStylesSubscription = this.configurationService.trackStyles$.subscribe(trackStyles => {
      this.defaultTrackStyles = trackStyles;
      this.defaultLegendSettings = LayerTypeFilter.currencyLayerTypeFilter(trackStyles, 1);
    });
    this.userStylesSubscription = this.configurationService.userTrackStyles$.subscribe(trackStyles => {
      this.userTrackStyles = trackStyles;
      this.userLegendSettings = LayerTypeFilter.currencyLayerTypeFilter(trackStyles, 1);
    });
  }

  ngOnDestroy() {
    this.defaultStylesSubscription?.unsubscribe();
    this.userStylesSubscription?.unsubscribe();
  }

  openConfiguration() {
    const defaultConfigCopy = JSON.parse(JSON.stringify(this.defaultLegendSettings));
    const userConfigCopy = JSON.parse(JSON.stringify(this.userLegendSettings));
    const dialogRef = this.dialog.open(TimeMapConfigComponent, {
      maxWidth: '80vw',
      maxHeight: '85vh',
      width: '500px',
      height: '550px',
      data: {
        reset: false,
        defaultLegendSettings: defaultConfigCopy,
        legendSettings: userConfigCopy,
      } as TimeMapConfig,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined && result) {
        // this.coverageColorsChanged(result);
        const configResult = result as TimeMapConfig;
        if (configResult.reset) {
          this.settingsService.setStringValue(SettingsService.CURRENCY_TRACK_STYLE_1, null);
          this.settingsService.setStringValue(SettingsService.CURRENCY_TRACK_STYLE_2, null);
          this.settingsService.setStringValue(SettingsService.CURRENCY_TRACK_STYLE_3, null);
          this.settingsService.setStringValue(SettingsService.CURRENCY_TRACK_STYLE_4, null);
          this.settingsService.setStringValue(SettingsService.CURRENCY_TRACK_STYLE_5, null);
        } else {
          this.userTrackStyles.roadStatusCurrencyLevel1.color = configResult.legendSettings.measurementUnits[0].color;
          this.userTrackStyles.roadStatusCurrencyLevel1.dasharray = configResult.legendSettings.measurementUnits[0].dashArray;
          this.settingsService.setStringValue(
              SettingsService.CURRENCY_TRACK_STYLE_1,
              JSON.stringify(this.userTrackStyles.roadStatusCurrencyLevel1)
          );

          this.userTrackStyles.roadStatusCurrencyLevel2.color = configResult.legendSettings.measurementUnits[1].color;
          this.userTrackStyles.roadStatusCurrencyLevel2.dasharray = configResult.legendSettings.measurementUnits[1].dashArray;
          this.settingsService.setStringValue(
              SettingsService.CURRENCY_TRACK_STYLE_2,
              JSON.stringify(this.userTrackStyles.roadStatusCurrencyLevel2)
          );

          this.userTrackStyles.roadStatusCurrencyLevel3.color = configResult.legendSettings.measurementUnits[2].color;
          this.userTrackStyles.roadStatusCurrencyLevel3.dasharray = configResult.legendSettings.measurementUnits[2].dashArray;
          this.settingsService.setStringValue(
              SettingsService.CURRENCY_TRACK_STYLE_3,
              JSON.stringify(this.userTrackStyles.roadStatusCurrencyLevel3)
          );

          this.userTrackStyles.roadStatusCurrencyLevel4.color = configResult.legendSettings.measurementUnits[3].color;
          this.userTrackStyles.roadStatusCurrencyLevel4.dasharray = configResult.legendSettings.measurementUnits[3].dashArray;
          this.settingsService.setStringValue(
              SettingsService.CURRENCY_TRACK_STYLE_4,
              JSON.stringify(this.userTrackStyles.roadStatusCurrencyLevel4)
          );

          this.userTrackStyles.roadStatusCurrencyLevel5.color = configResult.legendSettings.measurementUnits[4].color;
          this.userTrackStyles.roadStatusCurrencyLevel5.dasharray = configResult.legendSettings.measurementUnits[4].dashArray;
          this.settingsService.setStringValue(
              SettingsService.CURRENCY_TRACK_STYLE_5,
              JSON.stringify(this.userTrackStyles.roadStatusCurrencyLevel5)
          );
        }

        this.configurationService.refreshUserTrackStyles();
      }
    });
  }
}
