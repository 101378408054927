import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  BaseMapType,
  ISettingKeyValuePair,
  SettingsService,
  WeatherLayerType
} from 'src/app/configuration/settings.service';
import { filterSelectorFadeDown } from 'src/app/shared/animations/animations';
import { ConfigurationModel, FeatureFlagEnum, MapLayer } from 'src/app/shared/models/configuration.model';
import { ConfigurationService } from '../../../configuration/configuration.service';
import { MapLayersManager } from '../map-viewer/map-layers-manager';
import { environment } from '../../../../environments/environment';
import { MapControlService } from '../map-viewer/services/map-control.service';
import { SecurityService } from '../../../security/security.service';
import { CustomLayersConfigComponent } from './custom-layers-config/custom-layers-config.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-map-layer-switcher',
  templateUrl: './map-layer-switcher.component.html',
  styleUrls: ['./map-layer-switcher.component.scss'],
  animations: [filterSelectorFadeDown],
})
export class MapLayerSwitcherComponent implements OnInit, OnDestroy {
  @Input() mapLayersManager: MapLayersManager;

  isAdmin: boolean;
  filterOpen = false;

  showRadar = false;
  showWarnings = false;
  showTraffic = false;
  baseMapLayerType: string;

  configuration: ConfigurationModel;
  customMapLayers: MapLayer[];

  baseMaps: BaseMapType[] = [
    BaseMapType.WINTER,
    BaseMapType.DARK,
    BaseMapType.IMAGERY,
    BaseMapType.OUTDOORS,
  ];

  // this is a hack to access enums from templates
  BaseMapType = BaseMapType;
  FeatureFlagEnum = FeatureFlagEnum;
  WeatherLayerType = WeatherLayerType;

  private readonly openSubscriptions = Array<Subscription>();

  constructor(
    private settingsService: SettingsService,
    private configurationService: ConfigurationService,
    private mapControlService: MapControlService,
    private securityService: SecurityService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.isAdmin = this.securityService.isAdminSync();

    const configurationSubscription =
      this.configurationService.sharedConfigurationModel.subscribe(
        (configuration) => {
          this.configuration = configuration;
          this.customMapLayers = configuration.additionalLayers;
          this.customMapLayers.forEach(layer => {
            layer.checked = !!this.settingsService.getBooleanValue(layer.name);
          });
        }
      );
    this.openSubscriptions.push(configurationSubscription);

    const settingsSubscription =
      this.settingsService.settingsChangedObservable.subscribe(
        (keyValuePair: ISettingKeyValuePair) => {
          this.onSettingsChanged(keyValuePair.key, keyValuePair.value);
        }
      );
    this.openSubscriptions.push(settingsSubscription);

    this.mapControlService.mapLayerSwitcher$.subscribe((state) => {
      this.filterOpen = state;
    });
  }

  hasFeatureFlag(featureFlag: string): boolean {
    return (
        this.configuration.featureFlags.find(
            (value) => value.isEnabled && value.name === featureFlag
        ) !== undefined
    );
  }

  toggleVisibility() {
    this.mapControlService.toggleMapLayerSwitcher(!this.filterOpen);
  }

  switchBaseMap(baseMapType: BaseMapType) {
    this.settingsService.setStringValue(
      SettingsService.BASE_MAP_LAYER_KEY,
      baseMapType
    );
  }

  toggleRadar() {
    this.toggleCustomLayer(SettingsService.WEATHER_RADAR_LAYER_KEY, !this.showRadar);
    if (!this.showRadar) {
      this.toggleCustomLayer(SettingsService.WEATHER_WARNINGS_LAYER_KEY, false);
    }
  }

  toggleTrafficLayer() {
    this.toggleCustomLayer(SettingsService.TRAFFIC_LAYER_KEY, !this.showTraffic);
  }

  toggleCustomLayer(key: string, checked: boolean) {
    this.settingsService.setBooleanValue(key, checked);
  }

  private onSettingsChanged(key: string, value: any) {
    switch (key) {
      case SettingsService.BASE_MAP_LAYER_KEY:
        this.baseMapLayerType = value;
        break;
      case SettingsService.WEATHER_RADAR_LAYER_KEY:
        this.showRadar = value;
        break;
      case SettingsService.WEATHER_WARNINGS_LAYER_KEY:
        this.showWarnings = value;
        break;
      case SettingsService.TRAFFIC_LAYER_KEY:
        this.showTraffic = value;
        break;
      default: // other layers in configuration
        const mapLayer = this.customMapLayers.find((it) => it.name === key);
        if (mapLayer !== undefined) {
          mapLayer.checked = value as boolean;
        }
        break;
    }
  }

  ngOnDestroy() {
    this.openSubscriptions.forEach(subscription => subscription?.unsubscribe());
  }

  baseMapTypeBackground(baseMapType: BaseMapType) {
    // if class defined in SCSS then there is a problem with base_href on AWS env
    // this is a workaround where JS knows about base_href
    const basePath = environment.base_href;
    switch (baseMapType) {
      case BaseMapType.OUTDOORS:
        return {
          'background-image': `url(\'${basePath}assets/map_thumbnails/outdoor_thumb.png\')`,
        };
      case BaseMapType.DARK:
        return {
          'background-image': `url(\'${basePath}assets/map_thumbnails/darkgrey_thumb.png\')`,
        };
      case BaseMapType.IMAGERY:
        return {
          'background-image': `url(\'${basePath}assets/map_thumbnails/satellite_thumb.png\')`,
        };
      case BaseMapType.WINTER:
        return {
          'background-image': `url(\'${basePath}assets/map_thumbnails/winter_thumb.png\')`,
        };
      default: {
        return {};
      }
    }
  }

  weatherLayerToggleBackground(weatherLayerName: string) {
    const basePath = environment.base_href;
    switch (weatherLayerName) {
      case WeatherLayerType.RADAR:
        return {
          'background-image': `url(\'${basePath}assets/map_thumbnails/radar_thumb.png\')`,
        };
      case WeatherLayerType.WARNINGS:
        return {
          'background-image': `url(\'${basePath}assets/map_thumbnails/warnings_thumb.png\')`,
        };
      case WeatherLayerType.TRAFFIC:
        return {
          'background-image': `url(\'${basePath}assets/map_thumbnails/traffic_thumb.png\')`,
        };
      default:
        return {};
    }
  }

  toggleCustomMapLayer(customLayer: MapLayer, enable: boolean) {
    this.settingsService.setBooleanValue(customLayer.name, enable);
  }

  openConfiguration() {
    const dialogRef = this.dialog.open(CustomLayersConfigComponent, {
      maxWidth: '80vw',
      maxHeight: '85vh',
      width: '500px',
      height: '450px',
      data: null,
    });
  }
}
