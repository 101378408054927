<div class="image-report-table" (scroll)="onScroll($event)">
    <table
            mat-table
            matSort
            matSortDisableClear
            multiTemplateDataRows
            [dataSource]="dataSource"
            (matSortChange)="sortChangedEvent.emit()"
            matSortActive="time"
            matSortDirection="desc"
            class="ops-table"
    >
        <ng-container matColumnDef="shift.id">
            <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    class="mat-body"
            >
                Shift ID
            </th>
            <td mat-cell *matCellDef="let element">
                <a
                        [routerLink]="[InsightsRoute.SHIFT_DETAIL, element.shiftId]"
                        [relativeTo]="activatedRoute"
                        class="ops-link"
                >{{ element.shiftId }}</a
                >
            </td>
        </ng-container>

        <ng-container matColumnDef="time">
            <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    class="mat-body"
            >
                Time
            </th>
            <td mat-cell *matCellDef="let element">
                {{ element.time | date: 'medium' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="driver">
            <th mat-header-cell *matHeaderCellDef class="mat-body">Driver</th>
            <td mat-cell *matCellDef="let element">
                {{ element.driver.name }}
            </td>
        </ng-container>

        <ng-container matColumnDef="vehicle">
            <th mat-header-cell *matHeaderCellDef class="mat-body">Vehicle</th>
            <td mat-cell *matCellDef="let element">
                {{ element.vehicle.label ? element.vehicle.label : element.vehicle.name }}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="highlight(row)"
            [ngClass]="{'active': selectedRowId == row.id}"
        >
        </tr>
    </table>
</div>
