import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigurationModel, FeatureFlag, PublicPortalSettings} from '../shared/models/configuration.model';
import {catchError, retry} from 'rxjs/operators';
import {HttpErrorHandler} from '../http.error.handler';
import {environment} from 'src/environments/environment';
import {BehaviorSubject, firstValueFrom, Observable} from 'rxjs';
import {JsonApiResponse} from '../shared/models/JsonApiResponse';
import {MapLayerConfiguration} from '../shared/models/map-layer.model';
import {TrackStyles} from './model/TrackStyles';
import {TrackStylesService} from './track-styles.service';
import {SettingsService} from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private readonly configUrl = `${environment.services.service}`;

  private readonly configurationModel = new BehaviorSubject<ConfigurationModel>(null);
  sharedConfigurationModel: Observable<ConfigurationModel> = this.configurationModel;

  // raw styles loaded from backend
  trackStyles = new TrackStyles();
  trackStyles$ = new BehaviorSubject<TrackStyles>(this.trackStyles);

  // styles loaded from backend enhanced with configuration in browser storage
  // userTrackStyles = this.trackStyles;
  userTrackStyles$ = new BehaviorSubject<TrackStyles>(this.trackStyles);
  areUserTrackStylesInitialized = false;

  constructor(
    private http: HttpClient,
    private trackStylesService: TrackStylesService,
    private settingsService: SettingsService,
  ) {
  }

  initUserTrackStyles() {
    if (!this.areUserTrackStylesInitialized) {
      this.trackStyles$.subscribe(trackStyles => {
        const userTrackStyles = TrackStyles.enhanceFromSettings(trackStyles, this.settingsService);
        this.userTrackStyles$.next(userTrackStyles);
      });
      this.areUserTrackStylesInitialized = true;
    }
  }

  refreshTrackStyles() {
    this.trackStylesService.loadTrackStyles()
      .then(trackStyles => {
        this.trackStyles = trackStyles;
        this.trackStyles$.next(this.trackStyles);
      });
  }

  refreshUserTrackStyles() {
    const userTrackStyles = TrackStyles.enhanceFromSettings(this.trackStyles, this.settingsService);
    this.userTrackStyles$.next(userTrackStyles);
  }

  refreshConfiguration() {
    firstValueFrom(this.getConfiguration())
        .then(
            (response: JsonApiResponse<ConfigurationModel>) => {
              // initialize shared config model
              this.nextConfigurationModel(response.data);
            }, // success path
            (error) => console.log(error)
        );
  }

  private getConfiguration()  {
    return this.http.get<JsonApiResponse<ConfigurationModel>>(`${this.configUrl}v1/configuration/currentTenant`)
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(HttpErrorHandler.handleError) // then handle the error
      );
  }

  private nextConfigurationModel(model: ConfigurationModel) {
    this.configurationModel.next(model);
  }

  getMapLayers(isPublic: boolean) {
    return this.http.get<JsonApiResponse<MapLayerConfiguration[]>>(`${this.configUrl}v1/configuration/mapLayer/public/${isPublic}`)
        .pipe(
            catchError(HttpErrorHandler.handleError)
        ).toPromise();
  }

  updateMapLayers(mapLayers: MapLayerConfiguration[], isPublic: boolean): Promise<JsonApiResponse<MapLayerConfiguration[]>> {
    const requestUrl = `${environment.services.service}v1/configuration/mapLayer/public/${isPublic}`;
    return firstValueFrom(this.http.post<JsonApiResponse<MapLayerConfiguration[]>>(requestUrl, mapLayers).pipe(
      catchError(HttpErrorHandler.handleError) // then handle the error
    ));
  }

  updateRouteMapLayer(routeConfigurationId: number): Promise<JsonApiResponse<MapLayerConfiguration>> {
    const requestUrl = `${environment.services.service}v1/configuration/mapLayer/routeConfiguration/${routeConfigurationId}`;
    return firstValueFrom(this.http.post<JsonApiResponse<MapLayerConfiguration>>(requestUrl, {}).pipe(
      catchError(HttpErrorHandler.handleError) // then handle the error
    ));
  }

  getCustomerId(): Promise<JsonApiResponse<string>> {
    const requestUrl = `${environment.services.service}v1/configuration/customerId`;

    return this.http.get<JsonApiResponse<string>>(requestUrl)
        .pipe(
            catchError(HttpErrorHandler.handleError)
        ).toPromise();
  }

  getPublicPortalSettings(): Promise<JsonApiResponse<PublicPortalSettings>> {
    const requestUrl = `${environment.services.service}v1/configuration/publicPortalSettings`;

    return firstValueFrom(this.http.get<JsonApiResponse<PublicPortalSettings>>(requestUrl)
        .pipe(
            catchError(HttpErrorHandler.handleError)
        ));
  }

  updatePublicPortalSettings(settings: PublicPortalSettings) {
    const requestUrl = `${environment.services.service}v1/configuration/publicPortalSettings`;
    return firstValueFrom(this.http.post(requestUrl, settings).pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
    ));
  }

  updateLogo(logo: string) {
    const requestUrl = `${environment.services.service}v1/configuration/logo`;
    return this.http.post(requestUrl, logo).pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
    ).toPromise();
  }

  updateGuestDriverFeature(allowGuestDrivers: boolean) {
    const requestUrl = `${environment.services.service}v1/configuration/guestDrivers/${allowGuestDrivers}`;
    return this.http.post(requestUrl, {}).pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
    ).toPromise();
  }

  /**
   * Returns true if there is a feature flag in the list and its value is true.
   * @param flags feature flags
   * @param featureFlag feature flag
   */
  hasFeatureFlag(flags: FeatureFlag[], featureFlag: string): boolean {
    return flags && flags.find(value => value.isEnabled && value.name === featureFlag) !== undefined;
  }

}
