@if (preCardTitle) {
	<div class="pre-card-title">
		@if (icon) {
			<button mat-icon-button
					[disabled]="iconDisabled"
					(click)="iconClicked.emit()">
				<mat-icon>{{icon}}</mat-icon>
			</button>
		}
		<h2>{{ preCardTitle }}</h2>
	</div>
}

<mat-card class="my-8 card">
	@for (c of content; track c.templateRef) {
        @if(!c.needsMargin) {
			@if ($first && inCardTitle) {
				<mat-card-actions class="in-card-title">
					@if (icon) {
						<button mat-icon-button
								[disabled]="iconDisabled"
								(click)="iconClicked.emit()">
							<mat-icon>{{icon}}</mat-icon>
						</button>
					}
					<h2>{{ inCardTitle }}</h2>
				</mat-card-actions>
			}
			<ng-container [ngTemplateOutlet]="c.templateRef"></ng-container>
		} @else {
			@if ($first && inCardTitle) {
				<mat-card-actions class="in-card-title">
					@if (icon) {
						<button mat-icon-button
								[disabled]="iconDisabled"
								(click)="iconClicked.emit()">
							<mat-icon>{{icon}}</mat-icon>
						</button>
					}
					<h2>{{ inCardTitle }}</h2>
				</mat-card-actions>
			}
			<mat-card-content>
            	<ng-container [ngTemplateOutlet]="c.templateRef"></ng-container>
        	</mat-card-content>
		}
        <mat-divider *ngIf="!$last"></mat-divider>
    }
</mat-card>
