import { NgModule } from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {RouterModule} from '@angular/router';
import {ShiftMapComponent} from './components/shift-map.component';
import {ShiftMapPanelComponent} from './components/shift-map-panel/shift-map-panel.component';
import {MaterialModule} from '../../shared/material/material.module';
import {ShiftMapInspectionDialogComponent} from './components/shift-map-panel/shift-map-panel-dialogs';

@NgModule({
    declarations: [
        ShiftMapComponent,
        ShiftMapPanelComponent,
        ShiftMapInspectionDialogComponent,
    ],
    exports: [
    ],
    imports: [
        SharedModule,
        RouterModule,
        MaterialModule,
    ]
})
export class ShiftMapModule { }
