<form>
	@if (sections.length > 1 || sections[0].groups.length > 1 || sections[0].groups[0].questions.length > 0) {
		@for (section of sections; track (section.id || 'idx_' + $index)) {
			<app-card class="dvir-section" [preCardTitle]="sections.length > 1 ? 'Section' : (sections[0].groups.length > 1 ? 'Question Groups' : 'Questions')">
				@if (sections.length > 1) {
					<div *appCardContent>
						<div class="settings-header-container dvir-section-header mb-spacing">
							<h1>{{section.title}}</h1>
						</div>
					</div>
				}

				@for (group of section.groups; track $index) {
					<div *appCardContent class="dvir-group my-spacing">
						@if (sections[0].groups.length > 1 || section.groups.length > 1) {
							<div class="settings-header-container dvir-group-header">
								<h2>{{group.title}}</h2>
							</div>
						}
						<div class="dvir-questions gap-4">
							@for (question of group.questions; track $index) {
								<div class="dvir-question flex-row align-items-center">
									<div class="dvir-question-number">
										Q{{$index + 1}}
									</div>
									<div class="flex-grow-1">
										{{question.title}}
									</div>
									@if (question.type === DvirInspectionQuestionDataType.DROPDOWN) {
										<mat-icon [matTooltip]="'Selection from list of options'" class="text-muted">list</mat-icon>
									}
								</div>
							}
						</div>
						<!--/app-expansion-panel-->
					</div>
				} <!-- group -->
			</app-card>
		} <!-- section -->
	} <!-- should show questions -->
</form>
