import {MapContentLayer} from '../MapContentLayer';
import {MapContentSource} from '../MapContentSource';
import { FilterSpecification, LayerSpecification, Map, SymbolLayerSpecification } from 'maplibre-gl';
import {MapFilters} from '../../../../../configuration/map-filters';
import {ActivityFilter} from '../../../../../configuration/settings.service';
import {MapPreviewComponent} from '../../map-preview.component';
import moment from 'moment';
import { ShiftWithDriverAndVehicleModel } from '../../../../models/shift.model';

export class ShiftTrackArrowsLayer extends MapContentLayer {

  private filterFrom = 0;
  private filterTo = 100;

  private readonly shiftStart: number;
  private readonly shiftLength: number;

  constructor(
    source: MapContentSource,
    private shift?: ShiftWithDriverAndVehicleModel, // if null, filtering by time not supported
  ) {
    super('shift-track-arrows-layer', source);
    this.shiftStart = !!shift ? moment(shift.start).unix() : null;
    this.shiftLength = !!shift ? moment(shift.end).diff(moment(shift.start), 'seconds') : null;
  }

  toLayerSpecification(): LayerSpecification {
    return {
      id: this.layerId,
      type: 'symbol',
      source: this.sourceRef.sourceId,
      filter: MapFilters.getShiftGeoJsonFilter(
        ActivityFilter.NONE, this.getAbsTime(this.filterFrom), this.getAbsTime(this.filterTo)
      ),
      layout: {
        'symbol-placement': 'line',
        'icon-image': MapPreviewComponent.TRACK_ARROW_IMAGE,
        'icon-rotate': 90,
        'icon-rotation-alignment': 'map',
        'icon-allow-overlap': true,
        'icon-ignore-placement': true
      },
      paint: {
      }
    } as SymbolLayerSpecification;
  }

  filterByTime(map: Map, timeFrom: number, timeTo: number) {
    this.filterFrom = timeFrom;
    this.filterTo = timeTo;

    map.setFilter(
      this.layerId,
      MapFilters.getShiftGeoJsonFilter(
        ActivityFilter.NONE, this.getAbsTime(this.filterFrom), this.getAbsTime(this.filterTo)
      ) as FilterSpecification,
    );
  }

  private getAbsTime(relativePosition: number): number {
    // unknown start or length
    if (!this.shiftStart || !this.shiftLength) {
      return null;
    }
    // do not filter start or end at all
    if (relativePosition === 0 || relativePosition === 100) {
      return null;
    }
    return Math.round(this.shiftStart + relativePosition / 100 * this.shiftLength);
  }
}
