import {Component, OnInit} from '@angular/core';
import {RoutesService} from '../../../../../data/routes/routes.service';
import {RouteConfigurationWithSchema} from '../../../../../shared/models/route';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ToastService} from '../../../../../shared/services/toast.service';
import {DialogConfirmDeleteRouteConfigComponent} from '../dialogs/dialog-components';
import {RmRoot} from '../model/RmRoot';
import {RmConfiguration} from '../model/RmConfiguration';
import {RmNode} from '../model/RmNode';
import {ActionMenuItem, ActionMenuItemSubMenu} from '../../../../../shared/models/action-menu-item.class';
import {NodesAndBreadcrumbs} from '../model/NodesAndBreadcrumbs';
import {MapContent} from '../../../../../shared/components/map-preview/model/MapContent';
import {EmptyMapContent} from '../../../../../shared/components/map-preview/model/EmptyMapContent';
import {FeatureCollection} from 'geojson';
import {
  RouteConfigListMapContent
} from '../../../../../shared/components/map-preview/model/map-content/RouteConfigListMapContent';
import {Base64Tools} from '../../../../../shared/tools/Base64Tools';


@Component({
  selector: 'app-route-configuration-list',
  templateUrl: './route-configuration-list.component.html',
  styleUrls: ['./route-configuration-list.component.scss', '../../../settings-common.scss']
})
export class RouteConfigurationListComponent implements OnInit {

  root: RmRoot;
  routeConfigGeometries: FeatureCollection;

  isLoading = true;

  backButtonEnabled = false;
  backButtonTarget: RmNode = null;
  breadcrumbs = '';
  leftItems: ActionMenuItem[] = [];
  rightItems: ActionMenuItem[] = [];
  mapContent: MapContent = EmptyMapContent.instance;

  constructor(
    public dialog: MatDialog,
    private routesService: RoutesService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.root = RmRoot.fromRouteConfigurations([]);

    this.routesService.getRouteConfigurations().then(routeConfigsResponse => {
      this.root = RmRoot.fromRouteConfigurations(routeConfigsResponse.data);
      this.isLoading = false;

      /*this.routesService.getRouteGeoJson().then(routeConfigGeometriesResponse => {
        this.routeConfigGeometries = routeConfigGeometriesResponse;

        this.subscribeToQueryParamChanges();
        if (!this.activatedRoute.snapshot.queryParamMap.has('route-path-ids')) {
          // selecting the first configuration if nothing is selected
          const routeConfigs = this.root.leftColumnNodes();
          if (!!routeConfigs && routeConfigs.length > 0) {
            const firstRouteConfig = routeConfigs[0];
            this.selectRoute(firstRouteConfig);
          }
        }
        this.isLoading = false;

      }).catch(error => {
        const msg = 'error while loading map features';
        this.toastService.long(msg);
        console.error(`${msg} :: ${error}`);
        this.isLoading = false;
      });*/

    }).catch(error => {
      const msg = 'error while loading route configurations';
      this.toastService.long(msg);
      console.error(`${msg} :: ${error}`);
      this.isLoading = false;
    });
  }

  private updateUiModel() {
    const leftNodes: RmConfiguration[] = this.root.leftColumnNodes();
    const rightNodes: NodesAndBreadcrumbs = this.root.rightColumnNodes();

    this.backButtonEnabled = rightNodes.backButtonTarget != null;
    this.backButtonTarget = rightNodes.backButtonTarget;
    this.breadcrumbs = rightNodes.shortenedBreadcrumbs();
    this.leftItems = leftNodes.map(item => this.routeConfigActionMenuItem(item));
    this.rightItems = rightNodes.nodes.map(item => this.routeOrIntermediaryActionMenuItem(item));

    if (this.mapContent instanceof RouteConfigListMapContent) {
      const castMapContent = this.mapContent as RouteConfigListMapContent;
      const routeConfigs = this.root.getMapContentRouteConfigurations();
      castMapContent.changeRouteVisibility(routeConfigs);
    } else {
      this.recreateMapContent();
    }
  }

  backButtonClicked() {
    if (this.backButtonTarget != null) {
      this.selectRoute(this.backButtonTarget);
    }
  }

  addRouteConfiguration(): void {
    this.router.navigate(['/settings/manage-routes', 'new'])
      .then();
  }

  editRouteConfiguration(node: RmNode) {
    this.router.navigate(['/settings/manage-routes', node.asConfigBase().id])
      .then();
  }

  deleteRouteConfiguration(node: RmNode) {
    const deleted: RouteConfigurationWithSchema = node.asConfigBase();
    const dialogRef = this.dialog.open(DialogConfirmDeleteRouteConfigComponent, {
      data: {id: deleted.id, name: deleted.name},
      width: '350px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!!result) {
        this.routesService.deleteRouteConfiguration(result)
          .then(() => {
            node.delete(); // deletes itself from structure
            this.recreateMapContent();
            if (node.isSelected()) { // if it was selected
              this.updateQueryParams();
            } else {
              this.updateUiModel(); // update UI without changing query params
            }
          })
          .catch(error => {
            const msg = 'error while deleting route configuration';
            this.toastService.long(msg);
            console.error(`${msg} :: ${error}`);
          });
      }
    });
  }

  private routeConfigActionMenuItem(node: RmNode): ActionMenuItem {
    return new ActionMenuItem(
      node.actionMenuItemId(),
      'route',
      node.actionMenuItemTitle(),
      node.actionMenuItemSubTitle(),
      '',
      null,
      () => node.isSelected(),
      null,
      null,
      () => { this.selectRoute(node); },
      null,
      null,
      [
        new ActionMenuItemSubMenu(
          'edit',
          'Edit configuration',
          () => { this.editRouteConfiguration(node); }
        ),
        new ActionMenuItemSubMenu(
          'delete',
          'Delete configuration',
          () => { this.deleteRouteConfiguration(node); }
        )
      ]
    );
  }

  private routeOrIntermediaryActionMenuItem(node: RmNode): ActionMenuItem {
    return new ActionMenuItem(
      node.actionMenuItemId(),
      'route',
      node.actionMenuItemTitle(),
      node.actionMenuItemSubTitle(),
      '',
      null,
      () => node.isSelected(),
      null,
      null,
      () => { this.selectRoute(node); },
      null,
      null,
      []
    );
  }

  private selectRoute(node: RmNode) {
    node.select(); // selects itself in structure
    this.updateQueryParams();
  }

  /**
   * Updates query params from structure,
   * triggers subscription to their change,
   * updates UI
   */
  private updateQueryParams() {
    const pathIds = this.root.collectPathIdsForQueryParams();
    if (pathIds.length > 0) {
      const pathIdsEncoded = pathIds.map(value => Base64Tools.encodeText(value));
      const pathIdsJoined = pathIdsEncoded.join('-');
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {'route-path-ids': pathIdsJoined},
        queryParamsHandling: '',
      });
    } else {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {},
        queryParamsHandling: '',
      });
    }
  }

  private recreateMapContent() {
    if (this.root.isAnyConfigurationSelected()) {
      const routeConfigs = this.root.getMapContentRouteConfigurations();
      this.mapContent = new RouteConfigListMapContent(routeConfigs, this.routeConfigGeometries);
    } else {
      this.mapContent = EmptyMapContent.instance;
    }
  }
}
