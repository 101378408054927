declare function require(path: string): any;

const pkg = require('../../package.json');
const buildVersion = pkg.version;

export const environment = {
  name: 'test',
  production: true,
  authentication: {
    endpoint: undefined,
    userPoolId: 'us-east-1_nLabHWC8W',
    clientId: '6gto8m5lvrv18v3mfgmc61ffbo',
  },
  base_href: '/',
  publicBaseUrl: 'https://public.test.plowops.com/',
  services: {
    location: 'https://tracking.location-prod.neotreks.com/',
    service: 'https://api.test.plowops.com/api/',
    plowopsWebsocket: 'wss://ws.test.plowops.com',
    locationWebsocket: 'wss://tracking-ws.location-prod.neotreks.com',
  },
  version: buildVersion
};
