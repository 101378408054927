import {Component, Input, OnChanges, output, SimpleChanges, ViewChild} from '@angular/core';
import {
  MatNestedTreeNode,
  MatTree,
  MatTreeNestedDataSource,
  MatTreeNode,
  MatTreeNodeDef,
  MatTreeNodeOutlet,
  MatTreeNodeToggle
} from '@angular/material/tree';
import {RouteConfiguration, RouteHierarchyItem} from '../../../../../shared/models/route';
import {SelectionModel} from '@angular/cdk/collections';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';
import {SharedModule} from '../../../../../shared/shared.module';
import {MatIcon} from '@angular/material/icon';
import {MatTooltip} from '@angular/material/tooltip';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatIconButton} from '@angular/material/button';

export interface RouteFilter {
  routeIds: number[];
  all: boolean;
}

@Component({
  selector: 'app-route-tree',
  standalone: true,
  templateUrl: './route-tree.component.html',
  imports: [
    MatTree,
    MatMenu,
    SharedModule,
    MatIcon,
    MatTooltip,
    MatMenuTrigger,
    MatCheckbox,
    MatTreeNode,
    MatNestedTreeNode,
    MatTreeNodeDef,
    MatTreeNodeToggle,
    MatIconButton,
    MatTreeNodeOutlet
  ],
  styleUrls: ['./route-tree.component.scss']
})
export class RouteTreeComponent implements OnChanges {

  @Input() routeConfiguration: RouteConfiguration;
  @Input() attributes: string[];
  @Input() filteringMode = true;

  routeFiltered = output<RouteFilter>();
  styleChanged = output<RouteConfiguration>();

  routeHover = output<RouteHierarchyItem>();
  routeLeave = output<RouteHierarchyItem>();

  @ViewChild('treeControl') tree: MatTree<RouteHierarchyItem>;

  dataSource: MatTreeNestedDataSource<RouteHierarchyItem> = new MatTreeNestedDataSource<RouteHierarchyItem>();

  childrenAccessor = (node: RouteHierarchyItem) => node.children;

  checklistSelection = new SelectionModel<RouteHierarchyItem>(true);

  allDashArrays: number[][] = [
    [10],
    [5, 4],
    [2, 4],
    [1, 4],
    [4, 5, 1, 5],
  ];

  readonly colorNoOverride = '#dbdbdd';

  constructor() { }

  ngOnChanges(_: SimpleChanges) {
    if (!this.routeConfiguration || !this.attributes) {
      return
    }

    if (this.filteringMode) {
      this.childrenAccessor = (node: RouteHierarchyItem) => node.children;
      this.dataSource.data = this.routeConfiguration.children;
    } else {
      const filterOutHiddenNodes = (node: RouteHierarchyItem) => !node.hidden;
      this.childrenAccessor = (node: RouteHierarchyItem) => node.children?.filter(filterOutHiddenNodes) || [];
      this.dataSource.data = this.routeConfiguration.children.filter(filterOutHiddenNodes);
    }

    const allItems = RouteHierarchyItem.getFlat(this.attributes.length + 1, this.routeConfiguration.children);
    const visibleItems = allItems.filter(it => !it.hidden);
    if (allItems.length === visibleItems.length) {
      visibleItems.push(this.dataSource.data[0]);
    }

    // filter leave level items and select by default
    this.checklistSelection = new SelectionModel<RouteHierarchyItem>(
        true,
        visibleItems
    );
    this.nodeSelectionChanged();

    //this.treeControl.dataNodes = this.dataSource.data;
    setTimeout(() => this.tree.expandAll(), 100);
  }

  hasChild = (_: number, node: RouteHierarchyItem) => !!node.children && node.children.length > 0;

  getLevel(node: RouteHierarchyItem) {
    if (!node.childrenAttribute) {
      return this.attributes.length;
    }
    return this.attributes.indexOf(node.childrenAttribute);
  }
  /*selectAll(event: MatCheckboxChange) {
    if (event.checked) {
      this.checklistSelection.select(...RouteHierarchyItem.getLeaves(this.attributes.length, this.routeConfiguration.children));
      this.changeMapLayerFilter([], true);
    } else {
      this.checklistSelection.clear();
      this.changeMapLayerFilter([], false);
    }
  }*/

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: RouteHierarchyItem): boolean {
    const descendants = this.childrenAccessor(node);
    return descendants.length > 0 &&
        descendants.every(child => {
          return this.checklistSelection.isSelected(child);
        });
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: RouteHierarchyItem): boolean {
    const descendants = this.childrenAccessor(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the item selection. Select/deselect all the descendants node */
  itemSelectionToggle(node: RouteHierarchyItem): void {
    this.checklistSelection.toggle(node);
    const descendants = this.childrenAccessor(node);
    const isNodeSelected = this.checklistSelection.isSelected(node);
    node.hidden = !isNodeSelected;
    isNodeSelected
        ? this.checklistSelection.select(...descendants)
        : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => {
      this.checklistSelection.isSelected(child);
      child.hidden = !isNodeSelected;
    });
    this.checkAllParentsSelection(node);

    this.nodeSelectionChanged();
  }

  /** Toggle a leaf item selection. Check all the parents to see if they changed */
  leafItemSelectionToggle(node: RouteHierarchyItem): void {
    this.checklistSelection.toggle(node);
    const isNodeSelected = this.checklistSelection.isSelected(node);
    node.hidden = !isNodeSelected;
    this.checkAllParentsSelection(node);

    this.nodeSelectionChanged();
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: RouteHierarchyItem): void {
    let parent: RouteHierarchyItem | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: RouteHierarchyItem): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.childrenAccessor(node);
    const descAllSelected =
        descendants.length > 0 &&
        descendants.every(child => {
          return this.checklistSelection.isSelected(child);
        });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: RouteHierarchyItem): RouteHierarchyItem | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.routeConfiguration.children.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.routeConfiguration.children[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  nodeSelectionChanged() {
    const routeIds = this.checklistSelection.selected.map(node => node.routeId).filter(routeId => !!routeId);
    this.changeMapLayerFilter(routeIds);
  }

  changeMapLayerFilter(routeIds: number[], all: boolean = false) {
    this.routeFiltered.emit({routeIds, all});
  }

  clearColor(item: RouteHierarchyItem) {
    item.color = undefined;
    this.styleChanged.emit(this.routeConfiguration);
  }

  colorChanged() {
    this.styleChanged.emit(this.routeConfiguration);
  }

  selectDashArray(item: RouteHierarchyItem, index: number) {
    item.lineType = Array.from(this.allDashArrays[index]);
    this.styleChanged.emit(this.routeConfiguration);
  }

  clearDashArray(item: RouteHierarchyItem) {
    item.lineType = undefined;
    this.styleChanged.emit(this.routeConfiguration);
  }

  onHoverNode(node: RouteHierarchyItem) {
    this.routeHover.emit(node);
  }

  onLeaveNode(node: RouteHierarchyItem) {
    this.routeLeave.emit(node);
  }
}
