import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ConfigurationModel} from '../../../../../shared/models/configuration.model';
import {InfiniteScroll} from '../../../../../shared/tools/InfiniteScroll';
import {InsightsRoute} from '../../../insights-routing.module';
import {DeviceReportRecordModel} from '../../../../../shared/models/device-report.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-device-report-table',
  templateUrl: './device-report-table.component.html',
  styleUrls: ['./device-report-table.component.scss']
})
export class DeviceReportTableComponent implements OnInit {

  @Input() configuration: ConfigurationModel;
  @ViewChild(MatSort) sort: MatSort;

  @Input() dataSource: MatTableDataSource<DeviceReportRecordModel>;

  @Output() sortChangedEvent: EventEmitter<void> = new EventEmitter();
  @Output() scrolledToBottomEvent: EventEmitter<void> = new EventEmitter();

  columns = ['shiftId', 'phoneNumber', 'imei', 'sn', 'appVersionCode', 'appVersionName', 'deviceManufacturer', 'deviceModel', 'deviceSdkVersion', 'deviceOsVersion', 'deviceOsUid'];

  displayedColumnsWithAction: string[];

  InsightsRoute = InsightsRoute;

  constructor(
    public activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.displayedColumnsWithAction = [...this.columns, 'action'];
  }

  getSort(): string | undefined {
    if (!this.sort.active) {
      return undefined;
    }
    return `${this.sort.active},${this.sort.direction}`;
  }

  onScroll(e) {
    InfiniteScroll.onScroll(e, () => this.scrolledToBottomEvent.emit());
  }
}
