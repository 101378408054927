<h1 mat-dialog-title>
	Form data won't be saved
</h1>

<mat-dialog-content>
	<p>
		Form is not valid and the changes were not saved, are you sure you want to leave?
	</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
	<button mat-stroked-button [mat-dialog-close]="true">
		Yes, don't save changes
	</button>
	<button mat-raised-button color="accent" mat-dialog-close cdkFocusInitial>
		Cancel
	</button>
</mat-dialog-actions>
