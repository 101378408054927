<mat-form-field appearance="outline" color="accent" class="no-hint w-100" floatLabel="always">
    <mat-label>Line Type</mat-label>
    <mat-select [(ngModel)]="dashArray" (ngModelChange)="onChange($event)" placeholder="Solid">
        <mat-select-trigger>
            <app-line-dash-array-view [dashArray]="dashArray"></app-line-dash-array-view>
        </mat-select-trigger>
        <ng-container *ngFor="let dashArray of lineTypes">
            <mat-option [value]="dashArray">
                <app-line-dash-array-view [dashArray]="dashArray"></app-line-dash-array-view>
            </mat-option>
        </ng-container>
    </mat-select>
</mat-form-field>
