@if (isWorking) {
	<mat-spinner mode="indeterminate" diameter="30" class="mx-a"></mat-spinner>
}
@if (isInitialized) {
	<app-card inCardTitle="{{vehicle.name || 'unnamed'}}"
			  icon="arrow_back"
			  (iconClicked)="navigateBack()">
		<div *appCardContent>
			<ng-template [ngTemplateOutlet]="vehicleBasic">
			</ng-template>
		</div>
		<div *appCardContent>
			<app-row-with-toggle primaryText="Hardware Integrations"
								 [(checked)]="ui.additionalHardware"
								 (checkedChange)="additionalHardwareToggled()"
			></app-row-with-toggle>
		</div>
		@if (ui.additionalHardware) {
			<div *appCardContent>
				<ng-template [ngTemplateOutlet]="vehicleHardware"></ng-template>
			</div>
			<div *appCardContent>
				<ng-template [ngTemplateOutlet]="dashCamera"></ng-template>
			</div>
		}

		@if (!this.isImported) {
			<div *appCardContent class="mt-spacing">
				<h2>Delete Vehicle</h2>
				<div class="settings-header-container">
					<div class="settings-header-title">
						<p>Delete this vehicle</p>
						<div class="settings-header-subtitle">Any saved shifts from vehicle will remain</div>
					</div>
					<div class="settings-header-actions">
						<button mat-stroked-button
								(click)="deleteVehicle()">
							Delete Vehicle
						</button>
					</div>
				</div>
			</div>
		}
	</app-card>
}

<ng-template #vehicleBasic>
	<div class="mb-spacing">
		<app-expansion-panel [expanded]="true"
							 panelTitle="Vehicle settings"
							 panelDescription="Modify settings for this vehicle">
			<div class="indent">
				<form [formGroup]="form">
					<div class="field-row">
						<div class="field-row-title">
							Vehicle ID
						</div>

						<div class="field-row-element">
							<mat-form-field appearance="outline" color="accent" subscriptSizing="dynamic">
								<input matInput
									   autocomplete="off"
									   required
									   formControlName="name"
								>
                                @if (form.controls['name'].hasError('required')) {
                                    <mat-error> Vehicle ID is required.</mat-error>
                                }
                                @if (form.controls['name'].hasError('uniqueValue')) {
								    <mat-error>Vehicle ID already exists.</mat-error>
                                }
							</mat-form-field>
						</div>
					</div>

					<div class="field-row">
						<div class="field-row-title">
							Vehicle Alias
						</div>

						<div class="field-row-element">
							<mat-form-field appearance="outline" color="accent">
								<input matInput
									   autocomplete="off"
									   formControlName="label">
                                @if (form.controls['label'].hasError('uniqueValue')) {
                                    <mat-error> Vehicle Alias already exists.</mat-error>
                                }
								<mat-hint>
									Optional. Use the alias if the vehicle name is more than 20 characters.
								</mat-hint>
							</mat-form-field>

						</div>
					</div>

					<div class="field-row">
						<div class="field-row-title">
							Public Portal Alias
						</div>

						<div class="field-row-element">
							<mat-form-field appearance="outline" color="accent">
								<input matInput
									   autocomplete="off"
									   formControlName="publicAlias">
                            @if (form.controls['publicAlias'].hasError('uniqueValue')) {
                                <mat-error> Public Portal Alias already exists.</mat-error>
                            }
								<mat-hint>
									Optional
								</mat-hint>
							</mat-form-field>

						</div>
					</div>

					<div class="field-row">
						<div class="field-row-title">
							License Plate
						</div>
						<div class="field-row-element">
							<mat-form-field appearance="outline" color="accent">
								<input matInput
									   autocomplete="off"
									   formControlName="licensePlate">
                                @if (form.controls['licensePlate'].hasError('uniqueValue')) {
                                    <mat-error> License Plate already exists.</mat-error>
                                }
							</mat-form-field>

						</div>
					</div>
				</form>

				<app-row-with-select primaryText="Vehicle status"
									 [(model)]="vehicle.active"
									 (modelChange)="saveVehicle()"
									 [list]="ui.allVehicleStatuses"
									 listId="id"
									 listLabel="name">
				</app-row-with-select>

				<div class="mt-spacing">
					<app-row-with-select primaryText="Reporting Metric"
										 [(model)]="vehicle.reportingType"
										 (modelChange)="saveVehicle()"
										 [list]="ui.allReportingTypes"
										 listId="id"
										 listLabel="name"></app-row-with-select>
				</div>

				<app-row-with-toggle primaryText="Override default GPS track color"
									 [(checked)]="ui.gpsColorEnabled">
				</app-row-with-toggle>

				@if (ui.gpsColorEnabled) {
					<div class="field-row indent">
						<div class="field-row-title">
							GPS Track Color
						</div>
						<div class="field-row-element">
							<mat-form-field appearance="outline" color="accent" floatLabel="always"
											[cpDisableInput]="!ui.gpsColorEnabled" [cpDisabled]="!ui.gpsColorEnabled"
											[(colorPicker)]="vehicle.mapColor"
											[cpPosition]="'top-left'"
											(colorPickerChange)="saveVehicle()">
								<input matInput
									   autocomplete="off"
									   [placeholder]="defaultMapColor"
									   [(ngModel)]="vehicle.mapColor"
									   [disabled]="!ui.gpsColorEnabled"
                                       #ngMapColor="ngModel"
								>
                                @if (vehicle.mapColor) {
                                    <button matSuffix mat-icon-button aria-label="Clear"
                                            (click)="vehicle.mapColor=null;this.ui.gpsColorEnabled=false;saveVehicle();">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                }
								<mat-hint>Leave blank to use default color (recommended)</mat-hint>
							</mat-form-field>
						</div>
					</div>
				}

				<app-row-with-toggle primaryText="Using Tablet"
									 [(checked)]="ui.usingTablet"
									 (checkedChange)="usingTabletUpdated()">

				</app-row-with-toggle>

				<!--cartegraph settings -->
                @if (cartegraph.manualLogFeatureEnabled) {
                    <div class="mt-spacing">
                        <app-row-with-select primaryText="Log material amount by driver"
                                             [(model)]="cartegraph.manualMaterialLogOption"
                                             (modelChange)="updateMaterialAmountChange()"
                                             [list]="ui.allManualMaterialOptions"
                                             nullLabel="By global settings"
                                             listId="id"
                                             listLabel="name"></app-row-with-select>
                    </div>
                }
            </div>
		</app-expansion-panel>
	</div>
</ng-template>

<ng-template #vehicleHardware let-data="data" let-options="options">
	<div class="mt-spacing">
		<app-expansion-panel [expanded]="true"
							 panelTitle="Hardware">
			<div class="indent">
				<button mat-stroked-button
						(click)="modifyHardware()">
					Modify hardware
				</button>

                @if (!vehicle.hardwareConfiguration) {
                    <div class="list-item">
					<div class="item-text-secondary">
						No hardware has been added to this vehicle yet
					</div>
				</div>
                }

                @if (!!vehicle.hardwareConfiguration) {
                    <div class="list-item">
                        <div class="item-text-secondary">
                            AVL model: {{ vehicle.hardwareConfiguration.avlModel }}
                        </div>
                    </div>
                }
                @if (vehicle.hardwareConfiguration?.sensorInputs?.length > 0) {
					<span>Sensors:</span>
					@for (input of vehicle.hardwareConfiguration?.sensorInputs; track input.id) {
						<div class="list-item ml-25">
							<div class="item-text">
                                {{ input.type }}: {{ input.name }}
                                @if (!!input.label) {
                                    <span> - {{ input.label }}</span>
                                }
                            </div>
                        </div>
                    }
                }
            </div>
		</app-expansion-panel>
	</div>
</ng-template>

<ng-template #dashCamera let-data="data" let-options="options">
	<div class="mt-spacing">
		<app-expansion-panel [expanded]="true"
							 panelTitle="Dash Camera">
			<div class="indent">
				<button mat-stroked-button
						(click)="modifyDashCamera()">
					Modify Dash Camera
				</button>

                @if (!vehicle.cameraConfiguration) {
                    <div class="list-item">
					<div class="item-text-secondary">
						No camera has been added to this vehicle yet
					</div>
				</div>
                }

                @if (!!vehicle.cameraConfiguration) {
					<div class="list-item">
						<div class="item-text">
                            @if (!!vehicle.cameraConfiguration?.localRecording?.captureEnabled) {
                                <span>Local recording, </span>
                            }
                            @if ((vehicle.cameraConfiguration?.localRecording?.captureImageInterval > 0)) {
                                <span>Local image capturing, </span>
                            }
                            @if ((vehicle.cameraConfiguration?.streaming?.captureStreamDuration > 0)) {
                                <span>Streaming, </span>
                            }
							<span>Rotation {{ vehicle.cameraConfiguration?.rotation }}</span>
						</div>
					</div>
                }
            </div>
		</app-expansion-panel>
	</div>
</ng-template>
