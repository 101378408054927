import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LiveMapComponent } from './components/live-map.component';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { LiveMapPanelComponent } from './components/left-panel/live-map-panel.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatHoverDirective } from 'src/app/shared/directives/mat-hover.directive';
import { LiveMapRoutingModule } from './live-map-routing.module';
import {CommonModule, DatePipe} from '@angular/common';
import { LiveMapLoaderComponent } from './components/ui-components/live-map-loader/live-map-loader.component';
import {FilterRecipientsPipe} from './utilities/filter-messages.pipe';
import {ConfirmDeleteMessageDialogComponent} from './components/right-panel/messages/dialogs/confirm-delete-message-dialog.component';
import { MessagesComponent } from './components/right-panel/messages/messages.component';
import { LiveVideoComponent } from './components/right-panel/live-video/live-video.component';
import { AssetComponent } from './components/ui-components/asset/asset.component';
import { RouteSelectorComponent } from './components/ui-components/route-selector/route-selector.component';
import { ObservationsComponent } from './components/right-panel/observations/observations.component';
import {ShortDateOrTimePipe} from '../../shared/formatting/short-date-or-time.pipe';
import { VehicleGroupFilterButtonComponent } from './components/ui-components/filters/vehicle-group-filter-button/vehicle-group-filter-button.component';
import { StatusFilterButtonComponent } from './components/ui-components/filters/status-filter-button/status-filter-button.component';
import { HardwareTypeFilterButtonComponent } from './components/ui-components/filters/hardware-type-filter-button/hardware-type-filter-button.component';
import { ObservationTypeGroupFilterButtonComponent } from './components/ui-components/filters/observation-type-group-filter-button/observation-type-group-filter-button.component';
import { DateRangeFilterButtonComponent } from './components/ui-components/filters/date-range-filter-button/date-range-filter-button.component';
import { CompletedAssignmentsStatsComponent } from './components/ui-components/completed-assignments-stats/completed-assignments-stats.component';
import {
    IncludeOfflineFilterComponent
} from './components/ui-components/filters/include-offline-filter/include-offline-filter.component';
import {RouteGroupMenuComponent} from './components/ui-components/route-group-menu/route-group-menu.component';
import {LiveAddressComponent} from './components/ui-components/live-address/live-address.component';
import {RightPanelButtonComponent} from './components/ui-components/right-panel-button/right-panel-button.component';
import {RouteComponent} from './components/ui-components/route/route.component';
import {
    RouteAssignmentQueueComponent
} from './components/ui-components/route-assignment-queue/route-assignment-queue.component';
import {MessageComponent} from './components/right-panel/messages/message/message.component';
import {AlertsComponent} from './components/right-panel/alerts/alerts.component';
import { LeftMenuItemComponent } from './components/ui-components/left-menu-item/left-menu-item.component';
import { RouteAssignmentComponent } from './components/right-panel/route-assignment/route-assignment.component';
import { QuickInsightsComponent } from './components/right-panel/quick-insights/quick-insights.component';
import { DurationPipe } from '../../shared/formatting/duration.pipe';
import { DistancePipe } from '../../shared/formatting/distance.pipe';
import { WeightPipe } from '../../shared/formatting/weight.pipe';
import { SpreaderMassPipe } from '../../shared/formatting/spreader-mass.pipe';
import { SpreaderLiquidPipe } from '../../shared/formatting/spreader-liquid.pipe';
import { VolumePipe } from '../../shared/formatting/volume.pipe';

@NgModule({
    declarations: [
        LiveMapComponent,
        LiveMapPanelComponent,
        FilterRecipientsPipe,
        ConfirmDeleteMessageDialogComponent,
        MatHoverDirective,
        LiveMapLoaderComponent,
        MessagesComponent,
        MessageComponent,
        LiveVideoComponent,
        AssetComponent,
        LiveAddressComponent,
        RouteAssignmentComponent,
        QuickInsightsComponent,
        RouteSelectorComponent,
        ObservationsComponent,
        VehicleGroupFilterButtonComponent,
        StatusFilterButtonComponent,
        HardwareTypeFilterButtonComponent,
        ObservationTypeGroupFilterButtonComponent,
        DateRangeFilterButtonComponent,
        IncludeOfflineFilterComponent,
        CompletedAssignmentsStatsComponent,
        RightPanelButtonComponent,
        RouteComponent,
        RouteGroupMenuComponent,
        RouteAssignmentQueueComponent,
        AlertsComponent,
        LeftMenuItemComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        SharedModule,
        LiveMapRoutingModule,
    ],
    providers: [
        DatePipe,
        ShortDateOrTimePipe,
        DurationPipe,
        DistancePipe,
        WeightPipe,
        VolumePipe,
        SpreaderMassPipe,
        SpreaderLiquidPipe,
    ],
    exports: [
        LiveVideoComponent,
        MessagesComponent,
        RouteAssignmentComponent,
        ObservationsComponent,
        AlertsComponent,
        QuickInsightsComponent,
    ]
})
export class LiveMapModule {}
