import {inject} from '@angular/core';
import {SecurityService} from './security.service';
import { Router } from '@angular/router';
import { RootRoute } from '../shared/models/angular-routing';

export const adminGuard = () => {
    const securityService = inject(SecurityService);
    return securityService.isAdmin();
};

export const defaultSettingsRoute = async () => {
  const securityService = inject(SecurityService);
  const router = inject(Router);
  if (await securityService.isAdmin()) {
    router.navigate([`/${RootRoute.SETTINGS}`, 'manage-users']);
  } else {
    router.navigate([`/${RootRoute.SETTINGS}`, 'my-account']);
  }
  return false;
};
