import {Injectable} from '@angular/core';
import {MapLayersManager} from '../map-layers-manager';
import {RoutesService} from '../../../../data/routes/routes.service';
import {LineLayerSpecification, LngLatBounds, LngLatLike} from 'maplibre-gl';
import {Subscription} from 'rxjs';
import {
    RouteConfigurationWithSchema,
    RouteHierarchyItem,
    RouteHierarchyItemWithPath,
    RouteStyle
} from '../../../models/route';
import {MapFilters} from '../../../../configuration/map-filters';
import {RoadStatusCoverageLayerService} from './road-status-coverage-layer.service';
import {RoutesManagerService} from '../../../../data/routes/routes-manager.service';
import {MapControlService} from './map-control.service';
import {LineString} from 'geojson';
import {LiveMapDataService} from '../../../../pages/live-map/services/live-map-data.service';
import {RouteSourceService} from './route-source.service';
import {ISettingKeyValuePair, SettingsService, StatusLayerType} from '../../../../configuration/settings.service';
import {MapStyles} from '../../../../configuration/map-styles';
import {RouteAssignmentManagerService} from '../../../../data/routes/route-assignment-manager.service';
import moment from 'moment';
import {RouteAssignment} from '../../../models/route-assignment';

@Injectable({
    providedIn: 'root'
})
export class RoutesLayerService {

    static readonly LAYER_ID_ROUTES_PREFIX = 'routes-layer';

    // age in hours (how many hours ago) to consider route serviced
    routeStatusThreshold = 4;

    private mapLayersManager: MapLayersManager;
    private isEnabled: boolean;
    private isVisible = true;
    private lineLayers: LineLayerSpecification[] = [];
    private routes: RouteHierarchyItemWithPath[];
    private routeIdFilter: number[];
    private layersInitialized = false;
    private routeConfigId: number;
    private routeConfigurations: RouteConfigurationWithSchema[];
    private settingsVisibility = false;
    private componentVisibility = false;
    private recentlyCompletedAssignmentKeys: string[] = [];
    private inProgressAssignmentKeys: string[] = [];
    private recentAssignments: RouteAssignment[] = [];
    private highlightedLayerId: string;

    private readonly openSubscriptions = Array<Subscription>();

    static getRouteLayerId(route: RouteHierarchyItem) {
        return `${RoutesLayerService.LAYER_ID_ROUTES_PREFIX}_${route.configId}_${route.routeId}`;
    }

    constructor(private routesService: RoutesService,
                private routesManager: RoutesManagerService,
                private routeSourceService: RouteSourceService,
                private liveMapDataService: LiveMapDataService,
                private mapControlService: MapControlService,
                private settingsService: SettingsService,
                private routeAssignmentManager: RouteAssignmentManagerService,
    ) { }

    init(mapLayersManager: MapLayersManager, isEnabled: boolean) {
        if (!!this.mapLayersManager) {
            throw Error('The map layers manager has already been set.');
        }
        this.isEnabled = isEnabled;
        this.mapLayersManager = mapLayersManager;
        this.routeConfigurations = isEnabled ? this.routesManager.getRouteConfigurations() : [];
        this.routes = isEnabled ? this.routesManager.getRouteHierarchyLeaves() : null;
        this.reload();
        if (this.isEnabled) {
            this.connectToManager();
        }
    }

    release() {
        if (!this.mapLayersManager) {
            throw Error('The map has not been set!');
        }

        for (const subscription of this.openSubscriptions) {
            subscription.unsubscribe();
        }
        this.openSubscriptions.length = 0;
        this.mapLayersManager = null;
        this.layersInitialized = false;
        this.lineLayers = [];
        this.routeConfigId = undefined;
        this.routeIdFilter = undefined;
        this.routes = undefined;
        this.routeConfigurations = undefined;
    }

    // on init or on map style change
    reload() {
        if (this.isEnabled) {
            this.loadLayers();
        }
    }

    getRoutes() {
        if (this.layersInitialized) {
            return this.routes;
        } else {
            return null;
        }
    }

    highlightRoute(routeLayerId: string) {
        this.highlightedLayerId = routeLayerId;
        if (this.layersInitialized) {
            this.updateStyle();
        }
    }

    private loadLayers() {
        if (!!this.routeConfigurations) {
            this.lineLayers = [];
            if (this.routes && this.routes.length > 0) {
                // initialize layers
                const routeFilter = this.routesManager.getRouteFilterSnapshot();
                this.routeConfigId = routeFilter.configId;
                this.routeIdFilter = routeFilter.routeIds;

                this.isVisible = this.routesManager.getVisibilitySnapshot();
                const isVisible = this.isVisible ? 'visible' : 'none';
                this.getLineLayers().forEach(layer => {
                    layer.layout.visibility = isVisible;
                    this.lineLayers.push(layer);
                    this.mapLayersManager.addLayer(layer);
                    this.mapLayersManager.moveLayer(layer.id, `${RoadStatusCoverageLayerService.LAYER_ID_PREFIX}-5`);
                });

                this.layersInitialized = true;
            }
        }
    }

    private handleLayerVisibilityChange() {
        const makeVisible = this.componentVisibility || this.settingsVisibility;
        if (this.layersInitialized) {
            this.lineLayers.forEach(lineLayer => {
                this.mapLayersManager.setLayerVisibility(lineLayer.id, makeVisible);
            });
        }
    }

    private handleFilterChange() {
        // get routeId from layer ID
        if (this.layersInitialized) {
            this.lineLayers.forEach(lineLayer => {
                const splitLayerId = lineLayer.id.split('_');
                this.mapLayersManager.setFilter(
                    lineLayer.id,
                    MapFilters.getRoutesFilter(
                        +splitLayerId[1],
                        +splitLayerId[2],
                        this.routeConfigId,
                        this.routeIdFilter,
                    )
                );
            });

            // zoom to geometries
            const routeGeometries = this.routeSourceService.getFeatureCollection();
            if (!!this.routeConfigId && !!routeGeometries) {
                const coordinates = routeGeometries.features
                    .filter(feature => this.routeConfigId === feature.properties['configid'])
                    .filter(feature => this.routeIdFilter.indexOf(feature.properties['routeid']) !== -1)
                    .map(feature => {
                        const lineString = feature.geometry as LineString;
                        return lineString.coordinates;
                    })
                    .reduce((accumulator, value) => accumulator.concat(value), []); // flatten array

                if (coordinates.length > 0) {
                    const bounds = new LngLatBounds(
                        [coordinates[0][0], coordinates[0][1]],
                        [coordinates[0][0], coordinates[0][1]],
                    );

                    for (const coord of coordinates) {
                        bounds.extend(coord as LngLatLike);
                    }
                    this.mapControlService.fitMapToBounds(bounds);
                }
            }
        }
    }

    private handleModeChanged() {
        if (this.layersInitialized) {
            this.updateStyle();
        }
    }

    private getLineLayers(): LineLayerSpecification[] {
        return this.routes.map(route => {
            let globalRouteStyle = this.routeConfigurations.find(config => config.id === route.configId)?.schema?.style;
            if (!globalRouteStyle) {
                console.warn('Missing schema style. This should not happen!');
                globalRouteStyle = new RouteStyle('#FF0000', 4, 1.0);
            }
            const layerId = RoutesLayerService.getRouteLayerId(route);
            return {
                id: layerId,
                type: 'line',
                source: RouteSourceService.ROUTES_SOURCE_ID,
                filter: MapFilters.getRoutesFilter(route.configId, route.routeId, this.routeConfigId, this.routeIdFilter),
                layout: {
                    'line-join': 'miter',
                    'line-cap': 'butt',
                    'line-miter-limit': 2,
                },
                paint: {
                    'line-color': this.getColor(route, globalRouteStyle.color, layerId),
                    'line-width': [
                        'interpolate',
                        ['exponential', 2],
                        ['zoom'],
                        10, ['*', globalRouteStyle.width, ['^', 2, -1]],
                        24, ['*', globalRouteStyle.width, ['^', 2, 8]]
                    ],
                    'line-opacity': globalRouteStyle.opacity,
                    'line-dasharray': !!route.lineType
                        ? ['literal', route.lineType]
                        : ['literal', !!globalRouteStyle.lineType ? globalRouteStyle.lineType : [10]],
                }
            } as LineLayerSpecification;
        });
    }

    private updateStyle() {
        this.lineLayers.forEach(lineLayer => {
            const splitLayerId = lineLayer.id.split('_');
            let globalRouteStyle = this.routeConfigurations.find(config => config.id === +splitLayerId[1])?.schema?.style;
            if (!globalRouteStyle) {
                console.warn('Missing schema style. This should not happen!');
                globalRouteStyle = new RouteStyle('#FF0000', 4, 1.0);
            }
            const routeHierarchyItem = this.routes.find(route => route.configId === +splitLayerId[1] && route.routeId === +splitLayerId[2]);
            this.mapLayersManager.setLayerPaintProperty(
                lineLayer.id,
                'line-color',
                this.getColor(
                    routeHierarchyItem,
                    globalRouteStyle.color,
                    lineLayer.id,
                ),
            );
            this.mapLayersManager.setLayerPaintProperty(
                lineLayer.id,
                'line-dasharray',
                !!routeHierarchyItem.lineType
                ? ['literal', routeHierarchyItem.lineType]
                : (!!globalRouteStyle.lineType ? ['literal', globalRouteStyle.lineType] : undefined),
            );
        });
    }

    private filterRecentAssignments() {
        this.recentlyCompletedAssignmentKeys = this.recentAssignments
            .filter(assignment => {
                return !!assignment.completed && moment(assignment.completed).isAfter(moment().subtract(this.routeStatusThreshold, 'hour'));
            })
            .map(assignment => `${assignment.configId}_${assignment.routeId}`);
        this.inProgressAssignmentKeys = this.recentAssignments
            .filter(assignment => !assignment.completed && !!assignment.onAssignmentFrom)
            .map(assignment => `${assignment.configId}_${assignment.routeId}`);
    }

    connectToManager() {
        const routesFilterSubscription = this.routesManager.routeFilter$.subscribe(filterUpdate => {
            this.routeConfigId = filterUpdate.configId;
            this.routeIdFilter = filterUpdate.routeIds;
            this.handleFilterChange();
        });
        this.openSubscriptions.push(routesFilterSubscription);

        const routesVisibilitySubscription = this.routesManager.basedOnRouteVisibility$.subscribe(visible => {
            const componentVisibilityChanged = this.componentVisibility !== visible;
            this.componentVisibility = visible;
            this.handleLayerVisibilityChange();
            if (componentVisibilityChanged) {
                this.handleModeChanged();
            }
        });
        this.openSubscriptions.push(routesVisibilitySubscription);

        const settingsChangedSubscription = this.settingsService.settingsChangedObservable.subscribe(
            (newSettings: ISettingKeyValuePair) => {
                if (newSettings.key === SettingsService.TRACKS_LAYER_TYPE_KEY) {
                    this.settingsVisibility = newSettings.value === StatusLayerType.ROUTE_STATUS;
                    this.handleLayerVisibilityChange();
                }
                if (newSettings.key === SettingsService.ROUTE_STATUS_THRESHOLD) {
                    this.routeStatusThreshold = +newSettings.value;
                    if (this.layersInitialized) {
                        this.filterRecentAssignments();
                        this.updateStyle();
                    }
                }
            }
        );
        this.openSubscriptions.push(settingsChangedSubscription);

        const assignmentsSubscription = this.routeAssignmentManager.recentRouteAssignments$.subscribe(assignmentsUpdate => {
            this.recentAssignments = assignmentsUpdate.state;
            if (this.layersInitialized) {
                this.filterRecentAssignments();
                this.updateStyle();
            }
        });
        this.openSubscriptions.push(assignmentsSubscription);
    }

    private getColor(route: RouteHierarchyItem, globalColor: string, layerId: string): string {
        if (this.componentVisibility) {
            return !!route.color ? route.color : globalColor;
        } else if (!!this.highlightedLayerId && this.highlightedLayerId === layerId) {
            return MapStyles.PAUSED_COLOR;
        } else {
            const routeKey = `${route.configId}_${route.routeId}`;
            if (this.inProgressAssignmentKeys.includes(routeKey)) {
                return MapStyles.ROUTE_STATUS_INPROGRESS;
            } else if (this.recentlyCompletedAssignmentKeys.includes(routeKey)) {
                return MapStyles.ROUTE_STATUS_SERVICED;
            } else {
                return MapStyles.ROUTE_STATUS_NOTSERVICED;
            }
        }
    }
}
