import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ContentBlock} from '../../../../../shared/models/configuration.model';
import {Component, Inject} from '@angular/core';

abstract class ContentBlockDialogBase {

  public abstract dialogTitle: string;
  public abstract dialogActionTitle: string;

  protected constructor(
    public readonly dialogRef: MatDialogRef<DialogCreateContentBlockComponent>,
    public data: ContentBlock,
  ) {
  }

  handleActionButtonClick() {
    this.dialogRef.close(this.data);
  }

}

@Component({
  selector: 'app-dialog-create-content-block',
  templateUrl: './dialog-edit-content-block.html',
  styleUrls: ['../../../../settings/settings-fields.scss'],
})
export class DialogCreateContentBlockComponent extends ContentBlockDialogBase {

  dialogTitle = 'Add Content Block';
  dialogActionTitle = 'Add Content Block';

  constructor(
    dialogRef: MatDialogRef<DialogCreateContentBlockComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ContentBlock) {
    super(dialogRef, data);
  }


}

@Component({
  selector: 'app-dialog-update-content-block',
  templateUrl: './dialog-edit-content-block.html',
  styleUrls: ['../../../../settings/settings-fields.scss'],
})
export class DialogUpdateContentBlockComponent extends ContentBlockDialogBase {

  dialogTitle = 'Update Content Block';
  dialogActionTitle = 'Update';

  constructor(
    dialogRef: MatDialogRef<DialogUpdateContentBlockComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ContentBlock) {
    super(dialogRef, data);
  }
}
