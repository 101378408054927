<div class="nav-container">
  <!--<button mat-icon-button (click)="toggleLeftPanel()">
    <mat-icon>menu</mat-icon>
  </button>-->
  <div class="toggle-button">
    <app-map-panel-toggle-button></app-map-panel-toggle-button>
  </div>

  <div class="logo-wrapper">
    <img class="logo__icon"
         [ngClass]="'logo__' + envName"
         src="assets/PlowOpsLogo_Snowflake.svg"
         [alt]="dashboardVersion"
         [matTooltip]="'PlowOps: ' + dashboardVersion"
    />
    <img class="logo__text"
         src="assets/plowops_logo_text_only.png"
         [alt]="dashboardVersion"
    />
  </div>

  <div class="nav-items">
    <app-search-bar
            *ngIf="!!configuration"
            [configuration]="configuration"
            [isCompact]="isCompact"
            (searchResulted)="onSearchResult($event)"
    ></app-search-bar>

    <div class="weather-and-action-buttons">
      <app-weather-news
              class="weather-news"
              [configuration]="configuration"
              [isCompact]="isCompact"
              *ngIf="configuration && hasFeatureFlag(FeatureFlagEnum.Weather)"
      >
      </app-weather-news>

      <button mat-icon-button (click)="todoHelp()" matTooltip="Support" matTooltipPosition="below">
        <mat-icon>help</mat-icon>
      </button>

      <button mat-icon-button [routerLink]="['/settings']" matTooltip="Settings" matTooltipPosition="below">
        <mat-icon>settings</mat-icon>
      </button>
    </div>
  </div>

  <div class="account-block" *ngIf="!!configuration">
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="logout()">
        <mat-icon> login</mat-icon><span>Logout</span>
      </button>
    </mat-menu>
    <div [@opacityIn] class="account">
      <div class="account__tenant">
        {{ configuration.customerName }}
      </div>
      <div class="account__username mat-small" [matMenuTriggerFor]="menu">
        <div [matTooltip]="userName + ' <' + email + '>'" class="account__username__email">{{ email }}</div>
        <mat-icon class="account__username__icon">
          expand_more
        </mat-icon>
      </div>
    </div>
  </div>
</div>
