<div class="style-table">
    <div class="style-row style-header">
        <div class="category">
            Category
        </div>
        <div class="color">
            Color
        </div>
        <div>
            <!--Line Style-->
        </div>
    </div>
    <ng-container *ngFor="let lineStyle of layerType.measurementUnits; let i = index">
        <div class="style-row" (click)="editLineStyle(i, lineStyle)">
            <div class="category">
                {{ lineStyle.number}}
            </div>
            <div class="color">
                <!--<app-line-dash-array-view
                        [dashArray]="lineStyle.dashArray"
                        [color]="lineStyle.color"
                >
                </app-line-dash-array-view>-->
                <!--<mat-form-field appearance="outline" color="accent" class="no-hint form"
                                (colorPickerChange)="onLineStyleChange()"
                                [(colorPicker)]="lineStyle.color"
                                [cpAlphaChannel]="'disabled'">
                    <mat-label>Route Color</mat-label>
                    <input matInput
                           [value]="lineStyle.color"
                           [style.color]="lineStyle.color"
                    >
                </mat-form-field>-->
                <div class="color-selector-wrapper">
                    <div class="color-selector"
                         [ngStyle]="{background: lineStyle.color}"
                         [(colorPicker)]="lineStyle.color"
                         (colorPickerChange)="onLineStyleChange()"
                    >
                        <!--<span>{{ lineStyle.color}}</span>-->
                    </div>
                </div>
            </div>
            <app-line-type-selector
                    [(dashArray)]="lineStyle.dashArray"
                    (lineTypeChanged)="onLineStyleChange()"
            >
            </app-line-type-selector>
        </div>
    </ng-container>
</div>
