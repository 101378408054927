import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {RouteAssignmentFilterUpdate, RouteFilterUpdate} from '../../shared/models/route-filter';
import {RouteConfigurationWithSchema, RouteHierarchyItem, RouteHierarchyItemWithPath} from '../../shared/models/route';

@Injectable({
  providedIn: 'root'
})
export class RoutesManagerService {

  private isInitialized = false;
  private routeConfigurations: RouteConfigurationWithSchema[];
  private routeConfigurationLeaves: RouteHierarchyItemWithPath[] = [];

  private visibilitySource = new BehaviorSubject<boolean>(false);
  basedOnRouteVisibility$ = this.visibilitySource.asObservable();

  private routeFilterSource = new BehaviorSubject<RouteFilterUpdate>(new RouteFilterUpdate(null, []));
  routeFilter$ = this.routeFilterSource.asObservable();

  private assignmentFilterSource = new BehaviorSubject<RouteAssignmentFilterUpdate>(new RouteAssignmentFilterUpdate(null, []));
  assignmentFilter$ = this.assignmentFilterSource.asObservable();

  constructor() { }

  public init(routeConfigurations: RouteConfigurationWithSchema[]) {
    if (this.isInitialized) {
      throw Error('The Route Manager Service has already been initialized.');
    }

    this.routeConfigurations = routeConfigurations;
    this.routeConfigurations.forEach(configuration => {
      this.routeConfigurationLeaves.push(
          ...RouteHierarchyItem.getLeavesWithExplicitColors(
              configuration.schema.classification.length,
              configuration.configuration.children
          ).map(route => {
            route.configId = configuration.id;
            return route;
          })
      );
    });
    this.isInitialized = true;
  }

  public release() {
    this.routeConfigurations = null;
    this.routeConfigurationLeaves = [];
    this.isInitialized = false;
  }

  public getRouteConfigurations(): RouteConfigurationWithSchema[] {
    if (!this.isInitialized) {
      console.error('Route Manager not initialized! This should not happen.');
    }
    return this.routeConfigurations;
  }

  public getRouteHierarchyLeaves(): RouteHierarchyItemWithPath[] {
    if (!this.isInitialized) {
      console.error('Route Manager not initialized! This should not happen.');
    }
    return this.routeConfigurationLeaves;
  }

  updateVisibility(visible: boolean) {
    this.visibilitySource.next(visible);
  }

  getVisibilitySnapshot(): boolean {
    return this.visibilitySource.value;
  }

  updateRouteFilterNg(configId: number, routeIds: number[]) {
    this.routeFilterSource.next(
        new RouteFilterUpdate(configId, routeIds)
    );
  }

  updateRouteFilter(configId: number, path: string[]) {
    this.routeFilterSource.next(
        new RouteFilterUpdate(configId, this.pathToRouteIdFilter(configId, path))
    );
  }

  getRouteFilterSnapshot(): RouteFilterUpdate {
    return this.routeFilterSource.value;
  }

  updateAssignmentFilter(configId: number, routeIds: number[]) {
    this.assignmentFilterSource.next(new RouteAssignmentFilterUpdate(configId, routeIds));
  }

  // convert path in route hierarchy tree to list of route IDs
  private pathToRouteIdFilter(routeConfigId: number, routeHierarchyPath: string[]): number[] {
    let routeIdFilter: number[];
    if (this.routeConfigurations && this.routeConfigurations.length > 0) {
      // route config not set
      if (!routeConfigId) {
        // no filter, show all
        routeIdFilter = [-1];
        return routeIdFilter;
      }
      // invalid route config
      const configuration = this.routeConfigurations.find(config => config.id === routeConfigId);
      if (!configuration) {
        console.warn('Missing route config. This should not happen!');
        // filter no routes
        routeIdFilter = [-2];
        return routeIdFilter;
      }
      const fullPathLength = configuration.schema.classification.length;
      const routeHierarchy = configuration.configuration;
      if (!routeHierarchyPath) {
        console.warn('Route Hierarchy Path not set even if Route Config ID set. This should not happen!');
        // filter no routes
        routeIdFilter = [-2];
        return routeIdFilter;
      }

      const itemByPath = routeHierarchyPath.length > 0 ?
          RouteHierarchyItem.getByPath(routeHierarchyPath, routeHierarchy.children) : routeHierarchy as RouteHierarchyItem;
      const leavePathLength = fullPathLength - routeHierarchyPath.length;
      if (leavePathLength > 0) {
        const leaves = RouteHierarchyItem.getLeaves(leavePathLength, itemByPath.children);
        // all tree leaves
        routeIdFilter = leaves.map(leave => leave.routeId);
      } else if (itemByPath['routeId']) {
        // item itself
        routeIdFilter = [itemByPath.routeId];
      } else {
        console.warn('Item in Route Hierarchy not found properly. This should not happen!');
        // filter no routes
        routeIdFilter = [-2];
      }
      return routeIdFilter;
    }
    return [-2];
  }
}
