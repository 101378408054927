import {Component, Input, OnInit} from '@angular/core';
import {RouteHierarchyItem} from '../../../../../shared/models/route';
import {ActionMenuItem} from '../../../../../shared/models/action-menu-item.class';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {MainRoute, RootRoute} from '../../../../../shared/models/angular-routing';


export class ActionGroup {
  id: string;
  name: string;
  collapsed: boolean;
  visible: boolean;
  items: RouteHierarchyItem[];
}

@Component({
  selector: 'app-route-group-menu',
  templateUrl: './route-group-menu.component.html',
  styleUrl: './route-group-menu.component.scss'
})
export class RouteGroupMenuComponent implements OnInit {

  @Input() path: string[];
  @Input() routeHierarchyItem: RouteHierarchyItem;
  group: ActionGroup;
  item: ActionMenuItem;

  selectedPath: string[];
  selectedRouteId: number;

  constructor(
      private router: Router,
      private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.setSelectedRoute();

    if (!!this.routeHierarchyItem.children) {
      // initialize group
      this.group = {
        id: this.routeHierarchyItem.value,
        name: this.routeHierarchyItem.routeId === -1 ? this.routeHierarchyItem.routeName : this.routeHierarchyItem.value,
        collapsed: !this.selectedPath || !this.isOnPath(),
        visible: true,
        items: this.routeHierarchyItem.children,
      } as ActionGroup;
    } else {
      // initialize item
      this.item = new ActionMenuItem(
          this.routeHierarchyItem.routeId,
          'route',
          this.routeHierarchyItem.routeId === -1 ? this.routeHierarchyItem.routeName : this.routeHierarchyItem.value,
          '',
          '',
          null,
          () => this.routeHierarchyItem.configId === +this.selectedPath?.[0] && this.routeHierarchyItem.routeId === this.selectedRouteId,
          null,
          null,
          () => {
            this.router.navigate(
                [
                    `/${RootRoute.MAIN}`,
                    MainRoute.ROUTE,
                    this.getPath().join(':::'),
                    'route-id',
                    this.routeHierarchyItem.routeId,
                ],
                {
                  queryParamsHandling: 'merge',
                  queryParams: {
                    routeId: undefined,
                    routePath: undefined,
                  },
                }
            );
          },
          null,
          null,
          [],
      );
    }

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.setSelectedRoute();
        if (!!this.group && !!this.selectedRouteId) {
          // if changing route then do not collapse anything, just automatically expand the route path
          const expand = !!this.selectedPath && this.isOnPath();
          if (expand) {
            this.group.collapsed = false;
          }
        }
      }
    });
  }

  private setSelectedRoute() {
    this.selectedPath = this.activatedRoute.parent?.firstChild?.firstChild?.snapshot?.params['path']?.split(':::');
    this.selectedRouteId = +this.activatedRoute.parent?.firstChild?.firstChild?.snapshot?.params['routeId'];
  }

  toggleGroupExpansion() {
    this.group.collapsed = !this.group.collapsed;
  }

  getPath(): string[] {
    return [...this.path, this.routeHierarchyItem.value];
  }

  isOnPath() {
    const path = this.getPath();
    if (!!this.selectedPath && !!path) {
      if (path.length > this.selectedPath.length) {
        return false;
      }
      for (let i = 0; i < path.length; i++) {
        if (path[i] !== this.selectedPath[i]) {
          return false;
        }
      }
      return true;
    }
    return false;
  }
}
