import {
  AfterViewInit,
  Component,
  ViewChild,
  Input,
  OnInit, OnDestroy,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {ShiftReportRecordModel, ShiftReportTotalModel} from '../../../../shared/models/shift.model';
import {ShiftReportTableComponent} from '../shift-report-table/shift-report-table.component';
import {ReportsService} from '../../../../data/reports/reports.service';
import moment, {Moment} from 'moment';
import {saveAs} from 'file-saver-es';
import {ConfigurationModel} from '../../../../shared/models/configuration.model';
import {DateFilter} from '../../../../shared/models/DateFilter';
import {ToastService} from '../../../../shared/services/toast.service';
import {MatDialog} from '@angular/material/dialog';
import {
  ShiftReportTableSettingsDialogComponent
} from '../shift-report-table/settings/shift-report-table-settings-dialog/shift-report-table-settings-dialog.component';
import {
  ShiftReportTableSettingsDialogData
} from '../shift-report-table/settings/shift-report-table-settings-dialog/ShiftReportTableSettingsDialogData';
import {ShiftReportColumn} from './ShiftReportColumn';
import {Subscription} from 'rxjs';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {MultiSelectFilter} from '../../../../shared/components/multi-select-component';
import {ActivatedRoute, Router} from '@angular/router';
import {CloseableAndFilterableInsight} from '../../closeable-filterable-insight.class';


@Component({
  selector: 'app-shift-report',
  templateUrl: './shift-report.component.html',
  styleUrls: ['./shift-report.component.scss'],
})
export class ShiftReportComponent extends CloseableAndFilterableInsight implements AfterViewInit, OnInit, OnDestroy {

  configuration: ConfigurationModel;
  @Input() isBrowserMobile?: boolean;

  pageSize = 50;
  pageIndex = 0;
  totalElements = 0;

  @ViewChild(ShiftReportTableComponent) reportTableComponent: ShiftReportTableComponent;

  dataSource: MatTableDataSource<ShiftReportRecordModel> = new MatTableDataSource([]);
  reportTotal: ShiftReportTotalModel;
  isLoading = false;
  isScrollLoading = false;

  dateFilter: DateFilter = undefined;
  vehicleGroupFilter: MultiSelectFilter<number> = undefined;

  columns: ShiftReportColumn[];
  private configurationSubscription: Subscription;

  constructor(private reportService: ReportsService,
              private configurationService: ConfigurationService,
              private dialog: MatDialog,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              private toast: ToastService) {
    super(router, activatedRoute);
  }

  ngOnInit() {
    this.configurationSubscription = this.configurationService.sharedConfigurationModel.subscribe(model => {
      if (model) {
        this.configuration = model;
      }
    });

    let displayedColumnsSetting = localStorage.getItem(ShiftReportColumn.LOCAL_STORAGE_DISPLAYED_COLUMNS);

    // store and use default display columns setting if needed
    if (!displayedColumnsSetting) {
      displayedColumnsSetting = ShiftReportColumn.displayedByDefault.join(',');
      localStorage.setItem(ShiftReportColumn.LOCAL_STORAGE_DISPLAYED_COLUMNS, displayedColumnsSetting);
    }

    this.columns = ShiftReportColumn.columns(displayedColumnsSetting.split(','));
  }

  ngAfterViewInit() {
    // not needed, filters will do the job
    // this.loadReport();
  }

  ngOnDestroy() {
    this.configurationSubscription?.unsubscribe();
  }

  toggleLoading() {
    setTimeout(() => this.isLoading = !this.isLoading);
  }

  showSettingsDialog() {
    const dialogRef = this.dialog.open(ShiftReportTableSettingsDialogComponent, {
      data: new ShiftReportTableSettingsDialogData(
        this.columns.map(value => Object.assign({}, value))
      ),
      width: '300px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result != null) {
        const data = result as ShiftReportTableSettingsDialogData;
        localStorage.setItem(
          ShiftReportColumn.LOCAL_STORAGE_DISPLAYED_COLUMNS,
          data.columns.filter(value => value.displayed).map(value => value.id).join(',')
        );
        this.columns = data.columns;
      }
    });
  }

  // null or non-empty array
  private filtersInitialized() {
    return this.vehicleGroupFilter !== undefined && this.dateFilter !== undefined;
  }

  dateFilterChanged(dateFilter: DateFilter) {
    this.dateFilter = dateFilter;
    this.loadReport();
  }

  vehicleGroupFilterChanged(vehicleGroupFilter: MultiSelectFilter<number>) {
    this.vehicleGroupFilter = vehicleGroupFilter;
    this.loadReport();
  }

  loadReport() {
    if (this.filtersInitialized && !!this.reportTableComponent) {
      this.toggleLoading();
      this.pageIndex = 0;
      this.isScrollLoading = false;
      this.reportService.getShiftReport(
          !!this.dateFilter.from ? this.dateFilter.from : null,
          !!this.dateFilter.to ? this.dateFilter.to : null,
          this.vehicleGroupFilter,
          this.pageIndex,
          this.pageSize,
          this.reportTableComponent.getSort(),
      ).toPromise().then(response => {
        // console.log(`initialized, load count ${response.data.records.content.length} of ${response.data.records.totalElements}`);
        this.dataSource.data = response.data.records.content;
        this.reportTotal = response.data.total;
        this.totalElements = response.data.records.totalElements;
        this.pageSize = response.data.records.pageable.pageSize;
        this.toggleLoading();
      }).catch(error => {
        this.toggleLoading();
        this.toast.longer('Error while fetching initial data from the server');
        console.error(error);
      });
    }
  }

  extendReport() {
    if (!this.isScrollLoading && this.dataSource.data.length < this.totalElements) {
      this.pageIndex++;
      this.isScrollLoading = true;
      this.reportService.getShiftReport(
        !!this.dateFilter.from ? this.dateFilter.from : null,
        !!this.dateFilter.to ? this.dateFilter.to : null,
          this.vehicleGroupFilter,
        this.pageIndex,
        this.pageSize,
        this.reportTableComponent.getSort(),
      ).toPromise().then(response => {
        const extendedData = [...this.dataSource.data];
        extendedData.push(...response.data.records.content);
        // console.log(`extended, load count ${extendedData.length} of ${this.totalElements}`);
        this.dataSource.data = extendedData;
        this.isScrollLoading = false;
      }).catch(error => {
        this.toast.longer('Error while fetching more data from the server');
        console.error(error);
        this.isScrollLoading = false;
      });
    }
  }

  loadCSVExportFile() {
    if (this.filtersInitialized) {
      this.toggleLoading();
      this.reportService.getShiftReportCSV(
          !!this.dateFilter.from ? this.dateFilter.from : null,
          !!this.dateFilter.to ? this.dateFilter.to : null,
          this.vehicleGroupFilter,
      ).toPromise().then(response => {
        const blob = new Blob([response], { type: 'csv' });
        saveAs(blob, `${this._fileNameWithDateRange(
            !!this.dateFilter.from ? this.dateFilter.from : null,
            !!this.dateFilter.to ? this.dateFilter.to : null
        )}.csv`);

        this.toggleLoading();
      }).catch(error => {
        this.toggleLoading();
        this.toast.longer('Error while fetching data from the server');
        console.error(error);
      });
    }
  }

  loadPDFExportFile() {
    if (this.filtersInitialized) {
      this.toggleLoading();
      this.reportService.getShiftReportPDF(
          !!this.dateFilter.from ? this.dateFilter.from : null,
          !!this.dateFilter.to ? this.dateFilter.to : null,
          this.vehicleGroupFilter,
          this.columns.filter(value => !value.displayed).map(value => value.id),
      ).toPromise().then(response => {
        const blob = new Blob([response], {type: 'pdf'});
        saveAs(blob, `${this._fileNameWithDateRange(
            !!this.dateFilter.from ? this.dateFilter.from : null,
            !!this.dateFilter.to ? this.dateFilter.to : null
        )}.pdf`);

        this.toggleLoading();
      }).catch(error => {
        this.toggleLoading();
        this.toast.longer('Error while fetching data from the server');
        console.error(error);
      });
    }
  }

  private _fileNameWithDateRange(from: Moment, to: Moment): string {
    const fromStr = !!from ? `_${from.format('MM-DD-YYYY')}` : '';
    const toStr = !!to ? to.format('MM-DD-YYYY') : `${moment().format('MM-DD-YYYY')}`;
    return `shift_report${fromStr}_${toStr}`;
  }
}
