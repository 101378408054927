import { MapContentLayer } from '../MapContentLayer';
import { LayerSpecification, SymbolLayerSpecification } from 'maplibre-gl';
import { MapContentSource } from '../MapContentSource';
import { MapStyles } from '../../../../../configuration/map-styles';

export class ObservationLabelLayer extends MapContentLayer {

  constructor(
    source: MapContentSource
  ) {
    super('observation-label-layer', source);
  }

  toLayerSpecification(): LayerSpecification {
    return {
      id: this.layerId,
      type: 'symbol',
      source: this.sourceRef.sourceId,
      layout: {
        'text-field': ['get', 'title'],
        'text-font': ['Roboto'],
        'text-size': 12,
        'text-offset': [1.5, 0.0],
        'text-anchor': 'left',
      },
      paint: {
        'text-color': [
          'case',
          ['boolean', ['get', 'highlighted']],
          MapStyles.HIGHLIGHTED_COLOR,
          ['all', ['has', 'color'], ['!=', ['get', 'color'], null]],
          ['get', 'color'],
          MapStyles.LIVE_COLOR,
        ],
        'text-halo-color': '#ffffff',
        'text-halo-width': 2,
      },
    } as SymbolLayerSpecification;
  }

}
