<div class="filter-selector-wrapper">

  <!-- main layers button -->
  <div
    (click)="toggleVisibility()"
    class="layers__button noselect mat-elevation-z2"
    [ngStyle]="baseMapTypeBackground(BaseMapType.WINTER)"
  >
    <mat-icon>layers</mat-icon>
    <span>Layers</span>
  </div>


  <!-- layers list -->
  <div class="layers__box mat-elevation-z2" *ngIf="switcherOpen">

    <div class="layers__buttons">

      <ng-container *ngFor="let baseMap of baseMaps">
        <div class="map__filter__wrapper">
          <div
            [ngClass]="{ selected: baseMapLayerType === baseMap }"
            class="map__filter__square noselect"
            [ngStyle]="baseMapTypeBackground(baseMap)"
            (click)="switchBaseMap(baseMap)"
          ></div>
          <div class="map__filter__text">
            {{ baseMap }}
          </div>
        </div>
      </ng-container>

    </div>

  </div>
</div>
