<div class="drawer-layout" *ngIf="!!configuration && hasFeatureFlag(FeatureFlagEnum.DashCam)">
    <ng-container *ngFor="let item of items">
        <div class="drawer-selected-wrapper"
             [ngClass]="{ selected: item.id === selectedVehicle?.id }"
        >
            <div class="drawer-content">
                <app-action-menu-item [input]="item">
                </app-action-menu-item>
            </div>

            <ng-container *ngIf="item.id === selectedVehicle?.id">

                <div class="drawer-content" *ngIf="selectedVehicle.shiftStatus === ShiftState.ENDED">
                    <span>Vehicle is offline</span>
                </div>

                <ng-container *ngIf="selectedVehicle.shiftStatus !== ShiftState.ENDED">

                    <div class="drawer-content" *ngIf="selectedVehicle.cameraConfiguration?.streaming?.captureStreamDuration > 0">
                        <div class="drawer-title">Livestream</div>


                        <button mat-button mat-stroked-button color="accent"
                                (click)="startStreaming(videoPlayer)"
                                class="stream-button"
                                *ngIf="!streaming"
                        >
                            <mat-icon *ngIf="!streaming">play_arrow</mat-icon>
                            <!--<mat-icon *ngIf="streaming">sync</mat-icon>-->
                            <span *ngIf="firstTimeStreaming">Start Livestream</span>
                            <span *ngIf="!firstTimeStreaming">Continue Livestream</span>
                        </button>
                        <app-video-player [vehicleId]="selectedVehicle.id"
                                          [timeFrom]="selectedVehicle.shiftStartTime"
                                          #videoPlayer
                        ></app-video-player>
                    </div>

                    <div class="drawer-content" *ngIf="selectedVehicle.cameraConfiguration?.streaming?.captureImageInterval > 0">
                        <div class="drawer-title">Captured Images</div>

                        <mat-spinner *ngIf="!images"></mat-spinner>

                        <ng-container *ngIf="!!images">

                            <div *ngIf="!!images && images.length === 0">
                                No still images
                            </div>

                            <app-image-viewer
                                    *ngIf="!!images && images.length > 0"
                                    [images]="images"
                            ></app-image-viewer>
                        </ng-container>
                    </div>

                    <div class="drawer-content" *ngIf="
                        !selectedVehicle.cameraConfiguration?.streaming?.captureImageInterval &&
                        !selectedVehicle.cameraConfiguration?.streaming?.captureStreamDuration
                    ">
                        Insufficient dashcam configuration, none of the dashcam options is selected (livestreaming, capturing images on interval)
                    </div>
                </ng-container>

            </ng-container>
        </div>
    </ng-container>
</div>
