<div class="image-report-container">

    <div class="report-menu">
        <div class="report-name-and-desc">
            <div class="report-name">
                Image Retrieval
            </div>
            <div class="report-description">
                Retrieve images from past shifts
            </div>
        </div>

        <div class="report-menu__actions">
            <button
                    mat-icon-button
                    [disabled]="!imagePreview"
                    (click)="downloadImage()"
                    [matTooltip]="'Download Image'"
            >
                <mat-icon>download_for_offline</mat-icon>
            </button>

          <app-filter-button [appliedFiltersCount]="appliedFiltersCount"
                             [active]="showingFilterBar"
                             (toggled)="toggleFilter()"
          ></app-filter-button>

            <button
                    mat-icon-button
                    (click)="closeInsight()"
                    [matTooltip]="'Close'"
            >
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <app-filter-bar
      class="header__filters"
      [origin]="'imageReport'"
      [showingFilterBar]="showingFilterBar"
      [useDateFilter]="true"
      [useVehicleGroupFilter]="true"
      (dateFilterChanged)="dateFilterChanged($event)"
      (vehicleGroupFilterChanged)="vehicleGroupFilterChanged($event)"
      (appliedFiltersChanged)="appliedFiltersCount = $event"
    >
    </app-filter-bar>

    <div class="content__wrapper">
        <div class="table__wrapper">
            <app-image-report-table
                    [dataSource]="dataSource"
                    (sortChangedEvent)="loadReport()"
                    (scrolledToBottomEvent)="extendReport()"
                    (imageSelected)="onImageSelectionChanged($event)"
            >
            </app-image-report-table>
        </div>
        <div class="image__preview">
            <app-retrying-image-viewer *ngIf="!!imagePreview"
                                       [imageUrl]="imagePreview?.imageUrl"
                                       [alternativeText]="'Dashboard Camera Image'"></app-retrying-image-viewer>
        </div>
    </div>
</div>
