<div class="flex-column gap-32">
@if (isLoading) {
    <app-card preCardTitle="Group Management">
        <div *appCardContent class="settings-header-container">
            <mat-spinner mode="indeterminate" diameter="30" class="mx-a my-15"></mat-spinner>
        </div>
    </app-card>
}
@else {
    @if (isCartegraphEnabled) {
        <app-card preCardTitle="Group Management">
            <div *appCardContent class="settings-header-container">
                <div class="settings-header-title">
                    <div class="settings-header-subtitle">Update Vehicle Group settings.</div>
                </div>
            </div>
        </app-card>
    } @else {
        <app-card preCardTitle="Group Management">
            <div *appCardContent class="settings-header-container">
                <div class="settings-header-title">
                    <p>Add a new vehicle group</p>
                    <div class="settings-header-subtitle">Create new Vehicle Group and update its settings.</div>
                </div>
                <div class="settings-header-actions">
                    <button mat-stroked-button (click)="showAddNewVehicleGroupDialog()">Add Vehicle Group</button>
                </div>
            </div>
        </app-card>
    }

	<app-card preCardTitle="Vehicle Groups">
		<div *appCardContentRaw>
				<mat-nav-list class="vehicles-list">
					@for (group of vehicleGroups; track group.id) {
						<a mat-list-item
						   [routerLink]="['/settings', 'manage-vehicles', 'group', group.id]"
						   class="flex-row">
							<div class="flex-grow-1">{{ group.title }}</div>
							<mat-icon>arrow_right</mat-icon>
						</a>
						@if (!$last) {
							<mat-divider></mat-divider>
						}
					}
				</mat-nav-list>
		</div>
	</app-card>
}
</div>
