<div class="narrow-panel-container">
    <div class="narrow-panel-header">
        <div class="content-title-1">
            Address Found
        </div>
        <button
                class=""
                (click)="close()"
                mat-icon-button
        >
            <mat-icon class="action-icon">close</mat-icon>
        </button>
    </div>

    <div class="narrow-panel-section">
        <ng-container *ngIf="!!address">
            <div class="content-title-1">
                {{ address.name }}
            </div>
            <div class="content-title-2">
                {{ address.formatted_address }}
            </div>
            <div class="content-title-3">
                Most recent service times:
            </div>
            <div *ngIf="!!addressVisits && addressVisits.length > 0">
                <div *ngFor="let visit of addressVisits" class="address-visit">
                    <mat-icon class="timer-icon">timer</mat-icon>
                    <div>
                        <span>{{ visit.time | date:'shortTime' }}</span>&nbsp;
                        <span *ngIf="isOlderThanToday(visit.time)">{{ visit.time | date:'fullDate' }}</span>
                    </div>
                </div>
            </div>
            <div *ngIf="!!addressVisits && addressVisits.length === 0">
                No results in last {{ recentSearchRangeHours }} hours
            </div>
            <div *ngIf="!addressVisits">
                <mat-spinner></mat-spinner>
            </div>
            <div class="button-wrapper">
                <button mat-button
                        color="accent"
                        [routerLink]="['/' + RootRoute.MAIN, MainRoute.INSIGHTS, 'address-lookup']"
                        [queryParams]="getAddressLookupSearchParams()"
                        [queryParamsHandling]="'merge'"
                >
                    More details
                </button>
            </div>
        </ng-container>
    </div>
</div>
