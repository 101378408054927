import {MapContentSource} from './MapContentSource';
import {LayerSpecification} from 'maplibre-gl';

export abstract class MapContentLayer {

  protected constructor(
    public layerId: string,
    protected sourceRef: MapContentSource
  ) {}

  abstract toLayerSpecification(): LayerSpecification;
}
