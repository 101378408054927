import {Component, OnDestroy, OnInit} from '@angular/core';
import {LayerTypeFilter} from '../../../../configuration/model/LayerTypeFilter';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {Subscription} from 'rxjs';
import {SecurityService} from '../../../../security/security.service';
import {TrackStyles} from '../../../../configuration/model/TrackStyles';
import {MatDialog} from '@angular/material/dialog';
import {SettingsService} from '../../../../configuration/settings.service';
import {
  PassCountMapConfig,
  PassCountMapConfigComponent
} from '../pass-count-map-config/pass-count-map-config.component';

@Component({
  selector: 'app-coverage-legend',
  templateUrl: './coverage-legend.component.html',
  styleUrls: ['./coverage-legend.component.scss']
})
export class CoverageLegendComponent implements OnInit, OnDestroy {

  defaultLegendSettings: LayerTypeFilter;
  defaultTrackStyles: TrackStyles;
  defaultStylesSubscription: Subscription;

  userLegendSettings: LayerTypeFilter;
  userTrackStyles: TrackStyles;
  userStylesSubscription: Subscription;
  isAdmin: boolean;

  constructor(
      public dialog: MatDialog,
      private securityService: SecurityService,
      private configurationService: ConfigurationService,
      private settingsService: SettingsService,
  ) {}

  ngOnInit() {
    this.isAdmin = this.securityService.isAdminSync();
    this.defaultStylesSubscription = this.configurationService.trackStyles$.subscribe(trackStyles => {
      this.defaultTrackStyles = trackStyles;
      this.defaultLegendSettings = LayerTypeFilter.coverageLayerTypeFilter(trackStyles);
    });
    this.userStylesSubscription = this.configurationService.userTrackStyles$.subscribe(trackStyles => {
      this.userTrackStyles = trackStyles;
      this.userLegendSettings = LayerTypeFilter.coverageLayerTypeFilter(trackStyles);
    });
  }

  ngOnDestroy() {
    this.defaultStylesSubscription?.unsubscribe();
    this.userStylesSubscription?.unsubscribe();
  }

  openConfiguration() {
    const defaultConfigCopy = JSON.parse(JSON.stringify(this.defaultLegendSettings));
    const userConfigCopy = JSON.parse(JSON.stringify(this.userLegendSettings));
    const dialogRef = this.dialog.open(PassCountMapConfigComponent, {
      maxWidth: '80vw',
      maxHeight: '85vh',
      width: '500px',
      height: '550px',
      data: {
        reset: false,
        defaultLegendSettings: defaultConfigCopy,
        legendSettings: userConfigCopy,
      } as PassCountMapConfig,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined && result) {
        const configResult = result as PassCountMapConfig;
        if (configResult.reset) {
          this.settingsService.setStringValue(SettingsService.COVERAGE_TRACK_STYLE_1, null);
          this.settingsService.setStringValue(SettingsService.COVERAGE_TRACK_STYLE_2, null);
          this.settingsService.setStringValue(SettingsService.COVERAGE_TRACK_STYLE_3, null);
          this.settingsService.setStringValue(SettingsService.COVERAGE_TRACK_STYLE_4, null);
          this.settingsService.setStringValue(SettingsService.COVERAGE_TRACK_STYLE_5, null);
        } else {
          this.userTrackStyles.roadStatusCoverageLevel1.color = configResult.legendSettings.measurementUnits[0].color;
          this.userTrackStyles.roadStatusCoverageLevel1.dasharray = configResult.legendSettings.measurementUnits[0].dashArray;
          this.settingsService.setStringValue(
              SettingsService.COVERAGE_TRACK_STYLE_1,
              JSON.stringify(this.userTrackStyles.roadStatusCoverageLevel1)
          );

          this.userTrackStyles.roadStatusCoverageLevel2.color = configResult.legendSettings.measurementUnits[1].color;
          this.userTrackStyles.roadStatusCoverageLevel2.dasharray = configResult.legendSettings.measurementUnits[1].dashArray;
          this.settingsService.setStringValue(
              SettingsService.COVERAGE_TRACK_STYLE_2,
              JSON.stringify(this.userTrackStyles.roadStatusCoverageLevel2)
          );

          this.userTrackStyles.roadStatusCoverageLevel3.color = configResult.legendSettings.measurementUnits[2].color;
          this.userTrackStyles.roadStatusCoverageLevel3.dasharray = configResult.legendSettings.measurementUnits[2].dashArray;
          this.settingsService.setStringValue(
              SettingsService.COVERAGE_TRACK_STYLE_3,
              JSON.stringify(this.userTrackStyles.roadStatusCoverageLevel3)
          );

          this.userTrackStyles.roadStatusCoverageLevel4.color = configResult.legendSettings.measurementUnits[3].color;
          this.userTrackStyles.roadStatusCoverageLevel4.dasharray = configResult.legendSettings.measurementUnits[3].dashArray;
          this.settingsService.setStringValue(
              SettingsService.COVERAGE_TRACK_STYLE_4,
              JSON.stringify(this.userTrackStyles.roadStatusCoverageLevel4)
          );

          this.userTrackStyles.roadStatusCoverageLevel5.color = configResult.legendSettings.measurementUnits[4].color;
          this.userTrackStyles.roadStatusCoverageLevel5.dasharray = configResult.legendSettings.measurementUnits[4].dashArray;
          this.settingsService.setStringValue(
              SettingsService.COVERAGE_TRACK_STYLE_5,
              JSON.stringify(this.userTrackStyles.roadStatusCoverageLevel5)
          );
        }

        this.configurationService.refreshUserTrackStyles();
      }
    });
  }
}
