<div class="setup-page-container">

    <div class="header-container">
        <h2 class="header-container-filters">
            Vehicle installations report
        </h2>

        <div class="header-container-buttons">
            <div *ngIf="!isLoading">
                <button mat-button
                        color="accent"
                        (click)="loadCSVReport()"
                        class="caption text-primary">
                    CSV export
                </button>
            </div>
        </div>
    </div>

    <div class="content-container">
        <div class="selection-container">
            <div class="mt-15">
                <div class="data-table" *ngIf="!isLoading">
                    <h3>Total vehicle installations: {{totalInstalls}}</h3>
                    <table
                            mat-table
                            matSort
                            matSortDisableClear
                            [dataSource]="dataSource"
                            (matSortChange)="sortBy()"
                            matSortActive="id"
                            matSortDirection="desc"
                    >
                        <ng-container matColumnDef="id">
                            <th
                                    mat-header-cell
                                    *matHeaderCellDef
                                    class="mat-body text-accent"
                            >
                                UUID
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.id | slice: 0: 8 }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="startTimestamp">
                            <th
                                    mat-header-cell
                                    *matHeaderCellDef
                                    mat-sort-header
                                    class="mat-body text-accent"
                            >
                                Start Time
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.startTimestamp * 1000 | date: 'YYYY-MM-dd HH:mm:ss' }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="endTimestamp">
                            <th
                                    mat-header-cell
                                    *matHeaderCellDef
                                    mat-sort-header
                                    class="mat-body text-accent"
                            >
                                End Time
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.endTimestamp * 1000 | date: 'YYYY-MM-dd HH:mm:ss' }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="vehicleReference">
                            <th
                                    mat-header-cell
                                    *matHeaderCellDef
                                    mat-sort-header
                                    class="mat-body text-accent"
                            >
                                Vehicle Ref.
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.vehicleReference }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="usbAvlDeviceLmuId">
                            <th
                                    mat-header-cell
                                    *matHeaderCellDef
                                    mat-sort-header
                                    class="mat-body text-accent"
                            >
                                LMUId
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.usbAvlDeviceLmuId }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="usbAvlDevice">
                            <th
                                    mat-header-cell
                                    *matHeaderCellDef
                                    mat-sort-header
                                    class="mat-body text-accent"
                            >
                                Analog device
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.usbAvlDevice }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="usbDigitalDevice">
                            <th
                                    mat-header-cell
                                    *matHeaderCellDef
                                    mat-sort-header
                                    class="mat-body text-accent"
                            >
                                Digital device
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.usbDigitalDevice }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="installer">
                            <th
                                    mat-header-cell
                                    *matHeaderCellDef
                                    mat-sort-header
                                    class="mat-body text-accent"
                            >
                                Installer
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.installer }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="note">
                            <th
                                    mat-header-cell
                                    *matHeaderCellDef
                                    mat-sort-header
                                    class="mat-body text-accent"
                            >
                                Note
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.note }}
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    </table>
                </div>
                <div>
                    <mat-paginator
                            [pageSizeOptions]="[25, 50, 100, 250]"
                            (page)="page()"
                    ></mat-paginator>
                </div>
            </div>


        </div>

    </div>

</div>

