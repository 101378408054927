import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrl: './filter-button.component.scss'
})
export class FilterButtonComponent {

  @Input() appliedFiltersCount: number;
  @Input() active: boolean;
  @Output() toggled = new EventEmitter();

  onClick() {
    this.toggled.emit();
  }
}
