<div *ngIf="isLoading" class="loading__wrapper">
    <mat-spinner></mat-spinner>
</div>

<div class="my-25">

    <div class="container" *ngIf="!isLoading">

        <app-cartegraph-settings-import-filters
                [settings]="configuration?.settings"></app-cartegraph-settings-import-filters>

        <app-cartegraph-settings-defaults
                [settings]="configuration?.settings"
                (onValidityChange)="onDefaultsValidity($event)"></app-cartegraph-settings-defaults>

        <div *ngIf="!isLoading && !isTesting && uiError && uiError.length > 0" class="w-100 mt-15 content">
            <span class="ui-error">{{ uiError }}</span>
        </div>
        <div class="form-row mb-25 mt-10">
            <button
                    mat-button
                    color="accent"
                    type="button"
                    [disabled]="!defaultsValid"
                    (click)="updateSettings()"
            >
                <mat-icon>save</mat-icon>
                Update configuration
            </button>
        </div>

    </div>
</div>
