import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ShiftMapDataService} from '../../pages/shift-map/services/shift-map-data.service';
import {Subscription} from 'rxjs';
import {ShiftWithDriverAndVehicleModel} from '../../shared/models/shift.model';

export enum DrawerContent {
  MESSAGES = 'message',
  LIVE_VIDEO = 'video',
  ROUTE = 'route',
  OBSERVATION = 'observation',
  ALERTS = 'alerts',
  QUICK_INSIGHTS = 'insights',
}

export class DrawerType {
  static readonly MESSAGES  = new DrawerType(DrawerContent.MESSAGES, 'message', 'Messages');
  static readonly LIVE_VIDEO  = new DrawerType(DrawerContent.LIVE_VIDEO, 'tap_and_play', 'Livestreaming');
  static readonly ROUTE  = new DrawerType(DrawerContent.ROUTE, 'route', 'Route Assignments');
  static readonly OBSERVATION  = new DrawerType(DrawerContent.OBSERVATION, 'pin_drop', 'Observations');
  static readonly ALERTS  = new DrawerType(DrawerContent.ALERTS, 'notifications', 'Alerts');
  static readonly QUICK_INSIGHTS  = new DrawerType(DrawerContent.QUICK_INSIGHTS, 'bar_chart', 'Quick Insights');
  static getAll(): DrawerType[] {
    return [
        DrawerType.MESSAGES,
        DrawerType.LIVE_VIDEO,
        DrawerType.ROUTE,
        DrawerType.OBSERVATION,
        DrawerType.ALERTS,
        DrawerType.QUICK_INSIGHTS,
    ];
  }

  // private to disallow creating other instances of this type
  private constructor(
      public readonly content: DrawerContent,
      public readonly icon: string,
      public readonly label: string
  ) {}

  toString() {
    return this.label;
  }
}

@Component({
  selector: 'app-right-drawer',
  templateUrl: './right-drawer.component.html',
  styleUrls: ['./right-drawer.component.scss']
})
export class RightDrawerComponent implements OnInit, OnDestroy {

  isDisplayed = false;
  DrawerContent = DrawerContent;
  drawer: string;
  shift: ShiftWithDriverAndVehicleModel;

  drawersAll: DrawerType[] = DrawerType.getAll();
  selectedDrawer: DrawerType;

  shiftSubscription: Subscription;

  constructor(
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private shiftMapDataService: ShiftMapDataService,
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.isDisplayed = !!params.drawer;
      this.drawer = params.drawer;
      this.selectedDrawer = this.drawersAll.find(drawer => drawer.content === this.drawer);
    });

    // get shift entity from shared service
    this.shiftSubscription = this.shiftMapDataService.shift$.subscribe(shift => {
      this.shift = shift;
    });
  }

  ngOnDestroy() {
    this.shiftSubscription?.unsubscribe();
    this.shift = null;
  }

  hideDrawer() {
    this.isDisplayed = false;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { drawer: undefined },
      queryParamsHandling: 'merge',
    });
  }
}
