<h1 mat-dialog-title>
    {{ title || 'Confirmation' }}
</h1>

<mat-dialog-content class="dialog-content">
    <div #body>
        <!-- Inject the HTML here -->
    </div>

    <div [ngStyle]="{'visibility': isWorking ? 'visible' : 'hidden' }">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close [disabled]="isWorking">
        {{ cancelButtonLabel || 'Cancel' }}
    </button>
    <button mat-flat-button color="warn" (click)="tryDelete()"
        [disabled]="isWorking">
        {{ confirmButtonLabel || 'Yes' }}
    </button>
</mat-dialog-actions>
