import {Component, Input, OnInit} from '@angular/core';
import {DrawerContent, DrawerType} from '../../../../../layouts/right-drawer/right-drawer.component';
import {FeatureFlagEnum} from '../../../../../shared/models/configuration.model';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-right-panel-button',
  templateUrl: './right-panel-button.component.html',
  styleUrl: './right-panel-button.component.scss'
})
export class RightPanelButtonComponent implements OnInit {

  @Input() drawerType: DrawerType;

  activeDrawer: DrawerContent;

  protected readonly DrawerContent = DrawerContent;
  protected readonly FeatureFlagEnum = FeatureFlagEnum;

  constructor(
      private router: Router,
      private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.activeDrawer = params.drawer;
    });
  }

  showDrawerPanel(drawer: DrawerContent) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {drawer},
      queryParamsHandling: 'merge',
    });
  }
}
