import {Equipment} from '../model/equipment.class';
import {NamedId} from '../../../../../shared/models/NamedId';
import {
  AvlModel,
  DigitalSpreader,
  HardwareConfiguration,
  SensorInput,
  SensorType,
  VehicleModel
} from '../../../../../shared/models/vehicle.model';
import {VehiclesService} from '../../../../../data/vehicles/vehicles.service';
import {ToastService} from '../../../../../shared/services/toast.service';
import {Component, Inject, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FeatureFlagEnum} from '../../../../../shared/models/configuration.model';
import {AddOrImportVehicleResult, EditVehicleComponentInputData} from './vehicle.types';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'app-vehicle-hardware',
  templateUrl: './update-vehicle-hardware.component.html',
  styleUrls: [
    '../dialogs-vehicle/dialog-components.scss',
    '../../../../settings/settings-fields.scss'
  ],
})
export class DialogVehicleHardwareComponent implements OnInit, OnChanges {
  originalVehicleData: VehicleModel;
  model: VehicleModel;
  headline = 'Vehicle Hardware Integrations';
  isWorking = false;
  saveError?: string

  ui: {
    allAvlModels: NamedId[];
    useAvl?: boolean;
  } = {
    allAvlModels: [
      {id: AvlModel.NONE, name: 'None'},
      {id: AvlModel.PLOWOPS_HUB_C, name: 'PlowOps Hub (Rev C)'},
      {id: AvlModel.CALAMP_2630, name: 'CalAmp 2630'},
      {id: AvlModel.CALAMP_3641, name: 'CalAmp 3641'},
      {id: AvlModel.CALAMP_5530, name: 'CalAmp 5530'}
    ]
  };
  equipments: Equipment[] = [];
  usedInputs: number[] = [];
  allInputs: NamedId[];

  // digital spreader integration
  digitalSpreaderCheckbox: boolean;

  // enums
  AvlModel = AvlModel;
  FeatureFlagEnum = FeatureFlagEnum;
  SensorType = SensorType;

  protected isInitialized = false;

  constructor(
    private dialogRef: MatDialogRef<DialogVehicleHardwareComponent>,
    private vehicleService: VehiclesService,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) protected data: EditVehicleComponentInputData
  ) {
    // dialogRef.addPanelClass('use-material-design-3-theme');
    this.model = {...this.data.model};
    this.originalVehicleData = {...this.data.model};
  }

  save(): Promise<AddOrImportVehicleResult> {
    this.isWorking = true;
    delete this.saveError;
    const disableCloseStateToRestore = this.dialogRef.disableClose;
    this.dialogRef.disableClose = true;

    const vehicle = this.model;
    if (vehicle.hardwareConfiguration) {
      vehicle.hardwareConfiguration.sensorInputs = this.fromEquipmentsToSensorInputs(this.equipments);
    }
    return new Promise((resolve, reject) => {
      this.vehicleService.updateVehicle(vehicle.id, vehicle)
        .then((result) => {
          const saveResult: AddOrImportVehicleResult = {
            type: 'singleVehicle',
            data: result.data,
          };
          this.dialogRef.close(saveResult);
        })
        .catch((error) => {
          this.saveError = error;
          this.toastService.short('Update failed');
        }).finally(() => {
        this.isWorking = false;
        this.dialogRef.disableClose = disableCloseStateToRestore;
      });
    });
  }

  ngOnInit() {
    this.initFromData(this.data);
    this.isInitialized = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('data')) {
      if (changes.data.firstChange) {
        this.initFromData(this.data);
        this.originalVehicleData = {...this.model};
      }
    }
  }

  protected initFromData(data: EditVehicleComponentInputData) {

    this.ui.useAvl = !!data.model.hardwareConfiguration;
    if (!!this.model.hardwareConfiguration) {
      if (!!this.model.hardwareConfiguration.avlModel) {
        this.initializeAllInputsData();
        this.equipments = this.fromSensorInputsToEquipments(this.model.hardwareConfiguration.sensorInputs);
        this.setUsedInputs();
      }
      if (!!this.model.hardwareConfiguration.digitalSpreader) {
        this.digitalSpreaderCheckbox = true;
      }
    }
  }

  vehicleValues(key): string[] {
    // FIXME
    //return this.vehicles.map(v => v[key]).filter(v => this.originalVehicleData[key] !== v);
    return [];
  }

  canBeSaved(): boolean {
    if (!this.isInitialized) {
      return false;
    }

    // removed avl, save empty
    if (!this.ui.useAvl) {
      return true;
    }

    const isInvalid = (this.model.lmuId !== null && !this.isUniqueVehicleValue('lmuId', this.model.lmuId)) ||
      (this.model.hasNoTablet && !this.model.hardwareConfiguration?.avlModel) ||
      (!this.model.hardwareConfiguration?.avlModel) ||
      !this.areEquipmentsValid() ||
      !this.isDigitalSpreaderValid();
    return !isInvalid;
  }

  hasFeatureFlag(featureFlag: string): boolean {
    return this.data.configuration.featureFlags.find(value => value.isEnabled && value.name === featureFlag) !== undefined;
  }

  avlModelChanged() {
    this.initializeAllInputsData();
    this.model.hardwareConfiguration.sensorInputs = [];
    this.equipments = [];
    this.digitalSpreaderCheckbox = false;
    this.model.hardwareConfiguration.digitalSpreader = null;
  }

  onAvlChange() {
    if (this.ui.useAvl) {
      this.model.hardwareConfiguration = new HardwareConfiguration();
    } else {
      this.model.lmuId = null;
      this.model.hardwareConfiguration = null;
    }
  }

  fromSensorInputsToEquipments(sensorInputs: SensorInput[]): Equipment[] {
    const equipments: Equipment[] = [];
    sensorInputs
      .filter(input => input.type !== SensorType.NONE)
      .forEach((sensorInput, index) => {
        if (sensorInput.type !== SensorType.PLOW2W || // 1-input hw
          (sensorInput.type === SensorType.PLOW2W && // 2-input hw with previous non-2-input hw
            (index === 0 ||
              (index >= 1 && sensorInputs[index - 1].type !== SensorType.PLOW2W) ||
              (index >= 1 && sensorInputs[index - 1].type === SensorType.PLOW2W && !!equipments[equipments.length - 1]?.inputDown)
            )
          )
        ) {
          const equipment = {
            type: sensorInput.type,
            label: sensorInput.label,
            rate: sensorInput.rate,
            reversed: sensorInput.reversed,
            bitNumber: sensorInput.bitNumber,
          } as Equipment;
          equipment.inputUp = sensorInput;
          equipments.push(equipment);
        } else {
          // 2-input hw with previous 2-input hw with inputUp
          equipments[equipments.length - 1].inputDown = sensorInput;
        }
      });

    return equipments;
  }

  onEquipmentDeleted(index: number) {
    this.equipments.splice(index, 1);
  }

  // update bitNumbers for all equipment
  onEquipmentTypeChanged() {
    const bitOffsetsWithSensorTypes = [
      {
        offset: 0,
        types: [
          SensorType.PLOW,
          SensorType.PLOW2W,
        ]
      },
      {
        offset: 5,
        types: [
          SensorType.GRANULAR_SPREADER,
          SensorType.DIGITAL_GRANULAR_SPREADER,
        ]
      },
      {
        offset: 10,
        types: [
          SensorType.LIQUID_SPREADER,
          SensorType.DIGITAL_LIQUID_SPREADER,
        ]
      },
      {
        offset: 15,
        types: [
          SensorType.MOWER,
        ]
      },
      {
        offset: 20,
        types: [
          SensorType.SWEEPER,
        ]
      }
    ];

    bitOffsetsWithSensorTypes.forEach(offsetWithType => {
      let bitNumber = 0;
      const filteredEquipments = this.equipments.filter(input => offsetWithType.types.includes(input.type));
      for (const filteredEquipment of filteredEquipments) {
        filteredEquipment.bitNumber = bitNumber + offsetWithType.offset;
        bitNumber++;
      }
    });
  }

  onInputsChanged() {
    this.setUsedInputs();
  }

  private initializeAllInputsData() {
    const avlModel = this.model.hardwareConfiguration.avlModel;
    if (!avlModel) {
      console.warn('AVL model not initialized!');
    }
    const inputsDefault: string[] = ['Blue', 'Orange', 'Violet', 'Grey', 'Green/White'];
    const inputs5530AndHub: string[] = ['Blue', 'Orange', 'Violet', 'Grey', 'ATD - Analog to Digital, Pink'];
    const inputsHubC: string[] = [...Array(4)].map(() => 'ATD - Analog to Digital'); // same, 4 only
    let inputs: string[];
    switch (avlModel) {
      case AvlModel.CALAMP_5530:
        inputs = inputs5530AndHub;
        break;
      case AvlModel.PLOWOPS_HUB_C:
        inputs = inputsHubC;
        break;
      default:
        inputs = inputsDefault;
    }
    this.allInputs = inputs.map((color, index) => new NamedId(index + 1, color));
    this.allInputs.push(...[new NamedId(6, 'Virtual'), new NamedId(7, 'Virtual')]);
  }

  private setUsedInputs() {
    this.usedInputs = this.equipments.map(equipment => equipment.inputUp.id);
    this.usedInputs.push(
      ...this.equipments
        .filter(equipment => !!equipment.inputDown)
        .map(equipment => equipment.inputDown.id)
    );
  }

  canAddEquipment(): boolean {
    if (!!this.model.hardwareConfiguration.avlModel && this.model.hardwareConfiguration.avlModel === AvlModel.NONE) {
      return false;
    }
    const physicalInputsConfigured = this.equipments.filter(equipment => equipment.inputUp?.id < 6);
    return physicalInputsConfigured.length < 5;
  }

  addEquipment() {
    this.equipments.push({});
  }

  areEquipmentsValid(): boolean {
    if (!!this.equipments && this.equipments.length > 0) {
      // check required fields
      for (const equipment of this.equipments) {
        if (!equipment.type || !equipment.inputUp || (equipment.type === SensorType.PLOW2W && !equipment.inputDown)) {
          return false;
        }
      }
      // check duplicates
      const duplicates = this.equipments
        .slice()
        .map(item => [item.inputUp.id, item.inputDown?.id])
        .flat()
        .filter(item => !!item)
        .sort()
        .filter((e, i, a) => a[i - 1] === e);
      if (duplicates.length > 0) {
        console.log('Duplicate inputs found!');
        return false;
      }

      // check number of spreaders
      const liquidSpreaders = this.equipments.filter(equipment => {
        return equipment.type === SensorType.DIGITAL_LIQUID_SPREADER || equipment.type === SensorType.LIQUID_SPREADER;
      });
      if (liquidSpreaders.length > 5) {
        console.log('Too many liquid spreader sensors configured! ' + liquidSpreaders.length);
        return false;
      }
      const granularSpreaders = this.equipments.filter(equipment => {
        return equipment.type === SensorType.DIGITAL_GRANULAR_SPREADER || equipment.type === SensorType.GRANULAR_SPREADER;
      });
      if (granularSpreaders.length > 5) {
        console.log('Too many granular spreader sensors configured! ' + granularSpreaders.length);
        return false;
      }

      return true;
    } else {
      return true;
    }
  }

  isUniqueVehicleValue(key, value): boolean {
    const vehicleValues = this.vehicleValues(key);
    return vehicleValues.find(vehicleValue => vehicleValue === value) == null;
  }

  onDigitalSpreaderCheckboxChange() {
    if (this.digitalSpreaderCheckbox) {
      this.model.hardwareConfiguration.digitalSpreader = new DigitalSpreader();
    } else {
      this.model.hardwareConfiguration.digitalSpreader = null;
      this.equipments = this.equipments.filter(e =>
        e.type !== SensorType.DIGITAL_GRANULAR_SPREADER &&
        e.type !== SensorType.DIGITAL_LIQUID_SPREADER
      );
      this.onEquipmentTypeChanged();
    }
  }

  onDigitalSpreaderChange(event: DigitalSpreader) {
    const digitalSpreader = event;
    this.model.hardwareConfiguration.digitalSpreader = digitalSpreader;
    let equipment = this.equipments.find(e => e.type === SensorType.DIGITAL_GRANULAR_SPREADER);
    if (digitalSpreader.settings.readGranular && !equipment) {
      equipment = new Equipment();
      equipment.type = SensorType.DIGITAL_GRANULAR_SPREADER;
      equipment.inputUp = this.allInputs.find(input => input.id === 6);
      this.equipments.push(equipment);
    } else if (!digitalSpreader.settings.readGranular && equipment) {
      this.equipments.splice(this.equipments.indexOf(equipment), 1);
    }

    equipment = this.equipments.find(e => e.type === SensorType.DIGITAL_LIQUID_SPREADER);
    if (digitalSpreader.settings.readLiquid && !equipment) {
      equipment = new Equipment();
      equipment.type = SensorType.DIGITAL_LIQUID_SPREADER;
      equipment.inputUp = this.allInputs.find(input => input.id === 7);
      this.equipments.push(equipment);
    } else if (!digitalSpreader.settings.readLiquid && equipment) {
      this.equipments.splice(this.equipments.indexOf(equipment), 1);
    }
    this.onEquipmentTypeChanged();
  }

  isDigitalSpreaderValid(): boolean {
    const digitalSpreader = this.model.hardwareConfiguration.digitalSpreader;
    if (!!digitalSpreader) {
      return !!digitalSpreader.model &&
        !!digitalSpreader.settings &&
        (
          (digitalSpreader.settings.readGranular
            && !!digitalSpreader.settings.granularMaxRate && !!digitalSpreader.settings.granularBlastRate) ||
          (digitalSpreader.settings.readLiquid && !!digitalSpreader.settings.liquidMaxRate)
        );
    }
    return true;
  }

  private fromEquipmentsToSensorInputs(equipments: Equipment[]): SensorInput[] {
    const sensorInputs: SensorInput[] = [];
    for (const equipment of equipments) {
      const sensorInput = new SensorInput(equipment.inputUp.id, equipment.inputUp.name);
      sensorInput.type = equipment.type;
      sensorInput.label = equipment.label;
      sensorInput.rate = equipment.rate;
      sensorInput.reversed = equipment.reversed;
      sensorInput.bitNumber = equipment.bitNumber;
      sensorInputs.push(sensorInput);
      if (equipment.type === SensorType.PLOW2W) {
        const sensorInputDown = new SensorInput(equipment.inputDown.id, equipment.inputDown.name);
        sensorInputDown.type = equipment.type;
        sensorInputDown.label = equipment.label;
        sensorInputDown.rate = equipment.rate;
        sensorInputDown.reversed = equipment.reversed;
        sensorInputDown.bitNumber = equipment.bitNumber;
        sensorInputs.push(sensorInputDown);
      }
    }
    return sensorInputs;
  }
}


