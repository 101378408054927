import {TrackStyle} from './TrackStyle';
import {TrackStylesService} from '../track-styles.service';
import {SettingsService} from '../settings.service';

export class TrackStyles {

  static fallbackTrackStyle = new TrackStyle('', '#ffffff', 1.0, 0, 3, 0, null);

  roadStatusCoverageLevel1: TrackStyle = TrackStyles.fallbackTrackStyle;
  roadStatusCoverageLevel2: TrackStyle = TrackStyles.fallbackTrackStyle;
  roadStatusCoverageLevel3: TrackStyle = TrackStyles.fallbackTrackStyle;
  roadStatusCoverageLevel4: TrackStyle = TrackStyles.fallbackTrackStyle;
  roadStatusCoverageLevel5: TrackStyle = TrackStyles.fallbackTrackStyle;
  roadStatusCurrencyLevel1: TrackStyle = TrackStyles.fallbackTrackStyle;
  roadStatusCurrencyLevel2: TrackStyle = TrackStyles.fallbackTrackStyle;
  roadStatusCurrencyLevel3: TrackStyle = TrackStyles.fallbackTrackStyle;
  roadStatusCurrencyLevel4: TrackStyle = TrackStyles.fallbackTrackStyle;
  roadStatusCurrencyLevel5: TrackStyle = TrackStyles.fallbackTrackStyle;
  liveMapPlowLive: TrackStyle = TrackStyles.fallbackTrackStyle;
  liveMapPlowHighlighted: TrackStyle = TrackStyles.fallbackTrackStyle;
  liveMapPlowInactive: TrackStyle = TrackStyles.fallbackTrackStyle;
  shiftPlowNormal: TrackStyle = TrackStyles.fallbackTrackStyle;
  shiftPlowPlowDown: TrackStyle = TrackStyles.fallbackTrackStyle;
  shiftPlowSpreaderOn: TrackStyle = TrackStyles.fallbackTrackStyle;

  static saveCoverageAndCurrency(trackStyles: TrackStyles, service: TrackStylesService): Promise<TrackStyles> {
    return service.saveTrackStyles([
      trackStyles.roadStatusCoverageLevel1,
      trackStyles.roadStatusCoverageLevel2,
      trackStyles.roadStatusCoverageLevel3,
      trackStyles.roadStatusCoverageLevel4,
      trackStyles.roadStatusCoverageLevel5,
      trackStyles.roadStatusCurrencyLevel1,
      trackStyles.roadStatusCurrencyLevel2,
      trackStyles.roadStatusCurrencyLevel3,
      trackStyles.roadStatusCurrencyLevel4,
      trackStyles.roadStatusCurrencyLevel5,
    ]);
  }

  static resetCoverageAndCurrency(trackStyles: TrackStyles, service: TrackStylesService): Promise<TrackStyles> {
    return service.resetTrackStyles([
      trackStyles.roadStatusCoverageLevel1,
      trackStyles.roadStatusCoverageLevel2,
      trackStyles.roadStatusCoverageLevel3,
      trackStyles.roadStatusCoverageLevel4,
      trackStyles.roadStatusCoverageLevel5,
      trackStyles.roadStatusCurrencyLevel1,
      trackStyles.roadStatusCurrencyLevel2,
      trackStyles.roadStatusCurrencyLevel3,
      trackStyles.roadStatusCurrencyLevel4,
      trackStyles.roadStatusCurrencyLevel5,
    ]);
  }

  static copy(it: TrackStyles): TrackStyles {
    const copy = new TrackStyles();
    copy.roadStatusCoverageLevel1 = Object.assign({}, it.roadStatusCoverageLevel1);
    copy.roadStatusCoverageLevel2 = Object.assign({}, it.roadStatusCoverageLevel2);
    copy.roadStatusCoverageLevel3 = Object.assign({}, it.roadStatusCoverageLevel3);
    copy.roadStatusCoverageLevel4 = Object.assign({}, it.roadStatusCoverageLevel4);
    copy.roadStatusCoverageLevel5 = Object.assign({}, it.roadStatusCoverageLevel5);
    copy.roadStatusCurrencyLevel1 = Object.assign({}, it.roadStatusCurrencyLevel1);
    copy.roadStatusCurrencyLevel2 = Object.assign({}, it.roadStatusCurrencyLevel2);
    copy.roadStatusCurrencyLevel3 = Object.assign({}, it.roadStatusCurrencyLevel3);
    copy.roadStatusCurrencyLevel4 = Object.assign({}, it.roadStatusCurrencyLevel4);
    copy.roadStatusCurrencyLevel5 = Object.assign({}, it.roadStatusCurrencyLevel5);
    copy.liveMapPlowLive = Object.assign({}, it.liveMapPlowLive);
    copy.liveMapPlowHighlighted = Object.assign({}, it.liveMapPlowHighlighted);
    copy.liveMapPlowInactive = Object.assign({}, it.liveMapPlowInactive);
    copy.shiftPlowNormal = Object.assign({}, it.shiftPlowNormal);
    copy.shiftPlowPlowDown = Object.assign({}, it.shiftPlowPlowDown);
    copy.shiftPlowSpreaderOn = Object.assign({}, it.shiftPlowSpreaderOn);
    return copy;
  }

  static enhanceFromSettings(trackStyles: TrackStyles, service: SettingsService) {
    const userTrackStyles = TrackStyles.copy(trackStyles);

    TrackStyles.updateStyleFromSettings(userTrackStyles, 'roadStatusCoverageLevel1', SettingsService.COVERAGE_TRACK_STYLE_1, service);
    TrackStyles.updateStyleFromSettings(userTrackStyles, 'roadStatusCoverageLevel2', SettingsService.COVERAGE_TRACK_STYLE_2, service);
    TrackStyles.updateStyleFromSettings(userTrackStyles, 'roadStatusCoverageLevel3', SettingsService.COVERAGE_TRACK_STYLE_3, service);
    TrackStyles.updateStyleFromSettings(userTrackStyles, 'roadStatusCoverageLevel4', SettingsService.COVERAGE_TRACK_STYLE_4, service);
    TrackStyles.updateStyleFromSettings(userTrackStyles, 'roadStatusCoverageLevel5', SettingsService.COVERAGE_TRACK_STYLE_5, service);

    TrackStyles.updateStyleFromSettings(userTrackStyles, 'roadStatusCurrencyLevel1', SettingsService.CURRENCY_TRACK_STYLE_1, service);
    TrackStyles.updateStyleFromSettings(userTrackStyles, 'roadStatusCurrencyLevel2', SettingsService.CURRENCY_TRACK_STYLE_2, service);
    TrackStyles.updateStyleFromSettings(userTrackStyles, 'roadStatusCurrencyLevel3', SettingsService.CURRENCY_TRACK_STYLE_3, service);
    TrackStyles.updateStyleFromSettings(userTrackStyles, 'roadStatusCurrencyLevel4', SettingsService.CURRENCY_TRACK_STYLE_4, service);
    TrackStyles.updateStyleFromSettings(userTrackStyles, 'roadStatusCurrencyLevel5', SettingsService.CURRENCY_TRACK_STYLE_5, service);

    return userTrackStyles;
  }

  private static updateStyleFromSettings(trackStyles: TrackStyles, field: string, settingsName: string, settingsService: SettingsService) {
    const styleSettings = settingsService.getStringValue(settingsName);
    if (!!styleSettings && styleSettings !== 'null') {
      trackStyles[field] = JSON.parse(styleSettings) as TrackStyle;
      try {
      } catch (e) {
        console.warn(`${settingsName} setting cannot be parsed as JSON.`);
      }
    } else {
      // console.debug('CoverageLevel1TrackStyle setting is null');
    }
  }
}
