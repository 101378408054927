import {Component, Inject} from '@angular/core';
import {ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef, MatDialogTitle
} from '@angular/material/dialog';
import {UserModel} from '../../../../../shared/models/User';
import {MatError, MatFormField} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {MatButton} from '@angular/material/button';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-dialog-change-password',
  templateUrl: 'dialog-change-password.component.html',
  imports: [
    ReactiveFormsModule,
    MatDialogContent,
    MatFormField,
    MatInput,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    NgIf,
    MatError,
    MatDialogTitle,
  ],
  standalone: true
})
export class DialogChangePasswordComponent {

  passwordUpdateForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserModel,
    private fb: UntypedFormBuilder
  ) {
    this.initForm();
  }

  initForm() {
    this.passwordUpdateForm = this.fb.group({
      newPassword: '',
    }, {
      validators: []
    });
    this.passwordUpdateForm.controls['newPassword'].setValidators([
      Validators.required,
      Validators.minLength(8),
      Validators.pattern('(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W).{4,}')
    ]);
    this.passwordUpdateForm.controls['newPassword'].markAsTouched();
  }
}
