import {Map} from 'maplibre-gl';
import {FeatureCollection} from 'geojson';
import {MapContent} from '../MapContent';
import {TrackStyles} from '../../../../../configuration/model/TrackStyles';
import {MapContentSource} from '../MapContentSource';
import {LocationInfo} from '../../../../models/AddressLookup';
import {ShiftTrackLayer} from './ShiftTrackLayer';
import {AddressMarkerLayer} from './AddressMarkerLayer';
import {ShiftTrackArrowsLayer} from './ShiftTrackArrowsLayer';

export class AddressLookupVisitMapContent implements MapContent {

  private map: Map = null;
  private markersSource: MapContentSource = new MapContentSource('markers-source');
  private tracksSource: MapContentSource = new MapContentSource('tracks-source');
  private markerLayer: AddressMarkerLayer = new AddressMarkerLayer(this.markersSource);
  private shiftTrackLayer: ShiftTrackLayer;
  private shiftTrackArrowsLayer: ShiftTrackArrowsLayer = new ShiftTrackArrowsLayer(this.tracksSource);

  constructor(
    marker: LocationInfo,
    shiftTrackFeatureCollection: FeatureCollection,
    trackStyles: TrackStyles,
  ) {
    this.markerLayer.addMarker(marker);
    this.tracksSource.loadFromFeatureCollection(shiftTrackFeatureCollection);
    this.shiftTrackLayer = new ShiftTrackLayer(this.tracksSource, trackStyles, null);
  }

  load(map: Map) {
    this.map = map;

    this.markersSource.updateMap(this.map);
    this.tracksSource.updateMap(this.map);
    this.map.addLayer(this.shiftTrackLayer.toLayerSpecification());
    this.map.addLayer(this.shiftTrackArrowsLayer.toLayerSpecification());
    this.map.addLayer(this.markerLayer.toLayerSpecification());

    this.markerLayer.zoomToVisibleFeatures(this.map);
  }

  unload() {
    this.map.removeLayer(this.shiftTrackLayer.layerId);
    this.map.removeLayer(this.shiftTrackArrowsLayer.layerId);
    this.map.removeLayer(this.markerLayer.layerId);
    this.map.removeSource(this.markersSource.sourceId);
    this.map.removeSource(this.tracksSource.sourceId);
    this.map = null;
  }
}
