<mat-spinner *ngIf="!items"></mat-spinner>

<span *ngIf="!!items && items.length === 0">
    No assignments
</span>

<ng-container *ngIf="!!items">
    <ng-container *ngFor="let item of items">
        <app-action-menu-item [input]="item">
        </app-action-menu-item>
    </ng-container>
</ng-container>
