<div class="row-with-toggle" (click)="handleChange(!checked)">
    <div class="field-row-title" *ngIf="primaryText?.length > 0">
        {{ primaryText }}
        <div class="text-lighter" *ngIf="secondaryText?.length > 0">
            {{ secondaryText }}
        </div>
    </div>
    <div class="field-row-element">
        <mat-slide-toggle [hideIcon]="true" [checked]="checked" [disabled]="disabled">
        </mat-slide-toggle>
    </div>
</div>
@if(children?.length > 0) {
	<div class="indent children"
		 [@collapse]="checked">
		@for (child of children; track child.id) {
			<app-row-with-toggle [primaryText]="child.name" [checked]="selectedChildIds[child.id]" (checkedChange)="handleChildrenChange(child)"></app-row-with-toggle>
		}
	</div>
}
