<mat-vertical-stepper linear #stepper>
    <mat-step label="Upload CSV file" optional="false">
        <input hidden type="file"
               id="file"
               accept=".csv"
               #fileInput
               (change)="handleFileInput($event)">

        <div *ngIf="currentState === ImportVehiclesStateEnum.WAITING_FOR_FILE">

            <div class="row-with-button" *ngIf="!showCsvHelp">
                <div class="text-block">
                    Choose a CSV file in a
                    <button mat-button (click)="showCsvHelp=true">supported format</button>.
                </div>
                <button mat-stroked-button
                        color="accent" (click)="fileInput.click()">
                    Choose CSV file
                </button>
            </div>

            <mat-card *ngIf="showCsvHelp">
                <mat-card-header>
                    <mat-card-title>Sample CSV</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <p>
                        Map Label and License Plate are optional.
                    </p>

                    <div style="overflow: auto; font-family: monospace; white-space: nowrap">
                        name,mapLabel,licensePlate,vGroup<br />
                        4431009435,X0035,XE0436-X0035,Truck 4x4<br />
                        …<br />
                        …
                    </div>
                </mat-card-content>
                <mat-card-actions align="end">
                    <button mat-stroked-button (click)="showCsvHelp=false">Close</button>
                </mat-card-actions>
            </mat-card>
        </div>
        <div *ngIf="currentState === ImportVehiclesStateEnum.UPLOADING_FILE">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div *ngIf="currentState === ImportVehiclesStateEnum.UPLOAD_FAILED" class="ui-error">
            <p>{{ uiError }}</p>
        </div>
        <div *ngIf="currentState === ImportVehiclesStateEnum.UPLOAD_SUCCEEDED">
            <p>{{selectedFile.name}} uploaded.</p>
            <button mat-stroked-button matStepperNext color="accent">Next</button>
        </div>
    </mat-step>

    <mat-step label="Review data">
        <p *ngIf="(!validData || validData.length === 0) && (!invalidData || invalidData.length === 0)">
            No CSV data.
        </p>

        <mat-card *ngIf="invalidData && invalidData.length > 0" style="margin-top: 16px">
            <mat-card-header>
                <mat-card-title>Invalid data</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div style="overflow-x: auto">
                    <ng-container
                            *ngTemplateOutlet="table;context:{importData: invalidData,displayedColumns:displayedColumnsInvalid}"></ng-container>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card *ngIf="validData && validData.length > 0" style="margin-top: 16px">
            <mat-card-header>
                <mat-card-title>Valid data</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div style="overflow-x: auto">
                    <ng-container
                            *ngTemplateOutlet="table;context:{importData: validData,displayedColumns:displayedColumnsValid}"></ng-container>
                </div>
            </mat-card-content>
            <mat-card-actions align="end">
                <button mat-stroked-button matStepperNext color="accent" [disabled]="!hasValidRowsToImport()">Next</button>
            </mat-card-actions>
        </mat-card>
    </mat-step>

    <mat-step label="Import vehicles">
        <div *ngIf="currentState === ImportVehiclesStateEnum.UPLOAD_SUCCEEDED">
            <p *ngIf="hasValidRowsToImport()">
                {{ rowsToImport().length }}
                <span [ngPlural]="rowsToImport().length">
                    <ng-template ngPluralCase="=1">vehicle</ng-template>
                    <ng-template ngPluralCase="other">vehicles</ng-template>
                </span>
                ready to import.
            </p>
            <p *ngIf="!hasValidRowsToImport()">
                No vehicles to import
            </p>
        </div>
        <div *ngIf="currentState === ImportVehiclesStateEnum.IMPORTING">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div *ngIf="currentState === ImportVehiclesStateEnum.IMPORT_FAILED" class="ui-error">
            <p>{{ uiError }}</p>
        </div>
        <div *ngIf="currentState === ImportVehiclesStateEnum.IMPORT_SUCCEEDED">
            <p>Import succeeded.</p>
        </div>
    </mat-step>

</mat-vertical-stepper>

<ng-template #table let-data="importData" let-displayedColumns="displayedColumns">
    <table *ngIf="data && data.length>0" mat-table
           [dataSource]="data">

        <ng-container matColumnDef="line">
            <th mat-header-cell *matHeaderCellDef> Line</th>
            <td mat-cell *matCellDef="let element">{{element.index}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name</th>
            <td mat-cell *matCellDef="let element">{{element.row.name}} </td>
        </ng-container>

        <ng-container matColumnDef="mapLabel">
            <th mat-header-cell *matHeaderCellDef> Map Label</th>
            <td mat-cell *matCellDef="let element">
                {{element.row?.mapLabel}}
                <del *ngIf="element.vehicle?.mapLabel !== element.row?.mapLabel">{{element.vehicle?.mapLabel}}</del>
            </td>
        </ng-container>

        <ng-container matColumnDef="licensePlate">
            <th mat-header-cell *matHeaderCellDef> License Plate</th>
            <td mat-cell *matCellDef="let element">
                {{element.row?.licensePlate}}
                <del *ngIf="element.vehicle?.licensePlate !== element.row?.licensePlate">{{element.vehicle?.licensePlate}}</del>
            </td>
        </ng-container>

        <ng-container matColumnDef="vGroup">
            <th mat-header-cell *matHeaderCellDef> Vehicle Group</th>
            <td mat-cell *matCellDef="let element">
                {{element.row?.vehicleGroup}}
                <del *ngIf="element.vehicleCategory?.title !== element.row?.vehicleGroup">{{element.vehicleCategory?.title}}</del>
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action</th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngTemplateOutlet="action;context:{action:element.action}"></ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="errors">
            <th mat-header-cell *matHeaderCellDef> Errors</th>
            <td mat-cell *matCellDef="let element">
                <div *ngFor="let error of element.errors">{{error}}</div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</ng-template>

<ng-template #action let-action="action">
    <ng-container [ngSwitch]="action">
        <mat-icon *ngSwitchCase="'CREATE'" color="accent" [title]="action">add_circle</mat-icon>
        <mat-icon *ngSwitchCase="'UPDATE'" color="accent" [title]="action">sync</mat-icon>
    </ng-container>
</ng-template>
