<div *ngIf="!isLoading && !!settings">
    <ng-container *ngIf="!!formShift">
        <ng-container
                *ngTemplateOutlet="shiftDefaults;context:{title:'Shift task defaults', form: formShift}"></ng-container>
    </ng-container>

    <ng-container *ngIf="!!formPreInspection">
        <ng-container
                *ngTemplateOutlet="defaults;context:{title:'Pre-Inspection task defaults', form: formPreInspection}"></ng-container>
    </ng-container>

    <ng-container *ngIf="!!formPostInspection">
        <ng-container
                *ngTemplateOutlet="defaults;context:{title:'Post-Inspection', form: formPostInspection}"></ng-container>
    </ng-container>

    <ng-container *ngIf="!!formObservation">
        <ng-container
                *ngTemplateOutlet="defaults;context:{title:'Observation', form: formObservation}"></ng-container>
    </ng-container>
</div>

<ng-template #shiftDefaults let-title="title" let-form="form">
    <mat-card appearance="outlined" class="mb-10 mt-10">
        <mat-card-header>
            <mat-card-title>{{ title }}</mat-card-title>
        </mat-card-header>
        <mat-card-content class="mt-25">
            <form [formGroup]="form">
                <ng-container
                        *ngTemplateOutlet="formFields;context:{form: formShift}"></ng-container>

                <div class="form-row">
                    <mat-form-field appearance="outline" color="accent" class="form-input" floatLabel="always">
                        <mat-label>Work Order</mat-label>
                        <mat-select placeholder="Work Order" [formControl]="form.controls['workOrder']">
                            <mat-option
                                    *ngFor="let option of datasetWorkOrder"
                                    [value]="option.label">{{ option.label }} {{ option.description }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <mat-form-field appearance="outline" color="accent" class="form-input" floatLabel="always">
                    <mat-label>Max driver hours fallback</mat-label>
                    <input type="number" matInput [formControl]="form.controls['maxHoursFallback']">
                </mat-form-field>

                <div class="form-row">
                    <mat-form-field appearance="outline" color="accent" class="form-input" floatLabel="always">
                        <mat-label>Default department</mat-label>
                        <mat-select placeholder="Department (driver's department if empty)" [formControl]="form.controls['department']">
                            <mat-option
                                    *ngFor="let option of datasetDepartment"
                                    [value]="option.label">{{ option.label }}
                            </mat-option>
                        </mat-select>
                        <button matSuffix mat-icon-button aria-label="Clear" (click)="form.controls['department'].setValue(null); $event.stopPropagation()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>

            </form>
        </mat-card-content>
    </mat-card>
</ng-template>

<ng-template #defaults let-title="title" let-form="form">
    <mat-card appearance="outlined" class="mb-10 mt-10">
        <mat-card-header>
            <mat-card-title>{{ title }}</mat-card-title>
        </mat-card-header>
        <mat-card-content class="mt-25">
            <form [formGroup]="form">
                <ng-container
                        *ngTemplateOutlet="formFields;context:{form: form}"></ng-container>
            </form>
        </mat-card-content>
    </mat-card>
</ng-template>

<ng-template #formFields let-form="form">
    <div class="form-row">
        <mat-form-field appearance="outline" color="accent" class="form-input" floatLabel="always">
            <mat-label>Priority</mat-label>
            <mat-select placeholder="Priority" [formControl]="form.controls['priority']">
                <mat-option
                        *ngFor="let option of datasetPriority"
                        [value]="option.label">{{ option.label }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="form.controls['priority']?.hasError('required')">
                Field ['Priority'] is required
            </mat-error>
        </mat-form-field>
    </div>

    <div class="form-row">
        <mat-form-field appearance="outline" color="accent" class="form-input" floatLabel="always">
            <mat-label>Status</mat-label>
            <mat-select placeholder="Status" [formControl]="form.controls['status']">
                <mat-option
                        *ngFor="let option of datasetStatus"
                        [value]="option.label">{{ option.label }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="form.controls['priority']?.hasError('required')">
                Field ['Status'] is required
            </mat-error>
        </mat-form-field>
    </div>

    <div class="form-row">
        <mat-form-field appearance="outline" color="accent" class="form-input" floatLabel="always">
            <mat-label>Activity</mat-label>
            <mat-select placeholder="Activity" [formControl]="form.controls['activity']">
                <mat-option
                        *ngFor="let option of datasetActivity"
                        [value]="option.label">{{ option.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="form-row">
        <mat-form-field appearance="outline" color="accent" class="form-input" floatLabel="always">
            <mat-label>Work Completed For</mat-label>
            <mat-select placeholder="Work Completed For" [formControl]="form.controls['workCompletedFor']">
                <mat-option
                        *ngFor="let option of datasetWorkCompletedFor"
                        [value]="option.label">{{ option.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

</ng-template>