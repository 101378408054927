import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ShiftsService } from '../../../../data/shifts/shifts.service';
import { DomSanitizer } from '@angular/platform-browser';
import moment from 'moment';
import { ImageGenerationStatus, ShiftWithDriverAndVehicleModel } from '../../../models/shift.model';
import { ConfigurationModel } from '../../../models/configuration.model';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmDeleteShiftDialogComponent
} from '../../dialogs/confirm-delete-shift-dialog/confirm-delete-shift-dialog.component';
import { SecurityService } from '../../../../security/security.service';
import { ToastService } from '../../../services/toast.service';
import { InsightsRoute } from '../../../../pages/insights/insights-routing.module';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-shift-detail-card',
  templateUrl: './shift-detail-card.component.html',
  styleUrls: ['./shift-detail-card.component.scss'],
})
export class ShiftDetailCardComponent implements OnInit {
  @Input() configuration: ConfigurationModel;
  @Input() shift: ShiftWithDriverAndVehicleModel;
  @Input() source = 'vehicles';
  @Output() shiftDeleted = new EventEmitter<number>();
  imageData: any = null;
  isLoading = true;
  isAdmin = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private securityService: SecurityService,
    private shiftService: ShiftsService,
    private domSanitizer: DomSanitizer,
    private dialog: MatDialog,
    private toast: ToastService,
  ) {}

  ngOnInit(): void {
    this.isAdmin = this.securityService.isAdminSync();

    if (this.shift.imageGenerationStatus === ImageGenerationStatus.FAILURE_LOCATION_SERVICE ||
      this.shift.imageGenerationStatus === ImageGenerationStatus.FAILURE_AWS
    ) {
      // the generation failed, display empty
      this.imageData = null;
      this.isLoading = false;
    } else {
      // image for the shift was not generated yet
      firstValueFrom(this.shiftService.getShiftTrackThumbnail(
        this.shift.id,
        720,
        360,
        this.shift.imageGenerationStatus === ImageGenerationStatus.GENERATED,
      )).then((response) => {
        this.imageData = this.domSanitizer.bypassSecurityTrustResourceUrl(
            URL.createObjectURL(response)
        );
        this.isLoading = false;
      }).catch((message) => {
        console.log(message);
        this.isLoading = false;
      });
    }
  }

  getDuration() {
    const end = moment(this.shift.end);
    const start = moment(this.shift.start);
    // @ts-ignore
    return moment.duration(end - start).humanize();
  }

  viewShift() {
    this.router.navigate([InsightsRoute.SHIFT_DETAIL, this.shift.id], {
      relativeTo: this.activatedRoute,
      queryParams: {
        source: this.source,
      }
    });
  }

  canDeleteShift() {
    return this.isAdmin;
  }

  confirmDeletingShift() {
    const dialogRef = this.dialog.open(ConfirmDeleteShiftDialogComponent, {
      data: this.shift,
      width: '350px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!!result) {
        this.shiftService.deleteShift(this.shift.id)
            .then(() => {
              this.shiftDeleted.emit(this.shift.id);
            })
            .catch(error => {
              console.error(error);
              this.toast.longer('Deleting Shift failed!');
            });
      }
    });
  }
}
