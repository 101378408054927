<cdk-accordion class="expansion-panel">
	<cdk-accordion-item
        #accordionItem="cdkAccordionItem" #item
		[expanded]="expanded"
		role="button">

		<div class="panel-header"
			 (click)="accordionItem.toggle()">
			<div class="panel-title">
				<h2 class="panel-title-text mb-0">{{panelTitle}}</h2>
				@if (panelDescription) { <div class="panel-description-text">{{panelDescription}}</div> }
			</div>

			<div class="expansion-icon" [@rotate]="accordionItem.expanded">
				<mat-icon>keyboard_arrow_up</mat-icon>
			</div>
		</div>

		<div role="region"
			 class="panel-content"
			 [@collapse]="accordionItem.expanded">
			<ng-content></ng-content>
		</div>
	</cdk-accordion-item>
</cdk-accordion>
