import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LiveMapComponent} from './components/live-map.component';
import {InsightsModule} from '../insights/insights.module';
import {InsightsComponent} from '../insights/components/insights.component';
import {LiveAddressComponent} from './components/ui-components/live-address/live-address.component';
import {AssetComponent} from './components/ui-components/asset/asset.component';
import {RouteComponent} from './components/ui-components/route/route.component';
import {MainRoute} from '../../shared/models/angular-routing';
import {AboutComponent} from '../settings/components/about/about.component';

const mixedRoutes: Routes = [
  {
    path: '',
    component: LiveMapComponent,
    children: [
      {
        path: `${MainRoute.VEHICLE}/:id`,
        component: AssetComponent,
      },
      {
        path: `${MainRoute.ROUTE}/:path/route-id/:routeId`,
        component: RouteComponent,
      },
      {
        path: MainRoute.INSIGHTS,
        component: InsightsComponent,
        loadChildren: () => InsightsModule,
      },
      {
        path: MainRoute.ADDRESS,
        component: LiveAddressComponent,
      },
      {
        path: MainRoute.ABOUT,
        component: AboutComponent,
      },
      /*{
        // workaround for two routes, one component, multiple component onInit issue
        matcher: assetsMatcher,
        component: AssetsComponent,
      },
      {
        path: LiveMapTab.ROUTES,
        component: RoutesComponent,
      },*/
      /*{
        path: LiveMapTab.MESSAGES,
        component: MessagesComponent,
        children: [
          {
            path: '',
            component: AllMessagesComponent,
          },
          {
            path: 'new-message',
            component: NewMessageComponent,
          },
          {
            path: 'message',
            component: OpenMessageComponent,
          },
        ],
      },
      {
        path: LiveMapTab.OBSERVATIONS,
        component: ObservationsComponent,
      },*/
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(mixedRoutes)],
  exports: [RouterModule],
})
export class LiveMapRoutingModule {}
