<div class="input-row">
    <mat-form-field class="hw-type" appearance="outline" color="accent">
        <mat-label>Hardware Type</mat-label>
        <mat-select [(ngModel)]="equipment.type" (ngModelChange)="typeChanged()">
            <mat-option [value]="SensorType.PLOW">Plow</mat-option>
            <mat-option [value]="SensorType.PLOW2W" *ngIf="avlModel !== AvlModel.PLOWOPS_HUB_C">Plow (2-wire)</mat-option>
            <mat-option [value]="SensorType.GRANULAR_SPREADER">Granular Spreader</mat-option>
            <mat-option [value]="SensorType.LIQUID_SPREADER">Liquid Spreader</mat-option>
            <mat-option [value]="SensorType.MOWER">Mower</mat-option>
            <mat-option [value]="SensorType.SWEEPER">Sweeper</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="hw-label"
                    appearance="outline"
                    color="accent"
                    *ngIf="equipment.type === SensorType.PLOW || equipment.type === SensorType.PLOW2W">
        <mat-label>Label</mat-label>
        <input matInput
               autocomplete="off"
               [(ngModel)]="equipment.label"
        >
    </mat-form-field>

    <mat-form-field class="hw-rate"
                    appearance="outline"
                    color="accent"
                    *ngIf="equipment.type && (equipment.type === SensorType.LIQUID_SPREADER || equipment.type == SensorType.GRANULAR_SPREADER) && !!configuration">
        <mat-label>Rate</mat-label>
        <input matInput
               type="number"
               autocomplete="off"
               [(ngModel)]="equipment.rate"
        >
        <span *ngIf="equipment.type === SensorType.GRANULAR_SPREADER" class="units">
            {{ configuration.useMetricSystem ? 'kg/km' : 'lbs/mi' }}
        </span>
        <span *ngIf="equipment.type === SensorType.LIQUID_SPREADER" class="units">
            {{ configuration.useMetricSystem ? 'l/km' : 'gal/mi' }}
        </span>
    </mat-form-field>

    <div class="inputs-wrapper">
        <div class="input-connection-wrapper" >
            <div class="input-connection" *ngIf="equipment.type === SensorType.PLOW2W"></div>
        </div>
        <div class="inputs">
            <mat-form-field class="input-up" appearance="outline" color="accent">
                <mat-label>Input {{ equipment.type === SensorType.PLOW2W ? 'Up' : '' }}</mat-label>
                <mat-select [(ngModel)]="equipment.inputUp" [compareWith]="compareSensorInputs" (ngModelChange)="inputChanged()">
                    <ng-container *ngFor="let inputType of filteredInputs">
                        <mat-option [value]="inputType"><b>{{inputType.id}}</b> - {{ inputType.name }}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="input-down" appearance="outline" color="accent"
                            *ngIf="equipment.type === SensorType.PLOW2W">
                <mat-label>Input Down</mat-label>
                <mat-select [(ngModel)]="equipment.inputDown" [compareWith]="compareSensorInputs" (ngModelChange)="inputChanged()">
                    <ng-container *ngFor="let inputType of filteredInputs">
                        <mat-option [value]="inputType">{{ inputType.name }}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="actions-wrapper">
        <button
                class="icon-button"
                (click)="reverseClicked()"
                mat-icon-button
                [color]="equipment.reversed ? 'accent' : undefined"
                *ngIf="!!equipment.type && equipment.type !== SensorType.NONE"
                [matTooltip]="'Select this to reverse the meaning of the sensor signal'"
        >
            <mat-icon>autorenew</mat-icon>
        </button>
        <button
                mat-icon-button
                class="icon-button"
                [matTooltip]="'Delete Hardware'"
                (click)="delete()"
        >
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</div>
