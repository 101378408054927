import { MapContentLayer } from '../MapContentLayer';
import {LayerSpecification, SymbolLayerSpecification} from 'maplibre-gl';
import { MapContentSource } from '../MapContentSource';
import { MapPreviewComponent } from '../../map-preview.component';

export class ObservationIconLayer extends MapContentLayer {

  constructor(
    source: MapContentSource
  ) {
    super('observation-icon-layer', source);
  }

  toLayerSpecification(): LayerSpecification {
    return {
      id: this.layerId,
      type: 'symbol',
      source: this.sourceRef.sourceId,
      layout: {
        'icon-image': MapPreviewComponent.STAR_IMAGE,
        'icon-allow-overlap': true,
      },
      paint: {
        'icon-color': 'white',
      },
    } as SymbolLayerSpecification;
  }

}
