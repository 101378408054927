import {Map} from 'maplibre-gl';
import {MapContent} from '../MapContent';
import {LocationInfo} from '../../../../models/AddressLookup';
import {PointFeature} from '../../../../models/GeoJson';
import {MapContentSource} from '../MapContentSource';
import {MapTools} from '../../../../tools/MapTools';
import {AddressMarkerLayer} from './AddressMarkerLayer';

export class AddressLookupAddressMapContent implements MapContent {

  private map: Map = null;
  private source: MapContentSource = new MapContentSource('only-source');
  private mapMarkerLayer: AddressMarkerLayer = new AddressMarkerLayer(this.source);

  private readonly markerClickListener: any;

  constructor(
    markers: LocationInfo[],
    private markerHighlighted: (markerId: number) => void,
  ) {

    markers.forEach(marker => this.mapMarkerLayer.addMarker(marker));

    this.markerClickListener = (e) => {
      if (MapTools.checkPropagation(e)){
        return;
      }
      const pointFeature = e.features[0] as PointFeature;
      this.onClickMarker(pointFeature.properties.id);
    };
  }

  load(map: Map) {
    this.map = map;

    this.source.updateMap(this.map);
    this.map.addLayer(this.mapMarkerLayer.toLayerSpecification());

    MapTools.pointCursorForLayer(this.map, this.mapMarkerLayer, true);
    this.map.on('click', this.mapMarkerLayer.layerId, this.markerClickListener);

    this.mapMarkerLayer.zoomToVisibleFeatures(this.map);
  }

  unload() {
    MapTools.pointCursorForLayer(this.map, this.mapMarkerLayer, false);
    this.map.off('click', this.mapMarkerLayer.layerId, this.markerClickListener);

    this.map.removeLayer(this.mapMarkerLayer.layerId);
    this.map.removeSource(this.source.sourceId);
    this.map = null;
  }

  private onClickMarker(markerId: number) {
    this.highlightMarker(markerId);
    this.markerHighlighted(markerId);
  }

  highlightMarker(markerId: number) {
    this.source.features.forEach(feature => {
      if (feature.properties.type === AddressMarkerLayer.MARKER_FEATURE_TYPE) {
        feature.properties.highlighted = (markerId === feature.properties.id);
      }
    });

    MapTools.onMap(this.map, () => {
      this.source.updateMap(this.map);
    });
  }

  cancelMarkerHighlights() {
    this.source.features.forEach(feature => {
      if (feature.properties.type === AddressMarkerLayer.MARKER_FEATURE_TYPE) {
        feature.properties.highlighted = false;
      }
    });

    MapTools.onMap(this.map, () => {
      this.source.updateMap(this.map);
    });
  }
}
