<div class="left-panel-container noselect">

  <div class="pt-15">
    <button mat-button
            color="accent"
            (click)="goBack()"
    >
      <mat-icon>arrow_back</mat-icon>
      <ng-container [ngSwitch]="insightRoute">
        <span *ngSwitchCase="InsightsRoute.SHIFT_LOOKUP" class="text-primary">Back to Shift Lookup</span>
        <span *ngSwitchCase="InsightsRoute.DVIR_REPORT" class="text-primary">Back to Inspection Form retrieval</span>
        <span *ngSwitchCase="InsightsRoute.IMAGE_REPORT" class="text-primary">Back to Image Retrieval</span>
        <span *ngSwitchCase="InsightsRoute.ADDRESS_LOOKUP" class="text-primary">Back to Address Lookup</span>
        <span *ngSwitchCase="InsightsRoute.DEVICE_REPORT" class="text-primary">Back to Device Report</span>
        <span *ngSwitchDefault class="text-primary">Back to Shift Report</span>

      </ng-container>
    </button>
  </div>

  <div class="shift__details__container px-15">
    <div class="shift__details__wrapper__header mt-15">
      <div class="mat-headline-5 text-primary-dark bolder">Shift Details</div>
      <div class="mat-body-1 text-primary m0">
        {{ !!shift.vehicle.label ? shift.vehicle.label : shift.vehicle.name }}
      </div>
      <div class="mat-body-1 text-primary m0">
        {{ shift.start | date: 'medium' }}
      </div>
    </div>
    <div class="shift__details__wrapper__body mt-25">
      <div class="mat-body-1 text-primary-dark m0">SHIFT STATISTICS</div>
      <div *ngIf="statsLoading" class="stats__container__loading">
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>

      <div class="stats__container" *ngIf="!statsLoading">
        <div class="stats__row">
          <div class="mat-body">Vehicle:</div>
          <div class="mat-body">{{ !!shift.vehicle.label ? shift.vehicle.label : shift.vehicle.name }}</div>
        </div>
        <div class="stats__row">
          <div class="mat-body">Driver:</div>
          <div class="mat-body">{{ shift.driver.name }}</div>
        </div>
        <div class="stats__row">
          <div class="mat-body">Shift ID:</div>
          <div class="mat-body">{{ shift.id }}</div>
        </div>
        <div class="stats__row">
          <div class="mat-body">Start Time::</div>
          <div class="mat-body">
            {{ shift.start | date: 'MM/dd/yy HH:mm' }}
          </div>
        </div>
        <div class="stats__row">
          <div class="mat-body">Finish Time:</div>
          <div class="mat-body">{{ shift.end | date: 'MM/dd/yy HH:mm' }}</div>
        </div>
          <ng-container *ngIf="shift.statusStatistics === StatisticsStatus.ERROR">
              <div class="stats__row mt-10 mb-10">
                  <span>Statistics processing failed</span>
                  <button mat-button color="warn" (click)="loadStats()">Refresh</button>
              </div>
          </ng-container>
          <ng-container *ngIf="shift.statusStatistics === StatisticsStatus.READY || shift.statusStatistics === StatisticsStatus.PROCESSING">
              <div class="stats__row mt-10 mb-10">
                  <span>Processing statistics...</span>
                  <button mat-button color="accent" (click)="loadStats()">Refresh</button>
              </div>
          </ng-container>
          <ng-container *ngIf="shift.statusStatistics === StatisticsStatus.COMPLETE">
              <div class="stats__row">
                  <div class="mat-body">Duration:</div>
                  <div class="mat-body">{{ getDuration() }}</div>
              </div>
              <div class="stats__row">
                  <div class="mat-body">Distance Driven:</div>
                  <div class="mat-body" *ngIf="!statsLoading">
                      <span *ngIf="!!configuration">{{ stats.trackLength | distance:configuration.useMetricSystem }}</span>
                  </div>
              </div>
              <div class="stats__row">
                  <div class="mat-body">Distance Plowed:</div>
                  <div class="mat-body" *ngIf="!statsLoading">
                      <span *ngIf="!!configuration">{{ shift.distancePlowed | distance:configuration.useMetricSystem }}</span>
                  </div>
              </div>
              <div class="stats__row">
                  <div class="mat-body">Distance Spread (Granular):</div>
                  <div class="mat-body" *ngIf="!statsLoading">
                      <span *ngIf="!!configuration">{{ shift.distanceSpreadGranular | distance:configuration.useMetricSystem }}</span>
                  </div>
              </div>
              <div class="stats__row">
                  <div class="mat-body">Distance Spread (Liquid):</div>
                  <div class="mat-body" *ngIf="!statsLoading">
                      <span *ngIf="!!configuration">{{ shift.distanceSpreadLiquid | distance:configuration.useMetricSystem }}</span>
                  </div>
              </div>
              <div class="stats__row">
                  <div class="mat-body">Distance Mowed:</div>
                  <div class="mat-body" *ngIf="!statsLoading">
                      <span *ngIf="!!configuration">{{ shift.distanceMowed | distance:configuration.useMetricSystem }}</span>
                  </div>
              </div>
              <div class="stats__row">
                  <div class="mat-body">Distance Swept:</div>
                  <div class="mat-body" *ngIf="!statsLoading">
                      <span *ngIf="!!configuration">{{ shift.distanceSwept | distance:configuration.useMetricSystem }}</span>
                  </div>
              </div>
              <div class="stats__row">
                  <div class="mat-body">Material Used (Granular):</div>
                  <div class="mat-body" *ngIf="!statsLoading">
                      <span *ngIf="!!configuration">{{ shift.materialUsedGranular | weight:configuration.useMetricSystem }}</span>
                  </div>
              </div>
              <div class="stats__row">
                  <div class="mat-body">Material Used (Liquid):</div>
                  <div class="mat-body" *ngIf="!statsLoading">
                      <span *ngIf="!!configuration">{{ shift.materialUsedLiquid | volume:configuration.useMetricSystem }}</span>
                  </div>
              </div>
              <div class="stats__row">
                  <div class="mat-body">GPS Source:</div>
                  <div class="mat-body">{{ getGpsSource() }}</div>
              </div>
          </ng-container>

        <div class="stats__row" *ngIf="!!deviceInfo?.phoneNumber">
          <div class="mat-body">Phone number:</div>
          <div class="mat-body">{{ deviceInfo?.phoneNumber }}</div>
        </div>
      </div>

      <div class="shift__details__wrapper__body mt-25">
        <div class="mat-body-1 text-primary-dark m0">INSPECTION FORMS</div>
        <div class="stats__container">
          <div class="stats__row mb-10" *ngIf="!!shift.startInspectionId">
            <div class="mat-body">Pre-Trip Inspection:</div>
            <button
              mat-button
              color="accent"
              (click)="previewDVIR(true)"
            >
              View Form
            </button>
          </div>
          <div class="stats__row mb-10" *ngIf="!!shift.endInspectionId">
            <div class="mat-body">Post-Trip Inspection:</div>
            <button
              mat-button
              color="accent"
              (click)="previewDVIR(false)"
            >
              View Form
            </button>
          </div>
          <div *ngIf="!shift.startInspectionId && !shift.endInspectionId">
            <div class="mat-body">No Inspection Form Links Available</div>
          </div>
        </div>
      </div>

      <div class="shift__details__wrapper__body mt-25">
        <div class="mat-body-1 text-primary-dark m0">ASSIGNMENTS</div>
        <div class="stats__container" *ngIf="!assignments && assignmentsLoading">
          <mat-spinner [diameter]="50"></mat-spinner>
        </div>
        <div class="stats__container" *ngIf="!assignments && !assignmentsLoading">
          Failed to retrieve data.
        </div>
        <div class="stats__container" *ngIf="!!assignments && assignments.length === 0">
          None
        </div>
        <div class="stats__container" *ngIf="!!assignments && assignments.length > 0">
          <div *ngFor="let assignment of assignments">
            {{ assignment.routeName }}
          </div>
        </div>
      </div>

      <div class="shift__details__wrapper__body mt-25" *ngIf="hasFeatureFlag(FeatureFlagEnum.DashCam) && shift.vehicle.cameraConfiguration?.streaming?.captureImageInterval > 0">
        <div class="mat-body-1 text-primary-dark m0">DASH CAMERA IMAGES</div>
        <div class="stats__container">
          <div *ngIf="!!images && images.length === 0">
            No still images
          </div>
          <app-image-viewer
            *ngIf="!!images && images.length > 0"
            [images]="images"
            [isLiveMap]="false"
          ></app-image-viewer>
        </div>
      </div>

      <div class="shift__details__wrapper__body mt-25">
        <div class="mat-body-1 text-primary-dark m0">OBSERVATIONS</div>
        <div class="stats__container">
          <div *ngIf="!!observations && observations.length === 0">
            None
          </div>
          <ng-container *ngIf="!!observations && !!observationItems && observationItems.length > 0">
            <div *ngFor="let item of observationItems">
              <app-action-menu-item [input]="item"></app-action-menu-item>
            </div>
          </ng-container>
        </div>
      </div>

    </div>
  </div>
</div>
