<div class="wide__page__container"
     *ngIf="currentRoute; else noSelect"
     [ngSwitch]="currentRoute">
    <router-outlet></router-outlet>
</div>

<ng-template #noSelect>
    <app-blank-page
            class="centered__page__container"
            headline="No Insight Selected"
            text="Select an insight report from the list on the left to retrieve information about your operations."
    >
    </app-blank-page>
</ng-template>

