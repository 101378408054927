import { MapContentLayer } from '../MapContentLayer';
import { CircleLayerSpecification, LayerSpecification } from 'maplibre-gl';
import { MapContentSource } from '../MapContentSource';

export class ImageCircleLayer extends MapContentLayer {

  constructor(
    source: MapContentSource
  ) {
    super('image-circle-layer', source);
  }

  toLayerSpecification(): LayerSpecification {
    return {
      id: this.layerId,
      type: 'circle',
      source: this.sourceRef.sourceId,
      paint: {
        'circle-color': 'white',
        'circle-radius': 12,
        'circle-stroke-width': 2,
        'circle-stroke-color': 'white',
        'circle-opacity': 1.0
      },
    } as CircleLayerSpecification;
  }

}
