<h1 mat-dialog-title>
    Delete Inspection Form
</h1>

<mat-dialog-content>
    <p>
        Do you really want to delete form <strong>{{ data.form.title }}</strong>?
    </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-stroked-button [mat-dialog-close]="data">
        Delete The Form
    </button>
    <button mat-raised-button color="accent" mat-dialog-close cdkFocusInitial>
        Cancel
    </button>
</mat-dialog-actions>
