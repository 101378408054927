import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CartegraphConfiguration, CartegraphSyncTask} from '../../../../../shared/models/cartegraph.model';
import {CartegraphManagementService} from '../../../../../data/cartegraph/cartegraph-management.service';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort, Sort, SortDirection} from '@angular/material/sort';
import {CartegraphTasksImportDialogComponent} from '../cartegraph-tasks-import/cartegraph-tasks-import.component';
import {MatDialog} from '@angular/material/dialog';
import {ToastService} from '../../../../../shared/services/toast.service';
import {Router} from '@angular/router';
import {environment} from '../../../../../../environments/environment';
import {CgTaskStatusUpdateDialog} from './cartegraph-tasks-status.copmponent';
import {
  FilterBarSearchEvent
} from '../../../../../shared/components/filter-bar-composite/composite-filter-bar.component';
import { MainRoute, RootRoute } from '../../../../../shared/models/angular-routing';
import { InsightsRoute } from '../../../../insights/insights-routing.module';

@Component({
  selector: 'app-manage-cartegraph-tasks',
  templateUrl: './cartegraph-tasks.component.html',
  styleUrls: ['./cartegraph-tasks.component.scss'],
})
export class CartegraphTasksComponent implements OnInit, AfterViewInit {

  @Input()
  configuration: CartegraphConfiguration;

  isLoading = false;
  uiError: string;

  displayedColumns: string[] = [
    'id', 'taskType', 'created', 'submitted', 'shift', 'cgIdField', 'retryCount', 'processStatus', 'processMessage', 'action'];
  processingStatuses: { id: string, label: string }[] = [
    {id: 'WAITING', label: 'Wait'},
    {id: 'SUCCESS', label: 'Success'},
    {id: 'FAIL', label: 'Fail'},
    {id: 'NONE', label: 'None'}];
  processingStatus: string = this.processingStatusValue(); // default to all
  tasks: MatTableDataSource<CartegraphSyncTask> = new MatTableDataSource<CartegraphSyncTask>([]);

  sortActive = 'created';
  sortDirection: SortDirection = 'desc';

  // used in *ngIf, requires to be loaded dynamically
  @ViewChild(MatSort, {static: false}) set sorter(sort: MatSort) {
    if (sort) {
      this.tasks.sort = sort;
    }
  }

  // used out of *ngIf can be injected
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  itemCount = 0;
  pageSize = 20;
  pageIndex = 0;
  searchText: string;

  constructor(
    private cartegraphManagementService: CartegraphManagementService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private toast: ToastService,
    private router: Router
  ) {
    this.tasks.paginator = this.paginator;
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {
    this.getTasks();
  }

  showSnackBar(message: string) {
    this.snackBar.open(message, null, {duration: 2000});
  }

  getTasks(page: number = 0) {
    this.isLoading = true;
    this.uiError = null;
    const sortField = this.tasks?.sort?.active ? `${this.tasks?.sort?.active},${this.tasks?.sort?.direction}`
      : `${this.sortActive},${this.sortDirection}`;
    this.cartegraphManagementService
      .getTaskList(page, this.pageSize, this.processingStatus, this.searchText, sortField).toPromise().then(response => {
      if (response.error) {
        this.uiError = response.error;
      } else {
        this.tasks.data = response.data.content;
        this.pageSize = response.data.size;
        this.pageIndex = response.data.number;
        this.itemCount = response.data.totalElements;
      }
    }).catch(error => {
      console.log(error);
      this.uiError = error;
    }).finally(() => {
      this.isLoading = false;
    });
  }

  reprocessSyncTask(syncTask: CartegraphSyncTask) {
    this.cartegraphManagementService.updateSyncTask(syncTask.id, 'reprocess').toPromise()
      .then(response => {
        this.getTasks();
        this.toast.long(response.data);
      })
      .catch(error => {
        console.log(error);
        this.uiError = error;
      });
  }

  onPage(pageEvent: PageEvent) {
    // decide to reset sorting here
    this.getTasks(pageEvent.pageIndex);
  }

  openCgDetail(cgOid: string) {
    // this must be based on OID not cgField
    window.open(`${this.configuration.baseUrl}/#FlexUI/Edit/cgTasksClass/${cgOid}`, '_blank');
  }

  viewShiftDetail(shiftId: number) {
    const url = this.router.createUrlTree(
      [environment.base_href, RootRoute.MAIN, MainRoute.INSIGHTS, InsightsRoute.SHIFT_REPORT, InsightsRoute.SHIFT_DETAIL, shiftId],
      {},
    ).toString();
    window.open(url, '_blank');
  }

  doSearch(searchEvent: FilterBarSearchEvent[]) {
    if (!!searchEvent) {
      this.processingStatus = searchEvent.find(e => e.id === 'status')?.search;
      this.searchText = searchEvent.find(e => e.id === 'search')?.search;
    } else {
      this.searchText = undefined;
      this.processingStatus = this.processingStatusValue();
      this.pageIndex = 0;
    }
    this.getTasks(this.pageIndex);
  }

  sortChange(sort: Sort) {
    this.sortActive = sort.active;
    this.sortDirection = sort.direction;
    this.getTasks();
  }

  messageDetail(task: CartegraphSyncTask) {
    if (task.processMessage?.trim().length > 0) {
      const dialogRef = this.dialog.open(CartegraphTasksImportDialogComponent, {
        width: '800px',
        height: '800px',
        data: task.processMessage,
      });
    }
  }

  updateStatus(task: CartegraphSyncTask) {
    const dialogRef = this.dialog.open(CgTaskStatusUpdateDialog, {
      data: Object.assign({}, task),
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.cartegraphManagementService.updateSyncTask(result.id, 'update', result).toPromise()
          .then(response => {
            this.getTasks();
            this.toast.long(response.data);
          })
          .catch(error => {
            console.log(error);
            this.uiError = error;
          });
      }
    });
  }

  private processingStatusValue(): string {
    return this.processingStatuses.map(s => s.id).join(',');
  }

}
