import {DvirFormDto, DvirFormEmailNotificationType, DvirFormSettingsDto} from './dto/DvirFormDto';
import {DvirFormReviewingSignaturesOption} from './form-options/DvirFormReviewingSignaturesOption';
import {DvirNodeDto} from './dto/DvirNodeDto';
import {DvirSection} from './dvir-section';
import {DvirGroup} from './dvir-group';
import {DvirQuestion} from './dvir-question';
import {DvirFormOptionDto} from './dto/DvirFormOptionDto';
import {DvirFormQuestionFormatOption} from './form-options/DvirFormQuestionFormatOption';
import {DvirSectionDto} from './dto/DvirSectionDto';
import {DvirGroupDto} from './dto/DvirGroupDto';
import {DvirQuestionDto} from './dto/DvirQuestionDto';

export const NODE_TYPE_SECTION = 'SECTION';
export const NODE_TYPE_GROUP = 'GROUP';
export const NODE_TYPE_QUESTION = 'QUESTION';

export class DvirForm {
  id: number;
  journalId: number;
  title: string;
  note: string;

  questionFormat: DvirFormQuestionFormatOption;
  reviewingSignatures: DvirFormReviewingSignaturesOption;

  hasRemarks: boolean;
  settings: DvirFormSettingsDto;

  created: Date;
  modified: Date;

  sections: DvirSection[];

  static fromDto(dto: DvirFormDto) {
    const result = new DvirForm()

    result.id = dto.id
    result.journalId = dto.journalId
    result.title = dto.title
    result.note = dto.note

    result.hasRemarks = dto.hasRemarks
    result.created = dto.created
    result.modified = dto.modified

    result.questionFormat = DvirFormQuestionFormatOption.fromCode(
      dto.options.find(option => option.name === DvirFormQuestionFormatOption.optionCode).value)

    result.reviewingSignatures = DvirFormReviewingSignaturesOption.fromCode(
      dto.options.find(option => option.name === DvirFormReviewingSignaturesOption.optionCode)?.value
    ) || DvirFormReviewingSignaturesOption.NEVER_REQUIRED;

    result.settings = dto.settings;

    result.sections = this.convertNodesToSections(dto.nodes || []);

    return result
  }

  static createNew() {
    const result = new DvirForm();
    result.questionFormat = DvirFormQuestionFormatOption.FORM
    result.reviewingSignatures = DvirFormReviewingSignaturesOption.REQUIRED_ON_ISSUE;
    result.hasRemarks = false
    result.settings = new DvirFormSettingsDto()
    result.settings.enableEmailNotifications = false;
    result.settings.emailNotificationType = DvirFormEmailNotificationType.ON_OUT_OF_SERVICE;
    result.sections = [];
    return result;
  }

  private static convertNodesToSections(nodes: DvirNodeDto[]): DvirSection[] {
    // try to find the sections
    const sections = nodes
      .filter(node => node.nodeType === NODE_TYPE_SECTION)

    if (!sections.length) {
      return [
        new DvirSection(
          this.convertNodesToGroups(nodes)
        )
      ]
    }

    return sections.map(sectionNode => {
      const {id, title, rank, hasRemarks} = sectionNode.section;
      const result = new DvirSection(this.convertNodesToGroups(sectionNode.nodes));

      result.id = id
      result.rank = rank
      result.hasRemarks = hasRemarks
      result.title = title

      return result;
    })
  }

  private static convertNodesToGroups(nodes: DvirNodeDto[]): DvirGroup[] {
    // try to find the groups
    const groups = nodes
      .filter(node => node.nodeType === NODE_TYPE_GROUP)

    if (groups.length) {
      return groups.map(groupNode => {
        const {id, rank, title, hasRemarks} = groupNode.group;

        const result = new DvirGroup(this.convertNodesToQuestions(groupNode.nodes))
        result.id = id
        result.rank = rank
        result.title = title
        result.hasRemarks = hasRemarks
        return result
      })
    } else {
      // convert questions to a fake group
      const questions = this.convertNodesToQuestions(nodes)
      return [
        new DvirGroup(questions)
      ]
    }
  }

  private static convertNodesToQuestions(nodes: DvirNodeDto[]): DvirQuestion[] {
    return nodes
      .filter(node => node.nodeType === NODE_TYPE_QUESTION)
      .map((node) => {

        const {id, rank, title, type, notApplicable, valueProvider, answerMapper} = node.question
        return new DvirQuestion(id,
          rank,
          title,
          type,
          notApplicable,
          valueProvider,
          answerMapper)
      })
  }

  toDto(): DvirFormDto {
    const {
      id, journalId, title, note, hasRemarks, created, modified
    } = this;

    // FIXME
    const options = [
      new DvirFormOptionDto(DvirFormQuestionFormatOption.optionCode, this.questionFormat.code),
      new DvirFormOptionDto(DvirFormReviewingSignaturesOption.optionCode, this.reviewingSignatures.code),
    ];

    const settings = this.settings

    return new DvirFormDto(
      id,
      journalId,
      title,
      note,
      options,
      hasRemarks,
      settings,
      created,
      modified,
      0,
      this.getSectionsAsNodeDtos()
    );
  }

  private getSectionsAsNodeDtos(): DvirNodeDto[] {
    if (this.sections.length === 1) {
      if (this.sections[0].groups.length > 1) {
        return this.sections[0].groups.map(group => groupToMifNodeDto(group))
      }
      return this.sections[0].groups[0].questions.map(question => questionToMifNodeDto(question))
    }
    return this.sections.map(section => sectionToMifNodeDto(section));
  }

}

function sectionToMifNodeDto(section: DvirSection) {
  const {id, rank, title, hasRemarks} = section;

  const childNodes = section.groups.length === 1 ?
    section.groups[0].questions.map(question => questionToMifNodeDto(question)) :
    section.groups.map(group => groupToMifNodeDto(group));

  return new DvirNodeDto(
    NODE_TYPE_SECTION,
    new DvirSectionDto(
      id, rank, title, hasRemarks
    ),
    undefined,
    undefined,
    childNodes
  );
}

function groupToMifNodeDto(group: DvirGroup) {
  const {id, rank, title, hasRemarks} = group;
  return new DvirNodeDto(
    NODE_TYPE_GROUP,
    undefined,
    new DvirGroupDto(
      id, rank, title, hasRemarks
    ),
    undefined,
    group.questions.map(question => questionToMifNodeDto(question))
  );
}

function questionToMifNodeDto(question: DvirQuestion) {
  const {id, rank, title, type, notApplicable, valueProvider, answerMapper} = question;

  return new DvirNodeDto(
    NODE_TYPE_QUESTION,
    undefined,
    undefined,
    new DvirQuestionDto(
      id, rank, title, type, notApplicable, valueProvider, answerMapper
    ),
    undefined
  );
}
