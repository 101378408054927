export class SessionMetricEventModel {
  locationSourceId: number;
  sessionKey: number;
  unixtime: number;
  metricValue?: number;
  metricId: number;
  metricUnit?: string;
}

export class MetricType {
  static GRANULAR_RATE = 1;
  static LIQUID_RATE = 2;
  static ROAD_TEMPERATURE = 3;
}
