<div class="search-form-wrapper"
>
    <form class="ops-form">
        <div class="search__box"
             matAutocompleteOrigin
             #origin="matAutocompleteOrigin"
             tabindex="1"
             [ngClass]="{ 'show-full': this.searchControl.disabled,
                          'searching-state': showAutocomplete,
                          'compact': this.isCompact,
                          }"
             (focus)="onSearchBoxFocus($event)"
        >
            <mat-icon class="search__icon">
                search
            </mat-icon>

            <input
                    #searchInput
                    class="search__input"
                    type="text"
                    placeholder="Find Vehicle, Route or Address"
                    aria-label="Number"
                    matInput
                    [formControl]="searchControl"
                    [matAutocomplete]="auto"
                    [matAutocompleteConnectedTo]="origin"
                    (focus)="onFocus($event)"
                    (keyup.enter)="onPressEnter($event)"
                    (focusout)="onFocusOut($event)"
            />

            <button mat-icon-button
                    class="clear__button"
                    (click)="clearSearch()"
                    *ngIf="searchControl.dirty"
            >
                <mat-icon>close</mat-icon>
            </button>
        </div>

        <mat-autocomplete autoActiveFirstOption
                          #auto="matAutocomplete"
                          class="search-autocomplete"
                          [displayWith]="displayFn"
                          [panelWidth]="366"
        >
            <mat-optgroup [label]="'Vehicles'" [ngClass]="{ 'hide-autocomplete': !showAutocomplete }">
                <mat-option
                        *ngFor="let option of filteredAssets$ | async"
                        [value]="{type: SearchResultType.VEHICLE, result: option}"
                        (click)="vehicleSelected(option)"
                >
                    <div class="autocomplete-line">
                        <mat-icon>
                            directions_car
                        </mat-icon>

                        <span>{{ option.name }}</span>,&nbsp;
                        <ng-container *ngIf="option.shiftStatus !== ShiftStatus.ENDED">
                            <span class="mat-small">shift started at {{ option.time | date:'medium' }}</span>
                        </ng-container>
                        <ng-container *ngIf="option.shiftStatus === ShiftStatus.ENDED">
                            <span class="mat-small">offline</span>
                        </ng-container>
                    </div>
                </mat-option>
            </mat-optgroup>

            <mat-optgroup [label]="'Routes'" [ngClass]="{ 'hide-autocomplete': !showAutocomplete }">
                <mat-option
                        *ngFor="let suggestion of routeSuggestions"
                        [value]="{type: SearchResultType.ROUTE, result: suggestion}"
                        (click)="routeSelected(suggestion)"
                >
                    <div class="autocomplete-line">
                        <mat-icon>
                            routes
                        </mat-icon>

                        <span>{{ suggestion.value }}</span>
                    </div>
                </mat-option>
            </mat-optgroup>

            <mat-optgroup [label]="'Addresses'" [ngClass]="{ 'hide-autocomplete': !showAutocomplete }">
                <mat-option
                        *ngFor="let suggestion of addressSuggestions"
                        [value]="{type: SearchResultType.ADDRESS, result: suggestion}"
                        (click)="addressSelected(suggestion)"
                >
                    <div class="autocomplete-line">
                        <mat-icon>
                            home
                        </mat-icon>

                        <span>{{ suggestion.text }}</span>
                    </div>
                </mat-option>
            </mat-optgroup>

            <ng-container *ngIf="configuration && hasFeatureFlag(FeatureFlagEnum.Poi)">
                <mat-optgroup [label]="'POI'">
                    <mat-option
                            [ngClass]="{ 'hide-autocomplete': !showAutocomplete }"
                            *ngFor="let poi of pois"
                            [value]="{type: SearchResultType.POI, result: poi}"
                            (click)="poiSelected(poi)"
                    >
                        <div class="autocomplete-line">
                            <mat-icon>
                                {{ getPoiIconName() }}
                            </mat-icon>

                            <span>{{ poi.name }}</span>
                        </div>
                    </mat-option>
                </mat-optgroup>
            </ng-container>

        </mat-autocomplete>
    </form>
</div>
