import {
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  Inject,
  Injector,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Location, ShiftWithDriverAndVehicleModel } from '../../../shared/models/shift.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ShiftsService } from '../../../data/shifts/shifts.service';
import { ObservationsService } from '../../../data/observations/observations.service';
import moment from 'moment';
import { ConfigurationModel, FeatureFlagEnum } from '../../../shared/models/configuration.model';
import { ConfigurationService } from '../../../configuration/configuration.service';
import { ToastService } from '../../../shared/services/toast.service';
import { DrawerContent } from '../../../layouts/right-drawer/right-drawer.component';
import { ShiftMapDataService } from '../services/shift-map-data.service';
import { ObservationsManagerService } from '../../../data/observations/observations-manager.service';
import { ObservationManagementService } from '../../../data/observations/observation-management.service';
import { ImagesManagerService } from '../../../data/images/images-manager.service';
import { ShiftMapContent } from '../../../shared/components/map-preview/model/map-content/ShiftMapContent';
import { Feature, FeatureCollection } from 'geojson';
import { SettingsService } from '../../../configuration/settings.service';
import { DOCUMENT } from '@angular/common';
import { ObservationTypeGroup } from '../../../shared/models/observation-group';
import { Observation, ObservationType } from '../../../shared/models/observation';
import { JsonApiResponse } from '../../../shared/models/JsonApiResponse';
import { ImageModel } from '../../../shared/models/image';
import { ImageService } from '../../../data/images/image.service';
import { LocationApiService } from '../../../data/location-api/location-api.service';

@Component({
  selector: 'app-shift-map',
  templateUrl: './shift-map.component.html',
  styleUrls: ['./shift-map.component.scss']
})
export class ShiftMapComponent implements OnInit, OnDestroy {

  configuration: ConfigurationModel;
  activeDrawer: DrawerContent;

  private filterFrom = 0;
  private filterTo = 100;
  private speedFactor = 10; // 1 = normal time, 10 = 10x faster
  shift: ShiftWithDriverAndVehicleModel;
  images: ImageModel[];
  observations: Observation[];

  private readonly observationTypeGroupsMap = new Map<number, ObservationTypeGroup>();
  private readonly observationTypesMap = new Map<number, ObservationType>();

  mapContent: ShiftMapContent;

  isLoading = true;

  DrawerContent = DrawerContent;
  FeatureFlagEnum = FeatureFlagEnum;

  private readonly openSubscriptions = new Array<Subscription>();

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private configurationService: ConfigurationService,
              private shiftService: ShiftsService,
              private shiftMapDataService: ShiftMapDataService,
              private observationService: ObservationsService,
              private observationManager: ObservationsManagerService,
              private observationGroupsService: ObservationManagementService,
              private imagesService: ImageService,
              private imageManager: ImagesManagerService,
              private locationApi: LocationApiService,
              private toast: ToastService,
              private settingsService: SettingsService,
              private injector: Injector,
              private resolver: ComponentFactoryResolver,
              private appRef: ApplicationRef,
              @Inject(DOCUMENT) private document: Document,
  ) { }

  ngOnInit(): void {
    const configurationSubscription = this.configurationService.sharedConfigurationModel.subscribe(model => {
      if (model) {
        this.configuration = model;

        const shiftId = +this.activatedRoute.snapshot.paramMap.get('id');
        Promise.all([
          this.shiftService.getShiftInfo(shiftId).toPromise(),
          this.observationService.getObservationTypes().toPromise(),
          this.observationGroupsService.getObservationTypeGroups().toPromise(),
        ]).then(responses => {
          const [shiftInfoResponse, observationTypesResponse, observationTypeGroupResponse] = responses;

          this.shift = shiftInfoResponse.data;
          this.shiftMapDataService.sendShift(this.shift);

          // load observation type groups
          for (const observationTypeGroup of observationTypeGroupResponse.data) {
            this.observationTypeGroupsMap.set(observationTypeGroup.id, observationTypeGroup);
          }

          // load observation types
          for (const observationType of observationTypesResponse.data) {
            this.observationTypesMap.set(observationType.id, new ObservationType(
              observationType.id,
              observationType.title,
              !!observationType.abbreviation ? observationType.abbreviation : observationType.title,
              observationType.observationTypeGroupId,
            ));
          }
          // add deleted observation type
          this.observationTypesMap.set(-1, new ObservationType(
            -1, 'Deleted Observation Type', 'DELETED TYPE', -1
          ));

          this.loadMapContent();
          this.isLoading = false;
        }).catch(message => {
          console.error(message);
          this.isLoading = false;
        });
      }
    });
    this.openSubscriptions.push(configurationSubscription);

    this.activatedRoute.queryParams.subscribe((params) => {
      this.activeDrawer = params.drawer;
    });

    const that = this;
    const settingsSubscription = this.settingsService.settingsChangedObservable.subscribe({
      next(newSettings) {
        if (newSettings.key === SettingsService.SHIFT_TRACK_TIME_FILTER) {
          const valueFromSettings = newSettings.value;
          if (valueFromSettings) {
            that.filterFrom = +valueFromSettings.substring(0, valueFromSettings.indexOf('_'));
            that.filterTo = +valueFromSettings.substring(valueFromSettings.indexOf('_') + 1);

            if (that.mapContent instanceof ShiftMapContent) {
              that.mapContent.filterByTime(that.filterFrom, that.filterTo);
            } else {
              console.warn('empty map content while setting filtering by time');
            }
          }
        }
        if (newSettings.key === SettingsService.SHIFT_TRACK_ANIM_SPEED) {
          const valueFromSettings = newSettings.value;
          if (valueFromSettings) {
            that.speedFactor = +valueFromSettings;

            if (that.mapContent instanceof ShiftMapContent) {
              that.mapContent.changeAnimationSpeed(that.speedFactor);
            } else {
              console.warn('empty map content while setting speed factor');
            }
          }
        }
      }
    });
    this.openSubscriptions.push(settingsSubscription);
  }

  ngOnDestroy() {
    this.observationManager.release();
    this.imageManager.release();
    this.openSubscriptions.forEach(subscription => subscription?.unsubscribe());
    this.shiftMapDataService.sendShift(null);
  }

  private loadMapContent() {
    Promise.all([
      this.locationApi.getShiftTrack(this.shift.id, this.shift.vehicleId),
      this.locationApi.getShiftStatistics(this.shift.id).toPromise(),
      this.observationService.getObservationsByShiftId(this.shift.id).toPromise(),
      this.shift.vehicle.cameraConfiguration?.streaming?.captureImageInterval > 0
        ? this.imagesService.getCadenceImagesPerShift(this.shift.id).toPromise()
        : new Promise<JsonApiResponse<ImageModel[]>>((resolve) => resolve({data: []} as JsonApiResponse<ImageModel[]>)),
    ]).then(responses => {
      const [trackResponse, statsResponse, observationsResponse, imagesResponse] = responses;
      const markerFeatureCollection = {
        type: 'FeatureCollection',
        features: []
      } as FeatureCollection;
      if (!!statsResponse.data && statsResponse.data.length > 0) {
        const shiftStats = statsResponse.data[0];
        if (!!shiftStats?.start?.coordinates?.longitude && !!shiftStats?.start?.coordinates?.latitude) {
          markerFeatureCollection.features.push(
            this.locationToMarkerFeature(shiftStats.start, true)
          );
        }
        if (!!shiftStats?.end?.coordinates?.longitude && !!shiftStats?.end?.coordinates?.latitude) {
          markerFeatureCollection.features.push(
            this.locationToMarkerFeature(shiftStats.end, false)
          );
        }
      }
      const observationFeatureCollection = {
        type: 'FeatureCollection',
        features: []
      } as FeatureCollection;
      this.observations = observationsResponse.data.map(observationModel => {
        const type = this.observationTypesMap.get(observationModel.typeId) ?? this.observationTypesMap.get(-1);
        type.group = this.observationTypeGroupsMap.get(type.observationTypeGroupId);
        return Observation.fromModel(observationModel, type, this.shift.vehicle, this.shift.driver);
      });
      this.observations.forEach(observation => {
        observationFeatureCollection.features.push(
          this.observationToObservationFeature(observation)
        );
      });

      const imageFeatureCollection = {
        type: 'FeatureCollection',
        features: []
      } as FeatureCollection;
      this.images = imagesResponse.data;
      imagesResponse.data.forEach(image => {
        imageFeatureCollection.features.push(
          this.imageToImageFeature(image)
        );
      });

      this.mapContent = new ShiftMapContent(
        trackResponse,
        markerFeatureCollection,
        observationFeatureCollection,
        imageFeatureCollection,
        this.configuration,
        this.configurationService.trackStyles,
        this.observations,
        this.shift,
        this.locationApi,
        this.toast,
        this.injector,
        this.resolver,
        this.appRef,
        this.document,
        this.shiftMapDataService,
      );
    }).catch(error => {
      const msg = 'Error while loading shift geometries.';
      this.toast.long(msg);
      console.error(`${msg} :: ${error}`);
    });
  }

  private locationToMarkerFeature(location: Location, isStart: boolean): Feature {
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [location.coordinates.longitude, location.coordinates.latitude]
      },
      properties: {
        start: isStart
      }
    };
  }

  private observationToObservationFeature(observation: Observation): Feature {
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [
          observation.location.coords.lng,
          observation.location.coords.lat
        ]
      },
      properties: {
        id: observation.id,
        title: observation.observationType.mapLabel,
        highlighted: false,
        color: !!observation.observationType.group?.mapColor ? observation.observationType.group.mapColor : undefined,
      }
    };
  }

  private imageToImageFeature(image: ImageModel): Feature {
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [
          image.location.longitude,
          image.location.latitude,
        ]
      },
      properties: {
        id: image.id,
        time: new Date(image.time).valueOf() / 1000,
        highlighted: false,
      }
    };
  }

  hasFeatureFlag(featureFlag: string): boolean {
    return this.configuration.featureFlags.find(value => value.isEnabled && value.name === featureFlag) !== undefined;
  }

  getShiftLength(): number {
    return moment(this.shift.end).diff(moment(this.shift.start), 'seconds');
  }
}
