import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
  ISettingKeyValuePair,
  SettingsService,
  ActivityFilter,
  StatusLayerType
} from '../../../configuration/settings.service';
import {MapLayersManager} from '../map-viewer/map-layers-manager';
import {Subscription} from 'rxjs';
import {ConfigurationService} from '../../../configuration/configuration.service';
import {SecurityService} from '../../../security/security.service';
import {ConfigurationModel, FeatureFlagEnum} from '../../models/configuration.model';
import {SelectBoxModel} from '../select-box/select-box.component';
import {RouteConfigurationWithSchema, RouteHierarchyItem} from '../../models/route';
import {LiveMapDataService} from '../../../pages/live-map/services/live-map-data.service';
import {RoutesManagerService} from '../../../data/routes/routes-manager.service';
import { Router } from '@angular/router';
import { RootRoute } from '../../models/angular-routing';

@Component({
  selector: 'app-status-layer-switcher',
  templateUrl: './status-layer-switcher.component.html',
  styleUrl: './status-layer-switcher.component.scss'
})
export class StatusLayerSwitcherComponent implements OnInit, OnDestroy {

  private static EMPTY_ROUTE_FILTER_KEY = '__none';

  @Input() mapLayersManager: MapLayersManager;

  isAdmin: boolean;

  showRadar = false;
  showWarnings = false;
  showTraffic = false;

  configuration: ConfigurationModel;
  statusLayers: SelectBoxModel[];
  statusLayerType: string;

  activityFilter: string;
  activityFilters: SelectBoxModel[] = [
    {
      key: ActivityFilter.NONE,
      label: 'No Filter',
      icon: 'circle',
      iconMirrored: false,
      flagEmpty: true,
      emptyLabel: 'Activity Filter',
    },
    {
      key: ActivityFilter.PLOWING,
      label: 'Plowing',
      icon: 'signal_cellular_1_bar',
      iconMirrored: true,
    },
    {
      key: ActivityFilter.LIQUID_SPREADING,
      label: 'Liquid',
      icon: 'water_drop',
      iconMirrored: false,
    },
    {
      key: ActivityFilter.GRANULAR_SPREADING,
      label: 'Granular',
      icon: 'grain',
      iconMirrored: false,
    },
    {
      key: ActivityFilter.MOWING,
      label: 'Mowing',
      icon: 'grass',
      iconMirrored: false,
    },
    {
      key: ActivityFilter.SWEEPING,
      label: 'Sweeping',
      icon: 'cleaning_services',
      iconMirrored: false,
    },
  ];

  routeConfigurations: RouteConfigurationWithSchema[];
  routeItems: RouteHierarchyItem[];
  routePath: string[];
  routeFilters: SelectBoxModel[][];

  protected readonly StatusLayerType = StatusLayerType;
  protected readonly FeatureFlagEnum = FeatureFlagEnum;
  protected readonly RootRoute = RootRoute;

  private readonly openSubscriptions = Array<Subscription>();

  constructor(
      private settingsService: SettingsService,
      private configurationService: ConfigurationService,
      private securityService: SecurityService,
      private liveMapDataService: LiveMapDataService,
      private routeManager: RoutesManagerService,
      private router: Router,
  ) {}

  ngOnInit() {
    this.isAdmin = this.securityService.isAdminSync();
    this.statusLayerType = this.settingsService.getStringValue(SettingsService.TRACKS_LAYER_TYPE_KEY);
    this.activityFilter = this.settingsService.getStringValue(SettingsService.ACTIVITY_FILTER);
    const pathFromSettings = this.settingsService.getStringValue(SettingsService.ROUTE_STATUS_PATH);
    this.routePath = !pathFromSettings || pathFromSettings === '' ? [] : pathFromSettings.split(':::');
    this.updateRoutePath();
    this.updateRoutesManager();

    const configurationSubscription =
        this.configurationService.sharedConfigurationModel.subscribe(
            (configuration) => {
              this.configuration = configuration;
            }
        );
    this.openSubscriptions.push(configurationSubscription);

    const settingsSubscription =
        this.settingsService.settingsChangedObservable.subscribe(
            (keyValuePair: ISettingKeyValuePair) => {
              this.onSettingsChanged(keyValuePair.key, keyValuePair.value);
            }
        );
    this.openSubscriptions.push(settingsSubscription);

    this.statusLayers = [
      {
        key: StatusLayerType.NONE,
        label: 'No Theme',
        icon: 'cancel',
        flagEmpty: true,
      },
      {
        key: StatusLayerType.GPS_TRACKS,
        label: 'Live GPS tracks',
        icon: 'turn_sharp_left',
      }
    ];
    if (this.hasFeatureFlag(FeatureFlagEnum.RoadStatus)) {
      this.statusLayers.push(...[
        {
          key: StatusLayerType.CURRENCY,
          label: 'Time Map',
          icon: 'schedule',
        },
        {
          key: StatusLayerType.COVERAGE,
          label: 'Pass Count Map',
          icon: 'format_list_numbered',
        },
      ]);
    }
    this.statusLayers.push({
      key: StatusLayerType.ROUTE_STATUS,
      label: 'Route Status Map',
      icon: 'route',
    });

    const routeConfigSubscription = this.liveMapDataService.routeConfiguration$.subscribe(routeConfigurations => {
      this.routeConfigurations = routeConfigurations;
      this.routeItems = routeConfigurations
          .sort(RouteConfigurationWithSchema.routeConfigurationCompareFn)
          .map(routeConfig => {
            return this.routeConfigToHierarchy(routeConfig);
          });
      this.updateRoutePath();
      this.prepareRouteHierarchyLists();
    });
    this.openSubscriptions.push(routeConfigSubscription);
  }

  ngOnDestroy() {
    this.openSubscriptions.forEach(subscription => subscription?.unsubscribe());
  }

  hasFeatureFlag(featureFlag: string): boolean {
    return (
        this.configuration.featureFlags.find(
            (value) => value.isEnabled && value.name === featureFlag
        ) !== undefined
    );
  }

  private onSettingsChanged(key: string, value: any) {
    switch (key) {
      case SettingsService.TRACKS_LAYER_TYPE_KEY:
        this.statusLayerType = value;
        break;
      case SettingsService.ACTIVITY_FILTER:
        this.activityFilter = value;
        break;
      case SettingsService.WEATHER_RADAR_LAYER_KEY:
        this.showRadar = value;
        break;
      case SettingsService.WEATHER_WARNINGS_LAYER_KEY:
        this.showWarnings = value;
        break;
      case SettingsService.TRAFFIC_LAYER_KEY:
        this.showTraffic = value;
        break;
      case SettingsService.ROUTE_STATUS_PATH:
        this.routePath = !value || value === '' ? [] : value.split(':::');
        this.updateRoutePath();
        this.prepareRouteHierarchyLists();
        this.updateRoutesManager();
        break;
      default: // other layers in configuration
        break;
    }
  }

  onStatusLayerChange(statusLayer: SelectBoxModel) {
    this.settingsService.setStringValue(
        SettingsService.TRACKS_LAYER_TYPE_KEY,
        statusLayer.key,
    );

    if (statusLayer.key === StatusLayerType.ROUTE_STATUS) {
      this.prepareRouteHierarchyLists();
    }
  }

  onActivityFilterChange(activityFilter: SelectBoxModel) {
    this.settingsService.setStringValue(
        SettingsService.ACTIVITY_FILTER,
        activityFilter.key,
    );
  }

  shouldShowActivityFilter() {
    return this.statusLayerType === StatusLayerType.GPS_TRACKS
      || this.statusLayerType === StatusLayerType.COVERAGE
      || this.statusLayerType === StatusLayerType.CURRENCY;
  }

  private routeConfigToHierarchy(routeConfiguration: RouteConfigurationWithSchema): RouteHierarchyItem {
    const items = RouteHierarchyItem.getVisibleItems(routeConfiguration.configuration.children, routeConfiguration.id);
    return {
      routeId: -1,
      routeName: routeConfiguration.name,
      children: items,
      childrenAttribute: '',
      value: String(routeConfiguration.id)
    };
  }

  private prepareRouteHierarchyLists() {
    if (!!this.routePath && this.routeItems) {
      this.routeFilters = [];

      if (this.routeItems.length === 0) {
        // empty route configurations
        // do nothing
      } else if (this.routeItems.length === 1) {
        // only one configuration
        this.routeFilters.push(...this.prepareGroupFilters());
      } else {
        // multiple configurations
        this.routeFilters.push(this.prepareRouteConfigurationsFilter());
        this.routeFilters.push(...this.prepareGroupFilters());
      }
    }
  }

  private prepareRouteConfigurationsFilter(): SelectBoxModel[] {
    return this.routeItems.map(routeConfig => {
      return {
        key: routeConfig.value,
        label: routeConfig.routeName,
        icon: 'route',
      } as SelectBoxModel;
    });
  }

  private prepareGroupFilters(): SelectBoxModel[][] {
    const routeFilters: SelectBoxModel[][] = [];
    if (this.routePath.length > 0) {
      const currentConfiguration = this.routeConfigurations.find(config => config.id === +this.routePath[0]);
      if (!currentConfiguration) {
        console.warn('Route Path config doesn\'t correspond with any route config ID!');
        return routeFilters;
      }

      const currentPath: string[] = [];
      for (let i = 0; i < this.routePath.length; i++) {
        if (i < currentConfiguration.schema.classification.length - 1) {
          currentPath.push(this.routePath[i]);
          const itemOnPath = RouteHierarchyItem.getByPath(currentPath, this.routeItems);
          const pathLevelItems = itemOnPath.children.map(item => {
            return {
              key: item.value,
              label: item.value,
              icon: 'route',
            } as SelectBoxModel;
          });
          routeFilters.push([
            {
              key: StatusLayerSwitcherComponent.EMPTY_ROUTE_FILTER_KEY,
              label: 'All Groups',
              icon: 'cancel',
              flagEmpty: true,
            } as SelectBoxModel,
            ...pathLevelItems
          ]);
        }
      }
    }
    return routeFilters;
  }

  getSelectedRouteFilter(pathIndex: number) {
    if (!this.routePath || pathIndex >= this.routePath.length) {
      return StatusLayerSwitcherComponent.EMPTY_ROUTE_FILTER_KEY;
    }
    return this.routePath[pathIndex];
  }

  onRouteFilterChange(pathIndex: number, routeFilter: SelectBoxModel) {
    const newPath = [];
    for (let i = 0; i < pathIndex; i++) {
      newPath.push(this.routePath[i]);
    }
    if (routeFilter.key !== StatusLayerSwitcherComponent.EMPTY_ROUTE_FILTER_KEY) {
      newPath.push(routeFilter.key);
    }
    this.settingsService.setStringValue(SettingsService.ROUTE_STATUS_PATH, newPath.join(':::'));
    this.router.navigate([`/${RootRoute.MAIN}`], {
      queryParamsHandling: 'merge',
    });
  }

  private updateRoutesManager() {
    if (this.routePath.length === 0) {
      this.routeManager.updateRouteFilter(
          null,
          null,
      );
    } else {
      const routeConfigPath = this.routePath.slice(1);
      this.routeManager.updateRouteFilter(
          +this.routePath[0],
          routeConfigPath,
      );
    }
  }

  // make sure that route path contains route configuration ID
  private updateRoutePath() {
    if (!!this.routeItems && !!this.routePath) {
      if (this.routePath.length === 0) {
        if (this.routeItems.length > 0) {
          // if routePath is empty then set the first route config
          const firstRouteConfigValue = this.routeItems[0].value;
          this.routePath = [firstRouteConfigValue];
          this.settingsService.setStringValue(SettingsService.ROUTE_STATUS_PATH, firstRouteConfigValue);
        }
      }
    }
  }
}
