import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {JsonApiResponse} from '../../shared/models/JsonApiResponse';
import {environment} from '../../../environments/environment';
import {HttpErrorHandler} from '../../http.error.handler';
import {UpdateUserPasswordModel, CsvUserImportRecord, UserModel, UserModelView} from '../../shared/models/User';
import {Page} from '../../shared/models/Page';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  constructor(private http: HttpClient) { }

  getUsers(pageIndex: number, pageSize: number, sort?: string, roles?: string[], rolesOp?: string, search?: string) {
    const params = {
      page: pageIndex,
      size: pageSize,
    };
    if (roles && roles.length > 0) {
      params['roles'] = roles.join(':::');
    }
    if (rolesOp && rolesOp.length > 0) {
      params['rolesOp'] = rolesOp;
    }
    if (search && search.length > 0) {
      params['search'] = search;
    }
    if (sort && sort.length > 0) {
      params['sort'] = sort;
    }
    return this.http.get<JsonApiResponse<Page<UserModelView>>>(`${environment.services.service}v2/configuration/user/list`,
      {params})
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      );
  }

  getSupervisorList() {
    return this.http.get<JsonApiResponse<UserModel[]>>(`${environment.services.service}v1/configuration/user/list`,
        {})
        .pipe(
            catchError(HttpErrorHandler.handleError) // then handle the error
        );
  }

  getUser(email: string) {
    return this.http.get<JsonApiResponse<UserModel>>(`${environment.services.service}v1/configuration/user/${email}`)
        .pipe(
            catchError(HttpErrorHandler.handleError) // then handle the error
        );
  }

  deleteUser(email: string) {
    return this.http.delete(`${environment.services.service}v1/configuration/user/${email}`)
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      ).toPromise();
  }

  updateUser(userUpdate: UserModel) {
    return this.http.put<JsonApiResponse<UserModelView>>(`${environment.services.service}v2/configuration/user`, userUpdate)
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      );
  }

  updatePassword(userUpdate: UpdateUserPasswordModel): Promise<JsonApiResponse<UserModel>> {
    return this.http.put<JsonApiResponse<UserModel>>(`${environment.services.service}v1/configuration/user/password`, userUpdate)
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      ).toPromise();
  }

  promoteUser(emailToChange: string, userUpdate: UserModel) {
    return this.http.put<JsonApiResponse<UserModel>>(`${environment.services.service}v1/configuration/user/${emailToChange}`, userUpdate)
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      );
  }

  createUser(user: UserModel): Promise<JsonApiResponse<UserModelView>> {
    return this.http.post<JsonApiResponse<UserModelView>>(`${environment.services.service}v2/configuration/user`, user)
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      ).toPromise();
  }

  uploadAndVerifyCsvUserImport(file: File): Promise<JsonApiResponse<CsvUserImportRecord[]>> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return firstValueFrom(this.http.post<JsonApiResponse<CsvUserImportRecord[]>>(`${environment.services.service}v1/configuration/user/import/process-spreadsheet-file`, formData)
        .pipe(
            catchError(HttpErrorHandler.handleError) // then handle the error
        ));
  }

  uploadAndSaveCsvUserImport(userImportData: CsvUserImportRecord[]): Promise<JsonApiResponse<any>> {
    return firstValueFrom(this.http.put<JsonApiResponse<number>>(`${environment.services.service}v1/configuration/user/import`, userImportData)
        .pipe(
            catchError(HttpErrorHandler.handleError) // then handle the error
        ));
  }
}
