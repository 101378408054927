<h2 mat-dialog-title>Add vehicles</h2>
<form>
	<mat-dialog-content style="position:relative;">
		<div *ngIf="cartegraphEnabled === null"
			 style="display: flex; align-content: center; justify-content: center; padding: 16px;">
			<mat-spinner></mat-spinner>
		</div>
		<mat-tab-group color="accent"
					   (selectedIndexChange)="handleSelectedTabChange($event)"
					   *ngIf="cartegraphEnabled === false">
			<mat-tab label="Add manually">
				<div class="mt-15">
					<app-create-vehicle #createVehicleComponent [data]="data" />
				</div>
			</mat-tab>
			<mat-tab label="Import">
				<app-import-vehicles #importVehiclesComponent />
			</mat-tab>
		</mat-tab-group>
		<app-import-vehicles-cartegraph #importVehiclesCartegraphComponent *ngIf="cartegraphEnabled === true" />

		<app-ui-blocking-layer *ngIf="isWorking" />

		<mat-progress-bar mode="indeterminate" *ngIf="isWorking"
						  style="position: absolute;bottom: 0;left: 0;right: 0"></mat-progress-bar>

		@if (saveError) {
			<mat-error>
				{{saveError}}
			</mat-error>
		}
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-stroked-button mat-dialog-close [disabled]="isWorking">Cancel</button>
		<button mat-flat-button color="primary"
				type="submit"
				[disabled]="!isSaveButtonEnabled()"
				(click)="performSave()">
					@if (isImportOperation()) {
						Import Vehicles
					} @else {
						Add Vehicle
					}
				</button>
	</mat-dialog-actions>
</form>
