<div #overlaymap class="map">
    <div class="loading-message-wrapper" *ngIf="loadingContent">
        <div class="loading-message">
            <mat-spinner [diameter]="16" color="accent"></mat-spinner>
            <span>Loading map content ...</span>
        </div>
    </div>
    <div class="basemap-toggle-wrapper">
      <app-basemap-toggle
      ></app-basemap-toggle>
    </div>
</div>

