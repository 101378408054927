import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {VehiclesService} from '../../../data/vehicles/vehicles.service';
import {VehicleCategoryModel} from '../../models/vehicle';
import {ActivatedRoute, Router} from '@angular/router';
import {MultiSelectComponent, MultiSelectFilter} from '../multi-select-component';
import {ToastService} from '../../services/toast.service';

/**
 * This component allows to create a multi-select filter of Vehicle Group entities.
 * It either emits 'undefined' value if no filter is set or an array of entities.
 *
 * In addition, it saves the state of the filter to URL params and browser's storage.
 */

@Component({
  selector: 'app-vehicle-group-filter',
  templateUrl: './vehicle-group-filter.component.html',
  styleUrls: ['./vehicle-group-filter.component.scss']
})
export class VehicleGroupFilterComponent extends MultiSelectComponent<VehicleCategoryModel> implements OnInit {

  SETTINGS_KEY = 'selectedVehicleGroupIds';
  URL_PARAM_NAME = 'vehicleGroupIds';
  USE_FULLY_CHECKED_AS_DEFAULT = false;

  @Input() origin;
  @Output() filterChanged = new EventEmitter<MultiSelectFilter<VehicleCategoryModel>>();

  constructor(
      protected router: Router,
      protected route: ActivatedRoute,
      private vehicleService: VehiclesService,
      private toast: ToastService,
  ) {
    super(router, route);
  }

  ngOnInit() {
    this.vehicleService.getVehicleCategories().then(response => {
      this.initComponent(response.data);
    }).catch(error => {
      console.log(error);
      this.toast.longer('Error while loading vehicle groups!');
    });
  }

  getLabel(item: VehicleCategoryModel): string {
    return !!item.title ? item.title : item.name;
  }

  onApply() {}

  openMenu() {}
}
