import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {LngLatBounds, Map} from 'maplibre-gl';
import {Observable, Subscription} from 'rxjs';
import {ConfigurationModel} from '../../models/configuration.model';
import {ConfigurationService} from '../../../configuration/configuration.service';
import {debounceTime} from 'rxjs/operators';
import {MapContent} from './model/MapContent';
import {MapTools} from '../../tools/MapTools';
import {environment} from '../../../../environments/environment';
import {MapLayersManager} from '../map-viewer/map-layers-manager';
import {EmptyMapContent} from './model/EmptyMapContent';
import { BaseMapType, ISettingKeyValuePair, SettingsService } from '../../../configuration/settings.service';
import { BasemapToggleComponent } from './basemap-toggle/basemap-toggle.component';

@Component({
  selector: 'app-map-preview',
  templateUrl: './map-preview.component.html',
  styleUrls: ['./map-preview.component.scss']
})
export class MapPreviewComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  static readonly MAP_MARKER_IMAGE = 'address-marker-image';
  static readonly TRACK_ARROW_IMAGE = 'track-arrow-image';
  static readonly FLAG_IMAGE = 'flag-image';
  static readonly STAR_IMAGE = 'star-image';
  static readonly CAMERA_IMAGE = 'camera-image';

  @Input() nextContent: MapContent;

  @ViewChild('overlaymap') private mapContainer: ElementRef<HTMLElement>;
  @ViewChild(BasemapToggleComponent) private basemapSwitcher: BasemapToggleComponent;
  map: Map;

  private mapLoaded = false;
  loadingContent = true;
  private initialContent: MapContent = null;
  private content: MapContent = null;
  private mapStyle: string;

  private defaultBounds: LngLatBounds = new LngLatBounds([-105.5, 39], [-105.0, 40]);

  private tenantConfigurationSubscription: Subscription;
  private tenantConfiguration: ConfigurationModel;

  private mapResizeObserver: ResizeObserver;
  private mapResizeObserverSubscription: Subscription;
  private settingsSubscription: Subscription;

  BaseMapType = BaseMapType;

  constructor(
    private configurationService: ConfigurationService,
    private settingsService: SettingsService,
  ) { }

  ngOnInit(): void {
    this.initialContent = this.nextContent == null ? EmptyMapContent.instance : this.nextContent;
    this.tenantConfigurationSubscription = this.configurationService.sharedConfigurationModel.subscribe(tenantConfiguration => {
      if (tenantConfiguration) {
        this.tenantConfiguration = tenantConfiguration;
      }
    });

    this.settingsSubscription = this.settingsService.settingsChangedObservable.subscribe(
      (keyValuePair: ISettingKeyValuePair) => {
        this.onSettingsChanged(keyValuePair.key, keyValuePair.value);
      });
  }

  ngAfterViewInit(): void {
    if (!!this.tenantConfiguration) {
      const tenantConfiguration = this.tenantConfiguration;
      if (tenantConfiguration.region.length > 0) {
        this.defaultBounds = tenantConfiguration.region.reduce((bounds, latLngModel) => {
          return bounds.extend([latLngModel.lng, latLngModel.lat]);
        }, new LngLatBounds(tenantConfiguration.region[0], tenantConfiguration.region[0]));
      } else {
        console.warn('region in tenant configuration is empty - leaving default bounds');
      }

      const baseMapName = this.settingsService.getStringValue(
        SettingsService.BASE_MAP_LAYER_KEY
      );
      this.mapStyle = this.getStyleFromSettings(baseMapName);
    }

    this.initMap();
    this.subscribeToMapResize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const nextContentChange = changes['nextContent'] as SimpleChange;
    const nextContent = nextContentChange.currentValue as MapContent;

    if (this.isMapLoaded()) {
      this.loadContent(nextContent);
    } else {
      this.initialContent = nextContent;
    }
  }

  private loadContent(nextContent: MapContent) {
    this.loadingContent = true;
    this.content?.unload();
    this.content = nextContent == null ? EmptyMapContent.instance : nextContent;
    this.content.load(this.map);
    this.loadingContent = false;
  }

  ngOnDestroy(): void {
    this.settingsSubscription?.unsubscribe();
    this.content?.unload();
    this.tenantConfigurationSubscription?.unsubscribe();
    this.mapResizeObserver?.disconnect();
    this.mapResizeObserverSubscription?.unsubscribe();
  }

  private subscribeToMapResize() {
    const resizeObservable = new Observable<void>(subscriber => {
      this.mapResizeObserver = new ResizeObserver(entries => {
        entries.forEach(entry => {
          subscriber.next();
        });
      });
    });

    this.mapResizeObserverSubscription = resizeObservable
      .pipe(debounceTime(200))
      .subscribe({ next: () => this.map.resize() });

    this.mapResizeObserver.observe(this.mapContainer.nativeElement);
  }

  initMap() {
    this.map = new Map({
      container: this.mapContainer.nativeElement,
      style: !!this.mapStyle ? this.mapStyle : MapLayersManager.ACCUTERRA_WINTER_MAP_STYLE,
      bounds: this.defaultBounds,
      attributionControl: false,
    });

    this.map.on('load', () => {
      this.addImages();
      this.mapLoaded = true;
      this.loadContent(this.initialContent);
      this.initialContent = null;
    });

    this.map.on('click', (e) => {
      this.basemapSwitcher.closeSwitcher();
    });
  }

  private addImages() {
    // source: https://fonts.google.com/icons?selected=Material+Icons:menu
    MapTools.loadImage(
      this.map,
      MapPreviewComponent.MAP_MARKER_IMAGE,
      `${environment.base_href}assets/baseline_location_on_black_24dp.png`,
      { sdf: true });

    MapTools.loadImage(
      this.map,
      MapPreviewComponent.TRACK_ARROW_IMAGE,
      `${environment.base_href}assets/baseline_expand_less_black_24dp.png`,
      { sdf: false }
    );

    MapTools.loadImage(
      this.map,
      MapPreviewComponent.FLAG_IMAGE,
      `${environment.base_href}assets/baseline_flag_black_24dp.png`,
      { sdf: true }
    );

    MapTools.loadImage(
      this.map,
      MapPreviewComponent.STAR_IMAGE,
      `${environment.base_href}assets/star.png`,
      { sdf: true }
    );

    MapTools.loadImage(
      this.map,
      MapPreviewComponent.CAMERA_IMAGE,
      `${environment.base_href}assets/baseline_photo_camera_black_24dp.png`,
      { sdf: true }
    );
  }

  private updateMapStyle() {
    if (!!this.map) {
      this.map.setStyle(this.mapStyle);
      const that = this;
      this.map.once('styledata', (data) => {
        const waiting = () => {
          if (!that.map.isStyleLoaded()) {
            setTimeout(waiting, 200);
          } else {
            that.onMapStyleChange();
          }
        };
        waiting();
      });
    }
  }

  private isMapLoaded() {
    return this.map != null && this.mapLoaded;
  }

  private onSettingsChanged(key: string, value: any) {
    switch (key) {
      case SettingsService.BASE_MAP_LAYER_KEY:
        this.mapStyle = this.getStyleFromSettings(value);
        this.updateMapStyle();
        break;
      default: // other layers in configuration
        break;
    }
  }

  private onMapStyleChange() {
    this.loadingContent = true;
    this.content.load(this.map);
    this.loadingContent = false;
  }

  private getStyleFromSettings(baseMapName: string) {
    switch (baseMapName) {
      case BaseMapType.OUTDOORS:
        return this.tenantConfiguration?.useMetricSystem
          ? MapLayersManager.ACCUTERRA_OUTDOORS_M_MAP_STYLE
          : MapLayersManager.ACCUTERRA_OUTDOORS_MAP_STYLE;
      case BaseMapType.DARK:
        return this.tenantConfiguration?.useMetricSystem
          ? MapLayersManager.ACCUTERRA_DARK_M_MAP_STYLE
          : MapLayersManager.ACCUTERRA_DARK_MAP_STYLE;
      case BaseMapType.WINTER:
        return this.tenantConfiguration?.useMetricSystem
          ? MapLayersManager.ACCUTERRA_WINTER_M_MAP_STYLE
          : MapLayersManager.ACCUTERRA_WINTER_MAP_STYLE;
      case BaseMapType.IMAGERY:
      default:
        return this.tenantConfiguration?.useMetricSystem
          ? MapLayersManager.IMAGERY_M_MAP_STYLE
          : MapLayersManager.IMAGERY_MAP_STYLE;
    }
  }
}
