import {CameraConfiguration, Streaming, VehicleModel} from '../../../../../shared/models/vehicle.model';
import {VehiclesService} from '../../../../../data/vehicles/vehicles.service';
import {ToastService} from '../../../../../shared/services/toast.service';
import {Component, Inject, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FeatureFlagEnum} from '../../../../../shared/models/configuration.model';
import {AddOrImportVehicleResult, EditVehicleComponentInputData} from './vehicle.types';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogVehicleHardwareComponent} from './update-vehicle-hardware.component';
import {NamedId} from '../../../../../shared/models/NamedId';

@Component({
  selector: 'app-vehicle-hardware',
  templateUrl: './update-vehicle-camera.component.html',
  styleUrls: [
    '../dialogs-vehicle/dialog-components.scss',
    '../../../../settings/settings-fields.scss'
  ],
})
export class DialogVehicleCameraComponent implements OnInit, OnChanges {
  model: VehicleModel;
  headline = 'Dash Camera Integrations';
  isWorking = false;

  ui: {
    captureImageIntervals?: NamedId[];
    streamingDuration?: NamedId[];
    useCamera?: boolean;
    cameraRotation?: boolean;
    localImagesInterval?: boolean;
    streaming?: boolean;
    staticImagesInterval?: boolean;
  } = {
    captureImageIntervals: [
      {id: 1, name: '1 minute'},
      {id: 2, name: '2 minutes'},
      {id: 3, name: '3 minutes'},
      {id: 5, name: '5 minutes'},
      {id: 10, name: '10 minutes'},
      {id: 20, name: '20 minutes'},
      {id: 30, name: '30 minutes'}
    ],
    streamingDuration: [
      {id: 15, name: '15 seconds'},
      {id: 20, name: '20 seconds'},
      {id: 25, name: '25 seconds'},
      {id: 30, name: '30 seconds'},
      {id: 40, name: '40 seconds'},
      {id: 50, name: '50 seconds'},
      {id: 60, name: '60 seconds'}
    ]
  };

  FeatureFlagEnum = FeatureFlagEnum;

  protected isInitialized = false;

  constructor(
    private dialogRef: MatDialogRef<DialogVehicleHardwareComponent>,
    private vehicleService: VehiclesService,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) protected data: EditVehicleComponentInputData
  ) {
    this.model = JSON.parse(JSON.stringify(this.data.model));
  }

  save(): Promise<AddOrImportVehicleResult> {
    this.isWorking = true;
    const disableCloseStateToRestore = this.dialogRef.disableClose;
    this.dialogRef.disableClose = true;

    return new Promise((resolve, reject) => {
      this.vehicleService.updateVehicle(this.model.id, this.model)
        .then((result) => {
          const saveResult: AddOrImportVehicleResult = {
            type: 'singleVehicle',
            data: result.data,
          };
          this.dialogRef.close(saveResult);
        })
        .catch((error) => {
          this.toastService.short('Update failed');
        }).finally(() => {
        this.isWorking = false;
        this.dialogRef.disableClose = disableCloseStateToRestore;
      });
    });
  }

  ngOnInit() {
    this.initFromData(this.model);
    this.isInitialized = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('data')) {
      if (changes.data.firstChange) {
        this.model = JSON.parse(JSON.stringify(this.data.model));
        this.initFromData(this.model);
      }
    }
  }

  protected initFromData(model: VehicleModel) {
    // camera
    this.ui.useCamera = !!model.cameraConfiguration;

    // camera rotation
    this.ui.cameraRotation = !!model.cameraConfiguration?.rotation;

    // local image capture
    this.ui.localImagesInterval = model.cameraConfiguration?.localRecording?.captureImageInterval > 0;

    // video streaming
    this.ui.streaming = model.cameraConfiguration?.streaming?.captureStreamDuration > 0
      || model.cameraConfiguration?.streaming?.captureImageInterval > 0
      || model.cameraConfiguration?.streaming?.observationCapture === true;

    // image streaming
    this.ui.staticImagesInterval = model.cameraConfiguration?.streaming?.captureImageInterval > 0;
  }

  canBeSaved(): boolean {
    if (!this.isInitialized) {
      return false;
    }
    const isInvalid = this.cameraConfigurationInvalid();
    return !isInvalid;
  }

  hasFeatureFlag(featureFlag: string): boolean {
    return this.data.configuration.featureFlags.find(value => value.isEnabled && value.name === featureFlag) !== undefined;
  }

  onCameraChange() {
    if (this.ui.useCamera) {
      this.model.cameraConfiguration = new CameraConfiguration();
    } else {
      this.model.cameraConfiguration = null;
    }
  }

  onCameraRotationChange() {
    this.model.cameraConfiguration.rotation = !!this.ui.cameraRotation ? 180 : 0;
  }

  onStreamingChange() {
    if (this.ui.streaming) {
      this.model.cameraConfiguration.streaming = new Streaming();
      this.model.cameraConfiguration.streaming.captureStreamDuration = this.ui.streamingDuration[0].id;
    } else {
      this.model.cameraConfiguration.streaming = new Streaming();
    }
  }

  onCamStreamingImageIntervalChange() {
    this.model.cameraConfiguration.streaming.captureImageInterval = !!this.ui.staticImagesInterval ? 1 : null;
  }

  onLocalImageChange() {
    this.model.cameraConfiguration.localRecording.captureImageInterval = !!this.ui.localImagesInterval ? 1 : null;
  }

  cameraConfigurationInvalid() {
    return (!!this.model.cameraConfiguration?.localRecording &&
      (this.model.cameraConfiguration.localRecording.captureImageInterval > 30
        || this.model.cameraConfiguration.localRecording.captureImageInterval < 0)
    );
  }
}


