<div class="shift-report-container">
    <div class="report-menu">
        <div class="report-name-and-desc">
            <div class="report-name">
                Device Report
            </div>
            <div class="report-description">
                See devices information
            </div>
        </div>

        <div class="report-menu__actions">
            <!-- todo: add csv and pdf export -->

            <app-filter-button [appliedFiltersCount]="appliedFiltersCount"
                               [active]="showingFilterBar"
                               (toggled)="toggleFilter()"
            ></app-filter-button>

            <button
                    mat-icon-button
                    (click)="closeInsight()"
                    [matTooltip]="'Close'"
            >
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <app-composite-filter-bar (onSearch)="doSearch($event)"
                              (appliedFiltersChanged)="appliedFiltersCount = $event"
                              [showBar]="showingFilterBar"
    >
      <app-text-search-composite [title]="'Phone'" [id]="'phone'"></app-text-search-composite>
      <app-text-search-composite [title]="'IMEI'" [id]="'imei'"></app-text-search-composite>
      <app-text-search-composite [title]="'S/N'" [id]="'sn'"></app-text-search-composite>
    </app-composite-filter-bar>

    <div class="report__table__wrapper"
         [ngClass]="{'report-table-with-filter-bar': showingFilterBar}"
    >
        <app-device-report-table
                [configuration]="configuration"
                [dataSource]="dataSource"
                (sortChangedEvent)="loadReport()"
                (scrolledToBottomEvent)="extendReport()"
        >
        </app-device-report-table>
    </div>
</div>
