import { FilterSpecification, Map } from 'maplibre-gl';
import {MapContentLayer} from '../MapContentLayer';
import {MapContentSource} from '../MapContentSource';
import {TrackStyles} from '../../../../../configuration/model/TrackStyles';
import {LayerSpecification, LineLayerSpecification} from 'maplibre-gl';
import {MapFilters} from '../../../../../configuration/map-filters';
import {ActivityFilter} from '../../../../../configuration/settings.service';
import { ShiftWithDriverAndVehicleModel } from '../../../../models/shift.model';
import moment from 'moment';
import { MapTools } from '../../../../tools/MapTools';
import { LineString } from 'geojson';

export class ShiftTrackLayer extends MapContentLayer {

  private filterFrom = 0;
  private filterTo = 100;

  private readonly shiftStart: number;
  private readonly shiftLength: number;

  constructor(
    source: MapContentSource,
    private trackStyles: TrackStyles,
    private shift?: ShiftWithDriverAndVehicleModel, // if null, filtering by time not supported
  ) {
    super('shift-track-layer', source);
    this.shiftStart = !!shift ? moment(shift.start).unix() : null;
    this.shiftLength = !!shift ? moment(shift.end).diff(moment(shift.start), 'seconds') : null;
  }

  toLayerSpecification(): LayerSpecification {
    return {
      id: this.layerId,
      type: 'line',
      source: this.sourceRef.sourceId,
      filter: MapFilters.getShiftGeoJsonFilter(
        ActivityFilter.NONE, this.getAbsTime(this.filterFrom), this.getAbsTime(this.filterTo)
      ),
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': this.trackStyles.shiftPlowNormal.color,
        'line-opacity': this.trackStyles.shiftPlowNormal.opacity,
        'line-width': this.trackStyles.shiftPlowNormal.width,
      }
    } as LineLayerSpecification;
  }

  filterByTime(map: Map, timeFrom: number, timeTo: number) {
    this.filterFrom = timeFrom;
    this.filterTo = timeTo;

    map.setFilter(
      this.layerId,
      MapFilters.getShiftGeoJsonFilter(
        ActivityFilter.NONE, this.getAbsTime(this.filterFrom), this.getAbsTime(this.filterTo)
      ) as FilterSpecification,
    );
  }

  zoomToFeatures(map: Map, zoomLevel: number = 14) {
    // expecting linestring features with one or more coordinates
    if (this.sourceRef.features.length > 1 ||
      (this.sourceRef.features.length === 1 && (this.sourceRef.features[0].geometry as LineString)?.coordinates?.length > 1)
    ) {
      MapTools.zoomToFeatures(map, this.sourceRef.features);
    }
  }

  private getAbsTime(relativePosition: number): number {
    // unknown start or length
    if (!this.shiftStart || !this.shiftLength) {
      return null;
    }
    // do not filter start or end at all
    if (relativePosition === 0 || relativePosition === 100) {
      return null;
    }
    return Math.round(this.shiftStart + relativePosition / 100 * this.shiftLength);
  }
}
