import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {VehicleInstallationService} from '../../../../data/vehicle-installation/vehicle-installation.service';
import {VehicleInstallationModel} from '../../../../shared/models/vehicle-installation.model';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {saveAs} from 'file-saver-es';

@Component({
  selector: 'app-manage-vehicle-installation',
  templateUrl: './manage-vehicle-installation.component.html',
})
export class ManageVehicleInstallationComponent implements OnInit, AfterViewInit {

  isLoading = false;
  totalInstalls = 0;

  dataSource: MatTableDataSource<VehicleInstallationModel[]> = new MatTableDataSource([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  displayedColumns: string[] = [
    'id',
    'startTimestamp',
    'endTimestamp',
    'vehicleReference',
    'usbAvlDeviceLmuId',
    'usbAvlDevice',
    'usbDigitalDevice',
    'installer',
    'note'
  ];

  constructor(private vehicleInstallationService: VehicleInstallationService) {
  }

  ngOnInit() {
  }


  ngAfterViewInit() {
    this.loadData();
  }

  loadData() {
    this.toggleLoading();
    this.vehicleInstallationService.getVehicleInstallations(
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.getSort(),
    ).toPromise().then(response => {
      this.dataSource.data = response.data.content;
      this.paginator.length = response.data.totalElements;
      this.paginator.pageSize = response.data.pageable.pageSize;
      this.totalInstalls = response.data.totalElements;
    }).catch(error => {
      console.error(error);
    }).finally(() => {
        this.toggleLoading();
      }
    );
  }


  loadCSVReport() {
    this.vehicleInstallationService.getVehicleInstallationsReportCSV(
    ).toPromise().then(response => {
      const blob = new Blob([response], {type: 'csv'});
      saveAs(blob, `vehicle-installation-report.csv`);
    }).catch(error => {
      console.error(error);
    });
  }

  page() {
    this.loadData();
  }

  sortBy() {
    this.loadData();
  }

  getSort(): string | undefined {
    if (!this.sort.active) {
      return undefined;
    }
    return `${this.sort.active},${this.sort.direction}`;
  }

  toggleLoading() {
    setTimeout(() => this.isLoading = !this.isLoading);
  }

}
