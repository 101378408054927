import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'shortDateOrTime'
})
export class ShortDateOrTimePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): string {
    if (value instanceof Date) {
      const dateTimeMoment = moment(value);
      if (dateTimeMoment.isBefore(moment().startOf('day'))) {
        return dateTimeMoment.format('M/D/YY');
      } else {
        return dateTimeMoment.format('LT');
      }
    } else {
      console.warn(`${value} not instance of Date!`);
    }
    return null;
  }
}
