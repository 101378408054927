import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {VehicleModel} from '../../../../shared/models/vehicle.model';
import {ConfigurationModel} from '../../../../shared/models/configuration.model';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {NamedId} from '../../../../shared/models/NamedId';
import {VehicleGroupModelWithVehicles} from './model/VehicleGroupModelWithVehicles';
import {ToastService} from '../../../../shared/services/toast.service';
import {DialogConfirmDeleteVehicleCategoryComponent} from './dialogs-vehicle-group/vehicle-group-dialog-components';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {VehicleMoveEventArgs} from './model/vehicle-move-event-args';

@Component({
  selector: 'app-manage-vehicles',
  templateUrl: './manage-vehicles.component.html',
  styleUrls: ['../../../settings/settings-fields.scss'],
})
export class ManageVehiclesComponent implements OnInit, OnDestroy {

  isLoading = true;
  allVehicleGroupsWithVehicles: VehicleGroupModelWithVehicles[] = [];
  allInspectionForms: NamedId[] = [];
  allObservationTypeGroups: NamedId[] = [];
  allAllowedRouteConfigs: NamedId[] = [];
  isImported = false;

  protected configuration: ConfigurationModel;
  private configurationSubscription: Subscription;

  /**
   * A function that is just a safe wrapper around the `getVehicle()` method.
   *
   * Its purpose is to limit the number of invocation of the `getVehicle()` method because that's quite expensive
   * and on each run produces a new array instance which is very ineffective is used as an @Input() parameter
   * in another component.
   */
  getVehicleCallback: () => VehicleModel[];

  constructor(public dialog: MatDialog,
              private toastService: ToastService,
              private configurationService: ConfigurationService,
              private router: Router,
  ) {
    // Initialize the callback in a way to always execute with the expected `this` reference.
    this.getVehicleCallback = ((that: ManageVehiclesComponent) => {
      return () => that.getVehicles();
    })(this);
  }

  ngOnInit() {
    this.configurationSubscription = this.configurationService.sharedConfigurationModel.subscribe(model => {
      if (model) {
        this.configuration = model;
        this.reloadAll();
      }
    });
    this.reloadAll();
  }

  ngOnDestroy() {
    this.configurationSubscription?.unsubscribe();
  }

  private reloadAll() {
    // this.isLoading = true;
    //
    // Promise.all([
    //   this.vehicleService.getVehicleCategories().toPromise(),
    //   this.vehicleService.getVehicles().toPromise(),
    //   this.inspectionFormsService.getForms(),
    //   this.observationManagementService.getObservationTypeGroups().toPromise(),
    //   this.routesService.getRouteConfigurations(),
    // ])
    //   .then(responses => {
    //     const [
    //       categoriesResponse,
    //       vehiclesResponse,
    //       inspectionFormsResponse,
    //       observationGroupsResponse,
    //       routeConfigsResponse
    //     ] = responses;
    //
    //     this.isImported = this.configurationService
    //       .hasFeatureFlag(this.configuration?.featureFlags, FeatureFlagEnum.CartegraphIntegration);
    //
    //     this.allVehicleGroupsWithVehicles = [];
    //     for (const category of categoriesResponse.data) {
    //       const group = VehicleGroupModelWithVehicles.fromDtoAndAllVehicles(category, vehiclesResponse.data);
    //       this.allVehicleGroupsWithVehicles.push(group);
    //     }
    //     this.allVehicleGroupsWithVehicles.sort(VehicleGroupModelWithVehicles.vehicleGroupCompareFn);
    //
    //     this.allInspectionForms = inspectionFormsResponse.contents.map(value => new NamedId(value.journalId, value.title));
    //     // this.allInspectionForms.splice(0, 0, new NamedId(null, 'None')); // add default empty option
    //     this.allObservationTypeGroups = observationGroupsResponse.data.map((value: ObservationTypeGroup) => {
    //       return {id: value.id, name: value.name};
    //     });
    //     this.allAllowedRouteConfigs = routeConfigsResponse.data.map((value: RouteConfigurationWithSchema) => {
    //       return {id: value.id, name: value.name};
    //     });
    //   })
    //   .catch(error => {
    //     const msg = 'Error while loading data from server';
    //     this.toastService.long(msg);
    //     console.error(`${msg} :: ${error}`);
    //   })
    //   .finally(() => {
    //     this.isLoading = false;
    //   });
  }

  deleteVehicleGroup(vehicleGroupId: number) {
    const categoryToDelete = this.allVehicleGroupsWithVehicles.find(group => group.id === vehicleGroupId);
    const index = this.allVehicleGroupsWithVehicles.indexOf(categoryToDelete);
    const dialogRef = this.dialog.open(DialogConfirmDeleteVehicleCategoryComponent, {
      width: '450px',
      data: categoryToDelete
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult !== undefined && dialogResult) {
        this.allVehicleGroupsWithVehicles.splice(index, 1);
      }
    });
  }

  onVehicleDeleted(deletedVehicle: VehicleModel) {
    const group = this.allVehicleGroupsWithVehicles
      .find(group => group.id === deletedVehicle.groupId);

    if (group) {
      if (this.removeVehicleFromGroup(deletedVehicle.id, group)) {
        this.forceRefreshGroupVehicles(group);
        this.toastService.short('Vehicle deleted.');
      }
    } else {
      console.warn('No group found for the deleted vehicle.');
    }
  }

  onVehicleMoved(vehicleMovedArgs: VehicleMoveEventArgs) {
    const fromGroup = this.allVehicleGroupsWithVehicles
      .find((group) => group.id === vehicleMovedArgs.fromGroupId);
    if (!fromGroup) {
      throw Error('The fromGroup not found.');
    }
    this.removeVehicleFromGroup(vehicleMovedArgs.vehicle.id, fromGroup);
    this.forceRefreshGroupVehicles(fromGroup);

    const toGroup = this.allVehicleGroupsWithVehicles
      .find((group) => group.id === vehicleMovedArgs.toGroupId);
    if (!toGroup) {
      throw Error('The toGroup not found.');
    }

    toGroup.vehicles.push(vehicleMovedArgs.vehicle);
    toGroup.vehicles.sort(VehicleModel.vehicleCompareFn);
    this.forceRefreshGroupVehicles(toGroup);
  }

  // showAddOrImportVehicleDialog(vehicleGroup: VehicleGroupModelWithVehicles) {
  //   const emptyVehicleModel = new VehicleModel();
  //   emptyVehicleModel.groupId = vehicleGroup.id;
  //
  //   const dialogData: EditVehicleComponentInputData = {
  //     model: emptyVehicleModel,
  //     configuration: this.configuration,
  //     vehicles: this.getVehicles(),
  //     isImported: this.isImported,
  //     defaultMapColor: this.configurationService.trackStyles.liveMapPlowLive.color
  //   };
  //
  //   const dialogRef = this.dialog.open(AddOrImportVehicleDialogComponent, {
  //     width: '550px',
  //     data: dialogData
  //   });
  //
  //   dialogRef.afterClosed().subscribe((dialogResult: AddOrImportVehicleResult) => {
  //     if (dialogResult !== undefined && dialogResult) {
  //       switch (dialogResult.type) {
  //         case 'singleVehicle':
  //           const newVehicle = dialogResult.data;
  //           vehicleGroup.vehicles.push(newVehicle);
  //           this.forceRefreshGroupVehicles(vehicleGroup);
  //           break;
  //         case 'vehicleImport':
  //           // We do not know what exactly has changed. Let's refresh everything...
  //           this.reloadAll();
  //           break;
  //       }
  //     }
  //   });
  // }

  // showAddNewVehicleGroupDialog() {
  //   const dialogRef = this.dialog.open(DialogCreateVehicleCategoryComponent, {
  //     width: '450px',
  //     data: {
  //       model: new VehicleGroupModel(),
  //       isImported: false
  //     } as CreateVehicleGroupDialogInput
  //   });
  //
  //   dialogRef.afterClosed().subscribe(dialogResult => {
  //     if (dialogResult) {
  //       const newCategory = dialogResult as VehicleCategoryModel;
  //       const newVehicleCategory = VehicleGroupModelWithVehicles.fromDto(newCategory);
  //       this.allVehicleGroupsWithVehicles.push(newVehicleCategory);
  //       this.allVehicleGroupsWithVehicles.sort(VehicleGroupModelWithVehicles.vehicleGroupCompareFn);
  //     }
  //   });
  // }

  private forceRefreshGroupVehicles(group: VehicleGroupModelWithVehicles) {
    // Make Angular notice the changes in the arrays by making their shallow copies
    group.vehicles = group.vehicles.slice();
    this.allVehicleGroupsWithVehicles = this.allVehicleGroupsWithVehicles.slice();
  }

  private removeVehicleFromGroup(idToRemove: number, group: VehicleGroupModelWithVehicles): boolean {
    const index = group.vehicles.findIndex(groupVehicle => groupVehicle.id === idToRemove);
    if (index >= 0) {
      group.vehicles.splice(index, 1);
      return true;
    } else {
      console.warn('Vehicle not found in the group.');
      return false;
    }
  }

  getVehicles(): VehicleModel[] {
    if (!this.allVehicleGroupsWithVehicles.length) {
      return [];
    }

    let vehicles: VehicleModel[] = [];

    this.allVehicleGroupsWithVehicles.forEach(category => {
      if (category.vehicles.length) {
        vehicles = vehicles.concat(category.vehicles);
      }
    });

    return vehicles;
  }

  editVehicleGroup(group: VehicleGroupModelWithVehicles) {
    console.log('editVehicleGroup', group);
    this.router.navigate(['manage-vehicles', 'group', group.id]);
  }
}
