export class VehicleGroupModel {
    id: number;
    name: string;
    title: string;
    useDvir: boolean;
    allowRouteSelfAssign: boolean;
    preTripForm: any;
    postTripForm: any;
    showVehicles: boolean;
    showVehicleHistory: boolean;
    showVehicleObservations: boolean;
    maxStationaryTime?: number; // value in minutes
    observationTypeGroupIds: number[] = [];
    allowedRouteConfigIds: number[] = [];
    allowReportObservations = false;

}
