<app-card preCardTitle="My Account">
  <div *appCardContent class="settings-header-container mb-16">
    <div class="settings-header-title">
      <p>Account Information</p>
      <div class="settings-header-subtitle"></div>
    </div>
    <div class="settings-header-actions">
      <button mat-stroked-button (click)="updateUser()">Edit</button>
    </div>
  </div>

  <div *appCardContent class="my-16">
    <div class="flex-row ops-form">
      <div class="flex-row-left">
        Account ID
      </div>
      <div class="field-with-right-buttons">
        <span *ngIf="customerId">{{ customerId }}</span>
      </div>
    </div>

    <div class="flex-row ops-form">
      <div class="flex-row-left">
        Name
      </div>
      <div class="field-with-right-buttons">
        <span>{{ user?.givenName}} {{ user?.familyName }}</span>
      </div>
    </div>

    <div class="flex-row ops-form">
      <div class="flex-row-left">
        Email
      </div>
      <div class="field-with-right-buttons">
        <span>{{ user?.email }}</span>
      </div>
    </div>

    <div class="flex-row ops-form">
      <div class="flex-row-left">
        Phone
      </div>
      <div class="field-with-right-buttons">
        <span>{{ user?.phoneNumber }}</span>
      </div>
    </div>

    <div class="flex-row ops-form">
      <div class="flex-row-left">
        Role
      </div>
      <div class="field-with-right-buttons">
        <span *ngIf="!!user">{{ listRoles() }}</span>
      </div>
    </div>
  </div>

  <div *appCardContent class="settings-header-container mt-16">
    <div class="settings-header-title">
      <p>Password Reset</p>
      <div class="settings-header-subtitle"></div>
    </div>
    <div class="settings-header-actions">
      <button mat-stroked-button (click)="changePassword()">Reset Password</button>
    </div>
  </div>
</app-card>
