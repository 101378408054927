import {Component, ContentChildren, EventEmitter, Input, Output, QueryList} from '@angular/core';
import {MatIconButton} from '@angular/material/button';
import {MatCard, MatCardActions, MatCardContent} from '@angular/material/card';
import {MatDivider} from '@angular/material/divider';
import {NgIf, NgTemplateOutlet} from '@angular/common';
import {CardContent, CardContentRawDirective} from './card-content.directive';
import {MatIcon} from '@angular/material/icon';

/**
 * A component that takes creates a standard `<mat-card>` element with a leading `<h2>` tag as a title.
 * The `<mat-card>` will host one or more `<mat-card-content>` separated by a `<mat-divider>` based
 * on the number of provided `<xxx *appCardContent>` child elements.
 *
 * See `AppCardContent` directive.
 */
@Component({
  selector: 'app-card',
  standalone: true,
  imports: [
    MatCard,
    MatCardContent,
    MatDivider,
    NgTemplateOutlet,
    NgIf,
    MatIconButton,
    MatIcon,
    MatCardActions,
  ],
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss'
})
export class CardComponent {
  @ContentChildren(CardContent, {descendants: true}) content: QueryList<CardContent>;

  @Input() preCardTitle: string;

  @Input() inCardTitle: string;
  @Input() icon: string;
  @Input() iconDisabled = false;

  @Output() iconClicked = new EventEmitter<void>();

  protected readonly CardContentRawDirective = CardContentRawDirective;
}
