import {ActivatedRoute, Router} from '@angular/router';
import {MainRoute, RootRoute} from '../../shared/models/angular-routing';

export abstract class CloseableAndFilterableInsight {

    protected showingFilterBar = false;
    protected appliedFiltersCount: number;

    protected constructor(
        protected router: Router,
        protected activatedRoute: ActivatedRoute,
    ) {}

    protected closeInsight() {
        const queryParams = this.activatedRoute.snapshot.queryParams;
        if (!!queryParams['vehicleId']) {
            // back to vehicle detail
            this.router.navigate(
                [`/${RootRoute.MAIN}`, MainRoute.VEHICLE, +queryParams['vehicleId']],
                {
                    queryParamsHandling: 'merge',
                    queryParams: {
                        vehicleId: undefined,
                    }
                },
            );
            return;
        }
        if (!!queryParams['routeId'] && !!queryParams['routePath']) {
            // back to route detail
            this.router.navigate(
                [`/${RootRoute.MAIN}`, MainRoute.ROUTE, queryParams['routePath'], 'route-id', queryParams['routeId']],
                {
                    queryParamsHandling: 'merge',
                    queryParams: {
                        routeId: undefined,
                        routePath: undefined,
                    }
                },
            );
            return;
        }
        this.router.navigate(
            [`/${RootRoute.MAIN}`, MainRoute.INSIGHTS],
            {
                queryParamsHandling: 'merge',
            },
        );
    }

    protected toggleFilter() {
        this.showingFilterBar = !this.showingFilterBar;
    }
}
