import {Component} from '@angular/core';
import { MapStyles } from 'src/app/configuration/map-styles';
import {MatDialog} from '@angular/material/dialog';
import {GpsTracksConfigComponent, GpsTracksSettings} from '../gps-tracks-config/gps-tracks-config.component';
import {SettingsService} from '../../../../configuration/settings.service';

@Component({
  selector: 'app-gps-tracks-legend',
  templateUrl: './gps-tracks-legend.component.html',
  styleUrls: ['./gps-tracks-legend.component.scss']
})
export class GpsTracksLegendComponent {

  MapStyles = MapStyles;

  constructor(
      public dialog: MatDialog,
      private settingsService: SettingsService,
  ) {
  }

  openConfiguration() {
    const data = {
      showRecent: this.settingsService.getBooleanValue(SettingsService.PLOW_TRACK_SHOW_RECENT),
      timeLimit: +this.settingsService.getStringValue(SettingsService.PLOW_TRACK_TIME_FILTER),
    } as GpsTracksSettings;

    const dialogRef = this.dialog.open(GpsTracksConfigComponent, {
      maxWidth: '80vw',
      maxHeight: '85vh',
      width: '500px',
      height: '300px',
      data: Object.assign({}, data),
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined && result) {
        const settings = result as GpsTracksSettings;
        if (settings.showRecent !== data.showRecent) {
          this.settingsService.setBooleanValue(SettingsService.PLOW_TRACK_SHOW_RECENT, settings.showRecent);
        }
        if (settings.timeLimit !== data.timeLimit) {
          this.settingsService.setStringValue(SettingsService.PLOW_TRACK_TIME_FILTER, String(settings.timeLimit));
        }
      }
    });
  }
}
