import { Component, OnDestroy, OnInit } from '@angular/core';
import { RightPanel } from '../right-panel.class';
import { DrawerContent } from 'src/app/layouts/right-drawer/right-drawer.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigurationService } from '../../../../../configuration/configuration.service';
import { AssetsManagerService } from '../../../../../data/assets/assets-manager.service';
import { InsightsService } from '../../../../../data/insights/insights.service';
import { AssignmentStatistics, ShiftStatistics } from '../../../../../shared/models/insights.model';
import { ActionMenuItem, ActionMenuItemSubMenu } from '../../../../../shared/models/action-menu-item.class';
import { ShortDateOrTimePipe } from '../../../../../shared/formatting/short-date-or-time.pipe';
import { DurationPipe } from '../../../../../shared/formatting/duration.pipe';
import { DistancePipe } from '../../../../../shared/formatting/distance.pipe';
import { WeightPipe } from '../../../../../shared/formatting/weight.pipe';
import { SpreaderMassPipe } from '../../../../../shared/formatting/spreader-mass.pipe';
import { SpreaderLiquidPipe } from '../../../../../shared/formatting/spreader-liquid.pipe';
import { VolumePipe } from '../../../../../shared/formatting/volume.pipe';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

class InsightDescriptor {
  insightId: string;
  pinned: boolean;
  sortOrder: number;
  sourceClass: string;
  // tslint:disable-next-line:ban-types
  transformFn: Function;

  public static insightCompareFn = (a: InsightDescriptor, b: InsightDescriptor): number => {
    // Sort by pinned first (true comes before false)
    if (a.pinned && !b.pinned) {
      return -1;
    }
    if (!a.pinned && b.pinned) {
      return 1;
    }

    // If both have the same pinned value, sort by sortOrder (ascending)
    return a.sortOrder - b.sortOrder;
  }
}

enum InsightId {
  SHIFTS = 'shift_stats_shifts',
  BLADE_USE = 'shift_stats_blade_use',
  GRANULAR_USE = 'shift_stats_granular',
  LIQUID_USE = 'shift_stats_liquid',
  ASSIGNMENTS = 'assignment_stats',
}

@Component({
  selector: 'app-quick-insights',
  templateUrl: './quick-insights.component.html',
  styleUrl: './quick-insights.component.scss'
})
export class QuickInsightsComponent extends RightPanel implements OnInit, OnDestroy {

  static readonly PINNED_ITEMS_SETTINGS_KEY = 'quickInsightsPinnedItems';
  static readonly SORTED_ITEMS_SETTINGS_KEY = 'quickInsightsSortedItems';

  readonly sinceHoursItems: number[] = [12, 24, 36, 48, 72];
  sinceHours = 12;
  shiftStats: ShiftStatistics;
  assignmentStats: AssignmentStatistics;
  refreshTimer = null;
  readonly refreshInterval = 300; // 5 minutes

  placeholder: InsightDescriptor[] = [
    {
      insightId: InsightId.SHIFTS,
      pinned: false,
      sortOrder: 1,
      sourceClass: 'ShiftStatistics',
      transformFn: this.shiftStatsToShiftsItem.bind(this),
    },
    {
      insightId: InsightId.BLADE_USE,
      pinned: false,
      sortOrder: 2,
      sourceClass: 'ShiftStatistics',
      transformFn: this.shiftStatsToBladeUseItem.bind(this),
    },
    {
      insightId: InsightId.GRANULAR_USE,
      pinned: false,
      sortOrder: 3,
      sourceClass: 'ShiftStatistics',
      transformFn: this.shiftStatsToGranularSpreadingItem.bind(this),
    },
    {
      insightId: InsightId.LIQUID_USE,
      pinned: false,
      sortOrder: 4,
      sourceClass: 'ShiftStatistics',
      transformFn: this.shiftStatsToLiquidSpreadingItem.bind(this),
    },
    {
      insightId: InsightId.ASSIGNMENTS,
      pinned: false,
      sortOrder: 5,
      sourceClass: 'AssignmentStatistics',
      transformFn: this.assignmentStatsToCardItem.bind(this),
    },
  ];
  items: ActionMenuItem[] = [];

  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected configurationService: ConfigurationService,
    protected assetsManager: AssetsManagerService,
    private insightsService: InsightsService,
    private shortDateOrTimePipe: ShortDateOrTimePipe,
    private durationPipe: DurationPipe,
    private distancePipe: DistancePipe,
    private weightPipe: WeightPipe,
    private volumePipe: VolumePipe,
    private spreaderMassPipe: SpreaderMassPipe,
    private spreaderLiquidPipe: SpreaderLiquidPipe,
  ) {
    super(
      DrawerContent.QUICK_INSIGHTS,
      router,
      activatedRoute,
      configurationService,
      assetsManager,
    );
  }

  ngOnInit(): void {
    this.initialize();
    this.loadConfiguration();
    this.restoreDescriptorsState();
    this.scheduleRefresh();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
    this.cancelRefresh();
  }

  onAssetsUpdate() {
    // do nothing
  }

  onAssetChange() {
    // do nothing
  }

  onAssetAction() {
    // do nothing
  }

  onRouteAction() {
    // do nothing
  }

  onConfigurationLoad() {
    this.loadData();
  }

  onHoursFilterChange() {
    this.items = [];
    this.onConfigurationLoad();
  }

  existingPinnedItems(): boolean {
    return this.placeholder.some(descriptor => descriptor.pinned);
  }

  private loadData() {
    Promise.all([
      this.insightsService.findShiftStatistics(this.sinceHours).toPromise(),
      this.insightsService.findAssignmentStatistics(this.sinceHours).toPromise(),
    ]).then(results => {
      this.shiftStats = results[0].data;
      this.assignmentStats = results[1].data;
      this.updateItems();
    });
  }

  private async loadSource(sourceClass: string) {
    let sourceData: AssignmentStatistics | ShiftStatistics;
    if (sourceClass === 'ShiftStatistics') {
      const sourceDataResponse = await this.insightsService.findShiftStatistics(this.sinceHours).toPromise();
      sourceData = sourceDataResponse.data;
      this.shiftStats = sourceData;
    } else {
      const sourceDataResponse = await this.insightsService.findAssignmentStatistics(this.sinceHours).toPromise();
      sourceData = sourceDataResponse.data;
      this.assignmentStats = sourceData;
    }

    return sourceData;
  }

  private getCachedSource(sourceClass: string) {
    return sourceClass === 'ShiftStatistics'
      ? this.shiftStats
      : this.assignmentStats;
  }

  private shiftStatsToShiftsItem(sourceData: ShiftStatistics, pinned: boolean): ActionMenuItem {
    return new ActionMenuItem(
      InsightId.SHIFTS,
      'stars',
      'Shifts',
      `${sourceData.shiftCount} shifts
        ${sourceData.driverCount} drivers
        ${this.durationPipe.transform(sourceData.averageDuration)} average duration
        ${this.durationPipe.transform(sourceData.totalDuration)} total duration
        `,
      this.shortDateOrTimePipe.transform(new Date(sourceData.created * 1000)),
      `pinned_${pinned}`,
      () => false,
      () => this.togglePin(InsightId.SHIFTS),
      null,
      () => false,
      null,
      null,
      [
        this.getRefreshAction(InsightId.SHIFTS),
      ],
    );
  }

  private shiftStatsToBladeUseItem(sourceData: ShiftStatistics, pinned: boolean): ActionMenuItem {
    const useMetric = this.configuration.useMetricSystem;
    return new ActionMenuItem(
      InsightId.BLADE_USE,
      'stars',
      'Blade Use',
      `${this.distancePipe.transform(sourceData.distanceDriven, useMetric, true)} total
        ${this.distancePipe.transform(sourceData.distanceDriven - sourceData.distancePlowed, useMetric, true)} blade up
        ${this.distancePipe.transform(sourceData.distancePlowed, useMetric, true)} blade down
        ${(sourceData.distancePlowed / sourceData.distanceDriven * 100 || 0).toFixed(1)}% blade efficiency
        `,
      this.shortDateOrTimePipe.transform(new Date(sourceData.created * 1000)),
      `pinned_${pinned}`,
      () => false,
      () => this.togglePin(InsightId.BLADE_USE),
      null,
      () => false,
      null,
      null,
      [
        this.getRefreshAction(InsightId.BLADE_USE),
      ],
    );
  }

  private shiftStatsToGranularSpreadingItem(sourceData: ShiftStatistics, pinned: boolean): ActionMenuItem {
    const useMetric = this.configuration.useMetricSystem;
    return new ActionMenuItem(
      InsightId.GRANULAR_USE,
      'stars',
      'Granular Material Use',
      `${this.distancePipe.transform(sourceData.distanceSpreadGranular, useMetric, true)}
        ${this.weightPipe.transform(sourceData.materialUsedGranular, useMetric, true)}
        ${this.spreaderMassPipe.transform((sourceData.materialUsedGranular / sourceData.distanceSpreadGranular / 1000 || 0), useMetric, true)} average
        ${(sourceData.distanceSpreadGranular / sourceData.distanceDriven * 100 || 0).toFixed(1)}% total road ${useMetric ? 'km' : 'miles'}
        `,
      this.shortDateOrTimePipe.transform(new Date(sourceData.created * 1000)),
      `pinned_${pinned}`,
      () => false,
      () => this.togglePin(InsightId.GRANULAR_USE),
      null,
      () => false,
      null,
      null,
      [
        this.getRefreshAction(InsightId.GRANULAR_USE),
      ],
    );
  }

  private shiftStatsToLiquidSpreadingItem(sourceData: ShiftStatistics, pinned: boolean): ActionMenuItem {
    const useMetric = this.configuration.useMetricSystem;
    return new ActionMenuItem(
      InsightId.LIQUID_USE,
      'stars',
      'Liquid Material Use',
      `${this.distancePipe.transform(sourceData.distanceSpreadLiquid, useMetric, true)}
        ${this.volumePipe.transform(sourceData.materialUsedLiquid, useMetric, true)}
        ${this.spreaderLiquidPipe.transform((sourceData.materialUsedLiquid / sourceData.distanceSpreadLiquid / 1000 || 0), useMetric, true)} average
        ${(sourceData.distanceSpreadLiquid / sourceData.distanceDriven * 100 || 0).toFixed(1)}% total road ${useMetric ? 'km' : 'miles'}
        `,
      this.shortDateOrTimePipe.transform(new Date(sourceData.created * 1000)),
      `pinned_${pinned}`,
      () => false,
      () => this.togglePin(InsightId.LIQUID_USE),
      null,
      () => false,
      null,
      null,
      [
        this.getRefreshAction(InsightId.LIQUID_USE),
      ],
    );
  }

  private assignmentStatsToCardItem(sourceData: AssignmentStatistics, pinned: boolean): ActionMenuItem {
    const useMetric = this.configuration.useMetricSystem;
    return new ActionMenuItem(
      InsightId.ASSIGNMENTS,
      'stars',
      'Assignments',
      `${sourceData.assignmentCount} assignments
        ${sourceData.driverCount} drivers
        ${sourceData.vehicleCount} vehicles
        ${this.durationPipe.transform(sourceData.averageDuration)} average duration
        ${this.distancePipe.transform(sourceData.averageDistance / 1000, useMetric, false)} average ${useMetric ? 'kilometers' : 'miles'}
        `,
      this.shortDateOrTimePipe.transform(new Date(sourceData.created * 1000)),
      `pinned_${pinned}`,
      () => false,
      () => this.togglePin(InsightId.ASSIGNMENTS),
      null,
      () => false,
      null,
      null,
      [
        this.getRefreshAction(InsightId.ASSIGNMENTS),
      ],
    );
  }

  private getRefreshAction(insightId: string): ActionMenuItemSubMenu {
    return new ActionMenuItemSubMenu(
      'refresh',
      'Refresh',
      () => {
        this.refreshByInsightId(insightId);
      },
    );
  }

  // refresh source data for one card item
  private async refreshByInsightId(insightId: string) {
    const insightIndex = this.placeholder.findIndex(insight => insight.insightId === insightId);
    const insight = this.placeholder[insightIndex];
    const sourceData = await this.loadSource(insight.sourceClass);
    const item = insight.transformFn(sourceData, insight.pinned);
    this.items.splice(this.items.findIndex(item => item.id === insightId), 1, item);
  }

  // toggle pinned item
  private togglePin(insightId: string) {
    const insightIndex = this.placeholder.findIndex(insight => insight.insightId === insightId);
    const insight = this.placeholder[insightIndex];
    insight.pinned = !insight.pinned;
    this.saveDescriptorsState();
    this.updateItems();
    return insight.pinned;
  }

  // drag'n'drop - change order of insights
  drop(event: CdkDragDrop<ActionMenuItem[]>) {
    const insightIds = this.items.map(item => item.id);
    moveItemInArray(insightIds, event.previousIndex, event.currentIndex);
    insightIds.forEach((insightId, index) => {
      const insight = this.placeholder.find(insight => insight.insightId === insightId);
      insight.sortOrder = index + 1;
    });
    this.saveDescriptorsState();
    this.updateItems();
  }

  // update all card items
  private updateItems() {
    this.items = [];
    // copy array, sort and transform
    [...this.placeholder]
      .sort(InsightDescriptor.insightCompareFn)
      .forEach(insight => {
        const item = insight.transformFn(
          this.getCachedSource(insight.sourceClass),
          insight.pinned,
        );
        this.items.push(item);
      });
  }

  // cancel scheduled data refresh
  private cancelRefresh() {
    if (!!this.refreshTimer) {
      clearTimeout(this.refreshTimer);
      this.refreshTimer = null;
    }
  }

  // schedule all items refresh every 5 minutes
  private scheduleRefresh() {
    this.refreshTimer = setTimeout(() => {
      this.loadData();
      this.scheduleRefresh();
    }, this.refreshInterval * 1000);
  }

  // save state of insight descriptors into browser cache
  private saveDescriptorsState() {
    const sortOrders = this.placeholder.map(descriptor => descriptor.sortOrder).join(':::');
    if (sortOrders === '1:::2:::3:::4:::5') {
      localStorage.removeItem(QuickInsightsComponent.SORTED_ITEMS_SETTINGS_KEY);
    } else {
      localStorage.setItem(QuickInsightsComponent.SORTED_ITEMS_SETTINGS_KEY, sortOrders);
    }

    const pinnedItems = this.placeholder.map(descriptor => descriptor.pinned).join(':::');
    if (pinnedItems === 'false:::false:::false:::false:::false') {
      localStorage.removeItem(QuickInsightsComponent.PINNED_ITEMS_SETTINGS_KEY);
    } else {
      localStorage.setItem(QuickInsightsComponent.PINNED_ITEMS_SETTINGS_KEY, pinnedItems);
    }
  }

  // restore state of insight descriptors from browser cache
  private restoreDescriptorsState() {
    const pinnedItems = this.getCachedPinnedItems();
    if (!!pinnedItems) {
      pinnedItems.forEach((pinnedItem, index) => {
        if (index < this.placeholder.length) {
          this.placeholder[index].pinned = pinnedItem;
        }
      });
    }

    const sortedItems = this.getCachedSortedItems();
    if (!!sortedItems) {
      sortedItems.forEach((sortOrder, index) => {
        if (index < this.placeholder.length) {
          this.placeholder[index].sortOrder = sortOrder;
        }
      });
    }
  }

  // get cached settings - sorted insights
  private getCachedSortedItems(): number[] {
    const sortedItemsString = localStorage.getItem(
      QuickInsightsComponent.SORTED_ITEMS_SETTINGS_KEY
    );
    if (!!sortedItemsString) {
      return sortedItemsString.split(':::').map(value => +value);
    } else {
      return null;
    }
  }

  // get cached settings - pinned insights
  private getCachedPinnedItems(): boolean[] {
    const pinnedItemsString = localStorage.getItem(
      QuickInsightsComponent.PINNED_ITEMS_SETTINGS_KEY
    );
    if (!!pinnedItemsString) {
      return pinnedItemsString.split(':::').map(value => value === 'true');
    } else {
      return null;
    }
  }
}
