import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MultiSelectFilter, MultiSelectComponent} from '../multi-select-component';
import {VehicleCategoryModel} from '../../models/vehicle';
import {MatMenuTrigger} from '@angular/material/menu';
import {VehicleGroupFilterComponent} from '../vehicle-group-filter/vehicle-group-filter.component';
import {DateFilter, LabeledDateFilter, TimeRangeFilter} from '../../models/DateFilter';
import {DateFilterDropdownComponent} from '../date-filter-dropdown/date-filter-dropdown.component';
import {ObservationTypeGroup} from '../../models/observation-group';
import {AssetStatus} from '../../models/asset-status';
import {HardwareFilterComponent, HardwareType} from '../hardware-filter/hardware-filter.component';
import {ObservationGroupFilterComponent} from '../observation-group-filter/observation-group-filter.component';
import {AssetStatusFilterComponent} from '../asset-status-filter/asset-status-filter.component';

interface Chip {
  type: ChipType;
  filterName: string;
  clear: boolean;
  items: string;
}

enum ChipType {
  DATE_FILTER,
  VEHICLE_GROUP_FILTER,
  OBSERVATION_TYPE_GROUP_FILTER,
  ASSET_STATUS_FILTER,
  HARDWARE_FILTER,
}

@Component({
  selector: 'app-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss']
})
export class FilterBarComponent {

  @Input() origin: string;
  @Input() useDateFilter = false;
  @Input() defaultDateFilter: TimeRangeFilter;
  @Input() useVehicleGroupFilter = false;
  @Input() useObservationTypeGroupFilter = false;
  @Input() useAssetStatusFilter = false;
  @Input() useHardwareFilter = false;

  @Output() dateFilterChanged = new EventEmitter<DateFilter>();
  @Output() vehicleGroupFilterChanged = new EventEmitter<MultiSelectFilter<number>>();
  @Output() observationTypeGroupFilterChanged = new EventEmitter<MultiSelectFilter<number>>();
  @Output() assetStatusFilterChanged = new EventEmitter<MultiSelectFilter<string>>();
  @Output() hardwareFilterChanged = new EventEmitter<string>();
  @Output() appliedFiltersChanged = new EventEmitter<number>();

  dateFilter: LabeledDateFilter = undefined;
  vehicleGroupFilter: VehicleCategoryModel[] = undefined;
  observationTypeGroupFilter: ObservationTypeGroup[] = undefined;
  assetStatusFilter: AssetStatus[] = undefined;
  hardwareFilter: HardwareType = undefined;

  filterChips: Chip[] = [];
  @Input() showingFilterBar = false;

  @ViewChild('matTriggerAdd') matTriggerAdd: MatMenuTrigger;
  @ViewChild('matTriggerVehicleGroup') matTriggerVehicleGroup: MatMenuTrigger;
  @ViewChild(VehicleGroupFilterComponent) vgFilterComponent: VehicleGroupFilterComponent;

  @ViewChild('matTriggerDateFilter') matTriggerDateFilter: MatMenuTrigger;
  @ViewChild(DateFilterDropdownComponent) dateFilterComponent: DateFilterDropdownComponent;

  @ViewChild('matTriggerObservationTypeGroup') matTriggerObservationTypeGroup: MatMenuTrigger;
  @ViewChild(ObservationGroupFilterComponent) otgFilterComponent: ObservationGroupFilterComponent;

  @ViewChild('matTriggerAssetStatus') matTriggerAssetStatus: MatMenuTrigger;
  @ViewChild(AssetStatusFilterComponent) asFilterComponent: AssetStatusFilterComponent;

  @ViewChild('matTriggerHardware') matTriggerHardware: MatMenuTrigger;
  @ViewChild(HardwareFilterComponent) hardwareFilterComponent: HardwareFilterComponent;

  allFiltersSet(): boolean {
    return (!this.useDateFilter || (!!this.dateFilter && (!!this.dateFilter.from || this.dateFilter.to))) &&
        (!this.useVehicleGroupFilter || !!this.vehicleGroupFilter) &&
        (!this.useObservationTypeGroupFilter || !!this.observationTypeGroupFilter) &&
        (!this.useAssetStatusFilter || !!this.assetStatusFilter) &&
        (!this.useHardwareFilter || !!this.hardwareFilter);
  }

  noFiltersSet(): boolean {
    return (!this.useDateFilter || (!!this.dateFilter && (!this.dateFilter.from && !this.dateFilter.to))) &&
        (!this.useVehicleGroupFilter || !this.vehicleGroupFilter) &&
        (!this.useObservationTypeGroupFilter || !this.observationTypeGroupFilter) &&
        (!this.useAssetStatusFilter || !this.assetStatusFilter) &&
        (!this.useHardwareFilter || !this.hardwareFilter);
  }

  clearFilters() {
    this.filterChips.forEach(chip => {
      this.filterChipRemoved(null, chip);
    });
    this.filterChips = [];
  }

  onDateFilterChanged(dateFilter: LabeledDateFilter) {
    this.dateFilter = dateFilter;

    // update dateFilter chip
    if (this.useDateFilter) {
      const chipIndex = this.filterChips.findIndex(chip => chip.type === ChipType.DATE_FILTER);
      if (!!dateFilter) {
        const chip = {
          type: ChipType.DATE_FILTER,
          filterName: 'Date Range',
          clear: !dateFilter.from && !dateFilter.to,
          items: dateFilter.label,
        };
        if (chipIndex >= 0) {
          this.filterChips[chipIndex] = chip;
        } else {
          this.filterChips.push(chip);
        }
      } else {
        if (chipIndex >= 0) {
          this.filterChips.splice(chipIndex, 1);
        }
      }
    }

    // emit event to parent
    this.dateFilterChanged.emit(dateFilter);
    this.appliedFiltersChanged.emit(this.filterChips.filter(chip => !chip.clear).length);
  }

  onVehicleGroupsChanged(filter: MultiSelectFilter<VehicleCategoryModel>) {
    this.vehicleGroupFilter = filter.elements;

    // update vehicle group chip
    if (this.useVehicleGroupFilter) {
      const chipIndex = this.filterChips.findIndex(chip => chip.type === ChipType.VEHICLE_GROUP_FILTER);
      const filterName = 'Vehicle Group';
      let chip: Chip;
      let itemLabel: string;
      if (filter.elements?.length > 0) {
        const firstGroup = filter.elements[0];
        itemLabel = this.vgFilterComponent.getLabel(firstGroup);
        if (filter.elements.length > 1) {
          itemLabel = `${itemLabel}, +1`;
        }
      } else {
        itemLabel = 'All';
      }
      chip = {
        type: ChipType.VEHICLE_GROUP_FILTER,
        filterName,
        clear: !filter.elements,
        items: itemLabel,
      };
      if (chipIndex >= 0) {
        this.filterChips[chipIndex] = chip;
      } else {
        this.filterChips.push(chip);
      }
    }

    // emit event to parent
    this.vehicleGroupFilterChanged.emit(
        new MultiSelectFilter<number>(
            (!!filter.elements ? filter.elements.map(group => group.id) : null)
        )
    );
    this.appliedFiltersChanged.emit(this.filterChips.filter(chip => !chip.clear).length);
  }

  onObservationTypeGroupFilterChanged(filter: MultiSelectFilter<ObservationTypeGroup>) {
    this.observationTypeGroupFilter = filter.elements;

    // update vehicle group chip
    if (this.useObservationTypeGroupFilter) {
      const chipIndex = this.filterChips.findIndex(chip => chip.type === ChipType.OBSERVATION_TYPE_GROUP_FILTER);
      const filterName = 'Observation Group';
      let chip: Chip;
      let itemLabel: string;
      if (filter.elements?.length > 0) {
        const firstGroup = filter.elements[0];
        itemLabel = this.otgFilterComponent.getLabel(firstGroup);
        if (filter.elements.length > 1) {
          itemLabel = `${itemLabel}, +1`;
        }
      } else {
        itemLabel = 'All';
      }
      chip = {
        type: ChipType.OBSERVATION_TYPE_GROUP_FILTER,
        filterName,
        clear: !filter.elements,
        items: itemLabel,
      };
      if (chipIndex >= 0) {
        this.filterChips[chipIndex] = chip;
      } else {
        this.filterChips.push(chip);
      }
    }

    // emit event to parent
    this.observationTypeGroupFilterChanged.emit(
        new MultiSelectFilter<number>(
            (!!filter.elements ? filter.elements.map(group => group.id) : null)
        )
    );
    this.appliedFiltersChanged.emit(this.filterChips.filter(chip => !chip.clear).length);
  }

  onAssetStatusFilterChanged(filter: MultiSelectFilter<AssetStatus>) {
    this.assetStatusFilter = filter.elements;

    // update asset status chip
    if (this.useAssetStatusFilter) {
      const chipIndex = this.filterChips.findIndex(chip => chip.type === ChipType.ASSET_STATUS_FILTER);
      const filterName = 'Status';
      let chip: Chip;
      let itemLabel: string;
      if (filter.elements?.length > 0) {
        const firstGroupLabel = filter.elements[0].label;
        itemLabel = filter.elements.length > 1 ? `${firstGroupLabel}, +1` : firstGroupLabel;
      } else {
        itemLabel = 'All';
      }
      chip = {
        type: ChipType.ASSET_STATUS_FILTER,
        filterName,
        clear: !filter.elements,
        items: itemLabel,
      };
      if (chipIndex >= 0) {
        this.filterChips[chipIndex] = chip;
      } else {
        this.filterChips.push(chip);
      }
    }

    // emit event to parent
    this.assetStatusFilterChanged.emit(
        new MultiSelectFilter<string>(
            (!!filter.elements ? filter.elements.map(status => status.id) : null)
        )
    );
    this.appliedFiltersChanged.emit(this.filterChips.filter(chip => !chip.clear).length);
  }

  onHardwareFilterChanged(filter: HardwareType) {
    this.hardwareFilter = filter;

    // update hardware chip
    const chipIndex = this.filterChips.findIndex(chip => chip.type === ChipType.HARDWARE_FILTER);
    if (!!filter) {
      const chip = {
        type: ChipType.HARDWARE_FILTER,
        filterName: filter.name,
        clear: true,
        items: '',
      };
      if (chipIndex >= 0) {
        this.filterChips[chipIndex] = chip;
      } else {
        this.filterChips.push(chip);
      }
    } else {
      if (chipIndex >= 0) {
        this.filterChips.splice(chipIndex, 1);
      }
    }

    this.hardwareFilterChanged.emit(this.hardwareFilter?.id);
    this.appliedFiltersChanged.emit(this.filterChips.filter(chip => !chip.clear).length);
  }

  filterChipRemoved(e, chip: Chip) {
    switch (chip.type) {
      case ChipType.DATE_FILTER: {
        this.dateFilterComponent.setFilter(TimeRangeFilter.NONE.key);
        break;
      }
      case ChipType.VEHICLE_GROUP_FILTER: {
        this.vgFilterComponent.setFilter(null);
        break;
      }
      case ChipType.OBSERVATION_TYPE_GROUP_FILTER: {
        this.otgFilterComponent.setFilter(null);
        break;
      }
      case ChipType.ASSET_STATUS_FILTER: {
        this.asFilterComponent.setFilter(null);
        break;
      }
      case ChipType.HARDWARE_FILTER: {
        this.hardwareFilterComponent.setFilter(null);
        break;
      }
      default: {
        console.warn('Unhandled switch case! ' + chip.type);
      }
    }
  }

  filterChipSelected(e, chip: Chip) {
    let trigger: MatMenuTrigger;
    let component: MultiSelectComponent<any> | any;
    switch (chip.type) {
      case ChipType.DATE_FILTER: {
        trigger = this.matTriggerDateFilter;
        component = null;
        break;
      }
      case ChipType.VEHICLE_GROUP_FILTER: {
        trigger = this.matTriggerVehicleGroup;
        component = this.vgFilterComponent;
        break;
      }
      case ChipType.OBSERVATION_TYPE_GROUP_FILTER: {
        trigger = this.matTriggerObservationTypeGroup;
        component = this.otgFilterComponent;
        break;
      }
      case ChipType.ASSET_STATUS_FILTER: {
        trigger = this.matTriggerAssetStatus;
        component = this.asFilterComponent;
        break;
      }
      case ChipType.HARDWARE_FILTER: {
        trigger = this.matTriggerHardware;
        component = this.hardwareFilterComponent;
        break;
      }
      default: {
        console.warn('Unhandled switch case! ' + chip.type);
      }
    }
    if (!trigger) {
      return;
    }
    // entered delay to wait for menu initialization
    setTimeout(
      () => {
        trigger.openMenu();
        const panelElement: HTMLElement = document.getElementById(trigger.menu.panelId);
        panelElement.style.position = 'absolute';
        panelElement.style.left = e.x + 'px';
        panelElement.style.top = e.y + 'px';
      }, 100
    );
  }
}
