
export class CurrentEditing {
  dirtyControlIds = {};

  isDirty(controlId: string): boolean {
    return this.dirtyControlIds[controlId];
  }

  cancel(controlId: string) {
    delete this.dirtyControlIds[controlId]
  }

  loaded(controlId: string) {
    this.cancel(controlId);
  }

  saved(controlId: string) {
    this.cancel(controlId);
  }

  changed(controlId: string) {
    this.dirtyControlIds[controlId] = true;
  }
}
