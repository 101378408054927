import { Injectable } from '@angular/core';
import {MapLayersManager} from '../map-layers-manager';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {LngLatBounds, LngLatLike} from 'maplibre-gl';
import {TracksGeoJsonLayerService} from './tracks-geo-json-layer.service';
import { LocationApiService } from '../../../../data/location-api/location-api.service';
import { ServerEventService } from '../../../../data/server-events/server-event.service';
import { FeatureCollection } from 'geojson';
import { PointFeature } from '../../../models/GeoJson';

@Injectable({
  providedIn: 'root'
})
export class MapControlService {

  mapResizeEvent: Subject<any> = new Subject();

  private mapLayerSwitcher = new BehaviorSubject<boolean>(false);
  mapLayerSwitcher$ = this.mapLayerSwitcher.asObservable();

  private mapLayersManager: MapLayersManager;
  private readonly openSubscriptions = Array<Subscription>();

  static getBoundsZoomLevel(bounds: LngLatBounds, mapDim) {
    const WORLD_DIM = { height: 512, width: 512 };
    const ZOOM_MAX = 21;

    function latRad(lat) {
      const sin = Math.sin(lat * Math.PI / 180);
      const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx, worldPx, fraction) {
      return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    const latFraction = (latRad(ne.lat) - latRad(sw.lat)) / Math.PI;

    const lngDiff = ne.lng - sw.lng;
    const lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

    const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
    const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

    return Math.min(latZoom, lngZoom, ZOOM_MAX);
  }

  constructor(private locationApi: LocationApiService,
              private trackGeojsonLayer: TracksGeoJsonLayerService,
              private serverEventService: ServerEventService,
              ) { }

  init(mapLayersManager: MapLayersManager) {
    if (!!this.mapLayersManager) {
      throw Error('The map layers manager has already been set.');
    }
    this.mapLayersManager = mapLayersManager;
  }

  release() {
    if (!this.mapLayersManager) {
      throw Error('The map layer manager has not been set.');
    }

    for (const subscription of this.openSubscriptions) {
      subscription.unsubscribe();
    }
    this.openSubscriptions.length = 0;
    this.mapLayersManager = null;
  }

  public resizeMap() {
    this.mapResizeEvent.next(true);
  }

  public toggleMapLayerSwitcher(state: boolean) {
    this.mapLayerSwitcher.next(state);
  }

  public zoomToCoordinates(coordinates: LngLatLike, zoomLevel: number = 14) {
    if (this.mapLayersManager) {
      this.mapLayersManager.zoomTo(coordinates, zoomLevel);
    }
  }

  public fitMapToBounds(bounds: LngLatBounds) {
    if (this.mapLayersManager) {
      this.mapLayersManager.fitTo(bounds);
    }
  }

  public zoomToShiftExtent(shiftId: number) {
    this.locationApi.getShiftBoundingBox(shiftId).then(boundingBox => {
      if (!!boundingBox && boundingBox.length === 4) {
        // @ts-ignore
        const bounds = new LngLatBounds(boundingBox);

        // extend bounds to new locations on active shift
        const uncachedBounds = this.trackGeojsonLayer.getBounds(shiftId);
        if (!!uncachedBounds) {
          bounds.extend(uncachedBounds);
        }
        this.fitMapToBounds(bounds);
      } else {
        const uncachedBounds = this.trackGeojsonLayer.getBounds(shiftId);
        if (!!uncachedBounds) {
          this.fitMapToBounds(uncachedBounds);
        }
      }
    }).catch(message => {
      console.warn(message);
      // zoom to vehicle marker
      const vehicleLocationGeojson = this.serverEventService.currentLocationsObservable.getValue() as FeatureCollection;
      const vehicleFeature = vehicleLocationGeojson.features.find(feature => feature.properties.shiftId === shiftId) as PointFeature;
      if (!!vehicleFeature) {
        this.zoomToCoordinates(vehicleFeature.geometry.coordinates as LngLatLike);
      }
    });
  }
}
