import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {RightPanel} from '../right-panel.class';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfigurationService} from '../../../../../configuration/configuration.service';
import {AssetsManagerService} from '../../../../../data/assets/assets-manager.service';
import {FormControl} from '@angular/forms';
import {ToastService} from '../../../../../shared/services/toast.service';
import {RouteAssignmentService} from '../../../../../data/routes/route-assignment.service';
import {
  RouteAssignment,
  RouteAssignmentQueue,
  RouteAssignmentStatus,
  VehicleRouteAssignmentStatus
} from '../../../../../shared/models/route-assignment';
import {ActionMenuItem, ActionMenuItemSubMenu} from '../../../../../shared/models/action-menu-item.class';
import {
  RouteConfigurationWithSchema,
  RouteHierarchyItem,
  RouteHierarchyItemWithPath
} from '../../../../../shared/models/route';
import {RouteAssignmentManagerService} from '../../../../../data/routes/route-assignment-manager.service';
import {LiveMapDataService} from '../../../services/live-map-data.service';
import {MainRoute, RootRoute} from '../../../../../shared/models/angular-routing';
import {Asset} from '../../../models/asset.class';
import {ShiftState} from '../../../../../shared/models/shift.model';
import {DrawerContent} from '../../../../../layouts/right-drawer/right-drawer.component';
import moment from 'moment';

class AssignmentAction {
  constructor(
      readonly title: string,
      readonly onClick: () => void,
  ) {}
}

@Component({
  selector: 'app-route-assignment',
  templateUrl: './route-assignment.component.html',
  styleUrls: ['./route-assignment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RouteAssignmentComponent extends RightPanel implements OnInit, OnDestroy {


  routeAssignments: RouteAssignment[];
  items: ActionMenuItem[];
  assignmentActions: AssignmentAction[];
  readonly vehiclesStatus: Map<number, VehicleRouteAssignmentStatus> = new Map<number, VehicleRouteAssignmentStatus>();

  assigningMode = false;
  showConfirmDialog = false;
  delayedRouteAction = false;

  flatRouteItems: RouteHierarchyItemWithPath[];
  routeHierarchyItems: RouteHierarchyItem[];

  routeSelectorControl: FormControl<RouteHierarchyItem> = new FormControl(null);

  availableVehiclesForAssignment: Asset[];
  selectedVehicle: Asset;
  selectedRoute: RouteHierarchyItem;
  RouteAssignmentQueue = RouteAssignmentQueue;

  constructor(
      protected router: Router,
      protected activatedRoute: ActivatedRoute,
      protected configurationService: ConfigurationService,
      protected assetsManager: AssetsManagerService,
      private routeAssignmentService: RouteAssignmentService,
      private routeAssignmentManager: RouteAssignmentManagerService,
      private liveMapDataService: LiveMapDataService,
      private toast: ToastService,
  ) {
    super(
        DrawerContent.ROUTE,
        router,
        activatedRoute,
        configurationService,
        assetsManager,
    );
  }

  ngOnInit() {
    this.initialize();
    this.loadConfiguration();
    this.selectedVehicle = this.asset;

    const assignmentsSubscription = this.routeAssignmentManager.recentRouteAssignments$.subscribe(assignmentsUpdate => {
      // filter out these which are completed more than 24 hours ago
      this.routeAssignments = RouteAssignment.sortByCreated(
          assignmentsUpdate.state.filter(assignment => !assignment.completed || moment(assignment.completed).isAfter(moment().subtract(24, 'hour')))
      );
      this.updateUiItems();
    });
    this.openSubscriptions.push(assignmentsSubscription);

    const that = this;
    const vehicleStatusSubscription = this.routeAssignmentManager.vehicleRouteAssignmentStatus$.subscribe({
      next(statusUpdate) {
        let vehiclesStatus: VehicleRouteAssignmentStatus[];
        if (statusUpdate.updated.length === 0) {
          // initial setup
          vehiclesStatus = statusUpdate.state;
        } else {
          // only update
          vehiclesStatus = statusUpdate.updated;
        }
        vehiclesStatus.forEach(vehicleStatus => {
          that.vehiclesStatus.set(vehicleStatus.vehicleId, vehicleStatus);
        });
        if (statusUpdate.updated.length === 0) {
          // initial setup, update only UI items
          that.updateUiItems();
        } else {
          // route assignments has changed, reload
          that.updateUiItems();
        }
        that.onAssignmentFormChange();
      }
    });
    this.openSubscriptions.push(vehicleStatusSubscription);

    const routeSubscription = this.liveMapDataService.routeConfiguration$.subscribe(routeConfiguration => {
      this.flatRouteItems = routeConfiguration.map(routeConfig => {
        return this.routeConfigToLeaves(routeConfig);
      }).flat();
      this.routeHierarchyItems = routeConfiguration.map(routeConfig => {
        return this.routeConfigToHierarchy(routeConfig);
      });
      if (!!this.routeConfigId && !!this.routeId && this.delayedRouteAction) {
        this.preFillRoute();
        this.delayedRouteAction = false;
      }
    });
    this.openSubscriptions.push(routeSubscription);

    this.routeSelectorControl.valueChanges.subscribe((routeHierarchyItem) => {
      console.log('on route selector control change');
      this.selectedRoute = routeHierarchyItem;
      this.onAssignmentFormChange();
    });
  }

  ngOnDestroy() {
    this.unsubscribe();
    this.vehiclesStatus.clear();
  }

  onAssetsUpdate() {
    this.availableVehiclesForAssignment = this.assets
        .filter(asset => {
          return asset.shiftStatus !== ShiftState.ENDED &&
              !asset.hasNoTablet;
        })
        .sort((a, b) => {
          return a.name < b.name ? -1 : 1;
        });
  }

  onAssetChange() {
    // do nothing
  }

  onAssetAction() {
    this.selectedVehicle = this.asset;
    this.onAssignmentFormChange();
    this.assigningMode = true;
  }

  onRouteAction() {
    if (!!this.routeConfigId && !!this.routeId && !!this.flatRouteItems) {
      this.preFillRoute();
    } else {
      // route items not ready at this moment
      this.delayedRouteAction = true;
    }
  }

  onConfigurationLoad() {
    // do nothing
  }

  onAssignmentFormChange() {
    this.prepareAssignmentActions();
  }

  private routeConfigToLeaves(routeConfiguration: RouteConfigurationWithSchema): RouteHierarchyItemWithPath[] {
    return RouteHierarchyItem.getLeaves(routeConfiguration.schema.classification.length, routeConfiguration.configuration.children)
        .map(item => {
          item.configId = routeConfiguration.id;
          item.routeName = null;
          return item;
        });
  }

  private routeConfigToHierarchy(routeConfiguration: RouteConfigurationWithSchema): RouteHierarchyItem {
    const items = RouteHierarchyItem.getVisibleItems(routeConfiguration.configuration.children, routeConfiguration.id);
    return {
      routeId: -1,
      routeName: routeConfiguration.name,
      children: items,
      childrenAttribute: '',
      value: String(routeConfiguration.id)
    };
  }

  private updateUiItems() {
    if (!!this.routeAssignments) {
      this.items = this.routeAssignments.map(it => this.routeAssignmentToActionMenuItem(it));
    }
  }

  private routeAssignmentToActionMenuItem(routeAssignment: RouteAssignment): ActionMenuItem {
    const actions: ActionMenuItemSubMenu[] = [];
    const vehicleStatus = this.vehiclesStatus.get(routeAssignment.vehicleId);
    const isInProgress = this.isInProgress(vehicleStatus, routeAssignment);
    if (!isInProgress && !routeAssignment.completed) {
      actions.push(
          new ActionMenuItemSubMenu(
              'delete',
              'Delete',
              () => this.deleteRouteAssignment(routeAssignment),
          )
      );
    }
    return new ActionMenuItem(
        `${routeAssignment.configId}_${routeAssignment.routeId}`,
        'route',
        routeAssignment.routeName,
        `${routeAssignment.vehicleName}\n${routeAssignment.driverName}`,
        routeAssignment.completed ? 'Completed' : (isInProgress ? 'In Progress' : 'Pending'),
        null,
        () => false,
        null,
        null,
        () => this.viewRoute(routeAssignment),
        null,
        null,
        actions,
    );
  }

  private prepareAssignmentActions() {
    if (!!this.routeAssignments && !!this.selectedRoute && !!this.selectedVehicle) {
      let assignmentActions: AssignmentAction[];
      const vehicleAssignments = this.routeAssignments
          .filter(assignment => assignment.vehicleId === this.selectedVehicle.id && !assignment.completed)
          .sort((a, b) => a.queueOrder < b.queueOrder ? -1 : 1);
      if (vehicleAssignments.length === 0) {
        // empty queue, starting immediately
        assignmentActions = [
            new AssignmentAction(
                'Assign',
                () => this.assignRoute(RouteAssignmentQueue.IMMEDIATE),
            )
        ];
      } else if (vehicleAssignments.length === 1) {
        // if the assignment is in progress
        if (!!vehicleAssignments[0].onAssignmentFrom) {
          assignmentActions = [
            new AssignmentAction(
                  'Assign',
                  () => this.assignRoute(RouteAssignmentQueue.END),
            ),
            new AssignmentAction(
                'Start immediately',
                () => this.confirmImmediateAssignment(),
            ),
          ];
        } else {
          // one pending assignment - waiting for acceptance
          assignmentActions = [
            new AssignmentAction(
                'Assign',
                () => this.assignRoute(RouteAssignmentQueue.END),
            ),
            new AssignmentAction(
                'Make next in queue',
                () => this.assignRoute(RouteAssignmentQueue.IMMEDIATE),
            ),
          ];
        }
      } else {
        // queue has at least two assignments
        if (!!vehicleAssignments[0].onAssignmentFrom) {
          // if the assignment is in progress
          assignmentActions = [
            new AssignmentAction(
                'Assign',
                () => this.assignRoute(RouteAssignmentQueue.END),
            ),
            new AssignmentAction(
                'Start immediately',
                () => this.confirmImmediateAssignment(),
            ),
            new AssignmentAction(
                'Make next in queue',
                () => this.assignRoute(RouteAssignmentQueue.NEXT),
            ),
          ];
        } else {
          // none in progress
          assignmentActions = [
            new AssignmentAction(
                'Assign',
                () => this.assignRoute(RouteAssignmentQueue.END),
            ),
            new AssignmentAction(
                'Make next in queue',
                () => this.assignRoute(RouteAssignmentQueue.IMMEDIATE),
            ),
          ];
        }
      }
      this.assignmentActions = assignmentActions;
    }
  }

  private isInProgress(vehicleStatus: VehicleRouteAssignmentStatus, routeAssignment: RouteAssignment) {
    if (!!vehicleStatus) {
      if (vehicleStatus.status === RouteAssignmentStatus.ON_ASSIGNMENT
          && vehicleStatus.routeAssignment?.configId === routeAssignment.configId
          && vehicleStatus.routeAssignment?.routeId === routeAssignment.routeId) {
        return true;
      }
    }
    return false;
  }

  toggleAssigningMode() {
    this.assigningMode = !this.assigningMode;
    this.routeSelectorControl.reset(null);
    this.selectedRoute = null;
    this.selectedVehicle = null;
    this.showConfirmDialog = false;
  }

  assignRoute(queueOrder: RouteAssignmentQueue) {
    const routeAssignment = new RouteAssignment();
    routeAssignment.configId = this.selectedRoute.configId;
    routeAssignment.routeId = this.selectedRoute.routeId;
    routeAssignment.routeName = this.selectedRoute.value;
    routeAssignment.vehicleId = this.selectedVehicle.id;
    routeAssignment.shiftId = this.selectedVehicle.shiftId;

    // UI validation
    if (this.routeAssignmentManager.canRouteBeAssigned(
        routeAssignment.vehicleId,
        routeAssignment.shiftId,
        routeAssignment.configId,
        routeAssignment.routeId,
        )
    ) {
      this.routeAssignmentService.createRouteAssignment(routeAssignment, queueOrder)
          .then(response => {
            // this.routeAssignments = response.data;
            this.toggleAssigningMode();
            // this.updateUiItems();
            this.toast.short(`Route '${routeAssignment.routeName}' assigned!`);
          })
          .catch(error => {
            this.toast.longer('Error while assigning route: ' + error);
          });
    } else {
      this.toast.short(`Route '${routeAssignment.routeName}' is already queued!`);
      return;
    }
  }

  deleteRouteAssignment(routeAssignment: RouteAssignment) {
    this.routeAssignmentService.deleteRouteAssignment(routeAssignment).then(() => {
      // TODO check if the list is refreshed automatically - it should be
      // this.routeAssignments.splice(this.routeAssignments.indexOf(routeAssignment), 1);
      // this.updateUiItems();
      this.toast.short(`Route '${routeAssignment.routeName}' unassigned.`);
    }).catch(error => {
      this.toast.longer(`Route '${routeAssignment.routeName}' could not be unassigned. Error: ${error}`);
    });
  }

  confirmImmediateAssignment() {
    this.showConfirmDialog = true;
  }

  cancelConfirmation() {
    this.showConfirmDialog = false;
  }

  getCurrentAssignmentInProgressIfAny(): RouteAssignment {
    if (!!this.selectedVehicle) {
      const vehicleStatus = this.vehiclesStatus.get(this.selectedVehicle.id);
      return vehicleStatus?.routeAssignment;
    }
    return null;
  }

  viewRoute(routeAssignment: RouteAssignment) {
    if (!!this.flatRouteItems) {
      const route = this.flatRouteItems.find(item => {
        return item.configId === routeAssignment.configId && item.routeId === routeAssignment.routeId;
      });
      if (!!route) {
        const path = [String(route.configId)];
        if (!!route.path && route.path.length > 0) {
          path.push(...route.path);
        }
        path.push(route.value);
        this.router.navigate([`/${RootRoute.MAIN}`, MainRoute.ROUTE, path.join(':::'), 'route-id', route.routeId], {
          queryParamsHandling: 'merge',
        });
      } else {
        const msg = `Route Configuration not found! Config ID: ${routeAssignment.configId} Route ID: ${routeAssignment.routeId}`;
        console.warn(msg);
        this.toast.longer(msg);
      }
    } else {
      console.warn('Route Configuration not initialized!');
    }
  }

  private preFillRoute() {
    const routeItemWithPath: RouteHierarchyItemWithPath = this.flatRouteItems?.find(item => {
      return item.configId === this.routeConfigId && item.routeId === this.routeId;
    });

    if (!!routeItemWithPath) {
      let routeItem: RouteHierarchyItem;
      if (!!routeItemWithPath.path && routeItemWithPath.path.length > 0) {
        routeItem = RouteHierarchyItem.getByPath(
          routeItemWithPath.path,
          this.routeHierarchyItems.find(item => item.value === String(routeItemWithPath.configId))?.children,
        )?.children.find(x => x.routeId === routeItemWithPath.routeId);
      } else {
        routeItem = routeItemWithPath;
      }

      this.assigningMode = true;
      this.routeSelectorControl.setValue(routeItem, {emitEvent: false});
      this.selectedRoute = routeItem;
      this.onAssignmentFormChange();
    }
  }
}
