import { MapContentLayer } from '../MapContentLayer';
import { CircleLayerSpecification, LayerSpecification } from 'maplibre-gl';
import { MapContentSource } from '../MapContentSource';

export class ShiftMarkerCircleLayer extends MapContentLayer {

  constructor(
    source: MapContentSource
  ) {
    super('shift-marker-circle-layer', source);
  }

  toLayerSpecification(): LayerSpecification {
    return {
      id: this.layerId,
      type: 'circle',
      source: this.sourceRef.sourceId,
      paint: {
        'circle-color': '#FEFEFE',
        'circle-radius': 15,
        'circle-opacity': 1.0
      },
      visibility: 'visible'
    } as CircleLayerSpecification;
  }

}
