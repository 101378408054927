import { Feature, FeatureCollection, Position } from 'geojson';
import {GeoJSONSource, GeoJSONSourceSpecification, Map} from 'maplibre-gl';

export class MapContentSource {

  public features: Feature[] = [];

  constructor(
    public sourceId: string,
  ) {}

  loadFromFeatureCollection(featureCollection?: FeatureCollection) {
    this.features = !!featureCollection ? [...featureCollection.features] : [];
  }

  addFeature(added: Feature) {
    this.features.push(added);
  }

  clearFeatures() {
    this.features = [];
  }

  setLineString(coordinates: Position[]) {
    this.features = [
      {type: 'Feature', geometry: {type: 'LineString', coordinates}} as Feature
    ];
  }

  updateMap(map: Map) {
    const mapSource = map.getSource(this.sourceId);
    if (mapSource == null) {
      map.addSource(this.sourceId, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: this.features
        },
      } as GeoJSONSourceSpecification);
    } else {
      const mapSourceCast = mapSource as GeoJSONSource;
      mapSourceCast.setData(
        {
          type: 'FeatureCollection',
          features: this.features
        }
      );
    }
  }
}
