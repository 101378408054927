import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { DvirReport, InspectionReport } from '../../../../shared/models/dvir';
import { ReportsService } from '../../../../data/reports/reports.service';
import {saveAs} from 'file-saver-es';
import {InfiniteScroll} from '../../../../shared/tools/InfiniteScroll';
import {JsonApiResponse} from '../../../../shared/models/JsonApiResponse';
import {InsightsRoute} from '../../insights-routing.module';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dvir-report-table',
  templateUrl: './dvir-report-table.component.html',
  styleUrls: ['./dvir-report-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class DvirReportTableComponent {
  @ViewChild(MatSort) sort: MatSort;

  @Input() dataSource: MatTableDataSource<DvirReport>;

  @Output() sortChangedEvent: EventEmitter<void> = new EventEmitter();
  @Output() scrolledToBottomEvent: EventEmitter<void> = new EventEmitter();

  displayedColumns: string[] = [
    'id',
    'driver',
    'vehicle',
    'startTime',
    'endTime',
  ];
  displayedColumnsWithActions: string[] = [...this.displayedColumns, 'action'];

  expandedElement: DvirReport;
  startInspection: InspectionReport;
  endInspection: InspectionReport;
  loading = false;

  InsightsRoute = InsightsRoute;

  constructor(
    private reportService: ReportsService,
    public activatedRoute: ActivatedRoute,
  ) {}

  getSort(): string | undefined {
    if (!this.sort.active) {
      return undefined;
    }

    return `${this.sort.active},${this.sort.direction}`;
  }

  loadInspectionReports() {
    this.loading = true;
    this.startInspection = null;
    this.endInspection = null;
    if (this.expandedElement) {
      const reportPromises: Promise<JsonApiResponse<InspectionReport>>[] = [];
      if (!!this.expandedElement.startInspectionId && this.expandedElement.startInspectionId > 0) {
        reportPromises.push(this.reportService.getInspectionReport(this.expandedElement.startInspectionId));
      } else {
        reportPromises.push(new Promise((resolve, reject) => {
          reject(undefined);
        }));
      }
      if (!!this.expandedElement.endInspectionId && this.expandedElement.endInspectionId > 0) {
        reportPromises.push(this.reportService.getInspectionReport(this.expandedElement.endInspectionId));
      } else {
        reportPromises.push(new Promise((resolve, reject) => {
          reject(undefined);
        }));
      }
      Promise.allSettled(reportPromises).then((responses) => {
        if (responses[0].status === 'fulfilled') {
          this.startInspection = responses[0]['value'].data;
        }
        if (responses[1].status === 'fulfilled') {
          this.endInspection = responses[1]['value'].data;
        }
      }).finally(() => {
        this.loading = false;
      });
    }
  }

  downloadDVIR(shiftId: number, preTrip: boolean) {
    this.reportService.getDvirByShiftPDF(shiftId, preTrip).toPromise().then(response => {
      const blob = new Blob([response], { type: 'pdf' });
      const suffix = preTrip ? 'pre' : 'post';
      saveAs(blob, `Inspection_Report__id_${shiftId}_${suffix}.pdf`);
    });
  }

  onScroll(e) {
    InfiniteScroll.onScroll(e, () => this.scrolledToBottomEvent.emit());
  }
}
