<div class="drawer-layout">

  <div class="drawer-content">
    <mat-form-field appearance="outline" color="accent" class="w-100">
      <mat-select
        [(ngModel)]="sinceHours"
        (ngModelChange)="onHoursFilterChange()"
        class="vehicle-selector"
      >
        <mat-option
          *ngFor="let item of sinceHoursItems"
          [value]="item">
          Last {{ item }} hours
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <ng-container *ngIf="!!items">
    <div cdkDropList
         class="drawer-content"
         *ngIf="items.length > 0"
         [cdkDropListData]="items"
         (cdkDropListDropped)="drop($event)"
    >
      <ng-container *ngIf="existingPinnedItems()">
        <div class="pinned-items">
          <ng-container *ngFor="let item of items">
            <ng-container *ngIf="item.actionIcon.endsWith('_true')">
              <app-card-menu-item
                class="insight-card"
                cdkDrag
                [cdkDragData]="item"
                [input]="item"
              ></app-card-menu-item>
            </ng-container>
          </ng-container>

          <div class="divider"></div>
        </div>
      </ng-container>

      <ng-container *ngFor="let item of items">
        <ng-container *ngIf="item.actionIcon.endsWith('_false')">
          <app-card-menu-item
            class="insight-card"
            cdkDrag
            [cdkDragData]="item"
            [input]="item"
          ></app-card-menu-item>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</div>
