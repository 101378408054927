import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LayerTypeFilter} from '../../../../configuration/model/LayerTypeFilter';

export interface PassCountMapConfig {
  reset: boolean;
  defaultLegendSettings: LayerTypeFilter;
  legendSettings: LayerTypeFilter;
}

@Component({
  selector: 'app-pass-count-map-config',
  templateUrl: './pass-count-map-config.component.html',
  styleUrl: './pass-count-map-config.component.scss'
})
export class PassCountMapConfigComponent {

  constructor(
      public dialogRef: MatDialogRef<PassCountMapConfigComponent>,
      @Inject(MAT_DIALOG_DATA) public data: PassCountMapConfig,
  ) {
  }

  reset() {
    this.data.legendSettings = LayerTypeFilter.copy(this.data.defaultLegendSettings);
    this.data.reset = true;
  }

  isDefault() {
    return this.data.reset;
  }

  onChange(legendSettings: LayerTypeFilter) {
    this.data.reset = false;
  }
}
