import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { HttpErrorHandler } from '../../../../http.error.handler';
import { firstValueFrom } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-retrying-image-viewer',
  templateUrl: './retrying-image-viewer.component.html',
  styleUrls: ['./retrying-image-viewer.component.css']
})
export class RetryingImageViewerComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  imageUrl: string;

  @Input()
  alternativeText: string;

  @ViewChild('imageElement') imageElement;

  uniqueImageUrl: string;
  imageData: any;
  isImageLoaded = false;

  retryTimer = null;

  constructor(
    private http: HttpClient,
    private domSanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.uniqueImageUrl = this.imageUrl;
    this.loadImageData();
    this.attachImageHandlers();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.isImageLoaded = false;
    this.uniqueImageUrl = this.imageUrl;
    this.loadImageData();
  }

  ngOnDestroy() {
    if (!!this.retryTimer) {
      clearTimeout(this.retryTimer);
      this.retryTimer = null;
    }
  }

  private retryToLoadImage() {
    this.uniqueImageUrl = this.imageUrl + `?t=${encodeURIComponent(new Date().getTime().toString())}`;
    this.loadImageData();
  }

  private loadImageData(): void {
    this.imageData = undefined;
    firstValueFrom(this.http.get(
        this.uniqueImageUrl,
        {responseType: 'blob'},
      )
        .pipe(
          retry(0),
          catchError(HttpErrorHandler.handleError) // then handle the error
        )
    ).then(response => {
      this.imageData = this.domSanitizer.bypassSecurityTrustResourceUrl(
        URL.createObjectURL(response)
      );
      this.handleImageLoaded();
    }).catch(error => {
      this.handleImageNotLoaded();
    });
  }

  private handleImageNotLoaded() {
    console.warn('Image not loaded.', this.uniqueImageUrl);

    const that = this;
    this.retryTimer = setTimeout(() => {
      that.retryToLoadImage();
    }, 3000);
  }

  private handleImageLoaded() {
    console.log('Image loaded.', this.uniqueImageUrl);
    this.retryTimer = null;
    this.isImageLoaded = true;
  }

  private attachImageHandlers() {
    const that = this;

    const nativeImageElement = this.imageElement.nativeElement;

    nativeImageElement.addEventListener('error', () => {
      that.handleImageNotLoaded();
    });
    nativeImageElement.addEventListener('load', function imageLoadedHandler() {
      that.handleImageLoaded();
    });
  }
}
