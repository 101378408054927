import {Component, Input} from '@angular/core';
import {CardComponent} from '../../../../../../shared/components/card/card.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DvirSection} from '../../model/dvir-section';
import {CardContentDirective} from '../../../../../../shared/components/card/card-content.directive';
import {DvirInspectionQuestionDataType} from '../../model/QuestionDatatype';
import {MatIcon} from '@angular/material/icon';
import {MatTooltip} from '@angular/material/tooltip';

@Component({
  selector: 'app-inspection-form-sections-preview',
  standalone: true,
  imports: [
    CardComponent,
    FormsModule,
    ReactiveFormsModule,
    CardContentDirective,
    MatIcon,
    MatTooltip
  ],
  templateUrl: './inspection-form-sections-preview.component.html',
  styleUrls: ['../inspection-form-sections-edit/inspection-form-sections-edit.component.scss']
})
export class InspectionFormSectionsPreviewComponent {

  @Input()
  sections: DvirSection[] = [];

  protected readonly DvirInspectionQuestionDataType = DvirInspectionQuestionDataType;
}
