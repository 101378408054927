import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { ConfigurationModel, FeatureFlagEnum } from '../../../../shared/models/configuration.model';
import { SelectionModel } from '@angular/cdk/collections';
import { ActionMenuItem, ActionMenuItemSubMenu } from '../../../../shared/models/action-menu-item.class';
import { MultiSelectFilter } from '../../../../shared/components/multi-select-component';
import { MapOverlayType } from '../live-map.component';
import { DrawerContent } from 'src/app/layouts/right-drawer/right-drawer.component';
import { AssetsManagerService } from '../../../../data/assets/assets-manager.service';
import { VehiclesManagerService } from '../../../../data/vehicles/vehicles-manager.service';
import { ShiftsManagerService } from '../../../../data/shifts/shifts-manager.service';
import { ImagesManagerService } from '../../../../data/images/images-manager.service';
import { LiveMapDataService } from '../../services/live-map-data.service';
import { ConfigurationService } from '../../../../configuration/configuration.service';
import { AssetStatus } from '../../../../shared/models/asset-status';
import { Asset } from '../../models/asset.class';
import { ShiftState } from '../../../../shared/models/shift.model';
import { RouteConfigurationWithSchema, RouteHierarchyItem } from '../../../../shared/models/route';
import { InsightsRoute } from '../../../insights/insights-routing.module';

import { MainRoute, RootRoute } from '../../../../shared/models/angular-routing';
import {
  VehicleFilter,
  VehicleFilterComponent
} from '../../../../shared/components/vehicle-filter/vehicle-filter.component';

export class LeftPanelActiveItem {
  type: LeftPanelSection;
  vehicleId?: number;
  routePath?: string[];
  routeId?: number;
  insightName?: string;
}

export enum LeftPanelSection {
  VEHICLES,
  ROUTES,
  INSIGHTS,
  ADDRESS,
  ABOUT,
}

export class ActionGroupWithItems {
  id: number;
  name: string;
  collapsed: boolean;
  offlineCollapsed: boolean;
  visible: boolean;
  offlineVisible: boolean;
  activeShiftCount: number;
  items?: LeftMenuItem[];
  offlineItems?: LeftMenuItem[];
}

export class LeftMenuItem {
  constructor(
    readonly id: number | string,
    readonly color: string,
    readonly title: string,
    readonly subtitle: string,
    readonly text3: string,
    readonly actionIcon: string,
    readonly activePredicate: () => boolean,
    readonly inactivePredicate: () => boolean,
    readonly hiddenPredicate: () => boolean,
    readonly onClick: () => void,
    readonly onHoverOn: () => void,
    readonly onHoverOff: () => void,
    readonly subMenus: ActionMenuItemSubMenu[]
  ) {}
}

@Component({
  selector: 'app-live-map-left-panel',
  templateUrl: './live-map-panel.component.html',
  styleUrls: ['./live-map-panel.component.scss'],
})
export class LiveMapPanelComponent implements OnInit, OnDestroy {

  @Input() configuration: ConfigurationModel;
  @Output() activeItemChanged = new EventEmitter<LeftPanelActiveItem>();

  sidenavOpen$?: Observable<boolean>;

  vehiclesCollapsed = true;
  routesCollapsed = true;
  insightsCollapsed = true;

  initialExpansionSetting = true;
  vehicleGroupExpansionModel: SelectionModel<number> = new SelectionModel<number>(true, []);
  offlineVehicleGroupExpansionModel: SelectionModel<number> = new SelectionModel<number>(true, []);

  assets: Asset[];
  vehicleGroupsWithItems: ActionGroupWithItems[];
  vehicleFilter: VehicleFilter;

  routeItems: RouteHierarchyItem[];
  insightsSections: ActionMenuItem[] = [];

  activeItem: LeftPanelActiveItem;
  private readonly openSubscriptions = Array<Subscription>();

  showMapOverlay = MapOverlayType.NARROW;
  @ViewChild(VehicleFilterComponent) vehicleFilterComponent: VehicleFilterComponent;

  FeatureFlagEnum = FeatureFlagEnum;
  DrawerContent = DrawerContent;

  constructor(private store: Store<any>,
              private router: Router,
              private assetsManager: AssetsManagerService,
              private vehiclesManager: VehiclesManagerService,
              private shiftManager: ShiftsManagerService,
              private imageManager: ImagesManagerService,
              private liveMapDataService: LiveMapDataService,
              private activatedRoute: ActivatedRoute,
              private configurationService: ConfigurationService,
  ) {
  }

  private setActiveItem() {
    const activeLink = this.router.url.split(/[\/?]+/)[2]?.toLowerCase();
    switch (activeLink) {
      case MainRoute.VEHICLE:
        this.activeItem = {
          type: LeftPanelSection.VEHICLES,
          vehicleId: +this.activatedRoute.parent?.firstChild?.firstChild?.snapshot?.params['id'],
        };
        if (this.initialExpansionSetting) {
          this.vehiclesCollapsed = false;
        }
        break;
      case MainRoute.ROUTE:
        this.activeItem = {
          type: LeftPanelSection.ROUTES,
          routePath: this.activatedRoute.parent?.firstChild?.firstChild?.snapshot?.params['path']?.split(':::'),
          routeId: +this.activatedRoute.parent?.firstChild?.firstChild?.snapshot?.params['routeId'],
        };
        if (this.initialExpansionSetting) {
          this.routesCollapsed = false;
        }
        break;
      case MainRoute.INSIGHTS:
        const queryParams = this.activatedRoute.parent?.firstChild?.firstChild?.snapshot?.queryParams;
        this.activeItem = {
          type: LeftPanelSection.INSIGHTS,
          insightName: this.router.url.split(/[\/]+/)[3]?.split('?')[0]?.toLowerCase(),
          vehicleId: !!queryParams && queryParams['vehicleId'] ? +queryParams['vehicleId'] : undefined,
          routeId: !!queryParams && queryParams['routeId'] ? +queryParams['routeId'] : undefined,
          routePath: !!queryParams && queryParams['routePath'] ? queryParams['routePath'].split(':::') : undefined,
        };
        if (this.initialExpansionSetting) {
          this.insightsCollapsed = false;
        }
        break;
      case MainRoute.ADDRESS:
        this.activeItem = {
          type: LeftPanelSection.ADDRESS,
        };
        break;
      case MainRoute.ABOUT:
        this.activeItem = {
          type: LeftPanelSection.ABOUT,
        };
        break;
      default:
        // live address or plain main
        this.activeItem = null;
        if (this.initialExpansionSetting) {
          this.vehiclesCollapsed = false;
        }
    }
    this.activeItemChanged.emit(this.activeItem);
  }

  ngOnInit() {
    // get selected information from URL
    this.setActiveItem();
    this.assetsManager.filterByVehicleIds(null);
    this.imageManager.filterByShift(null);

    this.sidenavOpen$ = this.store.pipe(
        select((state) => state.ui.sidenavOpen)
    );
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.setActiveItem();

        // if clicking on vehicle marker we want to expand vehicle group
        const vehiclesExpandParam = this.activatedRoute.snapshot.queryParams['expandVehicleGroup'];
        if (!!this.assets && !!vehiclesExpandParam) {
          this.initialExpansionSetting = true;
          this.setVehicles(this.assets);
          this.router.navigate([], {queryParamsHandling: 'merge', queryParams: {expandVehicleGroup: undefined}});
        }

        // if clicking on route geometry we want to expand routes group
        const routesExpandParam = this.activatedRoute.snapshot.queryParams['expandRouteGroup'];
        if (!!this.routeItems && !!routesExpandParam) {
          this.routesCollapsed = false;
          this.router.navigate([], {queryParamsHandling: 'merge', queryParams: {expandRouteGroup: undefined}});
        }
      }
    });

    const assetManagerSubscription = this.assetsManager.statusFilteredAssets$.subscribe(assets => {
      this.setVehicles(assets);
      this.assets = assets;

      // check that there is not invalid state of offline items within groups and visibility settings
      // #32331 Vehicles incorrectly have lines through them in list
      this.vehicleGroupsWithItems
        .filter(group => group.activeShiftCount === 0)
        .forEach(group => {
          // it is invalid state where there are no active shifts and
          // offline vehicles are not visible when group is expanded
          if (!group.collapsed && !group.offlineVisible && group.offlineCollapsed) {
            // make offline visible
            group.visible = true;
            group.offlineVisible = true;
            group.offlineCollapsed = false;
            this.offlineVehicleGroupExpansionModel.select(group.id);
            this.vehicleFilterComponent.toggleGroup(group.id, !group.visible, group.offlineVisible);
          }
        });
    });
    this.openSubscriptions.push(assetManagerSubscription);

    const routeConfigSubscription = this.liveMapDataService.routeConfiguration$.subscribe(routeConfigurations => {
      this.routeItems = routeConfigurations
          .sort(RouteConfigurationWithSchema.routeConfigurationCompareFn)
          .map(routeConfig => {
            return this.routeConfigToHierarchy(routeConfig);
          });
    });
    this.openSubscriptions.push(routeConfigSubscription);

    const configSubscription = this.configurationService.sharedConfigurationModel.subscribe(model => {
      if (model) {
        this.configuration = model;
        this.setInsights();
      }
    });
    this.openSubscriptions.push(configSubscription);
  }

  private setVehicles(assets: Asset[]) {
    // get all available vehicle groups
    const groups = this.assetsManager.getVehicleGroups();

    // remove duplicates
    this.vehicleGroupsWithItems = [
      ...new Map(
          assets.map(asset => {
            const presentOnGroupFilter =
                !(this.vehicleFilter?.hiddenGroups?.find(group => group.id === asset.vehicleTypeId));
            const offlinePresentOnGroupFilter =
              !!(this.vehicleFilter?.visibleOfflineGroups?.find(group => group.id === asset.vehicleTypeId));
            return [
              asset.vehicleTypeId,
              {
                id: asset.vehicleTypeId,
                name: asset.vehicleType,
                collapsed: !this.vehicleGroupExpansionModel.isSelected(asset.vehicleTypeId),
                offlineCollapsed: !this.offlineVehicleGroupExpansionModel.isSelected(asset.vehicleTypeId),
                visible: presentOnGroupFilter,
                offlineVisible: offlinePresentOnGroupFilter,
                activeShiftCount: 0,
              } as ActionGroupWithItems,
            ];
          })
      ).values()
    ];
    // add groups with empty asset list
    groups.forEach(group => {
      if (this.vehicleGroupsWithItems.findIndex(groupWithItems => groupWithItems.id === group.id) < 0) {
        const presentOnGroupFilter =
          !(this.vehicleFilter?.hiddenGroups?.find(hiddenGroup => group.id === hiddenGroup.id));
        const offlinePresentOnGroupFilter =
          !!(this.vehicleFilter?.visibleOfflineGroups?.find(hiddenGroup => group.id === hiddenGroup.id));
        this.vehicleGroupsWithItems.push(
            {
              id: group.id,
              name: group.title,
              collapsed: !this.vehicleGroupExpansionModel.isSelected(group.id),
              offlineCollapsed: !this.offlineVehicleGroupExpansionModel.isSelected(group.id),
              visible: presentOnGroupFilter,
              offlineVisible: offlinePresentOnGroupFilter,
              activeShiftCount: 0,
            } as ActionGroupWithItems
        );
      }
    });
    // set asset list per group
    this.vehicleGroupsWithItems.forEach(group => {
      const groupVehicles = assets
          .filter(vehicle => vehicle.vehicleTypeId === group.id);
      group.activeShiftCount = groupVehicles.filter(vehicle => !!vehicle.shiftId).length;
      group.items = groupVehicles
          .filter(asset => asset.shiftStatus !== ShiftState.ENDED)
          .map(asset => {
            // on component init, expand the group for selected vehicle
            this.setInitialVehicleGroupExpansion(group, asset);
            return new LeftMenuItem(
                asset.id,
                this.getVehicleColor(AssetStatus.getAssetStatus(asset)),
                asset.name,
                asset.driverName,
                '',
                null,
                () => this.activeItem?.type === LeftPanelSection.VEHICLES && this.activeItem?.vehicleId === asset.id,
                () => false,
                () => !group.visible,
                () => this.onClick(asset),
                () => this.activeItem?.vehicleId !== asset.id && this.onHoverOn(asset), // disable hover when selected
                () => this.activeItem?.vehicleId !== asset.id && this.onHoverOff(asset),
                [],
            );
          });
      group.offlineItems = groupVehicles
        .filter(asset => asset.shiftStatus === ShiftState.ENDED)
        .map(asset => {
          this.setInitialVehicleGroupExpansion(group, asset);
          return new LeftMenuItem(
            asset.id,
            this.getVehicleColor(AssetStatus.OFFLINE),
            asset.name,
            '',
            '',
            null,
            () => this.activeItem?.type === LeftPanelSection.VEHICLES && this.activeItem?.vehicleId === asset.id,
            () => false,
            () => !group.offlineVisible,
            () => this.onClick(asset),
            () => this.activeItem?.vehicleId !== asset.id && this.onHoverOn(asset), // disable hover when selected
            () => this.activeItem?.vehicleId !== asset.id && this.onHoverOff(asset),
            [],
          );
        });
    });
    this.vehicleGroupsWithItems.sort((a, b) => {
      return a.name < b.name ? -1 : 1;
    });
  }

  private setInitialVehicleGroupExpansion(group: ActionGroupWithItems, asset: Asset) {
    if (this.initialExpansionSetting &&
      !!this.activeItem && this.activeItem.type === LeftPanelSection.VEHICLES && this.activeItem.vehicleId === asset.id
    ) {
      group.collapsed = false;
      this.vehicleGroupExpansionModel.select(asset.vehicleTypeId);
      if (asset.shiftStatus === ShiftState.ENDED) {
        // expand offline vehicles too
        group.offlineCollapsed = false;
        group.offlineVisible = true;
        this.offlineVehicleGroupExpansionModel.select(asset.vehicleTypeId);
      }
      this.initialExpansionSetting = false;
    }
  }

  private getVehicleColor(status: AssetStatus) {
    switch (status) {
      case AssetStatus.ONLINE:
        return '#1F8F3F';
      case AssetStatus.STATIONARY:
        return '#FB0505';
      case AssetStatus.PAUSED:
        return '#A0C4AA';
      case AssetStatus.ASSIGNMENT_PENDING:
        return '#FBBC05';
      case AssetStatus.ASSIGNMENT_REQUESTED:
        return '#FBBC05';
      case AssetStatus.ON_ASSIGNMENT:
        return '#62A4F9';
      case AssetStatus.OFFLINE:
      default:
        return '#A0A0A0';
    }
  }

  private setInsights() {
    if (this.hasFeatureFlag(FeatureFlagEnum.ReportShift)) {
      this.insightsSections.push(
          new ActionMenuItem(
              InsightsRoute.SHIFT_REPORT,
              'description',
              'Shift Report',
              '',
              'Retrieve shift based stats for your fleet',
              null,
              () => this.activeItem?.type === LeftPanelSection.INSIGHTS && this.activeItem?.insightName === InsightsRoute.SHIFT_REPORT,
              null,
              null,
              () => {
                this.selectReport(InsightsRoute.SHIFT_REPORT);
              },
              null,
              null,
              [],
          )
      );
    }
    this.insightsSections.push(
        new ActionMenuItem(
            InsightsRoute.SHIFT_LOOKUP,
            'description',
            'Shift Lookup',
            '',
            'Browse vehicles and drivers to review past shifts',
            null,
            () => this.activeItem?.type === LeftPanelSection.INSIGHTS && this.activeItem?.insightName === InsightsRoute.SHIFT_LOOKUP,
            null,
            null,
            () => {
              this.selectReport(InsightsRoute.SHIFT_LOOKUP);
            },
            null,
            null,
            [],
        )
    );
    if (this.hasFeatureFlag(FeatureFlagEnum.ReportDvir)) {
      this.insightsSections.push(
          new ActionMenuItem(
              InsightsRoute.DVIR_REPORT,
              'description',
              'Inspection Form Retrieval',
              '',
              'Review past inspection forms',
              null,
              () => this.activeItem?.type === LeftPanelSection.INSIGHTS && this.activeItem?.insightName === InsightsRoute.DVIR_REPORT,
              null,
              null,
              () => { this.selectReport(InsightsRoute.DVIR_REPORT); },
              null,
              null,
              [],
          ),
      );
    }
    if (this.hasFeatureFlag(FeatureFlagEnum.ReportImage)) {
      this.insightsSections.push(
          new ActionMenuItem(
              InsightsRoute.IMAGE_REPORT,
              'description',
              'Image Retrieval',
              '',
              'Retrieve images from past shifts',
              null,
              () => this.activeItem?.type === LeftPanelSection.INSIGHTS && this.activeItem?.insightName === InsightsRoute.IMAGE_REPORT,
              null,
              null,
              () => { this.selectReport(InsightsRoute.IMAGE_REPORT); },
              null,
              null,
              [],
          ),
      );
    }
    if (this.hasFeatureFlag(FeatureFlagEnum.AddressLookup)) {
      this.insightsSections.push(
          new ActionMenuItem(
              InsightsRoute.ADDRESS_LOOKUP,
              'map',
              'Address Lookup',
              '',
              'See every time a vehicle has passed an address',
              null,
              () => this.activeItem?.type === LeftPanelSection.INSIGHTS && this.activeItem?.insightName === InsightsRoute.ADDRESS_LOOKUP,
              null,
              null,
              () => {
                this.selectReport(InsightsRoute.ADDRESS_LOOKUP);
              },
              null,
              null,
              [],
          ),
      );
    }

    // todo: make feature flag or let it make available  for all or for all admins only
    this.insightsSections.push(
        new ActionMenuItem(
            InsightsRoute.DEVICE_REPORT,
            'description',
            'Device Report',
            '',
            'See devices information',
            null,
            () => this.activeItem?.type === LeftPanelSection.INSIGHTS && this.activeItem?.insightName === InsightsRoute.DEVICE_REPORT,
            null,
            null,
            () => {
              this.selectReport(InsightsRoute.DEVICE_REPORT);
            },
            null,
            null,
            [],
        ),
    );
  }

  ngOnDestroy() {
    this.openSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onHoverOn(asset: Asset) {
    if (asset.shiftStatus !== ShiftState.ENDED) {
      this.shiftManager.highlightShift(asset.shiftId, ShiftsManagerService.PANEL_SOURCE);
    } else {
      this.vehiclesManager.highlightVehicle(asset.id);
    }
  }

  onHoverOff(asset: Asset) {
    if (asset.shiftStatus !== ShiftState.ENDED) {
      this.shiftManager.highlightShift(null, ShiftsManagerService.PANEL_SOURCE);
    } else {
      this.vehiclesManager.highlightVehicle(null);
    }
  }

  onClick(asset: Asset) {
    this.shiftManager.highlightShift(null, ShiftsManagerService.PANEL_SOURCE);
    this.vehiclesManager.highlightVehicle(null);
    this.router.navigate(
        [`/${RootRoute.MAIN}`, MainRoute.VEHICLE, asset.id],
        {
          queryParamsHandling: 'merge',
          queryParams: {
            vehicleId: undefined,
          },
        }
    );
  }

  hasFeatureFlag(featureFlag: string): boolean {
    return this.configuration.featureFlags.find(value => value.isEnabled && value.name === featureFlag) !== undefined;
  }

  onStatusFilterChange(filter: MultiSelectFilter<AssetStatus>) {
    this.assetsManager.filterByStatus(filter.elements?.map(status => status.id));
  }

  toggleVehicleGroupExpansion(groupIndex: number) {
    const group = this.vehicleGroupsWithItems[groupIndex];
    group.collapsed = !group.collapsed;
    if (group.collapsed) {
      this.vehicleGroupExpansionModel.deselect(group.id);
    } else {
      this.vehicleGroupExpansionModel.select(group.id);
    }

    // automatically offline visible on expansion when no active shift
    if (group.activeShiftCount === 0) {
      this.toggleVehicleGroupOfflineExpansion(groupIndex);
    }
  }

  toggleVehicleGroupOfflineExpansion(groupIndex: number) {
    const group = this.vehicleGroupsWithItems[groupIndex];
    group.offlineCollapsed = !group.offlineCollapsed;
    if (group.offlineCollapsed) {
      this.offlineVehicleGroupExpansionModel.deselect(group.id);
    } else {
      this.offlineVehicleGroupExpansionModel.select(group.id);
    }

    // toggle visibility at the same time
    group.offlineVisible = group.visible && !group.offlineCollapsed;
    this.vehicleFilterComponent.toggleGroup(group.id, !group.visible, group.offlineVisible);
  }

  toggleVehicleGroupVisibility(groupIndex: number) {
    const group = this.vehicleGroupsWithItems[groupIndex];
    group.visible = !group.visible;
    group.offlineVisible = group.visible && !group.offlineCollapsed;

    this.vehicleFilterComponent.toggleGroup(group.id, !group.visible, group.offlineVisible);
  }

  /*
  not needed anymore
  toggleVehicleGroupOfflineVisibility(groupIndex: number) {
    const group = this.vehicleGroupsWithItems[groupIndex];
    group.offlineVisible = !group.offlineVisible;

    this.vehicleFilterComponent.toggleGroup(group.id, !group.visible, group.offlineVisible);
  }*/

  onVehicleFilterChange(filter: VehicleFilter) {
    this.vehicleFilter = filter;
    this.assetsManager.filterByLeftPanel(
      filter.hiddenGroups?.map(group => group.id),
      filter.visibleOfflineGroups?.map(group => group.id),
    );
  }

  toggleVehiclesExpansion() {
    this.vehiclesCollapsed = !this.vehiclesCollapsed;
  }

  toggleRoutesExpansion() {
    this.routesCollapsed = !this.routesCollapsed;
  }

  toggleInsightsExpansion() {
    this.insightsCollapsed = !this.insightsCollapsed;
  }

  private routeConfigToHierarchy(routeConfiguration: RouteConfigurationWithSchema): RouteHierarchyItem {
    if (!!routeConfiguration.schema.style) {
      // this.color = routeConfiguration.schema.style.color;
    }

    const items = RouteHierarchyItem.getVisibleItems(routeConfiguration.configuration.children, routeConfiguration.id);
    return {
      routeId: -1,
      routeName: routeConfiguration.name,
      children: items,
      childrenAttribute: '',
      value: String(routeConfiguration.id)
    };
  }

  selectReport(sectionId: string) {
    this.router.navigate(
        [`/${RootRoute.MAIN}`, MainRoute.INSIGHTS, sectionId],
        {
          queryParamsHandling: 'merge',
          queryParams: {
            vehicleId: this.activeItem?.vehicleId,
            routeId: this.activeItem?.routeId,
            routePath: this.activeItem?.routePath?.join(':::'),
          },
        },
    );
    this.showMapOverlay = MapOverlayType.FULL;
  }
}
