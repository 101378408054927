import { MapContentLayer } from '../MapContentLayer';
import { CircleLayerSpecification, LayerSpecification } from 'maplibre-gl';
import { MapContentSource } from '../MapContentSource';
import { MapStyles } from '../../../../../configuration/map-styles';

export class ObservationCircleLayer extends MapContentLayer {

  constructor(
    source: MapContentSource
  ) {
    super('observation-circle-layer', source);
  }

  toLayerSpecification(): LayerSpecification {
    return {
      id: this.layerId,
      type: 'circle',
      source: this.sourceRef.sourceId,
      paint: {
        'circle-color': [
          'case',
          ['boolean', ['get', 'highlighted']],
          MapStyles.HIGHLIGHTED_COLOR,
          ['all', ['has', 'color'], ['!=', ['get', 'color'], null]],
          ['get', 'color'],
          MapStyles.LIVE_COLOR,
        ],
        'circle-radius': 10,
        'circle-stroke-width': 2,
        'circle-stroke-color': 'white',
        'circle-opacity': 1.0
      },
      visibility: 'visible'
    } as CircleLayerSpecification;
  }

}
