import { Component, OnInit } from '@angular/core';
import {RootRoute} from '../../../../shared/models/angular-routing';
import {Router} from '@angular/router';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  close() {
    this.router.navigate(
        [`/${RootRoute.MAIN}`],
        {
          queryParamsHandling: 'merge',
        }
    );
  }
}
