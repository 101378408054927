import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from 'src/app/shared/material/material.module';
import {SharedModule} from 'src/app/shared/shared.module';
import {SettingsComponent} from './components/settings.component';
import {MyAccountComponent} from './components/my-account/my-account.component';
import {VehicleManagementModule} from './components/manage-vehicles/vehicle-management.module';
import {ObservationManagementModule} from './components/manage-observations/observation-management.module';
import {UserManagementModule} from './components/manage-users/user-management.module';
import {AboutComponent} from './components/about/about.component';
import {MarkdownModule} from 'ngx-markdown';
import {
  RouteConfigurationComponent
} from './components/manage-routes/route-configuration/route-configuration.component';
import {RouterModule} from '@angular/router';
import {SettingsRoutingModule} from './settings-routing.module';
import {CartegraphManagementModule} from './components/manage-cartegraph/cartegraph-management.module';
import {
  RouteConfigurationListComponent
} from './components/manage-routes/route-configuration-list/route-configuration-list.component';
import {HeadlineComponent} from './components/manage-public-portal/headline/headline.component';
import {
  MapLayerPreviewComponent
} from './components/manage-public-portal/map-layer-preview/map-layer-preview.component';
import {ManagePublicPortalComponent} from './components/manage-public-portal/manage-public-portal.component';
import {MatRipple} from '@angular/material/core';
import {NgOptimizedImage} from '@angular/common';
import {
  ManageVehicleInstallationModule
} from './components/manage-vehicle-installation/manage-vehicle-installation.module';
import {CardComponent} from '../../shared/components/card/card.component';
import {CardContentDirective, CardContentRawDirective} from '../../shared/components/card/card-content.directive';
import {
  ToggleExpansionPanelComponent
} from '../../shared/components/toggle-expansion-panel/toggle-expansion-panel.component';
import {ExpansionPanelComponent} from '../../shared/components/expansion-panel/expansion-panel.component';
import {
  DialogCreateContentBlockComponent,
  DialogUpdateContentBlockComponent
} from './components/manage-public-portal/dialogs-content-block/content-block-dialog-components';
import {ContentBlockComponent} from './components/manage-public-portal/content-block/content-block.component';
import {RouteTreeComponent} from './components/manage-routes/route-tree/route-tree.component';
import {UiBlockingLayerComponent} from './components/ui-blocking-layer/ui-blocking-layer.component';


@NgModule({
  declarations: [
    SettingsComponent,
    MyAccountComponent,
    AboutComponent,
    RouteConfigurationComponent,
    RouteConfigurationListComponent,
    HeadlineComponent,
    ContentBlockComponent,
    MapLayerPreviewComponent,
    DialogCreateContentBlockComponent,
    DialogUpdateContentBlockComponent,
    ManagePublicPortalComponent,
  ],
  imports: [
    SharedModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MaterialModule,
    VehicleManagementModule,
    ObservationManagementModule,
    UserManagementModule,
    MarkdownModule.forRoot(),
    SettingsRoutingModule,
    CartegraphManagementModule,
    MatRipple,
    NgOptimizedImage,
    ManageVehicleInstallationModule,
    CardComponent,
    CardContentDirective,
    CardContentRawDirective,
    ToggleExpansionPanelComponent,
    ExpansionPanelComponent,
    RouteTreeComponent,
    UiBlockingLayerComponent,
  ],
  providers: [],
})
export class SettingsModule {}
