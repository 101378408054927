import {Component, OnDestroy, OnInit} from '@angular/core';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {FeatureFlagEnum} from '../../../../shared/models/configuration.model';
import {Subscription} from 'rxjs';
import {ManageUsersComponent} from './manage-users.component';

@Component({
  standalone: true,
  selector: 'app-manage-users-main',
  templateUrl: './manage-users-main.component.html',
  imports: [
    ManageUsersComponent
  ]
})
export class ManageUsersMainComponent implements OnInit, OnDestroy {
  isLoading = false;
  cartegraphEnabled = false;
  private configurationSubscription: Subscription;

  constructor(private configurationService: ConfigurationService) {
  }

  ngOnInit() {
    this.isLoading = true;

    this.configurationSubscription = this.configurationService.sharedConfigurationModel.subscribe(cfg => {
      if (cfg) {
        this.cartegraphEnabled = this.configurationService.hasFeatureFlag(cfg.featureFlags, FeatureFlagEnum.CartegraphIntegration);
        this.isLoading = false;
      }
    });
  }

  ngOnDestroy() {
    this.configurationSubscription?.unsubscribe();
  }
}
