<mat-card appearance="outlined" class="mb-10 mt-10">

    <mat-card-header>
        <mat-card-title>Processing configuration</mat-card-title>
        <mat-card-subtitle>
            Behaviour of the processing
        </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content class="mt-25">

        <div class="form-row mb-15">
            <mat-form-field appearance="outline" color="accent" class="form-input" floatLabel="always">
                <mat-label>Processing type</mat-label>
                <mat-select placeholder="Type" [(ngModel)]="processing.processingType"
                            [ngModelOptions]="{standalone: true}">
                    <mat-option
                            *ngFor="let option of processingTypes"
                            [value]="option.type">{{ option.note }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
       </div>

        <div class="form-row mb-15">
            <mat-form-field appearance="outline" color="accent" class="form-input" floatLabel="always">
                <mat-label>Notification emails</mat-label>
                <input type="text" matInput
                       placeholder="1@email.com,2@email.com"
                       [(ngModel)]="emails" (ngModelChange)="updateEmails($event)">
            </mat-form-field>
        </div>

        <div class="form-row mb-15">
            <mat-form-field appearance="outline" color="accent" class="form-input" floatLabel="always">
                <mat-label>Exclude filter</mat-label>
                <input type="text" matInput
                       placeholder="lmu shift only, not found"
                       [(ngModel)]="excludeFilter" (ngModelChange)="updateExcludeFilter($event)">
            </mat-form-field>
        </div>

    </mat-card-content>

    <mat-card-footer>
        <div *ngIf="!isLoading && uiError && uiError.length > 0" class="w-100 mt-15 content">
            <span class="ui-error">{{ uiError }}</span>
        </div>
        <div class="form-row mb-25 mt-10">
            <button
                    mat-button
                    color="accent"
                    type="button"
                    (click)="writeChanges()"
            >
                <mat-icon>save</mat-icon>
                Update configuration
            </button>
        </div>
    </mat-card-footer>

</mat-card>

