import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {DeviceReportTableComponent} from '../device-report-table/device-report-table.component';
import {ReportsService} from '../../../../../data/reports/reports.service';
import moment, {Moment} from 'moment';
import {ConfigurationModel} from '../../../../../shared/models/configuration.model';
import {ToastService} from '../../../../../shared/services/toast.service';
import {Subscription} from 'rxjs';
import {ConfigurationService} from '../../../../../configuration/configuration.service';
import {DeviceReportRecordModel} from '../../../../../shared/models/device-report.model';
import {
  FilterBarSearchEvent
} from '../../../../../shared/components/filter-bar-composite/composite-filter-bar.component';
import {ActivatedRoute, Router} from '@angular/router';
import {CloseableAndFilterableInsight} from '../../../closeable-filterable-insight.class';

@Component({
  selector: 'app-device-report',
  templateUrl: './device-report.component.html',
  styleUrls: ['./device-report.component.scss'],
})
export class DeviceReportComponent extends CloseableAndFilterableInsight implements AfterViewInit, OnInit, OnDestroy {

  configuration: ConfigurationModel;
  @Input() isBrowserMobile?: boolean;

  pageSize = 50;
  pageIndex = 0;
  totalElements = 0;
  private searchCriteria: {};

  @ViewChild(DeviceReportTableComponent) reportTableComponent: DeviceReportTableComponent;

  dataSource: MatTableDataSource<DeviceReportRecordModel> = new MatTableDataSource([]);
  isLoading = false;
  isScrollLoading = false;

  private configurationSubscription: Subscription;

  constructor(private reportService: ReportsService,
              private configurationService: ConfigurationService,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              private toast: ToastService) {
    super(router, activatedRoute);
  }

  ngOnInit() {
    this.configurationSubscription = this.configurationService.sharedConfigurationModel.subscribe(model => {
      if (model) {
        this.configuration = model;
      }
    });
  }

  ngAfterViewInit() {
    // not needed, filters will do the job
    this.loadReport();
  }

  ngOnDestroy() {
    this.configurationSubscription?.unsubscribe();
  }

  toggleLoading() {
    setTimeout(() => this.isLoading = !this.isLoading);
  }

  // null or non-empty array
  loadReport() {
    if (!!this.reportTableComponent) {
      this.toggleLoading();
      this.pageIndex = 0;
      this.isScrollLoading = false;
      this.reportService.getDeviceReport(
        this.searchCriteria,
        this.pageIndex,
        this.pageSize,
        this.reportTableComponent.getSort(),
      ).toPromise().then(response => {
        // console.log(`initialized, load count ${response.data.records.content.length} of ${response.data.records.totalElements}`);
        this.dataSource.data = response.data.records.content;
        this.totalElements = response.data.records.totalElements;
        this.pageSize = response.data.records.pageable.pageSize;
        this.toggleLoading();
      }).catch(error => {
        this.toast.longer('Error while fetching initial data from the server');
        console.error(error);
      }).finally(() => {
        this.toggleLoading();
      });
    }
  }

  extendReport() {
    if (!this.isScrollLoading && this.dataSource.data.length < this.totalElements) {
      this.pageIndex++;
      this.isScrollLoading = true;
      this.reportService.getDeviceReport(
        this.searchCriteria,
        this.pageIndex,
        this.pageSize,
        this.reportTableComponent.getSort(),
      ).toPromise().then(response => {
        const extendedData = [...this.dataSource.data];
        extendedData.push(...response.data.records.content);
        this.dataSource.data = extendedData;
      }).catch(error => {
        this.toast.longer('Error while fetching initial data from the server');
        console.error(error);
      }).finally(() => {
        this.isScrollLoading = false;
      });
    }
  }

  private _fileNameWithDateRange(from: Moment, to: Moment): string {
    const fromStr = !!from ? `_${from.format('MM-DD-YYYY')}` : '';
    const toStr = !!to ? to.format('MM-DD-YYYY') : `${moment().format('MM-DD-YYYY')}`;
    return `device_report${fromStr}_${toStr}`;
  }

  doSearch(searchEvents: FilterBarSearchEvent[]) {
    this.searchCriteria = {};
    if (!!searchEvents && searchEvents.length > 0) {
      searchEvents.forEach(event => {
        this.searchCriteria[event.id] = event.search;
      });
    }
    this.loadReport();
  }
}
