import {Component, OnDestroy, OnInit} from '@angular/core';
import {RightPanel} from '../right-panel.class';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfigurationService} from '../../../../../configuration/configuration.service';
import {AssetsManagerService} from '../../../../../data/assets/assets-manager.service';
import {ToastService} from '../../../../../shared/services/toast.service';
import {ObservationsManagerService} from '../../../../../data/observations/observations-manager.service';
import {Observation} from '../../../../../shared/models/observation';
import {ActionMenuItem, ActionMenuItemSubMenu} from '../../../../../shared/models/action-menu-item.class';
import {ShortDateOrTimePipe} from '../../../../../shared/formatting/short-date-or-time.pipe';
import {SecurityService} from '../../../../../security/security.service';
import {ObservationsService} from '../../../../../data/observations/observations.service';
import {DrawerContent} from '../../../../../layouts/right-drawer/right-drawer.component';
import { Asset } from '../../../models/asset.class';

@Component({
  selector: 'app-observations',
  templateUrl: './observations.component.html',
  styleUrls: ['./observations.component.scss']
})
export class ObservationsComponent extends RightPanel implements OnInit, OnDestroy {

  selectedVehicle: Asset;
  initialAction = false;

  selectedObservationId: number;
  allObservations: Observation[];
  items: ActionMenuItem[];
  isAdmin = false;

  constructor(
      private securityService: SecurityService,
      protected router: Router,
      protected activatedRoute: ActivatedRoute,
      protected configurationService: ConfigurationService,
      protected assetsManager: AssetsManagerService,
      private observationsManager: ObservationsManagerService,
      private observationsService: ObservationsService,
      private shortDateOrTimePipe: ShortDateOrTimePipe,
      private toast: ToastService,
  ) {
    super(
        DrawerContent.OBSERVATION,
        router,
        activatedRoute,
        configurationService,
        assetsManager,
    );
  }

  ngOnInit(): void {
    this.isAdmin = this.securityService.isAdminSync();
    this.initialize();
    this.loadConfiguration();

    const observationSubscription = this.observationsManager.filteredObservations$.subscribe(allObservations => {
      let observations = [];
      if (!!allObservations && allObservations.length > 0) {
        observations = allObservations;
      }
      if (this.allObservations?.length !== observations.length) {
        this.allObservations = observations;
        if (this.initialAction) {
          this.onAssetAction();
          this.initialAction = false;
        } else {
          this.observationsToItems();
        }
      }
    });
    this.openSubscriptions.push(observationSubscription);
  }

  ngOnDestroy(): void {
    this.unsubscribe();
    this.observationsManager.hideAll();
    this.selectedVehicle = undefined;
    this.items = undefined;
  }

  onAssetsUpdate() {
    // do nothing
  }

  onAssetChange() {
    this.selectedObservationId = null;

    // do not filter observations at all
    this.observationsManager.filterByShiftIds(null);
  }

  onAssetAction() {
    if (!!this.allObservations) {
      this.selectedVehicle = this.asset;
      this.observationsToItems();
    } else {
      this.initialAction = true;
    }
  }

  onRouteAction() {
    // do nothing
  }

  onConfigurationLoad() {
    // do nothing
  }

  private observationsToItems() {
    if (!!this.allObservations) {
      const filteredObservations = this.allObservations
          .filter(observation => !!this.selectedVehicle ? observation.vehicle.id === this.selectedVehicle.id : true)
          .sort((a, b) => {
            return +new Date(b.location.time) - +new Date(a.location.time);
          });
      this.items = filteredObservations.map(observation => this.observationToActionMenuItem(observation));
    }
  }

  private selectObservation(observation: Observation) {
    this.selectedObservationId = observation.id;
    this.observationsManager.highlightObservation(observation.id, ObservationsManagerService.LIST_ACTION_SOURCE);
  }

  /*private viewObservation(observation: Observation) {
    this.observationsManager.highlightObservation(observation.id, ObservationsManagerService.LIST_ACTION_SOURCE);
    this.router.navigate(['/live-map', LiveMapTab.OBSERVATIONS], {
      queryParams: { id: observation.id, scrollToView: true },
    });
  }*/

  private deleteObservation(observationId: number) {
    this.observationsService.deleteObservation(observationId).then(() => {
      console.log(`Observation ${observationId} deleted!`);
    }).catch(error => {
      console.error('Observation deletion failed!');
      console.error(error);
    });
  }

  private viewShiftDetail(shiftId: number) {
    this.router.navigate(['/shift-detail', shiftId], {
      queryParams: {
        source: 'observations',
      },
    });
  }

  private observationToActionMenuItem(observation: Observation): ActionMenuItem {
    const actions: ActionMenuItemSubMenu[] = [];
    if (this.isAdmin) {
      actions.push(
          new ActionMenuItemSubMenu(
              'delete',
              'Delete',
              () => this.deleteObservation(observation.id),
          )
      );
    }
    // TODO fixme
    /*if (!observation.activeShift) {
      actions.push(
          new ActionMenuItemSubMenu(
              'map',
              'View Shift Detail',
              () => this.viewShiftDetail(observation.shiftId),
          )
      );
    }*/
    return new ActionMenuItem(
        observation.id,
        'stars',
        observation.observationType.title,
        observation.observationType.group?.name,
        this.shortDateOrTimePipe.transform(observation.location.time),
        null,
        () => this.selectedObservationId === observation.id,
        null,
        null,
        () => this.selectObservation(observation),
        null,
        null,
        actions,
    );
  }

  onSelectedVehicleChange() {
    this.observationsToItems();
  }

  clearSelectedVehicle() {
    this.selectedVehicle = null;
    this.observationsToItems();
  }
}
