import {ActivityFilter} from './settings.service';
import {ExpressionFilterSpecification} from 'maplibre-gl';
import {ShiftModel} from '../shared/models/shift.model';

export class MapFilters {
    static getInactiveShiftsFilter(
        gpsTracksMode: boolean,
        selectedVehicleMode: boolean,
        activeShiftIds: number[],
        vehicleIds: number[],
        hours: number,
        flagsFilter: any[],
        showRecent: boolean
    ) {
        const filter: any[] = ['all'];
        if (!showRecent || !gpsTracksMode || (gpsTracksMode && selectedVehicleMode)) {
            // hiding all if recent are not allowed
            // OR different theme from GPS Tracks, filtering all out
            // OR GPS tracks with selected vehicle mode
            filter.push(['boolean', false]);
            return filter;
        }
        if (gpsTracksMode) {
            // GPS Tracks theme, filtering all inactive shifts
            if (activeShiftIds) {
                // filter only inactive shifts
                filter.push(['!', ['in', ['get', 'sessionkey'], ['literal', activeShiftIds]]]);
            }
            // apply vehicle filter only if a vehicle is selected
            if (selectedVehicleMode && vehicleIds) {
                // only selected vehicles
                filter.push(['in', ['get', 'locationsourceid'], ['literal', vehicleIds]]);
            }
            // no highlighted shift exception here, inactive shift cannot be highlighted
        }

        if (hours && hours < 12) {
            // apply hour filter from track bar
            const minTime = new Date().getTime() / 1000 - hours * 3600;
            filter.push(['>=', ['get', 'startunixtime'], minTime]);
        }

        // apply flagsFilter
        if (flagsFilter.length > 0) {
            filter.push(flagsFilter);
        }

        return filter;
    }

    static getActiveShiftsFilter(
        gpsTracksMode: boolean,
        selectedVehicleMode: boolean,
        activeShiftIds: number[],
        vehicleIds: number[],
        hours: number,
        flagsFilter: any[],
        highlightedShift: ShiftModel,
    ) {
        const filter: any[] = ['all'];
        if (gpsTracksMode) {
            if (!selectedVehicleMode) {
                // GPS Tracks theme, no vehicle selected, filtering all active shifts
                if (activeShiftIds) {
                    // filter only active shifts
                    filter.push(['in', ['get', 'sessionkey'], ['literal', activeShiftIds]]);
                }
            } else {
                // GPS Tracks theme, vehicle selected, filtering active shifts for selected vehicles plus highlighted shift
                const shiftIds = !!highlightedShift ? activeShiftIds.concat([highlightedShift.id]) : activeShiftIds;
                const selectedVehicleIds = !!highlightedShift ? vehicleIds.concat([highlightedShift.vehicleId]) : vehicleIds;
                if (shiftIds) {
                    filter.push(['in', ['get', 'sessionkey'], ['literal', shiftIds]]);
                }
                if (selectedVehicleIds) {
                    filter.push(['in', ['get', 'locationsourceid'], ['literal', selectedVehicleIds]]);
                }
            }

            if (hours && hours < 12) {
                // apply hour filter from track bar
                const minTime = new Date().getTime() / 1000 - hours * 3600;
                filter.push(['>=', ['get', 'startunixtime'], minTime]);
            }

            // apply flagsFilter
            if (flagsFilter.length > 0) {
                filter.push(flagsFilter);
            }
        } else {
            // different theme from GPS Tracks
            if (!selectedVehicleMode) {
                // no selected vehicle
                if (!!highlightedShift) {
                    // shift highlighted, filtering the shift
                    filter.push(['==', ['get', 'sessionkey'], highlightedShift?.id]);
                } else {
                    // no highlighted shift, filtering all out
                    filter.push(['boolean', false]);
                    return filter;
                }
            } else {
                // selected vehicle, filtering active shifts for selected vehicles plus highlighted shift
                const shiftIds = !!highlightedShift ? activeShiftIds.concat([highlightedShift.id]) : activeShiftIds;
                const selectedVehicleIds = !!highlightedShift ? vehicleIds.concat([highlightedShift.vehicleId]) : vehicleIds;
                if (shiftIds) {
                    filter.push(['in', ['get', 'sessionkey'], ['literal', shiftIds]]);
                }
                if (selectedVehicleIds) {
                    filter.push(['in', ['get', 'locationsourceid'], ['literal', selectedVehicleIds]]);
                }
            }
        }

        return filter;
    }

    static getTrackFilter(trackFilter: ActivityFilter): any[] {
        const filter: any[] = ['any'];
        let bitNumbers;
        switch (trackFilter) {
            case ActivityFilter.PLOWING:
                bitNumbers = [0, 1, 2, 3, 4];
                break;
            case ActivityFilter.GRANULAR_SPREADING:
                bitNumbers = [5, 6, 7, 8, 9];
                break;
            case ActivityFilter.LIQUID_SPREADING:
                bitNumbers = [10, 11, 12, 13, 14];
                break;
            case ActivityFilter.MOWING:
                bitNumbers = [15, 16, 17, 18, 19];
                break;
            case ActivityFilter.SWEEPING:
                bitNumbers = [20, 21, 22, 23, 24];
                break;
            case ActivityFilter.NONE:
            default:
                filter.push(['boolean', true]);
                return filter;
        }
        for (const bitNumber of bitNumbers) {
            filter.push(['>=', ['%', ['get', 'flags'], Math.pow(2, bitNumber + 1)], Math.pow(2, bitNumber)]);
        }
        return filter;
    }

    static getShiftGeoJsonFilter(
        trackFilter: ActivityFilter,
        positionFilterFrom?: number,
        positionFilterTo?: number,
        shiftIds?: number[], // live map parameter only
    ) {
        const filter: any[] = ['all'];
        if (!!positionFilterFrom) {
            filter.push(['>=', ['get', 'time_start'], positionFilterFrom]);
        }
        if (!!positionFilterTo) {
            filter.push(['<=', ['get', 'time_end'], positionFilterTo]);
        }
        if (!!shiftIds) {
            filter.push(['in', ['get', 'sessionkey'], ['literal', shiftIds]]);
        }

        let bitNumbers;
        switch (trackFilter) {
            case ActivityFilter.PLOWING:
                bitNumbers = [0, 1, 2, 3, 4];
                break;
            case ActivityFilter.GRANULAR_SPREADING:
                bitNumbers = [5, 6, 7, 8, 9];
                break;
            case ActivityFilter.LIQUID_SPREADING:
                bitNumbers = [10, 11, 12, 13, 14];
                break;
            case ActivityFilter.SPREADING:
                bitNumbers = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
                break;
            case ActivityFilter.MOWING:
                bitNumbers = [15, 16, 17, 18, 19];
                break;
            case ActivityFilter.SWEEPING:
                bitNumbers = [20, 21, 22, 23, 24];
                break;
            case ActivityFilter.PLOWING_MOWING_SWEEPING:
                bitNumbers = [0, 1, 2, 3, 4, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
                break;
            case ActivityFilter.NONE:
            default:
                return filter;
        }
        const trackTypeFilter: any[] = ['any'];
        for (const bitNumber of bitNumbers) {
            trackTypeFilter.push(['>=', ['%', ['get', 'flags'], Math.pow(2, bitNumber + 1)], Math.pow(2, bitNumber)]);
        }
        filter.push(trackTypeFilter);

        return filter;
    }

    /**
     * -2 equals none
     * -1 equals all
     * any number filters the route ids
     */
    static getRoutesFilter(
        configId: number,
        routeLayerId: number,
        configIdFilter: number,
        routeIdFilter: number[],
    ): ExpressionFilterSpecification {
        const filter: ExpressionFilterSpecification = ['all'];
        // filter only route id layer
        filter.push(['==', ['get', 'routeid'], routeLayerId]);
        filter.push(['==', ['get', 'configid'], configId]);
        if (configIdFilter) {
            filter.push(['==', ['get', 'configid'], configIdFilter]);
        }
        if (routeIdFilter && routeIdFilter.length > 0 &&
            (routeIdFilter.length > 1 || routeIdFilter[0] >= 0)
        ) {
            // selected routes
            filter.push(['in', ['get', 'routeid'], ['literal', routeIdFilter]]);
        } else if (routeIdFilter && routeIdFilter.length === 1 && routeIdFilter[0] === -2) {
            // none
            filter.push(['boolean', false]);
        } // else no filter = show all

        return filter;
    }
}

