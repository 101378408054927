<div class="row-with-select">
    <div class="field-row-title" *ngIf="primaryText?.length > 0">
        {{ primaryText }}
        <div class="text-lighter" *ngIf="secondaryText?.length > 0">
            {{ secondaryText }}
        </div>
    </div>
    <div class="field-row-element">
        <mat-form-field color="primary" appearance="outline" subscriptSizing="dynamic">
            <mat-select (selectionChange)="handleChange($event)"
                        [(ngModel)]="model" [compareWith]="modelComparison" [disabled]="disabled">
				@if (nullLabel) {
					<mat-option value="">{{ nullLabel }}</mat-option>
				}
                @for (optionValue of list; track $index) {
					<mat-option [value]="listId ? optionValue[listId] : optionValue">{{ optionValue[listLabel] }}</mat-option>
                }
            </mat-select>
        </mat-form-field>
    </div>

</div>
