import {NgModule} from '@angular/core';
import {ManageVehiclesComponent} from './manage-vehicles.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../../../../shared/material/material.module';
import {SharedModule} from '../../../../shared/shared.module';
import {UniqueValueDirective} from '../../../../shared/directives/unique-value.directive';
import {ImportVehiclesComponent} from './import-vehicles.component';
import {RouterModule} from '@angular/router';
import {ImportVehiclesCartegraphComponent} from './vehicle-import-cartegraph/import-vehicles-cartegraph.component';
import {EquipmentComponent} from './equipment/equipment.component';
import {DigitalSpreaderComponent} from './digital-spreader/digital-spreader.component';
import {
  DialogConfirmDeleteVehicleCategoryComponent,
  DialogCreateVehicleCategoryComponent
} from './dialogs-vehicle-group/vehicle-group-dialog-components';
import {CardComponent} from '../../../../shared/components/card/card.component';
import {CardContentDirective, CardContentRawDirective} from '../../../../shared/components/card/card-content.directive';
import {RowWithButtonComponent} from '../row-with-button/row-with-button.component';
import {ConfirmDeletionDialogComponent} from '../confirm-deletion-dialog/confirm-deletion-dialog.component';
import {
  AddOrImportVehicleDialogComponent,
} from './add-or-import-vehicle-dialog/add-or-import-vehicle-dialog.component';
import {UiBlockingLayerComponent} from '../ui-blocking-layer/ui-blocking-layer.component';
import {RowWithSelectComponent} from '../row-with-select/row-with-select.component';
import {RowWithToggleComponent} from '../row-with-toggle/row-with-toggle.component';
import {VehicleGroupEditComponent} from './vehicle-group-edit/vehicle-group-edit.component';
import {VehicleEditComponent} from './vehicle-edit/vehicle-edit.component';
import {CreateVehicleComponent} from './edit-vehicle/create-vehicle.component';
import {DialogConfirmDeleteVehicleComponent} from './dialogs-vehicle/dialog-confirm-delete-vehicle.component';
import {DialogMoveVehicleComponent} from './dialogs-vehicle/dialog-move-vehicle.component';
import {DialogVehicleHardwareComponent} from './edit-vehicle/update-vehicle-hardware.component';
import {DialogVehicleCameraComponent} from './edit-vehicle/update-vehicle-camera.component';
import {ExpansionPanelComponent} from '../../../../shared/components/expansion-panel/expansion-panel.component';

@NgModule({
  declarations: [
    ManageVehiclesComponent,
    ImportVehiclesComponent,
    ImportVehiclesCartegraphComponent,
    DialogConfirmDeleteVehicleCategoryComponent,
    DialogConfirmDeleteVehicleComponent,
    DialogCreateVehicleCategoryComponent,
    DialogMoveVehicleComponent,
    DialogVehicleHardwareComponent,
    DialogVehicleCameraComponent,
    VehicleGroupEditComponent,
    VehicleEditComponent,
    UniqueValueDirective,
    EquipmentComponent,
    DigitalSpreaderComponent,
    CreateVehicleComponent,
    AddOrImportVehicleDialogComponent,
  ],
  exports: [
    ManageVehiclesComponent,
    ImportVehiclesComponent,
    UniqueValueDirective,
    EquipmentComponent,
    DigitalSpreaderComponent,
    CreateVehicleComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule,
    RouterModule,
    CardComponent,
    CardContentDirective,
    RowWithButtonComponent,
    ConfirmDeletionDialogComponent,
    UiBlockingLayerComponent,
    RowWithSelectComponent,
    RowWithToggleComponent,
    CardContentRawDirective,
    ExpansionPanelComponent,
  ]
})
export class VehicleManagementModule {
}
