import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {HttpErrorHandler} from '../../http.error.handler';
import {environment} from '../../../environments/environment';
import {JsonApiResponse} from '../../shared/models/JsonApiResponse';
import {VehicleInstallationModel} from '../../shared/models/vehicle-installation.model';
import {Page} from '../../shared/models/Page';

@Injectable({
  providedIn: 'root'
})
export class VehicleInstallationService {

  constructor(private http: HttpClient) {
  }

  getVehicleInstallations(page: number = 0, size: number = 25, sort?: string) {
    const params = {
      page: String(page),
      size: String(size),
      sort
    };
    return this.http.get<JsonApiResponse<Page<VehicleInstallationModel[]>>>(`${environment.services.service}v1/vehicle-installation/page/`, {params})
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      );
  }

  getVehicleInstallationsReportCSV() {
    const params = {};
    return this.http.get(
      `${environment.services.service}v1/vehicle-installation/csv`, {params, responseType: 'blob'}
    ).pipe(catchError(HttpErrorHandler.handleError));
  }

}
