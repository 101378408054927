@if (isLoading || isSubmitting) {
	<mat-spinner class="mx-a" diameter="30"></mat-spinner>
}

<form [formGroup]="userForm" *ngIf="!isLoading && !isSubmitting && !isSubmitted"
	class="user-edit" style="display: flex; flex-direction: column; align-content: stretch">
	<p *ngIf="(isModeUpdate() || isModeSelfUpdate()) && !isJustDriver">
		<mat-form-field appearance="outline" color="accent">
			<mat-label>Email</mat-label>
			<input matInput
				   formControlName="email">
		</mat-form-field>
	</p>

	<p *ngIf="!isModeSelfUpdate()">
		<mat-form-field appearance="outline" color="accent">
			<mat-label>User role</mat-label>
			<mat-select formControlName="userType">
				<mat-option *ngFor="let userType of userTypes" [value]="userType">{{userType.label}}</mat-option>
			</mat-select>
		</mat-form-field>
	</p>

	<p>
		<mat-form-field appearance="outline" color="accent">
			<mat-label>First Name</mat-label>
			<input matInput
				   autocomplete="off"
				   formControlName="givenName"
				   required>
			<mat-error *ngIf="ngGivenName?.invalid && ngGivenName?.errors?.required">
				First name is required.
			</mat-error>
		</mat-form-field>
	</p>

	<p>
		<mat-form-field appearance="outline" color="accent">
			<mat-label>Last Name</mat-label>
			<input matInput
				   autocomplete="off"
				   formControlName="familyName"
				   required>
			<mat-error *ngIf="ngFamilyName?.invalid && ngFamilyName?.errors?.required">
				Last name is required.
			</mat-error>
		</mat-form-field>
	</p>

	<p *ngIf="isModeCreate() && ngEmailPrefix">
		<mat-form-field appearance="outline" color="accent">
			<mat-label>Email</mat-label>
			<input matInput
				   autocomplete="off"
				   formControlName="emailPrefix"
				   required>
			<span matTextSuffix>{{configuration.driverSuffix}}</span>
			<mat-error *ngIf="ngEmailPrefix?.invalid && ngEmailPrefix?.errors?.required">
				Email is required.
			</mat-error>
			<mat-error *ngIf="ngEmailPrefix?.invalid && ngEmailPrefix?.errors?.pattern">
				Email format is not valid.
			</mat-error>
		</mat-form-field>
	</p>

	<p *ngIf="ngPhoneNumber" class="phone-number-forms">
    <mat-form-field appearance="outline" color="accent" style="width: 145px">
      <mat-label>Country Code</mat-label>
      <mat-select formControlName="countryCode" required>
        <mat-option [value]="'+1'">(+1)</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent" style="width: unset; flex-grow: 1">
      <mat-label>Phone Number</mat-label>
      <input matInput
           autocomplete="off"
           type="tel"
           formControlName="phoneNumberWithOutCountryCode">
      <mat-error *ngIf="ngPhoneNumber?.invalid && ngPhoneNumber?.errors?.pattern">
        Please enter a valid phone number. (10 digits)
      </mat-error>
    </mat-form-field>
	</p>

	<p *ngIf="ngHideFromLogin && !isModeSelfUpdate()">
		<mat-slide-toggle labelPosition="before" formControlName="hideFromLogin" [hideIcon]="true">
			<mat-label>Hide from PlowOps mobile login</mat-label>
		</mat-slide-toggle>
	</p>

	<p class="ui-error" *ngIf="uiError">
		{{ uiError }}
	</p>
</form>

<p *ngIf="isSubmitted">
	<ng-container *ngIf="isModeCreate()">User created.</ng-container>
	<ng-container *ngIf="isModeUpdate() || isModeSelfUpdate()">User updated.</ng-container>
</p>
