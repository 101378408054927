import {
  CircleLayerSpecification,
  GeoJSONSource,
  LayerSpecification,
  LineLayerSpecification,
  LngLatBounds,
  LngLatLike,
  Map as MapLibre,
  RasterLayerSpecification,
  RasterSourceSpecification,
  RasterTileSource, RequestParameters, RequestTransformFunction,
  SourceSpecification,
  SymbolLayerSpecification,
  VectorSourceSpecification
} from 'maplibre-gl';
import {SecurityService} from '../../../security/security.service';
import {BaseMapType, ISettingKeyValuePair, SettingsService} from '../../../configuration/settings.service';
import {ConfigurationModel, FeatureFlagEnum, MapLayer} from '../../models/configuration.model';
import {MapLayerType} from '../../models/map-layer.model';
import {Subscription} from 'rxjs';
import {ObservationMapMarkerService} from './services/observation-map-marker.service';
import {VehicleMapMarkerService} from './services/vehicle-map-marker.service';
import {ImageMapMarkerService} from './services/image-map-marker.service';
import {PopupInfoService} from './services/popup-info.service';
import {BreadcrumbInfoWindowService} from './services/breadcrumb-info-window.service';
import {MapEventService} from './services/map-event.service';
import {TracksVectorTilesLayerService} from './services/tracks-vector-tiles-layer.service';
import {TracksGeoJsonLayerService} from './services/tracks-geo-json-layer.service';
import {AddressLookupMapMarkerService} from './services/address-lookup-map-marker.service';

import {RoadStatusService} from './services/road-status.service';
import {RoadStatusCoverageLayerService} from './services/road-status-coverage-layer.service';
import {RoadStatusCurrencyLayerService} from './services/road-status-currency-layer.service';
import {MapControlService} from './services/map-control.service';
import {RoutesLayerService} from './services/routes-layer.service';
import {WeatherRadarLayerService} from './services/weather-radar-layer.service';
import {WeatherWarningsLayerService} from './services/weather-warnings-layer.service';
import {TrafficLayerService} from './services/traffic-layer.service';
import {ExtAuthService} from '../../../data/ext-auth/ext-auth.service';
import moment from 'moment';
import {environment} from '../../../../environments/environment';
import {RouteSourceService} from './services/route-source.service';
import {StaticRoutesLayerService} from './services/static-routes-layer.service';

interface ImageDef {
  name: string;
  url: string;
  options: any;
}

export class MapLayersManager {

  // use AccuTerra Maps PROD for PlowOps PROD and
  // AccuTerra Maps STAGING for other PlowOps environments
  public static ACCUTERRA_API_KEY = environment.name === 'local' || environment.name === 'dev'
    ? 'a9EvGjUQ7R88aRqfwve6c70QMKqzXxDC6kOHV5Ww'
    : 'xv77dLihsm7ggIOc8Kn1X64sMFna09u32iBNmiHU';
  public static ACCUTERRA_DOMAIN = environment.name === 'local' || environment.name === 'dev'
    ? 'maps.location-dev.neotreks.com'
    : 'maps.location-prod.neotreks.com';

  public static ACCUTERRA_OUTDOORS_MAP_STYLE = `https://${MapLayersManager.ACCUTERRA_DOMAIN}/v1/styles/accuterra-outdoors/style.json?key=${MapLayersManager.ACCUTERRA_API_KEY}`;
  public static ACCUTERRA_OUTDOORS_M_MAP_STYLE = `https://${MapLayersManager.ACCUTERRA_DOMAIN}/v1/styles/accuterra-outdoors-m/style.json?key=${MapLayersManager.ACCUTERRA_API_KEY}`;
  public static ACCUTERRA_DARK_MAP_STYLE = `https://${MapLayersManager.ACCUTERRA_DOMAIN}/v1/styles/dark-grey/style.json?key=${MapLayersManager.ACCUTERRA_API_KEY}`;
  public static ACCUTERRA_DARK_M_MAP_STYLE = `https://${MapLayersManager.ACCUTERRA_DOMAIN}/v1/styles/dark-grey-m/style.json?key=${MapLayersManager.ACCUTERRA_API_KEY}`;
  public static ACCUTERRA_WINTER_MAP_STYLE = `https://${MapLayersManager.ACCUTERRA_DOMAIN}/v1/styles/winter/style.json?key=${MapLayersManager.ACCUTERRA_API_KEY}`;
  public static ACCUTERRA_WINTER_M_MAP_STYLE = `https://${MapLayersManager.ACCUTERRA_DOMAIN}/v1/styles/winter-m/style.json?key=${MapLayersManager.ACCUTERRA_API_KEY}`;
  public static IMAGERY_MAP_STYLE = `https://${MapLayersManager.ACCUTERRA_DOMAIN}/v1/styles/satellite/style.json?key=${MapLayersManager.ACCUTERRA_API_KEY}`;
  public static IMAGERY_M_MAP_STYLE = `https://${MapLayersManager.ACCUTERRA_DOMAIN}/v1/styles/satellite-m/style.json?key=${MapLayersManager.ACCUTERRA_API_KEY}`;

  private layersMap = new Map<string, string>();
  private readonly imagesMap = new Map<string, ImageDef>();
  private settingsSubscription: Subscription;
  private readonly refreshTimers: Map<string, NodeJS.Timeout> = new Map<string, NodeJS.Timeout>();

  constructor(
    private map: MapLibre,
    private configuration: ConfigurationModel,
    private settingsService: SettingsService,
    private securityService: SecurityService,
    private observationMapMarkerService: ObservationMapMarkerService,
    private vehicleMapMarkerService: VehicleMapMarkerService,
    private imageMapMarkerService: ImageMapMarkerService,
    private popupInfoService: PopupInfoService,
    private breadcrumbInfoWindowService: BreadcrumbInfoWindowService,
    private mapEventService: MapEventService,
    private mapControlService: MapControlService,
    private tracksVectorTilesLayerService: TracksVectorTilesLayerService,
    private tracksGeoJsonLayerService: TracksGeoJsonLayerService,
    private routeSourceService: RouteSourceService,
    private routesLayerService: RoutesLayerService,
    private staticRoutesLayerService: StaticRoutesLayerService,
    private roadStatusService: RoadStatusService,
    private roadStatusCoverageLayerService: RoadStatusCoverageLayerService,
    private roadStatusCurrencyLayerService: RoadStatusCurrencyLayerService,
    private addressLookupMapMarkerService: AddressLookupMapMarkerService,
    private weatherRadarLayerService: WeatherRadarLayerService,
    private weatherWarningsLayerService: WeatherWarningsLayerService,
    private trafficLayerService: TrafficLayerService,
    private extAuthService: ExtAuthService,
  ) {
    this.settingsSubscription =
      this.settingsService.settingsChangedObservable.subscribe(
        (keyValuePair: ISettingKeyValuePair) => {
          this.onSettingsChanged(keyValuePair.key, keyValuePair.value);
        }
      );
  }

  init() {
    // route source and static route layers initialization
    this.routeSourceService.init(this, true);
    this.staticRoutesLayerService.init(this);

    // custom overlays
    this.addOverlayMapLayers();

    // weather layers
    this.weatherRadarLayerService.init(
        this,
        this.hasFeatureFlag(FeatureFlagEnum.Weather),
    );
    this.weatherWarningsLayerService.init(
        this,
        this.hasFeatureFlag(FeatureFlagEnum.Weather),
        this.configuration,
    );
    this.trafficLayerService.init(
        this,
        this.hasFeatureFlag(FeatureFlagEnum.Traffic)
    );

    // heat layers
    this.roadStatusService.init(
      this,
      this.hasFeatureFlag(FeatureFlagEnum.RoadStatus)
    );
    this.roadStatusCoverageLayerService.init(
      this,
      this.hasFeatureFlag(FeatureFlagEnum.RoadStatus)
    );
    this.roadStatusCurrencyLayerService.init(
      this,
      this.hasFeatureFlag(FeatureFlagEnum.RoadStatus)
    );

    // routes layers
    this.routesLayerService.init(this, true);

    // tracks
    this.tracksVectorTilesLayerService.init(this, true);
    this.tracksGeoJsonLayerService.init(this, true);

    // image markers
    this.imageMapMarkerService.init(this);

    // observation markers
    this.observationMapMarkerService.init(this);

    // vehicle markers
    this.vehicleMapMarkerService.init(this);


    // vehicle and observation marker info
    this.popupInfoService.init(this.map);
    this.breadcrumbInfoWindowService.init(this.map, this.configuration);

    // address lookup
    this.addressLookupMapMarkerService.init(this);

    // map clicks and events
    this.mapEventService.init(this.map);

    // map manipulation - zooming etc.
    this.mapControlService.init(this);
  }

  release() {
    this.weatherRadarLayerService.release();
    this.weatherWarningsLayerService.release();
    this.trafficLayerService.release();
    this.observationMapMarkerService.release();
    this.imageMapMarkerService.release();
    this.popupInfoService.release();
    this.breadcrumbInfoWindowService.release();
    this.tracksVectorTilesLayerService.release();
    this.tracksGeoJsonLayerService.release();
    this.staticRoutesLayerService.release();
    this.routesLayerService.release();
    this.routeSourceService.release();
    this.roadStatusCoverageLayerService.release();
    this.roadStatusCurrencyLayerService.release();
    this.roadStatusService.release();
    this.vehicleMapMarkerService.release();
    this.addressLookupMapMarkerService.release();
    this.mapEventService.release();
    this.mapControlService.release();
    if (!!this.settingsSubscription) {
      this.settingsSubscription.unsubscribe();
    }
    this.refreshTimers.forEach(refreshTimer => {
      clearTimeout(refreshTimer);
    });
    this.refreshTimers.clear();
  }

  addLayers() {
    // map layers:

    // first route source as it is used in custom overlays and route assignments
    this.routeSourceService.reloadSource();
    this.staticRoutesLayerService.reset();

    // overlay layers in order defined by indexes
    this.addOverlayMapLayers();
    // weather
    // routes
    // heat layer
    // tracks - vector
    // tracks - geojson
    // tracks - shift
    // images
    // observations
    // vehicles
    // address - tracks
    // address - breadcrumbs
    // address - locations
    this.weatherRadarLayerService.addSourceAndLayer(true);
    this.weatherWarningsLayerService.addSourceAndLayer(true);
    this.trafficLayerService.addSourceAndLayer(true);
    this.roadStatusService.addSource();
    this.roadStatusCoverageLayerService.addLayers();
    this.routesLayerService.reload();
    this.roadStatusCurrencyLayerService.addLayers();
    this.tracksVectorTilesLayerService.addSourceAndLayers();
    this.tracksGeoJsonLayerService.addSourceAndLayers();
    this.imageMapMarkerService.addSourceAndLayers();
    this.observationMapMarkerService.createSourceAndLayers();
    this.vehicleMapMarkerService.addSourceAndLayers();
    this.addressLookupMapMarkerService.addSourcesAndLayers();
    this.mapEventService.attachOnClickHandlers();
  }

  /**
   * Adds the layer to the map at a given position.
   * @param layer The layer to add.
   * @param beforeId The layer will be added before another layer with the id.
   */
  addLayer(
      layer: LineLayerSpecification | SymbolLayerSpecification | CircleLayerSpecification | RasterLayerSpecification,
      beforeId: string = null
  ) {
    if (this.layersMap.has(layer.id)) {
      console.warn('Trying to add existing layer: ' + layer.id);
      return; // the layer already exists
    }

    this.layersMap.set(layer.id, null);
    if (this.map.getSource(layer.id)) {
      // source already exists on the style
      console.warn('Trying to add existing source: ' + layer.id);
      layer.source = layer.id;
    }
    // console.log(layer);
    this.map.addLayer(layer as LayerSpecification, beforeId);
  }

  moveLayer(layerId: string, beforeId: string) {
    if (this.layersMap.has(layerId) && this.layersMap.has(beforeId)) {
      this.map.moveLayer(layerId, beforeId);
    } else {
      console.warn(`Trying to move layer ${layerId} before ${beforeId}. Failed: ${this.layersMap.has(layerId)}, ${this.layersMap.has(beforeId)}`);
    }
  }

  hideLayer(layer: LayerSpecification) {
    if (!this.layersMap.has(layer.id)) {
      console.warn('Trying to hide layer: ' + layer.id + ', it doesn\'t exist');
      return; // the layer does not exist
    }
    this.setLayerVisibility(layer.id, false);
  }

  updateConfiguration(configuration: ConfigurationModel) {
    this.configuration = configuration;
    this.changeBaseMap(this.getCurrentBaseMap(), true);
  }

  getRasterSource(sourceId: string): RasterTileSource {
    return this.map.getSource(sourceId) as RasterTileSource;
  }

  getGeoJsonSource(sourceId: string): GeoJSONSource {
    return this.map.getSource(sourceId) as GeoJSONSource;
  }

  getVectorSource(sourceId: string): VectorSourceSpecification {
    return this.map.getSource(sourceId) as VectorSourceSpecification;
  }

  addSource(sourceId: string, source: SourceSpecification) {
    this.map.addSource(sourceId, source);
  }

  setLayerVisibility(layerId: string, visible: boolean) {
    if (this.map.getLayer(layerId)) {
      this.map.setLayoutProperty(
        layerId,
        'visibility',
        visible ? 'visible' : 'none'
      );
    }
  }

  setLayerPaintProperty(layerId: string, property: string, value: any) {
    this.map.setPaintProperty(layerId, property, value);
  }

  setFilter(layerId: string, filter: any) {
    this.map.setFilter(layerId, filter);
  }

  getCurrentBaseMap() {
    const styleName = this.map.getStyle().name;
    if (styleName.startsWith('Dark')) {
      return BaseMapType.DARK;
    } else if (styleName.startsWith('Winter')) {
      return BaseMapType.WINTER;
    } else if (styleName.startsWith('Labels')) {
      return BaseMapType.IMAGERY;
    } else {
      return BaseMapType.OUTDOORS;
    }
  }

  changeBaseMap(mapTypeCode: string, force: boolean = false) {
    const useMetric = this.configuration.useMetricSystem;
    if (mapTypeCode !== this.getCurrentBaseMap() || force) {
      switch (mapTypeCode) {
        case BaseMapType.OUTDOORS:
          this.map.setStyle(
              useMetric ? MapLayersManager.ACCUTERRA_OUTDOORS_M_MAP_STYLE : MapLayersManager.ACCUTERRA_OUTDOORS_MAP_STYLE,
              {diff: !force},
          );
          break;
        case BaseMapType.DARK:
          this.map.setStyle(
              useMetric ? MapLayersManager.ACCUTERRA_DARK_M_MAP_STYLE : MapLayersManager.ACCUTERRA_DARK_MAP_STYLE,
              {diff: !force},
          );
          break;
        case BaseMapType.IMAGERY:
          this.map.setStyle(
            useMetric ? MapLayersManager.IMAGERY_M_MAP_STYLE : MapLayersManager.IMAGERY_MAP_STYLE,
            {diff: !force},
          );
          break;
        case BaseMapType.WINTER:
        default:
          this.map.setStyle(
            useMetric ? MapLayersManager.ACCUTERRA_WINTER_M_MAP_STYLE : MapLayersManager.ACCUTERRA_WINTER_MAP_STYLE,
            {diff: !force},
          );
      }

      // once the style is loaded, trigger onChange function
      const that = this;
      this.map.once('styledata', (data) => {
        const waiting = () => {
          if (!that.map.isStyleLoaded()) {
            setTimeout(waiting, 200);
          } else {
            that.onMapStyleChange();
          }
        };
        waiting();
      });
    }
  }

  private onMapStyleChange() {
    this.layersMap.clear();
    this.refreshTimers.forEach(refreshTimer => {
      clearTimeout(refreshTimer);
    });
    this.refreshTimers.clear();

    this.addImages();
    this.addLayers();
  }

  refreshMapLayersVisibility(layerInfo: MapLayer) {
    const shouldBeVisible = this.settingsService.getBooleanValue(
        layerInfo.name
    );
    if (layerInfo.type === MapLayerType.RASTER) {
      this.setLayerVisibility(layerInfo.name, shouldBeVisible);
    } else if (layerInfo.type === MapLayerType.VECTOR) {
      const vectorLayers = JSON.parse(layerInfo.configuration) as LayerSpecification[];
      vectorLayers.forEach((layer) => {
        this.setLayerVisibility(layer.id, shouldBeVisible);
      });
    } else if (layerInfo.type === MapLayerType.ROUTE) {
      this.staticRoutesLayerService.setRouteConfigLayersVisibility(layerInfo.routeConfigurationId, shouldBeVisible);
    }
  }

  addOverlayMapLayer(layerInfo: MapLayer) {
    if (layerInfo.type === MapLayerType.RASTER) {
      this.addRasterOverlayMapLayer(layerInfo);
    } else if (layerInfo.type === MapLayerType.VECTOR) {
      this.addVectorOverlayMapLayer(layerInfo);
    } else if (layerInfo.type === MapLayerType.ROUTE) {
      this.addRouteMapLayer(layerInfo);
    }
  }

  private addRasterOverlayMapLayer(layerInfo: MapLayer, afterId: string = null) {
    const shouldBeVisible = this.settingsService.getBooleanValue(
      layerInfo.name
    );

    if (layerInfo.tokenRequired) {
      const lastLayerId = this.map.style._order[this.map.style._order.length - 1];
      this.extAuthService.getToken(layerInfo.url).then(tokenResponse => {
        this.addRasterLayer(layerInfo, shouldBeVisible, tokenResponse.data.token, !!afterId ? afterId : lastLayerId);

        console.log(`Token for Map Layer ${layerInfo.name} will expire on ${moment(tokenResponse.data.expires)}`);
        console.log(`Token validity in milliseconds: ${tokenResponse.data.expires - (moment().unix() * 1000)}`);
        const refreshTimer = setTimeout(() => {
          this.refreshRasterLayer(layerInfo, !!afterId ? afterId : lastLayerId);
        }, tokenResponse.data.expires - (moment().unix() * 1000));
        this.refreshTimers.set(layerInfo.name, refreshTimer);
      }).catch(error => {
        console.warn(`Token could not be retrieved! Map Layer ${layerInfo.name} will stay hidden!`);
      });
    } else {
      this.addRasterLayer(layerInfo, shouldBeVisible);
    }
  }

  private addRasterLayer(layerInfo: MapLayer, visible: boolean, token: string = null, afterId: string = null) {
    let tokenParam = '';
    if (!!token) {
      tokenParam = `&token=${token}`;
    }
    const layer = {
      id: layerInfo.name,
      source: `${layerInfo.name}-source`,
      type: 'raster',
      layout: {
        visibility: visible ? 'visible' : 'none',
      },
      paint: {
        'raster-opacity': 1.0,
      },
    } as RasterLayerSpecification;

    const source = {
      type: 'raster',
      tiles: [layerInfo.url + tokenParam],
      tileSize: 256,
    } as RasterSourceSpecification;

    // convert afterId to beforeId
    // this is valid only for layers with token which are added asynchronously
    // other layers are added synchronously in their order
    let beforeId = null;
    if (!!afterId) {
      const afterIdIndex = this.map.style._order.indexOf(afterId);
      if (afterIdIndex < this.map.style._order.length - 1) {
        beforeId = this.map.style._order[afterIdIndex + 1];
      }
    }

    const sourceName = `${layerInfo.name}-source`;
    this.addSource(sourceName, source);
    this.addLayer(layer, beforeId);
  }

  private refreshRasterLayer(layerInfo: MapLayer, afterId: string) {
    // delete layer, delete source
    this.map.removeLayer(layerInfo.name);
    this.layersMap.delete(layerInfo.name);
    this.map.removeSource(`${layerInfo.name}-source`);

    // add again
    this.addRasterOverlayMapLayer(layerInfo, afterId);
  }

  private addVectorOverlayMapLayer(layerInfo: MapLayer) {
    const shouldBeVisible = this.settingsService.getBooleanValue(
      layerInfo.name
    );
    const vectorLayers = JSON.parse(layerInfo.configuration) as LayerSpecification[];
    vectorLayers.forEach((layer) => {
      this.addLayer(layer as SymbolLayerSpecification);
      if (!shouldBeVisible) {
        this.setLayerVisibility(layer.id, false);
      }
    });
  }

  private addRouteMapLayer(layerInfo: MapLayer) {
    const shouldBeVisible = this.settingsService.getBooleanValue(
        layerInfo.name
    );
    this.staticRoutesLayerService.addRouteConfigLayers(layerInfo.routeConfigurationId, shouldBeVisible);
  }

  addOverlayMapLayers() {
    this.configuration.additionalLayers.forEach((additionalLayer) => {
      this.addOverlayMapLayer(additionalLayer);
    });
  }

  zoomTo(location: LngLatLike, zoomLevel: number) {
    this.map.flyTo({
      center: location,
      zoom: zoomLevel,
      essential: true,
    });
  }

  fitTo(locations: LngLatBounds) {
    this.map.fitBounds(locations, {
      padding: {top: 80, bottom: 80, left: 320, right: 420},
      linear: false,
      maxZoom: 15,
      essential: true
    });
  }

  async loadImage(name: string, url: string, options: any) {
    const imageResponse = await this.map.loadImage(url);
    this.map.addImage(name, imageResponse.data, options);
  }

  // reload images on basemap style refresh
  addImages() {
    this.imagesMap.forEach(imageDef => {
      this.loadImage(imageDef.name, imageDef.url, imageDef.options);
    });
  }

  hasFeatureFlag(featureFlag: string): boolean {
    return (
      this.configuration.featureFlags.find(
        (value) => value.isEnabled && value.name === featureFlag
      ) !== undefined
    );
  }

  getMapCenter() {
    return this.map.getCenter();
  }

  setTransformRequest(accessToken: string) {
    this.map.setTransformRequest(this.getTransformRequestFunction(accessToken));
  }

  private getTransformRequestFunction(accessToken: string): RequestTransformFunction {
    return (url, resourceType): RequestParameters => {
      if (resourceType === 'Tile' && url.indexOf(environment.services.location) > -1 && !!accessToken) {
        return {
          url,
          headers: { Authorization: 'Bearer ' + accessToken },
        };
      } else {
        return undefined;
      }
    };
  }

  private onSettingsChanged(key: string, value: any) {
    // change base map
    if (key === SettingsService.BASE_MAP_LAYER_KEY) {
      this.changeBaseMap(value);
    } else {
      // refresh the layer identified by layer name
      this.configuration.additionalLayers.forEach(
        (layer: MapLayer, index) => {
          if (layer.name === key) {
            this.refreshMapLayersVisibility(layer);
          }
        }
      );
    }
  }
}
