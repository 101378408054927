import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MyAccountComponent} from './components/my-account/my-account.component';
import {ManageObservationsComponent} from './components/manage-observations/manage-observations.component';
import {adminGuard, defaultSettingsRoute} from '../../security/admin-guard.service';
import {ManageUsersMainComponent} from './components/manage-users/manage-users-main.component';
import {ManageCartegraphComponent} from './components/manage-cartegraph/manage-cartegraph.component';
import {
  RouteConfigurationListComponent
} from './components/manage-routes/route-configuration-list/route-configuration-list.component';
import {ManagePublicPortalComponent} from './components/manage-public-portal/manage-public-portal.component';
import {
  InspectionFormListComponent
} from './components/manage-inspection-forms/form-list/inspection-form-list.component';
import {InspectionFormComponent} from './components/manage-inspection-forms/form/inspection-form.component';
import {
  ManageVehicleInstallationComponent
} from './components/manage-vehicle-installation/manage-vehicle-installation.component';
import {ManageVehiclesComponent} from './components/manage-vehicles/manage-vehicles.component';
import {VehicleGroupListComponent} from './components/manage-vehicles/vehicle-group-list/vehicle-group-list.component';
import {VehicleGroupEditComponent} from './components/manage-vehicles/vehicle-group-edit/vehicle-group-edit.component';
import {VehicleEditComponent} from './components/manage-vehicles/vehicle-edit/vehicle-edit.component';
import {SettingsComponent} from './components/settings.component';
import {
  RouteConfigurationEditorComponent
} from './components/manage-routes/route-configuration-editor/route-configuration-editor.component';
import {
  InspectionFormQuestionsComponent
} from './components/manage-inspection-forms/questions/inspection-form-questions.component';
import {VehicleListComponent} from './components/manage-vehicles/vehicle-list/vehicle-list.component';

const routes: Routes = [
  {
    path: 'manage-routes/:configurationId',
    component: RouteConfigurationEditorComponent,
    canActivate: [adminGuard],
  },
  {
    path: '',
    component: SettingsComponent,
    children: [
      {
        // the default redirect to the users management sub-route
        path: '',
        component: MyAccountComponent,
        canActivate: [defaultSettingsRoute], // redirect based on privileges
      },
      {
        path: 'manage-users',
        component: ManageUsersMainComponent,
        canActivate: [adminGuard],
      },
      {
        path: 'manage-vehicles',
        component: ManageVehiclesComponent,
        canActivate: [adminGuard],
        children: [
          {
            path: '',
            component: VehicleGroupListComponent,
          },
          {
            path: 'group/:id',
            component: VehicleGroupEditComponent,
          },
          {
            path: 'group/:id/vehicles',
            component: VehicleListComponent,
          },
          {
            path: 'vehicle/:id',
            component: VehicleEditComponent,
          }
        ]
      },
      {
        path: 'manage-observations',
        component: ManageObservationsComponent,
        canActivate: [adminGuard],
      },
      {
        path: 'manage-routes',
        component: RouteConfigurationListComponent,
        canActivate: [adminGuard],
      },
      {
        path: 'manage-public-portal',
        component: ManagePublicPortalComponent,
        canActivate: [adminGuard],
      },
      {
        path: 'inspection-forms',
        component: InspectionFormListComponent,
        canActivate: [adminGuard],
      },
      {
        path: 'inspection-forms/:inspectionFormId',
        component: InspectionFormComponent,
        canActivate: [adminGuard],
      },
      {
        path: 'inspection-forms/:inspectionFormId/questions',
        component: InspectionFormQuestionsComponent,
        canActivate: [adminGuard],
      },
      {
        path: 'inspection-forms/:inspectionFormId/copy',
        component: InspectionFormComponent,
        canActivate: [adminGuard],
      },
      {
        path: 'cartegraph-settings',
        component: ManageCartegraphComponent,
        canActivate: [adminGuard],
      },
      {
        path: 'cartegraph-settings/:tabId',
        component: ManageCartegraphComponent,
        canActivate: [adminGuard],
      },
      {
        path: 'cartegraph-settings/:tabId/:stepId',
        component: ManageCartegraphComponent,
        canActivate: [adminGuard],
      },
      {
        path: 'vehicle-installation',
        component: ManageVehicleInstallationComponent,
        canActivate: [adminGuard],
      },
      {
        path: 'my-account',
        component: MyAccountComponent
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
})
export class SettingsRoutingModule {
}
