<div class="drawer-layout">
    <div class="drawer-content">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>Filter by Vehicle</mat-label>
            <mat-select
              [(ngModel)]="selectedVehicle"
              (ngModelChange)="onSelectedVehicleChange()"
              class="vehicle-selector"
              [ngClass]="{ filled: !!selectedVehicle }"
            >
                <mat-option
                  *ngFor="let asset of assets"
                  [value]="asset">
                    {{ asset.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <button
          mat-icon-button
          class="clear-button"
          *ngIf="!!selectedVehicle"
          (click)="clearSelectedVehicle()"
        >
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <ng-container *ngIf="!!items">
        <div class="drawer-content" *ngIf="items.length > 0">
            <ng-container *ngFor="let item of items">
                <div>
                    <app-card-menu-item [input]="item"></app-card-menu-item>
                </div>
            </ng-container>
        </div>

        <div class="drawer-content" *ngIf="!!selectedVehicle && items.length === 0">
            <span>There are no observations for the vehicle.</span>
        </div>

        <div class="drawer-content" *ngIf="!selectedVehicle && items.length === 0">
            <span>There are no observations.</span>
        </div>
    </ng-container>
</div>
