<h2>My vehicle groups</h2>

<p>
    Import vehicles from remote Cartegraph server.
</p>

<div *ngIf="currentState === ImportVehiclesStateEnum.NOOP && uiError && uiError.length > 0">
    <span class="ui-error">{{ uiError }}</span>
</div>

<div *ngIf="currentState === ImportVehiclesStateEnum.IMPORTED">
    <mat-accordion>
        <mat-expansion-panel *ngFor="let key of importKeys">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{key.label}}: {{importResult[key.name]?.length || 0}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ol>
                <li *ngFor="let row of importResult[key.name]">{{row.key}}
                    <span *ngIf="key.name === 'error'" class="mat-small">{{row.note}}</span>
                </li>
            </ol>
        </mat-expansion-panel>
    </mat-accordion>
</div>
