import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ContentBlock, Headline } from '../../../../../shared/models/configuration.model';

@Component({
  selector: 'app-content-block',
  templateUrl: './content-block.component.html',
  styleUrls: ['./content-block.component.scss']
})
export class ContentBlockComponent implements OnInit {

  @Input() contentBlock: ContentBlock;
  @Input() index: number;

  @Output() contentBlockDeleted = new EventEmitter<number>();
  @Output() contentBlockSelected = new EventEmitter<number>();
  @Output() contentBlockUpdated = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  delete() {
    this.contentBlockDeleted.emit(this.index);
  }

  edit() {
    this.contentBlockUpdated.emit(this.index);
  }

  selectedChanged() {
    this.contentBlockSelected.emit(this.index);
  }
}
