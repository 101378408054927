<div class="dvir-report-table">
  <table
    mat-table
    matSort
    matSortDisableClear
    multiTemplateDataRows
    [dataSource]="dataSource"
    (matSortChange)="sortChangedEvent.emit()"
    (scroll)="onScroll($event)"
    matSortActive="startTime"
    matSortDirection="asc"
    class="ops-table"
  >
    <ng-container matColumnDef="id">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="mat-body"
      >
        Shift ID
      </th>
      <td mat-cell *matCellDef="let element">
        <a
          [routerLink]="[InsightsRoute.SHIFT_DETAIL, element.shiftId]"
          [relativeTo]="activatedRoute"
          class="ops-link"
          >{{ element.shiftId }}</a
        >
      </td>
    </ng-container>

    <ng-container matColumnDef="driver">
      <th mat-header-cell *matHeaderCellDef class="mat-body">Driver</th>
      <td mat-cell *matCellDef="let element">{{ element.driver.name }}</td>
    </ng-container>

    <ng-container matColumnDef="vehicle">
      <th mat-header-cell *matHeaderCellDef class="mat-body">Vehicle</th>
      <td mat-cell *matCellDef="let element">{{ element.vehicle.name }}</td>
    </ng-container>

    <ng-container matColumnDef="startTime">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="mat-body"
      >
        Shift Start
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.start | date: 'medium' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="endTime">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="mat-body"
      >
        Shift End
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.end | date: 'medium' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
      <td mat-cell *matCellDef="let element" class="ops-table-action-cell">
        <button mat-icon-button class="ops-table-action-button">
          <mat-icon *ngIf="expandedElement !== element" class="ops-table-action-icon">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="expandedElement === element" class="ops-table-action-icon">keyboard_arrow_up</mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- Expanded Content Column -->
    <ng-container matColumnDef="expandedDetail">
      <td
        mat-cell
        *matCellDef="let element"
        [attr.colspan]="displayedColumnsWithActions.length"
      >
        <div
          class="report-element-detail"
          [@detailExpand]="
            element === expandedElement ? 'expanded' : 'collapsed'
          "
        >
          <div class="loading-spinner-wrapper">
            <div class="loading-spinner" *ngIf="loading">
              <mat-spinner [diameter]="18"></mat-spinner>
              <span> Loading...</span>
            </div>
          </div>
          <div class="inspection">
            <div class="inspection-header">
              <div class="mat-headline-6">Pre-Trip Inspection</div>
              <ng-container *ngIf="!loading && !!startInspection">
                <div>
                  <a
                      href="javascript:void(0)"
                      (click)="downloadDVIR(element.shiftId, true)"
                      class="ops-link mat-body"
                  >
                    View
                  </a>
                </div>
              </ng-container>
            </div>
            <ng-container *ngIf="!loading && !startInspection">
              Start inspection not available
            </ng-container>
            <app-dvir-inspection
              *ngIf="!loading && !!startInspection"
              [inspection]="startInspection"
            ></app-dvir-inspection>
          </div>
          <div class="inspection">
            <div class="inspection-header">
              <div class="mat-headline-6">Post-Trip Inspection</div>
              <ng-container *ngIf="!loading && !!endInspection">
                <div>
                  <a
                      href="javascript:void(0)"
                      (click)="downloadDVIR(element.shiftId, false)"
                      class="ops-link mat-body"
                  >
                    View
                  </a>
                </div>
              </ng-container>
            </div>
            <ng-container *ngIf="!loading && !endInspection">
              End inspection not available
            </ng-container>
            <app-dvir-inspection
              *ngIf="!loading && !!endInspection"
              [inspection]="endInspection"
            ></app-dvir-inspection>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsWithActions; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumnsWithActions"
      class="report-table-row"
      [class.report-expanded-row]="expandedElement === row"
      (click)="
        expandedElement = expandedElement === row ? null : row;
        loadInspectionReports()
      "
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['expandedDetail']"
      class="ops-table-detail-row"
    ></tr>
  </table>
</div>
