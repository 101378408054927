import { MapContentLayer } from '../MapContentLayer';
import { CircleLayerSpecification, LayerSpecification } from 'maplibre-gl';
import { MapContentSource } from '../MapContentSource';

export class ObservationShadowLayer extends MapContentLayer {

  constructor(
    source: MapContentSource
  ) {
    super('observation-shadow-layer', source);
  }

  toLayerSpecification(): LayerSpecification {
    return {
      id: this.layerId,
      type: 'circle',
      source: this.sourceRef.sourceId,
      paint: {
        'circle-radius': 15,
        'circle-color': '#000',
        'circle-blur': 0.75,
        'circle-translate': [2, 2],
      },
    } as CircleLayerSpecification;
  }

}
