<div class="drawer-layout">
<ng-container *ngIf="!openedMessage">
    <div class="drawer-content">
        <button mat-button
                class="drawer-action-button"
                (click)="onSendNewMessageClick()"
        >
            <mat-icon>add</mat-icon> <span class="button-label">Send new message...</span>
        </button>
    </div>

    <div *ngIf="!!configuration" class="drawer-action-form"
         [ngClass]="{'show-form': showNewMessageForm}"
    >
        <div class="form-line">
            <div class="form-icon">
                <mat-icon>person_add</mat-icon>
            </div>

            <mat-form-field class="w-100" color="accent" appearance="outline">
                <mat-label>Recipients</mat-label>
                <mat-chip-grid #chipGrid aria-label="Recipients selection" required>
                    <mat-chip-row
                            *ngFor="let recipient of formData.recipients"
                            [editable]="selectable"
                            [removable]="removable"
                            (removed)="remove(recipient)"
                    >
                        <div class="recipient-chip">
                            <mat-icon>{{ getIconName(recipient.type) }}</mat-icon>
                            <span class="recipient-chip-label">{{ recipient.name }}</span>
                        </div>
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip-row>
                    <input
                            placeholder="{{ getRecipientsPlaceholder() }}"
                            #recipientInput
                            [formControl]="recipientsForm"
                            [matAutocomplete]="auto"
                            [matChipInputFor]="chipGrid"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    />
                </mat-chip-grid>
                <mat-autocomplete
                    #auto="matAutocomplete"
                    (optionSelected)="selected($event.option.value)"
                >
                    <mat-optgroup
                            *ngFor="let recipientType of filteredRecipients | async"
                            [label]="recipientType.type"
                    >
                        <mat-option
                                *ngFor="let recipient of recipientType.recipients"
                                [value]="recipient"
                        >
                            <span class="select-recipient-option">{{ recipient.name }}</span>
                        </mat-option>
                    </mat-optgroup>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="form-line">
            <div class="form-icon">
                <mat-icon>subheader</mat-icon>
            </div>

            <mat-form-field class="w-100" color="accent" appearance="outline">
                <mat-label>Subject</mat-label>
                <input
                        matInput
                        autocomplete="off"
                        [(ngModel)]="formData.subject"
                        placeholder="Your message subject..."
                        #ngSubject="ngModel"
                        minlength="2"
                        maxlength="500"

                />
            </mat-form-field>
        </div>
        <div class="form-line">
            <div class="form-icon">
                <mat-icon>message</mat-icon>
            </div>
            <mat-form-field class="w-100" color="accent" appearance="outline">
                <mat-label>Message</mat-label>
                <textarea
                        matInput
                        autocomplete="off"
                        rows="15"
                        placeholder="Your message text..."
                        [(ngModel)]="formData.body"
                        #ngBody="ngModel"
                        minlength="2"
                        maxlength="2000"

                ></textarea>
            </mat-form-field>
        </div>

        <div class="action__row">
            <button
                    color="accent"
                    mat-button
                    (click)="sendMessage()"
                    [disabled]="ngSubject.invalid || ngBody.invalid || !formData.recipients.length"
            >
                Send
            </button>
        </div>

        <mat-spinner *ngIf="isSending"></mat-spinner>
    </div>

    <div class="drawer-content">
        <div *ngFor="let message of recentItems">
            <app-card-menu-item
                    [input]="message"
            ></app-card-menu-item>
        </div>
        <div *ngFor="let message of archivedItems">
            <app-card-menu-item
                    [input]="message"
            ></app-card-menu-item>
        </div>
    </div>
</ng-container>

    <div class="drawer-content" *ngIf="!!openedMessage">
        <app-message [message]="openedMessage"
                     (messageClosed)="onMessageClosed()"
                     (messageDeleted)="onMessageDeleted($event)"
        ></app-message>
    </div>
</div>
