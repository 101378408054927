import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {RootRoute} from '../../../../../shared/models/angular-routing';
import {LiveMapDataService} from '../../../services/live-map-data.service';
import {RoutesManagerService} from '../../../../../data/routes/routes-manager.service';
import {RouteConfigurationWithSchema} from '../../../../../shared/models/route';
import {Subscription} from 'rxjs';
import {SettingsService} from '../../../../../configuration/settings.service';
import {RouteAssignment} from '../../../../../shared/models/route-assignment';
import {ConfigurationModel} from '../../../../../shared/models/configuration.model';
import {ConfigurationService} from '../../../../../configuration/configuration.service';
import {RouteAssignmentManagerService} from '../../../../../data/routes/route-assignment-manager.service';
import moment from 'moment';
import { RoutesService } from '../../../../../data/routes/routes.service';
import { DrawerType } from '../../../../../layouts/right-drawer/right-drawer.component';
import { RightPanel } from '../../right-panel/right-panel.class';

export class RouteMetrics {
  totalDistance: number;
  totalTime: number;
  avgDistancePerAssignment: number;
  avgTimePerAssignment: number;
}

@Component({
  selector: 'app-route',
  templateUrl: './route.component.html',
  styleUrl: './route.component.scss'
})
export class RouteComponent implements OnInit, OnDestroy {

  routeConfigurations: RouteConfigurationWithSchema[];
  usedRouteConfiguration: RouteConfigurationWithSchema;
  path: string[];
  routeId: number;
  routeLength: number;

  configuration: ConfigurationModel;
  timeFromHours = 24;
  filterRouteConfigId: number = null;
  filterRouteIds: number[] = null;

  allRecentRouteAssignments: RouteAssignment[];
  completeRouteAssignments: RouteAssignment[];
  inProgressRouteAssignments: RouteAssignment[];

  assignmentInProgressCount: number;
  assignmentCompleteCount: number;
  routeMetrics: RouteMetrics;

  DrawerType = DrawerType;

  private readonly openSubscriptions = Array<Subscription>();

  constructor(
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private configurationService: ConfigurationService,
      private settingsService: SettingsService,
      private liveMapDataService: LiveMapDataService,
      private routeManager: RoutesManagerService,
      private routeAssignmentManager: RouteAssignmentManagerService,
      private routeService: RoutesService,
  ) {
  }

  ngOnInit() {
    this.routeManager.updateVisibility(true);

    const configurationSubscription = this.configurationService.sharedConfigurationModel.subscribe(model => {
      if (model) {
        this.configuration = model;
      }
    });
    this.openSubscriptions.push(configurationSubscription);

    const routeConfigSubscription = this.liveMapDataService.routeConfiguration$.subscribe(routeConfigurations => {
      this.routeConfigurations = routeConfigurations;

      const paramsSubscription = this.activatedRoute.params.subscribe(params => {
        const routeId = +params['routeId'];
        const path = params['path']?.split(':::');
        this.path = path;
        this.routeId = routeId;
        // console.log(`routeId: ${routeId}`);
        // console.log(`path: ${path?.split(':::')}`);
        if (!!path && !!routeId) {
          this.changeRoute(
              +path[0],
              routeId,
          );
        } else {
          console.warn('Route parameters not set!');
        }
      });
      this.openSubscriptions.push(paramsSubscription);
    });
    this.openSubscriptions.push(routeConfigSubscription);

    const routesFilterSubscription = this.routeManager.assignmentFilter$.subscribe(filterUpdate => {
      this.filterRouteConfigId = filterUpdate.configId;
      this.filterRouteIds = filterUpdate.routeIds;
      this.filterCompletedItems();
      this.filterInProgressAssignments();
    });
    this.openSubscriptions.push(routesFilterSubscription);

    const assignmentsSubscription = this.routeAssignmentManager.recentRouteAssignments$.subscribe(assignmentsUpdate => {
      this.allRecentRouteAssignments = assignmentsUpdate.state;
      this.reloadCompleteRouteAssignments();
      this.reloadInProgressAssignments();
    });
    this.openSubscriptions.push(assignmentsSubscription);
  }

  ngOnDestroy() {
    this.routeManager.updateVisibility(false);
    // this.settingsService.setBooleanValue(SettingsService.ROUTE_STATUS_MODE, true);
    this.openSubscriptions.forEach(subscription => subscription.unsubscribe());

    // get back to route filter from settings - Status Layer Switcher
    const pathFromSettings = this.settingsService.getStringValue(SettingsService.ROUTE_STATUS_PATH);
    const routePath = !pathFromSettings || pathFromSettings === '' ? [] : pathFromSettings.split(':::');
    if (routePath.length === 0) {
      this.routeManager.updateRouteFilter(
          null,
          null,
      );
    } else {
      const routeConfigPath = routePath.slice(1);
      this.routeManager.updateRouteFilter(
          +routePath[0],
          routeConfigPath,
      );
    }

    this.routeManager.updateAssignmentFilter(null, null);
  }

  changeRoute(configId: number, routeId: number) {
    this.usedRouteConfiguration = this.routeConfigurations?.find(config => config.id === configId);
    this.routeManager.updateRouteFilterNg(
        configId,
        [routeId],
    );
    this.routeManager.updateAssignmentFilter(configId, [routeId]);
    this.loadRouteLength(configId, routeId);
  }

  close() {
    this.router.navigate(
        [`/${RootRoute.MAIN}`],
        {
          queryParamsHandling: 'merge',
        }
    );
  }

  getRouteCategories() {
    if (!this.path || this.path.length === 0) {
      return [];
    }
    return this.path.slice(1, this.path.length - 1).reverse();
  }

  private reloadCompleteRouteAssignments() {
    if (!!this.allRecentRouteAssignments) {
      this.completeRouteAssignments = this.allRecentRouteAssignments.filter(assignment => {
        return !!assignment.completed && moment(assignment.created).isAfter(moment().subtract(this.timeFromHours, 'hour'));
      });
      this.filterCompletedItems();
    }
  }

  private filterCompletedItems() {
    if (!!this.completeRouteAssignments) {
      const routeMetrics = new RouteMetrics();

      // filter route IDs on-the-fly
      const filteredAssignments = this.completeRouteAssignments
          .filter(assignment => !this.filterRouteConfigId || this.filterRouteConfigId === assignment.configId)
          .filter(assignment => !this.filterRouteIds || this.filterRouteIds.includes(assignment.routeId));

      routeMetrics.totalDistance = 0;
      routeMetrics.totalTime = 0;
      routeMetrics.avgDistancePerAssignment = 0;
      routeMetrics.avgTimePerAssignment = 0;
      for (const assignment of filteredAssignments) {
        routeMetrics.totalDistance += (assignment.distanceDriven || 0);
        routeMetrics.totalTime += (assignment.timeDriven || 0);
      }
      const assignmentCount = filteredAssignments.length;
      if (filteredAssignments.length > 0) {
        routeMetrics.avgDistancePerAssignment = routeMetrics.totalDistance / assignmentCount;
        routeMetrics.avgTimePerAssignment = routeMetrics.totalTime / assignmentCount;
      }
      this.routeMetrics = routeMetrics;
      this.assignmentCompleteCount = assignmentCount;
    }
  }

  private reloadInProgressAssignments() {
    if (!!this.allRecentRouteAssignments) {
      this.inProgressRouteAssignments = this.allRecentRouteAssignments.filter(assignment => {
        return !!assignment.onAssignmentFrom && !assignment.deleted && !assignment.completed;
      });
      this.filterInProgressAssignments();
    }
  }

  private filterInProgressAssignments() {
    if (!!this.inProgressRouteAssignments) {
      const filteredAssignments = this.inProgressRouteAssignments
          .filter(assignment => !this.filterRouteConfigId || this.filterRouteConfigId === assignment.configId)
          .filter(assignment => !this.filterRouteIds || this.filterRouteIds.includes(assignment.routeId));
      this.assignmentInProgressCount = filteredAssignments.length;
    }
  }

  changeTimeFilter(value: number) {
    this.timeFromHours = value;
    this.reloadCompleteRouteAssignments();
  }

  private loadRouteLength(configId: number, routeId: number) {
    this.routeLength = null;
    this.routeService.getRouteLength(configId, routeId).then(response => {
      this.routeLength = response.data;
    }).catch(error => {
      console.log(error);
    });
  }

  getRightPanelQueryParams(drawerType: DrawerType) {
    const queryParams = {drawer: drawerType.content};
    queryParams[RightPanel.TRIGGER_ROUTE_ACTION_PARAM_NAME] = true;
    return queryParams;
  }
}
