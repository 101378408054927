export class DvirSectionDto {
  id: number;
  rank: number;
  title: string;
  hasRemarks: boolean;

  constructor(id: number, rank: number, title: string, hasRemarks: boolean) {
    this.id = id;
    this.rank = rank;
    this.title = title;
    this.hasRemarks = hasRemarks;
  }
}
