import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

export class SelectBoxModel {
  key: string;
  label: string;
  icon: string;
  iconMirrored?: boolean;
  flagEmpty?: boolean;
  emptyLabel?: string;
}

@Component({
  selector: 'app-select-box',
  templateUrl: './select-box.component.html',
  styleUrl: './select-box.component.scss'
})
export class SelectBoxComponent implements OnInit {

  @Input() useCircleIcon = false;
  @Input() width: number;
  @Input() allItems: SelectBoxModel[];
  @Input() selectedItemKey: string;
  @Output() selectedItemChanged = new EventEmitter<SelectBoxModel>();

  selectedItem: SelectBoxModel;
  isMenuOpened = false;

  ngOnInit() {
    this.selectedItem = this.allItems.find(item => item.key === this.selectedItemKey);
    if (!this.selectedItem) {
      console.warn('Missing selected item!');
      if (this.allItems.length > 0) {
        const emptyItem = this.allItems.find(item => item.flagEmpty);
        if (!!emptyItem) {
          this.selectedItem = emptyItem;
        } else {
          this.selectedItem = this.allItems[0];
        }
      }
    }
  }

  onSelect(item: SelectBoxModel) {
    this.selectedItem = item;
    this.selectedItemKey = item.key;
    this.selectedItemChanged.emit(item);
  }

  onMenuClosed() {
    this.isMenuOpened = false;
  }

  onMenuOpened() {
    this.isMenuOpened = true;
  }
}
