import {Component, EventEmitter, Input, Output} from '@angular/core';
import {RouteStyle} from '../../../../../shared/models/route';
import {MatFormField, MatLabel, MatSuffix} from '@angular/material/form-field';
import {FormsModule} from '@angular/forms';
import {MatTooltip} from '@angular/material/tooltip';
import {SharedModule} from '../../../../../shared/shared.module';
import {MatInput} from '@angular/material/input';
import {NgxColorsModule} from 'ngx-colors';

@Component({
  standalone: true,
  selector: 'app-route-style-editor',
  templateUrl: './route-style-editor.component.html',
  imports: [
    MatFormField,
    FormsModule,
    MatTooltip,
    SharedModule,
    MatInput,
    MatLabel,
    NgxColorsModule,
    MatSuffix
  ],
  styleUrls: ['./route-style-editor.component.scss', '../../../../../../styles/forms.scss']
})
export class RouteStyleEditorComponent  {

  @Input() routeStyle: RouteStyle;
  @Output() routeStyleChanged: EventEmitter<RouteStyle> = new EventEmitter<RouteStyle>();

  constructor() { }

  styleChanged() {
    this.routeStyleChanged.emit(this.routeStyle);
  }
}
