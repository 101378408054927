import {DvirInspectionQuestionDataType} from './QuestionDatatype';
import {DvirQuestionValueProviderDto} from './dto/DvirQuestionValueProviderDto';
import {DvirQuestionAnswerMapperDto} from './dto/DvirQuestionAnswerMapperDto';

export class DvirQuestion {

  id?: number;
  rank?: number;
  title?: string;
  type: DvirInspectionQuestionDataType;

  notApplicable?: boolean;
  valueProvider?: DvirQuestionValueProviderDto;
  answerMapper?: DvirQuestionAnswerMapperDto;

  constructor(id?: number,
              rank?: number,
              title?: string,
              type: DvirInspectionQuestionDataType = DvirInspectionQuestionDataType.TEXT,
              notApplicable: boolean = undefined,
              valueProvider: DvirQuestionValueProviderDto = undefined,
              answerMapper: DvirQuestionAnswerMapperDto = undefined) {
    this.id = id;
    this.rank = rank;
    this.title = title;
    this.type = type;
    this.notApplicable = notApplicable;
    this.valueProvider = valueProvider;
    this.answerMapper = answerMapper;
  }
}
