import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsGlobalSearchService {

  private searchListeners: GlobalSearchListener[] = [];

  private topicsSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  /**
   * An observable to be consumed by a global input fields. Provides information about all registered search
   * listeners and the topics they are _interested_ in.
   */
  public topics: Observable<string[]> = this.topicsSubject.asObservable();

  private termSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  /**
   * An observable to be consumed by search implementations. Provides information about the search term provided
   * via a global input search field.
   */
  public term: Observable<string | null> = this.termSubject.asObservable();

  constructor() {
    this.handleListenersCollectionChange();
  }

  search(newSearchTerm: string | null = null) {
    this.termSubject.next(newSearchTerm);
  }

  registerListener(listener: GlobalSearchListener) {
    this.searchListeners.push(listener);
    this.handleListenersCollectionChange();
  }

  unregisterListener(listener: GlobalSearchListener) {
    const index = this.searchListeners.indexOf(listener);
    if (index < 0) {
      throw new Error('Cannot unregister. Listener not registered or already unregistered.');
    }

    this.searchListeners.splice(index, 1);
    this.handleListenersCollectionChange();
  }

  private handleListenersCollectionChange() {
    const filteredTopicsList = this.searchListeners
        .map((searchListener) => {
          return searchListener.topic;
        })
        .filter((label, index, allLabels) => {
          // remove any duplicate labels
          return index === allLabels.indexOf(label);
        });

    this.topicsSubject.next(filteredTopicsList);
  }
}

export interface GlobalSearchListener {
  /**
   * The label to be displayed in the global search component.
   */
  topic: string;
}
