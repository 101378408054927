import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatOption, MatSelect, MatSelectChange} from '@angular/material/select';
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule} from '@angular/forms';
import {MatFormField} from '@angular/material/form-field';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-row-with-select',
  standalone: true,
  imports: [
    MatSelect,
    MatOption,
    FormsModule,
    MatFormField,
    NgIf,
    ReactiveFormsModule
  ],
  templateUrl: './row-with-select.component.html',
  styleUrl: './row-with-select.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: RowWithSelectComponent
    }
  ]
})
export class RowWithSelectComponent implements ControlValueAccessor {

  // using array for multiple selection
  @Output() modelChange = new EventEmitter<any[]>();
  @Input() primaryText!: string;
  @Input() secondaryText?: string;
  @Input() list: any[] = [];
  @Input() listId?: string;
  @Input() listLabel!: string;
  @Input() nullLabel?: string;
  @Input() model: any;
  @Input() modelComparison: (item1: any, item2: any) => boolean;
  @Input() disabled = false;

  onChangeListener = _ => {}
  onTouchedListener = () => {}

  constructor() {
    this.modelComparison = this.compareItems;
  }

  writeValue(obj: any) {
    this.model = obj || '';
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any) {
    this.onChangeListener = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedListener = fn;
  }

  compareItems(item1: any, item2: any): boolean {
    const idField = this.listId || 'id';
    if (typeof item1 === 'object' && typeof item2 === 'object' && idField in item1 && idField in item2) {
      return item1['id'] === item2['id'];
    }
    return item1 === item2;
  }

  handleChange(changeEvent: MatSelectChange) {
    this.onTouchedListener();
    const value = changeEvent.value || null;
    this.modelChange.emit(value);
    this.onChangeListener(value);
  }

}
