import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';

import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatFooterCell,
  MatFooterCellDef,
  MatFooterRow,
  MatFooterRowDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatNoDataRow,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource
} from '@angular/material/table';
import {UpdateUserPasswordModel, UserModelView} from '../../../../shared/models/User';
import {UserManagementService} from '../../../../data/users/user-management.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {GlobalSearchListener, SettingsGlobalSearchService} from '../settings-global-search.service';
import {firstValueFrom, Subscription} from 'rxjs';
import {MatSort, MatSortHeader} from '@angular/material/sort';
import {MatStickyTableBorderHelper} from '../../../../shared/directives/mat-sticky-table-border-helper';
import {AddUserDialogComponent} from './dialogs/add-user-dialog/add-user-dialog.component';
import {
  ImportUsersSpreadsheetDialogComponent
} from './dialogs/import-users-spreadsheet-dialog/import-users-spreadsheet-dialog.component';
import {
  ImportUsersCartegraphDialogComponent
} from './dialogs/import-users-cartegraph-dialog/import-users-cartegraph-dialog.component';
import {CardComponent} from '../../../../shared/components/card/card.component';
import {CardContentDirective} from '../../../../shared/components/card/card-content.directive';
import {MatButton, MatIconButton} from '@angular/material/button';
import {NgIf} from '@angular/common';
import {MatIcon} from '@angular/material/icon';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {DialogConfirmDeleteUserComponent} from './dialogs/dialog-confirm-delete-user.component';
import {DialogEditUserComponent} from './dialogs/dialog-edit-user.component';
import {DialogChangePasswordComponent} from './dialogs/dialog-change-password.component';
import {PromoteUserComponent} from './dialogs/promote-user/promote-user.component';
import {UserEditMode} from './edit-user/edit-user.component';

@Component({
  standalone: true,
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss', '../../settings-tables.scss', '../../settings-common.scss'],
  imports: [
    CardComponent,
    CardContentDirective,
    MatButton,
    NgIf,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCell,
    MatSortHeader,
    MatCellDef,
    MatHeaderCellDef,
    MatCell,
    MatIcon,
    MatMenuItem,
    MatMenu,
    MatFooterCell,
    MatHeaderRow,
    MatRow,
    MatFooterRow,
    MatProgressSpinner,
    MatMenuTrigger,
    MatIconButton,
    MatHeaderRowDef,
    MatRowDef,
    MatFooterRowDef,
    MatNoDataRow,
    MatFooterCellDef
  ]
})
export class ManageUsersComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() origin: any;
  @Input() cartegraphEnabled = false;
  @ViewChild(MatSort) sort: MatSort;
  isInitialized = false;
  isLoading = true;
  uiError: string;
  dataSource: MatTableDataSource<UserModelView> = new MatTableDataSource<UserModelView>([]);
  displayedColumns = [
    'firstName',
    'lastName',
    'login',
    'roles',
    'actions',
  ];
  searchFilter: string;
  @ViewChild('listEnd') private listEnd: ElementRef<HTMLElement>;
  @ViewChild('tableScrollRoot') private tableScrollRoot: ElementRef<HTMLElement>;
  private loadedRecordsCount = 0;
  private page: { pageIndex: number; pageSize: number; totalElements: number } = {
    pageIndex: 0,
    pageSize: 20,
    totalElements: 0
  };
  private listEndIntersectionObserver = new IntersectionObserver((entries, _) => {
    // Let's see if the <div id="listEnd"> element just got into the visible area of the device viewport.
    entries.forEach(entry => {
      this.endOfTheUserListVisible = entry.intersectionRatio > 0;
      if (this.endOfTheUserListVisible) {
        // Yes, the element is visible now. That means we've just reached the end of the list and want to load more items.
        this.onUsersListEndReached();
      }
    });
  }, {
    rootMargin: '0px',
    threshold: 0
  });
  private endOfTheUserListVisible = false;
  private stickyBorderHelper = new MatStickyTableBorderHelper();
  private readonly openSubscriptions = Array<Subscription>();
  private globalSearchListener: GlobalSearchListener = {
    topic: 'users',
  };

  constructor(
    private userManagementService: UserManagementService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private globalSearchService: SettingsGlobalSearchService,
  ) {
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.loadUsersAndDrivers(true);
    this.attachListEndVisibilityObserver();
    this.stickyBorderHelper.attach(this.tableScrollRoot.nativeElement);
  }

  ngOnInit(): void {
    this.globalSearchService.registerListener(this.globalSearchListener);
    const searchTermSubscription = this.globalSearchService.term.subscribe((searchTerm) => {
      this.handleGlobalSearchTermUpdate(searchTerm);
    });
    this.openSubscriptions.push(searchTermSubscription);

    /* Note: The globalSearchService.term Observable is built upon a BehaviorSubject which means
     * that the very first callback has already been received right after the globalSearchService.term.subscribe() call.
     * So it's safe to set the isInitialized to true now.
     */
    this.isInitialized = true;
  }

  ngOnDestroy(): void {
    this.listEndIntersectionObserver?.disconnect();
    this.stickyBorderHelper.detach();
    this.globalSearchService.unregisterListener(this.globalSearchListener);

    this.openSubscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  deleteUser(email: string) {
    const that = this;
    const user = this.findUser(email);
    const dialogRef = this.dialog.open(DialogConfirmDeleteUserComponent, {
      width: '450px',
      data: {
        email: user.email,
        familyName: user.familyName,
        givenName: user.givenName,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined && result) {
        that.removeUser(email);
        this.showSnackBar('User has been deleted.');
      }
    });
  }

  updateUser(email: string) {
    // reference only
    const user = this.findUser(email);
    // const user = Object.assign({}, this.findUser(userId)); // shallow copy
    // const user = {...this.findUser(userId)}; // shallow copy

    // make deep copy
    const userCopy = JSON.parse(JSON.stringify(this.findUser(email)));
    const dialogRef = this.dialog.open(DialogEditUserComponent, {
      width: '450px',
      data: {
        mode: UserEditMode.UPDATE,
        user: {
          email: userCopy.email,
          familyName: userCopy.familyName,
          givenName: userCopy.givenName,
          roles: userCopy.roles,
          phoneNumber: userCopy.phoneNumber,
        } as UserModelView
      },
    });

    firstValueFrom(dialogRef.afterClosed()).then((result) => {
      if (result) {
        Object.assign(user, UserModelView.preload(result, this.cartegraphEnabled));
        this.showSnackBar('User has been updated.');
        this.uiError = '';
      }
    });
  }

  promoteUser(email: string) {
    // reference only
    const user = this.findUser(email);

    // make deep copy
    const userCopy = JSON.parse(JSON.stringify(this.findUser(email)));
    const dialogRef = this.dialog.open(PromoteUserComponent, {
      width: '450px',
      data: {
        email,
        user: {
          email: userCopy.email,
          familyName: userCopy.familyName,
          givenName: userCopy.givenName,
          roles: userCopy.roles,
          phoneNumber: userCopy.phoneNumber,
        } as UserModelView
      },
    });

    firstValueFrom(dialogRef.afterClosed()).then((result) => {
      if (result) {
        Object.assign(user, UserModelView.preload(result, this.cartegraphEnabled));
        this.showSnackBar('User has been updated.');
        this.uiError = '';
      }
    });
  }

  changePassword(email: string) {
    const user = this.findUser(email);
    const dialogRef = this.dialog.open(DialogChangePasswordComponent, {
      width: '450px',
      data: {
        email: user.email,
        familyName: user.familyName,
        givenName: user.givenName,
      },
      // panelClass: 'use-material-design-3-theme',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined && result) {
        this.userManagementService
          .updatePassword(new UpdateUserPasswordModel(user.email, result))
          .then((_) => {
            this.showSnackBar('Password has been updated for the user.');
            this.uiError = '';
          })
          .catch((error) => {
            this.uiError = error;
            this.showSnackBar(error);
          });
      }
    });
  }

  showAddDialog(): void {
    const dialogRef = this.dialog.open(AddUserDialogComponent, {
      minWidth: '500px',
      closeOnNavigation: true,
    });

    firstValueFrom(dialogRef.afterClosed()).then((result) => {
      if (result) {
        this.showSnackBar('User has been added.')
        this.handleNewUserFormSubmission();
      }
    });
  }

  showImportSpreadsheetDialog(): void {
    const dialogRef = this.dialog.open(ImportUsersSpreadsheetDialogComponent, {
      minWidth: '500px',
      closeOnNavigation: true,
    });

    firstValueFrom(dialogRef.afterClosed()).then((result) => {
      if (result) {
        this.handleUserImportSucceeded();
      }
    });
  }

  showImportCartegraphDialog() {
    const dialogRef = this.dialog.open(ImportUsersCartegraphDialogComponent, {
      minWidth: '500px',
      closeOnNavigation: true,
    });

    firstValueFrom(dialogRef.afterClosed()).then((result) => {
      if (result) {
        this.handleUserImportSucceeded();
      }
    });
  }

  showAddOrImportDialog(): void {
    const dialogRef = this.dialog.open(AddUserDialogComponent, {
      width: '500px',
      closeOnNavigation: true,
      // panelClass: 'use-material-design-3-theme',
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult !== undefined && dialogResult) {
        switch (dialogResult.type) {
          case 'singleUser':
            this.handleNewUserFormSubmission();
            break;
          case 'userImport':
            this.handleUserImportSucceeded();
            break;
        }
      }
    });
  }

  showSnackBar(message: string) {
    this.snackBar.open(message, null, {duration: 2000});
  }

  onSortChanged(/* sort: Sort */) {
    this.refreshUsersList();
  }

  private handleNewUserFormSubmission() {
    this.uiError = '';
    this.refreshUsersList();
  }

  private handleUserImportSucceeded() {
    this.uiError = '';
    this.refreshUsersList();
  }

  private refreshUsersList() {
    this.loadUsersAndDrivers(true);
  }

  private findUser(email: string): UserModelView | null {
    return this.dataSource.data.find(record => record.email === email);
  }

  private removeUser(email: string): void {
    this.dataSource.data = this.dataSource.data.filter(user => user.email !== email);
    this.loadedRecordsCount--;
    this.page.totalElements--;
  }

  private loadUsersAndDrivers(reload: boolean) {
    if (reload) {
      this.page.pageIndex = 0;
    } else {
      this.page.pageIndex = this.page.pageIndex + 1;
    }

    this.isLoading = true;

    firstValueFrom(this.userManagementService
      .getUsers(this.page.pageIndex,
        this.page.pageSize,
        this.getSort(),
        null,
        null,
        this.searchFilter,
      ))
      .then((response) => {
        const loadedRecordsCount = response.data.content.length;
        const data = response.data.content
          .filter(user => !user.email.startsWith('neotreks_support')) // do now show support user
          .map<UserModelView>(user => {
            return UserModelView.preload(user, this.cartegraphEnabled);
          });

        if (reload) {
          this.dataSource.data = data;
          this.loadedRecordsCount = loadedRecordsCount;
        } else {
          const extended = [...this.dataSource.data];
          extended.push(...data);
          this.dataSource.data = extended;
          this.loadedRecordsCount += loadedRecordsCount;
        }
        this.page.totalElements = response.data.totalElements;
        this.page.pageSize = response.data.pageable.pageSize;

        // wait some time for the table to render and check if we should load more data
        setTimeout(() => {
          if (this.endOfTheUserListVisible && this.loadedRecordsCount < this.page.totalElements) {
            this.loadUsersAndDrivers(false)
          }
        }, 250);
      })
      .catch((error) => {
        console.log(error);
        this.uiError = error;
      }).finally(() => {
      this.isLoading = false;
    });
  }

  private attachListEndVisibilityObserver() {
    if (!IntersectionObserver) {
      console.warn('Intersection Observer API not supported');
      return;
    }

    this.listEndIntersectionObserver.observe(this.listEnd.nativeElement);
  }

  private onUsersListEndReached() {
    if (!this.isLoading && this.loadedRecordsCount < this.page.totalElements) {
      this.loadUsersAndDrivers(false);
    }
  }

  private getSort(): string | undefined {
    return this.sort ? `${this.sort.active},${this.sort.direction}` : '';
  }

  private handleGlobalSearchTermUpdate(term: string | null) {
    this.searchFilter = term;

    // do not load any data until fully initialized
    if (this.isInitialized) {
      this.loadUsersAndDrivers(true);
    }
  }
}
