import {NamedItem} from './named-item';
import {CameraConfiguration, HardwareConfiguration} from './vehicle.model';
import {DeviceInfo} from './device-info';

export class VehicleGroup implements NamedItem {

  vehicles = new Array<Vehicle>();

  constructor(public id: number,
              public title: string) {}
}

export class Vehicle {
  constructor(public id: number,
              public group: VehicleGroup,
              public label: string,
              public licensePlate: string,
              public hardwareConfiguration: HardwareConfiguration,
              public cameraConfiguration: CameraConfiguration,
              public deviceInfo?: DeviceInfo,
  ) {}
}

export class VehicleCategoryModel {
  id: number;
  name: string;
  title: string;
  useDvir: boolean;
  allowRouteSelfAssign: boolean;
  preTripFormId?: number;
  postTripFormId?: number;
  showVehicles: boolean;
  showVehicleHistory: boolean;
  showVehicleObservations: boolean;
  maxStationaryTime?: number;
  observationTypeGroupIds: number[] = [];
  allowedRouteConfigIds: number[] = [];
}
