import { Injectable } from '@angular/core';
import {Observable, Subscriber} from 'rxjs';
import {ConfigurationModel} from '../shared/models/configuration.model';

export interface ISettingKeyValuePair {
  readonly key: string;
  readonly value: any;
}

export enum BaseMapType {
  OUTDOORS = 'Outdoors',
  DARK = 'Dark',
  IMAGERY = 'Imagery',
  WINTER = 'Winter',
}

export enum StatusLayerType {
  COVERAGE = 'Coverage',
  CURRENCY = 'Currency',
  GPS_TRACKS = 'GPS Tracks',
  ROUTE_STATUS = 'Route Status',
  NONE = 'None'
}

export enum WeatherLayerType {
  RADAR = 'radar',
  WARNINGS = 'warnings',
  TRAFFIC = 'traffic',
}

export enum ActivityFilter {
  NONE = 'none',
  PLOWING = 'plowing',
  GRANULAR_SPREADING = 'gspreading',
  LIQUID_SPREADING = 'lspreading',
  SPREADING = 'spreading',
  MOWING = 'mowing',
  SWEEPING = 'sweeping',
  PLOWING_MOWING_SWEEPING = 'plow_mow_sweep',
}

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  static readonly TRACKS_LAYER_TYPE_KEY = 'Tracks';
  static readonly WEATHER_RADAR_LAYER_KEY = 'Radar';
  static readonly WEATHER_WARNINGS_LAYER_KEY = 'Warnings';
  static readonly TRAFFIC_LAYER_KEY = 'Traffic';

  static readonly PLOW_TRACK_TIME_FILTER = 'Plow Track: Time Filter';
  static readonly ACTIVITY_FILTER = 'Plow Track: Layer Filter';

  static readonly SHIFT_TRACK_TIME_FILTER = 'Shift Track: Time Filter';
  static readonly SHIFT_TRACK_ANIM_SPEED = 'Shift Track: Animation Speed';
  static readonly PLOW_TRACK_SHOW_RECENT = 'Plow Track: Show Recent';

  static readonly ROUTE_STATUS_PATH = 'Route Status Path';
  static readonly ROUTE_STATUS_THRESHOLD = 'Route Status Threshold';
  static readonly WEATHER_LOCATION = 'Weather Location';

  static readonly COVERAGE_TRACK_STYLE_1 = 'CoverageLevel1TrackStyle';
  static readonly COVERAGE_TRACK_STYLE_2 = 'CoverageLevel2TrackStyle';
  static readonly COVERAGE_TRACK_STYLE_3 = 'CoverageLevel3TrackStyle';
  static readonly COVERAGE_TRACK_STYLE_4 = 'CoverageLevel4TrackStyle';
  static readonly COVERAGE_TRACK_STYLE_5 = 'CoverageLevel5TrackStyle';

  static readonly CURRENCY_TRACK_STYLE_1 = 'CurrencyLevel1TrackStyle';
  static readonly CURRENCY_TRACK_STYLE_2 = 'CurrencyLevel2TrackStyle';
  static readonly CURRENCY_TRACK_STYLE_3 = 'CurrencyLevel3TrackStyle';
  static readonly CURRENCY_TRACK_STYLE_4 = 'CurrencyLevel4TrackStyle';
  static readonly CURRENCY_TRACK_STYLE_5 = 'CurrencyLevel5TrackStyle';

  static readonly BASE_MAP_LAYER_KEY = 'BaseMap';
  private static readonly localStorageKey = 'portal-settings';

  private booleanSettingsMap: Map<string, boolean>;
  private stringSettingsMap: Map<string, string>;

  private isInitialized = false;

  private readonly settingsChangedObservableSubscribers = new Array<Subscriber<ISettingKeyValuePair>>();
  readonly settingsChangedObservable = new Observable<ISettingKeyValuePair>((observer) => {
    if (this.booleanSettingsMap) {
      this.booleanSettingsMap.forEach((mapValue: boolean, mapKey: string) => {
        observer.next({ key: mapKey, value: mapValue});
      });
      this.stringSettingsMap.forEach((mapValue: string, mapKey: string) => {
        observer.next({ key: mapKey, value: mapValue});
      });
    }
    this.settingsChangedObservableSubscribers.push(observer);
  });

  constructor() { }

  getBooleanValue(key: string): boolean {
    if (!this.isInitialized) {
      console.log('Warn: Settings Service is not initialized yet!');
      return undefined;
    }

    return this.booleanSettingsMap.get(key);
  }

  setBooleanValue(key: string, value: boolean) {
    if (!this.isInitialized) {
      console.log('Warn: Settings Service is not initialized yet!');
      return;
    }

    const oldValue = this.getBooleanValue(key);
    if (value === oldValue) {
      return;
    }

    this.booleanSettingsMap.set(key, value);

    if (!!localStorage) {
      localStorage.setItem(SettingsService.localStorageKey + '_' + key, value ? 'true' : 'false');
    }

    this.onSettingsChanged(key, value);
  }

  getStringValue(key: string): string {
    if (!this.isInitialized) {
      console.log('Warn: Settings Service is not initialized yet!');
      return undefined;
    }

    return this.stringSettingsMap.get(key);
  }

  setStringValue(key: string, value: string) {
    if (!this.isInitialized) {
      console.log('Warn: Settings Service is not initialized yet!');
      return;
    }

    const oldValue = this.getStringValue(key);
    if (value === oldValue) {
      return;
    }

    this.stringSettingsMap.set(key, value);

    if (!!localStorage) {
      localStorage.setItem(SettingsService.localStorageKey + '_' + key, value);
    }

    this.onSettingsChanged(key, value);
  }

  public initSettings(tenantConfiguration: ConfigurationModel) {
    if (tenantConfiguration === null) {
      console.log('Initializing Settings Service failed! Empty tenant config!');
      return;
    }

    this.booleanSettingsMap = new Map<string, boolean>();
    this.stringSettingsMap = new Map<string, string>();

    // init tenant specific map layer settings
    const mapLayers = tenantConfiguration.additionalLayers;
    const mapLayerNames = mapLayers.map(mapLayer => mapLayer.name);
    for (const mapLayerName of mapLayerNames) {
      this.setDefaultBooleanValue(mapLayerName, false);
    }

    // recent tracks
    this.setDefaultBooleanValue(SettingsService.PLOW_TRACK_SHOW_RECENT, false);

    // init weather layers settings
    this.setDefaultBooleanValue(SettingsService.WEATHER_RADAR_LAYER_KEY, false);
    this.setDefaultBooleanValue(SettingsService.WEATHER_WARNINGS_LAYER_KEY, false);
    this.setDefaultBooleanValue(SettingsService.TRAFFIC_LAYER_KEY, false);

    // init base map layer settings
    this.setDefaultStringValue(SettingsService.BASE_MAP_LAYER_KEY, BaseMapType.WINTER);
    this.setDefaultStringValue(SettingsService.TRACKS_LAYER_TYPE_KEY, StatusLayerType.COVERAGE);

    this.setDefaultStringValue(SettingsService.PLOW_TRACK_TIME_FILTER, String(tenantConfiguration.lookBackPeriod));
    this.setDefaultStringValue(SettingsService.ACTIVITY_FILTER, ActivityFilter.NONE);
    this.setDefaultStringValue(SettingsService.SHIFT_TRACK_TIME_FILTER, '0_100');
    this.setDefaultStringValue(SettingsService.SHIFT_TRACK_ANIM_SPEED, String(10));

    this.setDefaultStringValue(SettingsService.ROUTE_STATUS_PATH, '');
    this.setDefaultStringValue(SettingsService.ROUTE_STATUS_THRESHOLD, String(4));

    // load weather location
    this.setDefaultStringValue(SettingsService.WEATHER_LOCATION, null);

    this.setDefaultStringValue(SettingsService.COVERAGE_TRACK_STYLE_1, null);
    this.setDefaultStringValue(SettingsService.COVERAGE_TRACK_STYLE_2, null);
    this.setDefaultStringValue(SettingsService.COVERAGE_TRACK_STYLE_3, null);
    this.setDefaultStringValue(SettingsService.COVERAGE_TRACK_STYLE_4, null);
    this.setDefaultStringValue(SettingsService.COVERAGE_TRACK_STYLE_5, null);

    this.setDefaultStringValue(SettingsService.CURRENCY_TRACK_STYLE_1, null);
    this.setDefaultStringValue(SettingsService.CURRENCY_TRACK_STYLE_2, null);
    this.setDefaultStringValue(SettingsService.CURRENCY_TRACK_STYLE_3, null);
    this.setDefaultStringValue(SettingsService.CURRENCY_TRACK_STYLE_4, null);
    this.setDefaultStringValue(SettingsService.CURRENCY_TRACK_STYLE_5, null);

    this.isInitialized = true;
  }

  private setDefaultBooleanValue(propertyKey: string, defaultValue: boolean) {
    const savedValue = localStorage ? localStorage.getItem(SettingsService.localStorageKey + '_' + propertyKey) : null;
    if (savedValue !== null) {
      this.booleanSettingsMap.set(propertyKey, savedValue === 'true');
    } else {
      this.booleanSettingsMap.set(propertyKey, defaultValue);
    }
  }

  private setDefaultStringValue(propertyKey: string, defaultValue: string) {
    const savedStringValue = localStorage ? localStorage.getItem(SettingsService.localStorageKey + '_' + propertyKey) : null;
    if (savedStringValue !== null) {
      this.stringSettingsMap.set(propertyKey, savedStringValue);
    } else {
      this.stringSettingsMap.set(propertyKey, defaultValue);
    }
  }

  private onSettingsChanged(changedKey: string, newValue: any) {
    for (const listener of this.settingsChangedObservableSubscribers) {
      listener.next({ key: changedKey, value: newValue });
    }
  }
}
