import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MapLayerConfiguration, MapLayerType} from '../../../models/map-layer.model';
import {ExtAuthService} from '../../../../data/ext-auth/ext-auth.service';
import {ToastService} from '../../../services/toast.service';

@Component({
  selector: 'app-map-layer-edit',
  templateUrl: './map-layer-edit.component.html',
  styleUrl: './map-layer-edit.component.scss'
})
export class MapLayerEditComponent {

  @Input() mapLayer: MapLayerConfiguration;

  @Output() mapLayerSaved = new EventEmitter();
  @Output() mapLayerCancelled = new EventEmitter();

  MapLayerType = MapLayerType;

  @ViewChild('ngName') ngName;
  @ViewChild('ngUrl') ngUrl;
  @ViewChild('ngConfig') ngConfig;

  constructor(
      private extAuthService: ExtAuthService,
      private toast: ToastService,
  ) { }

  changed() {
    this.mapLayer.dirty = true;
  }

  save() {
    this.mapLayerSaved.emit();
  }

  cancel() {
    this.mapLayerCancelled.emit();
  }

  onCredentialsToggle() {
    if (!this.mapLayer.useCredentials) {
      this.mapLayer.currentUsername = null;
      this.mapLayer.currentPassword = null;
    }
  }

  testCredentials() {
    this.extAuthService.verifyEsriCredentials(
        this.mapLayer.currentUrl,
        this.mapLayer.currentUsername,
        this.mapLayer.currentPassword
    ).then(response => {
      if (response.data) {
        this.toast.short('Connection is OK!');
      } else {
        this.toast.long('Error on connection!');
      }
    });
  }

  setUntouched() {
    this.ngName.control.markAsUntouched();
    if (this.mapLayer.currentType === MapLayerType.RASTER) {
      this.ngUrl.control.markAsUntouched();
    } else {
      this.ngConfig.control.markAsUntouched();
    }
  }
}
