import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {RightPanel} from '../right-panel.class';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfigurationService} from '../../../../../configuration/configuration.service';
import {AssetsManagerService} from '../../../../../data/assets/assets-manager.service';
import {ToastService} from '../../../../../shared/services/toast.service';
import {MessagesService} from '../../../../../data/messages/messages.service';
import {FormControl} from '@angular/forms';
import {MessageRecipientModel} from '../../../models/message-recipient.model';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Observable} from 'rxjs';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {map, startWith} from 'rxjs/operators';
import {LiveMapDataService} from '../../../services/live-map-data.service';
import {MessageRecipientCollectionModel} from '../../../models/message-recipient-collection.model';
import {ActionMenuItem, ActionMenuItemSubMenu} from '../../../../../shared/models/action-menu-item.class';
import {MessageModel} from '../../../models/message.model';
import {ShortDateOrTimePipe} from '../../../../../shared/formatting/short-date-or-time.pipe';
import {ConfirmDeleteMessageDialogComponent} from './dialogs/confirm-delete-message-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {DrawerContent} from '../../../../../layouts/right-drawer/right-drawer.component';
import { Asset } from '../../../models/asset.class';

export const subFilter = (recipients: any[], value: any): MessageRecipientModel[] => {
  const filterValue = value.hasOwnProperty('name') ? value.name.toLowerCase() : value.toLowerCase();

  return recipients.filter(item => item.name.toLowerCase().indexOf(filterValue) === 0);
};

interface MessageRecipient {
  type: string;
  recipients: MessageRecipientModel[];
}

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent extends RightPanel implements OnInit, OnDestroy {

  isSending = false;
  showNewMessageForm = false;
  openedMessage: MessageModel = null;
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredRecipients: Observable<MessageRecipient[]>;
  recipientsForm = new FormControl();
  formData = {
    subject: '',
    body: '',
    recipients: [],
  };
  availableRecipients: MessageRecipient[];
  recipientsInitialized = false;
  selectedVehicle: Asset;

  recentMessages: MessageModel[] = [];
  archivedMessages: MessageModel[] = [];
  recentItems: ActionMenuItem[] = [];
  archivedItems: ActionMenuItem[] = [];

  @ViewChild('recipientInput') recipientInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
      protected router: Router,
      protected activatedRoute: ActivatedRoute,
      protected configurationService: ConfigurationService,
      protected assetsManager: AssetsManagerService,
      private messagesService: MessagesService,
      private liveMapDataService: LiveMapDataService,
      private dialog: MatDialog,
      private shortDateOrTimePipe: ShortDateOrTimePipe,
      private toast: ToastService,
  ) {
    super(
        DrawerContent.MESSAGES,
        router,
        activatedRoute,
        configurationService,
        assetsManager,
    );
    this.filteredRecipients = this.recipientsForm.valueChanges.pipe(
        startWith(''),
        map((input: string) => this._filterGroup(input)));
  }

  ngOnInit() {
    this.initialize();
    this.loadConfiguration();
    this.selectedVehicle = this.asset;

    this.liveMapDataService.availableRecipients$.subscribe(recipients => {
      if (recipients) {
        this.availableRecipients = this.prepareRecipientsForDialog(recipients);
        if (!this.recipientsInitialized) {
          // preselect only on component initialization
          this.preSelectVehicle();
          this.recipientsInitialized = true;
        }
        this.recipientsForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
      }
    });

    this.liveMapDataService.recentMessages$.subscribe((messages) => {
      this.recentMessages = messages;
      this.recentItems = messages.map(message => {
        const actions: ActionMenuItemSubMenu[] = [];
        actions.push(
            new ActionMenuItemSubMenu(
                'delete',
                'Delete',
                () => this.deleteMessage(message),
            )
        );
        return new ActionMenuItem(
            message.id,
            'message',
            message.subject,
            message.body,
            this.shortDateOrTimePipe.transform(new Date(message.createdAt)),
            `${this.seenMessagesCount(message)}/${message.messageDeliveries.length}`,
            () => false,
            null,
            null,
            () => { this.navigateToSelectedMessage(message); },
            null,
            null,
            actions,
        );
      });
    });

    this.liveMapDataService.archivedMessages$.subscribe((messages) => {
      this.archivedMessages = messages;
      this.archivedItems = messages.map(message => {
        const actions: ActionMenuItemSubMenu[] = [];
        actions.push(
            new ActionMenuItemSubMenu(
                'delete',
                'Delete',
                () => this.deleteMessage(message),
            )
        );
        return new ActionMenuItem(
            message.id,
            'message',
            message.subject,
            message.body,
            this.shortDateOrTimePipe.transform(new Date(message.createdAt)),
            `${this.seenMessagesCount(message)}/${message.messageDeliveries.length}`,
            () => false,
            null,
            null,
            () => { this.navigateToSelectedMessage(message); },
            null,
            null,
            actions,
        );
      });
    });
  }

  ngOnDestroy() {
    this.unsubscribe();
    this.availableRecipients = undefined;
    this.recipientsInitialized = false;
  }

  onAssetsUpdate() {
    // do nothing
  }

  onAssetChange() {
    // do nothing
  }

  onAssetAction() {
    this.selectedVehicle = this.asset;
    this.preSelectVehicle();
    this.showNewMessageForm = true;
  }

  onRouteAction() {
    // do nothing
  }

  onConfigurationLoad() {
    // do nothing
  }

  private preSelectVehicle() {
    if (!!this.availableRecipients) {
      if (!!this.selectedVehicle) {
        const recipient = this.availableRecipients[0].recipients.find(recipient => recipient.id === this.selectedVehicle.id);
        if (!!recipient) {
          // remove all recipients
          this.formData.recipients = [];
          // add currently selected vehicle
          this.selected(recipient);
        }
      }
    }
  }

  onSendNewMessageClick() {
    this.showNewMessageForm = !this.showNewMessageForm;
    this.selectedVehicle = null;
    this.formData.recipients = [];
  }

  sendMessage() {
    this.isSending = true;
    this.messagesService.sendMessage(this.formData).then((response) => {
      this.liveMapDataService.handleMessageSent(response.data);

      this.toast.short('Message sent successfully.');
      this.isSending = false;
      this.showNewMessageForm = false;
      this.resetForm();
    }).catch((error: Error) => {
      console.error(error);
      this.toast.longer('Failed to send a message.');
      this.isSending = false;
    });
  }

  deleteMessage(message: MessageModel) {
    const dialogRef = this.dialog.open(ConfirmDeleteMessageDialogComponent, {
      data: { id: message.id, name: message.subject },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!!result) {
        this.messagesService.deleteMessage(message.id).then(() => {
          let messageIndex = this.recentMessages.findIndex(item => item.id === message.id);
          if (messageIndex > -1) {
            this.recentMessages.splice(messageIndex, 1);
            this.liveMapDataService.sendRecentMessages(this.recentMessages);
          } else {
            messageIndex = this.archivedMessages.findIndex(item => item.id === message.id);
            if (messageIndex > -1) {
              this.archivedMessages.splice(messageIndex, 1);
              this.liveMapDataService.sendArchivedMessages(this.archivedMessages);
            }
          }
          this.openedMessage = null;
        }).catch(error => {
          console.error(error);
        });
      }
    });
  }

  remove(recipient: string): void {
    const index = this.formData.recipients.indexOf(recipient);

    if (index >= 0) {
      this.formData.recipients.splice(index, 1);
    }
  }

  selected(recipient: MessageRecipientModel): void {
    this.formData.recipients.push(recipient);
    if (!!this.recipientInput) {
      this.recipientInput.nativeElement.value = '';
    }
    this.recipientsForm.setValue(null);
  }

  getIconName(type: string): string {
    const mapping = {
      vehicle_group: 'groups',
      vehicle: 'directions_car',
      driver: 'person',
    };

    return mapping[type] ? mapping[type] : 'groups';
  }

  getRecipientsPlaceholder() {
    return this.isRecipientPresent() ? 'Search for active recipients' : 'No active recipients found';
  }

  isRecipientPresent(): boolean {
    if (!this.availableRecipients) {
      return false;
    }
    return Boolean(this.availableRecipients[0].recipients.length);
  }

  private _filterGroup(value: string): MessageRecipient[] {
    if (value) {
      return this.availableRecipients
          .map(group => ({type: group.type, recipients: subFilter(group.recipients, value)}))
          .filter(group => group.recipients.length > 0);
    }

    return this.availableRecipients;
  }

  private prepareRecipientsForDialog(recipients: MessageRecipientCollectionModel): MessageRecipient[] {
    const mappedRecipients = [];

    mappedRecipients.push(
        {
          type: 'Vehicles',
          recipients: recipients.vehicles,
        }
    );

    mappedRecipients.push(
        {
          type: 'Vehicle Groups',
          recipients: recipients.vehicleGroups,
        }
    );

    if (recipients.vehicles.length) {
      mappedRecipients.push({
        type: 'System',
        recipients: [{
          name: 'Everyone',
          type: 'everyone'
        }],
      });
    }

    return mappedRecipients;
  }

  private resetForm() {
    this.formData = {
      subject: '',
      body: '',
      recipients: [],
    };
  }

  navigateToSelectedMessage(selectedMsg: MessageModel) {
    this.openedMessage = selectedMsg;
  }

  seenMessagesCount(message: MessageModel) {
    if (!message.messageDeliveries.length) {
      return 0;
    }

    return message.messageDeliveries.filter((delivery) => delivery.seenAt !== null).length;
  }

  onMessageClosed() {
    this.openedMessage = null;
  }

  onMessageDeleted(message: MessageModel) {
    this.deleteMessage(message);
  }
}
