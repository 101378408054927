<h1 mat-dialog-title>
	{{ dialogTitle }}
</h1>

<form #form="ngForm">
	<mat-dialog-content>
		<div class="flex-column">
			<mat-form-field appearance="outline" color="accent" class="w-100">
				<mat-label>Name</mat-label>
				<input matInput
					   [(ngModel)]="data.title"
					   name="name"
					   required
				/>
				<mat-error *ngIf="form.controls['name']?.errors?.required">
					Name is required.
				</mat-error>
			</mat-form-field>
			<mat-form-field appearance="outline" color="accent" class="w-100">
				<mat-label>Description</mat-label>
				<input matInput
					   [(ngModel)]="data.description"
					   name="description"
				/>
			</mat-form-field>

			<div class="flex-row align-items-start">
				<mat-form-field appearance="outline" color="accent" class="w-50">
					<mat-label>Content</mat-label>
					<textarea matInput
							  cdkTextareaAutosize
							  cdkAutosizeMinRows="12"
							  cdkAutosizeMaxRows="12"
							  [cols]="80"
							  [(ngModel)]="data.markdown"
							  name="content"
							  required
					></textarea>
					<mat-error *ngIf="form.controls['content']?.errors?.required">
						Content is required.
					</mat-error>
				</mat-form-field>
				<div class="w-50">
					<h3>Preview</h3>
					<markdown [data]="data.markdown">
					</markdown>
				</div>
			</div>
		</div>
	</mat-dialog-content>

	<mat-dialog-actions align="end">
		<button mat-stroked-button mat-dialog-close>Cancel</button>
		<button mat-flat-button color="primary" [disabled]="form.invalid"
				(click)="handleActionButtonClick()">
			{{ dialogActionTitle }}
		</button>
	</mat-dialog-actions>
</form>
