import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatStep, MatStepLabel, MatStepper} from '@angular/material/stepper';
import {MatButton, MatIconButton} from '@angular/material/button';
import {
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
  ValueChangeEvent
} from '@angular/forms';
import {MatFormField, MatInput} from '@angular/material/input';
import {MatLabel} from '@angular/material/form-field';
import {MatProgressBar} from '@angular/material/progress-bar';
import {ToastService} from '../../../../../shared/services/toast.service';
import {RoutesService} from '../../../../../data/routes/routes.service';
import {
  FieldFormat,
  LayerBasicModel,
  RouteConfiguration,
  RouteConfigurationWithSchema,
  RouteHierarchyItem,
  RouteImportAttribute,
  RouteSchema,
  RouteStyle,
  TaskStatus
} from '../../../../../shared/models/route';
import {BehaviorSubject, firstValueFrom, interval, mergeMap, Subscription} from 'rxjs';
import {NgForOf} from '@angular/common';
import {MatOption} from '@angular/material/autocomplete';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {EmptyMapContent} from '../../../../../shared/components/map-preview/model/EmptyMapContent';
import {
  RouteConfigDetailMapContent
} from '../../../../../shared/components/map-preview/model/map-content/RouteConfigDetailMapContent';
import {McRouteConfiguration} from '../../../../../shared/components/map-preview/model/McRouteConfiguration';
import {MapContent} from '../../../../../shared/components/map-preview/model/MapContent';
import {RouteFilter, RouteTreeComponent} from '../route-tree/route-tree.component';
import {SharedModule} from '../../../../../shared/shared.module';
import {MatIcon} from '@angular/material/icon';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {MatCheckbox} from '@angular/material/checkbox';
import {filter} from 'rxjs/operators';
import {FeatureCollection, GeoJsonProperties, Geometry} from 'geojson';
import {MatTooltip} from '@angular/material/tooltip';
import {ConfigurationService} from '../../../../../configuration/configuration.service';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {ExpansionPanelComponent} from '../../../../../shared/components/expansion-panel/expansion-panel.component';
import {RouteStyleEditorComponent} from '../route-style-editor/route-style-editor.component';
import {DialogConfirmDeleteRouteConfigComponent} from '../dialogs/dialog-components';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

const DEFAULT_ROUTE_COLOR = '#0059CC';
const HOVERED_ROUTE_COLOR = '#FBBC05';

const DEFAULT_CONFIG_NAME = 'All Routes';

const DEFAULT_ROUTE_STYLE = new RouteStyle(DEFAULT_ROUTE_COLOR, 4, 1.0)

class RouteEditorWizard {
  // input
  routeConfigurationId?: number;

  loadingData = new BehaviorSubject(false);

  /******
   * step 1 - Data Source
   ******/
  serverUrl?: string = ''
  username?: string
  password?: string
  /******
   * step 2 - Layer And Route Name Attribute
   ******/
  layers: LayerBasicModel[] | undefined;
  loadingLayers = new BehaviorSubject(false);
  layerLoadingError?: string;
  fields: FieldFormat[];
  loadingFields = new BehaviorSubject(false);
  fieldsLoadingError?: string;
  // state
  selectedLayerId: number | undefined;
  nameField: string | undefined;

  attributes: string[];
  /******
   * step 3 - Route Selection And Grouping
   ******/
  categories: string[] = [];
  routeFilter: RouteFilter = {routeIds: [], all: true};
  routeConfiguration: RouteConfiguration;
  configurationWithSchema: RouteConfigurationWithSchema;
  loadingFeatureCollection = new BehaviorSubject(false);

  featureTaskUuid: string | undefined;
  featureCollectionLoadingError: string | undefined;
  /******
   * step 4 - Route Presentation
   ******/
  defaultRouteStyle = Object.assign({}, DEFAULT_ROUTE_STYLE);

  //attributes: string[] = [];
  /******
   * step 5 - Route Configuration Settings
   ******/
  configurationName: string = DEFAULT_CONFIG_NAME;
  keepUpToDate = false;
  exportToCustomMapLayers = false;

  savingData = new BehaviorSubject(false);

  constructor(private readonly routesService: RoutesService,
              private readonly configurationService: ConfigurationService,) {
  }

  hasDataForStep1() {
    return this.layers;
  }

  async loadLayers() {
    // step 0
    delete this.layerLoadingError;
    // step 1
    delete this.layers;
    delete this.selectedLayerId;
    delete this.nameField;

    const {serverUrl, username, password} = this;

    this.loadingLayers.next(true);
    try {
      let response = await this.routesService.getFeatureServiceDescription(
        serverUrl, username, password);
      this.layers = response.data.layers;
    } catch (error: any) {
      this.layerLoadingError = error;
      throw error;
    } finally {
      this.loadingLayers.next(false);
    }
  }

  async loadLayerDescription() {
    delete this.fields;
    delete this.nameField;

    delete this.routeConfiguration;
    delete this.configurationWithSchema;
    delete this.fieldsLoadingError;

    const {serverUrl, username, password} = this;
    this.loadingFields.next(true);

    try {
      const response = await this.routesService.getFeatureServiceLayerDescription(
        serverUrl, this.selectedLayerId, username, password
      )
      this.fields = response.data.fields;
    } catch (error: any) {
      this.fieldsLoadingError = error;
      delete this.selectedLayerId;
      throw error;
    } finally {
      this.loadingFields.next(false);
    }
  }

  async loadFeatureCollection() {
    delete this.featureCollectionLoadingError;

    this.loadingFeatureCollection.next(true);
    this.updateAttributes();

    const {serverUrl, username, password} = this;
    // start async get feature config
    try {
      const response = await this.routesService.readFeatureCollectionFromFeatureProxyAsync(
        serverUrl,
        this.selectedLayerId,
        this.attributes,
        username,
        password,
      )
      // first clean any previous features data
      this.cleanFeatureTask();
      this.featureTaskUuid = response.data;
      console.log(`Task UUID: ${this.featureTaskUuid}`);
      // repeatedly check the task status
      this.routeConfiguration = await this.getTaskResult(this.featureTaskUuid);
      this.updateRouteConfigurationWithSchemaFromWizard();

      return await this.loadFeatureCollectionGeoJson(response.data);
    } catch (error: any) {
      console.error(error);
      throw error;
    } finally {
      this.loadingFeatureCollection.next(false);
    }
  }

  async getTaskResult(taskUuid: string) {
    return firstValueFrom(
      interval(2000).pipe(
        mergeMap(() => {
          console.log(`checking task ${taskUuid} status`);
          return this.taskStatusHandler(taskUuid);
        }),
        filter(status => {
          switch (status) {
            case TaskStatus.DONE:
              return true;
            case TaskStatus.FAILURE:
              throw new Error('Error loading task status')
            default:
              return false;
          }
        }),
        mergeMap(() => {
          return this.loadTaskResult(taskUuid);
        })
      )
    )
  }

  hasDataForStep2() {
    return this.selectedLayerId !== undefined
      && this.nameField !== undefined
      && this.configurationWithSchema;
  }

  async addCategory(fieldName: string) {
    this.categories.push(fieldName);
    return await this.loadFeatureCollection();
  }

  async removeCategory(fieldName: string) {
    const index = this.categories.indexOf(fieldName);
    if (index === -1) {
      throw new Error('Cannot remove category')
    }
    this.categories.splice(index, 1);
    return await this.loadFeatureCollection();
  }

  setRouteFilter(routeFilter: RouteFilter) {
    this.routeFilter = routeFilter;
  }

  updateRouteConfigurationWithSchemaFromWizard() {
    const {serverUrl, username, password} = this;

    const routeConfig = new RouteConfiguration();
    routeConfig.childrenAttribute = this.nameField;
    routeConfig.children = this.routeConfiguration.children;
    this.configurationWithSchema = new RouteConfigurationWithSchema(
      this.configurationName,
      serverUrl,
      username,
      password,
      this.selectedLayerId,
      this.layers.find(layer => layer.id === this.selectedLayerId).name,
      this.keepUpToDate,
      this.exportToCustomMapLayers,
      new RouteSchema(
        this.attributes.map(attribute => {
          return new RouteImportAttribute(attribute);
        }),
        this.defaultRouteStyle,
      ),
      routeConfig,
    );
    this.configurationWithSchema.id = this.routeConfigurationId;
  }

  async saveChanges() {
    const {configurationWithSchema, configurationName, keepUpToDate, exportToCustomMapLayers} = this
    configurationWithSchema.name = configurationName;
    configurationWithSchema.regularUpdate = keepUpToDate;
    configurationWithSchema.updateCustomLayers = exportToCustomMapLayers;

    this.savingData.next(true);

    console.log('Saving route configuration...');
    try {
      const response = await this.routesService.updateRouteConfiguration(configurationWithSchema)
      console.log('Route configuration saved.');

      console.log('Saving route geometries...');
      await this.routesService.updateRouteGeoJson(this.featureTaskUuid, response.data.id, this.configurationWithSchema)
      console.log('Route geometries saved.');

      this.cleanFeatureTask();
      if (exportToCustomMapLayers) {
        await this.configurationService.updateRouteMapLayer(response.data.id)
        console.log('Custom map layer saved.');
        this.configurationService.refreshConfiguration();
      }
    } catch (error: any) {
      throw error;
    } finally {
      this.savingData.next(false)
    }
  }

  async deleteRouteConfiguration() {
    if (this.routeConfigurationId === undefined) {
      return Promise.reject()
    }
    this.loadingData.next(true);
    const result = await this.routesService.deleteRouteConfiguration(this.routeConfigurationId)
    this.loadingData.next(false);
    return result
  }

  creatingNewRouteConfiguration() {
    delete this.routeConfigurationId;
    delete this.serverUrl;
    delete this.username;
    delete this.password;

    delete this.layers;
    delete this.layerLoadingError;
    delete this.selectedLayerId;
    delete this.nameField;
    delete this.fieldsLoadingError;

    this.categories = [];
    this.routeFilter = {routeIds: [], all: false};
    delete this.routeConfiguration;
    delete this.configurationWithSchema;

    delete this.featureTaskUuid;
    delete this.featureCollectionLoadingError;

    this.defaultRouteStyle = new RouteStyle(DEFAULT_ROUTE_COLOR, 4, 1.0);

    this.configurationName = DEFAULT_CONFIG_NAME;
    this.keepUpToDate = false;
    this.exportToCustomMapLayers = false;
  }

  async loadRouteConfiguration(configurationId: number) {
    this.loadingData.next(true);
    try {
      const {data, data: {schema}} = await this.routesService.getRouteConfiguration(configurationId)

      this.configurationName = data.name;
      this.serverUrl = data.url;
      this.username = data.username;
      this.password = data.password;

      await this.loadLayers();
      this.selectedLayerId = data.layerId;

      await this.loadLayerDescription()
      this.nameField = schema.classification[schema.classification.length - 1].attribute;
      this.attributes = schema.classification.map(att => att.attribute);
      this.categories = this.attributes.splice(0, this.attributes.length - 1);

      this.defaultRouteStyle = schema.style;
      this.routeConfiguration = data.configuration;
      this.routeConfigurationId = data.id;
      this.keepUpToDate = data.regularUpdate;
      this.exportToCustomMapLayers = data.updateCustomLayers;

      return await this.loadFeatureCollection()
    } catch (e) {
      this.creatingNewRouteConfiguration();
      throw e;
    } finally {
      this.loadingData.next(false);
    }
  }

  private cleanFeatureTask() {
    if (this.featureTaskUuid) {
      this.routesService.cleanFeatureTaskStatus(this.featureTaskUuid).catch();
      this.featureTaskUuid = null;
    }
  }

  private async taskStatusHandler(taskUuid: string) {
    console.log('Checking task status...');
    return await this.routesService.getFeatureTaskStatus(taskUuid).then(taskStatus => {
      return taskStatus.data;
    });
  }

  private async loadTaskResult(taskUuid: string) {
    try {
      const response = await this.routesService.getRouteConfigurationFromFeatureProxy(
        taskUuid,
        this.routeConfigurationId,
        this.serverUrl,
        this.selectedLayerId,
        this.attributes,
      )
      return response.data;
    } catch (error: any) {
      console.log(error);
      throw error
    }
  }

  private async loadFeatureCollectionGeoJson(taskUuid: string) {
    return this.routesService.getRoutesGeoJsonFromExternalService(
      taskUuid,
      this.configurationWithSchema.layerId,
      this.attributes,
      this.configurationWithSchema.configuration,
    ).then(response => response.data)
  }

  private updateAttributes() {
    this.attributes = [...this.categories, this.nameField];
  }

  defaultRouteStyleChanged(routeStyle: RouteStyle) {
    this.defaultRouteStyle = routeStyle;
  }

  step1SourceDataChanged() {
    if (!this.layers) {
      return false;
    }
    delete this.layers;
    delete this.layerLoadingError;
    delete this.selectedLayerId;
    delete this.nameField;
    delete this.fieldsLoadingError;

    this.categories = [];
    this.routeFilter = {routeIds: [], all: false};
    delete this.routeConfiguration;
    delete this.configurationWithSchema;

    delete this.featureTaskUuid;
    delete this.featureCollectionLoadingError;
    return true;
  }

  nameFieldChanged() {
    delete this.routeConfiguration;
    delete this.configurationWithSchema;
    delete this.featureTaskUuid;
    delete this.featureCollectionLoadingError;
  }
}

@Component({
  selector: 'app-route-configuration-editor',
  standalone: true,
  imports: [
    MatStepper,
    MatStep,
    MatStepLabel,
    MatButton,
    ReactiveFormsModule,
    MatInput,
    MatLabel,
    MatFormField,
    MatProgressBar,
    MatOption,
    MatSelect,
    NgForOf,
    FormsModule,
    SharedModule,
    RouteTreeComponent,
    MatIcon,
    MatIconButton,
    RouterLink,
    MatCheckbox,
    MatTooltip,
    MatProgressSpinner,
    ExpansionPanelComponent,
    RouteStyleEditorComponent,
  ],
  templateUrl: './route-configuration-editor.component.html',
  styleUrls: ['./route-configuration-editor.component.scss', '../../../settings-common.scss']
})
export class RouteConfigurationEditorComponent implements OnDestroy, OnInit {
  wizard: RouteEditorWizard;

  @ViewChild('stepper')
  stepper: MatStepper;

  // step 0
  dataSourceFormGroup: FormGroup;

  mapContent: MapContent = EmptyMapContent.instance;

  // step 4
  routeConfigurationSettingsFormGroup: FormGroup;

  subscriptions: Subscription[] = [];
  currentStep = -1;
  groupBeingAdded: any;

  constructor(private readonly activatedRoute: ActivatedRoute,
              private readonly formBuilder: UntypedFormBuilder,
              private readonly routesService: RoutesService,
              private readonly router: Router,
              private readonly dialog: MatDialog,
              private readonly snackBar: MatSnackBar,
              private readonly configurationService: ConfigurationService,
              private readonly toastService: ToastService,) {
    this.createWizard();
    this.createFormGroups();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.activatedRoute.params.subscribe(params => {
        const configurationId = params.configurationId;
        if (!isNaN(+configurationId)) {
          this.processMapContext(
            this.wizard.loadRouteConfiguration(Number(configurationId))
          ).then(_ => {
            const {
              serverUrl, username, password,
              configurationName, keepUpToDate, exportToCustomMapLayers
            } = this.wizard
            this.dataSourceFormGroup.setValue({serverUrl, username, password});

            this.routeConfigurationSettingsFormGroup.setValue({
              configurationName,
              keepUpToDate,
              exportToCustomMapLayers
            })

            setTimeout(() => {
              this.stepper.next();
              this.stepper.next();
              this.stepper.next();
              this.stepper.next();
            }, 10);
          })
        } else {
          this.wizard.creatingNewRouteConfiguration();
          this.currentStep = 0;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  step1LoadLayers() {
    this.dataSourceFormGroup.markAsPristine();
    this.wizard.loadLayers().then(() => {
      setTimeout(() => this.stepper.next(), 100);
    }).catch((error) => {
      this.toastService.long('Failed to get Feature Service Description! Error: ' + error);
    });
  }

  step2SelectedLayerChanged() {
    this.wizard.loadLayerDescription()
      .catch(error => this.toastService.long(error));
  }

  step1NameFieldChanged() {
    this.wizard.nameFieldChanged();
  }

  step1LoadRoutes() {
    this.processMapContext(this.wizard.loadFeatureCollection())
      .then(() => {
        setTimeout(() => {
          this.stepper.next();
        }, 100);
      })
      .catch(error => {
        this.toastService.long(error)
      });
  }

  filterRoutes(routeFilter: RouteFilter) {
    this.wizard.setRouteFilter(routeFilter);
    this.updateMapRouteVisibility();
  }

  colorsOverrideChanged($event: RouteConfiguration) {
    console.log(`colors override change ${JSON.stringify($event)}`);
  }

  onStepperSelectionChange(event: StepperSelectionEvent) {
    const {selectedIndex} = event;
    this.currentStep = selectedIndex;
    switch (this.currentStep) {
      case 2:
        this.updateMapConfigurationStyle(DEFAULT_ROUTE_STYLE);
        break;
      case 3:
        this.updateMapConfigurationStyle(this.wizard.defaultRouteStyle);
        break;
    }
  }

  nextStep() {
    this.stepper.next();
  }

  onCategorySelected($event: MatSelectChange) {
    this.processMapContext(
      this.wizard.addCategory($event.value)
    )
      .then(() => {
        this.groupBeingAdded = undefined;
      });
  }

  removeCategory(category: string) {
    this.processMapContext(
      this.wizard.removeCategory(category)
    )
      .then();
  }

  onRouteHover(route: RouteHierarchyItem) {
    route.color = HOVERED_ROUTE_COLOR;
    switch (this.currentStep) {
      case 2:
        this.updateMapConfigurationStyle(DEFAULT_ROUTE_STYLE);
        break;
      case 3:
        this.updateMapConfigurationStyle(this.wizard.defaultRouteStyle);
        break;
    }
  }

  onRouteLeave(route: RouteHierarchyItem) {
    delete route.color;
    switch (this.currentStep) {
      case 2:
        this.updateMapConfigurationStyle(DEFAULT_ROUTE_STYLE);
        break;
      case 3:
        this.updateMapConfigurationStyle(this.wizard.defaultRouteStyle);
        break;
    }
  }

  saveChanges() {
    this.wizard.saveChanges().then(() => {
      this.toastService.long('Configuration has been updated');
      this.router.navigate(['/settings', 'manage-routes']).then();
    })
  }

  createWizard() {
    this.wizard = new RouteEditorWizard(this.routesService, this.configurationService)
    this.subscriptions.push(
      this.wizard.loadingLayers.subscribe((loading) => {
          if (loading) {
            this.dataSourceFormGroup.disable();
          } else {
            this.dataSourceFormGroup?.enable();
          }
        }
      ));
  }

  updateMapRouteVisibility() {
    if (this.isMapContentUpdateAllowed()) {
      const castMapContent = this.mapContent as RouteConfigDetailMapContent;
      const {configurationWithSchema, defaultRouteStyle, routeFilter} = this.wizard;
      castMapContent.changeRouteVisibility(
        McRouteConfiguration.fromBaseAndStyle(configurationWithSchema, defaultRouteStyle, routeFilter, true));
    }
  }

  updateMapConfigurationStyle(routeStyle: RouteStyle) {
    if (this.isMapContentUpdateAllowed()) {
      const castMapContent = this.mapContent as RouteConfigDetailMapContent;
      const {configurationWithSchema, routeFilter} = this.wizard;
      castMapContent.changeConfigurationStyle(
        McRouteConfiguration.fromBaseAndStyle(configurationWithSchema, routeStyle, routeFilter, true));
    }
  }

  isMapContentUpdateAllowed() {
    return this.wizard.configurationWithSchema && this.mapContent && this.mapContent instanceof RouteConfigDetailMapContent;
  }

  createFormGroups() {
    const {serverUrl, username, password} = this.wizard;
    this.dataSourceFormGroup = this.formBuilder.group({
      serverUrl: this.formBuilder.control(serverUrl, [Validators.required]),
      username: this.formBuilder.control(username),
      password: this.formBuilder.control(password),
    });

    this.subscriptions.push(
      this.dataSourceFormGroup.events.subscribe(event => {
        if (this.currentStep !== 0) {
          return
        }
        if (event instanceof ValueChangeEvent) {
          const {serverUrl, username, password} = event.value;
          this.wizard.serverUrl = serverUrl;
          this.wizard.username = username;
          this.wizard.password = password;
        }
        if (this.dataSourceFormGroup.dirty) {
          // remove the loaded layers on any touched change
          if (this.wizard.step1SourceDataChanged()) {
            this.stepper.reset();
          }
        }
      })
    );

    this.routeConfigurationSettingsFormGroup = this.formBuilder.group({
      configurationName: this.formBuilder.control('', [Validators.required]),
      keepUpToDate: this.formBuilder.control(false),
      exportToCustomMapLayers: this.formBuilder.control(false),
    });

    this.subscriptions.push(
      this.routeConfigurationSettingsFormGroup.events.subscribe(event => {
        if (this.currentStep !== 4) {
          return
        }
        if (event instanceof ValueChangeEvent) {
          const {configurationName, keepUpToDate, exportToCustomMapLayers} = event.value;
          this.wizard.configurationName = configurationName;
          this.wizard.keepUpToDate = keepUpToDate;
          this.wizard.exportToCustomMapLayers = exportToCustomMapLayers;
        }
      })
    )
  }

  async processMapContext(promise: Promise<FeatureCollection<Geometry, GeoJsonProperties>>) {
    try {
      const geometries = await promise;
      this.updateMapContext(geometries)
      return geometries;
    } catch (e) {
      this.resetMapContext();
      throw e;
    }
  }

  private updateMapContext(geometries: FeatureCollection) {
    this.mapContent = new RouteConfigDetailMapContent(
      McRouteConfiguration.fromBase(
        this.wizard.configurationWithSchema,
        this.wizard.routeFilter, true),
      geometries);
  }

  private resetMapContext() {
    this.mapContent = EmptyMapContent.instance;
  }

  defaultRouteStyleChanged(routeStyle: RouteStyle) {
    this.wizard.defaultRouteStyleChanged(routeStyle);
    this.updateMapConfigurationStyle(routeStyle);
  }

  deleteRouteConfiguration() {
    const dialog = this.dialog.open(DialogConfirmDeleteRouteConfigComponent, {
      data: {
        name: this.wizard.configurationName
      }
    });

    firstValueFrom(dialog.afterClosed()).then(result => {
      if (result) {
        this.wizard.deleteRouteConfiguration().then(() => {
          this.snackBar.open('Route Configuration has been deleted', null, {duration: 2000});
          this.router.navigate(['/settings', 'manage-routes']).then();
        });
      }
    })
  }
}
