import {DvirQuestionValueProviderDto} from './DvirQuestionValueProviderDto';
import {DvirQuestionAnswerMapperDto} from './DvirQuestionAnswerMapperDto';
import {DvirInspectionQuestionDataType} from '../QuestionDatatype';

export class DvirQuestionDto {
  id: number;
  rank: number;
  title: string;
  type: DvirInspectionQuestionDataType;
  // "notApplicable"
  notApplicable?: boolean;
  valueProvider?: DvirQuestionValueProviderDto;
  answerMapper?: DvirQuestionAnswerMapperDto;

  constructor(id: number,
              rank: number,
              title: string,
              type?: DvirInspectionQuestionDataType,
              notApplicable?: boolean,
              valueProvider?: DvirQuestionValueProviderDto,
              answerMapper?: DvirQuestionAnswerMapperDto) {
    this.id = id;
    this.rank = rank;
    this.title = title;
    this.type = type;
    this.notApplicable = notApplicable;
    this.valueProvider = valueProvider;
    this.answerMapper = answerMapper;
  }
}
