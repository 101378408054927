import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../../../../shared/material/material.module';
import {SharedModule} from '../../../../shared/shared.module';
import {CardComponent} from '../../../../shared/components/card/card.component';
import {CardContentDirective} from '../../../../shared/components/card/card-content.directive';
import {RowWithButtonComponent} from '../row-with-button/row-with-button.component';
import {UiBlockingLayerComponent} from '../ui-blocking-layer/ui-blocking-layer.component';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule,
    CardComponent,
    CardContentDirective,
    RowWithButtonComponent,
    UiBlockingLayerComponent,
  ]
})
export class UserManagementModule {
}
