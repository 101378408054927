import {AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogRef,
    MatDialogTitle
} from '@angular/material/dialog';
import {NgStyle} from '@angular/common';
import {MatProgressBar} from '@angular/material/progress-bar';
import {ToastService} from '../../../../shared/services/toast.service';

/**
 * A wrapper of the `mat-dialog-title`, `mat-dialog-content` and `mat-dialog-actions` directives that can be
 * used to display a dialog.
 *
 * The `mat-dialog-actions` consists of two buttons - a cancellation and a confirmation one.
 */
@Component({
  selector: 'app-confirm-deletion-dialog',
  standalone: true,
    imports: [
        MatButton,
        MatDialogActions,
        MatDialogClose,
        MatDialogContent,
        MatDialogTitle,
        NgStyle,
        MatProgressBar,
    ],
  templateUrl: './confirm-deletion-dialog.component.html',
})
export class ConfirmDeletionDialogComponent implements OnChanges, AfterViewInit {
    /**
     * The dialog title. `"Confirmation"` is used if no custom title provided.
     */
    @Input() title: string;
    /**
     * The dialog body as an HTML markup. `"Do you really want to delete this item?"` is used if no custom body provided.
     */
    @Input() bodyHtml: string;
    /**
     * The cancellation button label. `"Cancel"` is used if no custom label provided.
     */
    @Input() cancelButtonLabel: string;
    /**
     * The confirmation button label. `"Yes"` is used if no custom label provided.
     */
    @Input() confirmButtonLabel: string;
    /**
     * The error message to be displayed should the deletion fail. Uses `defaultErrorToastMessage` if none provided.
     */
    @Input() errorToastMessage: string;
    /**
     * The result object to be sent when the confirmation button was used to close the dialog.
     */
    @Input() dialogCloseObject: any;

    /**
     * A callback method to be invoked when the user presses the Confirmation button.
     *
     * The `dialogCloseObject` will be passed as the only callback argument.
     *
     * Expects to return a `Promise`. When the promise resolves the dialog will close with the `dialogCloseObject`.
     * If it rejects the dialog will remain visible and available for resubmission.
     */
    @Input() deletionCallback: (id: any) => Promise<any>;

    @ViewChild('body') body: ElementRef;

    private defaultBodyHtml = 'Do you really want to delete this item?';
    private mergedBodyHtml = this.defaultBodyHtml;
    private defaultErrorToastMessage = 'Deletion failed.';
    private mergedErrorToastMessage = this.defaultErrorToastMessage;
    private isViewInitialized = false;

    isWorking = false;

    constructor(
        public dialogRef: MatDialogRef<any>,
        private toastService: ToastService,
    ) {
        // dialogRef.addPanelClass('use-material-design-3-theme');
    }

    ngAfterViewInit() {
        this.body.nativeElement.innerHTML = this.mergedBodyHtml;
        this.isViewInitialized = true;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('bodyHtml')) {
            this.mergedBodyHtml = changes.bodyHtml.currentValue || this.defaultBodyHtml;

            if (this.isViewInitialized) {
                this.body.nativeElement.innerHTML = this.mergedBodyHtml;
            }
        }

        if (changes.hasOwnProperty('mergedErrorToastMessage')) {
            this.mergedErrorToastMessage = changes.mergedErrorToastMessage.currentValue || this.defaultErrorToastMessage;
        }
    }

    protected tryDelete() {
        if (!this.deletionCallback) {
            this.dialogRef.close(this.dialogCloseObject);
            return;
        }

        this.isWorking = true;
        const disableCloseStateToRestore = this.dialogRef.disableClose;
        this.dialogRef.disableClose = true;

        this.deletionCallback(this.dialogCloseObject)
            .then(_ => {
                this.dialogRef.close(this.dialogCloseObject);
            })
            .catch((error) => {
                console.warn('deletion error', error);
                this.showErrorToast();
            })
            .finally(() => {
                this.isWorking = false;
                this.dialogRef.disableClose = disableCloseStateToRestore;
            });
    }

    private showErrorToast() {
        this.toastService.short(this.mergedErrorToastMessage);
    }
}
