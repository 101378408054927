<h1 mat-dialog-title>
	Add Vehicle Group
</h1>
<form #form="ngForm">
	<mat-dialog-content>
		<mat-progress-bar *ngIf="isWorking" mode="indeterminate"></mat-progress-bar>

		<div class="field-row">
			<div class="field-row-title">Group Name</div>
			<div class="field-row-element">
				<mat-form-field appearance="outline" color="accent">
					<input matInput
						   autocomplete="off"
						   [(ngModel)]="data.model.title"
						   name="title"
						   required
						   cdkFocusInitial
					/>
					@if (form.controls['title']?.errors?.required) {
						<mat-error>
							Name is required.
						</mat-error>
					}
				</mat-form-field>
			</div>
			<div class="field-row-divider"></div>
		</div>
	</mat-dialog-content>

	<mat-dialog-actions>
		<button mat-stroked-button
				mat-dialog-close
				[disabled]="isWorking">
			Cancel
		</button>
		<button mat-flat-button color="primary"
				[disabled]="form.invalid || isWorking"
				cdkFocusInitial
				type="submit"
				(click)="handleActionButtonClick()">
			Add Vehicle Group
		</button>
	</mat-dialog-actions>
</form>
