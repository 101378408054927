import {Component, Inject} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef, MatDialogTitle
} from '@angular/material/dialog';
import {MatButton} from '@angular/material/button';

export interface RouteConfigData {
  name: string;
}

@Component({
  selector: 'app-dialog-confirm-delete-route-config',
  standalone: true,
  templateUrl: 'dialog-confirm-delete-route-config.html',
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatButton,
    MatDialogTitle
  ]
})
export class DialogConfirmDeleteRouteConfigComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmDeleteRouteConfigComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RouteConfigData) {}
}
