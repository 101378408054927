<div class="flex-column gap-32">
<app-card preCardTitle="Route Management">
	<div *appCardContent class="settings-header-container">
		<div class="settings-header-title">
			Create a new route configuration
		</div>
		<div class="settings-header-actions">
			<button
					(click)="addRouteConfiguration()"
					mat-stroked-button
					color="accent"
			>
				Add Routes
			</button>
		</div>
	</div>
</app-card>

@if (isLoading) {
	<mat-spinner diameter="30" class="mx-a my-10"></mat-spinner>
}
@else {
	<app-card preCardTitle="Route Configurations">
		<div *appCardContentRaw>
				@if (root.hasChildren()) {
					<mat-nav-list class="route-configurations-list">
						@for (route of root.children; track route.base.id ) {
							<a mat-list-item
							   class="flex-row"
							   (click)="editRouteConfiguration(route)">
								<div class="flex-grow-1">{{route.base.name}}</div>
								<mat-icon>open_in_new</mat-icon>
							</a>
						}
					</mat-nav-list>
				} @else {
					 <div class="p-15 no-data">No route configurations defined yet</div>
				}
		</div>
	</app-card>
}
	<div class="setup-page-container">
		<div class="content-container">
			<div class="selector-container">
				<div class="selector-container-list">
					<ng-container *ngFor="let leftItem of leftItems">
						<app-action-menu-item style="width: 100%;" [input]="leftItem"></app-action-menu-item>
					</ng-container>
				</div>
			</div>
			<div class="divider"></div>
			<div class="selector-container">
				<div class="nodes-back-button">
					<button mat-icon-button
							*ngIf="backButtonEnabled"
							(click)="backButtonClicked()"
					>
						<mat-icon>arrow_back</mat-icon>
					</button>
				</div>
				<div class="nodes-breadcrumbs">{{ breadcrumbs }}</div>
				<div class="selector-container-list">
					<ng-container *ngFor="let rightItem of rightItems">
						<app-action-menu-item style="width: 100%;" [input]="rightItem"></app-action-menu-item>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</div>
