import {ConfigurationModel} from '../../../../../shared/models/configuration.model';
import {Subscription} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConfigurationService} from '../../../../../configuration/configuration.service';
import {CreateVehicleGroupDialogInput} from '../model/CreateVehicleGroupDialogInput';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {VehiclesService} from '../../../../../data/vehicles/vehicles.service';
import {ToastService} from '../../../../../shared/services/toast.service';

export interface VehicleCategoryData {
  id: number;
  name: string;
  title: string;
  useDvir: boolean;
  maxStationaryTime: number;
  observationTypeGroups: number[];
}

@Component({
  selector: 'app-dialog-create-vehicle-category',
  templateUrl: './dialog-create-vehicle-category.html',
  styleUrls: ['../dialogs-vehicle/dialog-components.scss', '../../../../settings/settings-fields.scss'],
})
export class DialogCreateVehicleCategoryComponent implements OnInit, OnDestroy {

  isWorking = false;

  configuration: ConfigurationModel;

  subscriptions: Subscription[] = []

  constructor(
    private readonly dialogRef: MatDialogRef<DialogCreateVehicleCategoryComponent>,
    private readonly configurationService: ConfigurationService,
    private readonly toastService: ToastService,
    private readonly vehicleService: VehiclesService,
    @Inject(MAT_DIALOG_DATA) public data: CreateVehicleGroupDialogInput) {
  }

  ngOnInit(): void {
    this.subscriptions.push(this.configurationService.sharedConfigurationModel.subscribe(model => {
      this.configuration = model;
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  protected handleActionButtonClick() {
    const model = this.data.model;
    model.name = model.title; // set the name the same as title

    this.isWorking = true;
    const disableCloseStateToRestore = this.dialogRef.disableClose;
    this.dialogRef.disableClose = true;

    this.vehicleService.createVehicleCategory(model)
      .then(apiResponse => {
        this.dialogRef.close(apiResponse.data);
      })
      .catch((error) => {
        console.warn('Vehicle group create error.', error);
        this.showSaveErrorToast();
      })
      .finally(() => {
        this.isWorking = false;
        this.dialogRef.disableClose = disableCloseStateToRestore;
      });
  }

  showSaveErrorToast() {
    this.toastService.short('Error while adding vehicle group');
  }

}

@Component({
  selector: 'app-dialog-confirm-delete-vehicle-category',
  templateUrl: './dialog-confirm-delete-vehicle-category.html',
})
export class DialogConfirmDeleteVehicleCategoryComponent {
  dialogBody: string = null;

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmDeleteVehicleCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: VehicleCategoryData
  ) {
    this.populateDialogBody();
  }

  private populateDialogBody() {
    this.dialogBody = `
      <p>
        Are you sure you want to delete vehicle group <strong>${this.data.title}</strong>?
      </p>
    `;
  }
}

