import {Component, Input} from '@angular/core';
import {ActionMenuItem} from '../../models/action-menu-item.class';

@Component({
  selector: 'app-card-menu-item',
  templateUrl: './card-menu-item.component.html',
  styleUrl: './card-menu-item.component.scss'
})
export class CardMenuItemComponent {
  @Input() input: ActionMenuItem;
}
