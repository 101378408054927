import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {
  LayerSpecification,
  LngLatBounds,
  Map,
  RasterLayerSpecification,
  RasterSourceSpecification,
  SymbolLayerSpecification
} from 'maplibre-gl';
import {MapLayersManager} from '../../../../../shared/components/map-viewer/map-layers-manager';
import {ConfigurationModel} from '../../../../../shared/models/configuration.model';
import {Subscription} from 'rxjs';
import {ConfigurationService} from '../../../../../configuration/configuration.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MapLayerConfiguration, MapLayerType} from '../../../../../shared/models/map-layer.model';

@Component({
  selector: 'app-map-layer-preview',
  templateUrl: './map-layer-preview.component.html',
  styleUrls: ['./map-layer-preview.component.scss']
})
export class MapLayerPreviewComponent implements OnInit, AfterViewInit {

  @ViewChild('map') private mapContainer: ElementRef<HTMLElement>;
  map: Map;
  tenantConfig: ConfigurationModel;
  configSubscription: Subscription;
  bounds: LngLatBounds = new LngLatBounds([-105.5, 39], [-105.0, 40]);
  mapLayers: MapLayerConfiguration[];
  errors: string[] = [];
  loaded = false;

  constructor(private configurationService: ConfigurationService,
              public dialogRef: MatDialogRef<MapLayerPreviewComponent>,
              @Inject(MAT_DIALOG_DATA) public data: MapLayerConfiguration[],
  ) { }

  ngOnInit(): void {
    this.mapLayers = this.data;
    this.configSubscription = this.configurationService.sharedConfigurationModel.subscribe(configuration => {
      if (configuration) {
        this.tenantConfig = configuration;
      }
    });
  }

  ngAfterViewInit(): void {
    if (!!this.tenantConfig) {
      const configuration = this.tenantConfig;
      if (configuration.region.length > 0) {
        this.bounds = configuration.region.reduce((bounds, latLngModel) => {
          return bounds.extend([latLngModel.lng, latLngModel.lat]);
        }, new LngLatBounds(configuration.region[0], configuration.region[0]));

        if (!!this.map) {
          this.map.setMaxBounds(this.bounds);
        }
      } else {
        console.warn('Tenant Region is empty, leaving default bounds.');
      }
    }

    this.loadMap();
  }

  loadMap() {
    const useMetric = this.tenantConfig?.useMetricSystem;
    this.map = new Map({
      container: this.mapContainer.nativeElement,
      style: !!useMetric
        ? MapLayersManager.ACCUTERRA_WINTER_M_MAP_STYLE
        : MapLayersManager.ACCUTERRA_WINTER_MAP_STYLE,
      bounds: this.bounds,
    });

    this.map.on('load', () => {
      // load additional layers
      this.mapLayers.forEach(mapLayer => {
        mapLayer.checked = true;
        this.addOverlayMapLayer(mapLayer);
      });
      this.loaded = true;
    });
  }

  toggleMapLayer(layerInfo: MapLayerConfiguration) {
    if (layerInfo.type === MapLayerType.RASTER) {
      if (this.map.getLayer(layerInfo.name)) {
        this.map.setLayoutProperty(
            layerInfo.name,
            'visibility',
            layerInfo.checked ? 'visible' : 'none'
        );
      }
    } else if (layerInfo.type === MapLayerType.VECTOR) {
      const vectorLayers = JSON.parse(layerInfo.configuration) as LayerSpecification[];
      vectorLayers.forEach((layer) => {
        if (this.map.getLayer(layer.id)) {
          this.map.setLayoutProperty(
              layer.id,
              'visibility',
              layerInfo.checked ? 'visible' : 'none'
          );
        }
      });
    } else if (layerInfo.type === MapLayerType.ROUTE) {
      console.log('TODO fixme ROUTE map layer type: ' + layerInfo.name);
    }
  }

  private addOverlayMapLayer(layerInfo: MapLayerConfiguration) {
    if (layerInfo.type === MapLayerType.RASTER) {
      this.addRasterOverlayMapLayer(layerInfo);
    } else if (layerInfo.type === MapLayerType.VECTOR) {
      this.addVectorOverlayMapLayer(layerInfo);
    } else if (layerInfo.type === MapLayerType.ROUTE) {
      console.log('TODO fixme ROUTE map layer type: ' + layerInfo.name);
    }
  }

  private addRasterOverlayMapLayer(layerInfo: MapLayerConfiguration) {
    const layer = {
      id: layerInfo.name,
      source: `${layerInfo.name}-source`,
      type: 'raster',
      layout: {
        visibility: layerInfo.checked ? 'visible' : 'none',
      },
      paint: {
        'raster-opacity': 1.0,
      },
    } as RasterLayerSpecification;

    const source = {
      type: 'raster',
      tiles: [layerInfo.url],
      tileSize: 256,
    } as RasterSourceSpecification;

    this.map.addSource(`${layerInfo.name}-source`, source);
    this.map.addLayer(layer as LayerSpecification);
  }

  private addVectorOverlayMapLayer(layerInfo: MapLayerConfiguration) {
    let configuration;
    try {
      configuration = JSON.parse(layerInfo.configuration);
    } catch (e: any) {
      this.setError(layerInfo, 'Error in layer configuration.', e);
      return;
    }
    const vectorLayers = configuration as LayerSpecification[];
    if (!vectorLayers || vectorLayers.length === 0) {
      this.setError(layerInfo, 'Empty configuration.', null);
    }
    vectorLayers.forEach((layer) => {
      const symbolLayer = layer as SymbolLayerSpecification;
      if (!this.validateSymbolLayerSpecification(layerInfo, symbolLayer)) {
        return;
      }
      this.map.addLayer(symbolLayer);
      if (this.map.getLayer(layer.id)) {
        this.map.setLayoutProperty(
            layer.id,
            'visibility',
            layerInfo.checked ? 'visible' : 'none'
        );
      }
    });
  }

  private validateSymbolLayerSpecification(layerInfo: MapLayerConfiguration, layer: SymbolLayerSpecification): boolean {
    let valid = true;
    if (!layer.id) {
      this.setError(layerInfo, 'Missing Layer ID in Symbol Layer Specification.', null);
      valid = false;
    }
    if (!layer.type || layer.type !== 'symbol') {
      this.setError(layerInfo, 'Missing or incorrect type in Symbol Layer Specification.', null);
      valid = false;
    }
    if (!layer.source) {
      this.setError(layerInfo, 'Missing source in Symbol Layer Specification.', null);
      valid = false;
    }
    return valid;
  }

  private setError(layerInfo: MapLayerConfiguration, message: string, exception: any) {
    let exceptionMessage = '';
    if (!!exception) {
      exceptionMessage = `Message: ${exception}`;
    }
    this.errors.push(`Layer ${layerInfo.name}: ${message} ${exceptionMessage}`);
    layerInfo.checked = false;
    layerInfo.disabledOnPreview = true;
  }
}
