import {ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector, Type} from '@angular/core';
import {LngLat, LngLatLike, Map, Popup} from 'maplibre-gl';
import {VehiclesManagerService} from '../../../../data/vehicles/vehicles-manager.service';
import {ObservationsManagerService} from '../../../../data/observations/observations-manager.service';
import {Observation} from '../../../models/observation';
import {ObservationInfoWindowContentComponent} from '../observation-info-window-content/observation-info-window-content.component';
import {ObservationFilterUpdate} from '../../../models/observations-filter';
import {Subscription} from 'rxjs';
import {RoadStatusInfoWindowContentComponent} from '../road-status-info-window-content/road-status-info-window-content.component';
import {AlertInfoWindowContentComponent} from '../alert-info-window-content/alert-info-window-content.component';
import {IncidentInfo} from './traffic-layer.service';
import {IncidentInfoWindowContentComponent} from '../incident-info-window-content/incident-info-window-content.component';

@Injectable({
  providedIn: 'root'
})
export class PopupInfoService {

  private map: Map;
  private lastComponentRef: ComponentRef<any>;
  private lastPopup: Popup;
  private lastVehicleId = -1;

  private readonly openSubscriptions = Array<Subscription>();

  constructor(private injector: Injector,
              private resolver: ComponentFactoryResolver,
              private appRef: ApplicationRef,
              private vehiclesManagerService: VehiclesManagerService,
              private observationsManagerService: ObservationsManagerService,
              ) { }

  init(map: Map) {
    if (!!this.map) {
      throw Error('The map has already been set.');
    }
    this.map = map;
    this.connectToManager();
  }

  private dismissLastInfoWindow() {
    if (!!this.lastComponentRef) {
      this.lastComponentRef.destroy();
      this.lastComponentRef = null;
    }
    if (this.lastPopup) {
      this.lastPopup.remove();
      this.lastVehicleId = -1;
    }
  }

  private instantiateComponent<T>(componentType: Type<T>): ComponentRef<T> {
    const compFactory = this.resolver.resolveComponentFactory(componentType);
    const newComponentRef = compFactory.create(this.injector);

    this.appRef.attachView(newComponentRef.hostView);
    this.lastComponentRef = newComponentRef;

    return newComponentRef;
  }

  private showObservation(observation: Observation) {
    const infoComponentRef = this.instantiateComponent(ObservationInfoWindowContentComponent);
    infoComponentRef.instance.observation = observation;
    infoComponentRef.instance.isLiveMap = true;

    const div = document.createElement('div');
    div.appendChild(this.lastComponentRef.location.nativeElement);

    this.lastPopup = new Popup({offset: [0, -15], className: 'marker-popup', maxWidth: '300px'})
      .setLngLat([observation.location.coords.lng, observation.location.coords.lat])
      .setDOMContent(div)
      .addTo(this.map);
  }

  private handleObservationSelectionFilterChanged(filter: ObservationFilterUpdate|null) {
    this.dismissLastInfoWindow();

    if (!!filter && !!filter.observation) {
      this.showObservation(filter.observation);
    }
  }

  public showRoadStatusInfoWindow(roadSegmentId: number, coords: LngLatLike) {
    const infoComponentRef = this.instantiateComponent(RoadStatusInfoWindowContentComponent);
    infoComponentRef.instance.roadSegmentId = roadSegmentId;

    const div = document.createElement('div');
    div.appendChild(this.lastComponentRef.location.nativeElement);

    this.lastPopup = new Popup({offset: [0, -15], className: 'marker-popup', maxWidth: '300px'})
        .setLngLat(coords)
        .setDOMContent(div)
        .addTo(this.map);
  }

  public showWeatherAlert(location: LngLat, oid: string, title: string) {
    const infoComponentRef = this.instantiateComponent(AlertInfoWindowContentComponent);
    infoComponentRef.instance.oid = oid;
    infoComponentRef.instance.title = title;

    const div = document.createElement('div');
    div.appendChild(this.lastComponentRef.location.nativeElement);

    this.lastPopup = new Popup({maxWidth: '500px'})
        .setLngLat(location)
        .setDOMContent(div)
        .addTo(this.map);
  }

  public showTrafficIncidentInfo(location: LngLat, incidentInfo: IncidentInfo) {
    const infoComponentRef = this.instantiateComponent(IncidentInfoWindowContentComponent);
    infoComponentRef.instance.incidentInfo = incidentInfo;

    const div = document.createElement('div');
    div.appendChild(this.lastComponentRef.location.nativeElement);

    this.lastPopup = new Popup({maxWidth: '450px'})
        .setLngLat(location)
        .setDOMContent(div)
        .addTo(this.map);
  }

  private connectToManager() {
    const activeObservationSubscription = this.observationsManagerService.highlightedObservation$.subscribe(activeObservation => {
      this.handleObservationSelectionFilterChanged(activeObservation);
    });
    this.openSubscriptions.push(activeObservationSubscription);
  }

  release() {
    if (!this.map) {
      throw Error('The map has not been set. Only a single map instance is supported.');
    }

    this.openSubscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });

    this.map = null;
    this.lastComponentRef = undefined;
  }
}
