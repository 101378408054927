<ng-container *ngIf="group">
    <div class="group-content-wrapper"
         [ngClass]="{ expanded: !group.collapsed }"
    >
        <div class="group-wrapper ops-form">
            <div class="group">
                <button mat-icon-button
                        (click)="toggleGroupExpansion()"
                >
                    <mat-icon *ngIf="group.collapsed" class="tree-level-button">arrow_right</mat-icon>
                    <mat-icon *ngIf="!group.collapsed" class="tree-level-button">arrow_drop_down</mat-icon> <!-- expanded -->

                </button>

                <div class="group-name">{{ group.name }}</div>
            </div>
        </div>

        <div class="items" *ngIf="!group.collapsed">
            <div *ngFor="let item of group.items" class="item">
                <app-route-group-menu
                        [path]="getPath()"
                        [routeHierarchyItem]="item"
                ></app-route-group-menu>
            </div>
        </div>
    </div>
</ng-container>

 <ng-container *ngIf="item">
     <app-action-menu-item
             [input]="item"
     ></app-action-menu-item>
 </ng-container>
