import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  /**
   * Transforms duration in seconds to
   * @param value Must be a number
   */
  transform(value: any): any {
    if (!value) {
      return '0 m';
    }

    const finalDuration = moment.duration(value, 'seconds');

    const hours = finalDuration.asHours();
    const hoursFormatted = hours && hours > 1 ? ` ${Math.floor(hours)} h` : '';

    const minutes = finalDuration.minutes();
    const minutesFormatted = minutes ? ` ${minutes} m` : '';

    const seconds = finalDuration.seconds();
    const secondsFormatted = ` ${seconds} s`;

    return [hoursFormatted, minutesFormatted, secondsFormatted].join('');
  }
}
