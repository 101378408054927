
@if (beingEdited) {
	<div class="flex-row align-items-center">
		<div class="flex-grow-1">
			<mat-form-field appearance="outline" color="accent" class="w-100" subscriptSizing="dynamic">
				<mat-label>Message Text</mat-label>
				<input matInput
					   autocomplete="off"
					   type=text
					   [(ngModel)]="headlineText"
				>
			</mat-form-field>
		</div>
		<div class="action-buttons">
			<!--<button mat-icon-button
					color="accent"
					[(colorPicker)]="headlineColor"
					[cpAlphaChannel]="'disabled'"
					[cpFallbackColor]="'#FFFFFF'"
			>
				<mat-icon>format_color_text</mat-icon>
			</button>
			<button mat-icon-button
					color="accent"
					[(colorPicker)]="headlineBackground"
					[cpAlphaChannel]="'disabled'"
					[cpFallbackColor]="'#0b568a'"
			>
				<mat-icon>format_color_fill</mat-icon>
			</button>-->
			<button mat-icon-button
					color="accent"
					(click)="update()"
					[disabled]="!headlineText"
					[matTooltip]="'Save'"
			>
				<mat-icon>save</mat-icon>
			</button>
			<button mat-icon-button
					color="accent"
					(click)="cancel()"
					[matTooltip]="'Cancel'"
			>
				<mat-icon>Cancel</mat-icon>
			</button>
		</div>
	</div>
} @else {
	<div class="flex-row align-items-center">
		<mat-slide-toggle [hideIcon]="true"
						  matTooltip="Show/Hode the text on the site"
						  (change)="selectedChanged()"
						  [checked]="headline.selected">
		</mat-slide-toggle>

		<mat-divider vertical class="h-100 divider"></mat-divider>

		<div class="flex-grow-1">{{ headline.text }}</div>

		<div class="action-buttons">
			<button mat-icon-button
					color="accent"
					(click)="edit()"
					[matTooltip]="'Edit message'"
			>
				<mat-icon>edit</mat-icon>
			</button>
			<button mat-icon-button
					color="accent"
					(click)="delete()"
					[matTooltip]="'Remove message'"
			>
				<mat-icon>delete</mat-icon>
			</button>
		</div>
	</div>
}
