<div class="flex-column gap-32 mt-16">
<app-card preCardTitle="User Management">
	@if (cartegraphEnabled) {
		<div *appCardContent class="settings-header-container">
			<div class="settings-header-title">
				<p>Add Users to your organization</p>
				You can import drivers from Cartegraph
			</div>
			<div class="settings-header-actions">
				<button (click)="showImportCartegraphDialog()"
						mat-stroked-button>
					Import users
				</button>
			</div>
		</div>
	} @else {
		<div *appCardContent class="settings-header-container mb-spacing">
			<div class="settings-header-title">
				<p>Add Manually</p>
				<div class="settings-header-subtitle">Add a single new user</div>
			</div>
			<div class="settings-header-actions">
				<button (click)="showAddDialog()"
						mat-stroked-button>
					Add User
				</button>
			</div>
		</div>
		<div *appCardContent class="settings-header-container mt-spacing">
			<div class="settings-header-title">
				<p>Import Users</p>
				<div class="settings-header-subtitle">Import a group of users from a spreadsheet</div>
			</div>
			<div class="settings-header-actions">
				<button (click)="showImportSpreadsheetDialog()"
						mat-stroked-button>
					Import
				</button>
			</div>
		</div>
	}
</app-card>

	<app-card preCardTitle="Users">
		<div *appCardContent>
			<div *ngIf="!isLoading && uiError && uiError.length > 0" class="content">
				<span class="ui-error">{{ uiError }}</span>
			</div>

			<div #tableScrollRoot>
				<table mat-table
					   matSort
					   matSortDisableClear
					   matSortActive="firstName"
					   (matSortChange)="onSortChanged()"
					   [dataSource]="dataSource">

					<ng-container matColumnDef="firstName" sticky>
						<th mat-header-cell *matHeaderCellDef
							mat-sort-header disableClear start="asc">First Name
						</th>
						<td mat-cell *matCellDef="let element">{{ element.givenName }}</td>
					</ng-container>

					<ng-container matColumnDef="lastName" sticky>
						<th mat-header-cell *matHeaderCellDef class="left-from-non-sticky"
							mat-sort-header disableClear>Last Name
						</th>
						<td mat-cell *matCellDef="let element" class="left-from-non-sticky">{{ element.familyName }}</td>
					</ng-container>

					<ng-container matColumnDef="login">
						<th mat-header-cell *matHeaderCellDef
							mat-sort-header disableClear>Email
						</th>
						<td mat-cell *matCellDef="let element">
							<ng-container *ngIf="!element.isDriverOnly">{{ element.email }}</ng-container>
						</td>
					</ng-container>

					<ng-container matColumnDef="roles">
						<th mat-header-cell *matHeaderCellDef>Role</th>
						<td mat-cell *matCellDef="let element">{{ element.mainRoleLabel }}</td>
					</ng-container>

					<ng-container matColumnDef="actions" stickyEnd>
						<th mat-header-cell *matHeaderCellDef class="right-from-non-sticky"></th>
						<td mat-cell *matCellDef="let element" class="right-from-non-sticky column-actions">
							<ng-container
									*ngIf="element.canUpdateInfo || element.isDriverOnly || element.canUpdatePassword || element.canDelete">
								<button
										[matMenuTriggerFor]="menu"
										mat-icon-button
										aria-label="Actions"
								>
									<mat-icon>more_vert</mat-icon>
								</button>
								<mat-menu #menu="matMenu">
									<button mat-menu-item
											*ngIf="element.canUpdateInfo"
											(click)="updateUser(element.email)">
										<mat-icon>edit</mat-icon>
										<span>Update User Information</span>
									</button>
					<button mat-menu-item
							*ngIf="element.isDriverOnly"
							(click)="promoteUser(element.email)">
					  <mat-icon>upgrade</mat-icon>
					  <span>Promote User</span>
					</button>
									<button mat-menu-item
											*ngIf="element.canUpdatePassword"
											(click)="changePassword(element.email)">
										<mat-icon>password</mat-icon>
										<span>Update User Password</span>
									</button>
									<button mat-menu-item
											*ngIf="element.canDelete"
											(click)="deleteUser(element.email)">
										<mat-icon>delete</mat-icon>
										<span>Delete user</span>
									</button>
								</mat-menu>
							</ng-container>
						</td>
					</ng-container>

					<ng-container matColumnDef="none">
						<td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
							<div #listEnd></div>
							@if (isLoading) {
								<div class="loading-spinner-container">
									<mat-spinner class="mx-a" diameter="30"></mat-spinner>
								</div>
							}
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
					<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
					<tr mat-footer-row *matFooterRowDef="['none']"></tr>

					<!-- Row shown when there is no matching data. -->
					<tr class="mat-row" *matNoDataRow>
						<td [attr.colspan]="displayedColumns.length" class="text--center text-muted pt-15">
							@if (!isLoading) {
								@if (searchFilter) {
									There are no users matching your filter
								} @else {
									There are no users
								}
							}
						</td>
					</tr>

				</table>
			</div>
		</div>
	</app-card>
</div>
