import { MapContentLayer } from '../MapContentLayer';
import {LayerSpecification, SymbolLayerSpecification} from 'maplibre-gl';
import { MapContentSource } from '../MapContentSource';
import { MapPreviewComponent } from '../../map-preview.component';
import { MapStyles } from '../../../../../configuration/map-styles';

export class ImageIconLayer extends MapContentLayer {

  constructor(
    source: MapContentSource
  ) {
    super('Image-icon-layer', source);
  }

  toLayerSpecification(): LayerSpecification {
    return {
      id: this.layerId,
      type: 'symbol',
      source: this.sourceRef.sourceId,
      layout: {
        'icon-image': MapPreviewComponent.CAMERA_IMAGE,
        'icon-allow-overlap': true,
        'symbol-sort-key': [
          'case',
          ['boolean', ['get', 'time']],
          2,
          1,
        ],
      },
      paint: {
        'icon-color': [
          'case',
          ['boolean', ['get', 'highlighted']],
          MapStyles.HIGHLIGHTED_COLOR,
          MapStyles.LIVE_COLOR,
        ],
      },
    } as SymbolLayerSpecification;
  }

}
