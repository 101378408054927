<amplify-authenticator [hideSignUp]="true" [formFields]="formFields">
    <ng-template amplifySlot="header">
        <div style="padding: var(--amplify-space-medium); text-align: center">
            <img
                    class="amplify-image"
                    alt="Amplify logo"
                    [src]="getLoginLogoUrl()"
            />
        </div>
    </ng-template>

    <ng-template amplifySlot="sign-in-footer">
        <div style="padding: 0 32px var(--amplify-space-medium) 32px; text-align: center">
            <button
                    (click)="authenticator.toForgotPassword()"
                    class="amplify-button amplify-button--fullwidth amplify-button--link amplify-button--small"
                    data-fullwidth="true"
                    data-size="small"
                    data-variation="link"
                    type="button"
                    style="font-weight: normal"
            >
                Forgot your password?
            </button>

            <button
                    (click)="goTo('https://plowops.com/contact')"
                    class="amplify-button amplify-button--fullwidth amplify-button--link amplify-button--small"
                    data-fullwidth="true"
                    data-size="small"
                    data-variation="link"
                    type="button"
                    style="font-weight: normal"
            >
                Contact PlowOps for an account
            </button>
        </div>
    </ng-template>

    <ng-template amplifySlot="footer">
        <div class="footer">
            <button
                    (click)="goTo('https://plowops.com/terms-of-service/')"
                    class="amplify-button amplify-button--fullwidth amplify-button--link amplify-button--small footer-link"
                    data-fullwidth="true"
                    data-size="small"
                    data-variation="link"
                    type="button"
                    style="font-weight: normal"
            >
                Terms of Service
            </button>

            <button
                    (click)="goTo('https://plowops.com/privacy-policy')"
                    class="amplify-button amplify-button--fullwidth amplify-button--link amplify-button--small footer-link"
                    data-fullwidth="true"
                    data-size="small"
                    data-variation="link"
                    type="button"
                    style="font-weight: normal"
            >
                Privacy Policy
            </button>
        </div>
    </ng-template>

    <ng-template
            amplifySlot="authenticated"
    >
        <router-outlet></router-outlet>
    </ng-template>
</amplify-authenticator>

