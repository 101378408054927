import {Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ImageService} from '../../../data/images/image.service';
import Hls from 'hls.js';
import moment from 'moment';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit, OnDestroy {
  @Input() vehicleId: number;
  @Input() timeFrom: Date;
  @Input() timeTo: Date;
  private hls = new Hls();
  currentTime: number;
  absUnixTime: number;

  isLoading: boolean;
  isError = false;

  autoplay = true;
  liveMode: boolean;
  videoRelativeStart = 0;
  browserSupportsFullScreen: boolean;
  liveDiff = 0; // in milliseconds

  private duration: number;

  @ViewChild('video', { static: true }) private readonly video: ElementRef<HTMLVideoElement>;
  @ViewChild('progress', { static: true }) private readonly progress: ElementRef<HTMLProgressElement>;

  constructor(
      private elementRef: ElementRef,
      private imageService: ImageService,
      @Inject(DOCUMENT) private document: any
  ) {
    this.browserSupportsFullScreen = !!(
        document.fullscreenEnabled ||
        document.mozFullScreenEnabled ||
        document.msFullscreenEnabled ||
        document.webkitSupportsFullscreen ||
        document.webkitFullscreenEnabled ||
        document.createElement('video').webkitRequestFullScreen
    );
  }

  ngOnInit() {
    this.isLoading = true;
    this.isError = false;
    // initialize with live mode if timeTo is not set
    this.liveMode = !this.timeTo;
    this.imageService.getVideoStreamURL(
        this.vehicleId,
        this.liveMode ? null : (!!this.timeFrom ? new Date(this.timeFrom) : null),
        this.liveMode ? null : (!!this.timeTo ? new Date(this.timeTo) : null),
    ).toPromise().then(response => {
      // const videoSrc = 'https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8';
      const videoSrc = response.data;
      if (!!videoSrc) {
        if (Hls.isSupported()) {
          this.hls.loadSource(videoSrc);
          this.hls.attachMedia(this.video.nativeElement);
        }
            // HLS.js is not supported on platforms that do not have Media Source
            // Extensions (MSE) enabled.
            //
            // When the browser has built-in HLS support (check using `canPlayType`),
            // we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video
            // element through the `src` property. This is using the built-in support
            // of the plain video element, without using HLS.js.
            //
            // Note: it would be more normal to wait on the 'canplay' event below however
            // on Safari (where you are most likely to find built-in HLS support) the
            // video.src URL must be on the user-driven white-list before a 'canplay'
            // event will be emitted; the last video event that can be reliably
        // listened-for when the URL is not on the white-list is 'loadedmetadata'.
        else if (this.video.nativeElement.canPlayType('application/vnd.apple.mpegurl')) {
          this.video.nativeElement.src = videoSrc;
        }
      } else {
        this.isError = true;
      }
      this.isLoading = false;
    }).catch(error => {
      console.error(error);
      this.isError = true;
      this.isLoading = false;
    });
  }

  ngOnDestroy() {
    if (this.hls) {
      this.hls.destroy();
    }
    this.duration = undefined;
  }

  onPlayPauseClick() {
    if (this.video.nativeElement.paused || this.video.nativeElement.ended) {
      this.video.nativeElement.play();
    } else {
      this.video.nativeElement.pause();
    }
  }

  onMetadataLoaded() {
  }

  onTimeUpdate() {
    if (!this.liveMode) {
      this.progress.nativeElement.setAttribute(
          'max',
          String(this.getDuration())
      );
      const videoCurrentTime = this.video.nativeElement.currentTime;
      this.updatePositionLabels(videoCurrentTime);
    } else {
      // live mode
      this.liveDiff = moment().diff(moment(this.hls.playingDate));
    }
  }

  private getDuration(): number {
    if (!this.timeTo) {
      return moment().unix() - moment(this.timeFrom).unix();
    } else {
      if (!this.duration) {
        this.duration = moment(this.timeTo).unix() - moment(this.timeFrom).unix();
      }
      return this.duration;
    }
  }

  onProgressClick(e) {
    const rect = this.progress.nativeElement.getBoundingClientRect();
    const pos = (e.pageX - rect.left) / this.progress.nativeElement.offsetWidth;
    const posRelative = pos * this.getDuration();
    this.loadStreamUrl(posRelative);
  }

  private updatePositionLabels(currentTime: number) {
    this.progress.nativeElement.value = this.videoRelativeStart + currentTime;
    this.currentTime = this.videoRelativeStart + currentTime;
    this.absUnixTime = moment(this.timeFrom).unix() + this.videoRelativeStart + currentTime;
  }

  private async loadStreamUrl(positionRelativeSeconds: number) {
    let streamFrom = null;
    if (!this.liveMode) {
      streamFrom = new Date((moment(this.timeFrom).unix() + positionRelativeSeconds) * 1000);
      this.videoRelativeStart = positionRelativeSeconds;
      this.updatePositionLabels(0);
    }

    this.isLoading = true;
    return this.imageService.getVideoStreamURL(
        this.vehicleId,
        streamFrom,
        this.liveMode ? null : (!!this.timeTo ? new Date(this.timeTo) : null),
    ).toPromise().then(response => {
      const videoSrc = response.data;
      if (!!videoSrc) {
        if (Hls.isSupported()) {
          this.hls.loadSource(videoSrc);
          if (this.isError) {
            this.hls.attachMedia(this.video.nativeElement);
          }
        }
        this.isError = false;
      } else {
        this.hls.detachMedia();
        this.isError = true;
      }
      this.isLoading = false;
      return this.isError;
    }).catch(error => {
      console.error(error);
      this.hls.detachMedia();
      this.isError = true;
      this.isLoading = false;
      return this.isError;
    });
  }

  onLiveToggle() {
    this.liveMode = !this.liveMode;
    this.loadStreamUrl(0);
  }

  onFullScreen() {
    this.video.nativeElement.requestFullscreen();
  }

  async refresh() {
    return await this.loadStreamUrl(0);
  }
}
