import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../../../../shared/material/material.module';
import {SharedModule} from '../../../../shared/shared.module';
import {RouterModule} from '@angular/router';
import {ManageVehicleInstallationComponent} from './manage-vehicle-installation.component';


@NgModule({
  declarations: [
    ManageVehicleInstallationComponent
  ],
  exports: [],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule,
    RouterModule,
  ]
})
export class ManageVehicleInstallationModule {
}
