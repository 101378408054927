<div class="message-top-bar">
    <button
            class="drawer-action-button"
            mat-button
            (click)="closeMessage()"
    >
        <mat-icon>arrow_back</mat-icon> <span class="button-label">Back</span>
    </button>

    <button class="delete__btn text-primary-dark"
            mat-icon-button
            (click)="deleteMessage()"
    >
        <mat-icon class="delete-icon action-icon">delete</mat-icon>
    </button>
</div>

<div class="message-subject">
    {{ message.subject }}
</div>

<div class="message-created-at">
    {{ getCreatedAt() | shortDateOrTime }}
</div>

<div class="message-body">
    {{ message.body }}
</div>

<div class="message-seen">
    {{ seenMessagesCount(message) }}/{{ message.messageDeliveries.length }}
    <mat-icon>visibility</mat-icon>
</div>


<div>
    <div class="message-recipient-filters">
        <span class="highlighted">Recipients</span>
        <ng-container *ngFor="let filter of allFilters">
            <button class="filter-button mat-small"
                    color="accent"
                    [ngClass]="{'highlighted': filter === recipientFilter}"
                    (click)="changeFilter(filter)"
                    mat-button
            >
                {{filter}}
            </button>
        </ng-container>
    </div>
    <div *ngFor="let recipient of message.messageDeliveries | filterRecipients: recipientFilter" class="message-recipient">
        <div>{{ recipient.recipientName }}</div>
        <div *ngIf="recipient.seenAt === null">Unread</div>
        <div *ngIf="recipient.seenAt !== null">{{ recipient.seenAt | date:'short' }}</div>
    </div>
</div>

