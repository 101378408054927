<div>
    <div class="active-indicator ops-selectable-secondary"
         [ngClass]="{ 'active': activeDrawer === drawerType.content }"
    ></div>
    <button mat-icon-button
            (click)="showDrawerPanel(drawerType.content)"
            [ngClass]="{ 'active': activeDrawer === drawerType.content }"
            [matTooltip]="drawerType.label"
            [matTooltipPosition]="'left'"
            class="ops-selectable-secondary"
    >
        <mat-icon class="mat-icon-bolder">{{ drawerType.icon }}</mat-icon>
    </button>
</div>
