
export class DvirFormReviewingSignaturesOption {
    public static ALWAYS_REQUIRED = new DvirFormReviewingSignaturesOption('Driver only', 'All forms must be signed by the person doing the inspection', 'ALWAYS_REQUIRED');
    public static REQUIRED_ON_ISSUE = new DvirFormReviewingSignaturesOption('Co-signature', 'All defects must be co-signed by mechanic or supervisor', 'REQUIRED_ON_ISSUE');
    public static NEVER_REQUIRED = new DvirFormReviewingSignaturesOption('Never required', '', 'NEVER_REQUIRED');

    public static options = [
        DvirFormReviewingSignaturesOption.ALWAYS_REQUIRED,
        DvirFormReviewingSignaturesOption.REQUIRED_ON_ISSUE,
        DvirFormReviewingSignaturesOption.NEVER_REQUIRED
    ];

    public static signatureOptions = [
        DvirFormReviewingSignaturesOption.ALWAYS_REQUIRED,
        DvirFormReviewingSignaturesOption.REQUIRED_ON_ISSUE,
    ];

    static label = 'Reviewing Signatures';
    static default = DvirFormReviewingSignaturesOption.ALWAYS_REQUIRED;
    static optionCode = 'REVIEWING_SIGNATURES';

    label: string;
    description: string;
    code: string;

    constructor(label: string, description: string, code: string) {
        this.label = label;
        this.description = description;
        this.code = code;
    }

    static fromCode(code: string) {
        const found = DvirFormReviewingSignaturesOption.options.find(value => value.code === code);
        if (found == null) { throw new Error(`error parsing MifFormReviewingSignaturesOption, code = ${code}`); }
        return found;
    }
}
