import {Component, Inject} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import {ConfirmDeleteFormDialogData} from './ConfirmDeleteFormDialogData';
import {MatButton} from '@angular/material/button';

@Component({
  standalone: true,
  selector: 'app-confirm-delete-form-dialog',
  templateUrl: './confirm-delete-form-dialog.component.html',
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatButton,
    MatDialogTitle
  ],
  styleUrls: ['./confirm-delete-form-dialog.component.scss']
})
export class ConfirmDeleteFormDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDeleteFormDialogData) {}
}
