import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {JsonApiResponse} from '../../shared/models/JsonApiResponse';
import {catchError, map} from 'rxjs/operators';
import {HttpErrorHandler} from '../../http.error.handler';
import {GeobufResponseHandler} from '../../geobuf.response.handler';
import {LocationSearchParams} from '../../shared/models/breadcrumb.model';
import {FeatureCollection} from 'geojson';
import {SessionMetricEventModel} from '../../shared/models/session-metric.model';
import {firstValueFrom, of} from 'rxjs';
import {SessionStatistics} from '../../shared/models/shift.model';

@Injectable({
  providedIn: 'root'
})
export class LocationApiService {

  constructor(
    private http: HttpClient,
  ) {
  }

  getCurrentLocationPoints() {
    return this.http.get(
      `${environment.services.location}v1/location/current`,
      {
        observe: 'response',
        responseType: 'arraybuffer',
        headers: {Accept: 'application/octet-stream'}
      }
    )
      .pipe(
        map(response => GeobufResponseHandler.handleResponse(response)),
        catchError(HttpErrorHandler.handleError) // then handle the error
      ).toPromise();
  }

  getCurrentLocationPointsGeoJson() {
    return this.http.get<FeatureCollection>(
      `${environment.services.location}v1/location/current`,
      {
        headers: {Accept: 'application/json'}
      }
    )
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      ).toPromise();
  }

  getLocationHistoryTrackHeads() {
    return this.http.get(
      `${environment.services.location}v1/track/head`,
      {
        observe: 'response',
        responseType: 'arraybuffer',
        headers: {Accept: 'application/octet-stream'}
      }
    )
      .pipe(
        map(response => GeobufResponseHandler.handleResponse(response)),
        catchError(HttpErrorHandler.handleError) // then handle the error
      ).toPromise();
  }

  search(searchParams: LocationSearchParams) {
    return this.http.post<FeatureCollection>(
      `${environment.services.location}v1/location/history/search`,
      searchParams,
      {
        headers: {Accept: 'application/json'}
      }
    )
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      );
  }

  closestSessionMetricEvent(shiftId: number, vehicleId: number, metricId: number, unixtime: number = Math.round(Date.now().valueOf() / 1000)) {
    const request: SessionMetricEventModel = {
      locationSourceId: vehicleId,
      sessionKey: shiftId,
      metricId,
      unixtime
    };
    return firstValueFrom(this.http.post<JsonApiResponse<SessionMetricEventModel>>(
      `${environment.services.location}v1/session-metric/event/closest`,
      request,
      {
        headers: {Accept: 'application/json'}
      }
    )
      .pipe(
        catchError((err, caught) => {
          if (err.status === 500 && err.error && err.error.error && err.error.error.indexOf('404 NOT_FOUND') >= 0) {
            const other = new JsonApiResponse<SessionMetricEventModel>();
            other.data = new SessionMetricEventModel();
            return of(other);
          } else {
            return HttpErrorHandler.handleError(err);
          }
        })
      ));
  }

  getLocationHistoryVectorTileUrl(): string {
    return `${environment.services.location}v1/track/tile/{z}/{x}/{y}`;
  }

  getRoadSegments(flagMask: number = null) {
    let params: HttpParams = new HttpParams();
    if (!!flagMask) {
      params = params.set('flagMask', flagMask);
    }

    return this.http.get(
      `${environment.services.location}v1/road/segment`,
      {
        params,
        observe: 'response',
        responseType: 'arraybuffer',
        headers: {Accept: 'application/octet-stream'}
      }
    )
      .pipe(
        map(response => GeobufResponseHandler.handleResponse(response)),
        catchError(HttpErrorHandler.handleError) // then handle the error
      ).toPromise();
  }

  getShiftStatistics(shiftId: number) {
    return this.http.get<JsonApiResponse<SessionStatistics[]>>(
      `${environment.services.location}v1/session/statistic?sessionKey=${shiftId}`
    )
      .pipe(
        catchError(HttpErrorHandler.handleError)
      );
  }

  getShiftPoints(shiftId: number, vehicleId: number): Promise<FeatureCollection> {
    return this.http.get(
      `${environment.services.location}v1/track/vertex?sessionKey=${shiftId}&locationSourceId=${vehicleId}&anchorTime=0`,
      {
        observe: 'response',
        responseType: 'arraybuffer',
        headers: {Accept: 'application/octet-stream'}
      }
    )
      .pipe(
        map(response => GeobufResponseHandler.handleResponse(response)),
        catchError(HttpErrorHandler.handleError)
      ).toPromise();
  }

  getShiftTrack(shiftId: number, vehicleId: number): Promise<FeatureCollection> {
    return this.http.get(
      `${environment.services.location}v1/track/segment?sessionKey=${shiftId}&locationSourceId=${vehicleId}`,
      {
        observe: 'response',
        responseType: 'arraybuffer',
        headers: {Accept: 'application/octet-stream'}
      }
    )
      .pipe(
        map(response => GeobufResponseHandler.handleResponse(response)),
        catchError(HttpErrorHandler.handleError) // then handle the error
      ).toPromise();
  }

  getShiftBoundingBox(shiftId: number) {
    return this.http.get<number[]>(
      `${environment.services.location}v1/track/${shiftId}/boundingBox`,
    )
      .pipe(
        catchError(HttpErrorHandler.handleError)
      ).toPromise();
  }
}
