<div class="style-editor-wrapper">
	<mat-form-field appearance="outline" color="accent" class="w-100">
		<mat-label>Route Color</mat-label>
		<input matInput
			   [(ngModel)]="routeStyle.color"
			   (change)="styleChanged()">
		<ngx-colors matSuffix class="suffix color-picker"
					ngx-colors-trigger
					colorPickerControls="no-alpha"
					[(ngModel)]="routeStyle.color"
					(ngModelChange)="styleChanged()">
		</ngx-colors>
	</mat-form-field>

	<mat-form-field appearance="outline" color="accent" class="no-hint w-100">
		<mat-label>Route Width</mat-label>
		<input matInput
			   type="number"
			   min="0"
			   [(ngModel)]="routeStyle.width"
			   (change)="styleChanged()">
	</mat-form-field>

	<mat-form-field appearance="outline" color="accent" class="no-hint w-100">
		<mat-label>Route Opacity</mat-label>
		<input matInput
			   type="number"
			   max="1"
			   min="0"
			   [matTooltip]="'Decimal value 0.0 - 1.0'"
			   [(ngModel)]="routeStyle.opacity"
			   (ngModelChange)="styleChanged()">
	</mat-form-field>

	<app-line-type-selector
			class="w-100"
			[(dashArray)]="routeStyle.lineType"
			(lineTypeChanged)="styleChanged()">
	</app-line-type-selector>

	<!-- preview -->
	<!--<div
		 [style.width]="'80px'"
		 [style.opacity]="routeStyle?.opacity"
		 [style.height]="routeStyle?.width + 'px'"
		 [style.background-color]="routeStyle?.color"
	></div>-->
</div>
