import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardLayoutComponent} from './layouts/dashboard-layout/dashboard-layout.component';
import {LiveMapModule} from './pages/live-map/live-map.module';
import {SettingsModule} from './pages/settings/settings.module';
import {UnauthorizedComponent} from './pages/public/components/unauthorized/unauthorized.component';
import {CallbackComponent} from './pages/public/components/callback/callback.component';
import {RootRoute} from './shared/models/angular-routing';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: `${RootRoute.MAIN}` },
  { path: 'live-map', pathMatch: 'full', redirectTo: `${RootRoute.MAIN}` },
  {
    path: '',
    component: DashboardLayoutComponent,
    children: [
      {
        path: RootRoute.MAIN,
        loadChildren: () => LiveMapModule,
      },
    ],
  },
  {
    path: RootRoute.SETTINGS,
    loadChildren: () => SettingsModule,
  },
  {
    path: 'callback',
    component: CallbackComponent,
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(
      routes,
      {
        useHash: false,
        enableTracing: false
      }
      )],
  exports: [RouterModule],
})
export class AppRoutingModule {}
