import {Injectable} from '@angular/core';
import {WebSocketClientService} from './web-socket-client.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServicesSocketService extends WebSocketClientService {
  getEndpointBaseUrl(): string| null {
    return environment.services.plowopsWebsocket;
  }
}
