import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ConfigurationModel} from '../../../../../shared/models/configuration.model';
import {Subscription} from 'rxjs';
import {UserManagement, UserModelView, UserType} from '../../../../../shared/models/User';
import {
  FormControl,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {ConfigurationService} from '../../../../../configuration/configuration.service';
import {UserManagementService} from '../../../../../data/users/user-management.service';
import {JsonApiResponse} from '../../../../../shared/models/JsonApiResponse';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {NgForOf, NgIf} from '@angular/common';
import {MatError, MatFormField, MatLabel, MatSuffix} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {MatOption, MatSelect} from '@angular/material/select';
import {MatSlideToggle} from '@angular/material/slide-toggle';

export enum UserEditMode {
  CREATE = 'create',
  UPDATE = 'update',
  SELF_UPDATE = 'self-update'
}

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  standalone: true,
  imports: [
    MatProgressSpinner,
    NgIf,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatSelect,
    MatOption,
    NgForOf,
    MatSuffix,
    MatSlideToggle,
    MatLabel,
    MatError
  ],
  styleUrl: './edit-user.component.scss'
})
export class EditUserComponent implements OnInit, OnDestroy {

  isLoading = true;
  configuration: ConfigurationModel;
  userType: UserType;
  userForm: UntypedFormGroup;
  @Input() data: { mode: UserEditMode, user: UserModelView };
  uiError = '';
  isSubmitting = false;
  isSubmitted = false;
  private readonly openSubscriptions = Array<Subscription>();
  // user type dropdown
  private userTypeDriver = {name: UserManagement.ROLE_DRIVER, label: UserManagement.ROLE_DRIVER_LABEL} as UserType;
  private userTypeSupervisor = {
    name: UserManagement.ROLE_PORTAL_USER,
    label: UserManagement.ROLE_PORTAL_USER_LABEL
  } as UserType;
  private userTypeAdmin = {
    name: UserManagement.ROLE_PORTAL_ADMIN,
    label: UserManagement.ROLE_PORTAL_ADMIN_LABEL
  } as UserType;
  userTypes: UserType[] = [
    this.userTypeDriver,
    this.userTypeSupervisor,
    this.userTypeAdmin,
  ];
  private hideFromLogin = false;
  private emailPrefix: string;

  isJustDriver = false;

  constructor(
    protected fb: UntypedFormBuilder,
    private configurationService: ConfigurationService,
    private userManagementService: UserManagementService,
  ) {
  }

  get ngUserType(): FormControl {
    return this.userForm.get('userType') as FormControl;
  }

  get ngGivenName(): FormControl {
    return this.userForm.get('givenName') as FormControl;
  }

  get ngFamilyName(): FormControl {
    return this.userForm.get('familyName') as FormControl;
  }

  get ngEmailPrefix(): FormControl {
    return this.userForm.get('emailPrefix') as FormControl;
  }

  get ngPhoneNumber(): FormControl | null {
    return this.userForm.get('phoneNumberWithOutCountryCode') as FormControl;
  }

  get ngHideFromLogin(): FormControl | null {
    return this.userForm.get('hideFromLogin') as FormControl;
  }

  static getCreateNewUserParams(): { mode: UserEditMode, user: UserModelView } {
    return {
      mode: UserEditMode.CREATE,
      user: new UserModelView(),
    };
  }

  ngOnDestroy(): void {
    try {
      this.openSubscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    } catch (e) {
    }
  }

  ngOnInit(): void {
    this.initForm();
    this.loadGeneralConfiguration();

    // set if creating new user
    if (this.data.user.roles === undefined) {
      this.data.user.roles = [UserManagement.ROLE_DRIVER];
    }
    if (this.data.user.roles.includes(UserManagement.ROLE_PORTAL_ADMIN)) {
      this.ngUserType.setValue(this.userTypeAdmin);
    } else if (this.data.user.roles.includes(UserManagement.ROLE_PORTAL_USER)) {
      this.ngUserType.setValue(this.userTypeSupervisor);
    } else if (this.data.user.roles.includes(UserManagement.ROLE_DRIVER)) {
      this.ngUserType.setValue(this.userTypeDriver);
    } else {
      this.ngUserType.setValue(this.userTypeDriver); // for new user
    }

    if (this.ngHideFromLogin) {
      if (this.data.user.roles.includes(UserManagement.ROLE_DRIVER)) {
        this.ngHideFromLogin.setValue(false);
      } else {
        this.ngHideFromLogin.setValue(true);
      }
    }

    if (this.isModeUpdate() || this.isModeSelfUpdate()) {
      if (this.userForm.get('emailPrefix') !== null) {
        this.userForm.removeControl('emailPrefix');
      }
    }

    this.isJustDriver = this.data.user.roles.length === 1 && this.data.user.roles.indexOf(UserManagement.ROLE_DRIVER) === 0;
    if (this.isModeUpdate() && this.isJustDriver) {
      this.ngUserType.disable();
    }
  }

  isModeCreate() {
    return this.data.mode === UserEditMode.CREATE;
  }

  isModeUpdate() {
    return this.data.mode === UserEditMode.UPDATE;
  }

  isModeSelfUpdate() {
    return this.data.mode === UserEditMode.SELF_UPDATE;
  }

  isDriverType(userType: UserType) {
    return userType.name === UserManagement.ROLE_DRIVER;
  }

  userTypeChange(userType: UserType) {
    // handle toggle to hide driver or defaults
    this.setUserRoles(userType);

    if (userType.name === UserManagement.ROLE_DRIVER) {
      if (this.ngHideFromLogin) {
        this.ngHideFromLogin.setValue(false);
        this.userForm.removeControl('hideFromLogin');
      }
      if (this.userForm.get('countryCode') !== null) {
        this.userForm.removeControl('countryCode');
      }
      if (this.userForm.get('phoneNumberWithOutCountryCode') !== null) {
        this.userForm.removeControl('phoneNumberWithOutCountryCode');
      }
      if (this.userForm.get('emailPrefix') !== null) {
        this.userForm.removeControl('emailPrefix');
      }
    } else {
      if (!this.ngHideFromLogin) {
        this.userForm.addControl('hideFromLogin', this.buildHideFromLogin());
        this.ngHideFromLogin.setValue(false);
      }
      if (this.userForm.get('countryCode') === null) {
        this.userForm.addControl('countryCode', this.buildCountryCodeControl());
      }
      if (this.userForm.get('phoneNumberWithOutCountryCode') === null) {
        this.userForm.addControl('phoneNumberWithOutCountryCode', this.buildPhoneNumberControl());
      }
      if (this.isModeCreate() && this.userForm.get('emailPrefix') === null) {
        this.userForm.addControl('emailPrefix', this.buildEmailControl());
      }
    }
  }

  buildHideFromLogin() {
    const control = this.fb.control(this.hideFromLogin);
    control.valueChanges.subscribe(change => {
      this.updateDriverRole(!change);
    });
    return control;
  }

  async save(): Promise<UserModelView> {
    const result = await this.createOrUpdateUser();
    return result.data;
  }

  createOrUpdateUser(): Promise<JsonApiResponse<UserModelView>> {
    this.isSubmitting = true;

    const user = this.data.user;
    let apiResponsePromise: Promise<JsonApiResponse<UserModelView>>;
    switch (this.data.mode) {
      case UserEditMode.CREATE:
        apiResponsePromise = this.userManagementService.createUser(user);
        break;
      case UserEditMode.UPDATE:
      case UserEditMode.SELF_UPDATE:
        apiResponsePromise = this.userManagementService.updateUser(user).toPromise();
        break;
    }

    apiResponsePromise
      .then((_) => {
        this.uiError = '';
        this.isSubmitted = true;
      })
      .catch((error) => {
        this.uiError = error;
      })
      .finally(() => {
        this.isSubmitting = false;
      });

    return apiResponsePromise;
  }

  canBeSaved() {
    return this.userForm.valid
      && !this.isSubmitting && !this.isSubmitted && !this.isLoading;
  }

  private initForm() {
    // init form
    this.userForm = this.fb.group({
      email: this.fb.control(this.data.user.email),
      userType: this.fb.control(this.userType),
      givenName: this.fb.control(this.data.user.givenName, {
        validators: [
          Validators.required
        ]
      }),
      familyName: this.fb.control(this.data.user.familyName),
      emailPrefix: this.buildEmailControl(),
      countryCode: this.buildCountryCodeControl(),
      phoneNumberWithOutCountryCode: this.buildPhoneNumberControl(),
      hideFromLogin: this.buildHideFromLogin()
    });
    this.userForm.controls['email'].disable();

    // on change listeners, update the data back to the model
    this.userForm.valueChanges.subscribe(change => {
      this.data.user.givenName = change['givenName'];
      this.data.user.familyName = change['familyName'];
      this.data.user.phoneNumber = !!change['phoneNumberWithOutCountryCode']
        ? change['countryCode'] + change['phoneNumberWithOutCountryCode']
        : null;
      if (this.isModeCreate() && !this.isDriverType(change['userType'])) {
        this.data.user.email = change['emailPrefix'] + this.configuration.driverSuffix;
      }
      if (this.isModeCreate() && this.isDriverType(change['userType'])) {
        delete this.data.user.email;
      }
    });

    this.userForm.get('userType').valueChanges.subscribe(change => {
      this.userTypeChange(change);
    });
  }

  private hasDriverRole() {
    return this.data.user.roles.indexOf(UserManagement.ROLE_DRIVER) > -1;
  }

  private loadGeneralConfiguration() {
    const configSubscription = this.configurationService.sharedConfigurationModel.subscribe(model => {
      this.configuration = model;
      this.isLoading = false;
    });
    this.openSubscriptions.push(configSubscription);
  }

  private buildEmailControl(): UntypedFormControl {
    return this.fb.control(this.emailPrefix, {
      validators: [
        Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+$')
      ]
    });
  }

  private buildCountryCodeControl(): UntypedFormControl {
    return this.fb.control('+1'); // always +1 (USA/Canada)
  }

  private buildPhoneNumberControl(): UntypedFormControl {
    let phoneNumber = null;
    if (!!this.data.user.phoneNumber && this.data.user.phoneNumber.length === 12) {
      phoneNumber = this.data.user.phoneNumber.substring(2);
    }
    return this.fb.control(phoneNumber, {
      validators: [
        Validators.pattern(/^\d{10}$/)
      ]
    });
  }

  private setUserRoles(userType: UserType) {
    const hasDriverRole = this.hasDriverRole();
    switch (userType.name) {
      case UserManagement.ROLE_DRIVER:
        this.data.user.roles = [
          UserManagement.ROLE_DRIVER
        ];
        break;
      case UserManagement.ROLE_PORTAL_USER:
        this.data.user.roles = [
          UserManagement.ROLE_PORTAL_USER
        ];
        break;
      case UserManagement.ROLE_PORTAL_ADMIN:
        this.data.user.roles = [
          UserManagement.ROLE_PORTAL_USER,
          UserManagement.ROLE_PORTAL_ADMIN];
        break;
      default:
        this.data.user.roles = [UserManagement.ROLE_DRIVER];
    }
    this.updateDriverRole(hasDriverRole);
  }

  private updateDriverRole(keepDriver: boolean) {
    const driverRoleIndex = this.data.user.roles.indexOf(UserManagement.ROLE_DRIVER);
    if (keepDriver && driverRoleIndex === -1) {
      this.data.user.roles.push(UserManagement.ROLE_DRIVER);
    }
    if (!keepDriver && driverRoleIndex > -1) {
      this.data.user.roles.splice(driverRoleIndex, 1);
    }
  }
}
