import { Injectable } from '@angular/core';
import { ServicesSocketService } from '../websocket/services-socket.service';
import { EventRecord } from '../../shared/models/event.model';
import { ReplaySubject, Subscription } from 'rxjs';
import { EventEvent } from '../websocket/model/event.event';
import { MessageSource, WebSocketEvent } from '../websocket/model/message.event';

@Injectable({
  providedIn: 'root'
})
export class EventManagerService {

  private isInitialized = false;

  private eventRecords: EventRecord[] = [];
  readonly events$ = new ReplaySubject<EventRecord[]>(1);
  statusEventSubscription: Subscription;

  constructor(
      private servicesSocketService: ServicesSocketService,
  ) { }

  public init(
      eventRecords: EventRecord[],
  ) {
    if (this.isInitialized) {
      throw Error('The EventManagerService has already been initialized.');
    }

    this.eventRecords = eventRecords;
    this.events$.next(this.eventRecords);

    this.statusEventSubscription = this.servicesSocketService
        .onMessage(MessageSource.EVENT)
        .subscribe((eventEvent: WebSocketEvent<EventEvent, EventRecord>) => {
          this.eventRecords.splice(0, 0, eventEvent.data);
          this.events$.next(this.eventRecords);
        });
  }

  public release() {
    this.eventRecords = [];
    this.statusEventSubscription?.unsubscribe();
    this.isInitialized = false;
  }
}
