import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ReportsService} from '../../../../data/reports/reports.service';

@Component({
  templateUrl: 'dialogs/shift-map-inspection-dialog.html',
  styles: [
    '.iframe { border: 0; width: 800px; height: calc(90vh - 73px); }',
    '.frame { overflow-y: hidden; overflow-x: auto; }',
  ]
})
export class ShiftMapInspectionDialogComponent implements OnInit {

  @ViewChild('iframe') iframe: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<ShiftMapInspectionDialogComponent>,
    private reportsService: ReportsService,
    @Inject(MAT_DIALOG_DATA) public data: { shiftId: string, preTrip: boolean }) {
  }

  ngOnInit(): void {
    this.reportsService.getDvirByShiftHtml(+this.data.shiftId, this.data.preTrip).subscribe(blob => {
      this.iframe.nativeElement.src = URL.createObjectURL(blob);
    });
  }

}
