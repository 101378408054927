<div class="legend">
    <div class="legend-header">
        <div>
            Traffic
        </div>
    </div>

    <div class="legend-subtitle">
        <span>Current traffic conditions and incidents</span>
    </div>

    <div class="legend-line">
        <div class="legend-item__wrapper">
            <!--<div class="legend-item__box"
                 [ngStyle]="{ background: '#6dccac' }"
            >
            </div>-->
            <app-line-dash-array-view
                    [dashArray]="undefined"
                    [color]="'#6dccac'"
            ></app-line-dash-array-view>

            <div class="legend-item__name">
                Free Flow
            </div>
        </div>
        <div class="legend-item__wrapper">
            <!--<div class="legend-item__box"
                 [ngStyle]="{ background: '#e9d14c' }"
            >
            </div>-->
            <app-line-dash-array-view
                    [dashArray]="undefined"
                    [color]="'#e9d14c'"
            ></app-line-dash-array-view>

            <div class="legend-item__name">
                Moderate
            </div>
        </div>
        <div class="legend-item__wrapper">
            <!--<div class="legend-item__box"
                 [ngStyle]="{ background: '#ec8d00' }"
            >
            </div>-->
            <app-line-dash-array-view
                    [dashArray]="undefined"
                    [color]="'#ec8d00'"
            ></app-line-dash-array-view>

            <div class="legend-item__name">
                Slow
            </div>
        </div>
        <div class="legend-item__wrapper">
            <!--<div class="legend-item__box"
                 [ngStyle]="{ background: '#e6002e' }"
            >
            </div>-->
            <app-line-dash-array-view
                    [dashArray]="undefined"
                    [color]="'#e6002e'"
            ></app-line-dash-array-view>

            <div class="legend-item__name">
                Stop and Go
            </div>
        </div>
    </div>
</div>
