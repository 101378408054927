import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, retry} from 'rxjs/operators';
import {HttpErrorHandler} from '../../http.error.handler';
import {environment} from '../../../environments/environment';
import {JsonApiResponse} from '../../shared/models/JsonApiResponse';
import {DeviceInfo} from '../../shared/models/device-info';

@Injectable({
  providedIn: 'root'
})
export class DeviceInfoService {

  constructor(private http: HttpClient) {
  }

  getDeviceInfoForShift(shiftId: number) {
    return this.http.get<JsonApiResponse<DeviceInfo>>(`${environment.services.service}v1/device-info`, {
      params: {
        'shift-id': shiftId
      }
    })
      .pipe(
        retry(2), // retry a failed request up to 3 times
        catchError(HttpErrorHandler.handleError) // then handle the error
      );
  }
}
