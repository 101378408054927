import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {Subscription} from 'rxjs';
import {ConfigurationModel} from '../../../models/configuration.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export class GpsTracksSettings {
    showRecent: boolean;
    timeLimit: number;
}

@Component({
  selector: 'app-gps-tracks-config',
  templateUrl: './gps-tracks-config.component.html',
  styleUrl: './gps-tracks-config.component.scss'
})
export class GpsTracksConfigComponent implements OnInit, OnDestroy {

    hours: number[] = [];

    private tenantConfigurationSubscription: Subscription;
    configuration: ConfigurationModel;

    constructor(
        public dialogRef: MatDialogRef<GpsTracksConfigComponent>,
        @Inject(MAT_DIALOG_DATA) public data: GpsTracksSettings,
        private configurationService: ConfigurationService,
    ) {
    }

    ngOnInit(): void {
        this.tenantConfigurationSubscription = this.configurationService.sharedConfigurationModel.subscribe(tenantConfiguration => {
            if (tenantConfiguration) {
                this.configuration = tenantConfiguration;
                // array: 1..desired length
                this.hours = Array.from({length: this.configuration.lookBackPeriod}, (x, i) => i + 1);
            }
        });
    }

    ngOnDestroy(): void {
        this.tenantConfigurationSubscription?.unsubscribe();
    }

    reset() {
        this.data.showRecent = true;
        this.data.timeLimit = this.configuration.lookBackPeriod;
    }

    isDefault() {
        return this.data.showRecent && this.data.timeLimit === this.configuration.lookBackPeriod;
    }
}
