import {DvirQuestion} from './dvir-question';

export class DvirGroup {
  id?: number;
  rank?: number;
  title?: string;
  hasRemarks = false;

  questions: DvirQuestion[] = [];

  constructor(questions: DvirQuestion[] = []) {
    this.questions = questions;
  }

}
