<div class="">
	<div class="field-row">
		<div class="field-row-title">Vehicle ID</div>
		<div class="field-row-element">
			<mat-form-field appearance="outline" color="accent" class="field-row">
				<input matInput
					   autocomplete="off"
					   [disabled]="data.isImported"
					   [(ngModel)]="data.model.name"
                       #name="ngModel"
					   required
					   [attr.cdkFocusInitial]="data.isImported !== true ? 'true' : null"
				>
				<mat-error *ngIf="name.invalid && name.errors.required">
					Vehicle ID is required.
				</mat-error>
			</mat-form-field>
		</div>
	</div>
</div>
