import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {MapControlService} from '../../../../../shared/components/map-viewer/services/map-control.service';
import {AddressSuggestion, ArcgisApiService} from '../../../../../data/address-search/arcgis-api.service';
import {
  AddressLookupMapMarkerService
} from '../../../../../shared/components/map-viewer/services/address-lookup-map-marker.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AddressVisit, LocationInfo} from '../../../../../shared/models/AddressLookup';
import {Subscription} from 'rxjs';
import {AssetsManagerService} from '../../../../../data/assets/assets-manager.service';
import {ImagesManagerService} from '../../../../../data/images/images-manager.service';
import {Base64Tools} from '../../../../../shared/tools/Base64Tools';
import {AddressLookupService} from '../../../../../data/address-search/address-lookup.service';
import moment from 'moment';
import {MainRoute, RootRoute} from '../../../../../shared/models/angular-routing';

@Component({
  selector: 'app-live-address',
  templateUrl: './live-address.component.html',
  styleUrl: './live-address.component.scss'
})
export class LiveAddressComponent implements OnInit, OnDestroy, AfterViewInit {

  readonly recentSearchRangeHours = 72;
  addressSuggestion: AddressSuggestion;
  address: LocationInfo;
  addressVisits: AddressVisit[];
  private readonly openSubscriptions = Array<Subscription>();

  RootRoute = RootRoute;
  MainRoute = MainRoute;

  constructor(
      private mapControlService: MapControlService,
      private arcGisApiService: ArcgisApiService,
      private addressLookupMapMarkerService: AddressLookupMapMarkerService,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private assetsManager: AssetsManagerService,
      private imageManager: ImagesManagerService,
      private addressLookupService: AddressLookupService,
  ) {
  }

  ngOnInit() {
    this.assetsManager.filterByVehicleIds(null);

    const paramsSubscription = this.activatedRoute.queryParams.subscribe(params => {
      this.addressSuggestion = {text: params['text'], magicKey: params['key'], isCollection: false};
      // console.log('set suggestion');
    });
    this.openSubscriptions.push(paramsSubscription);
  }

  ngAfterViewInit() {
    if (!!this.addressSuggestion.magicKey) {
      this.arcGisApiService.findAddressBasedOnSuggestion(this.addressSuggestion.text, this.addressSuggestion.magicKey).then(response => {
        this.address = LocationInfo.fromArcGisGeocodeResponse(response, 1);
        // console.log(this.address);
        this.addressLookupMapMarkerService.addLocations([this.address]);
        this.mapControlService.zoomToCoordinates(this.address.geometry, 15);
        this.addressLookupService.findLocationHistory(
            LocationInfo.toLatLngModel(this.address),
            moment(),
            moment().subtract(this.recentSearchRangeHours, 'hour'),
        ).toPromise().then(addressVisits => {
          // console.log('visits: ');
          // console.log(addressVisits.data);
          this.addressVisits = addressVisits.data;
        });
      });
    }
  }

  ngOnDestroy() {
    this.openSubscriptions.forEach(subscription => subscription.unsubscribe());
    if (this.addressLookupMapMarkerService.isInitialized) {
      this.addressLookupMapMarkerService.clearLocations();
    }
  }

  close() {
    this.router.navigate(
        [`/${RootRoute.MAIN}`],
        {
          queryParamsHandling: 'merge',
          queryParams: {text: undefined, key: undefined},
        }
    );
  }

  getAddressLookupSearchParams() {
    return {
      search: Base64Tools.encodeText(this.address.formatted_address),
      address: Base64Tools.encodeText(this.address.id.toString()),
    };
  }

  isOlderThanToday(dateTime: Date) {
    return moment(dateTime).isBefore(moment().startOf('day'));
  }
}
