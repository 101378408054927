import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatButton} from '@angular/material/button';

@Component({
  selector: 'app-row-with-button',
  standalone: true,
    imports: [
        MatButton
    ],
  templateUrl: './row-with-button.component.html',
  styleUrl: './row-with-button.component.scss'
})
export class RowWithButtonComponent {

  @Input() primaryText!: string;
  @Input() secondaryText: string;
  @Input() buttonLabel!: string;
  @Output() buttonClicked = new EventEmitter<void>();

  handleClick() {
    this.buttonClicked.emit();
  }
}
