<h1 mat-dialog-title>
  Promote Driver
</h1>

<mat-dialog-content>
  <p>Promote Driver to Supervisor or Admin</p>

  <form [formGroup]="userForm">
    <p>
      <mat-form-field appearance="outline" color="accent">
        <mat-label>User role</mat-label>
        <mat-select formControlName="userType">
          <mat-option *ngFor="let userType of userTypes" [value]="userType">{{userType.label}}</mat-option>
        </mat-select>
      </mat-form-field>
    </p>

    <p>
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Email</mat-label>
        <input matInput
               autocomplete="off"
               formControlName="emailPrefix"
               required>
        <span matTextSuffix>{{configuration.driverSuffix}}</span>
        <mat-error *ngIf="ngEmailPrefix?.invalid && ngEmailPrefix?.errors?.required">
          Email is required.
        </mat-error>
        <mat-error *ngIf="ngEmailPrefix?.invalid && ngEmailPrefix?.errors?.pattern">
          Email format is not valid.
        </mat-error>
      </mat-form-field>
    </p>

    <p class="ui-error" *ngIf="uiError">
      {{ uiError }}
    </p>
  </form>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close>Cancel</button>
  <button mat-flat-button color="primary" [disabled]="!userForm.valid"
          (click)="performSave()">Save</button>
</mat-dialog-actions>
