import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {firstValueFrom, Observable, Subscription} from 'rxjs';
import {inOutAnimation} from 'src/app/shared/animations/animations';
import {MapPanelComponent} from '../../../shared/components/map-viewer/map-panel/map-panel.component';
import {ConfigurationModel, FeatureFlagEnum} from '../../../shared/models/configuration.model';
import {ConfigurationService} from '../../../configuration/configuration.service';
import {VehiclesService} from '../../../data/vehicles/vehicles.service';
import {VehiclesManagerService} from '../../../data/vehicles/vehicles-manager.service';
import {ObservationsService} from '../../../data/observations/observations.service';
import {ObservationsManagerService} from '../../../data/observations/observations-manager.service';
import {ImagesManagerService} from '../../../data/images/images-manager.service';
import {DriversService} from '../../../data/drivers/drivers.service';
import {DriversManagerService} from '../../../data/drivers/drivers-manager.service';
import {ShiftsManagerService} from '../../../data/shifts/shifts-manager.service';
import {ServicesSocketService} from '../../../data/websocket/services-socket.service';
import {MatSnackBarRef, SimpleSnackBar} from '@angular/material/snack-bar';
import {JsonApiResponse} from '../../../shared/models/JsonApiResponse';
import {LiveMapDataService} from '../services/live-map-data.service';
import {select, Store} from '@ngrx/store';
import {SettingsService} from '../../../configuration/settings.service';
import {ObservationManagementService} from '../../../data/observations/observation-management.service';
import {DriverWithShiftCount} from '../../../shared/models/driver.model';
import {ServerEventService} from '../../../data/server-events/server-event.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AssetsManagerService} from '../../../data/assets/assets-manager.service';
import {RoutesService} from '../../../data/routes/routes.service';
import {RouteAssignmentService} from '../../../data/routes/route-assignment.service';
import {RouteAssignmentManagerService} from '../../../data/routes/route-assignment-manager.service';
import {debounceTime} from 'rxjs/operators';
import {MapControlService} from '../../../shared/components/map-viewer/services/map-control.service';
import {ArcgisApiService} from '../../../data/address-search/arcgis-api.service';
import {
  AddressLookupMapMarkerService
} from '../../../shared/components/map-viewer/services/address-lookup-map-marker.service';
import {LocationSocketService} from '../../../data/websocket/location-socket.service';
import {RoutesManagerService} from '../../../data/routes/routes-manager.service';
import moment from 'moment';
import {DrawerContent, DrawerType} from '../../../layouts/right-drawer/right-drawer.component';
import {LeftPanelActiveItem, LeftPanelSection} from './left-panel/live-map-panel.component';
import {MainRoute, RootRoute} from '../../../shared/models/angular-routing';
import {MessagesService} from '../../../data/messages/messages.service';
import {MessageRecipientCollectionModel} from '../models/message-recipient-collection.model';
import {MessageModel} from '../models/message.model';
import {Page} from '../../../shared/models/Page';
import {EventService} from '../../../data/events/event.service';
import {EventManagerService} from '../../../data/events/event-manager.service';

export enum MapOverlayType {
    NONE,
    NARROW,
    FULL,
}

@Component({
  selector: 'app-live-map',
  templateUrl: './live-map.component.html',
  styleUrls: ['./live-map.component.scss'],
  animations: [inOutAnimation],
})
export class LiveMapComponent implements OnInit, OnDestroy {
  sidenavOpen$?: Observable<boolean>;

  @ViewChild(MapPanelComponent)
  mapComponent: MapPanelComponent;

  configuration: ConfigurationModel;
  isLoading = true;
  loadingDataName: string;
  loadError: string;
  activeDrawer: DrawerContent;
  private readonly openSubscriptions = Array<Subscription>();

  private pushStatusUnavailableSnack: MatSnackBarRef<SimpleSnackBar> = null;

  showMapOverlay: MapOverlayType = MapOverlayType.NONE;
  activeLeftPanelItem: LeftPanelActiveItem;
  option = 'default';

  private mapResizeObserver: ResizeObserver;
  private mapResizeObserverSubscription: Subscription;
  @ViewChild('mapContent') private mapContainer: ElementRef<HTMLElement>;

  readonly DrawerContent = DrawerContent;
  readonly DrawerType = DrawerType;
  readonly FeatureFlagEnum = FeatureFlagEnum;
  readonly MapOverlayType = MapOverlayType;
  readonly MainRoute = MainRoute;
  readonly RootRoute = RootRoute;
  readonly LeftPanelSection = LeftPanelSection;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private configurationService: ConfigurationService,
    private settingsService: SettingsService,
    private vehiclesService: VehiclesService,
    private vehiclesManager: VehiclesManagerService,
    private observationsService: ObservationsService,
    private observationGroupsService: ObservationManagementService,
    private observationsManager: ObservationsManagerService,
    private imagesManager: ImagesManagerService,
    private driversService: DriversService,
    private driversManager: DriversManagerService,
    private shiftsManager: ShiftsManagerService,
    private routesService: RoutesService,
    private routeManagerService: RoutesManagerService,
    private routeAssignmentService: RouteAssignmentService,
    private routeAssignmentManager: RouteAssignmentManagerService,
    private serverEventService: ServerEventService,
    private servicesSocketService: ServicesSocketService,
    private locationSocketService: LocationSocketService,
    private liveMapDataService: LiveMapDataService,
    private assetsManager: AssetsManagerService,
    private mapControlService: MapControlService,
    private arcGisApiService: ArcgisApiService,
    private addressLookupMapMarkerService: AddressLookupMapMarkerService,
    private store: Store<any>,
    private messagesService: MessagesService,
    private eventService: EventService,
    private eventManager: EventManagerService,
  ) {}

  ngOnInit(): void {
    this.servicesSocketService.init().then(() => {
        const serviceSocketStatusSubscription =
            this.servicesSocketService.connectionStatusObservable.subscribe(
                (status) => {
                    if (!status) {
                        console.log('PlowOps WebSocket: waiting...');
                    } else {
                        console.log('PlowOps WebSocket: connected');
                        if (this.pushStatusUnavailableSnack != null) {
                            this.pushStatusUnavailableSnack.dismiss();
                            this.pushStatusUnavailableSnack = null;
                        }
                    }
                }
            );
        this.openSubscriptions.push(serviceSocketStatusSubscription);
    }).catch(error => {
        console.log('Error while connecting to PlowOps Svc Web Socket!');
    });

    this.locationSocketService.init().then(() => {
      const locationSocketStatusSubscription =
        this.locationSocketService.connectionStatusObservable.subscribe(
          (status) => {
            if (!status) {
              console.log('Location WebSocket: waiting...');
            } else {
              console.log('Location WebSocket: connected');
              if (this.pushStatusUnavailableSnack != null) {
                this.pushStatusUnavailableSnack.dismiss();
                this.pushStatusUnavailableSnack = null;
              }
            }
          }
        );
      this.openSubscriptions.push(locationSocketStatusSubscription);
    }).catch(error => {
        console.log('Error while connecting to Location Svc Web Socket!');
    });

    // NGRX Stuff
    this.sidenavOpen$ = this.store.pipe(
      select((state) => state.ui.sidenavOpen)
    );
    this.loadGeneralConfiguration();

    this.activatedRoute.queryParams.subscribe((params) => {
        this.activeDrawer = params.drawer;
    });
  }

  ngOnDestroy() {
    for (const subscription of this.openSubscriptions) {
      subscription.unsubscribe();
    }
    this.openSubscriptions.length = 0;

    this.servicesSocketService.release();
    this.locationSocketService.release();
    this.routeManagerService.release();
    this.routeAssignmentManager.release();
    this.eventManager.release();
    this.shiftsManager.release();
    this.driversManager.release();
    this.observationsManager.release();
    this.imagesManager.release();
    this.vehiclesManager.release();
    this.assetsManager.release();
    this.serverEventService.release();
    this.mapResizeObserver?.disconnect();
    this.mapResizeObserverSubscription?.unsubscribe();
  }

  private subscribeToMapResize() {
    const resizeObservable = new Observable<void>(subscriber => {
        this.mapResizeObserver = new ResizeObserver(entries => {
            entries.forEach(entry => {
                subscriber.next();
            });
        });
    });

    // added delay to allow mapContainer to be initialized
    setTimeout(() => {
        this.mapResizeObserverSubscription = resizeObservable
            .pipe(debounceTime(200))
            .subscribe({ next: () => {
                if (!this.activatedRoute.snapshot.queryParams?.drawer) {
                    console.log('Resizing maplibre component explicitly.');
                    this.mapControlService.resizeMap();
                }
            } });
        this.mapResizeObserver.observe(this.mapContainer?.nativeElement);
    }, 2000);
  }

  /*private onSettingsChanged(key: string, value: any) {
    if (key === SettingsService.TRACKS_LAYER_TYPE_KEY) {
      this.currentFilter = value;
    }
    /!*if (key === SettingsService.ROUTE_STATUS_MODE) {
      this.routeStatusMode = value;
    }*!/
  }*/

  private loadGeneralConfiguration() {
    this.loadingDataName = 'Configuration';

    const configSubscription =
      this.configurationService.sharedConfigurationModel.subscribe(
        (model) => {
          // run only once with correct model
          if (model && !this.configuration) {
            this.configuration = model;
            this.loadVehiclesConfiguration();
          }
        }, // success path
        (error) => (this.loadError = error) // error path
      );
    this.openSubscriptions.push(configSubscription);
  }

  private loadVehiclesConfiguration() {
    this.loadingDataName = 'Vehicles';

    Promise.all([
      this.vehiclesService.getVehicleCategories(),
      firstValueFrom(this.vehiclesService.getVehiclesWithActiveShift()),
    ])
      .then((responses) => {
        const [categoriesResponse, vehiclesResponse] = responses;
        // initialize data managers
        this.serverEventService.init();
        this.vehiclesManager.init(
          categoriesResponse.data,
          vehiclesResponse.data
        );
        // send data to child components
        this.liveMapDataService.sendVehicleCategories(categoriesResponse.data);
        this.liveMapDataService.sendVehicles(vehiclesResponse.data);
        // continue with initial data load
        this.loadDriversConfiguration();
      })
      .catch((error) => {
        this.loadError = error; // error path
      });
  }

    private loadDriversConfiguration() {
        this.loadingDataName = 'Drivers';

        const driversSubscription = this.driversService.getDriverList().subscribe(
            (response: JsonApiResponse<DriverWithShiftCount[]>) => {
                this.driversManager.init(response.data);
                this.liveMapDataService.sendDrivers(response.data);
                this.loadObservationsConfiguration();
            }, // success path
            (error) => (this.loadError = error)
        );
        this.openSubscriptions.push(driversSubscription);
    }

  private loadObservationsConfiguration() {
    this.loadingDataName = 'Observations';

    Promise.all([
        this.observationsService.getObservationTypes().toPromise(),
        this.observationGroupsService.getObservationTypeGroups().toPromise()
    ]).then(responses => {
        const [observationTypesResponse, observationGroupsResponse] = responses;
        // initialize observation manager
        this.observationsManager.init(
            observationTypesResponse.data,
            observationGroupsResponse.data
        );
        // initialize image manager
        this.imagesManager.init(
            this.configuration,
        );
        this.loadShiftsConfiguration();
    });
  }

    private loadShiftsConfiguration() {
      this.shiftsManager.init(this.configuration).then(() => {
        this.loadAssets();
      });
    }

    private loadAssets() {
      this.assetsManager.init();
      this.loadRouteConfiguration();
    }

    private loadRouteConfiguration() {
        this.loadingDataName = 'Routes';

        this.routesService.getRouteConfigurations().then(response => {
            this.liveMapDataService.sendRouteConfiguration(response.data);
            this.routeManagerService.init(response.data);
            this.loadVehiclesRouteAssignmentStatus();
        }).catch((error) => {
            this.loadError = error; // error path
        });
    }

    private loadVehiclesRouteAssignmentStatus() {
        this.loadingDataName = 'Route Assignments';

        Promise.all([
            this.routeAssignmentService.getRouteAssignments(
                moment().subtract(168, 'hour').toDate(),
                null,
                null,
                null,
                null,
                true,
                false,
            ),
            this.routeAssignmentService.getVehicleStatus(),
        ]).then(responses => {
            const [recentAssignments, vehicleStatus] = responses;
            this.routeAssignmentManager.init(recentAssignments.data, vehicleStatus.data);
            this.loadEvents();
        }).catch((error) => {
            this.loadError = error; // error path
        });
    }

    private loadEvents() {
        this.loadingDataName = 'Events';

        this.eventService.getRecentEvents().then(response => {
            this.eventManager.init(response.data);
            this.loadMessages();
        }).catch((error) => {
            this.loadError = error; // error path
        });
    }

    private loadMessages() {
        this.loadingDataName = 'Messages';

        // load message recipients
        this.loadMessageRecipients();

        // initialize recent messages
        this.messagesService
            .getRecentMessages()
            .then((response: JsonApiResponse<MessageModel[]>) => {
                this.liveMapDataService.sendRecentMessages(response.data);
                this.isLoading = false;
                this.subscribeToMapResize();
            });

        // TODO create infinite list?
        // initialize archived messages
        this.messagesService
            .getArchivedMessages(0, 30)
            .then((response: JsonApiResponse<Page<MessageModel>>) => {
                this.liveMapDataService.sendArchivedMessages(response.data.content);
            });
    }

    private loadMessageRecipients() {
        this.messagesService
            .getRecipients()
            .then((response: JsonApiResponse<MessageRecipientCollectionModel>) => {
                this.liveMapDataService.sendAvailableRecipients(response.data);
            });
    }

    onActiveItemChanged(activeItem: LeftPanelActiveItem) {
      this.activeLeftPanelItem = activeItem;
      switch (activeItem?.type) {
          case LeftPanelSection.VEHICLES:
              this.showMapOverlay = MapOverlayType.NARROW;
              break;
          case LeftPanelSection.ROUTES:
              this.showMapOverlay = MapOverlayType.NARROW;
              break;
          case LeftPanelSection.INSIGHTS:
              this.showMapOverlay = !!activeItem.insightName ? MapOverlayType.FULL : MapOverlayType.NONE;
              break;
          case LeftPanelSection.ADDRESS:
              this.showMapOverlay = MapOverlayType.NARROW;
              break;
          case LeftPanelSection.ABOUT:
              this.showMapOverlay = MapOverlayType.FULL;
              break;
          default:
              this.showMapOverlay = MapOverlayType.NONE;
      }
    }

    /*assetFiltersShown() {
        return !!this.childComponent &&
            !(this.childComponent instanceof AssetComponent) && // not shown on single Asset
            !(this.childComponent instanceof ObservationsComponent) && // not shown on Observations
            !(this.childComponent instanceof MessagesComponent) && // not shown on Messages
            (!this.routeStatusMode || !(this.childComponent instanceof RoutesComponent)); // not shown on Routes in Status Mode
    }

    observationFiltersShown() {
        return this.childComponent instanceof ObservationsComponent;
    }

    routeModeToggleShown() {
        return this.childComponent instanceof RoutesComponent;
    }*/

    /*locationLayersHidden() {
        return this.childComponent instanceof ObservationsComponent ||
            this.childComponent instanceof MessagesComponent ||
            (this.routeStatusMode && this.childComponent instanceof RoutesComponent);
    }*/

    /*onVehicleGroupFilterChange(filter: MultiSelectFilter<VehicleCategoryModel>) {
      this.assetsManager.filterByVehicleGroup(filter.elements?.map(group => group.id));
    }

    onStatusFilterChange(filter: MultiSelectFilter<AssetStatus>) {
      this.assetsManager.filterByStatus(filter.elements?.map(status => status.id));
    }

    onHardwareFilterChange(filter: HardwareType) {
      this.assetsManager.filterByHardware(filter?.id);
    }

    onObservationTypeGroupFilterChange(filter: MultiSelectFilter<ObservationTypeGroup>) {
      this.observationsManager.filterByObservationTypeGroup(filter.elements?.map(group => group.id));
    }

    onDateFilterChange(filter: LabeledDateFilter) {
      this.observationsManager.filterByDate(filter);
    }*/

    hasFeatureFlag(featureFlag: string): boolean {
        return this.configuration.featureFlags.find(value => value.isEnabled && value.name === featureFlag) !== undefined;
    }
}
