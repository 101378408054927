<h1 mat-dialog-title>{{ headline }}</h1>
<mat-dialog-content style="position:relative;">
    <div>
        <app-row-with-toggle primaryText="Additional Hardware Integration"
							 [(checked)]="ui.useAvl"
                             (checkedChange)="onAvlChange()"
        ></app-row-with-toggle>


        <div class="field-row-divider"></div>

        <div *ngIf="!!model.hardwareConfiguration">

	  <app-row-with-select primaryText="AVL Model"
						   [disabled]="!(model.hardwareConfiguration)"
						   [list]="ui.allAvlModels" listId="id" listLabel="name"
						   [(model)]="model.hardwareConfiguration.avlModel"
						   (modelChange)="avlModelChanged()">
	  </app-row-with-select>

	  <div class="field-row">
		<div class="field-row-title">Serial Number</div>
		<div class="field-row-element">
		  <mat-form-field class="avl-select" appearance="outline" color="accent">
			<!--[appUniqueValue]="vehicleValues('lmuId')"-->
			<input matInput
				   autocomplete="off"
				   [(ngModel)]="model.lmuId"
				   [disabled]="!model.hardwareConfiguration.avlModel">
		  </mat-form-field>
		</div>
	  </div>

	  <ng-container *ngIf="model.hardwareConfiguration.avlModel">
		  <div>
			  <ng-container *ngFor="let equipment of equipments; let i = index">
				  <ng-container
						  *ngIf="equipment.type !== SensorType.DIGITAL_GRANULAR_SPREADER && equipment.type !== SensorType.DIGITAL_LIQUID_SPREADER">
					  <app-equipment [configuration]="data.configuration"
									 [equipment]="equipment"
									 [avlModel]="model.hardwareConfiguration.avlModel"
									 [allInputs]="allInputs"
									 [excludedInputs]="usedInputs"
									 (equipmentDeleted)="onEquipmentDeleted(i)"
									 (equipmentTypeChanged)="onEquipmentTypeChanged()"
									 (inputsChanged)="onInputsChanged()"
					  ></app-equipment>
				  </ng-container>
			  </ng-container>

			  <button
					  *ngIf="canAddEquipment()"
					  (click)="addEquipment()"
					  [disabled]="!model.hardwareConfiguration.avlModel || !areEquipmentsValid()"
					  mat-stroked-button
					  color="accent"
			  >
				  Add Equipment
			  </button>
		  </div>

          </ng-container>

          <ng-container>
            <app-row-with-toggle primaryText="Digital Spreader Integration"
								 [(checked)]="digitalSpreaderCheckbox"
                                 (checkedChange)="onDigitalSpreaderCheckboxChange()"
            ></app-row-with-toggle>

            <ng-container *ngIf="digitalSpreaderCheckbox">
              <app-digital-spreader
                (digitalSpreaderChanged)="onDigitalSpreaderChange($event)"
                [configuration]="data.configuration"
                [hardwareConfiguration]="model.hardwareConfiguration"
                class="w-100"
              ></app-digital-spreader>
            </ng-container>
          </ng-container>

        </div>
    </div>

	@if (saveError) {
		<mat-error>
			{{saveError}}
		</mat-error>
	}

    <app-ui-blocking-layer *ngIf="isWorking"/>

    <mat-progress-bar mode="indeterminate" *ngIf="isWorking"
                      style="position: absolute;bottom: 0;left: 0;right: 0"></mat-progress-bar>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close [attr.cdkFocusInitial]="data.isImported === true ? 'true' : null"
            [disabled]="isWorking">
        Cancel
    </button>
    <button mat-flat-button color="primary"
            (click)="save()"
            [disabled]="isWorking || !canBeSaved()">
        Update
    </button>
</mat-dialog-actions>
