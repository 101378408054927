import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MessageModel} from '../../../../models/message.model';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrl: './message.component.scss'
})
export class MessageComponent {

  @Input() message: MessageModel;
  @Output() messageClosed = new EventEmitter();
  @Output() messageDeleted = new EventEmitter<MessageModel>();

  allFilters = ['All', 'Read', 'Unread'];
  recipientFilter = 'All';

  changeFilter(filter: string) {
    this.recipientFilter = filter;
  }

  closeMessage() {
    this.messageClosed.emit();
  }

  deleteMessage() {
    this.messageDeleted.emit(this.message);
  }

  getCreatedAt(): Date {
    return new Date(this.message.createdAt);
  }

  seenMessagesCount(message: MessageModel) {
    if (!message.messageDeliveries.length) {
      return 0;
    }

    return message.messageDeliveries.filter((delivery) => delivery.seenAt !== null).length;
  }
}
