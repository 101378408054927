import {Injectable} from '@angular/core';
import {RouteAssignment, VehicleRouteAssignmentStatus} from '../../shared/models/route-assignment';
import {ReplaySubject, Subscription} from 'rxjs';
import {ListWithUpdates} from '../../shared/models/list-with-updates.class';
import {ServicesSocketService} from '../websocket/services-socket.service';
import {
  MessageSource,
  RouteAssignmentEventType,
  VehicleRouteAssignmentStatusEventType,
  WebSocketEvent
} from '../websocket/model/message.event';

@Injectable({
  providedIn: 'root'
})
export class RouteAssignmentManagerService {

  private isInitialized = false;
  private readonly vehiclesMap = new Map<number, VehicleRouteAssignmentStatus>();
  private routeAssignments: RouteAssignment[] = [];
  readonly vehicleRouteAssignmentStatus$ = new ReplaySubject<ListWithUpdates<VehicleRouteAssignmentStatus>>(1);
  readonly recentRouteAssignments$ = new ReplaySubject<ListWithUpdates<RouteAssignment>>(1);
  statusEventSubscription: Subscription;
  assignmentsUpdateSubscription: Subscription;

  constructor(
      private servicesSocketService: ServicesSocketService,
  ) { }

  public init(
      recentAssignments: RouteAssignment[],
      vehiclesRouteAssignmentStatus: VehicleRouteAssignmentStatus[]
  ) {
    if (this.isInitialized) {
      throw Error('The RouteAssignmentManagerService has already been initialized.');
    }

    // initial setting of vehicle status
    vehiclesRouteAssignmentStatus.forEach(status => {
      this.vehiclesMap.set(
          status.vehicleId,
          status,
      );
    });
    this.notifyStatusChanged(
        new ListWithUpdates<VehicleRouteAssignmentStatus>(
            [...this.vehiclesMap.values()],
        )
    );

    // listening on websocket for updates of vehicle status
    this.statusEventSubscription = this.servicesSocketService.onMessage(MessageSource.VEHICLE_STATUS)
        .subscribe((e: WebSocketEvent<VehicleRouteAssignmentStatusEventType, VehicleRouteAssignmentStatus>) => {
          if (e.eventType === VehicleRouteAssignmentStatusEventType.UPDATE) {
            this.vehiclesMap.set(
                e.data.vehicleId,
                e.data,
            );
            this.notifyStatusChanged(
                new ListWithUpdates<VehicleRouteAssignmentStatus>(
                    [...this.vehiclesMap.values()],
                    [],
                    [],
                    [e.data]
                )
            );

            if (!!e.data.routeAssignment) {
              const assignmentIndex = this.routeAssignments.findIndex(assignment => {
                return assignment.id === e.data.routeAssignment.id;
              });
              if (assignmentIndex >= 0) {
                this.routeAssignments[assignmentIndex] = e.data.routeAssignment;
                this.notifyAssignmentsChanged(
                    new ListWithUpdates<RouteAssignment>(
                        this.routeAssignments,
                        [],
                        [],
                        [e.data.routeAssignment],
                    )
                );
              }
            }
          }
        });

    // initial setting of route assignments
    this.routeAssignments = recentAssignments;
    this.notifyAssignmentsChanged(
        new ListWithUpdates<RouteAssignment>(
            this.routeAssignments,
        )
    );

    // listening on websocket for updates of assignment queues
    this.assignmentsUpdateSubscription = this.servicesSocketService.onMessage(MessageSource.ROUTE_ASSIGNMENT)
        .subscribe((e: WebSocketEvent<RouteAssignmentEventType, RouteAssignment[]>) => {
          if (e.eventType === RouteAssignmentEventType.UPDATE) {
            const removed = [];
            const updated = [];
            const added = [];
            e.data.forEach(assignment => {
              const foundIndex = this.routeAssignments.findIndex(a => a.id === assignment.id);
              if (!!assignment.deleted) {
                if (foundIndex >= 0) {
                  this.routeAssignments.splice(foundIndex, 1);
                }
                removed.push(assignment);
              } else {
                if (foundIndex >= 0) {
                  this.routeAssignments[foundIndex] = assignment;
                  updated.push(assignment);
                } else {
                  this.routeAssignments.push(assignment);
                  added.push(assignment);
                }
              }
            });
            this.notifyAssignmentsChanged(
                new ListWithUpdates<RouteAssignment>(
                    this.routeAssignments,
                    added,
                    removed,
                    updated,
                )
            );
          }
        });

    this.isInitialized = true;
  }

  public release() {
    this.vehiclesMap.clear();
    this.statusEventSubscription?.unsubscribe();
    this.assignmentsUpdateSubscription?.unsubscribe();
    this.isInitialized = false;
  }

  public getAssignmentsForRoute(routeConfigId: number, routeId: number): RouteAssignment[] {
    return this.routeAssignments.filter(assignment => assignment.configId === routeConfigId && assignment.routeId === routeId);
  }

  // there is no queued route with the same ID for vehicle and shift
  public canRouteBeAssigned(vehicleId: number, shiftId: number, routeConfigId: number, routeId: number): boolean {
    return this.routeAssignments.filter(assignment => {
      return assignment.configId === routeConfigId &&
          assignment.routeId === routeId &&
          assignment.shiftId === shiftId &&
          assignment.vehicleId === vehicleId &&
          !assignment.deleted &&
          !assignment.completed;
    }).length === 0;
  }

  private notifyStatusChanged(statusUpdate: ListWithUpdates<VehicleRouteAssignmentStatus>) {
    this.vehicleRouteAssignmentStatus$.next(statusUpdate);
  }

  private notifyAssignmentsChanged(assignmentsUpdate: ListWithUpdates<RouteAssignment>) {
    this.recentRouteAssignments$.next(assignmentsUpdate);
  }
}
