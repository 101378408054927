import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {inOutAnimation} from 'src/app/shared/animations/animations';
import {ConfigurationModel} from '../../../shared/models/configuration.model';

@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.scss'],
  animations: [inOutAnimation],
})
export class InsightsComponent implements OnInit {
  configuration: ConfigurationModel;
  public collapseMenu = false;
  public currentRoute?: any;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    this.currentRoute = this.route.firstChild?.routeConfig.path;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = this.route.firstChild?.routeConfig.path;
      }
    });
  }
}
