import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {SettingsService} from '../../../../configuration/settings.service';
import {MapLayersManager} from '../map-layers-manager';
import {ReplaySubject, Subscription, throwError, timer} from 'rxjs';
import {LngLat, RasterLayerSpecification, RasterSourceSpecification} from 'maplibre-gl';
import {catchError, delay, delayWhen, repeatWhen, retryWhen, take} from 'rxjs/operators';
import {HttpErrorHandler} from '../../../../http.error.handler';
import {FeatureCollection, GeoJSON} from 'geojson';
import {ConfigurationModel} from '../../../models/configuration.model';
import {GridInfo} from '../../navigation/weather-news/weather-news.component';
import {ToastService} from '../../../services/toast.service';
import {environment} from '../../../../../environments/environment';

export interface WeatherPointsFeature {
  id: string;
  type: string;
  geometry: any;
  properties: WeatherPointProperties;
}

interface WeatherPointProperties {
  cwa: string;
  forecastOffice: string;
  gridId: string;
  gridX: number;
  gridY: number;
  forecast: string;
  forecastHourly: string;
  forecastGridData: string;
  observationStations: string;
  relativeLocation: GeoJSON;
  forecastZone: string;
  county: string;
  fireWeatherZone: string;
  timeZone: string;
  radarStation: string;
}

export interface WeatherStationsFeatureCollection {
  id: string;
  type: string;
  features: WeatherStationFeature[];
  observationStations: string[];
  pagination: any;
}

interface WeatherStationFeature {
  id: string;
  type: string;
  geometry: any;
  properties: WeatherStationProperties;
}

interface WeatherStationProperties {
  elevation: any; // unitAmount
  stationIdentifier: string;
  name: string;
  timeZone: string;
  forecast: string;
  county: string;
  fireWeatherZone: string;
}

export interface WeatherObservationsFeatureCollection {
  type: string;
  features: WeatherObservationFeature[];
  observationStations: string[];
}

interface WeatherObservationFeature {
  id: string;
  type: string;
  geometry: any;
  properties: WeatherObservationProperties;
}

interface WeatherObservationProperties {
  elevation: any; // unitAmount
  station: string;
  timestamp: string;
  rawMessage: string;
  textDescription: string;
  icon: string;
  presentWeather: string[];
  temperature: any; // unitAmount with qualityControl: string
  dewpoint: any; // unitAmount with qualityControl: string
  windDirection: any; // unitAmount with qualityControl: string
  windSpeed: any; // unitAmount with qualityControl: string
  windGust: any; // unitAmount with qualityControl: string
  barometricPressure: any; // unitAmount with qualityControl: string
  seaLevelPressure: any; // unitAmount with qualityControl: string
  visibility: any; // unitAmount with qualityControl: string
  maxTemperatureLast24Hours: any; // unitAmount
  minTemperatureLast24Hours: any; // unitAmount
  precipitationLastHour: any; // unitAmount with qualityControl: string
  precipitationLast3Hours: any; // unitAmount with qualityControl: string
  precipitationLast6Hours: any; // unitAmount with qualityControl: string
  relativeHumidity: any; // unitAmount with qualityControl: string
  windChill: any; // unitAmount with qualityControl: string
  heatIndex: any; // unitAmount with qualityControl: string
  cloudLayers: any[]; // base unitAmount
}

export interface WeatherForecastFeature {
  type: string;
  geometry: any;
  properties: WeatherForecastProperties;
}

interface WeatherForecastProperties {
  updated: Date;
  units: string;
  forecastGenerator: string;
  generatedAt: Date;
  updateTime: Date;
  validTimes: Date;
  elevation: any;
  periods: WeatherForecast[];
}

export interface WeatherForecast {
  number: number;
  name: string;
  startTime: Date;
  endTime: Date;
  isDaytime: boolean;
  temperature: number;
  temperatureUnit: string;
  temperatureTrend: any;
  probabilityOfPrecipitation: any; // unitAmount, pct
  dewpoint: any; // unitAmount
  relativeHumidity: any; // unitAmount, pct
  windSpeed: string;
  windDirection: string;
  icon: string;
  shortForecast: string;
  detailedForecast: string;
}

export interface AlertGeoJson {
  id: string;
  type: string;
  geometry: any;
  properties: AlertGeoJsonProperties;
}

interface AlertGeoJsonProperties {
  id: string;
  event: string;
  sent: Date;
  effective: Date;
  expires: Date;
  ends: Date;
  messageType: string;
  senderName: string;
  headline: string;
  description: string;
  instruction: string;
  areaDesc: string;
}

export enum WeatherUnit {
  SI = 'si',
  US = 'us',
}


@Injectable({
  providedIn: 'root'
})
export class WeatherWarningsLayerService {

  static readonly WARNINGS_LAYER_ID = 'weather-warnings';
  static readonly WARNINGS_SOURCE_ID = 'weather-warnings-source';
  static readonly WARNINGS_SERVICE_URL = 'https://nowcoast.noaa.gov/geoserver/alerts/wms';
  static readonly WARNINGS_SERVICE_RASTER_URL = `${WeatherWarningsLayerService.WARNINGS_SERVICE_URL}?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng8&TRANSPARENT=true&LAYERS=watches_warnings_advisories&STYLES=&CRS=EPSG%3A3857&WIDTH=256&HEIGHT=256&BBOX={bbox-epsg-3857}`;
  private mapLayersManager: MapLayersManager;
  private isEnabled: boolean;
  private shouldBeVisible: boolean;
  settingsSubscription: Subscription;
  readonly refreshInterval = 600; // 10 minutes
  refreshTimer = null;

  lngLat: LngLat;
  configuration: ConfigurationModel;
  debugLevel = false;
  readonly weatherLocation$ = new ReplaySubject<LngLat>(1);
  readonly gridInfo$ = new ReplaySubject<GridInfo>(1);

  constructor(
      private http: HttpClient,
      private settingsService: SettingsService,
      private toast: ToastService,
  ) { }

  init(mapLayersManager: MapLayersManager, isEnabled: boolean, configuration: ConfigurationModel) {
    if (!!this.mapLayersManager) {
      throw Error('The map layers manager has already been set.');
    }
    this.mapLayersManager = mapLayersManager;
    this.isEnabled = isEnabled;
    this.configuration = configuration;
    this.addSourceAndLayer();

    if (this.isEnabled) {
      this.connectToManager();
    }

    if (environment.name !== 'local') {
      // initial load of weather location
      const locationFromSettings = this.settingsService.getStringValue(SettingsService.WEATHER_LOCATION)
          ?.split(',')
          ?.map(value => +value);
      this.lngLat = !!locationFromSettings && locationFromSettings.length === 2
          ? new LngLat(locationFromSettings[0], locationFromSettings[1])
          : new LngLat(this.configuration.weatherLocation.x, this.configuration.weatherLocation.y);
      if (this.debugLevel) {
        console.log('Using these coordinates:');
        console.log(this.lngLat);
      }
      this.weatherLocation$.next(this.lngLat);
      this.updateGridInfo();

      // update weather location based on settings change
      this.settingsSubscription = this.settingsService.settingsChangedObservable.subscribe(settingsPair => {
        if (settingsPair.key === SettingsService.WEATHER_LOCATION) {
          if (this.debugLevel) {
            console.log('Weather Location setting changed: ');
            console.log(settingsPair.value);
          }
          let lngLat;
          if (!!settingsPair.value && settingsPair.value !== 'null') {
            const lngLatFromSettings = settingsPair.value
                .split(',')
                .map(value => +value);
            lngLat = new LngLat(lngLatFromSettings[0], lngLatFromSettings[1]);
          } else {
            lngLat = new LngLat(this.configuration.weatherLocation.x, this.configuration.weatherLocation.y);
          }
          if (this.lngLat.lat !== lngLat.lat || this.lngLat.lng !== lngLat.lng) {
            this.lngLat = lngLat;
            if (this.debugLevel) {
              console.log('Using these coordinates:');
              console.log(this.lngLat);
            }
            this.weatherLocation$.next(this.lngLat);
            this.updateGridInfo();
          }
        }
      });
    }
  }

  release() {
    this.settingsSubscription?.unsubscribe();
    if (!this.mapLayersManager) {
      throw Error('The map has not been set!');
    }
    this.cancelRefresh();
    this.mapLayersManager = null;
    this.configuration = null;
  }

  addSourceAndLayer(resetRefreshTimer: boolean = false) {
    if (this.isEnabled) {
      this.shouldBeVisible = this.settingsService.getBooleanValue(
          SettingsService.WEATHER_WARNINGS_LAYER_KEY
      );

      const source = {
        type: 'raster',
        tiles: [WeatherWarningsLayerService.WARNINGS_SERVICE_RASTER_URL],
        tileSize: 256,
      } as RasterSourceSpecification;

      this.mapLayersManager.addSource(WeatherWarningsLayerService.WARNINGS_SOURCE_ID, source);

      const layer = {
        id: WeatherWarningsLayerService.WARNINGS_LAYER_ID,
        source: WeatherWarningsLayerService.WARNINGS_SOURCE_ID,
        type: 'raster',
        layout: {
          visibility: this.shouldBeVisible ? 'visible' : 'none',
        },
        paint: {
          'raster-opacity': 0.5,
        },
      } as RasterLayerSpecification;
      this.mapLayersManager.addLayer(layer);

      if (resetRefreshTimer) {
        this.resetTimer(this.shouldBeVisible);
      }
    }
  }

  private updateSource() {
    const source = this.mapLayersManager?.getRasterSource(WeatherWarningsLayerService.WARNINGS_SOURCE_ID);
    if (!!source) {
      source.setTiles([WeatherWarningsLayerService.WARNINGS_SOURCE_ID]);
    }
  }

  /*
    WMS GetFeatureInfo returns FeatureCollection

    Example:
    {
      "type": "FeatureCollection",
      "features": [
        {
          "type": "Feature",
          "id": "watches_warnings_advisories.fid-3e2069e0_18a17529a53_1129",
          "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
              ...
            ]
          },
          "geometry_name": "wkb_geometry",
          "properties": {
            "ogc_fid": 8554613,
            "cap_id": "urn:oid:2.49.0.1.840.0.619f25851780e1bbb9208b1f39f3fedb68321de9.001.1",
            "vtec": null,
            "phenom": null,
            "sig": null,
            "wfo": null,
            "event": null,
            "url": "https://alerts-v2.weather.gov/#/?id=urn:oid:2.49.0.1.840.0.619f25851780e1bbb9208b1f39f3fedb68321de9.001.1",
            "msg_type": "AQA",
            "prod_type": "Air Quality Alert",
            "render_order": 6,
            "issuance": "2023-08-20T22:10:00Z",
            "expiration": "2023-08-21T22:00:00Z",
            "onset": "2023-08-20T22:10:00Z",
            "ends": null
          }
        }
      ],
      "totalFeatures": "unknown",
      "numberReturned": 1,
      "timeStamp": "2023-08-21T09:00:33.689Z",
      "crs": {
        "type": "name",
        "properties": {
          "name": "urn:ogc:def:crs:EPSG::3857"
        }
      }
    }
  */
  getWmsFeatures(lnglat: LngLat): Promise<FeatureCollection> {
    const bboxControl = 0.0001;

    const x = (lnglat.lng * 20037508.34) / 180;
    let y =
        Math.log(Math.tan(((90 + lnglat.lat) * Math.PI) / 360)) /
        (Math.PI / 180);
    y = (y * 20037508.34) / 180;

    const bbox = (x - bboxControl) + ',' +
        (y - bboxControl) + ',' +
        (x + bboxControl) + ',' +
        (y + bboxControl);

    const params = new URLSearchParams({
      service: 'wms',
      version: '1.3.0',
      request: 'GetFeatureInfo',
      format: 'image/png8',
      transparent: 'true',
      query_layers: 'watches_warnings_advisories',
      layers: 'watches_warnings_advisories',
      styles: '',
      time: new Date().toISOString(),
      info_format: 'application/json',
      feature_count: '1',
      i: '50',
      j: '50',
      crs: 'EPSG:3857',
      width: '101',
      height: '101',
      bbox,
    });

    return new Promise<FeatureCollection>((resolve, reject) => {
      fetch(`${WeatherWarningsLayerService.WARNINGS_SERVICE_URL}?${params.toString()}`)
          .then(response => response.json())
          .then(data => resolve(data as FeatureCollection))
          .catch(error => reject(error));
    });
  }

  getAlert(oid: string) {
    return this.http.get<AlertGeoJson>(`https://api.weather.gov/alerts/${oid}`)
        .pipe(
            catchError(HttpErrorHandler.handleError) // then handle the error
        );
  }

  getWeatherPoints(lnglat: LngLat) {
    return this.http.get<WeatherPointsFeature>(`https://api.weather.gov/points/${lnglat.lat},${lnglat.lng}`)
        .pipe(
            catchError(HttpErrorHandler.handleError) // then handle the error
        );
  }

  getWeatherStations(cwa: string, gridX: number, gridY: number) {
    return this.http.get<WeatherStationsFeatureCollection>(`https://api.weather.gov/gridpoints/${cwa}/${gridX},${gridY}/stations`)
        .pipe(
            catchError(HttpErrorHandler.handleError) // then handle the error
        );
  }

  getWeatherStationObservations(stationId: string) {
    return this.http.get<WeatherObservationsFeatureCollection>(`https://api.weather.gov/stations/${stationId}/observations`)
        .pipe(
            catchError(HttpErrorHandler.handleError) // then handle the error
        );
  }

  getWeatherForecastHourly(cwa: string, gridX: number, gridY: number, unit: WeatherUnit = WeatherUnit.US) {
    return this.http.get<WeatherForecastFeature>(`https://api.weather.gov/gridpoints/${cwa}/${gridX},${gridY}/forecast/hourly?units=${unit}`)
        .pipe(
            repeatWhen(delay(500)),
            take(1),
            retryWhen(delayWhen((err, i) => i < 5 ? timer(500) : throwError(err))),
            catchError(HttpErrorHandler.handleError) // then handle the error
        );
  }

  // retry 5 times with 500ms delay
  /*
      When we will upgrade to RxJS 7.x, let's use:
        repeat({ delay: 500 }),
        skipWhile((res) => res === null),
        take(1),
        retry({ count: 5, delay: 500 })
   */
  getWeatherForecast(cwa: string, gridX: number, gridY: number, unit: WeatherUnit = WeatherUnit.US) {
    return this.http.get<WeatherForecastFeature>(`https://api.weather.gov/gridpoints/${cwa}/${gridX},${gridY}/forecast?units=${unit}`)
        .pipe(
            repeatWhen(delay(500)),
            take(1),
            retryWhen(delayWhen((err, i) => i < 5 ? timer(500) : throwError(err))),
            catchError(HttpErrorHandler.handleError) // then handle the error
        );
  }

  updateGridInfo() {
    if (!!this.lngLat) {
      this.getWeatherPoints(this.lngLat).toPromise().then(pointsResponse => {
        if (this.debugLevel) {
          console.log('Grid Location (Points endpoint): ');
          console.log(pointsResponse);
        }
        const pointProperties = pointsResponse.properties;
        const gridInfo = {
          cwa: pointProperties.cwa,
          gridX: pointProperties.gridX,
          gridY: pointProperties.gridY,
        };
        this.gridInfo$.next(gridInfo);
      }).catch(error => {
        console.log(error);
        this.toast.long('Loading Weather Points failed!');
      });
    }
  }

  isVisible(): boolean {
    return this.isEnabled && this.shouldBeVisible;
  }

  private handleLayerVisibilityChange(makeVisible: boolean) {
    this.shouldBeVisible = makeVisible;
    this.mapLayersManager.setLayerVisibility(WeatherWarningsLayerService.WARNINGS_LAYER_ID, makeVisible);
    this.resetTimer(makeVisible);
  }

  private resetTimer(setTimer: boolean) {
    if (setTimer) {
      this.scheduleRefresh();
    } else {
      this.cancelRefresh();
    }
  }

  private refreshHandler() {
    this.updateSource();
    this.scheduleRefresh();
  }

  private scheduleRefresh() {
    const that = this;
    if (!!this.mapLayersManager) {
      this.refreshTimer = setTimeout(() => {
        that.refreshHandler();
      }, that.refreshInterval * 1000);
    }
  }

  private cancelRefresh() {
    if (!!this.refreshTimer) {
      clearTimeout(this.refreshTimer);
      this.refreshTimer = null;
    }
  }

  private connectToManager() {
    const that = this;

    this.settingsSubscription = this.settingsService.settingsChangedObservable.subscribe({
      next(newSettings) {
        if (newSettings.key === SettingsService.WEATHER_WARNINGS_LAYER_KEY) {
          that.handleLayerVisibilityChange(newSettings.value);
        }
      }
    });
  }
}
