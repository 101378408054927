import {Component, Input} from '@angular/core';
import {CdkAccordion, CdkAccordionItem} from '@angular/cdk/accordion';
import {MatIcon} from '@angular/material/icon';
import {animate, AUTO_STYLE, state, style, transition, trigger} from '@angular/animations';

const DEFAULT_DURATION = 300;

@Component({
  selector: 'app-expansion-panel',
  standalone: true,
  imports: [
    CdkAccordion,
    CdkAccordionItem,
    MatIcon
  ],
  templateUrl: './expansion-panel.component.html',
  styleUrl: './expansion-panel.component.scss',
  animations: [
    trigger('collapse', [
      state('true', style({ height: AUTO_STYLE, opacity: AUTO_STYLE })),
      state('false', style({ height: 0, opacity: 0 })),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-out')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out'))
    ]),
    trigger('rotate', [
      state('true', style({ scale: 1 })),
      state('false', style({ scale: -1 })),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out'))
    ])
  ]
})
export class ExpansionPanelComponent {

  @Input()
  panelTitle = ''

  @Input()
  panelDescription = ''

  @Input() expanded = false;

}
