import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ImageModel } from '../../models/image';
import { SecurityService } from '../../../security/security.service';
import { ImagesManagerService } from '../../../data/images/images-manager.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { ShiftMapDataService } from '../../../pages/shift-map/services/shift-map-data.service';
import { HighlightedItemSource } from '../../models/highlighted-item-update';
import { HttpClient } from '@angular/common/http';
import { HttpErrorHandler } from '../../../http.error.handler';
import { catchError, retry } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit, OnChanges, OnDestroy {

  @Input() images: ImageModel[];
  @Input() isLiveMap = true;

  imageData: any;
  loadingImage = false;
  imageError = false;

  selectedItem: ImageModel = null;
  selectedItemIndex: number = null;
  positionString: string;

  highlightedImageSubscription: Subscription;

  constructor(
      private securityService: SecurityService,
      private imageManager: ImagesManagerService,
      private shiftMapManager: ShiftMapDataService,
      private http: HttpClient,
      private domSanitizer: DomSanitizer,
  ) {
  }

  ngOnInit() {
    if (this.selectedItem === null && !!this.images && this.images.length > 0) {
      this.selectedItemIndex = 0;
      this.updatePosition();
      this.selectedItem = this.images[this.selectedItemIndex];
    }
    this.loadImage();

    if (this.isLiveMap) {
      this.highlightedImageSubscription = this.imageManager.highlightedImage$.subscribe(imageUpdate => {
        if (!!imageUpdate.itemId && imageUpdate.source === HighlightedItemSource.MAP) {
          this.selectedItemIndex = this.images.findIndex(image => image.id === imageUpdate.itemId);
          this.updatePosition();
          this.selectedItem = this.images[this.selectedItemIndex];
          this.loadImage();
        }
      });
    } else {
      this.highlightedImageSubscription = this.shiftMapManager.highlightedImage$.subscribe(highlightedImageUpdate => {
        if (!!highlightedImageUpdate.itemId && highlightedImageUpdate.source === HighlightedItemSource.MAP) {
          this.selectedItemIndex = this.images.findIndex(image => image.id === highlightedImageUpdate.itemId);
          this.updatePosition();
          this.selectedItem = this.images[this.selectedItemIndex];
          this.loadImage();
        }
      });
    }
  }

  ngOnDestroy() {
    this.highlightedImageSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    // if already initialized
    this.updatePosition();
  }

  private loadImage() {
    this.imageError = false;
    this.loadingImage = true;
    this.imageData = undefined;
    // I cannot use authorization as request param because of authorization lambda response caching
    // this.imageData = this.selectedItem.imageUrl + `?Authorization=${this.token}`;
    firstValueFrom(this.http.get(
        this.selectedItem.imageUrl,
        {responseType: 'blob'},
      )
        .pipe(
          retry(0),
          catchError(HttpErrorHandler.handleError) // then handle the error
        )
    ).then(response => {
      this.imageData = this.domSanitizer.bypassSecurityTrustResourceUrl(
        URL.createObjectURL(response)
      );
    }).catch(error => {
      this.onImageError();
    });
  }

  onImageError() {
    // console.log('on error');
    this.imageError = true;
    this.loadingImage = false;
  }

  onImageLoad() {
    // console.log('on load');
    this.imageError = false;
    this.loadingImage = false;
  }

  previousImage() {
    const newIndex = this.selectedItemIndex - 1;
    if (newIndex < 0) {
      this.selectedItemIndex = this.images.length - 1;
    } else {
      this.selectedItemIndex = newIndex;
    }
    this.updatePosition();
    this.selectedItem = this.images[this.selectedItemIndex];
    this.highlightImageOnMap(this.selectedItem.id);
    this.loadImage();
  }

  nextImage() {
    const newIndex = this.selectedItemIndex + 1;
    if (newIndex > this.images.length - 1) {
      this.selectedItemIndex = 0;
    } else {
      this.selectedItemIndex = newIndex;
    }
    this.updatePosition();
    this.selectedItem = this.images[this.selectedItemIndex];
    this.highlightImageOnMap(this.selectedItem.id);
    this.loadImage();
  }

  private updatePosition() {
    this.positionString = `${this.selectedItemIndex + 1} / ${this.images.length}`;
  }

  private highlightImageOnMap(imageId: number) {
    if (this.isLiveMap) {
      this.imageManager.highlightImage(imageId, HighlightedItemSource.PANEL);
    } else {
      this.shiftMapManager.sendHighlightedImageUpdate(imageId, HighlightedItemSource.PANEL);
    }
  }
}
