import {Component, OnInit} from '@angular/core';
import {CreateObservationGroup, ObservationTypeGroup} from '../../../../shared/models/observation-group';
import {MatDialog} from '@angular/material/dialog';
import {ObservationManagementService} from '../../../../data/observations/observation-management.service';
import {
  CreateObservationGroupDialogComponent
} from './dialogs/create-observation-group-dialog/create-observation-group-dialog.component';
import {
  UpdateObservationGroupDialogComponent
} from './dialogs/update-observation-group-dialog/update-observation-group-dialog.component';
import {
  DeleteObservationGroupDialogComponent
} from './dialogs/delete-observation-group-dialog/delete-observation-group-dialog.component';
import {
  CreateObservationTypeDialogComponent
} from './observation-group/dialogs/create-observation-type-dialog/create-observation-type-dialog.component';
import {ToastService} from '../../../../shared/services/toast.service';
import {ObservationType} from '../../../../shared/models/observation-type';
import {
  UpdateObservationTypeDialogComponent
} from './observation-group/dialogs/update-observation-type-dialog/update-observation-type-dialog.component';
import {MapStyles} from '../../../../configuration/map-styles';
import {firstValueFrom} from 'rxjs';
import {
  ConfirmationDialogComponent
} from '../../../../shared/components/dialogs/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-manage-observations',
  templateUrl: './manage-observations.component.html',
  styleUrls: ['./manage-observations.component.scss', '../../settings-common.scss']
})
export class ManageObservationsComponent implements OnInit {

  observationTypeGroups: ObservationTypeGroup[] = [];

  isLoading = true;

  displayedColumns = ['title', 'abbreviation', 'operations'];

  constructor(
    public dialog: MatDialog,
    private observationManagementService: ObservationManagementService,
    private toastService: ToastService,
  ) {
  }

  ngOnInit() {
    this.isLoading = true;
    firstValueFrom(this.observationManagementService.getObservationTypeGroups()).then(result => {
      this.observationTypeGroups = result.data;
      this.observationTypeGroups.sort(ObservationTypeGroup.observationTypeGroupCompareFn);
      this.isLoading = false;
    }).catch(error => {
      const msg = 'Error while loading data from server';
      this.toastService.long(msg);
      console.error(`${msg} :: ${error}`);
      this.isLoading = false;
    });
  }

  addObservationTypeGroupDialog(): void {
    const dialogRef = this.dialog.open(CreateObservationGroupDialogComponent, {
      width: '450px',
      data: {
        name: '',
        duration: 0,
        isAudible: false,
        mapColor: null,
        defaultMapColor: MapStyles.LIVE_COLOR,
      },
    });

    dialogRef.afterClosed().subscribe((data: CreateObservationGroup) => {
      if (!!data) {
        this.observationManagementService.createObservationTypeGroup(data)
          .then(result => {
            const withVehicleGroups = new ObservationTypeGroup(
              result.data.id,
              result.data.name,
              result.data.durationInSeconds,
              [],
              false,
              [],
              result.data.mapColor,
              result.data.isAudible,
            );
            this.observationTypeGroups.push(withVehicleGroups);
            this.observationTypeGroups.sort(ObservationTypeGroup.observationTypeGroupCompareFn);
            this.toastService.short('Observation type group has been created');
          }).catch(error => {
          const msg = 'Error while adding observation type group';
          this.toastService.long(msg);
          console.error(`${msg} :: ${error}`);
        });
      }
    });
  }

  addObservationTypeDialog(observationTypeGroup: ObservationTypeGroup): void {
    const dialogRef = this.dialog.open(CreateObservationTypeDialogComponent, {
      width: '450px',
      data: {title: '', abbreviation: ''}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!!result) {
        const observationTypeData = {
          title: result.title,
          abbreviation: result.abbreviation
        };
        this.observationManagementService.createObservationType(observationTypeGroup.id, observationTypeData)
          .then(response => {
            observationTypeGroup.observationTypes.push(response.data);
            observationTypeGroup.observationTypes = [...observationTypeGroup.observationTypes];
            this.toastService.short('Observation type has been created');
          })
          .catch(error => {
            const msg = 'Error while adding observation type';
            this.toastService.long(msg);
            console.error(`${msg} :: ${error}`);
          });
      }
    });
  }

  editObservationTypeGroup(edited: ObservationTypeGroup) {
    const dialogRef = this.dialog.open(UpdateObservationGroupDialogComponent, {
      width: '450px',
      data: {
        name: edited.name,
        duration: edited.durationInSeconds / 60 / 60,
        isAudible: edited.isAudible,
        mapColor: edited.mapColor,
        defaultMapColor: MapStyles.LIVE_COLOR,
      },
    });

    dialogRef.afterClosed().subscribe((data: any) => {
      if (data === 'delete') {
        this.deleteObservationTypeGroup(edited);
        return;
      }
      if (!!data) {
        this.observationManagementService.editObservationTypeGroup(data, edited.id).then(result => {
          edited.name = result.data.name;
          edited.durationInSeconds = result.data.durationInSeconds;
          edited.isAudible = result.data.isAudible;
          edited.mapColor = result.data.mapColor;
          this.observationTypeGroups.sort(ObservationTypeGroup.observationTypeGroupCompareFn);
          this.toastService.short('Observation type group has been updated');
        }).catch(error => {
          const msg = 'Error while editing observation type group';
          this.toastService.long(msg);
          console.error(`${msg} :: ${error}`);
        });
      }
    });
  }

  deleteObservationTypeGroup(deleted: ObservationTypeGroup) {
    const indexToDelete = this.observationTypeGroups.indexOf(deleted);

    const dialogRef = this.dialog.open(DeleteObservationGroupDialogComponent, {
      width: '450px',
      data: deleted,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.observationManagementService.deleteObservationTypeGroup(deleted.id).then(() => {
          this.observationTypeGroups.splice(indexToDelete, 1);
          this.toastService.short('Observation type group has been deleted');
        }).catch(error => {
          const msg = 'Error while deleting observation type group';
          this.toastService.long(msg);
          console.error(`${msg} :: ${error}`);
        });
      }
    });
  }

  editObservationType(edited: ObservationType, observationTypeGroup: ObservationTypeGroup): void {
    const dialogRef = this.dialog.open(UpdateObservationTypeDialogComponent, {
      width: '450px',
      data: {title: edited.title, abbreviation: edited.abbreviation}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!!result) {
        const observationTypeData = {
          title: result.title,
          abbreviation: result.abbreviation
        };
        this.observationManagementService.editObservationType(observationTypeGroup.id, edited.id, observationTypeData)
          .then(response => {
            edited.title = response.data.title;
            edited.abbreviation = response.data.abbreviation;
            observationTypeGroup.observationTypes = [...observationTypeGroup.observationTypes];
            this.toastService.short('Observation type has been updated');
          })
          .catch(error => {
            const msg = 'Error while editing observation type';
            this.toastService.long(msg);
            console.error(`${msg} :: ${error}`);
          });
      }
    });
  }

  deleteObservationType(deleted: ObservationType, observationTypeGroup: ObservationTypeGroup): void {
    const indexToDelete = observationTypeGroup.observationTypes.indexOf(deleted);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: {
        text: `Are you sure you want to delete observation type "${deleted.title} (${deleted.abbreviation})"?`
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.observationManagementService.deleteObservationType(observationTypeGroup.id, deleted.id)
          .then(_ => {
            observationTypeGroup.observationTypes.splice(indexToDelete, 1);
            observationTypeGroup.observationTypes = [...observationTypeGroup.observationTypes];
            this.toastService.short('Observation type has been deleted');
          })
          .catch(error => {
            const msg = 'Error while deleting observation type';
            this.toastService.long(msg);
            console.error(`${msg} :: ${error}`);
          });
      }
    });
  }

}
