<div class="list-item">
    <div class="item-text">Make/Model</div>

    <div class="item-text">

        <mat-form-field class="device-model" appearance="outline" color="accent">
            <mat-select [(ngModel)]="digitalSpreader.model"
                        (ngModelChange)="change()"
                        #ngDeviceModel="ngModel"
                        required>
                <mat-option [value]="DeviceModel.SPREADER_CPS_CIRRUS">CPS Cirus</mat-option>
                <mat-option [value]="DeviceModel.SPREADER_CPS_FREEDOM">CPS Freedom</mat-option>
                <mat-option [value]="DeviceModel.SPREADER_FORCE_AMERICA_GENERIC">Force America (generic)</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>

<div *ngIf="!!digitalSpreader.model">


    <app-row-with-toggle primaryText="Read Granular Information"
                         [(checked)]="digitalSpreader.settings.readGranular"
                         (checkedChange)="onReadGranularCheckboxChange()"
    ></app-row-with-toggle>

    <div class="list-item ml-25">
        <div class="item-text">Granular max rate</div>
        <div class="item-text">
            <mat-form-field class="device-rate"
                            appearance="outline"
                            color="accent">
                <input matInput
                       type="number"
                       autocomplete="off"
                       [disabled]="!(!!digitalSpreader.model && digitalSpreader.settings.readGranular)"
                       [(ngModel)]="digitalSpreader.settings.granularMaxRate"
                       (ngModelChange)="change()"
                >
                <span class="units">
                {{ 'granular' | rateUnit: configuration.useMetricSystem }}
            </span>
            </mat-form-field>
        </div>
    </div>

    <div class="list-item ml-25">
        <div class="item-text">Granular blast rate</div>
        <div class="item-text">
            <mat-form-field class="device-rate"
                            appearance="outline"
                            color="accent">
                <input matInput
                       type="number"
                       autocomplete="off"
                       [disabled]="!(!!digitalSpreader.model && digitalSpreader.settings.readGranular)"
                       [(ngModel)]="digitalSpreader.settings.granularBlastRate"
                       (ngModelChange)="change()"
                >
                <span class="units">
                {{ 'granular' | rateUnit: configuration.useMetricSystem }}
            </span>
            </mat-form-field>
        </div>
    </div>

</div>

<div>

    <app-row-with-toggle primaryText="Read Liquid Information"
						 [(checked)]="digitalSpreader.settings.readLiquid"
                         (checkedChange)="onReadLiquidCheckboxChange();change();"
    ></app-row-with-toggle>
    <div class="list-item ml-25">
        <div class="item-text">Liquid max rate</div>
        <div class="item-text">
            <mat-form-field class="device-rate"
                            appearance="outline"
                            color="accent">
                <input matInput
                       type="number"
                       autocomplete="off"
                       [disabled]="!(!!digitalSpreader.model && digitalSpreader.settings.readLiquid)"
                       [(ngModel)]="digitalSpreader.settings.liquidMaxRate"
                       (ngModelChange)="change()"
                >
                <span class="units">
                {{ 'liquid' | rateUnit: configuration.useMetricSystem }}
            </span>
            </mat-form-field>
        </div>
    </div>
</div>

