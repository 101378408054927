<h1 mat-dialog-title>
  Confirmation
</h1>

<mat-dialog-content class="dialog-content">
  <p>
    Are you sure you want to delete observation type group <b>"{{data.name}}"</b> and all its observation types?
  </p>

  <div *ngIf="data.vehicleGroups.length" class="delete-linked-info">
    <p>
      This Observation Type Group is linked to these Vehicle Groups:
    </p>
    <mat-list>
      <mat-list-item  *ngFor="let vehicleGroup of data.vehicleGroups"><mat-icon>tag</mat-icon> {{ vehicleGroup.title }} ({{vehicleGroup.name}})</mat-list-item>
    </mat-list>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-flat-button [mat-dialog-close]="data.name" cdkFocusInitial>Yes</button>
</mat-dialog-actions>
