import {DvirGroup} from './dvir-group';

export class DvirSection {
  id?: number;
  rank?: number;
  title?: string;
  hasRemarks = false;

  groups: DvirGroup[] = [];

  constructor(groups: DvirGroup[]) {
    this.groups = groups;
  }

}
