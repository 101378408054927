<cdk-accordion class="expansion-panel">
	<cdk-accordion-item
    #accordionItem="cdkAccordionItem"
		[expanded]="expanded"
		role="button">

		<div class="panel-header"
			 (click)="accordionItem.toggle()">
			<div class="panel-title">
				<div class="panel-title-text">{{panelTitle}}</div>
				@if (panelDescription) { <div class="panel-description-text">{{panelDescription}}</div> }
			</div>

      <mat-slide-toggle [hideIcon]="true"
                        (change)="handleChange($event)"
                        [checked]="accordionItem.expanded"
                        (click)="$event.stopPropagation()"
      >
      </mat-slide-toggle>
		</div>

		<div role="region"
			 class="panel-content"
			 [@collapse]="accordionItem.expanded">
			<ng-content></ng-content>
		</div>
	</cdk-accordion-item>
</cdk-accordion>
