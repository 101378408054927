import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {from, Observable, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {AuthSession, fetchAuthSession} from 'aws-amplify/auth';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(fetchAuthSession()).pipe(
        switchMap((auth: AuthSession) => {
          if (request.url.startsWith(environment.services.service) ||
              request.url.startsWith(environment.services.location)
          ) {
              const jwt: string = auth.tokens.idToken.toString();
              request = request.clone({
                  setHeaders: {
                      Authorization: `Bearer ${jwt}`,
                  },
              });
          }
          return next.handle(request);
        }),
        catchError((err) => {
          console.error('Error when getting Cognito ID token!');
          return next.handle(request);
        })
    );
  }
}
