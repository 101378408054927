import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {ConfigurationService} from '../../../../../configuration/configuration.service';
import {ConfigurationModel, FeatureFlagEnum} from '../../../../../shared/models/configuration.model';
import {CreateVehicleComponent} from '../edit-vehicle/create-vehicle.component';
import {ImportVehiclesComponent} from '../import-vehicles.component';
import {ImportVehiclesCartegraphComponent} from '../vehicle-import-cartegraph/import-vehicles-cartegraph.component';
import {AddOrImportVehicleDialogForm, EditVehicleComponentInputData} from '../edit-vehicle/vehicle.types';

@Component({
  selector: 'app-add-or-import-vehicle-dialog',
  templateUrl: './add-or-import-vehicle-dialog.component.html',
})
export class AddOrImportVehicleDialogComponent implements OnInit, OnDestroy {

  private configurationSubscription: Subscription;
  private isInitialized = false;
  isWorking = false;
  saveError?: string;

  /**
   * A nullable flag telling whether the current account has the `FeatureFlagEnum.CartegraphIntegration`
   * feature flag.
   * It is set to `null` when the flag presence is unknown.
   * It is set to `true` if the flag is present.
   * It is set to `false` when the flag is missing.
   */
  cartegraphEnabled: boolean = null;

  activeVehicleEntryComponent: ActiveComponent = ActiveComponent.NONE;
  @ViewChild('createVehicleComponent') createVehicleComponent: CreateVehicleComponent;
  @ViewChild('importVehiclesComponent') importVehiclesComponent: ImportVehiclesComponent;
  @ViewChild('importVehiclesCartegraphComponent') importVehiclesCartegraphComponent: ImportVehiclesCartegraphComponent;

  constructor(
    private dialogRef: MatDialogRef<AddOrImportVehicleDialogComponent>,
    private configurationService: ConfigurationService,
    @Inject(MAT_DIALOG_DATA) protected data: EditVehicleComponentInputData,
  ) {
    // dialogRef.addPanelClass('use-material-design-3-theme');
  }

  ngOnInit() {
    this.configurationSubscription = this.configurationService.sharedConfigurationModel.subscribe(cfg => {
      if (cfg) {
        this.handleConfigurationLoaded(cfg);
      }
    });
    this.initAsync();
  }

  ngOnDestroy() {
    this.configurationSubscription?.unsubscribe();
  }


  protected handleSelectedTabChange(selectedTabIndex: number) {
    switch (selectedTabIndex) {
      case 0:
        this.activeVehicleEntryComponent = ActiveComponent.MANUAL_ADD_VEHICLE;
        break;
      case 1:
        this.activeVehicleEntryComponent = ActiveComponent.IMPORT_VEHICLES;
        break;
      default:
        throw new Error('Unknown tab index');
    }
  }

  protected isSaveButtonEnabled() {
    const formComponent = this.resolveActiveFormComponent();
    return this.isInitialized && !this.isWorking && !!formComponent && formComponent.canBeSaved();
  }

  protected performSave() {
    delete this.saveError;
    const formComponent = this.resolveActiveFormComponent();
    if (!formComponent) {
      throw new Error('Cannot import while there is not active user entry component.');
    }

    this.isWorking = true;
    const disableCloseStateToRestore = this.dialogRef.disableClose;
    this.dialogRef.disableClose = true;

    formComponent.save()
      .then((vehicleResult) => {
        this.dialogRef.close(vehicleResult);
      })
      .catch((saveError) => {
        this.saveError = saveError;
        console.warn('vehicle save error', saveError);
        // this.editVehicleComponent.showSaveErrorToast();
      })
      .finally(() => {
        this.isWorking = false;
        this.dialogRef.disableClose = disableCloseStateToRestore;
      });

  }

  private resolveActiveFormComponent(): AddOrImportVehicleDialogForm {
    switch (this.activeVehicleEntryComponent) {
      case ActiveComponent.NONE:
        return null;
      case ActiveComponent.MANUAL_ADD_VEHICLE:
        return this.createVehicleComponent;
      case ActiveComponent.IMPORT_VEHICLES:
        return this.importVehiclesComponent;
      case ActiveComponent.IMPORT_FROM_CARTEGRAPH:
        return this.importVehiclesCartegraphComponent;
      default:
        throw new Error('Unknown vehicle entry component.');
    }
  }

  private handleConfigurationLoaded(cfg: ConfigurationModel) {
    this.cartegraphEnabled = this.configurationService
      .hasFeatureFlag(cfg.featureFlags, FeatureFlagEnum.CartegraphIntegration);

    if (this.cartegraphEnabled) {
      this.activeVehicleEntryComponent = ActiveComponent.IMPORT_FROM_CARTEGRAPH;
    } else {
      this.activeVehicleEntryComponent = ActiveComponent.MANUAL_ADD_VEHICLE; // the tab at index 0
    }
  }

  private initAsync() {
    // FIXME: Find a proper solution to avoid ExpressionChangedAfterItHasBeenCheckedError. See https://angular.io/errors/NG0100
    setTimeout(() => {
      this.isInitialized = true;
    }, 0);
  }

  isImportOperation() {
    return this.activeVehicleEntryComponent != ActiveComponent.MANUAL_ADD_VEHICLE;
  }
}

enum ActiveComponent {
  NONE,
  MANUAL_ADD_VEHICLE,
  IMPORT_VEHICLES,
  IMPORT_FROM_CARTEGRAPH,
}
