import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {
    AdditionalMapLayerManager
} from '../../../../pages/settings/components/manage-public-portal/additional-map-layer-manager';
import {ConfigurationModel} from '../../../models/configuration.model';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {ToastService} from '../../../services/toast.service';
import {MatDialog} from '@angular/material/dialog';
import {ActionMenuItemSubMenu} from '../../../models/action-menu-item.class';
import {MapLayerConfiguration, MapLayerType} from '../../../models/map-layer.model';
import {ExtAuthService} from '../../../../data/ext-auth/ext-auth.service';
import {Subscription} from 'rxjs';
import {MapLayerEditComponent} from '../map-layer-edit/map-layer-edit.component';

class LayerMenuItem {
    constructor(
        readonly id: number | string,
        readonly icon: string,
        readonly title: string,
        readonly type: string,
        readonly description: string,
        readonly onClick: () => void,
        readonly subMenus: ActionMenuItemSubMenu[]
    ) {}
}


@Component({
  selector: 'app-custom-layers-config',
  templateUrl: './custom-layers-config.component.html',
  styleUrls: ['./custom-layers-config.component.scss'],
})
export class CustomLayersConfigComponent extends AdditionalMapLayerManager implements OnInit, OnDestroy {

    configuration: ConfigurationModel;
    protected publicLayers = false;
    layerEditMode = false;

    items: LayerMenuItem[];
    mapLayer: MapLayerConfiguration;
    newMapLayer: MapLayerConfiguration;
    tabIndex = 0;

    private tenantConfigurationSubscription: Subscription;

    @ViewChild('newMapLayerEdit') newMapLayerEdit: MapLayerEditComponent;

    constructor(
        protected configurationService: ConfigurationService,
        private extAuthService: ExtAuthService,
        protected toast: ToastService,
        public dialog: MatDialog,
    ) {
        super(configurationService, toast, dialog);
    }

    ngOnInit(): void {
        this.tenantConfigurationSubscription = this.configurationService.sharedConfigurationModel.subscribe(tenantConfiguration => {
            if (tenantConfiguration) {
                this.configuration = tenantConfiguration;
                this.reloadConfigurations();
            }
        });
    }

    ngOnDestroy() {
        this.tenantConfigurationSubscription?.unsubscribe();
    }

    private reloadConfigurations() {
        this.loadMapLayerConfigurations(notEmpty => {

            this.createNewLayer();
            this.updateItems();
        });
    }

    private createNewLayer() {
        const mapLayer = new MapLayerConfiguration();
        mapLayer.public = this.publicLayers;
        mapLayer.type = MapLayerType.RASTER;
        mapLayer.order = this.mapLayers.length > 0 ? (this.mapLayers[this.mapLayers.length - 1].order + 1) : 1;
        mapLayer.enabled = false;
        mapLayer.new = true;
        mapLayer.dirty = true;
        mapLayer.currentType = mapLayer.type;
        mapLayer.currentName = mapLayer.name;
        mapLayer.currentUrl = mapLayer.url;
        mapLayer.currentConfiguration = mapLayer.configuration;
        mapLayer.currentUsername = mapLayer.username;
        mapLayer.currentPassword = mapLayer.password;
        this.newMapLayer = mapLayer;
    }

    onMapLayerSaved(mapLayer: MapLayerConfiguration) {
        this.saveLayer(mapLayer, () => {
            this.layerEditMode = false;
        });
    }

    createLayer() {
        this.mapLayers.push(this.newMapLayer);
        this.saveLayer(this.newMapLayer, () => {
            this.createNewLayer();
            this.newMapLayerEdit.setUntouched();
            this.layerEditMode = false;
            this.tabIndex = 0;
        });
    }

    editLayer(index) {
        this.layerEditMode = true;
        this.mapLayer = this.mapLayers[index];
    }

    back() {
        this.layerEditMode = false;
        this.reloadConfigurations();
    }

    private updateItems() {
        this.items = this.mapLayers.map((layer, index) => this.mapLayerToMenuItem(layer, index));
    }

    private mapLayerToMenuItem(layer: MapLayerConfiguration, index: number): LayerMenuItem {
        return new LayerMenuItem(
            layer.id,
            'layers',
            layer.name,
            layer.type,
            layer.type === MapLayerType.RASTER ? layer.url : (layer.type === MapLayerType.ROUTE ? 'system generated layer' : ''),
            () => this.editLayer(index),
            [
                new ActionMenuItemSubMenu(
                    'delete',
                    'Delete',
                    () => {
                        this.deleteLayer(index);
                        this.updateItems();
                    },
                ),
                new ActionMenuItemSubMenu(
                    'south',
                    'Move Layer',
                    () => {
                        this.moveLayerDown(index);
                        this.updateItems();
                    },
                ),
            ],
        );
    }
}
