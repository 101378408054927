import {Injectable} from '@angular/core';
import {Asset} from '../../pages/live-map/models/asset.class';
import {LiveMapDataService} from '../../pages/live-map/services/live-map-data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, Subscription} from 'rxjs';
import {DriverModel} from '../../shared/models/driver.model';
import {VehicleCategoryModel} from '../../shared/models/vehicle';
import {ShiftsManagerService} from '../shifts/shifts-manager.service';
import {VehiclesManagerService} from '../vehicles/vehicles-manager.service';
import {ShiftModel, ShiftState} from '../../shared/models/shift.model';
import moment from 'moment/moment';
import {VehicleLocationUpdate} from '../../shared/models/vehicle-breadcrumb';
import {VehicleModelWithActiveShift} from '../../shared/models/vehicle.model';
import {RouteAssignmentManagerService} from '../routes/route-assignment-manager.service';
import {VehicleRouteAssignmentStatus} from '../../shared/models/route-assignment';
import {AssetStatus} from '../../shared/models/asset-status';
import {HardwareTypeId} from '../../shared/components/hardware-filter/hardware-filter.component';

@Injectable({
  providedIn: 'root'
})
export class AssetsManagerService {

  private isInitialized = false;
  private activeShiftsInitialized = false;
  private vehicleLocationsInitialized = false;
  private readonly assetsMap = new Map<number, Asset>();

  private readonly driversMap = new Map<number, DriverModel>();
  private readonly vehiclesMap = new Map<number, VehicleModelWithActiveShift>();
  private readonly vehicleGroupsMap = new Map<number, VehicleCategoryModel>();
  private readonly vehiclesStatusMap = new Map<number, VehicleRouteAssignmentStatus>();

  private assetsSource = new BehaviorSubject<Asset[]>([]);
  public assets$ = this.assetsSource.asObservable();

  private filteredAssetsSource = new BehaviorSubject<Asset[]>([]);
  public filteredAssets$ = this.filteredAssetsSource.asObservable();

  // left menu source: show all or hide offline
  private statusFilteredAssetsSource = new BehaviorSubject<Asset[]>([]);
  public statusFilteredAssets$ = this.statusFilteredAssetsSource.asObservable();

  // filters
  private hiddenVehicleGroupIdsFilter: number[] = null;
  private visibleOfflineVehicleGroupIdsFilter: number[] = null;
  private vehicleFilter: number[] = null;
  private statusFilter: string[] = null;
  private hardwareFilter: string = null;

  private readonly openSubscriptions = Array<Subscription>();

  constructor(
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private liveMapDataService: LiveMapDataService,
      private shiftsManagerService: ShiftsManagerService,
      private vehiclesManagerService: VehiclesManagerService,
      private routeAssignmentManager: RouteAssignmentManagerService,
  ) { }

  public init() {
    if (this.isInitialized) {
      throw Error('The Assets Manager has already been initialized.');
    }

    const driverSubscription = this.liveMapDataService.drivers$.subscribe(drivers => {
      drivers.forEach(driver => {
        this.driversMap.set(driver.id, driver);
      });
    });
    this.openSubscriptions.push(driverSubscription);

    const vehicleGroupSubscription = this.liveMapDataService.vehicleCategories$.subscribe(vehicleGroups => {
      vehicleGroups.forEach(vg => {
        this.vehicleGroupsMap.set(vg.id, vg);
      });
      this.assetsMap.forEach(asset => {
        this.updateVehicleGroup(asset);
      });
      this.onAssetsUpdated();
    });
    this.openSubscriptions.push(vehicleGroupSubscription);

    // replace all assets
    const vehiclesSubscription = this.liveMapDataService.vehicles$.subscribe(vehicles => {
      vehicles.forEach(vehicle => {
        this.vehiclesMap.set(vehicle.id, vehicle);
        const asset = new Asset(
            vehicle.id,
            vehicle.activeShift?.id,
            !!vehicle.label ? vehicle.label : vehicle.name,
            vehicle.groupId,
        );
        if (!!vehicle.activeShift) {
          asset.shiftStatus = ShiftState.NORMAL;
          asset.shiftStartTime = vehicle.activeShift.start;
          asset.driverId = vehicle.activeShift.driverId;
          asset.setDriverName(vehicle.activeShift?.driver?.name, vehicle);
          asset.driverIsGuest = vehicle.activeShift.driver.guest;
          asset.time = vehicle.activeShift.start;
        }
        if (!!vehicle.deviceInfo) {
          asset.deviceInfo = vehicle.deviceInfo;
        }
        asset.vehicleHardwareConfiguration = vehicle.hardwareConfiguration;
        asset.vehicleLmuId = vehicle.lmuId;
        asset.cameraConfiguration = vehicle.cameraConfiguration;
        asset.hasNoTablet = vehicle.hasNoTablet;
        this.updateVehicleGroup(asset);
        this.assetsMap.set(
            vehicle.id,
            asset,
        );
      });
      this.onAssetsUpdated();
    });
    this.openSubscriptions.push(vehiclesSubscription);

    // listen to shift info updates (triggered on start and end)
    const shiftInfoSubscription = this.liveMapDataService.shiftInfo$.subscribe(shiftInfo => {
      if (!!shiftInfo) {
        const asset = this.assetsMap.get(shiftInfo.vehicleId);
        if (!!asset) {
          // update shift using shift info event data
          asset.shiftId = shiftInfo.id;
          asset.shiftStartTime = shiftInfo.start;
          if (asset.shiftStatus !== ShiftState.ENDED) { // we cannot rely on shiftInfo.end
            asset.driverId = shiftInfo.driverId;
            asset.driverName = shiftInfo.driver.name;
            asset.setDriverName(shiftInfo.driver.name, shiftInfo.vehicle);
            asset.driverIsGuest = shiftInfo.driver.guest;
          } else {
            asset.driverId = null;
            asset.shiftId = null;
          }
          this.assetsMap.set(asset.id, asset);
          this.onAssetsUpdated();
        }
      }
    });
    this.openSubscriptions.push(shiftInfoSubscription);

    const that = this;
    // process incoming shift events
    const activeShiftsSubscription = this.shiftsManagerService.activeShifts$.subscribe({
      next(activeShifts) {
        if (!that.activeShiftsInitialized) {
          that.handleActiveShiftsChanged(activeShifts.state);
        } else {
          if (activeShifts.added.length > 0) {
            that.handleShiftsStarted(activeShifts.added);
          }
        }
        if (activeShifts.removed.length > 0) {
          that.handleShiftsEnded(activeShifts.removed);
        }
        if (activeShifts.updated.length > 0) {
          that.handleShiftsUpdated(activeShifts.updated);
        }
      }
    });
    this.openSubscriptions.push(activeShiftsSubscription);

    // process vehicle position change
    const lastLocationSubscription = this.vehiclesManagerService.vehicleLocations$.subscribe(locationUpdate => {
      if (!this.vehicleLocationsInitialized) {
        this.handleVehicleLocationChanged(locationUpdate.state);
        this.vehicleLocationsInitialized = true;
      } else {
        this.handleVehicleLocationChanged(locationUpdate.updated);
      }
    });
    this.openSubscriptions.push(lastLocationSubscription);

    const vehiclesRouteAssignmentSubscription = this.routeAssignmentManager.vehicleRouteAssignmentStatus$.subscribe(statusUpdate => {
      let vehiclesStatus: VehicleRouteAssignmentStatus[];
      if (statusUpdate.updated.length === 0) {
        vehiclesStatus = statusUpdate.state;
      } else {
        vehiclesStatus = statusUpdate.updated;
      }
      vehiclesStatus.forEach(vehicleStatus => {
        this.vehiclesStatusMap.set(vehicleStatus.vehicleId, vehicleStatus);
        const asset = this.assetsMap.get(vehicleStatus.vehicleId);
        if (!!asset) {
          asset.routeAssignmentStatus = vehicleStatus.status;
          asset.currentRoute = vehicleStatus.routeAssignment;
          this.assetsMap.set(asset.id, asset);
        }
      });
      this.onAssetsUpdated();
    });
    this.openSubscriptions.push(vehiclesRouteAssignmentSubscription);

    this.isInitialized = true;
  }

  public release() {
    if (!this.isInitialized) {
      return;
    }

    this.openSubscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });

    this.isInitialized = false;
    this.activeShiftsInitialized = false;
    this.vehicleLocationsInitialized = false;
    this.assetsMap.clear();
    this.vehiclesMap.clear();
    this.vehicleGroupsMap.clear();
    this.driversMap.clear();
    this.vehiclesStatusMap.clear();
  }

  public filterByLeftPanel(hiddenVehicleGroupIds: number[], visibleOfflineVehicleGroupIds: number[]) {
    this.hiddenVehicleGroupIdsFilter = hiddenVehicleGroupIds;
    this.visibleOfflineVehicleGroupIdsFilter = visibleOfflineVehicleGroupIds;
    this.onFilterChanged();
  }

  public filterByStatus(statusFilter: string[], source: string = null) {
    this.statusFilter = statusFilter;
    this.onFilterChanged();
  }

  public filterByHardware(hardwareFilter: string) {
    this.hardwareFilter = hardwareFilter;
    this.onFilterChanged();
  }

  public filterByVehicleIds(vehicleIds: number[]) {
    this.vehicleFilter = vehicleIds;
    this.onFilterChanged();
  }

  public resetFilters() {
    this.vehicleFilter = null;
    this.hiddenVehicleGroupIdsFilter = null;
    this.visibleOfflineVehicleGroupIdsFilter = null;
    this.statusFilter = null;
    this.hardwareFilter = null;
    this.onFilterChanged();
  }

  public getVehicleGroups() {
    return [...this.vehicleGroupsMap.values()];
  }

  private onAssetsUpdated() {
    this.assetsSource.next([...this.assetsMap.values()]);
    this.onFilterChanged();
  }

  private onFilterChanged() {
    const filteredAssets = [];
    const statusFilteredAssets = [];
    if (!!this.vehicleFilter) {
      this.vehicleFilter.forEach(vehicleId => {
        filteredAssets.push(this.assetsMap.get(vehicleId));
      });
    } else {
      this.assetsMap.forEach(asset => {
        if (((asset.shiftStatus !== ShiftState.ENDED &&
              (!this.hiddenVehicleGroupIdsFilter || !this.hiddenVehicleGroupIdsFilter.includes(asset.vehicleTypeId))
            ) ||
            (asset.shiftStatus === ShiftState.ENDED &&
              (!!this.visibleOfflineVehicleGroupIdsFilter && this.visibleOfflineVehicleGroupIdsFilter.includes(asset.vehicleTypeId))
            )) &&
            (!this.statusFilter || this.statusFilter.includes(AssetStatus.getAssetStatus(asset).id)) &&
            (!this.hardwareFilter || (this.hardwareFilter === HardwareTypeId.LMU && asset.hasNoTablet) || (this.hardwareFilter === HardwareTypeId.TABLET && !asset.hasNoTablet))
        ) {
          filteredAssets.push(asset);
        }
      });
    }
    this.filteredAssetsSource.next(this.sortAssets(filteredAssets));

    // TODO nicer code
    this.assetsMap.forEach(asset => {
      if (
          (!this.statusFilter || this.statusFilter.includes(AssetStatus.getAssetStatus(asset).id))
      ) {
        statusFilteredAssets.push(asset);
      }
    });
    this.statusFilteredAssetsSource.next(statusFilteredAssets);
  }

  private handleActiveShiftsChanged(activeShifts: ShiftModel[]) {
    // add active shifts
    // update existing or add new
    activeShifts.forEach(activeShiftModel => {
      // update shift by active shifts info event data
      const asset = this.assetsMap.get(activeShiftModel.vehicleId);
      if (!!asset) {
        asset.shiftId = activeShiftModel.id;
        asset.shiftStatus = activeShiftModel.state;
        asset.shiftStartTime = activeShiftModel.start;
        asset.shiftDuration = 0;
        asset.time = activeShiftModel.start;
        // TODO driver name??
        this.assetsMap.set(asset.id, asset);
      }
    });
    this.onAssetsUpdated();
    this.activeShiftsInitialized = true;
  }

  private handleShiftsEnded(endedShifts: ShiftModel[]) {
    // end active shifts
    endedShifts.forEach(endedShift => {
      const asset = this.assetsMap.get(endedShift.vehicleId);
      if (!!asset) {
        asset.driverId = null;
        asset.shiftId = null;
        asset.shiftStatus = ShiftState.ENDED;
        asset.shiftDuration = moment().diff(moment(asset.shiftStartTime), 'seconds');
        asset.time = new Date();
        this.assetsMap.set(asset.id, asset);
      }
    });
    this.onAssetsUpdated();
  }

  private handleShiftsStarted(startedShifts: ShiftModel[]) {
    // add active shifts
    startedShifts.forEach(startedShift => {
      const asset = this.assetsMap.get(startedShift.vehicleId);
      if (!!asset) {
        asset.shiftId = startedShift.id;
        asset.shiftStatus = startedShift.state;
        asset.shiftStartTime = new Date();
        asset.shiftDuration = 0;
        asset.time = new Date();
        this.assetsMap.set(asset.id, asset);
      }
    });
    this.onAssetsUpdated();
  }

  private handleShiftsUpdated(shifts: ShiftModel[]) {
    shifts.forEach(shift => {
      const asset = this.assetsMap.get(shift.vehicleId);
      if (!!asset) {
        asset.shiftStatus = shift.state;
        this.assetsMap.set(asset.id, asset);
      }
    });
    this.onAssetsUpdated();
  }

  private handleVehicleLocationChanged(locationUpdates: VehicleLocationUpdate[]) {
    locationUpdates.forEach(locationUpdate => {
      if (locationUpdate.location) {
        const asset = this.assetsMap.get(locationUpdate.vehicleId);
        if (!!asset) {
          asset.currentHeading = locationUpdate.location.heading;
          asset.flags = locationUpdate.location.flags;
          asset.location = locationUpdate.location.coords;
          asset.speed = locationUpdate.location.speed;
          this.assetsMap.set(asset.id, asset);
        }
      }
    });
    if (locationUpdates.length > 0) {
      this.onAssetsUpdated();
    }
  }

  private updateVehicleGroup(asset: Asset) {
    const vehicleGroup = this.vehicleGroupsMap.get(asset.vehicleTypeId);
    if (!!vehicleGroup) {
      asset.vehicleType = vehicleGroup.title;
    }
  }

  private sortAssets(assets: Asset[]): Asset[] {
    return assets.sort((a, b) => {
      if (a.shiftStatus === b.shiftStatus) {
        return a.name < b.name ? -1 : 1;
      } else {
        return b.shiftStatus === ShiftState.ENDED ? -1 : 1;
      }
    });
  }
}
