import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Subscription} from 'rxjs';
import {Asset} from '../../../models/asset.class';
import {RouteAssignmentManagerService} from '../../../../../data/routes/route-assignment-manager.service';
import {RouteAssignment} from '../../../../../shared/models/route-assignment';
import {ActionMenuItem} from '../../../../../shared/models/action-menu-item.class';
import {ShortDateOrTimePipe} from '../../../../../shared/formatting/short-date-or-time.pipe';
import {VehiclesManagerService} from '../../../../../data/vehicles/vehicles-manager.service';
import moment from 'moment/moment';

export enum QueueType {
  VEHICLES,
  ROUTES,
}

@Component({
  selector: 'app-route-assignment-queue',
  templateUrl: './route-assignment-queue.component.html',
  styleUrl: './route-assignment-queue.component.scss'
})
export class RouteAssignmentQueueComponent implements OnInit, OnChanges, OnDestroy {

  @Input() vehicle: Asset;
  @Input() routeConfigId: number;
  @Input() routeId: number;
  @Input() timeFromHoursFilter: number;

  allRecentRouteAssignments: RouteAssignment[];
  filteredAssignments: RouteAssignment[];
  items: ActionMenuItem[] = [];

  private readonly openSubscriptions = Array<Subscription>();

  constructor(
      private routeAssignmentManager: RouteAssignmentManagerService,
      private vehicleManager: VehiclesManagerService,
      private shortDateOrTimePipe: ShortDateOrTimePipe,
  ) {
  }

  ngOnInit() {
    const assignmentsSubscription = this.routeAssignmentManager.recentRouteAssignments$.subscribe(assignmentsUpdate => {
      // console.log(assignmentsUpdate);
      this.allRecentRouteAssignments = assignmentsUpdate.state;
      this.reloadAssignments();
    });
    this.openSubscriptions.push(assignmentsSubscription);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.reloadAssignments();
  }

  ngOnDestroy() {
    this.openSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private reloadAssignments() {
    if (!!this.allRecentRouteAssignments) {
      const queueType = !!this.vehicle ? QueueType.ROUTES : QueueType.VEHICLES;
      this.filteredAssignments = RouteAssignment.sortByStatus(
          this.filterBySubject(
              this.allRecentRouteAssignments,
              queueType,
          )
      );
      this.items = this.filteredAssignments.map(assignment => {
        return this.assignmentToItem(
            assignment,
            queueType,
        );
      });
    }
  }

  private filterBySubject(assignments: RouteAssignment[], queueType: QueueType) {
    if (queueType === QueueType.ROUTES) {
      // assignments for the shift
      return assignments.filter(assignment => assignment.shiftId === this.vehicle.shiftId);
    } else {
      return assignments.filter(assignment => {
        // assignments in last 24 hours for the route
        return assignment.routeId === this.routeId
          && assignment.configId === this.routeConfigId
          && moment(assignment.created).isAfter(moment().subtract(24, 'hour'));
      });
    }
  }

  private assignmentToItem(assignment: RouteAssignment, type: QueueType): ActionMenuItem {
    return new ActionMenuItem(
        assignment.id,
        type === QueueType.VEHICLES ? 'directions_car' : 'route',
        type === QueueType.VEHICLES ? this.getVehicleName(assignment.vehicleId) : assignment.routeName,
        this.getStatus(assignment),
        null,
        null,
        () => !!assignment.onAssignmentFrom && !assignment.completed,
        null,
        null,
        () => false,
        null,
        null,
        [],
    );
  }

  private getVehicleName(vehicleId: number): string {
    return this.vehicleManager.getVehicle(vehicleId)?.label;
  }

  private getStatus(assignment: RouteAssignment): string {
    if (!!assignment.completed) {
      return 'Completed ' + this.shortDateOrTimePipe.transform(new Date(assignment.completed));
    } else {
      if (!!assignment.onAssignmentFrom) {
        return 'In Progress';
      } else {
        return 'Assigned';
      }
    }
  }
}
