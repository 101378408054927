import {Directive, forwardRef, TemplateRef} from '@angular/core';

export abstract class CardContent {
  abstract templateRef: TemplateRef<any>

  abstract needsMargin: boolean
}

/**
 * A directive used to annotate children of an `<app-card>` component.
 */
@Directive({
  selector: '[appCardContent]',
  providers: [{provide: CardContent, useExisting: forwardRef(() => CardContentDirective)}],
  standalone: true,
})
export class CardContentDirective extends CardContent {
  constructor(public templateRef: TemplateRef<any>) {
    super()
  }

  needsMargin = true
}

@Directive({
  selector: '[appCardContentRaw]',
  providers: [{provide: CardContent, useExisting: forwardRef(() => CardContentRawDirective)}],
  standalone: true,
})
export class CardContentRawDirective extends CardContent {
  constructor(public templateRef: TemplateRef<any>) {
    super()
  }

  needsMargin = false
}
