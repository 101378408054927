import {LngLat, LngLatBounds, LngLatBoundsLike, LngLatLike, Map, MapMouseEvent} from 'maplibre-gl';
import {Feature, LineString, Point} from 'geojson';
import {PointFeature} from '../models/GeoJson';
import {MapContentLayer} from '../components/map-preview/model/MapContentLayer';

export class MapTools {

  static readonly MSG_NO_MAP = 'changing map content that is not displayed on map';

  static async loadImage(map: Map, name: string, url: string, options: any) {
    const imageResponse = await map.loadImage(url);
    map.addImage(name, imageResponse.data, options);
  }

  static onMap(map: Map, changes: () => void) {
    if (map == null) {
      console.error(MapTools.MSG_NO_MAP);
    } else {
      changes();
    }
  }

  static checkPropagation(e: MapMouseEvent): boolean {
    // https://github.com/mapbox/mapbox-gl-js/issues/9369
    if (e.originalEvent.cancelBubble){
      return true;
    } else {
      e.originalEvent.cancelBubble = true;
      return false;
    }
  }

  static zoomToPointFeature(map: Map, feature: PointFeature, zoomLevel: number = 14) {
    MapTools.zoomToLocation(map, new LngLat(feature.geometry.coordinates[0], feature.geometry.coordinates[1]), zoomLevel);
  }

  static zoomToLocation(map: Map, location: LngLatLike, zoomLevel: number = 14) {
    map.flyTo({
      center: location,
      zoom: zoomLevel,
      essential: true,
    });
  }

  static zoomToFeatures(map: Map, features: Feature[]) {
    if (features != null && features.length > 0) {
      const bounds = MapTools.calculateBoundsFromFeatures(features);
      map.fitBounds(bounds, {
        padding: 80
      });
    }
  }

  static calculateBoundsFromFeatures(features: Feature[]): LngLatBounds {
    const flattenedCoordinates = features
      .map(feature => {

        if (feature.geometry?.type === 'LineString') {
          const lineString = feature.geometry as LineString;
          return lineString.coordinates;
        } else if (feature.geometry?.type === 'Point') {
          const point = feature.geometry as Point;
          return [point.coordinates];
        } else {
          throw Error('error while calculating bounds :: geometry type not supported');
        }

      })
      .reduce((accumulator, value) => accumulator.concat(value), []); // flatten array

    const bounds = new LngLatBounds(
        [flattenedCoordinates[0][0], flattenedCoordinates[0][1]],
        [flattenedCoordinates[0][0], flattenedCoordinates[0][1]],
    );

    // extend the 'LngLatBounds' to include every coordinate in the bounds result.
    for (const position of flattenedCoordinates) {
      bounds.extend(position as LngLatBoundsLike);
    }

    return bounds;
  }

  static pointCursorForLayer(map: Map, layer: MapContentLayer, enable: boolean) {
    const mouseenter = () => {
      map.getCanvas().style.cursor = 'pointer';
    };
    const mouseleave = () => {
      map.getCanvas().style.cursor = '';
    };

    if (enable) {
      map.on('mouseenter', layer.layerId, mouseenter);
      map.on('mouseleave', layer.layerId, mouseleave);
    } else {
      map.off('mouseenter', layer.layerId, mouseenter);
      map.off('mouseleave', layer.layerId, mouseleave);
    }
  }
}
