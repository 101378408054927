import {Injectable} from '@angular/core';
import {TrackStyles} from './model/TrackStyles';
import {environment} from '../../environments/environment';
import {JsonApiResponse} from '../shared/models/JsonApiResponse';
import {TrackStyle} from './model/TrackStyle';
import {catchError} from 'rxjs/operators';
import {HttpErrorHandler} from '../http.error.handler';
import { HttpClient } from '@angular/common/http';
import {ToastService} from '../shared/services/toast.service';

@Injectable({
  providedIn: 'root'
})
export class TrackStylesService {

  constructor(
    private http: HttpClient,
    private toast: ToastService,
  ) {}

  loadTrackStyles(): Promise<TrackStyles> {
    const requestUrl = `${environment.services.service}v1/configuration/track-styles`;
    return this.http.get<JsonApiResponse<TrackStyles>>(requestUrl)
      .pipe(catchError(HttpErrorHandler.handleError))
      .toPromise()
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        console.error(`unable to load track styles :: error = '${error}'`);
        this.toast.longer('Unable to load track styles');
        return Promise.reject(error);
      });
  }

  saveTrackStyles(saved: TrackStyle[]): Promise<TrackStyles> {
    const requestUrl = `${environment.services.service}v1/configuration/track-styles/save`;
    return this.http.post<JsonApiResponse<TrackStyles>>(requestUrl, saved)
      .pipe(catchError(HttpErrorHandler.handleError))
      .toPromise()
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        console.error(`unable to save track styles :: error = '${error}'`);
        return Promise.reject(error);
      });
  }

  resetTrackStyles(reset: TrackStyle[]): Promise<TrackStyles> {
    const requestUrl = `${environment.services.service}v1/configuration/track-styles/reset`;
    return this.http.post<JsonApiResponse<TrackStyles>>(requestUrl, reset)
      .pipe(catchError(HttpErrorHandler.handleError))
      .toPromise()
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        console.error(`unable to reset track styles :: error = '${error}'`);
        return Promise.reject(error);
      });
  }
}
