import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ServicesSocketService } from '../websocket/services-socket.service';
import { ImageModel } from '../../shared/models/image';
import { HighlightedItemSource, HighlightedItemUpdate } from '../../shared/models/highlighted-item-update';
import { ImageService } from './image.service';
import { ConfigurationModel, FeatureFlagEnum } from '../../shared/models/configuration.model';
import { ToastService } from '../../shared/services/toast.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DrawerContent } from '../../layouts/right-drawer/right-drawer.component';
import { ImageEventType, MessageSource, WebSocketEvent } from '../websocket/model/message.event';

@Injectable({
  providedIn: 'root'
})
export class ImagesManagerService {

  private isInitialized = false;

  private readonly imagesMap = new Map<number, ImageModel>();

  private filteredImagesSource = new BehaviorSubject<ImageModel[]>([]);
  public filteredImages$ = this.filteredImagesSource.asObservable();

  highlightedImageId: number = null;
  private highlightedImageSource = new BehaviorSubject<HighlightedItemUpdate>(new HighlightedItemUpdate(null, null));
  readonly highlightedImage$ = this.highlightedImageSource.asObservable();

  private shiftId: number = null;
  private readonly openSubscriptions = new Array<Subscription>();

  constructor(private imagesService: ImageService,
              private serviceSocketService: ServicesSocketService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private toast: ToastService) {
  }

  public init(configuration: ConfigurationModel) {
    if (this.isInitialized) {
      throw Error('The ImagesManagerService has already been initialized.');
    }

    if (this.hasFeatureFlag(configuration, FeatureFlagEnum.ReportImage)) {
      // listen to web socket updates on Live Map
      const imageSubscription = this.serviceSocketService.onMessage(MessageSource.IMAGE)
        .subscribe(((imageEvent: WebSocketEvent<ImageEventType, ImageModel>) => {
          if (imageEvent.eventType === ImageEventType.NEW) {
            if (imageEvent.data.shiftId === this.shiftId) {
              this.addImages([imageEvent.data]);
            } else {
              console.log('Ignoring new image. Not on selected shift.');
            }
          }
        }));
      this.openSubscriptions.push(imageSubscription);
    }

    this.isInitialized = true;
  }

  public release() {
    if (!this.isInitialized) {
      return;
    }

    this.imagesMap.clear();
    this.openSubscriptions.forEach(subscription => subscription?.unsubscribe());
    this.openSubscriptions.length = 0;
    this.isInitialized = false;
  }

  hasFeatureFlag(configuration: ConfigurationModel, featureFlag: string): boolean {
    return configuration.featureFlags.find(value => value.isEnabled && value.name === featureFlag) !== undefined;
  }

  public filterByShift(shiftId: number) {
    if (this.shiftId !== shiftId) {
      this.shiftId = shiftId;
      if (!!this.shiftId) {
        this.loadShiftImages();
      } else {
        this.imagesMap.clear();
        this.onFilterChanged();
      }
    }
  }

  private loadShiftImages() {
    this.imagesService.getCadenceImagesPerShift(this.shiftId).toPromise().then(response => {
      this.addImages(response.data);
    }).catch(() => {
      this.toast.short('Images sync error.');
    });
  }

  private addImages(images: ImageModel[]) {
    images.forEach(image => {
      this.addImage(image);
    });
    this.onImagesUpdated();
  }

  private addImage(image: ImageModel) {
    if (this.imagesMap.has(image.id)) {
      throw new Error(`An image with id ${image.id} already exists.`);
    }
    this.imagesMap.set(image.id, image);
  }

  private onImagesUpdated() {
    this.onFilterChanged();
  }

  private onFilterChanged() {
    const images = [];
    if (!!this.shiftId) {
      this.imagesMap.forEach(image => {
        if (this.shiftId === image.shiftId) {
          images.push(image);
        }
      });
    }
    this.filteredImagesSource.next(images);
  }

  public highlightImage(imageId: number, source: HighlightedItemSource) {
    if (this.highlightedImageId !== imageId) {
      this.highlightedImageId = imageId;
      this.onHighlightedChanged(imageId, source);
      if (!!imageId && source === HighlightedItemSource.MAP) {
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: { drawer: DrawerContent.LIVE_VIDEO },
          queryParamsHandling: 'merge',
        });
      }
    }
  }

  private onHighlightedChanged(imageId: number, source: HighlightedItemSource) {
    this.highlightedImageSource.next(
        new HighlightedItemUpdate(imageId, source)
    );
  }
}
