
export class DvirFormQuestionFormatOption {
    public static FORM = new DvirFormQuestionFormatOption('Form (All questions presented as a single form)', 'FORM');
    public static WIZARD = new DvirFormQuestionFormatOption('Wizard (One question at a time)', 'WIZARD');

    public static options = [
      DvirFormQuestionFormatOption.FORM,
      DvirFormQuestionFormatOption.WIZARD
    ];
    static label = 'Questions format on Tablet';
    static default = DvirFormQuestionFormatOption.FORM;
    static optionCode = 'QUESTION_FORMAT';

    label: string;
    code: string;

    constructor(label: string, code: string) {
        this.label = label;
        this.code = code;
    }

    static fromCode(code: string) {
        const found = DvirFormQuestionFormatOption.options.find(value => value.code === code);
        if (found == null) { throw new Error(`error parsing MifFormQuestionFormatOption, code = ${code}`); }
        return found;
    }
}
