import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AssetsManagerService} from '../../../../data/assets/assets-manager.service';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {Subscription} from 'rxjs';
import {ConfigurationModel} from '../../../../shared/models/configuration.model';
import {Asset} from '../../models/asset.class';
import {MainRoute, RootRoute} from '../../../../shared/models/angular-routing';
import {DrawerContent} from '../../../../layouts/right-drawer/right-drawer.component';

export abstract class RightPanel {

    static readonly TRIGGER_VEHICLE_ACTION_PARAM_NAME = 'vehicleAction';
    static readonly TRIGGER_ROUTE_ACTION_PARAM_NAME = 'routeAction';

    configuration: ConfigurationModel;

    private assetId: number;
    assets: Asset[];
    asset: Asset;

    protected routeConfigId: number;
    protected routeId: number;

    protected readonly openSubscriptions = new Array<Subscription>();

    abstract onAssetChange();
    abstract onAssetsUpdate();
    abstract onAssetAction();
    abstract onRouteAction();
    abstract onConfigurationLoad();

    protected constructor(
        protected content: DrawerContent,
        protected router: Router,
        protected activatedRoute: ActivatedRoute,
        protected configurationService: ConfigurationService,
        protected assetsManager: AssetsManagerService,
    ) {
    }

    protected loadConfiguration() {
        const configSubscription = this.configurationService.sharedConfigurationModel.subscribe(model => {
            if (model) {
                this.configuration = model;
                this.onConfigurationLoad();
            }
        });
        this.openSubscriptions.push(configSubscription);
    }

    protected initialize() {
        this.getAssetIdParamFromUrl();
        this.getRouteParamFromUrl();
        this.changeAsset();
        this.triggerAssetAction();
        this.triggerRouteAction();

        const routerEventsSubscription = this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                this.getAssetIdParamFromUrl();
                this.getRouteParamFromUrl();
                this.changeAsset();
                this.triggerAssetAction();
                this.triggerRouteAction();
            }
        });
        this.openSubscriptions.push(routerEventsSubscription);

        const assetsManagerSubscription = this.assetsManager.assets$.subscribe(assets => {
            this.assets = assets;
            this.onAssetsUpdate();
            this.changeAsset();
        });
        this.openSubscriptions.push(assetsManagerSubscription);
    }

    protected unsubscribe() {
        this.openSubscriptions.forEach(s => s?.unsubscribe());
        this.assets = undefined;
        this.assetId = undefined;
        this.asset = undefined;
        this.routeConfigId = undefined;
        this.routeId = undefined;
    }

    private changeAsset() {
        if (!!this.assets) {
            if (!this.assetId) {
                // vehicle previously set or initial setting to null
                if (!!this.asset || this.asset === undefined) {
                    this.asset = null;
                    this.onAssetChange();
                    return;
                }
            } else {
                if (this.asset?.id !== this.assetId && this.assets.length > 0) {
                    this.asset = this.assets.find(asset => asset.id === this.assetId);
                    this.onAssetChange();
                }
            }
        }
    }

    private triggerAssetAction() {
        const vehicleActionParam = this.activatedRoute.snapshot.queryParams[RightPanel.TRIGGER_VEHICLE_ACTION_PARAM_NAME];
        const drawerParam = this.activatedRoute.snapshot.queryParams['drawer'];
        if (!!vehicleActionParam && this.content === drawerParam) {
            this.onAssetAction();
            const queryParams = {};
            queryParams[RightPanel.TRIGGER_VEHICLE_ACTION_PARAM_NAME] = undefined;
            this.router.navigate([], { queryParamsHandling: 'merge', queryParams });
        }
    }

    private triggerRouteAction() {
        const routeActionParam = this.activatedRoute.snapshot.queryParams[RightPanel.TRIGGER_ROUTE_ACTION_PARAM_NAME];
        const drawerParam = this.activatedRoute.snapshot.queryParams['drawer'];
        if (!!routeActionParam && this.content === drawerParam) {
            this.onRouteAction();
            const queryParams = {};
            queryParams[RightPanel.TRIGGER_ROUTE_ACTION_PARAM_NAME] = undefined;
            this.router.navigate([], { queryParamsHandling: 'merge', queryParams });
        }
    }

    private getAssetIdParamFromUrl() {
        // url: /main/vehicle/21?dateFilter=none&drawer=route
        // components: dashboard-layout -> empty -> main -> asset
        const endIndex = this.router.routerState.snapshot.url.indexOf('?') > 0 ? this.router.routerState.snapshot.url.indexOf('?') : null;
        const assetIdFromUrl = +this.router.routerState.snapshot.url.substring(`/${RootRoute.MAIN}/${MainRoute.VEHICLE}/`.length, endIndex);
        this.assetId = !!assetIdFromUrl ? assetIdFromUrl : null;

        /* The following doesn't work on AWS :-(
        const componentChild = this.activatedRoute.firstChild?.firstChild?.firstChild;
        if (!!componentChild && !!componentChild.snapshot.params) {
            const assetId = +componentChild.snapshot.params.id;
            if (!!assetId) {
                this.assetId = assetId;
                return;
            }
        }
        this.assetId = null;
        */
    }

    private getRouteParamFromUrl() {
        const endIndex = this.router.routerState.snapshot.url.indexOf('?') > 0 ? this.router.routerState.snapshot.url.indexOf('?') : null;
        const pathWithoutQueryParams = this.router.routerState.snapshot.url.substring(0, endIndex);
        if (pathWithoutQueryParams.startsWith(`/${RootRoute.MAIN}/${MainRoute.ROUTE}/`)) {
            const routePath: string[] = pathWithoutQueryParams.split('/');
            // validation
            if (routePath.length < 6 || routePath[0] !== '' || routePath[4] !== 'route-id') {
                console.warn('Unexpected URL!');
                console.log(pathWithoutQueryParams);
            }
            this.routeConfigId = +routePath[3].split(':::')?.[0];
            this.routeId = +routePath[5];
        } else {
            this.routeConfigId = undefined;
            this.routeId = undefined;
        }
    }
}
