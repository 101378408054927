<div class="dvir-report-container">

  <div class="report-menu">
    <div class="report-name-and-desc">
      <div class="report-name">
        Inspection Form Retrieval
      </div>
      <div class="report-description">
        Review past inspection forms
      </div>
    </div>

    <div class="report-menu__actions">
      <button
              mat-icon-button
              [matMenuTriggerFor]="downloadMenu"
              [matTooltip]="'Download'"
      >
        <mat-icon>
          download_for_offline
        </mat-icon>
      </button>
      <mat-menu #downloadMenu="matMenu">
        <button
                mat-menu-item
                (click)="previewPDFExportFile()"
        >
          Preview report
        </button>
        <button
                mat-menu-item
                (click)="loadPDFExportFile()"
        >
          Download report as PDF
        </button>
      </mat-menu>

      <app-filter-button [appliedFiltersCount]="appliedFiltersCount"
                         [active]="showingFilterBar"
                         (toggled)="toggleFilter()"
      ></app-filter-button>

      <button
              mat-icon-button
              (click)="closeInsight()"
              [matTooltip]="'Close'"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <app-filter-bar
    class="report-menu__filters"
    [origin]="'dvirReport'"
    [showingFilterBar]="showingFilterBar"
    [useDateFilter]="true"
    [useVehicleGroupFilter]="true"
    (dateFilterChanged)="dateFilterChanged($event)"
    (vehicleGroupFilterChanged)="vehicleGroupFilterChanged($event)"
    (appliedFiltersChanged)="appliedFiltersCount = $event"
  >
  </app-filter-bar>

  <div class="report__table__wrapper"
       [ngClass]="{'report-table-with-filter-bar': showingFilterBar}"
  >
    <app-dvir-report-table
      [dataSource]="dataSource"
      (sortChangedEvent)="loadReport()"
      (scrolledToBottomEvent)="extendReport()"
    >
    </app-dvir-report-table>
  </div>
</div>
