import {DvirSectionDto} from './DvirSectionDto';
import {DvirGroupDto} from './DvirGroupDto';
import {DvirQuestionDto} from './DvirQuestionDto';

export class DvirNodeDto {
  nodeType: string;
  section: DvirSectionDto;
  group: DvirGroupDto;
  question: DvirQuestionDto;
  nodes: DvirNodeDto[];

  constructor(nodeType: string, section: DvirSectionDto, group: DvirGroupDto, question: DvirQuestionDto, nodes: DvirNodeDto[]) {
    this.nodeType = nodeType;
    this.section = section;
    this.group = group;
    this.question = question;
    this.nodes = nodes;
  }
}
