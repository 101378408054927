<h1 mat-dialog-title>{{ headline }}</h1>
<mat-dialog-content>
    <div class=""
         *ngIf="!data.configuration || !hasFeatureFlag(FeatureFlagEnum.DashCam)">
        Dash Camera feature is not enabled.
    </div>

    <div class=""
         *ngIf="data.configuration && hasFeatureFlag(FeatureFlagEnum.DashCam)"
    >

        <app-row-with-toggle primaryText="DashCam Integration"
							 [(checked)]="ui.useCamera"
                             (checkedChange)="onCameraChange()">
        </app-row-with-toggle>

        <ng-container *ngIf="ui.useCamera">
          <div class="mt-10 list-item-divider"></div>
          <app-row-with-toggle primaryText="Rotate 180°"
							   [(checked)]="ui.cameraRotation"
                               (checkedChange)="onCameraRotationChange()">
          </app-row-with-toggle>

          <div class="mt-10 list-item-divider"></div>
          <app-row-with-toggle primaryText="Video capture to SD card"
							   [(checked)]="model.cameraConfiguration.localRecording.captureEnabled">
          </app-row-with-toggle>

          <app-row-with-toggle primaryText="Still image capture to SD card"
							   [(checked)]="ui.localImagesInterval"
                               (checkedChange)="onLocalImageChange()">
          </app-row-with-toggle>

          <div class="ml-25">
            <app-row-with-select primaryText="Capture interval"
                                 [disabled]="!ui?.localImagesInterval"
								 [(model)]="model.cameraConfiguration.localRecording.captureImageInterval"
                                 [list]="ui.captureImageIntervals"
                                 listId="id" listLabel="name">
            </app-row-with-select>
          </div>


          <div class="mt-10 list-item-divider"></div>
          <app-row-with-toggle primaryText="Allow live streaming"
							   [(checked)]="ui.streaming"
                               (checkedChange)="onStreamingChange()">
          </app-row-with-toggle>
          <div class="ml-25">
              <app-row-with-select primaryText="Duration (seconds)"
                                   [disabled]="!ui.streaming"
                                   [(model)]="model.cameraConfiguration.streaming.captureStreamDuration"
                                   [list]="ui.streamingDuration"
                                   listId="id" listLabel="name">
              </app-row-with-select>

              <app-row-with-toggle primaryText="Capture image with each observation"
                                   [disabled]="!ui.streaming"
								   [(checked)]="model.cameraConfiguration.streaming.observationCapture">
              </app-row-with-toggle>

              <app-row-with-toggle primaryText="Capture images on interval"
                                   [disabled]="!ui.streaming"
								   [(checked)]="ui.staticImagesInterval"
                                   (checkedChange)="onCamStreamingImageIntervalChange()">
              </app-row-with-toggle>

              <div class="ml-25">
                  <app-row-with-select primaryText="Capture interval"
                                       [disabled]="!ui.staticImagesInterval"
                                       [(model)]="model.cameraConfiguration.streaming.captureImageInterval"
                                       [list]="ui.captureImageIntervals" listId="id" listLabel="name">
                  </app-row-with-select>
              </div>
          </div>
        </ng-container>
    </div>

    <app-ui-blocking-layer *ngIf="isWorking"/>

    <mat-progress-bar mode="indeterminate" *ngIf="isWorking"
                      style="position: absolute;bottom: 0;left: 0;right: 0"></mat-progress-bar>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close [attr.cdkFocusInitial]="data.isImported === true ? 'true' : null"
            [disabled]="isWorking">
        Cancel
    </button>
	@if (data.configuration && hasFeatureFlag(FeatureFlagEnum.DashCam)) {
		<button mat-flat-button color="primary"
				(click)="save()"
				[disabled]="isWorking || !canBeSaved()">
			Update
		</button>
	}
</mat-dialog-actions>
