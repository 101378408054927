<div class="menu-wrapper">
  <ng-container *ngFor="let item of items">
    <mat-checkbox [checked]="isChecked(item)"
                  (change)="check($event, item)"
                  (click)="$event.stopPropagation()"
                  class=""
    >
      {{ item.label }}
    </mat-checkbox>
  </ng-container>
  <mat-divider></mat-divider>
  <div class="menu-footer">
    <button
      mat-button
      color="accent"
      class="ml-10"
      (click)="setFilter(null)"
    >
      Clear All
    </button>
    <button
      mat-button
      color="accent"
      class="mr-10"
      (click)="apply()"
    >
      Apply
    </button>
  </div>
</div>
