import { Component } from '@angular/core';

/**
 * An **invisible** overlay that cover the entire viewport and effectively disables any user interactions.
 */
@Component({
  selector: 'app-ui-blocking-layer',
  standalone: true,
  template: '<div ' +
      'style="position: fixed;left: 0;right: 0;top: 0;bottom: 0;background-color: #00000044;overscroll-behavior: contain;z-index:1"></div>',
})
export class UiBlockingLayerComponent {}
