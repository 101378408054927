import {Moment} from 'moment';

export class DateFilter {

  from: Moment;
  to: Moment;

  constructor(from: Moment,
              to: Moment) {
    this.from = from;
    this.to = to;
  }

  equals(dateFilter: DateFilter) {
    return (!!this.from && this.from.isSame(dateFilter.from)) ||
        (!this.from && !dateFilter);
  }
}

export class LabeledDateFilter extends DateFilter {
  label: string;

  constructor(
      label: string,
      from: Moment,
      to: Moment,
  ) {
    super(from, to);
    this.label = label;
  }
}

export class TimeRangeFilter {
  static readonly NONE  = new TimeRangeFilter('none', '---', 'All Dates');
  static readonly TODAY = new TimeRangeFilter('today', 'Today', 'Today');
  static readonly WEEK  = new TimeRangeFilter('week', 'This Week', 'This Week');
  static readonly MONTH  = new TimeRangeFilter('month', 'This Month', 'This Month');
  static readonly PAST_90_DAYS  = new TimeRangeFilter('past_90_days', 'Past 90 Days', 'Past 90 Days');
  static readonly CUSTOM  = new TimeRangeFilter('custom', 'Custom Date Range', 'Custom Date Range');

  // private to disallow creating other instances of this type
  private constructor(
      public readonly key: string,
      public readonly select: any,
      public readonly display: any,
  ) {
  }

  toString() {
    return this.key;
  }

  equals(filter: TimeRangeFilter) {
    return filter.key === this.key;
  }
}
