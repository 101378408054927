import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {DvirReportTableComponent} from '../dvir-report-table/dvir-report-table.component';
import {DvirReport} from '../../../../shared/models/dvir';
import {ReportsService} from '../../../../data/reports/reports.service';
import {DateFilter} from '../../../../shared/models/DateFilter';
import moment from 'moment';
import {ToastService} from '../../../../shared/services/toast.service';
import {saveAs} from 'file-saver-es';
import {MatDialog} from '@angular/material/dialog';
import {DvirReportPreviewDialogComponent} from './dialogs/dvir-report-dialogs.component';
import {MultiSelectFilter} from '../../../../shared/components/multi-select-component';
import {ActivatedRoute, Router} from '@angular/router';
import {CloseableAndFilterableInsight} from '../../closeable-filterable-insight.class';

@Component({
  selector: 'app-dvir-report',
  templateUrl: './dvir-report.component.html',
  styleUrls: ['./dvir-report.component.scss']
})
export class DvirReportComponent extends CloseableAndFilterableInsight implements AfterViewInit {

  @ViewChild(DvirReportTableComponent) reportTableComponent: DvirReportTableComponent;

  pageSize = 50;
  pageIndex = 0;
  totalElements = 0;

  dataSource: MatTableDataSource<DvirReport> = new MatTableDataSource([]);
  isLoading = false;
  isScrollLoading = false;
  dateFilter: DateFilter = undefined;
  vehicleGroupFilter: MultiSelectFilter<number> = undefined;

  constructor(
      private reportService: ReportsService,
      private toast: ToastService,
      protected router: Router,
      protected activatedRoute: ActivatedRoute,
      private dialog: MatDialog
  ) {
    super(router, activatedRoute);
  }

  ngAfterViewInit() {
    this.loadReport();
  }

  toggleLoading() {
    setTimeout(() => this.isLoading = !this.isLoading);
  }

  private filtersInitialized() {
    return this.vehicleGroupFilter !== undefined && this.dateFilter !== undefined;
  }

  dateFilterChanged(dateFilter: DateFilter) {
    this.dateFilter = dateFilter;
    this.loadReport();
  }

  vehicleGroupFilterChanged(vehicleGroupFilter: MultiSelectFilter<number>) {
    this.vehicleGroupFilter = vehicleGroupFilter;
    this.loadReport();
  }

  loadReport() {
    if (this.filtersInitialized() && !!this.reportTableComponent) {
      this.toggleLoading();
      this.pageIndex = 0;
      this.isScrollLoading = false;
      this.reportService.getDvirReport(
          !!this.dateFilter.from ? this.dateFilter.from : null,
          !!this.dateFilter.to ? this.dateFilter.to : null,
          this.vehicleGroupFilter,
          this.pageIndex,
          this.pageSize,
          this.reportTableComponent.getSort(),
      ).toPromise().then(response => {
        // console.log(`initialized, load count ${response.data.content.length} of ${response.data.totalElements}`);
        this.dataSource.data = response.data.content;
        this.totalElements = response.data.totalElements;
        this.pageSize = response.data.pageable.pageSize;
        this.toggleLoading();
      }).catch(error => {
        this.toggleLoading();
        this.toast.longer('Error while fetching initial data from the server');
        console.error(error);
      });
    }
  }

  extendReport() {
    if (!this.isScrollLoading && this.dataSource.data.length < this.totalElements) {
      this.pageIndex++;
      this.isScrollLoading = true;
      this.reportService.getDvirReport(
        !!this.dateFilter.from ? this.dateFilter.from : null,
        !!this.dateFilter.to ? this.dateFilter.to : null,
        this.vehicleGroupFilter,
        this.pageIndex,
        this.pageSize,
        this.reportTableComponent.getSort(),
      ).toPromise().then(response => {
        const extendedData = [...this.dataSource.data];
        extendedData.push(...response.data.content);
        // console.log(`extended, load count ${extendedData.length} of ${this.totalElements}`);
        this.dataSource.data = extendedData;

        this.isScrollLoading = false;
      }).catch(error => {
        this.toast.longer('Error while fetching more data from the server');
        console.error(error);
        this.isScrollLoading = false;
      });
    }
  }

  loadPDFExportFile() {
    if (this.filtersInitialized()) {
      this.reportService.getDvirPDF(
          null,
          !!this.dateFilter.from ? this.dateFilter.from : null,
          !!this.dateFilter.to ? this.dateFilter.to : null,
          this.vehicleGroupFilter,
      ).toPromise().then(response => {
        const blob = new Blob([response], { type: 'pdf' });
        const fromStr = !!this.dateFilter.from ? `_${this.dateFilter.from.format('MM-DD-YYYY')}` : '';
        const toStr = !!this.dateFilter.to ? this.dateFilter.to.format('MM-DD-YYYY') : `${moment().format('MM-DD-YYYY')}`;
        saveAs(blob, `Inspection_Report_${fromStr}_${toStr}.pdf`);
      }).catch(error => {
        this.toggleLoading();
        this.toast.longer('Error while fetching data from the server');
        console.error(error);
      });
    }
  }

  previewPDFExportFile() {
    if (this.filtersInitialized()) {
      const dialog = this.dialog.open(DvirReportPreviewDialogComponent,
        {
          maxWidth: '80vw',
          maxHeight: '90vh',
          data: {
            vehicleId: null,
            from: !!this.dateFilter.from ? this.dateFilter.from : null,
            to: !!this.dateFilter.to ? this.dateFilter.to : null,
            vehicleGroupFilter: this.vehicleGroupFilter
          }
        }
      );
      dialog.afterClosed().toPromise().then(result => {
        if (result) {
          this.loadPDFExportFile();
        }
      });
    }
  }
}
