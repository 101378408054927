import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {JsonApiResponse} from '../../shared/models/JsonApiResponse';
import {environment} from '../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {HttpErrorHandler} from '../../http.error.handler';
import {
    DataSourceModel,
    LayerModel,
    RouteConfiguration,
    RouteConfigurationWithSchema,
    TaskStatus
} from '../../shared/models/route';
import {FeatureCollection} from 'geojson';
import {GeobufResponseHandler} from '../../geobuf.response.handler';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoutesService {

    constructor(private http: HttpClient) { }

    getFeatureServiceDescription(url: string, username: string, password: string) {
    let params: HttpParams = new HttpParams()
        .set('url', encodeURIComponent(url));
    if (!!username) {
        params = params.set('username', username);
    }
    if (!!password) {
        params = params.set('password', password);
    }
    return this.http.get<JsonApiResponse<DataSourceModel>>(`${environment.services.service}v1/datasource/describe`, {params})
        .pipe(
            catchError(HttpErrorHandler.handleError)
        ).toPromise();
    }

    getFeatureServiceLayerDescription(url: string, layerId: number, username: string, password: string) {
    let params: HttpParams = new HttpParams()
        .set('url', encodeURIComponent(url));
    if (!!username) {
        params = params.set('username', username);
    }
    if (!!password) {
        params = params.set('password', password);
    }
    return this.http.get<JsonApiResponse<LayerModel>>(`${environment.services.service}v1/datasource/${layerId}/describe`, {params})
        .pipe(
            catchError(HttpErrorHandler.handleError)
        ).toPromise();
    }

    getFeatureTaskStatus(
        taskUuid: string,
    ) {
        return this.http.get<JsonApiResponse<TaskStatus>>(
            `${environment.services.service}v1/datasource/task-status/${taskUuid}`
        )
            .pipe(
                catchError(HttpErrorHandler.handleError)
            ).toPromise();
    }

    cleanFeatureTaskStatus(
        taskUuid: string,
    ) {
        return this.http.delete(
            `${environment.services.service}v1/datasource/task-status/${taskUuid}`
        )
            .pipe(
                catchError(HttpErrorHandler.handleError)
            ).toPromise();
    }

    // get task UUID
    readFeatureCollectionFromFeatureProxyAsync(
        url: string,
        layerId: number,
        orderedFields: string[],
        username: string,
        password: string,
    ) {
    let params: HttpParams = new HttpParams()
        .set('url', encodeURIComponent(url))
        .set('orderedFields', orderedFields.join(':::'));
    if (!!username) {
        params = params.set('username', username);
    }
    if (!!password) {
        params = params.set('password', password);
    }
    return this.http.get<JsonApiResponse<string>>(
        `${environment.services.service}v1/datasource/${layerId}/features`, {params}
        )
        .pipe(
            catchError(HttpErrorHandler.handleError)
        ).toPromise();
    }

    getRouteConfigurationFromFeatureProxy(
        taskUuid: string,
        routeConfigurationId: number,
        url: string,
        layerId: number,
        orderedFields: string[],
    ) {
        let params: HttpParams = new HttpParams()
            .set('url', encodeURIComponent(url))
            .set('orderedFields', orderedFields.join(':::'));
        if (routeConfigurationId !== null && routeConfigurationId !== undefined) {
            params = params.set('routeConfigurationId', routeConfigurationId);
        }
        return this.http.get<JsonApiResponse<RouteConfiguration>>(
            `${environment.services.service}v1/datasource/${layerId}/features/configuration/${taskUuid}`, {params}
        )
            .pipe(
                catchError(HttpErrorHandler.handleError)
            ).toPromise();
    }

    getRoutesGeoJsonFromExternalService(
        taskUuid: string,
        layerId: number,
        orderedFields: string[],
        routeConfiguration: RouteConfiguration,
    ) {
        const params: HttpParams = new HttpParams()
            .set('orderedFields', orderedFields.join(':::'));
        return this.http.post<JsonApiResponse<FeatureCollection>>(
            `${environment.services.service}v1/datasource/${layerId}/features/geojson/${taskUuid}`,
            routeConfiguration,
            { params }
        )
            .pipe(
                catchError(HttpErrorHandler.handleError)
            ).toPromise();
    }

    getRouteConfigurations() {
        return this.http.get<JsonApiResponse<RouteConfigurationWithSchema[]>>(
            `${environment.services.service}v1/route-management/configuration/list`, {}
        )
            .pipe(
                catchError(HttpErrorHandler.handleError)
            ).toPromise();
    }

    getRouteConfiguration(configId: number) {
        return this.http.get<JsonApiResponse<RouteConfigurationWithSchema>>(
            `${environment.services.service}v1/route-management/configuration/${configId}`, {}
        )
            .pipe(
                catchError(HttpErrorHandler.handleError)
            ).toPromise();
    }

    updateRouteConfiguration(routeConfiguration: RouteConfigurationWithSchema) {
        return firstValueFrom(this.http.post<JsonApiResponse<RouteConfigurationWithSchema>>(
            `${environment.services.service}v1/route-management/configuration`,
            routeConfiguration
        )
            .pipe(
                catchError(HttpErrorHandler.handleError)
            ));
    }

    getRouteGeoJson() {
        return this.http.get(
            `${environment.services.service}v1/route-management/allConfigurations/geometry`,
            {
                observe: 'response',
                responseType: 'arraybuffer',
                headers: {Accept: 'application/octet-stream'}
            }
        )
            .pipe(
                map( response => GeobufResponseHandler.handleResponse(response)),
                catchError(HttpErrorHandler.handleError) // then handle the error
            ).toPromise();
    }

    updateRouteGeoJson(taskUuid: string, configId: number, routeConfiguration: RouteConfigurationWithSchema) {
        return firstValueFrom(this.http.post(
            `${environment.services.service}v1/route-management/configuration/${configId}/geometry/${taskUuid}`,
            routeConfiguration
        )
            .pipe(
                catchError(HttpErrorHandler.handleError)
            ));
    }

    deleteRouteConfiguration(configId: number) {
        return firstValueFrom(this.http.delete(`${environment.services.service}v1/route-management/configuration/${configId}`)
            .pipe(
                catchError(HttpErrorHandler.handleError) // then handle the error
            ));
    }

    getRouteLength(configId: number, routeId: number) {
        return this.http.get<JsonApiResponse<number>>(
          `${environment.services.service}v1/route-management/configuration/${configId}/route/${routeId}/length`, {}
        )
          .pipe(
            catchError(HttpErrorHandler.handleError)
          ).toPromise();
    }
}
