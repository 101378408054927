import { Component, DoCheck, Inject, ViewChild } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import { NgIf } from '@angular/common';
import { UserManagementModule } from '../user-management.module';
import { MatButton } from '@angular/material/button';
import { EditUserComponent, UserEditMode } from '../edit-user/edit-user.component';
import { UserModelView } from '../../../../../shared/models/User';

@Component({
  selector: 'app-dialog-edit-user',
  templateUrl: 'dialog-edit-user.component.html',
  imports: [
    MatDialogTitle,
    NgIf,
    MatDialogContent,
    UserManagementModule,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    EditUserComponent
  ],
  standalone: true
})
export class DialogEditUserComponent implements DoCheck {

  @ViewChild('editUserComponent') editUserComponent: EditUserComponent;
  private isEditUserComponentReady = false;

  constructor(
    public dialogRef: MatDialogRef<DialogEditUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { mode: UserEditMode, user: UserModelView },
  ) {
  }

  ngDoCheck() {
    // To avoid ExpressionChangedAfterItHasBeenCheckedError in <button [disabled]="!isSaveButtonEnabled()">
    // we want to check the edit user component only
    // during the DoCheck phase.
    this.isEditUserComponentReady = this.editUserComponent !== undefined;
  }

  isModeCreate() {
    return this.data.mode === UserEditMode.CREATE;
  }

  isModeUpdate() {
    return this.data.mode === UserEditMode.UPDATE;
  }

  isModeSelfUpdate() {
    return this.data.mode === UserEditMode.SELF_UPDATE;
  }

  isSaveButtonEnabled() {
    return this.isEditUserComponentReady
      && this.editUserComponent.canBeSaved();
  }

  performSave() {
    this.editUserComponent.save()
      .then((result: any) => {
        this.dialogRef.close(result);
      })
      .catch((error) => {
        console.warn('User failed to save.', error);
      });
  }
}
