import { loadAnimations } from '../../animations/load-animations';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatTooltipDefaultOptions,
} from '@angular/material/tooltip';
import { ConfigurationModel, FeatureFlagEnum } from '../../models/configuration.model';
import { SecurityService } from '../../../security/security.service';
import { MatMenuTrigger } from '@angular/material/menu';
import {Router, RouterLinkActive} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {SearchResult, SearchResultType} from '../search-bar/search-bar.component';
import {AddressSuggestion, ArcgisApiService} from '../../../data/address-search/arcgis-api.service';
import {Asset} from '../../../pages/live-map/models/asset.class';
import {RouteHierarchyItemWithPath} from '../../models/route';
import {Poi} from '../../models/poi.model';
import {LocationInfo} from '../../models/AddressLookup';
import {MapControlService} from '../map-viewer/services/map-control.service';
import {AddressLookupMapMarkerService} from '../map-viewer/services/address-lookup-map-marker.service';
import { FetchUserAttributesOutput } from 'aws-amplify/auth';
import {MainRoute, RootRoute} from '../../models/angular-routing';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 1000,
};

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [loadAnimations.opacityLoadIn],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults },
  ],
})
export class NavigationComponent implements OnInit {

  @Input() configuration: ConfigurationModel;
  @Output() toggleMenu = new EventEmitter();
  @Output() darkMode = new EventEmitter();
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  userName: string;
  userData: FetchUserAttributesOutput;
  email: string;
  isCompact: boolean;

  dashboardVersion = environment.version;
  envName = environment.name;

  FeatureFlagEnum = FeatureFlagEnum;

  constructor(
    private securityService: SecurityService,
    private mapControlService: MapControlService,
    private arcGisApiService: ArcgisApiService,
    private addressLookupMapMarkerService: AddressLookupMapMarkerService,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
  ) {}

  ngOnInit(): void {
    this.securityService.getUserAttributes().then(response => {
      this.userData = response;
      this.email = this.userData.email;
      const givenName = this.userData.given_name;
      const familyName = this.userData.family_name;
      this.userName = `${givenName} ${familyName}`;
    });

    this.breakpointObserver
      .observe(['(max-width: 1100px)'])
      .subscribe((state: BreakpointState) => {
        this.isCompact = state.matches;
      });

    /*this.oidcSecurityService.isAuthenticated$.subscribe(
        ({ isAuthenticated }) => {
          this.securityService.getUserName().then(userName => {
            this.username = userName;
          });
        }
    );*/
  }

  hasFeatureFlag(featureFlag: string): boolean {
    return (
        this.configuration.featureFlags.find(
            (value) => value.isEnabled && value.name === featureFlag
        ) !== undefined
    );
  }

  logout() {
    this.securityService.logout();
  }

  // workaround, if directly accessed - variable not resolved
  isActive(rla: any) {
    return (rla as RouterLinkActive).isActive;
  }

  onSearchResult(searchResult: SearchResult) {
    switch (searchResult.type) {
      case SearchResultType.ADDRESS:
        const addressSuggestion = searchResult.result as AddressSuggestion;
        this.router.navigate([`/${RootRoute.MAIN}`, MainRoute.ADDRESS], {
          queryParams: {text: addressSuggestion.text, key: addressSuggestion.magicKey},
          queryParamsHandling: 'merge',
        });
        break;
      case SearchResultType.VEHICLE:
        this.router.navigate([`/${RootRoute.MAIN}`, MainRoute.VEHICLE, (searchResult.result as Asset).id],
          {queryParamsHandling: 'merge'},
        );
        break;
      case SearchResultType.ROUTE:
        const routeItem = searchResult.result as RouteHierarchyItemWithPath;
        this.router.navigate([`/${RootRoute.MAIN}`, MainRoute.ROUTE, routeItem.path.join(':::'), 'route-id', routeItem.routeId],
          {queryParamsHandling: 'merge'},
        );
        break;
      case SearchResultType.POI:
        this.onPoiSearch(searchResult.result as Poi);
        break;
      default:
        console.warn('Missing implementation for live map search!');
    }
  }

  todoHelp() {
    console.log('TODO open help page');
  }

  private onAddressSearch(addressSuggestion: AddressSuggestion) {
    this.arcGisApiService.findAddressBasedOnSuggestion(addressSuggestion.text, addressSuggestion.magicKey).then(response => {
      const address = LocationInfo.fromArcGisGeocodeResponse(response, 1);
      this.addressLookupMapMarkerService.addLocations([address]);
      this.mapControlService.zoomToCoordinates(address.geometry, 15);
    });
  }

  private onPoiSearch(poi: Poi) {
    const location = LocationInfo.fromPoi(poi);
    this.addressLookupMapMarkerService.addLocations([location]);
    this.mapControlService.zoomToCoordinates(location.geometry, 15);
  }
}
