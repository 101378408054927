import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Moment} from 'moment';
import {MultiSelectFilter} from '../../../../../shared/components/multi-select-component';
import {ReportsService} from '../../../../../data/reports/reports.service';


@Component({
  selector: 'app-dvir-report-preview',
  templateUrl: './dvir-report-preview.component.html',
  styles: [
    '.iframe { border: 0; width: 800px; height: calc(90vh - 73px); }',
    '.frame { overflow-y: hidden; overflow-x: auto; }',
  ]
})
export class DvirReportPreviewDialogComponent implements OnInit {

  @ViewChild('iframe') iframe: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<DvirReportPreviewDialogComponent>,
    private reportsService: ReportsService,
    @Inject(MAT_DIALOG_DATA) public data: {
      vehicleId: number,
      from: Moment,
      to: Moment,
      vehicleGroupFilter: MultiSelectFilter<number>
    }
  ) {
  }

  ngOnInit(): void {
    const params = (!!this.data.vehicleGroupFilter && !!this.data.vehicleGroupFilter.elements) ? this.data.vehicleGroupFilter : null;
    this.reportsService.getDvirHtml(this.data.vehicleId, this.data.from, this.data.to, params).subscribe(blob => {
      this.iframe.nativeElement.src = URL.createObjectURL(blob);
    });
  }

}
