<h1 mat-dialog-title>
    <ng-container *ngIf="isModeCreate()">Create a new User</ng-container>
    <ng-container *ngIf="isModeUpdate() || isModeSelfUpdate()">Update User</ng-container>
</h1>

<mat-dialog-content>
    <app-edit-user #editUserComponent [data]="data" />
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button mat-flat-button color="primary"
            [disabled]="!isSaveButtonEnabled()"
            (click)="performSave()">Save</button>
</mat-dialog-actions>
