export enum RootRoute {
    MAIN = 'main',
    SETTINGS = 'settings'
}

export enum MainRoute {
    VEHICLE = 'vehicle',
    ROUTE = 'route',
    ADDRESS = 'address',
    INSIGHTS = 'insights',
    ABOUT = 'about',
}
