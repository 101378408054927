import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError, retry} from 'rxjs/operators';
import {HttpErrorHandler} from '../../http.error.handler';
import {environment} from '../../../environments/environment';
import {JsonApiResponse} from '../../shared/models/JsonApiResponse';
import {VehicleBasic, VehicleModel, VehicleModelWithActiveShift, VehicleStats} from '../../shared/models/vehicle.model';
import {VehicleCategoryModel} from '../../shared/models/vehicle';
import {VehicleImport} from '../../shared/models/vehicle-import';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VehiclesService {
  constructor(private readonly http: HttpClient) {
  }

  // below are vehicle category services

  getVehicleCategories() {
    return firstValueFrom(this.http.get<JsonApiResponse<VehicleCategoryModel[]>>(`${environment.services.service}v1/vehicle/category/list`)
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(HttpErrorHandler.handleError) // then handle the error
      ));
  }

  deleteVehicleCategory(id: number) {
    return firstValueFrom(this.http.delete<JsonApiResponse<null>>(`${environment.services.service}v1/vehicle/category/${id}`)
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      ));
  }

  updateVehicleCategory(id: number, vehicleCategoryUpdate: VehicleCategoryModel): Promise<JsonApiResponse<VehicleCategoryModel>> {
    return firstValueFrom(this.http.put<JsonApiResponse<VehicleCategoryModel>>(`${environment.services.service}v1/vehicle/category/${id}`, vehicleCategoryUpdate)
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      ));
  }

  createVehicleCategory(vehicleCategory: VehicleCategoryModel): Promise<JsonApiResponse<VehicleCategoryModel>> {
    return firstValueFrom(this.http.post<JsonApiResponse<VehicleCategoryModel>>(`${environment.services.service}v1/vehicle/category`, vehicleCategory)
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      ));
  }

  // below are vehicle services

  getVehiclesWithActiveShift() {
    return this.http.get<JsonApiResponse<VehicleModelWithActiveShift[]>>(`${environment.services.service}v1/vehicle/list-extended`)
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(HttpErrorHandler.handleError) // then handle the error
      );
  }

  getVehicles() {
    return this.http.get<JsonApiResponse<VehicleModel[]>>(`${environment.services.service}v1/vehicle/list`)
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(HttpErrorHandler.handleError) // then handle the error
      );
  }

  getVehiclesBasic(from: Date = null, to: Date = null) {
    let params = new HttpParams();
    if (from) {
      params = params.set('shiftStartedFrom', from.toISOString());
    }
    if (to) {
      params = params.set('shiftEndedTo', to.toISOString());
    }
    return this.http.get<JsonApiResponse<VehicleBasic[]>>(`${environment.services.service}v1/vehicle/list-basic`, {params})
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(HttpErrorHandler.handleError) // then handle the error
      );
  }

  deleteVehicle(id: number) {
    return firstValueFrom(this.http.delete<JsonApiResponse<null>>(`${environment.services.service}v1/vehicle/${id}`)
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      ));
  }

  updateVehicle(id: number, vehicleToUpdate: VehicleModel): Promise<JsonApiResponse<VehicleModel>> {
    return firstValueFrom(
      this.http.put<JsonApiResponse<VehicleModel>>(`${environment.services.service}v1/vehicle/${id}`, vehicleToUpdate)
        .pipe(
          catchError(HttpErrorHandler.handleError) // then handle the error
        ));
  }

  createVehicle(vehicle: VehicleModel): Promise<JsonApiResponse<VehicleModel>> {
    return this.http.post<JsonApiResponse<VehicleModel>>(`${environment.services.service}v1/vehicle`, vehicle)
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      ).toPromise();
  }

  getVehicleStats(id: number, shiftId: number = null, from: Date = null, to: Date = null): Promise<JsonApiResponse<VehicleStats>> {
    let params = new HttpParams();
    if (shiftId != null) {
      params = params.set('shiftId', String(shiftId));
    }
    if (from) {
      params = params.set('from', from.toISOString());
    }
    if (to) {
      params = params.set('to', to.toISOString());
    }
    return this.http.get<JsonApiResponse<VehicleStats>>(`${environment.services.service}v1/vehicle/${id}/stats`, {params})
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(HttpErrorHandler.handleError) // then handle the error
      ).toPromise();
  }

  validateVehicleImport(formData: any): Promise<JsonApiResponse<VehicleImport[]>> {
    return this.http.post<JsonApiResponse<VehicleImport[]>>(`${environment.services.service}v1/vehicle/import/validate`, formData)
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      ).toPromise();
  }

  importVehicles(dataValidated: any) {
    return this.http.post<JsonApiResponse<any>>(`${environment.services.service}v1/vehicle/import`, dataValidated)
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      ).toPromise();
  }
}
