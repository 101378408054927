<div class="content-container">
	<div class="header">
		<div class="heading">
			<img src="assets/PlowOpsPortal_Logo.svg"
				 alt="PlowOps Logo">

			<img src="assets/Route_Builder.svg"
				 class="ml-10"
				 alt="Route Builder">
		</div>
		<div class="right-content">
			<button mat-icon-button [routerLink]="['/', 'settings', 'manage-routes']">
				<mat-icon>close</mat-icon>
			</button>
		</div>
	</div>
	<div class="content content-container">
		<div class="wizard-column">
			@if(wizard.loadingData | async) {
				<div class="p-15">
					<div class="text-muted">
						Processing Route Configuration Data
					</div>
					<mat-spinner diameter="30" class="mx-a mt-15">
					</mat-spinner>
				</div>
			}
			<mat-vertical-stepper [linear]="true" #stepper class="stepper"
								  [ngStyle]="{visibility: ((wizard.loadingData | async) ? 'hidden': 'inherit')}"
								  (selectionChange)="onStepperSelectionChange($event)">
				<mat-step [completed]="wizard.hasDataForStep1()">
					<div *matStepLabel>Data Source</div>
					<form [formGroup]="dataSourceFormGroup" class="pt-10">
						<mat-form-field class="w-100" appearance="outline" color="accent">
							<mat-label>Server URL</mat-label>
							<input matInput formControlName="serverUrl" required>
						</mat-form-field>

						<mat-form-field class="w-100" appearance="outline" color="accent">
							<mat-label>Username</mat-label>
							<input matInput formControlName="username">
						</mat-form-field>

						<mat-form-field class="w-100" appearance="outline" color="accent">
							<mat-label>Password</mat-label>
							<input matInput type="password" formControlName="password">
						</mat-form-field>
					</form>

					<div>
						@if (wizard.layerLoadingError) {
							<p class="ui-error">{{ wizard.layerLoadingError }}</p>
						}
						@if (wizard.loadingLayers | async) {
							<div class="text-muted">Connecting to the server</div>
							<mat-progress-bar mode="indeterminate"></mat-progress-bar>
						} @else {
							<button mat-stroked-button
									[disabled]="dataSourceFormGroup.invalid"
									(click)="step1LoadLayers()">
								Connect To The Map Server
							</button>
						}
					</div>
				</mat-step>

				<mat-step [completed]="wizard.hasDataForStep2()">
					<div *matStepLabel>Layer And Route Name Attribute</div>

					<div class="pt-10">
						<mat-form-field appearance="outline" color="accent" class="w-100">
							<mat-label>
								Route Layer
							</mat-label>
							<mat-select [(ngModel)]="wizard.selectedLayerId"
										(ngModelChange)="step2SelectedLayerChanged()"
										[disabled]="wizard.loadingFields | async"
										required>
								<ng-container *ngFor="let layer of wizard.layers">
									<mat-option [value]="layer.id">{{ layer.name }}</mat-option>
								</ng-container>
							</mat-select>
						</mat-form-field>

						@if (wizard.fields) {
							<mat-form-field appearance="outline" color="accent" class="w-100">
								<mat-label>
									Route Name Attribute
								</mat-label>
								<mat-select [(ngModel)]="wizard.nameField"
											(ngModelChange)="step1NameFieldChanged()"
											required>
									<ng-container *ngFor="let field of wizard.fields">
										<mat-option [value]="field.name">{{ field.name }}</mat-option>
									</ng-container>
								</mat-select>
							</mat-form-field>
						}

						@if ((wizard.loadingFields | async) || (wizard.loadingFeatureCollection | async)) {
							@if (wizard.loadingFields | async) {
								<div class="text-muted">Loading Fields In Layer</div>
							} @else {
								<div class="text-muted">Loading Routes</div>
							}
							<mat-progress-bar mode="indeterminate"
											  class="mb-10">
							</mat-progress-bar>
						}

						@if (wizard.fieldsLoadingError || wizard.featureCollectionLoadingError) {
							<p class="ui-error">{{ wizard.fieldsLoadingError || wizard.featureCollectionLoadingError }}</p>
						}

						<button mat-stroked-button
								[disabled]="(wizard.loadingFeatureCollection | async) || !wizard.nameField"
								(click)="step1LoadRoutes()">
							Get Routes
						</button>
					</div>
				</mat-step>
				<mat-step [completed]="wizard.configurationWithSchema">
					<div *matStepLabel>Route Selection And Grouping</div>

					<ng-template [ngTemplateOutlet]="step2"></ng-template>
				</mat-step>
				<mat-step [completed]="wizard.configurationWithSchema">
					<div *matStepLabel>Route Presentation</div>

					<ng-template *ngTemplateOutlet="step3"></ng-template>
				</mat-step>
				<mat-step [formGroup]="routeConfigurationSettingsFormGroup"
						  class="pt-10">
					<div *matStepLabel>Route Configuration Settings</div>

					<form [formGroup]="routeConfigurationSettingsFormGroup" class="mt-15">
						<mat-form-field class="w-100" appearance="outline" color="accent">
							<mat-label>Configuration Name</mat-label>
							<input matInput formControlName="configurationName" required>
						</mat-form-field>

						<mat-checkbox formControlName="keepUpToDate">Keep Up To Date</mat-checkbox>

						<mat-checkbox formControlName="exportToCustomMapLayers">Export To Custom Map Layers</mat-checkbox>
					</form>

					<div class="pt-10 buttons">
						<button mat-stroked-button
								(click)="deleteRouteConfiguration()">
							Delete Route Configuration
						</button>
						<button mat-stroked-button
								[disabled]="routeConfigurationSettingsFormGroup.invalid || (wizard.savingData | async)"
								(click)="saveChanges()">
							@if(wizard.routeConfigurationId) {
								Update Route Configuration
							} @else {
								Create Route Configuration
							}
						</button>
					</div>
				</mat-step>
			</mat-vertical-stepper>
		</div>
		<div class="map-container">
			<app-map-preview
					[nextContent]="mapContent"
			></app-map-preview>
		</div>
	</div>
</div>

<ng-template #step2>
	@if(wizard.categories.length) {
		<div class="categories-title">Grouped by</div>
		@for(category of wizard.categories; track category) {
			<div class="category">
				<div class="name">{{category}}</div>
				<button mat-icon-button class="actions"
						[disabled]="wizard.loadingFeatureCollection | async"
						matTooltip="Remove the group"
						(click)="removeCategory(category)">
					<mat-icon>delete</mat-icon>
				</button>
			</div>
		}
	} @else {
		<div class="pt-10"></div>
	}

	@if (wizard.loadingFeatureCollection | async) {
		<div class="text-muted">Loading Routes</div>
		<mat-progress-bar mode="indeterminate"></mat-progress-bar>
	} @else {
		<mat-form-field class="w-100" appearance="outline" color="accent">
			<mat-label>Select Attribute to Group By</mat-label>
			<mat-select (selectionChange)="onCategorySelected($event)"
						[disabled]="wizard.loadingFeatureCollection | async"
						matTooltip="Choose field to group routes by"
						[(value)]="groupBeingAdded">
				@for(field of wizard.fields; track field) {
					<mat-option [value]="field.name">{{ field.name }}</mat-option>
				}
			</mat-select>
		</mat-form-field>

		<app-route-tree
				[routeConfiguration]="wizard.routeConfiguration"
				[attributes]="wizard.attributes"
				[filteringMode]="true"
				(routeFiltered)="filterRoutes($event)"
				(styleChanged)="colorsOverrideChanged($event)"
				(routeHover)="onRouteHover($event)"
				(routeLeave)="onRouteLeave($event)">
		</app-route-tree>
	}

	<div class="pt-15">
		<button mat-stroked-button
				[disabled]="wizard.loadingFeatureCollection | async"
				(click)="nextStep()">
			Confirm Route Selection
		</button>
	</div>
</ng-template>

<ng-template #step3>
	<h3 class="pt-10">Default Route Style</h3>

	<app-route-style-editor [routeStyle]="wizard.defaultRouteStyle"
							(routeStyleChanged)="defaultRouteStyleChanged($event)">
	</app-route-style-editor>

	<app-expansion-panel panelTitle="Customize Individual Route Presentation"
						 [expanded]="false">
		@if (currentStep === 3) {
			<app-route-tree
					[routeConfiguration]="wizard.routeConfiguration"
					[attributes]="wizard.attributes"
					[filteringMode]="false"
					(styleChanged)="colorsOverrideChanged($event)"
					(routeHover)="onRouteHover($event)"
					(routeLeave)="onRouteLeave($event)">
			</app-route-tree>
		}
	</app-expansion-panel>
	<div class="pt-15">
		<button mat-stroked-button
				(click)="nextStep()">
			Confirm Route Presentation
		</button>
	</div>
</ng-template>
