import {Injectable} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivityFilter, SettingsService, StatusLayerType} from '../../../../configuration/settings.service';
import {ShiftsManagerService} from '../../../../data/shifts/shifts-manager.service';
import {ShiftModel, ShiftState} from '../../../models/shift.model';
import {MapLayersManager} from '../map-layers-manager';
import {
  LayerSpecification,
  LineLayerSpecification,
  SymbolLayerSpecification,
  VectorSourceSpecification,
  VectorTileSource
} from 'maplibre-gl';
import {MapStyles, VehicleColor} from '../../../../configuration/map-styles';
import {SecurityService} from '../../../../security/security.service';
import {LiveMapDataService} from '../../../../pages/live-map/services/live-map-data.service';
import {VehicleModelWithActiveShift} from '../../../models/vehicle.model';
import {MapFilters} from '../../../../configuration/map-filters';
import {ServerEventService} from '../../../../data/server-events/server-event.service';
import {TracksGeoJsonLayerService} from './tracks-geo-json-layer.service';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {AssetsManagerService} from '../../../../data/assets/assets-manager.service';
import {Asset} from '../../../../pages/live-map/models/asset.class';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {MainRoute} from '../../../models/angular-routing';
import { Hub } from 'aws-amplify/utils';
import { LocationApiService } from '../../../../data/location-api/location-api.service';

@Injectable({
  providedIn: 'root'
})
export class TracksVectorTilesLayerService {

  // normal mode layers
  static readonly TRACKS_SOURCE_ID = 'tracks-tiles';
  static readonly LAYER_ID_HISTORY = 'tracks-history-overlay';
  static readonly LAYER_ID_ACTIVE = 'tracks-overlay';

  // selected active shift mode layers
  static readonly LAYER_ID_TRACK_ACTIVE_SHIFT = 'track-tiles-active-shift';
  static readonly LAYER_ID_PLOW_DOWN_ACTIVE_SHIFT = 'track-tiles-active-shift-plow-down';
  static readonly LAYER_ID_SPREADER_ON_ACTIVE_SHIFT = 'track-tiles-active-shift-spreader-on';
  static readonly LAYER_ID_ARROWS_ACTIVE_SHIFT = 'track-tiles-active-shift-arrows';
  static readonly ICON_ARROW = 'arrow';

  private mapLayersManager: MapLayersManager;
  private lineLayers: LayerSpecification[] = [];

  private vehicleDetailMode = false;
  private highlightedShift: ShiftModel = null;
  private selectedVehicleIds = [];
  private activeShiftIds = [];
  private currentTheme: StatusLayerType = null;
  private hoursAgoFilter = 12;
  private showRecent = false;
  private customColors: VehicleColor[] = [];
  private flagsFilter: any[] = [];
  private isEnabled: boolean;
  private idToken: string;

  private readonly openSubscriptions = Array<Subscription>();

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private serverEventService: ServerEventService,
              private shiftsManagerService: ShiftsManagerService,
              private settingsService: SettingsService,
              private securityService: SecurityService,
              private assetManager: AssetsManagerService,
              private liveMapDataService: LiveMapDataService,
              private configurationService: ConfigurationService,
              private locationApi: LocationApiService,
  ) {
    Hub.listen('auth', ({ payload }) => {
      switch (payload.event) {
        case 'tokenRefresh':
          console.log('Auth tokens have been refreshed.');
          this.securityService.getIdToken()
              .then(idToken => {
                this.setIdToken(idToken);
                this.mapLayersManager?.setTransformRequest(this.idToken);
              })
              .catch(error => {
                console.error(error);
              });
          break;
      }
    });
  }

  init(mapLayersManager: MapLayersManager, isEnabled: boolean) {
    if (!!this.mapLayersManager) {
      throw Error('The mapLayersManager has already been set yet.');
    }

    this.mapLayersManager = mapLayersManager;
    this.isEnabled = isEnabled;
    this.addSourceAndLayers();
    this.connectToManager();
  }

  release() {
    if (!this.mapLayersManager) {
      throw Error('The mapLayersManager has not been set yet.');
    }

    for (const subscription of this.openSubscriptions) {
      subscription.unsubscribe();
    }
    this.openSubscriptions.length = 0;
    this.lineLayers = [];
    this.mapLayersManager = null;
  }

  addSourceAndLayers() {
    this.lineLayers = [];

    this.securityService.getIdToken().then(idToken => {
      this.setIdToken(idToken);
      this.mapLayersManager.setTransformRequest(this.idToken);

      // add source
      this.addSource();

      // add layers
      this.currentTheme = this.settingsService.getStringValue(SettingsService.TRACKS_LAYER_TYPE_KEY) as StatusLayerType;
      const isVisible = this.isEnabled ? 'visible' : 'none';
      this.getLineLayers().forEach(layer => {
        layer.layout.visibility = isVisible;
        this.lineLayers.push(layer);
        this.mapLayersManager.addLayer(layer as LineLayerSpecification | SymbolLayerSpecification);
        this.mapLayersManager.moveLayer(layer.id, TracksGeoJsonLayerService.LAYER_ID_TRACK_GEOJSON);
      });
    }).catch(error => {
      console.error(error);
    });
  }

  private handleHighlightedShiftChanged(shift?: ShiftModel) {
    this.highlightedShift = shift;
    if (this.lineLayers.length > 0) {
      this.updateFiltersAndStyles();
    }
  }

  private handleTimeFilterChanged(timeFilter: number) {
    this.hoursAgoFilter = timeFilter;
    this.updateFiltersAndStyles();
  }

  private handleShowRecentChanged(showRecent: boolean) {
    this.showRecent = showRecent;
    this.updateFiltersAndStyles();
  }

  private handleTrackFilterChanged(trackFilter: ActivityFilter) {
    this.flagsFilter = MapFilters.getTrackFilter(trackFilter);
    this.updateFiltersAndStyles();
  }

  private handleCustomVehicleMapColor(vehicles: VehicleModelWithActiveShift[]) {
    // update custom colors
    this.customColors = vehicles
        .filter(vehicle => !!vehicle.mapColor)
        .map(vehicle => {
          return {id: vehicle.id, color: vehicle.mapColor};
        });
    this.updateFiltersAndStyles();
  }

  private handleVehiclesChanged(assets: Asset[]) {
    const activeShiftIds = [];
    this.selectedVehicleIds = assets.map(asset => {
      if (asset.shiftStatus !== ShiftState.ENDED && !!asset.shiftId) {
        activeShiftIds.push(asset.shiftId);
      }
      return asset.id;
    });
    this.activeShiftIds = activeShiftIds;
    this.updateFiltersAndStyles();
  }

  private connectToManager() {
    const that = this;

    this.vehicleDetailMode =
        !!this.activatedRoute.firstChild?.firstChild?.firstChild?.firstChild?.snapshot?.routeConfig?.path?.startsWith(MainRoute.VEHICLE + '/:id');

    const changesSubscription = this.settingsService.settingsChangedObservable.subscribe({
      next(newSettings) {
        if (newSettings.key === SettingsService.TRACKS_LAYER_TYPE_KEY) {
          that.currentTheme = newSettings.value;
          that.updateFiltersAndStyles();
        }
        if (newSettings.key === SettingsService.PLOW_TRACK_TIME_FILTER) {
          that.handleTimeFilterChanged(Number(newSettings.value));
        }
        if (newSettings.key === SettingsService.ACTIVITY_FILTER) {
          that.handleTrackFilterChanged(newSettings.value);
        }
        if (newSettings.key === SettingsService.PLOW_TRACK_SHOW_RECENT) {
          that.handleShowRecentChanged(newSettings.value);
        }
      }
    });
    this.openSubscriptions.push(changesSubscription);

    const routerSubscription = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        const oldIsVehicleMode = this.vehicleDetailMode;
        this.vehicleDetailMode =
            !!this.activatedRoute.firstChild?.firstChild?.firstChild?.firstChild?.snapshot?.routeConfig?.path?.startsWith(MainRoute.VEHICLE + '/:id');

        // if any change
        if (oldIsVehicleMode !== this.vehicleDetailMode) {
          this.updateFiltersAndStyles();
        }
      }
    });
    this.openSubscriptions.push(routerSubscription);

    const allVehiclesSubscription = this.liveMapDataService.vehicles$.subscribe(vehicles => {
      this.handleCustomVehicleMapColor(vehicles);
    });
    this.openSubscriptions.push(allVehiclesSubscription);

    const assetsSubscription = this.assetManager.filteredAssets$.subscribe(assets => {
      this.handleVehiclesChanged(assets);
    });
    this.openSubscriptions.push(assetsSubscription);

    const highlightedShiftSubscription = this.shiftsManagerService.highlightedShift$.subscribe({
      next(shiftFilterUpdate) {
        that.handleHighlightedShiftChanged(shiftFilterUpdate.shift);
      }
    });
    this.openSubscriptions.push(highlightedShiftSubscription);

    const tileCacheChangeSubscription = this.serverEventService.tilesCacheUpdateObservable.subscribe({
      next(/*cacheUpdate*/) {
        that.updateSource();
      }
    });
    this.openSubscriptions.push(tileCacheChangeSubscription);
  }

  private setIdToken(token: string) {
    if (this.idToken === token) {
      console.warn('setting token is same as original!');
    }
    this.idToken = token;
  }

  private getSource(): VectorSourceSpecification {
    return {
      id: TracksVectorTilesLayerService.TRACKS_SOURCE_ID,
      type: 'vector',
      maxzoom: 14,
      tiles: [
        this.locationApi.getLocationHistoryVectorTileUrl()
      ]
    } as VectorSourceSpecification;
  }

  private addSource() {
    if (!!this.mapLayersManager) {
      this.mapLayersManager.addSource(TracksVectorTilesLayerService.TRACKS_SOURCE_ID, this.getSource());
    }
  }

  private updateSource() {
    if (!!this.mapLayersManager) {
      const source = this.mapLayersManager.getVectorSource(TracksVectorTilesLayerService.TRACKS_SOURCE_ID);
      if (!!source) {
        (source as VectorTileSource).setTiles([this.locationApi.getLocationHistoryVectorTileUrl()]);
      }
    }
  }

  getLineLayers(): LayerSpecification[] {
    const activeShiftIds = this.activeShiftIds;
    const selectedVehicleIds = this.selectedVehicleIds;
    const highlightedShift = this.highlightedShift;
    return [
      {
        id: TracksVectorTilesLayerService.LAYER_ID_HISTORY,
        type: 'line',
        source: TracksVectorTilesLayerService.TRACKS_SOURCE_ID,
        'source-layer': 'track',
        filter: !this.vehicleDetailMode && MapFilters.getInactiveShiftsFilter(
            this.currentTheme === StatusLayerType.GPS_TRACKS,
            this.vehicleDetailMode,
            activeShiftIds,
            selectedVehicleIds,
            this.hoursAgoFilter,
            this.flagsFilter,
            this.showRecent,
        ),
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': this.configurationService.trackStyles.liveMapPlowInactive.color, /* always inactive, cannot be highlighted */
          'line-opacity': this.configurationService.trackStyles.liveMapPlowInactive.opacity,
          'line-width': this.configurationService.trackStyles.liveMapPlowInactive.width,
        }
      } as LineLayerSpecification,
      {
        id: TracksVectorTilesLayerService.LAYER_ID_ACTIVE,
        type: 'line',
        source: TracksVectorTilesLayerService.TRACKS_SOURCE_ID,
        'source-layer': 'track',
        filter: !this.vehicleDetailMode && MapFilters.getActiveShiftsFilter(
            this.currentTheme === StatusLayerType.GPS_TRACKS,
            this.vehicleDetailMode,
            activeShiftIds,
            selectedVehicleIds,
            this.hoursAgoFilter,
            this.flagsFilter,
            highlightedShift,
        ),
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': this.getHighlightedColor(),
          'line-opacity': this.configurationService.trackStyles.liveMapPlowLive.opacity,
          'line-width': this.getHighlightedWidth(),
        }
      } as LineLayerSpecification,
      // below selected shift layers
      {
        id: TracksVectorTilesLayerService.LAYER_ID_TRACK_ACTIVE_SHIFT,
        type: 'line',
        source: TracksVectorTilesLayerService.TRACKS_SOURCE_ID,
        'source-layer': 'track',
        filter: this.vehicleDetailMode && MapFilters.getShiftGeoJsonFilter(
            ActivityFilter.NONE, null, null, this.activeShiftIds,
        ),
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': this.configurationService.trackStyles.shiftPlowNormal.color,
          'line-opacity': this.configurationService.trackStyles.shiftPlowNormal.opacity,
          'line-width': this.configurationService.trackStyles.shiftPlowNormal.width,
        }
      } as LineLayerSpecification,
      {
        id: TracksVectorTilesLayerService.LAYER_ID_PLOW_DOWN_ACTIVE_SHIFT,
        type: 'line',
        source: TracksVectorTilesLayerService.TRACKS_SOURCE_ID,
        'source-layer': 'track',
        filter: this.vehicleDetailMode && MapFilters.getShiftGeoJsonFilter(
            ActivityFilter.PLOWING_MOWING_SWEEPING, null, null, this.activeShiftIds,
        ),
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': this.configurationService.trackStyles.shiftPlowPlowDown.color,
          'line-opacity': this.configurationService.trackStyles.shiftPlowPlowDown.opacity,
          'line-width': this.configurationService.trackStyles.shiftPlowPlowDown.width,
          'line-offset': this.configurationService.trackStyles.shiftPlowPlowDown.offset,
        }
      } as LineLayerSpecification,
      {
        id: TracksVectorTilesLayerService.LAYER_ID_SPREADER_ON_ACTIVE_SHIFT,
        type: 'line',
        source: TracksVectorTilesLayerService.TRACKS_SOURCE_ID,
        'source-layer': 'track',
        filter: this.vehicleDetailMode && MapFilters.getShiftGeoJsonFilter(
            ActivityFilter.SPREADING, null, null, this.activeShiftIds,
        ),
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': this.configurationService.trackStyles.shiftPlowSpreaderOn.color,
          'line-opacity': this.configurationService.trackStyles.shiftPlowSpreaderOn.opacity,
          'line-width': this.configurationService.trackStyles.shiftPlowSpreaderOn.width,
          'line-offset': this.configurationService.trackStyles.shiftPlowSpreaderOn.offset,
        }
      } as LineLayerSpecification,
      {
        id: TracksVectorTilesLayerService.LAYER_ID_ARROWS_ACTIVE_SHIFT,
        type: 'symbol',
        source: TracksVectorTilesLayerService.TRACKS_SOURCE_ID,
        'source-layer': 'track',
        filter: this.vehicleDetailMode && MapFilters.getShiftGeoJsonFilter(
            ActivityFilter.NONE, null, null, this.activeShiftIds,
        ),
        layout: {
          'symbol-placement': 'line',
          'icon-image': TracksVectorTilesLayerService.ICON_ARROW,
          'icon-rotate': 90,
          'icon-rotation-alignment': 'map',
          'icon-allow-overlap': true,
          'icon-ignore-placement': true
        },
        paint: {
        }
      } as SymbolLayerSpecification,
    ];
  }

  updateFiltersAndStyles() {
    const activeShiftIds = this.activeShiftIds;
    const selectedVehicleIds = this.selectedVehicleIds;
    const highlightedShift = this.highlightedShift;
    this.lineLayers.forEach(lineLayer => {
      switch (lineLayer.id) {
        case TracksVectorTilesLayerService.LAYER_ID_HISTORY:
          this.mapLayersManager.setFilter(
              lineLayer.id,
              !this.vehicleDetailMode && MapFilters.getInactiveShiftsFilter(
                this.currentTheme === StatusLayerType.GPS_TRACKS,
                  this.vehicleDetailMode,
                activeShiftIds,
                selectedVehicleIds,
                this.hoursAgoFilter,
                this.flagsFilter,
                this.showRecent,
              )
          );
          this.mapLayersManager.setLayerPaintProperty(
            lineLayer.id,
            'line-color',
              this.configurationService.trackStyles.liveMapPlowInactive.color,
          );
          this.mapLayersManager.setLayerPaintProperty(
              lineLayer.id,
              'line-width',
              this.configurationService.trackStyles.liveMapPlowInactive.width,
          );
          break;
        case TracksVectorTilesLayerService.LAYER_ID_ACTIVE:
          this.mapLayersManager.setFilter(
              lineLayer.id,
              !this.vehicleDetailMode && MapFilters.getActiveShiftsFilter(
              this.currentTheme === StatusLayerType.GPS_TRACKS,
              this.vehicleDetailMode,
              activeShiftIds,
              selectedVehicleIds,
              this.hoursAgoFilter,
              this.flagsFilter,
              highlightedShift,
              )
          );
          this.mapLayersManager.setLayerPaintProperty(
            lineLayer.id,
            'line-color',
            this.getHighlightedColor(),
          );
          this.mapLayersManager.setLayerPaintProperty(
              lineLayer.id,
              'line-width',
              this.getHighlightedWidth(),
          );
          break;
        case TracksVectorTilesLayerService.LAYER_ID_TRACK_ACTIVE_SHIFT:
          this.mapLayersManager.setFilter(
              lineLayer.id,
              this.vehicleDetailMode && MapFilters.getShiftGeoJsonFilter(
                  ActivityFilter.NONE, null, null, this.activeShiftIds,
              ),
          );
          break;
        case TracksVectorTilesLayerService.LAYER_ID_PLOW_DOWN_ACTIVE_SHIFT:
          this.mapLayersManager.setFilter(
              lineLayer.id,
              this.vehicleDetailMode && MapFilters.getShiftGeoJsonFilter(
                  ActivityFilter.PLOWING_MOWING_SWEEPING, null, null, this.activeShiftIds,
              ),
          );
          break;
        case TracksVectorTilesLayerService.LAYER_ID_SPREADER_ON_ACTIVE_SHIFT:
          this.mapLayersManager.setFilter(
              lineLayer.id,
              this.vehicleDetailMode && MapFilters.getShiftGeoJsonFilter(
                  ActivityFilter.SPREADING, null, null, this.activeShiftIds,
              ),
          );
          break;
        case TracksVectorTilesLayerService.LAYER_ID_ARROWS_ACTIVE_SHIFT:
          this.mapLayersManager.setFilter(
              lineLayer.id,
              this.vehicleDetailMode && MapFilters.getShiftGeoJsonFilter(
                  ActivityFilter.NONE, null, null, this.activeShiftIds,
              ),
          );
          break;
      }
    });
  }

  private getHighlightedColor() {
    return !this.vehicleDetailMode && this.currentTheme === StatusLayerType.GPS_TRACKS
        ? MapStyles.getHighlightedColor(
            this.highlightedShift?.id !== undefined ? [this.highlightedShift.id] : null,
            this.configurationService.trackStyles.liveMapPlowHighlighted.color,
            this.configurationService.trackStyles.liveMapPlowLive.color,
            this.customColors,
        )
        : MapStyles.getHighlightedColor(
            this.highlightedShift?.id !== undefined ? [this.highlightedShift.id] : null,
            '#A0A0A0',
            this.configurationService.trackStyles.liveMapPlowLive.color,
            this.customColors,
        );
  }

  private getHighlightedWidth() {
    return !this.vehicleDetailMode && this.currentTheme === StatusLayerType.GPS_TRACKS
        ? MapStyles.getHighlightedProperty(
            this.highlightedShift?.id !== undefined ? [this.highlightedShift.id] : null,
            this.configurationService.trackStyles.liveMapPlowHighlighted.width,
            this.configurationService.trackStyles.liveMapPlowLive.width,
        )
        : MapStyles.getHighlightedProperty(
            this.highlightedShift?.id !== undefined ? [this.highlightedShift.id] : null,
            this.configurationService.trackStyles.liveMapPlowLive.width,
            this.configurationService.trackStyles.liveMapPlowLive.width,
        );
  }
}
