import { MapContent } from '../MapContent';
import { Map, MapMouseEvent, Popup } from 'maplibre-gl';
import { MapContentSource } from '../MapContentSource';
import { ShiftTrackLayer } from './ShiftTrackLayer';
import { ShiftTrackArrowsLayer } from './ShiftTrackArrowsLayer';
import { Feature, FeatureCollection, Position } from 'geojson';
import { TrackStyles } from '../../../../../configuration/model/TrackStyles';
import { ShiftMarkerFlagLayer } from './ShiftMarkerFlagLayer';
import { ShiftMarkerCircleLayer } from './ShiftMarkerCircleLayer';
import { ShiftMarkerShadowLayer } from './ShiftMarkerShadowLayer';
import { ShiftWithDriverAndVehicleModel } from '../../../../models/shift.model';
import { ShiftTrackPlowDownLayer } from './ShiftTrackPlowDownLayer';
import { ShiftTrackSpreadingLayer } from './ShiftTrackSpreadingLayer';
import { MapTools } from '../../../../tools/MapTools';
import { ShiftPlaybackLayer } from './ShiftPlaybackLayer';
import { PointFeature, PointGeometry } from '../../../../models/GeoJson';
import { Subject } from 'rxjs';
import { LocationSearchParams } from '../../../../models/breadcrumb.model';
import { MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { ToastService } from '../../../../services/toast.service';
import { Vehicle, VehicleGroup } from '../../../../models/vehicle';
import { VehicleBreadcrumb } from '../../../../models/vehicle-breadcrumb';
import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injector, Type } from '@angular/core';
import {
  BreadcrumbInfoWindowContentComponent
} from '../../../map-viewer/breadcrumb-info-window-content/breadcrumb-info-window-content.component';
import { ConfigurationModel } from '../../../../models/configuration.model';
import { ObservationShadowLayer } from './ObservationShadowLayer';
import { ObservationCircleLayer } from './ObservationCircleLayer';
import { ObservationIconLayer } from './ObservationIconLayer';
import { ObservationLabelLayer } from './ObservationLabelLayer';
import { ImageShadowLayer } from './ImageShadowLayer';
import { ImageCircleLayer } from './ImageCircleLayer';
import { ImageIconLayer } from './ImageIconLayer';
import { Observation } from '../../../../models/observation';
import {
  ObservationInfoWindowContentComponent
} from '../../../map-viewer/observation-info-window-content/observation-info-window-content.component';
import { ShiftMapDataService } from '../../../../../pages/shift-map/services/shift-map-data.service';
import { HighlightedItemSource } from '../../../../models/highlighted-item-update';
import { LocationApiService } from '../../../../../data/location-api/location-api.service';

export class ShiftMapContent implements MapContent {

  private map: Map = null;
  private tracksSource: MapContentSource = new MapContentSource('shift-tracks-source');
  private shiftTrackLayer: ShiftTrackLayer;
  private shiftTrackPlowDownLayer: ShiftTrackPlowDownLayer;
  private shiftTrackSpreadingLayer: ShiftTrackSpreadingLayer;
  private shiftTrackArrowsLayer: ShiftTrackArrowsLayer;

  private readonly shiftTrackClickListener: any;
  private searchSnackbar: MatSnackBarRef<SimpleSnackBar> = null;
  private lastComponentRef: ComponentRef<any>;

  private shiftMarkersSource: MapContentSource = new MapContentSource('shift-markers-source');
  private shiftMarkerShadowLayer: ShiftMarkerShadowLayer;
  private shiftMarkerCircleLayer: ShiftMarkerCircleLayer;
  private shiftMarkerFlagLayer: ShiftMarkerFlagLayer;

  private animationSource: MapContentSource = new MapContentSource('shift-playback-source');
  private shiftPlaybackLayer: ShiftPlaybackLayer;

  private observationsSource: MapContentSource = new MapContentSource('shift-observations-source');
  private observationShadowLayer: ObservationShadowLayer;
  private observationCircleLayer: ObservationCircleLayer;
  private observationIconLayer: ObservationIconLayer;
  private observationLabelLayer: ObservationLabelLayer;

  private readonly observationClickListener: any;
  private lastPopup: Popup;

  private imagesSource: MapContentSource = new MapContentSource('shift-images-source');
  private imageShadowLayer: ImageShadowLayer;
  private imageCircleLayer: ImageCircleLayer;
  private imageIconLayer: ImageIconLayer;

  private readonly imageClickListener: any;

  // animation properties
  private allShiftPoints: Feature[];
  private coordinates: Position[] = [];
  private shiftTimeOffset = 0;
  private shiftTimeLength = 0;

  private speedFactor = 10; // 1 = normal time, 10 = 10x faster
  private animation; // to store and cancel the animation
  private startTime = 0;
  private progress = 0; // progress = timestamp - startTime
  private resetTime = false; // indicator of whether time reset is needed for the animation
  private latestIndex = 0;

  readonly animationProgressObservable = new Subject<number>();

  constructor(
    shiftTrackFeatureCollection: FeatureCollection,
    shiftMarkersFeatureCollection: FeatureCollection,
    observationFeatureCollection: FeatureCollection,
    imageFeatureCollection: FeatureCollection,
    private configuration: ConfigurationModel,
    trackStyles: TrackStyles,
    private observations: Observation[],
    private shift: ShiftWithDriverAndVehicleModel,
    private locationApi: LocationApiService,
    private toast: ToastService,
    private injector: Injector,
    private resolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private document: Document,
    private shiftMapDataManager: ShiftMapDataService,
  ) {
    // shift track
    this.tracksSource.loadFromFeatureCollection(shiftTrackFeatureCollection);
    this.shiftTrackLayer = new ShiftTrackLayer(this.tracksSource, trackStyles, shift);
    this.shiftTrackPlowDownLayer = new ShiftTrackPlowDownLayer(this.tracksSource, trackStyles, shift);
    this.shiftTrackSpreadingLayer = new ShiftTrackSpreadingLayer(this.tracksSource, trackStyles, shift);
    this.shiftTrackArrowsLayer = new ShiftTrackArrowsLayer(this.tracksSource, shift);

    this.shiftTrackClickListener = (e) => {
      if (MapTools.checkPropagation(e)){
        return;
      }
      this.shiftTrackClicked(e);
    };

    // shift markers - start/end
    this.shiftMarkersSource.loadFromFeatureCollection(shiftMarkersFeatureCollection);
    this.shiftMarkerShadowLayer = new ShiftMarkerShadowLayer(this.shiftMarkersSource);
    this.shiftMarkerCircleLayer = new ShiftMarkerCircleLayer(this.shiftMarkersSource);
    this.shiftMarkerFlagLayer = new ShiftMarkerFlagLayer(this.shiftMarkersSource);

    // shift playback
    this.shiftPlaybackLayer = new ShiftPlaybackLayer(this.animationSource, trackStyles);

    // shift observations
    this.observationsSource.loadFromFeatureCollection(observationFeatureCollection);
    this.observationShadowLayer = new ObservationShadowLayer(this.observationsSource);
    this.observationCircleLayer = new ObservationCircleLayer(this.observationsSource);
    this.observationIconLayer = new ObservationIconLayer(this.observationsSource);
    this.observationLabelLayer = new ObservationLabelLayer(this.observationsSource);

    this.observationClickListener = (e) => {
      if (MapTools.checkPropagation(e)){
        return;
      }
      const pointFeature = e.features[0] as PointFeature;
      this.shiftMapDataManager.sendHighlightedObservationUpdate(
        pointFeature.properties.id,
        HighlightedItemSource.MAP,
      );
    };
    this.shiftMapDataManager.highlightedObservation$.subscribe(update => {
      if (!!update.itemId) {
        this.observationClicked(update.itemId, update.source);
      }
    });

    // shift images
    this.imagesSource.loadFromFeatureCollection(imageFeatureCollection);
    this.imageShadowLayer = new ImageShadowLayer(this.imagesSource);
    this.imageCircleLayer = new ImageCircleLayer(this.imagesSource);
    this.imageIconLayer = new ImageIconLayer(this.imagesSource);

    this.imageClickListener = (e) => {
      if (MapTools.checkPropagation(e)){
        return;
      }
      const pointFeature = e.features[0] as PointFeature;
      this.shiftMapDataManager.sendHighlightedImageUpdate(
        pointFeature.properties.id,
        HighlightedItemSource.MAP,
      );
    };
    this.shiftMapDataManager.highlightedImage$.subscribe(update => {
      if (!!update.itemId) {
        this.imageClicked(update.itemId, update.source);
      }
    });
  }

  load(map: Map) {
    this.map = map;

    // shift track
    this.tracksSource.updateMap(this.map);
    this.map.addLayer(this.shiftTrackLayer.toLayerSpecification());
    this.map.addLayer(this.shiftTrackArrowsLayer.toLayerSpecification());
    this.map.addLayer(this.shiftTrackPlowDownLayer.toLayerSpecification());
    this.map.addLayer(this.shiftTrackSpreadingLayer.toLayerSpecification());
    MapTools.pointCursorForLayer(this.map, this.shiftTrackLayer, true);
    this.map.on('click', this.shiftTrackLayer.layerId, this.shiftTrackClickListener);

    // shift markers - start/end
    this.shiftMarkersSource.updateMap(this.map);
    this.map.addLayer(this.shiftMarkerShadowLayer.toLayerSpecification());
    this.map.addLayer(this.shiftMarkerCircleLayer.toLayerSpecification());
    this.map.addLayer(this.shiftMarkerFlagLayer.toLayerSpecification());

    // shift playback
    this.animationSource.updateMap(this.map);
    this.map.addLayer(this.shiftPlaybackLayer.toLayerSpecification());

    // shift observations
    this.observationsSource.updateMap(this.map);
    this.map.addLayer(this.observationShadowLayer.toLayerSpecification());
    this.map.addLayer(this.observationCircleLayer.toLayerSpecification());
    this.map.addLayer(this.observationIconLayer.toLayerSpecification());
    this.map.addLayer(this.observationLabelLayer.toLayerSpecification());
    MapTools.pointCursorForLayer(this.map, this.observationCircleLayer, true);
    this.map.on('click', this.observationCircleLayer.layerId, this.observationClickListener);

    // shift images
    this.imagesSource.updateMap(this.map);
    this.map.addLayer(this.imageShadowLayer.toLayerSpecification());
    this.map.addLayer(this.imageCircleLayer.toLayerSpecification());
    this.map.addLayer(this.imageIconLayer.toLayerSpecification());
    MapTools.pointCursorForLayer(this.map, this.imageCircleLayer, true);
    this.map.on('click', this.imageCircleLayer.layerId, this.imageClickListener);

    this.shiftTrackLayer.zoomToFeatures(this.map);
  }

  unload() {
    MapTools.pointCursorForLayer(this.map, this.imageCircleLayer, false);
    this.map.off('click', this.imageCircleLayer.layerId, this.imageClickListener);
    this.map.removeLayer(this.imageIconLayer.layerId);
    this.map.removeLayer(this.imageCircleLayer.layerId);
    this.map.removeLayer(this.imageShadowLayer.layerId);
    this.map.removeSource(this.imagesSource.sourceId);

    MapTools.pointCursorForLayer(this.map, this.observationCircleLayer, false);
    this.map.off('click', this.observationCircleLayer.layerId, this.observationClickListener);
    this.map.removeLayer(this.observationLabelLayer.layerId);
    this.map.removeLayer(this.observationIconLayer.layerId);
    this.map.removeLayer(this.observationCircleLayer.layerId);
    this.map.removeLayer(this.observationShadowLayer.layerId);
    this.map.removeSource(this.observationsSource.sourceId);

    MapTools.pointCursorForLayer(this.map, this.shiftTrackLayer, false);
    this.map.off('click', this.shiftTrackLayer.layerId, this.shiftTrackClickListener);
    this.map.removeLayer(this.shiftTrackLayer.layerId);
    this.map.removeLayer(this.shiftTrackPlowDownLayer.layerId);
    this.map.removeLayer(this.shiftTrackSpreadingLayer.layerId);
    this.map.removeLayer(this.shiftTrackArrowsLayer.layerId);
    this.map.removeSource(this.tracksSource.sourceId);

    this.map.removeLayer(this.shiftMarkerFlagLayer.layerId);
    this.map.removeLayer(this.shiftMarkerCircleLayer.layerId);
    this.map.removeLayer(this.shiftMarkerShadowLayer.layerId);
    this.map.removeSource(this.shiftMarkersSource.sourceId);

    this.map.removeLayer(this.shiftPlaybackLayer.layerId);
    this.map.removeSource(this.animationSource.sourceId);
    this.map = null;
  }

  private shiftTrackClicked(event: MapMouseEvent) {
    this.searchBreadcrumb(event);
  }

  private observationClicked(observationId: number, source: HighlightedItemSource) {
    this.observationsSource.features.forEach(feature => {
      feature.properties.highlighted = feature.properties.id === observationId;
    });
    this.observationsSource.updateMap(this.map);
    const observation = this.observations.find(o => o.id === observationId);
    if (!!observation) {
      this.showObservationInfo(observation);
    }
  }

  private imageClicked(imageId: number, source: HighlightedItemSource) {
    let pointFeature: PointFeature;
    this.imagesSource.features.forEach(feature => {
      feature.properties.highlighted = feature.properties.id === imageId;
      if (feature.properties.id === imageId) {
        pointFeature = feature as PointFeature;
      }
    });
    this.imagesSource.updateMap(this.map);
    if (source === HighlightedItemSource.PANEL) {
      MapTools.zoomToPointFeature(this.map, pointFeature, 16);
    }
  }

  filterByTime(timeFrom: number, timeTo: number) {
    MapTools.onMap(this.map, () => {
      this.shiftTrackLayer.filterByTime(this.map, timeFrom, timeTo);
      this.shiftTrackPlowDownLayer.filterByTime(this.map, timeFrom, timeTo);
      this.shiftTrackSpreadingLayer.filterByTime(this.map, timeFrom, timeTo);
      this.shiftTrackArrowsLayer.filterByTime(this.map, timeFrom, timeTo);
    });
  }

  changeAnimationSpeed(speedFactor: number) {
    this.speedFactor = speedFactor;
  }

  private updateAnimationSource() {
    this.animationSource.setLineString(this.coordinates);
    this.animationSource.updateMap(this.map);
  }

  loadDataIfNeededAndPlay(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.shift) {
        if (!this.allShiftPoints) {
          this.locationApi.getShiftPoints(this.shift.id, this.shift.vehicleId).then(featureCollection => {
            const points = featureCollection.features;
            this.allShiftPoints = points;
            if (points.length > 0) {
              this.shiftTimeOffset = points[0].properties['unixtime'];
              this.shiftTimeLength = points[points.length - 1].properties['unixtime'] - this.shiftTimeOffset;
            }
            this.playAnimation();
            resolve(true);
          }).catch(error => reject(error));
        } else {
          // using existing points
          this.playAnimation();
          resolve(true);
        }
      } else {
        resolve(false);
      }
    });
  }

  getShiftTimeOffset(): number {
    if (!!this.shift) {
      return this.shiftTimeOffset;
    } else {
      console.warn('Incorrect usage! Shift Playback service not initialized!');
      return 0;
    }
  }

  playAnimation() {
    console.log('playing animation');
    this.startTime = performance.now();
    this.resetTime = true;
    this.animateLine(this.startTime);
  }

  // DOMHighResTimeStamp parameter
  private animateLine(timestamp) {
    if (this.resetTime) {
      // resume previous progress
      this.startTime = timestamp - this.progress;
      this.resetTime = false;
    } else {
      this.progress = timestamp - this.startTime;
    }

    if (this.progress / 1000 * this.speedFactor > this.shiftTimeLength) {
      console.log('Shift end has been reached.');
      return;
    }

    const calcNextPointTime = this.progress / 1000 * this.speedFactor;
    const nextPointTime = this.allShiftPoints[this.latestIndex].properties['unixtime'] - this.shiftTimeOffset;
    if (nextPointTime < calcNextPointTime) {
      // use the latest point and move index
      this.coordinates.push((this.allShiftPoints[this.latestIndex].geometry as PointGeometry).coordinates);
      // this.animationSource.
      this.updateAnimationSource();
      this.latestIndex = this.latestIndex + 1;
      this.animationProgressObservable.next(nextPointTime);
    } else {
      // keeping index and interpolate
      if (this.latestIndex > 0) {
        const from = this.allShiftPoints[this.latestIndex - 1];
        const fromLng = (from.geometry as PointGeometry).coordinates[0];
        const fromLat = (from.geometry as PointGeometry).coordinates[1];
        const fromTime = from.properties['unixtime'] - this.shiftTimeOffset;
        const to = this.allShiftPoints[this.latestIndex];
        const toLng = (to.geometry as PointGeometry).coordinates[0];
        const toLat = (to.geometry as PointGeometry).coordinates[1];
        const toTime = to.properties['unixtime'] - this.shiftTimeOffset;
        const timeDiff = toTime - fromTime;
        const timeBetweenPoints = calcNextPointTime - fromTime;
        const pctBetweenPoints = timeBetweenPoints / timeDiff;
        const interpolatedLng = fromLng + (toLng - fromLng) * pctBetweenPoints;
        const interpolatedLat = fromLat + (toLat - fromLat) * pctBetweenPoints;
        const interpolated = [interpolatedLng, interpolatedLat];
        this.coordinates.push(interpolated);
        this.updateAnimationSource();
        this.animationProgressObservable.next(fromTime + timeBetweenPoints);
      }
    }

    // Request the next frame of the animation.
    this.animation = requestAnimationFrame(time => this.animateLine(time));
  }

  pauseAnimation() {
    console.log('pausing animation...');
    cancelAnimationFrame(this.animation);
  }

  stopAnimation() {
    console.log('stopping animation...');
    this.latestIndex = 0;
    this.progress = 0;
    cancelAnimationFrame(this.animation);
    this.coordinates = [];
    this.updateAnimationSource();
  }

  jumpToPosition(newProgress: number) {
    console.log('jumping to position: ' + newProgress);
    this.animationProgressObservable.next(newProgress);
    this.progress = newProgress / this.speedFactor * 1000;
    this.coordinates = [];
    let index = 0;
    for (const pointFeature of this.allShiftPoints) {
      const pointTime = pointFeature.properties['unixtime'] - this.shiftTimeOffset;
      if (pointTime > newProgress) {
        this.coordinates.push(...this.allShiftPoints.slice(0, index).map((feature) => {
          return [
            (feature.geometry as PointGeometry).coordinates[0],
            (feature.geometry as PointGeometry).coordinates[1]
          ];
        }));
        this.latestIndex = index;
        this.updateAnimationSource();
        break;
      }
      index += 1;
    }
  }

  private searchBreadcrumb(event: MapMouseEvent) {
    // credits to https://gis.stackexchange.com/questions/7430/what-ratio-scales-do-google-maps-zoom-levels-correspond-to
    const metersPerPx = 156543.03392 * Math.cos(event.lngLat.lat * Math.PI / 180) / Math.pow(2, this.map.getZoom());

    const hoursCount = 12;
    const searchParams = {
      coordinate: {
        latitude: event.lngLat.lat,
        longitude: event.lngLat.lng
      },
      timeFrom: this.shift
        ? Math.floor((new Date(this.shift.start)).valueOf() / 1000)
        : Math.floor((new Date().valueOf() - hoursCount * 60 * 60 * 1000) / 1000),
      timeTo: this.shift ? Math.floor((new Date(this.shift.end)).valueOf() / 1000) : undefined,
      radius: Math.min(metersPerPx * 15, 5000), // toleration 15 pixels. maximum 5km because of ws limitation
      locationSources: [this.shift.vehicleId],
      sessions: [this.shift.id],
      includeInbox: false,
    } as LocationSearchParams;

    this.showSearchSnackBar('Searching for nearest breadcrumbs...');
    this.locationApi.search(searchParams).toPromise().then(response => {
      const features = response.features;
      if (features.length === 0) {
        this.showSearchSnackBar('No breadcrumbs found.');
      } else {
        this.selectBreadcrumbs(features as PointFeature[]);
        this.hideSearchSnackBar();
      }
    }).catch(error => {
      console.error('Breadcrumbs search failed.', error);
      this.showSearchSnackBar('Breadcrumbs search failed.');
    });
  }

  private selectBreadcrumbs(breadcrumbs: Array<PointFeature>) {
    const vehicleBreadcrumbs = breadcrumbs.map((breadcrumb) => {
      const vehicle = new Vehicle(
        this.shift.vehicle.id,
        new VehicleGroup(this.shift.vehicle.groupId, String(this.shift.vehicle.groupId)),
        !!this.shift.vehicle.label ? this.shift.vehicle.label : this.shift.vehicle.name,
        this.shift.vehicle.licensePlate,
        this.shift.vehicleHardwareConfiguration,
        this.shift.vehicle.cameraConfiguration,
      );

      const location = {
        id: breadcrumb.properties.id,
        coords: {
          lat: breadcrumb.geometry.coordinates[1],
          lng: breadcrumb.geometry.coordinates[0]
        },
        time: new Date(breadcrumb.properties.unixtime * 1000),
        speed: breadcrumb.properties.speed,
        heading: breadcrumb.properties.heading,
        imageUrl: null,
        flags: breadcrumb.properties.flags,
        gpsSource: breadcrumb.properties.gpssource,
      };
      return new VehicleBreadcrumb(vehicle, null, location, breadcrumb.properties.sessionkey);
    });

    this.showBreadcrumbInfo(vehicleBreadcrumbs);
  }

  private instantiateComponent<T>(componentType: Type<T>): ComponentRef<T> {
    const compFactory = this.resolver.resolveComponentFactory(componentType);
    const newComponentRef = compFactory.create(this.injector);

    this.appRef.attachView(newComponentRef.hostView);
    this.lastComponentRef = newComponentRef;

    return newComponentRef;
  }

  private showBreadcrumbInfo(breadcrumbs: Array<VehicleBreadcrumb>) {
    const infoComponentRef = this.instantiateComponent(BreadcrumbInfoWindowContentComponent);
    infoComponentRef.instance.breadcrumbs = breadcrumbs;
    infoComponentRef.instance.isLiveMap = false;
    infoComponentRef.instance.configuration = this.configuration;

    const div = document.createElement('div');
    div.appendChild(this.lastComponentRef.location.nativeElement);

    const coordinates = breadcrumbs[0].breadcrumb.coords;

    const popup = new Popup({offset: [0, -15], className: 'marker-popup', maxWidth: '300px'})
      .setLngLat([coordinates.lng, coordinates.lat])
      .setDOMContent(div)
      .addTo(this.map);

    // adjust position of the popup if selection next or previous
    infoComponentRef.instance.visibleBreadcrumbChanged.subscribe((vehicleBreadcrumb) => {
      popup.setLngLat([vehicleBreadcrumb.breadcrumb.coords.lng, vehicleBreadcrumb.breadcrumb.coords.lat]);
    });
  }

  private showObservationInfo(observation: Observation) {
    this.lastPopup?.remove();
    const infoComponentRef = this.instantiateComponent(ObservationInfoWindowContentComponent);
    infoComponentRef.instance.observation = observation;
    infoComponentRef.instance.isLiveMap = false;

    const div = document.createElement('div');
    div.appendChild(this.lastComponentRef.location.nativeElement);

    this.lastPopup = new Popup({offset: [0, -15], className: 'marker-popup', maxWidth: '300px'})
      .setLngLat([observation.location.coords.lng, observation.location.coords.lat])
      .setDOMContent(div)
      .addTo(this.map);
  }

  private hideSearchSnackBar() {
    this.searchSnackbar?.dismiss();
    this.searchSnackbar = null;
  }

  private showSearchSnackBar(message: string) {
    this.hideSearchSnackBar();
    this.searchSnackbar = this.toast.short(message);
  }
}
