import {VehiclesService} from '../../../../../data/vehicles/vehicles.service';
import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {NgModel} from '@angular/forms';
import {AddOrImportVehicleDialogForm, AddOrImportVehicleResult, EditVehicleComponentInputData} from './vehicle.types';


@Component({
  selector: 'app-create-vehicle',
  templateUrl: './create-vehicle.component.html',
  styleUrls: ['../dialogs-vehicle/dialog-components.scss', '../../../settings-fields.scss'],
})
export class CreateVehicleComponent implements OnChanges, AddOrImportVehicleDialogForm {
  // todo, not extend AddOrImportVehicleDialogForm
  headline = 'Add Vehicle';

  @Input() data: EditVehicleComponentInputData;
  @ViewChild('name') ngName: NgModel;
  isWorking = false;

  constructor(
    private readonly vehicleService: VehiclesService,
  ) {
  }

  canBeSaved(): boolean {
    return this.ngName.valid;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('data')) {
      if (changes.data.firstChange) {
        this.data.model.hasNoTablet = false;
        this.data.model.active = true;
        // this.initFromData(this.data);
      }
    }
  }

  async save(): Promise<AddOrImportVehicleResult> {
    const result = await this.vehicleService.createVehicle(this.data.model);
    return {
      type: 'singleVehicle',
      data: result.data,
    };
  }

}

