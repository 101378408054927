
export class NamedId {
    id: any;
    name: string;

    constructor(id: any, name: string) {
        this.id = id;
        this.name = name;
    }
}

export class ObjectWithId {
    id: any;
}
