import {Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {catchError, retry} from 'rxjs/operators';
import {HttpErrorHandler} from '../../http.error.handler';
import {Driver, DriverImport, DriverModel, DriverStats, DriverWithShiftCount} from '../../shared/models/driver.model';
import {JsonApiResponse} from '../../shared/models/JsonApiResponse';
import {Page} from '../../shared/models/Page';

export interface IUpdateDriverModel {
  id: number;
  name: string;
  email?: string;
}

@Injectable({
  providedIn: 'root'
})
export class DriversService {

  constructor(private http: HttpClient) {
  }

  getDriverList(from: Date = null, to: Date = null) {
    let params = new HttpParams();
    if (from) {
      params = params.set('shiftStartedFrom', from.toISOString());
    }
    if (to) {
      params = params.set('shiftEndedTo', to.toISOString());
    }
    return this.http.get<JsonApiResponse<DriverWithShiftCount[]>>(`${environment.services.service}v1/driver/all`, {params})
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(HttpErrorHandler.handleError) // then handle the error
      );
  }

  addDriver(driverName: string): Promise<JsonApiResponse<DriverModel>> {
    const newDriver = {
      name: driverName
    };
    return this.http.post<JsonApiResponse<DriverModel>>(`${environment.services.service}v1/driver`, newDriver)
      .pipe(
        catchError(HttpErrorHandler.handleError)
      )
      .toPromise();
  }

  deleteDriver<T>(driverId: number): Promise<JsonApiResponse<T>> {
    return this.http.delete<JsonApiResponse<T>>(`${environment.services.service}v1/driver/${encodeURIComponent(driverId.toString())}`)
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      ).toPromise();
  }

  updateDriver(driver: IUpdateDriverModel) {
    return this.http.put(`${environment.services.service}v1/driver/${encodeURIComponent(driver.id.toString())}`, {
      name: driver.name,
      email: driver.email,
    })
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      );
  }

  getDriver(driverId: number) {
    return this.http.get<JsonApiResponse<DriverModel>>
    (`${environment.services.service}v1/driver/${driverId}`)
      .pipe(
        catchError(HttpErrorHandler.handleError) // then handle the error
      );
  }

  getDriverStats(id: number, from: Date = null, to: Date = null): Promise<JsonApiResponse<DriverStats>> {
    let params = new HttpParams();
    if (from) {
      params = params.set('from', from.toISOString());
    }
    if (to) {
      params = params.set('to', to.toISOString());
    }
    return this.http.get<JsonApiResponse<DriverStats>>(`${environment.services.service}v1/driver/${id}/stats`, {params})
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(HttpErrorHandler.handleError) // then handle the error
      ).toPromise();
  }

}
