<div class="config-header">
    <h2 mat-dialog-title>Modify GPS Tracks</h2>

    <div class="config-header-action">
        <button mat-icon-button
                [matTooltip]="'Reset to defaults'"
                (click)="reset()"
                [disabled]="isDefault()"
        >
            <mat-icon>restart_alt</mat-icon>
        </button>
    </div>
</div>

<mat-dialog-content class="mat-typography">

    <div class="config-line">
        <div class="config-label-wrapper">
            <div class="config-label">
                Live Track Duration
            </div>
            <div class="config-label">
                How long a GPS track will stay on the map
            </div>
        </div>

        <div class="config-form" *ngIf="!!configuration">
            <mat-form-field appearance="outline" color="accent">
                <mat-select [(ngModel)]="data.timeLimit" placeholder="" panelWidth="150">
                    <mat-option *ngFor="let hour of hours" [value]="hour">{{ hour }} hour<span *ngIf="hour > 1">s</span></mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="config-line">
        <div class="config-label-wrapper">
            <div class="config-label">
                Recently Ended Shifts
            </div>
            <div class="config-label">
                Display shifts that have ended within the Live Track Duration
            </div>
        </div>

        <div class="config-form">
            <mat-slide-toggle
                    [hideIcon]="true"
                    class="mat-small"
                    [(ngModel)]="data.showRecent"
            ></mat-slide-toggle>
        </div>
    </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button color="accent" [mat-dialog-close]="data" cdkFocusInitial>Save</button>
</mat-dialog-actions>
