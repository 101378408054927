import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonApiResponse } from '../../shared/models/JsonApiResponse';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { HttpErrorHandler } from '../../http.error.handler';
import { AssignmentStatistics, ShiftStatistics } from '../../shared/models/insights.model';

@Injectable({
  providedIn: 'root'
})
export class InsightsService {

  constructor(private http: HttpClient) { }

  findShiftStatistics(
    sinceHours: number,
  ) {
    const params = {};
    params['sinceHours'] = sinceHours;

    return this.http.get<JsonApiResponse<ShiftStatistics>>(
      `${environment.services.service}v1/insights/shift`, {params}
    ).pipe(catchError(HttpErrorHandler.handleError));
  }

  findAssignmentStatistics(
    sinceHours: number,
  ) {
    const params = {};
    params['sinceHours'] = sinceHours;

    return this.http.get<JsonApiResponse<AssignmentStatistics>>(
      `${environment.services.service}v1/insights/assignment`, {params}
    ).pipe(catchError(HttpErrorHandler.handleError));
  }
}
