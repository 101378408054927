import { Injectable } from '@angular/core';
import {Subscription} from 'rxjs';
import {Map, GeoJSONSource, MapMouseEvent, LngLat} from 'maplibre-gl';
import {PointFeature} from '../../../models/GeoJson';
import {ObservationMapMarkerService} from './observation-map-marker.service';
import {VehicleMapMarkerService} from './vehicle-map-marker.service';
import {VehiclesManagerService} from '../../../../data/vehicles/vehicles-manager.service';
import {ObservationsManagerService} from '../../../../data/observations/observations-manager.service';
import {ImagesManagerService} from '../../../../data/images/images-manager.service';
import {MatSnackBarRef, SimpleSnackBar} from '@angular/material/snack-bar';
import {BreadcrumbsManagerService} from '../../../../data/breadcrumbs/breadcrumbs-manager.service';
import {TracksVectorTilesLayerService} from './tracks-vector-tiles-layer.service';
import {PopupInfoService} from './popup-info.service';
import {TracksGeoJsonLayerService} from './tracks-geo-json-layer.service';
import {VehicleBreadcrumb} from '../../../models/vehicle-breadcrumb';
import {LocationSearchParams} from '../../../models/breadcrumb.model';
import {RoadStatusCoverageLayerService} from './road-status-coverage-layer.service';
import {RoadStatusCurrencyLayerService} from './road-status-currency-layer.service';
import {WeatherWarningsLayerService} from './weather-warnings-layer.service';
import {ToastService} from '../../../services/toast.service';
import { AssetsManagerService } from 'src/app/data/assets/assets-manager.service';
import {Asset} from '../../../../pages/live-map/models/asset.class';
import {Router} from '@angular/router';
import {MapControlService} from './map-control.service';
import {ImageMapMarkerService} from './image-map-marker.service';
import {TrafficLayerService} from './traffic-layer.service';
import {MainRoute, RootRoute} from '../../../models/angular-routing';
import {RoutesLayerService} from './routes-layer.service';
import { HighlightedItemSource } from '../../../models/highlighted-item-update';
import { LocationApiService } from '../../../../data/location-api/location-api.service';

@Injectable({
  providedIn: 'root'
})
export class MapEventService {

  private map: Map;
  private openBreadcrumbSearchSubscription: Subscription = null;
  private vehiclesFilterSubscription: Subscription = null;
  private searchSnackbar: MatSnackBarRef<SimpleSnackBar> = null;
  private vehicleIdsFilter: number[] = null;

  constructor(private assetManager: AssetsManagerService,
              private vehiclesManager: VehiclesManagerService,
              private locationApi: LocationApiService,
              private breadcrumbManagerService: BreadcrumbsManagerService,
              private observationsManagerService: ObservationsManagerService,
              private imagesManagerService: ImagesManagerService,
              private markerInfoWindowService: PopupInfoService,
              private routesLayerService: RoutesLayerService,
              private weatherWarningsService: WeatherWarningsLayerService,
              private trafficLayerService: TrafficLayerService,
              private mapControlService: MapControlService,
              private router: Router,
              private toast: ToastService) { }

  init(map: Map) {
    if (!!this.map) {
      throw Error('The map has already been set. Only a single map instance is supported.');
    }

    this.map = map;
    this.attachOnClickHandlers();

    this.vehiclesFilterSubscription = this.assetManager.filteredAssets$.subscribe(assets => {
      this.handleAssetsChanged(assets);
    });
  }

  release() {
    if (!this.map) {
      throw Error('The map has not been set.');
    }

    this.cancelOpenBreadcrumbsSearch();
    if (this.vehiclesFilterSubscription) {
      this.vehiclesFilterSubscription.unsubscribe();
    }

    this.vehicleIdsFilter = null;
    this.map = null;
  }

  private async onClusteredPointClick(e: MapMouseEvent, clusterLayerId: string, sourceId: string) {
    const features = this.map.queryRenderedFeatures(e.point, {
      layers: [clusterLayerId]
    });
    const clusterId = features[0].properties.cluster_id;
    const zoom = await (this.map.getSource(sourceId) as GeoJSONSource).getClusterExpansionZoom(clusterId);
    // @ts-ignore
    const point = features[0] as PointFeature;
    this.map.easeTo({
      center: [point.geometry.coordinates[0], point.geometry.coordinates[1]],
      zoom,
      essential: true
    });
  }

  attachOnClickHandlers() {
    const that = this;
    // on observation point click handler
    this.map.on('click', ObservationMapMarkerService.LAYER_ID_OBSERVATIONS_CIRCLE, (e) => {
      if (this.checkPropagation(e)){
        return;
      }
      // @ts-ignore
      const pointFeature = e.features[0] as PointFeature;
      this.observationsManagerService.highlightObservation(pointFeature.properties.id, ObservationsManagerService.MAP_SOURCE);
    });
    this.showPointCursorForLayer(ObservationMapMarkerService.LAYER_ID_OBSERVATIONS_CIRCLE);

    // on clustered observations click handler
    /*this.map.on('click', ObservationMapMarkerService.LAYER_ID_OBSERVATION_CLUSTERS, (e) => {
      if (this.checkPropagation(e)){
        return;
      }
      that.onClusteredPointClick(e,
        ObservationMapMarkerService.LAYER_ID_OBSERVATION_CLUSTERS,
        ObservationMapMarkerService.OBSERVATION_SOURCE_ID
      );
    });
    this.showPointCursorForLayer(ObservationMapMarkerService.LAYER_ID_OBSERVATION_CLUSTERS);*/

    // on vehicle point click handler
    this.map.on('click', VehicleMapMarkerService.LAYER_ID_VEHICLES_CIRCLE, (e) => {
      // console.log('vehicle marker click');
      if (this.checkPropagation(e)){
        return;
      }
      // @ts-ignore
      const pointFeature = e.features[0] as PointFeature;
      this.router.navigate([`/${RootRoute.MAIN}`, MainRoute.VEHICLE, pointFeature.properties.id], {
        queryParams: {
          expandVehicleGroup: true,
        },
        queryParamsHandling: 'merge',
      });
    });
    this.showPointCursorForLayer(VehicleMapMarkerService.LAYER_ID_VEHICLES_CIRCLE);

    // on vehicle label click handler
    this.map.on('click', VehicleMapMarkerService.LAYER_ID_VEHICLES_LABEL, (e) => {
      // console.log('vehicle marker click');
      if (this.checkPropagation(e)){
        return;
      }
      // @ts-ignore
      const pointFeature = e.features[0] as PointFeature;
      this.router.navigate([`/${RootRoute.MAIN}`, MainRoute.VEHICLE, pointFeature.properties.id], {
        queryParams: {
          expandVehicleGroup: true,
        },
        queryParamsHandling: 'merge',
      });
    });
    this.showPointCursorForLayer(VehicleMapMarkerService.LAYER_ID_VEHICLES_LABEL);

    // on clustered vehicles click handler
    /*this.map.on('click', VehicleMapMarkerService.LAYER_ID_VEHICLE_CLUSTER_CIRCLE, (e) => {
      if (this.checkPropagation(e)){
        return;
      }
      that.onClusteredPointClick(e,
        VehicleMapMarkerService.LAYER_ID_VEHICLE_CLUSTER_CIRCLE,
        VehicleMapMarkerService.VEHICLE_SOURCE_ID
      );
    });
    this.showPointCursorForLayer(VehicleMapMarkerService.LAYER_ID_VEHICLE_CLUSTER_CIRCLE);*/

    // on image point click handler
    this.map.on('click', ImageMapMarkerService.LAYER_ID_IMAGES_CIRCLE, (e) => {
      if (this.checkPropagation(e)){
        return;
      }
      // @ts-ignore
      const pointFeature = e.features[0] as PointFeature;
      this.imagesManagerService.highlightImage(pointFeature.properties.id, HighlightedItemSource.MAP);
    });
    this.showPointCursorForLayer(ImageMapMarkerService.LAYER_ID_IMAGES_CIRCLE);

    // on clustered images click handler
    /*this.map.on('click', ImageMapMarkerService.LAYER_ID_IMAGE_CLUSTERS, (e) => {
      if (this.checkPropagation(e)){
        return;
      }
      that.onClusteredPointClick(e,
        ImageMapMarkerService.LAYER_ID_IMAGE_CLUSTERS,
        ImageMapMarkerService.IMAGE_SOURCE_ID
      );
    });
    this.showPointCursorForLayer(ImageMapMarkerService.LAYER_ID_IMAGE_CLUSTERS);*/

    // road status layers
    for (const i of [1, 2, 3, 4, 5]) {
      that.map.on('click', `${RoadStatusCoverageLayerService.LAYER_ID_PREFIX}-${i}`, (e) => {
        this.handleRoadStatusLayerClick(e);
      });
      this.showPointCursorForLayer(`${RoadStatusCoverageLayerService.LAYER_ID_PREFIX}-${i}`);

      that.map.on('click', `${RoadStatusCurrencyLayerService.LAYER_ID_PREFIX}-${i}`, (e) => {
        this.handleRoadStatusLayerClick(e);
      });
      this.showPointCursorForLayer(`${RoadStatusCurrencyLayerService.LAYER_ID_PREFIX}-${i}`);
    }

    // on breadcrumb search
    that.map.on('click', TracksVectorTilesLayerService.LAYER_ID_ACTIVE, (e) => {
      that.handleBreadCrumbSearchClick(e);
    });
    this.showPointCursorForLayer(TracksVectorTilesLayerService.LAYER_ID_ACTIVE);
    that.map.on('click', TracksVectorTilesLayerService.LAYER_ID_HISTORY, (e) => {
      that.handleBreadCrumbSearchClick(e);
    });
    this.showPointCursorForLayer(TracksVectorTilesLayerService.LAYER_ID_HISTORY);
    that.map.on('click', TracksGeoJsonLayerService.LAYER_ID_TRACK_GEOJSON, (e) => {
      that.handleBreadCrumbSearchClick(e, true);
    });
    this.showPointCursorForLayer(TracksGeoJsonLayerService.LAYER_ID_TRACK_GEOJSON);
    that.map.on('click', TracksGeoJsonLayerService.LAYER_ID_TRACK_HISTORY_GEOJSON, (e) => {
      that.handleBreadCrumbSearchClick(e, true);
    });
    this.showPointCursorForLayer(TracksGeoJsonLayerService.LAYER_ID_TRACK_HISTORY_GEOJSON);
    that.map.on('click', TracksVectorTilesLayerService.LAYER_ID_TRACK_ACTIVE_SHIFT, (e) => {
      that.handleBreadCrumbSearchClick(e, true);
    });
    this.showPointCursorForLayer(TracksVectorTilesLayerService.LAYER_ID_TRACK_ACTIVE_SHIFT);
    that.map.on('click', TracksGeoJsonLayerService.LAYER_ID_TRACK_ACTIVE_SHIFT, (e) => {
      that.handleBreadCrumbSearchClick(e, true);
    });
    this.showPointCursorForLayer(TracksGeoJsonLayerService.LAYER_ID_TRACK_ACTIVE_SHIFT);

    // on route click
    this.routesLayerService.getRoutes()?.forEach(route => {
      const routeLayerId = RoutesLayerService.getRouteLayerId(route);
      this.map.on('click', routeLayerId, (e) => {
        if (this.checkPropagation(e)){
          return;
        }
        // @ts-ignore
        const pointFeature = e.features[0] as PointFeature;
        const routePath = !!route.path ? route.path : [];
        const path = [String(route.configId), ...routePath, route.value];
        this.router.navigate([`/${RootRoute.MAIN}`, MainRoute.ROUTE, path.join(':::'), 'route-id', route.routeId], {
          queryParamsHandling: 'merge',
          queryParams: {
            expandRouteGroup: true,
          }
        });
      });
      this.map.on('mouseenter', routeLayerId, (e) => {
        if (this.checkPropagation(e)) {
          return;
        }
        this.routesLayerService.highlightRoute(routeLayerId);
      });
      this.map.on('mouseleave', routeLayerId, (e) => {
        if (this.checkPropagation(e)) {
          return;
        }
        this.routesLayerService.highlightRoute(undefined);
      });
      this.showPointCursorForLayer(routeLayerId);
    });

    // on default map click (nothing from PlowOps is clicked)
    this.map.on('click', (e) => {
      if (this.checkPropagation(e)){
        return;
      }
      // deselect observation
      this.observationsManagerService.highlightObservation(null, ObservationsManagerService.MAP_SOURCE);
      // deselect image
      this.imagesManagerService.highlightImage(null, HighlightedItemSource.MAP);
      // close map layer switcher
      this.mapControlService.toggleMapLayerSwitcher(false);
      // show popup on Weather Warnings layer
      if (this.weatherWarningsService.isVisible()) {
        this.weatherWarningsService.getWmsFeatures(e.lngLat).then(featureCollection => {
          if (featureCollection.features.length === 0) {
            return;
          }
          const feature = featureCollection.features[0];
          this.markerInfoWindowService.showWeatherAlert(e.lngLat, feature.properties.cap_id, feature.properties.prod_type);
        });
      }

      // show popup on Traffic layer
      if (this.trafficLayerService.isVisible()) {
        this.trafficLayerService.queryIncidents(this.map, e.lngLat).then(json => {
          // @ts-ignore
          if (json.features.length === 0) {
            return;
          }
          // @ts-ignore
          const r = json.features[0];
          // @ts-ignore
          this.markerInfoWindowService.showTrafficIncidentInfo(
              new LngLat(r.geometry.x, r.geometry.y),
              {
                description: r.attributes.description,
                end_localtime: r.attributes.end_localtime,
                end_utctime: r.attributes.end_utctime,
                fulldescription: r.attributes.fulldescription,
                incidenttype: r.attributes.incidenttype,
                lastupdated_localtime: r.attributes.lastupdated_localtime,
                lastupdated_utctime: r.attributes.lastupdated_utctime,
                location: r.attributes.location,
                objectid: r.attributes.objectid,
                severity: r.attributes.severity,
                start_localtime: r.attributes.start_localtime,
                start_utctime: r.attributes.start_utctime,
              },
          );
        });
      }
    });
  }

  private checkPropagation(e: MapMouseEvent): boolean {
    // https://github.com/mapbox/mapbox-gl-js/issues/9369
    if (e.originalEvent.cancelBubble){
      return true;
    } else {
      e.originalEvent.cancelBubble = true;
      return false;
    }
  }

  private handleRoadStatusLayerClick(e: any) {
    if (this.checkPropagation(e)){
      return;
    }
    const roadSegmentId = e.features[0]?.properties?.id as number;
    if (!!roadSegmentId) {
      this.markerInfoWindowService.showRoadStatusInfoWindow(roadSegmentId, e.lngLat);
    }
  }

  private handleBreadCrumbSearchClick(event: MapMouseEvent, includeInbox: boolean = false) {
    if (this.checkPropagation(event)){
      return;
    }
    this.cancelOpenBreadcrumbsSearch();

    // credits to https://gis.stackexchange.com/questions/7430/what-ratio-scales-do-google-maps-zoom-levels-correspond-to
    const metersPerPx = 156543.03392 * Math.cos(event.lngLat.lat * Math.PI / 180) / Math.pow(2, this.map.getZoom());

    const hoursCount = 12;
    const searchParams = {
      coordinate: {
        latitude: event.lngLat.lat,
        longitude: event.lngLat.lng
      },
      timeFrom: Math.floor((new Date().valueOf() - hoursCount * 60 * 60 * 1000) / 1000),
      timeTo: undefined,
      radius: Math.min(metersPerPx * 15, 5000), // toleration 15 pixels. maximum 5km because of ws limitation
      locationSources: this.vehicleIdsFilter,
      sessions: undefined,
      includeInbox,
    } as LocationSearchParams;

    this.showSearchSnackBar('Searching for nearest breadcrumbs...');
    const that = this;
    this.openBreadcrumbSearchSubscription = this.locationApi.search(searchParams)
      .subscribe((response) => {
          const features = response.features;
          if (features.length === 0) {
            that.showSearchSnackBar('No breadcrumbs found.');
          } else {
            that.selectBreadcrumbs(features as PointFeature[], 'map-viewer');
            this.hideSearchSnackBar();
          }
        }, (error) => {
          console.error('Breadcrumbs search failed.', error);
          that.showSearchSnackBar('Breadcrumbs search failed.');
        }
      );
  }

  private selectBreadcrumbs(breadcrumbs: Array<PointFeature>, source: string) {
    const vehicleBreadcrumbs = breadcrumbs.map((breadcrumb) => {
      const vehicleId = breadcrumb.properties.locationsourceid;

      const vehicle = this.vehiclesManager.getVehicle(vehicleId);

      const location = {
        id: breadcrumb.properties.id,
        coords: {
          lat: breadcrumb.geometry.coordinates[1],
          lng: breadcrumb.geometry.coordinates[0]
        },
        time: new Date(breadcrumb.properties.unixtime * 1000),
        speed: breadcrumb.properties.speed,
        heading: breadcrumb.properties.heading,
        imageUrl: null,
        flags: breadcrumb.properties.flags,
        gpsSource: breadcrumb.properties.gpssource,
      };
      return new VehicleBreadcrumb(vehicle, null, location, breadcrumb.properties.sessionkey);
    });

    this.breadcrumbManagerService.selectBreadcrumb(vehicleBreadcrumbs, source);
  }

  private cancelOpenBreadcrumbsSearch() {
    if (this.openBreadcrumbSearchSubscription != null) {
      this.openBreadcrumbSearchSubscription.unsubscribe();
      this.openBreadcrumbSearchSubscription = null;
    }
  }

  private hideSearchSnackBar() {
    if (this.searchSnackbar != null) {
      this.searchSnackbar.dismiss();
      this.searchSnackbar = null;
    }
  }

  private showSearchSnackBar(message: string) {
    this.hideSearchSnackBar();
    this.searchSnackbar = this.toast.short(message);
  }

  private handleAssetsChanged(assets: Asset[]) {
    this.vehicleIdsFilter = assets.map(asset => asset.id);
  }

  private showPointCursorForLayer(layerId: string) {
    const that = this;
    that.map.on('mouseenter', layerId, () => {
      that.map.getCanvas().style.cursor = 'pointer';
    });
    that.map.on('mouseleave', layerId, () => {
      that.map.getCanvas().style.cursor = '';
    });
  }
}
