<div class="config-header">
    <h2 mat-dialog-title *ngIf="!layerEditMode">My Layers</h2>
    <h2 mat-dialog-title *ngIf="layerEditMode">Edit Layer</h2>
</div>

<mat-spinner *ngIf="!mapLayers"></mat-spinner>

<mat-dialog-content *ngIf="!!mapLayers && !layerEditMode">
    <mat-tab-group color="accent"
                   mat-stretch-tabs="true"
                   mat-align-tabs="start"
                   [(selectedIndex)]="tabIndex"
    >
        <mat-tab label="My Layers">
            <div class="layer-list" *ngIf="!!items && !layerEditMode">
                <ng-container *ngFor="let item of items">
                    <div class="ops-layer-menu-item ops-selectable-primary noselect text-primary"
                         (click)="item.onClick()"
                    >
                        <div class="lmi-icon-and-title text-primary"
                        >
                            <mat-icon class="lmi-icon"
                            >
                                {{ item.icon }}
                            </mat-icon>
                            <div class="lmi-title">
                                {{ item.title }}
                            </div>
                        </div>
                        <div class="lmi-type text-primary"
                        >
                            {{ item.type }}
                        </div>
                        <div class="lmi-description"
                        >
                            {{ item.description }}
                        </div>
                        <div class="lmi-action">
                            <button
                                    *ngIf="!!item.subMenus && item.subMenus.length > 0"
                                    class="lmi-action-button"
                                    [matMenuTriggerFor]="menu"
                                    (click)="$event.stopPropagation()"
                                    mat-icon-button
                            >
                                <mat-icon class="lmi-action-icon">more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <ng-container *ngFor="let subMenu of item.subMenus" >
                                    <button mat-menu-item (click)="subMenu.onClick()">
                                        <mat-icon>{{ subMenu.icon }}</mat-icon>
                                        <span>{{ subMenu.label }}</span>
                                    </button>
                                </ng-container>
                            </mat-menu>
                        </div>
                    </div>
                </ng-container>
            </div>
        </mat-tab>

        <mat-tab label="Add New Layer">
            <app-map-layer-edit [mapLayer]="newMapLayer"
                                #newMapLayerEdit
            ></app-map-layer-edit>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>

<mat-dialog-content class="mat-typography" *ngIf="layerEditMode && mapLayer">

    <app-map-layer-edit [mapLayer]="mapLayer"
    ></app-map-layer-edit>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <!-- edit layer -->
    <button mat-button *ngIf="layerEditMode" (click)="back()">Cancel</button>
    <button mat-button color="accent" cdkFocusInitial *ngIf="layerEditMode" (click)="onMapLayerSaved(mapLayer)">Save</button>
    <!-- add layer -->
    <button mat-button color="accent" cdkFocusInitial *ngIf="!layerEditMode && tabIndex === 1" (click)="createLayer()" [matTooltip]="'Add the layer'">Create layer</button>
    <button mat-button color="accent" mat-dialog-close *ngIf="!layerEditMode">Close</button>
</mat-dialog-actions>
