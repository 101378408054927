import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {CdkAccordion, CdkAccordionItem} from '@angular/cdk/accordion';
import {animate, AUTO_STYLE, state, style, transition, trigger} from '@angular/animations';
import {MatSlideToggle, MatSlideToggleChange} from '@angular/material/slide-toggle';
import {FormsModule} from '@angular/forms';

const DEFAULT_DURATION = 300;

@Component({
  selector: 'app-toggle-expansion-panel',
  standalone: true,
  imports: [
    CdkAccordion,
    CdkAccordionItem,
    MatSlideToggle,
    FormsModule
  ],
  templateUrl: './toggle-expansion-panel.component.html',
  styleUrl: './toggle-expansion-panel.component.scss',
  animations: [
    trigger('collapse', [
      state('true', style({ height: AUTO_STYLE, opacity: AUTO_STYLE })),
      state('false', style({ height: 0, opacity: 0 })),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-out')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out'))
    ]),
    trigger('rotate', [
      state('true', style({ scale: 1 })),
      state('false', style({ scale: -1 })),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out'))
    ])
  ]
})
export class ToggleExpansionPanelComponent {

  @Input()
  panelTitle = '';

  @Input()
  panelDescription = '';

  @Input() expanded = false;

  @Output() expandedChange = new EventEmitter<boolean>();

  @ViewChild(CdkAccordionItem) cdkAccordionItem: CdkAccordionItem;

  handleChange(e: MatSlideToggleChange) {
    this.cdkAccordionItem.toggle();
    this.expanded = !this.expanded;
    this.expandedChange.emit(this.expanded);
  }
}
