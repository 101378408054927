import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ShiftStatisticsStatus, ShiftWithDriverAndVehicleModel } from '../../../../shared/models/shift.model';
import { VehiclesService } from '../../../../data/vehicles/vehicles.service';
import { VehicleStats } from '../../../../shared/models/vehicle.model';
import moment from 'moment';
import { ReportsService } from '../../../../data/reports/reports.service';
import { saveAs } from 'file-saver-es';
import { ConfigurationModel, FeatureFlagEnum } from '../../../../shared/models/configuration.model';
import { ToastService } from '../../../../shared/services/toast.service';
import { GpsSourcePipe } from '../../../../shared/formatting/gps-source.pipe';
import { MatDialog } from '@angular/material/dialog';
import { ShiftMapInspectionDialogComponent } from './shift-map-panel-dialogs';
import { NavigationService } from '../../../../shared/services/navigation.service';
import { InsightsRoute } from '../../../insights/insights-routing.module';
import { DeviceInfoService } from '../../../../data/device/device-info.service';
import { DeviceInfo } from '../../../../shared/models/device-info';
import { MainRoute } from '../../../../shared/models/angular-routing';
import { ImageModel } from '../../../../shared/models/image';
import { RouteAssignmentService } from '../../../../data/routes/route-assignment.service';
import { RouteAssignment } from '../../../../shared/models/route-assignment';
import { Observation } from '../../../../shared/models/observation';
import { ActionMenuItem } from '../../../../shared/models/action-menu-item.class';
import { ShortDateOrTimePipe } from '../../../../shared/formatting/short-date-or-time.pipe';
import { ShiftMapDataService } from '../../services/shift-map-data.service';
import { HighlightedItemSource } from '../../../../shared/models/highlighted-item-update';
import { ActivatedRoute, Router } from '@angular/router';
import { ShiftLookupCategory } from '../../../insights/components/shift-lookup/shift-lookup.component';

@Component({
  selector: 'app-shift-map-panel',
  templateUrl: './shift-map-panel.component.html',
  styleUrls: ['./shift-map-panel.component.scss'],
})
export class ShiftMapPanelComponent implements OnInit, OnChanges {
  @Input() configuration: ConfigurationModel;
  @Input() shift: ShiftWithDriverAndVehicleModel;
  @Input() images: ImageModel[];
  @Input() observations: Observation[];
  @Input() collapseMenu?: boolean;
  background: ThemePalette = undefined;
  sidenavOpen$?: Observable<boolean>;
  insightRoute: string;
  source: string;

  statsLoading = true;
  stats: VehicleStats;
  deviceInfo: DeviceInfo;

  assignmentsLoading = true;
  assignments: RouteAssignment[];

  highlightedObservationId: number;
  observationItems: ActionMenuItem[];

  FeatureFlagEnum = FeatureFlagEnum;
  InsightsRoute = InsightsRoute;
  MainRoute = MainRoute;
  StatisticsStatus = ShiftStatisticsStatus;

  constructor(
    private store: Store<any>,
    private shiftMapManager: ShiftMapDataService,
    private vehicleService: VehiclesService,
    private routeAssignmentService: RouteAssignmentService,
    private reportService: ReportsService,
    private deviceInfoService: DeviceInfoService,
    private gpsSourcePipe: GpsSourcePipe,
    private toast: ToastService,
    private dialog: MatDialog,
    private navigation: NavigationService,
    private shortDateOrTimePipe: ShortDateOrTimePipe,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.sidenavOpen$ = this.store.pipe(
      select((state) => state.ui.sidenavOpen)
    );

    this.loadStats();
    this.loadRouteAssignments();

    this.shiftMapManager.highlightedObservation$.subscribe(highlightedObservationUpdate => {
      this.highlightedObservationId = highlightedObservationUpdate.itemId;
      if (!!this.observations) {
        this.updateObservationItems();
      }
    });

    this.insightRoute = this.router.url.split(/[\/?]+/)[3]?.toLowerCase();

    this.activatedRoute.queryParams.subscribe((params) => {
      this.source = params.source;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const observations = changes['observations']?.currentValue as Observation[];
    if (!!observations) {
      this.updateObservationItems();
    }
  }

  getDuration() {
    const end = moment(this.shift.end);
    const start = moment(this.shift.start);
    return moment.duration(end.diff(start)).humanize();
  }

  loadStats() {
    if (this.shift != null) {
      this.statsLoading = true;
      Promise.all([
        this.vehicleService
          .getVehicleStats(this.shift.vehicle.id, this.shift.id),
        this.deviceInfoService.getDeviceInfoForShift(this.shift.id).toPromise()
      ]).then(responses => {
        const [vehicleStats, deviceInfo] = responses;
        this.stats = vehicleStats.data;
        this.deviceInfo = deviceInfo.data;
      }).catch((message) => {
        this.toast.long(message.error);
      }).finally(() => {
        this.statsLoading = false;
      });
    }
  }

  loadRouteAssignments() {
    if (!!this.shift) {
      this.routeAssignmentService.getRouteAssignments(
        null,
        this.shift.vehicleId,
        this.shift.id,
        null,
        null,
        true,
        false,
      ).then(response => {
        this.assignments = response.data;
      }).catch(error => {
        this.toast.longer('Cannot read Route Assignments from server!');
      }).finally(() => {
        this.assignmentsLoading = false;
      });
    }
  }

  getGpsSource() {
    return (this.stats && this.stats.gpsSources && this.stats.gpsSources.length > 0)
      ? this.stats.gpsSources.map((source) => (this.gpsSourcePipe.transform(source))).join(', ')
      : 'N/A';
  }

  previewDVIR(preTrip: boolean) {
      const dialog = this.dialog.open(ShiftMapInspectionDialogComponent, {
        maxWidth: '80vw',
        maxHeight: '90vh',
        data: {
          shiftId: this.shift.id,
          preTrip
        }
      });
      dialog.afterClosed().toPromise().then(result => {
        if (result) {
          this.downloadDVIR(preTrip);
        }
      });
  }

  private downloadDVIR(preTrip: boolean) {
    this.reportService.getDvirByShiftPDF(this.shift.id, preTrip).toPromise().then(response => {
      const blob = new Blob([response], { type: 'pdf' });
      const suffix = preTrip ? 'pre' : 'post';
      saveAs(blob, `Inspection_Report__id_${this.shift.id}_${suffix}.pdf`);
    });
  }

  private updateObservationItems() {
    this.observationItems = this.observations.map(observation => this.observationToActionMenuItem(observation));
  }

  private observationToActionMenuItem(observation: Observation): ActionMenuItem {
    return new ActionMenuItem(
      observation.id,
      'stars',
      observation.observationType.title,
      this.shortDateOrTimePipe.transform(observation.location.time),
      '',
      null,
      () => this.highlightedObservationId === observation.id,
      null,
      null,
      () => this.selectObservation(observation),
      null,
      null,
      [],
    );
  }

  private selectObservation(observation: Observation) {
    this.shiftMapManager.sendHighlightedObservationUpdate(observation.id, HighlightedItemSource.PANEL);
  }

  hasFeatureFlag(featureFlag: string): boolean {
    return this.configuration.featureFlags.find(value => value.isEnabled && value.name === featureFlag) !== undefined;
  }

  goBack() {
    const queryParams = {};
    if (this.insightRoute === InsightsRoute.SHIFT_LOOKUP && !!this.source) {
      queryParams['category'] = this.source;
      queryParams['id'] = this.source === ShiftLookupCategory.DRIVERS ? this.shift.driverId : this.shift.vehicleId;
    }
    this.router.navigate(
      ['../..'],
      {
        relativeTo: this.activatedRoute,
        queryParams,
      }
    );
  }
}
