import {Component, OnDestroy, OnInit} from '@angular/core';
import {RightPanel} from '../right-panel.class';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfigurationService} from '../../../../../configuration/configuration.service';
import {AssetsManagerService} from '../../../../../data/assets/assets-manager.service';
import {EventManagerService} from '../../../../../data/events/event-manager.service';
import {EventRecord} from '../../../../../shared/models/event.model';
import {ActionMenuItem} from '../../../../../shared/models/action-menu-item.class';
import {ShortDateOrTimePipe} from '../../../../../shared/formatting/short-date-or-time.pipe';
import {Asset} from '../../../models/asset.class';
import {DrawerContent} from '../../../../../layouts/right-drawer/right-drawer.component';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrl: './alerts.component.scss'
})
export class AlertsComponent extends RightPanel implements OnInit, OnDestroy {

  selectedVehicle: Asset;
  eventRecords: EventRecord[];
  items: ActionMenuItem[];
  initialAction = false;

  constructor(
      protected router: Router,
      protected activatedRoute: ActivatedRoute,
      protected configurationService: ConfigurationService,
      protected assetsManager: AssetsManagerService,
      private eventManager: EventManagerService,
      private shortDateOrTimePipe: ShortDateOrTimePipe,
  ) {
    super(
        DrawerContent.ALERTS,
        router,
        activatedRoute,
        configurationService,
        assetsManager,
    );
  }

  ngOnInit(): void {
    this.initialize();
    this.loadConfiguration();

    const eventSubscription = this.eventManager.events$.subscribe(events => {
      this.eventRecords = events;
      if (this.initialAction) {
        this.onAssetAction();
        this.initialAction = false;
      } else {
        this.eventsToItems();
      }
    });
    this.openSubscriptions.push(eventSubscription);
  }

  ngOnDestroy(): void {
    this.unsubscribe();
    this.eventRecords = undefined;
    this.selectedVehicle = undefined;
    this.items = undefined;
  }

  onAssetsUpdate() {
    // do nothing
  }

  onAssetChange() {
    // do nothing
  }

  onAssetAction() {
    if (!!this.eventRecords) {
      this.selectedVehicle = this.asset;
      this.eventsToItems();
    } else {
      this.initialAction = true;
    }
  }

  onRouteAction() {
    // do nothing
  }

  onSelectedVehicleChange() {
    this.eventsToItems();
  }

  clearSelectedVehicle() {
    this.selectedVehicle = null;
    this.eventsToItems();
  }

  onConfigurationLoad() {
    // do nothing
  }

  private eventsToItems() {
    const filteredEvents = !!this.selectedVehicle
        ? this.eventRecords.filter(event => event.vehicleId === this.selectedVehicle.id)
        : this.eventRecords;
    this.items = filteredEvents.map(event => this.eventRecordToItem(event));
  }

  private eventRecordToItem(event: EventRecord): ActionMenuItem {
    return new ActionMenuItem(
        event.id,
        'notifications',
        event.name,
        event.message,
        this.shortDateOrTimePipe.transform(new Date(event.created)),
        null,
        () => false,
        null,
        null,
        () => false,
        null,
        null,
        [],
    );
  }
}
