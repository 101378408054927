import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR} from '@angular/forms';
import {NgIf} from '@angular/common';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {NamedId} from '../../../../shared/models/NamedId';
import {animate, AUTO_STYLE, state, style, transition, trigger} from '@angular/animations';

const DEFAULT_DURATION = 300;

@Component({
  selector: 'app-row-with-toggle',
  standalone: true,
  imports: [
    FormsModule,
    NgIf,
    MatSlideToggle
  ],
  templateUrl: './row-with-toggle.component.html',
  styleUrl: './row-with-toggle.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: RowWithToggleComponent
    }
  ],
  animations: [
    trigger('collapse', [
      state('true', style({ height: AUTO_STYLE, opacity: AUTO_STYLE })),
      state('false', style({ height: 0, opacity: 0 })),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-out')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out'))
    ]),
  ]
})
export class RowWithToggleComponent implements ControlValueAccessor {

  @Input() primaryText!: string;
  @Input() secondaryText?: string;
  @Input() disabled: boolean;
  @Input() checked = false;
  @Output() checkedChange = new EventEmitter<boolean>();

  /**
   * When the toggle will render children toggles.
   */
  @Input() children?: NamedId[];

  selectedChildIds: {[key: number]: boolean} = {};

  @Input() set checkedChildren(value: number[]) {
    this.selectedChildIds = value?.reduce(
      (result, id) => {result[id] = true; return result},
      {}) || {};
  };
  @Output() checkedChildrenChange = new EventEmitter<number[]>();

  onChangeListener = _ => {}
  onTouchedListener = () => {}

  writeValue(obj: any) {
    this.checked = !!obj;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  registerOnTouched(fn: any) {
    this.onTouchedListener = fn;
  }

  registerOnChange(fn: any) {
    this.onChangeListener = fn;
  }

  handleChange(newState: boolean) {
    this.onTouchedListener();
    this.onChangeListener(newState)
    if (!newState) {
      this.checkedChildren = []; // reset children on disabled toggle
      this.checkedChildrenChange.emit(this.checkedChildren);
    }
    this.checkedChange.emit(newState);
    this.checked = newState;
  }

  handleChildrenChange(child: NamedId) {
    if (this.selectedChildIds[child.id]) {
      delete this.selectedChildIds[child.id];
    } else {
      this.selectedChildIds[child.id] = true;
    }
    this.checkedChildrenChange.emit(
      Object.keys(this.selectedChildIds).map(key => Number(key))
    );
  }

}
