import {MapContentLayer} from '../MapContentLayer';
import {MapContentSource} from '../MapContentSource';
import {TrackStyles} from '../../../../../configuration/model/TrackStyles';
import { FilterSpecification, LayerSpecification, LineLayerSpecification, Map } from 'maplibre-gl';
import {MapFilters} from '../../../../../configuration/map-filters';
import {ActivityFilter} from '../../../../../configuration/settings.service';
import { ShiftWithDriverAndVehicleModel } from '../../../../models/shift.model';
import moment from 'moment';

export class ShiftTrackPlowDownLayer extends MapContentLayer {

  private filterFrom = 0;
  private filterTo = 100;

  private readonly shiftStart: number;
  private readonly shiftLength: number;

  constructor(
    source: MapContentSource,
    private trackStyles: TrackStyles,
    private shift?: ShiftWithDriverAndVehicleModel, // if null, filtering by time not supported
  ) {
    super('shift-track-plow-down-layer', source);
    this.shiftStart = !!shift ? moment(shift.start).unix() : null;
    this.shiftLength = !!shift ? moment(shift.end).diff(moment(shift.start), 'seconds') : null;
  }

  toLayerSpecification(): LayerSpecification {
    return {
      id: this.layerId,
      type: 'line',
      source: this.sourceRef.sourceId,
      filter: MapFilters.getShiftGeoJsonFilter(
        ActivityFilter.PLOWING_MOWING_SWEEPING, this.getAbsTime(this.filterFrom), this.getAbsTime(this.filterTo)
      ),
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': this.trackStyles.shiftPlowPlowDown.color,
        'line-opacity': this.trackStyles.shiftPlowPlowDown.opacity,
        'line-width': this.trackStyles.shiftPlowPlowDown.width,
        'line-offset': this.trackStyles.shiftPlowPlowDown.offset,
      },
    } as LineLayerSpecification;
  }

  filterByTime(map: Map, timeFrom: number, timeTo: number) {
    this.filterFrom = timeFrom;
    this.filterTo = timeTo;

    map.setFilter(
      this.layerId,
      MapFilters.getShiftGeoJsonFilter(
        ActivityFilter.PLOWING_MOWING_SWEEPING, this.getAbsTime(this.filterFrom), this.getAbsTime(this.filterTo)
      ) as FilterSpecification,
    );
  }

  private getAbsTime(relativePosition: number): number {
    // unknown start or length
    if (!this.shiftStart || !this.shiftLength) {
      return null;
    }
    // do not filter start or end at all
    if (relativePosition === 0 || relativePosition === 100) {
      return null;
    }
    return Math.round(this.shiftStart + relativePosition / 100 * this.shiftLength);
  }
}
