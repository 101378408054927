import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CartegraphManagementService} from '../../../../../data/cartegraph/cartegraph-management.service';
import {ImportResultRow} from '../../../../../shared/models/cartegraph.model';
import {JsonApiResponse} from '../../../../../shared/models/JsonApiResponse';
import {AddOrImportVehicleDialogForm, AddOrImportVehicleResult} from '../edit-vehicle/vehicle.types';


@Component({
  selector: 'app-import-vehicles-cartegraph',
  templateUrl: './import-vehicles-cartegraph.component.html',
  styleUrls: ['../dialogs-vehicle/dialog-components.scss'],
})
export class ImportVehiclesCartegraphComponent implements AddOrImportVehicleDialogForm {

  uiError: string;

  ImportVehiclesStateEnum = ImportVehiclesStateEnum;
  currentState = ImportVehiclesStateEnum.NOOP;

  importKeys: { name: string, label: string }[] = [
    {name: 'added', label: 'Added'},
    {name: 'activated', label: 'Updated or reactivated'},
    {name: 'deactivated', label: 'Deactivated'},
    {name: 'skipped', label: 'Skipped'},
    {name: 'error', label: 'Errors'}];

  importResult: { key: string; list: ImportResultRow[] };

  constructor(public dialog: MatDialog,
              private cartegraphManagementService: CartegraphManagementService) {
  }

  canBeSaved(): boolean {
    return !this.importDisabled();
  }

  save(): Promise<AddOrImportVehicleResult> {
    return new Promise((resolve, reject) => {
      this.doImport()
          .then((result) => {
            if (result.error) {
              reject(result.error);
            } else {
              const saveResult: AddOrImportVehicleResult = {
                type: 'vehicleImport',
                data: result.data,
              };
              resolve(saveResult);
            }
          })
          .catch((error) => {
            reject(error);
          });
    });
  }

  doImport(): Promise<JsonApiResponse<{ key: string, list: ImportResultRow[] }>> {
    this.currentState = ImportVehiclesStateEnum.WORKING;
    this.uiError = null;

    const apiPromise = this.cartegraphManagementService.importVehicles();
    apiPromise
        .then(response => {
          if (response.error) {
            this.uiError = response.error;
            this.currentState = ImportVehiclesStateEnum.NOOP;
          } else {
            this.importResult = response.data;
            this.currentState = ImportVehiclesStateEnum.IMPORTED;
          }
        }).catch(error => {
          this.uiError = error;
          this.currentState = ImportVehiclesStateEnum.NOOP;
        });

    return apiPromise;
  }

  importDisabled(): boolean {
    return false; // return this.validData.filter(row => row.action !== 'NONE').length === 0;
  }

}

enum ImportVehiclesStateEnum {
  NOOP,
  WORKING,
  IMPORTED
}
