import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DvirFormDto} from '../../pages/settings/components/manage-inspection-forms/model/dto/DvirFormDto';
import {JsonApiResponse} from '../../shared/models/JsonApiResponse';
import {environment} from '../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {HttpErrorHandler} from '../../http.error.handler';
import {DvirSystemForm} from '../../pages/settings/components/manage-inspection-forms/model/DvirSystemForm';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InspectionFormsService {

  private apiVersion = 3;

  constructor(private http: HttpClient) {
  }

  async saveForm(form: DvirFormDto): Promise<DvirFormDto> {
    return firstValueFrom(this.http.post<JsonApiResponse<DvirFormDto>>(
        `${environment.services.service}v${this.apiVersion}/dvir-form`, form
      ).pipe(
        map(response => response.data),
        catchError(HttpErrorHandler.handleError))
    );
  }

  deleteForm(externalId: number): Promise<number> {
    return firstValueFrom(this.http.delete<JsonApiResponse<number>>(
        `${environment.services.service}v${this.apiVersion}/dvir-form/${externalId}`, {}
      ).pipe(
        map((response) => response.data),
        catchError(HttpErrorHandler.handleError))
    );
  }

  getForms(): Promise<DvirFormDto[]> {
    const params = {
      'fetch-type': 'FULL'
      // 'fetch-type': 'SIMPLE'
    };
    return firstValueFrom(this.http.get<JsonApiResponse<DvirFormDto[]>>(
        `${environment.services.service}v${this.apiVersion}/dvir-form/list`, {params}
      ).pipe(
        map((response) => response.data),
        catchError(HttpErrorHandler.handleError))
    );
  }

  getSystemForms(): Promise<DvirSystemForm[]> {
    return firstValueFrom(
      this.http.get<JsonApiResponse<DvirSystemForm[]>>(
        `${environment.services.service}v1/configuration/system-inspection-forms`, {}
      ).pipe(
        map((response) => response.data),
        catchError(HttpErrorHandler.handleError))
    );
  }

  getForm(externalId: number): Promise<DvirFormDto> {
    return firstValueFrom(this.http.get<JsonApiResponse<DvirFormDto>>(
        `${environment.services.service}v${this.apiVersion}/dvir-form/${externalId}`, {}
      ).pipe(
        map((response) => response.data),
        catchError(HttpErrorHandler.handleError))
    );
  }
}
