import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export class RouteStatusConfig {
    threshold: number;
}

@Component({
  selector: 'app-route-status-map-config',
  templateUrl: './route-status-map-config.component.html',
  styleUrl: './route-status-map-config.component.scss'
})
export class RouteStatusMapConfigComponent implements OnInit {

    thresholdIndex: number;
    thresholdValues: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 21, 24, 30, 36, 48, 72];

    constructor(
        public dialogRef: MatDialogRef<RouteStatusMapConfigComponent>,
        @Inject(MAT_DIALOG_DATA) public data: RouteStatusConfig,
    ) {
    }

    ngOnInit() {
        this.thresholdIndex = this.thresholdValues.indexOf(this.data.threshold) + 1;
    }

    // we do not have access to component members here, so we pass it via parameter
    formatLabel(values: number[]) {
        return (index: number): string => {
            return `${values[index - 1]}h`;
        };
    }

    onThresholdChange(e) {
        this.data.threshold = this.thresholdValues[this.thresholdIndex - 1];
    }

    reset() {
        this.data.threshold = 4;
        this.thresholdIndex = this.thresholdValues.indexOf(this.data.threshold) + 1;
    }

    isDefault() {
        return this.data.threshold === 4;
    }
}
