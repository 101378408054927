<div class="card-menu-item"
     (click)="input.onClick()"
     [ngClass]="{ 'active': input.activePredicate()}"
     (mouseenter)="!!input.onHoverOn && input.onHoverOn()"
     (mouseleave)="!!input.onHoverOff && input.onHoverOff()"
>
    <div class="card-header">
        <div class="card-header-title">
            {{ input.title }}
        </div>
        <div class="card-header-action">
          <ng-container *ngIf="!!input.subMenus && input.subMenus.length === 1">
            <button
              class="ami-action-button button-on-hover"
              (click)="input.subMenus[0].onClick()"
              mat-icon-button
              [matTooltip]="input.subMenus[0].label"
            >
              <mat-icon class="ami-action-icon">{{ input.subMenus[0].icon }}</mat-icon>
            </button>
          </ng-container>
          <ng-container *ngIf="!!input.actionIcon && input.actionIcon.startsWith('pinned_')">
            <!-- not pinned -->
            <button
              *ngIf="!!input.actionIcon && input.actionIcon.endsWith('_false')"
              class="ami-action-button button-on-hover"
              mat-icon-button
              (click)="input.inactivePredicate()"
              [matTooltip]="'Pin'"
            >
              <mat-icon class="ami-action-icon">keep</mat-icon>
            </button>
            <!-- pinned -->
            <button
              *ngIf="!!input.actionIcon && input.actionIcon.endsWith('_true')"
              class="ami-action-button black"
              mat-icon-button
              (click)="input.inactivePredicate()"
              [matTooltip]="'Unpin'"
            >
              <mat-icon class="ami-action-icon">keep</mat-icon>
            </button>
          </ng-container>
          <ng-container *ngIf="!!input.subMenus && input.subMenus.length > 1">
            <button
              class="ami-action-button"
              [matMenuTriggerFor]="menu"
              (click)="$event.stopPropagation()"
              mat-icon-button
            >
              <mat-icon class="ami-action-icon">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <ng-container *ngFor="let subMenu of input.subMenus" >
                <button mat-menu-item (click)="subMenu.onClick()">
                  <mat-icon>{{ subMenu.icon }}</mat-icon>
                  <span>{{ subMenu.label }}</span>
                </button>
              </ng-container>
            </mat-menu>
          </ng-container>
        </div>
    </div>

    <div class="card-body">
        {{ input.subtitle }}
    </div>

    <div class="card-footer">
        <div>
            {{ input.text3 }}
        </div>
        <div *ngIf="!!input.actionIcon && input.icon === 'message'" class="card-footer-right">
            {{ input.actionIcon }}
            <mat-icon>visibility</mat-icon>
        </div>
    </div>

</div>
